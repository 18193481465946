import { globalPostService, globalGetService } from "../../utils/globalApiServices";

export function getDevListsByOrgId(data = {}) {
  return new Promise((resolve, reject) => {
    globalPostService(`list-devices-by-orgId-v1`, data)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
export function getResListsByOrgId(data = {}) {
  return new Promise((resolve, reject) => {
    globalPostService(`list-resources-by-orgId-v1`, data)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function getAllRoomList() {
  return new Promise((resolve, reject) => {
    globalGetService(`list-of-room-details-v1`)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function activateDeviceById(data = {}) {
  return new Promise((resolve, reject) => {
    globalPostService(`device-add-v1`, data)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function deviceDetailById(data = {}) {
  return new Promise((resolve, reject) => {
    globalPostService(`devices-detail-by-Id-v1`, data)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function activateAndDeactivateDeviceById(data = {}) {
  return new Promise((resolve, reject) => {
    globalPostService(`device-activate-deactivate-by-Id-v1`, data)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function resetDeviceById(data = {}) {
  return new Promise((resolve, reject) => {
    globalPostService(`reset-device-by-Id-v1`, data)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
export function addDeviceWallpaper(data = {}) {
  return new Promise((resolve, reject) => {
    globalPostService(`update-device-wallpaper-v1`, data)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
export function timezone() {
  return new Promise((resolve, reject) => {
    globalGetService(`meta/timezone`).then((res) => {
      resolve(res)
    }).catch((err) => {
      reject(err)
    })
  })
}

export function createWorkspace(data = {}) {
  return new Promise((resolve, reject) => {
    globalPostService(`add-orglocations-details-v1`, data)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
export function createResource(data = {}) {
  return new Promise((resolve, reject) => {
    globalPostService(`resources-create-v1`, data)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function getWorkspaceWallpaperApi(data = {}) {
  return new Promise((resolve, reject) => {
    globalPostService(`get-org-wallpapers-v1`, data)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function getWorkspaceListing(data = {}) {
  return new Promise((resolve, reject) => {
    globalPostService(`get-org-locations-v1`, data)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
export function editWorkspaceListing(data = {}) {
  return new Promise((resolve, reject) => {
    globalPostService(`update-orglocation-details-by-id-v1`, data)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
export function getSingleWorkspaceList(data = {}) {
  return new Promise((resolve, reject) => {
    globalPostService(`get-org-location-details-v1`, data)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function deleteWorkspaceListing(data = {}) {
  return new Promise((resolve, reject) => {
    globalPostService(`workspace-delete-v1`, data)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function getWorkspaceRoomDetails(data = {}) {
  return new Promise((resolve, reject) => {
    globalPostService(`get-room-details-by-worspace-v1`, data)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
export function addWorkspaceRoomDetails(data = {}) {
  return new Promise((resolve, reject) => {
    globalPostService(`add-room-details-v1`, data)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function updateWorkspaceRoomDetails(data = {}) {
  return new Promise((resolve, reject) => {
    globalPostService(`update-room-details-v1`, data)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
export function getroomsbyOrg(data = {}) {
  return new Promise((resolve, reject) => {
    globalPostService(`get-room-by-org-v1`, data)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function deactiveWorkspaceRoomDetails(data = {}) {
  return new Promise((resolve, reject) => {
    globalPostService(`deactivate-room-v1`, data)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
export function deleteResource(data = {}) {
  return new Promise((resolve, reject) => {
    globalPostService(`delete-resourc-v1`, data)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
export function createDemoOrg(data = {}) {
  return new Promise((resolve, reject) => {
    globalPostService(`add-demo-details-v1`, data)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}