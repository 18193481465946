import React, { useState, useEffect, useContext } from "react";
import styles from "../../../assets/styles/overview.module.scss";
import { DevicesCard, AnalyticsCard } from "../../../shared_elements";
import { OrganizationDetailsCard, OverviewHeader } from "../components";
import { useHistory, useParams } from "react-router-dom";
import { GlobalContext } from "../../../context/GlobalContext";
import { getOrgOverviewList, partnerDashboardAnalyticsApi } from "../apiServices";
import Shimmer from "../components/Shimmer";

export default function Overview(props) {
  const { id } = useParams();
  const {
    state: { orgListNames },
  } = useContext(GlobalContext);
  const history = useHistory();
  const [loader, setLoader] = useState(true);
  const [orgOverview, setOrgOverview] = useState(null);
  const [analytics, setAnalytics] = useState(null)
  useEffect(() => {
    if (orgListNames.length > 0) {
      let crrOrg = orgListNames?.find((orgName) => orgName._id === id);
      if (crrOrg) {
        let recentlyVisitedOrgs = JSON.parse(localStorage.getItem("recentlyVisitedOrgs"));
        if (recentlyVisitedOrgs && recentlyVisitedOrgs.length) {
          recentlyVisitedOrgs = recentlyVisitedOrgs.filter((org) => org._id !== id);
          recentlyVisitedOrgs = [...[crrOrg], ...recentlyVisitedOrgs];
          localStorage.setItem("recentlyVisitedOrgs", JSON.stringify(recentlyVisitedOrgs));
        } else {
          recentlyVisitedOrgs = [crrOrg];
          localStorage.setItem("recentlyVisitedOrgs", JSON.stringify(recentlyVisitedOrgs));
        }
      } else {
        history.push("/home");
      }
    } else {
    }
  }, [id, orgListNames]);

  useEffect(() => {
    getOrgOverviewList({ orgId: id }).then((response) => {
      if (response.status && Object.keys(response.org).length > 0) {
        setOrgOverview(response.org);
        setLoader(false);
      } else {
        setLoader(false);
      }
    });
    partnerDashboardAnalyticsApi({ orgId: id }).then((response) => {
      if (response.status && Object.keys(response).length > 0) {
        setAnalytics(response.data);
        setLoader(false);
      } else {
        setLoader(false);
      }
    });
  }, [id]);

  return (
    <div className={styles.overviewPage}>
      <div className={styles.overviewWrapper}>
        <OverviewHeader />
        {loader ? (
          <Shimmer type="orgOverview" />
        ) : (
          <>
            <div className={styles.organizationDetailsCardWrapper}>
              <OrganizationDetailsCard orgOverview={orgOverview} />
            </div>
            <div className={styles.devicesCardWrapper}>
              <DevicesCard
                activatedDevices={analytics?.activeRooms}
                notActivatedDeviceCount=""
                disconnectedDeviceCount={analytics?.inactiveRooms}
                deviceOnlineCount={analytics?.onlineRoomsCount}
                deviceOfflineCount={analytics?.offlineRoomCount}
              />
            {loader ? null : <AnalyticsCard analytics={analytics} orgId={id}/>}
            </div>
          </>
        )}
      </div>
    </div>
  );
}
