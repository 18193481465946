import React from "react";
import { Grid, List, ListItem } from "@material-ui/core";
import styles from "../../../assets/styles/overview.module.scss";

export default function OverviewHeader(props) {
  return (
    <div className={styles.overviewHeaderWrapper}>
      <div className={styles.overviewPageTitleWrapper}>
        <h2 className={styles.overviewPageTitle + " subheading "}>Overview</h2>
      </div>
    </div>
  );
}
