import React from 'react';
import { Grid } from "@material-ui/core";
import styles from "../../../assets/styles/auth.module.scss";
import { imageBasePath } from "../../../constants";
import { CustomButton } from "../../../shared_ui_components";
import { useHistory } from 'react-router';
import { getOrganisationName } from "../../../features/request_quote/apiServices";
import { getStore } from '../../../store/GlobalStore';
import { toastFlashMessage,sortCaseInsetive } from '../../../utils';



const getAllOrganisationNames = () => {
    getOrganisationName().then((response) => {
      if (response.status && response.orgList?.length) {
        const { dispatch } = getStore();
        dispatch({
          type: "SET_ORGANISATION_NAME",
          payload: response.orgList.sort((a,b) => sortCaseInsetive(a, b, "orgName"))
        });
      } else {
        toastFlashMessage("Authorization is required", "error");
      }
    });
  };


export default function VerifiedSuccess(props) {
    const history = useHistory()
    const{ image, title, description, loader } = props;
    return (
        <section className={styles.successSection}>
            <Grid container direction="row" spacing={2} align="center" justifyContent="center" alignItems="center">
                <Grid item md={4}>
                    <div className={styles.successWrapper}>
                        <div className={styles.imageWrapper}>
                           {!loader && ( <img src={imageBasePath+image} alt="success"/>)}
                        </div>
                        <div className={styles.contentWrapper}>
                            <p className={styles.contentHeading+" heading6"}>{title}</p>
                            <p className={styles.contentPara+" paragraph"}>{description}</p>
                            <CustomButton
                                children={loader ? "Verifying..":"Continue"}
                                className={`${styles.contentBtn} primaryBtn submitBtn ${loader ? 'disabledBtn':''} `}
                                onClick={(e) => {history.push('/home');getAllOrganisationNames()}}
                            />
                        </div>
                    </div>
                </Grid>
            </Grid>
        </section>
    )
}
