import React, { useState, useEffect } from "react";
import styles from "../../../assets/styles/orgSettings.module.scss";
import { imageBasePath, errorCodes } from "../../../constants";
import { InputText, CustomButton } from "../../../shared_ui_components";
import { createNewDomain, getDomain, deleteDomain } from "../apiServices";
import { toastFlashMessage } from "../../../utils";
import { fieldValidation } from "../../../utils/formValidation";
import { useParams } from "react-router";
import Shimmer from "./Shimmer";

export default function Domains(props) {
  const { id } = useParams();
  const [error, setError] = useState({});
  const [formData, setFormData] = useState({
    orgId: id,
  });
  const [domainLists, setDomainLists] = useState([]);
  const [validateLoader, setValidateLoader] = useState(false);
  const [loader, setLoader] = useState(true);

  const getAllDomain = () => {
    getDomain(formData).then((response) => {
      if (response.status) {
        setDomainLists(response.domainList);
        setLoader(false);
      } else {
        setLoader(false);
      }
    });
  };

  useEffect(() => {
    getAllDomain();
  }, []);

  const handleChange = (key, value) => {
    setFormData((form) => ({
      ...form,
      [key]: value,
    }));
    setError((preError) => ({
      ...preError,
      [key]: "",
    }));
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    let validateNewInput = {};
    validateNewInput = {
      newDomain:
        errorCodes["newDomain"][
          fieldValidation({ ...errorCodes.newDomainObj, fieldval: formData.newDomain })
        ],
    };
    if (Object.keys(validateNewInput).every((k) => validateNewInput[k] == "")) {
      setValidateLoader(true);
      const data = domainLists.filter((ele) => ele.domainName === formData.newDomain);
      if (data.length > 0) {
        toastFlashMessage("Domain already exists", "error");
        setValidateLoader(false);
      } else
        createNewDomain(data.length > 0 ? data : formData).then((response) => {
          if (response.status) {
            setValidateLoader(false);
            setDomainLists(response.domainList);
            toastFlashMessage("Domain added successfully", "success");
            setFormData((form) => ({
              ...form,
              newDomain: "",
            }));
          } else {
            toastFlashMessage("Something went wrong while adding domain", "error");
            setValidateLoader(false);
          }
        });
    } else {
      setError(validateNewInput);
    }
  };

  const handleDeleteDomain = (domainId) => {
    const delDomainId = domainLists.find((item) => item._id === domainId)._id;
    const form = {
      orgId: id,
      domainId: delDomainId,
    };
    deleteDomain(form).then((response) => {
      if (response.status) {
        setDomainLists(response.orgList);
        toastFlashMessage("Domain deleted successfully", "success");
      } else {
        toastFlashMessage("Something went wrong while deleting domain", "error");
      }
    });
  };

  return (
    <div className={styles.domainsWrapper}>
      <div className={styles.addDomainWrapper}>
        <form onSubmit={handleSubmit}>
          <div className={styles.addDomainSection}>
            <InputText
              className={styles.addDomainInput}
              label="Enter Domain Name"
              placeholder="eg, www.amce.in"
              value={formData.newDomain || ""}
              error={error.newDomain}
              onChange={(e) => {
                handleChange("newDomain", e.target.value);
              }}
            />
          </div>
          <div className={styles.addDomainBtnWrapper}>
            <CustomButton
              children={validateLoader ? "Adding..." : "Add Domain"}
              className={`${styles.addDomainBtn} primaryBtn ${validateLoader ? "disabledBtn" : ""}`}
              //onClick={(e) => handleSubmit(e)}
              type="submit"
            />
          </div>
        </form>
      </div>
      <div className={styles.domainAddedListWrapper}>
        <div className={styles.domainAddedTitleWrapper}>
          <p className={styles.domainAddedTitle + " paragraph"}>
            {loader ? <Shimmer type="domainLength" /> : <span> Domains added ({domainLists?.length})</span>}
          </p>
        </div>
        {loader ? (
          <Shimmer type="domain" />
        ) : (
          domainLists?.map((item, key) => (
            <div className={styles.addedDomainListItem} key={key}>
              <div className={styles.addedDomainListItemLeftBlk}>
                <img src={imageBasePath + "domain_added.svg"} alt="" />
                <p className={styles.addedDomainName + " subheading2"}>{item.domainName}</p>
              </div>
              <div className={styles.addedDomainListItemRightBlk}>
                <img
                  src={imageBasePath + "delete-bin-line.svg"}
                  alt=""
                  onClick={() => {
                    handleDeleteDomain(item._id);
                  }}
                />
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
}
