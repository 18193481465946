import React, { useEffect, useState } from "react";
import styles from "../../../assets/styles/devices.module.scss";
import { Grid } from "@material-ui/core";
import { getDeploymentDetails } from "../apiServices";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import DeploymentList from "../components/DeploymentList";
import { NoResult } from "../../../shared_ui_components";
import { toastFlashMessage } from "../../../utils";

function DeploymentsDetails() {
  const { id } = useParams();
  const [deployDetails, setDeployDetails] = useState("");
  const [orgName, setOrgName] = useState("");

  useEffect(() => {
    getDeploymentDetails({ orgId: id }).then((resp) => {
      if (resp.status) {
        setDeployDetails(resp.deploymentDetails);
        setOrgName(resp.orgName);
      }else{
        toastFlashMessage(
          `Something went wrong while fetching the deployments`,
          "error"
        );
      }
    }).catch((error)=>{
      console.log(error);
      toastFlashMessage(
        error.message,
        "error"
      );
    });
  }, []);

  return (
    <div className={styles.devicesPage}>
      <div className={styles.devicesWrapper}>
        <div className={styles.devicesHeaderWrapper}>
          <div className={styles.devicesPageTitleWrapper}>
            <h2 className={styles.devicesPageTitle + " subheading "}>
              Deployments
            </h2>
          </div>
          <div className={styles.devicesListWrapper}>
            <div className={styles.devicesList}>
              <div className={styles.devicesListItemWrapper}>
                {deployDetails.length === 0 ? (
                  <NoResult image="no_order.png" title="No Deployments found" />
                ) : (
                  <>
                    <div className={styles.devicesListItemTitleWrapper}>
                      <Grid container spacing={2}>
                      <Grid item md={2}>
                          <div
                            className={styles.devicesListItemTitleTextWrapper}
                          >
                            <p
                              className={
                                styles.devicesListItemTitle + " supportText "
                              }
                            >
                              Organisation
                            </p>
                          </div>
                        </Grid>
                        <Grid item md={2}>
                          <div
                            className={styles.devicesListItemTitleTextWrapper}
                          >
                            <p
                              className={
                                styles.devicesListItemTitle + " supportText "
                              }
                            >
                              Workspace Name
                            </p>
                          </div>
                        </Grid>
                        <Grid item md={2}>
                          <div
                            className={styles.devicesListItemTitleTextWrapper}
                          >
                            <p
                              className={
                                styles.devicesListItemTitle + " supportText "
                              }
                            >
                              Release
                            </p>
                          </div>
                        </Grid>
                        <Grid item md={2}>
                          <div
                            className={styles.devicesListItemTitleTextWrapper}
                          >
                            <p
                              className={
                                styles.devicesListItemTitle + " supportText "
                              }
                            >
                              Status
                            </p>
                          </div>
                        </Grid>
                        <Grid item md={2}>
                          <div
                            className={styles.devicesListItemTitleTextWrapper}
                          >
                            <p
                              className={
                                styles.devicesListItemTitle + " supportText "
                              }
                            >
                              No of Devices
                            </p>
                          </div>
                        </Grid>
                        <Grid item md={2}>
                          <div
                            className={styles.devicesListItemTitleTextWrapper}
                          >
                            <p
                              className={
                                styles.devicesListItemTitle + " supportText "
                              }
                            >
                              Date
                            </p>
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                    {/*  */}
                    <div className={styles.devicesListTableContent}>
                      {deployDetails.map((deployment) => {
                        return (
                          <DeploymentList
                            deployment={deployment.deploy}
                            orgName={orgName}
                            workspaceName={deployment.workspaceName}
                            release={deployment.releaseVersion}
                            createdAt={deployment.createdAt}
                          />
                        );
                      })}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DeploymentsDetails;
