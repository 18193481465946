import React from "react";
import { Grid, List, ListItem } from "@material-ui/core";
import styles from "../../../assets/styles/devices.module.scss";
import { Skeleton } from "@material-ui/lab";

export default function Shimmer(props) {
  return (
    <>
      {props.type == "DeviceLength" && <h2 className={styles.devicesPageTitle + " subheading "}>Devices</h2>}

      {props.type != "DeviceLength" && (
        <div className={styles.devicesListWrapper}>
          <div className={styles.devicesList}>
            <div className={styles.devicesListItemWrapper}>
              <div className={styles.devicesListItemTitleWrapper}>
                <Grid container spacing={2}>
                  <Grid item md={3}>
                    <div className={styles.devicesListItemTitleTextWrapper}>
                      <p className={styles.devicesListItemTitle + " supportText "}>Device Name</p>
                    </div>
                  </Grid>
                  <Grid item md={3}>
                    <div className={styles.devicesListItemTitleTextWrapper}>
                      <p
                        className={styles.devicesListItemTitle + " supportText "}
                        style={{ marginLeft: "50px" }}
                      >
                        Room
                      </p>
                    </div>
                  </Grid>
                  <Grid item md={3}>
                    <div className={styles.devicesListItemTitleTextWrapper}>
                      <p
                        className={styles.devicesListItemTitle + " supportText "}
                        style={{ marginLeft: "40px" }}
                      >
                        MAC ID
                      </p>
                    </div>
                  </Grid>
                  <Grid item md={3}>
                    <div className={styles.devicesListItemTitleTextWrapper}>
                      <p
                        className={styles.devicesListItemTitle + " supportText "}
                        style={{ marginLeft: "70px" }}
                      >
                        Subscription Period
                      </p>
                    </div>
                  </Grid>
                </Grid>
              </div>
              <div className={styles.devicesListTableContent}>
                {props.type == "DeviceList" &&
                  [...Array(9).keys()].map((item, key) => (
                    <div className={styles.devicesListTableRow}>
                      <Grid container>
                        <Grid item md={6}>
                          <Grid container spacing={2}>
                            <Grid item md={6}>
                              <div className={styles.devicesContentWrapper}>
                                <div className={styles.devicePath}>
                                  <Skeleton
                                    variant="circle"
                                    width={32}
                                    height={32}
                                    style={{
                                      background: "rgba(0, 0, 0, 0.11)",
                                      position: "absolute",
                                      left: 8,
                                      top: 5,
                                    }}
                                    className="DeviceListStyleImg"
                                  />
                                </div>
                                <div className={styles.devicesContentWrapper}>
                                  <Skeleton
                                    variant="text"
                                    width="75%"
                                    height={40}
                                    style={{
                                      background: "rgba(0, 0, 0, 0.11)",
                                      marginBottom: 0,
                                      marginLeft: 60,
                                      marginTop: 0,
                                    }}
                                    className="DeviceListStyleText"
                                  />
                                </div>
                              </div>
                            </Grid>
                            <Grid item md={6}>
                              <div className={styles.devicesContentWrapper}>
                                <Skeleton
                                  variant="text"
                                  width="75%"
                                  height={40}
                                  style={{
                                    background: "rgba(0, 0, 0, 0.11)",
                                    marginBottom: 0,
                                    marginLeft: 43,
                                    marginTop: -5,
                                  }}
                                  className="DeviceListStyleText"
                                />
                              </div>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item md={6}>
                          <Grid container spacing={2}>
                            <Grid item md={7}>
                              <div className={styles.devicesContentWrapper}>
                                <Skeleton
                                  variant="text"
                                  width="75%"
                                  height={40}
                                  style={{
                                    background: "rgba(0, 0, 0, 0.11)",
                                    marginBottom: 0,
                                    marginLeft: 43,
                                    marginTop: -5,
                                  }}
                                  className="DeviceListStyleText"
                                />
                              </div>
                            </Grid>
                            <Grid item md={5}>
                              <div className={styles.devicesContentWrapper}>
                                <Skeleton
                                  variant="text"
                                  width="75%"
                                  height={40}
                                  style={{
                                    background: "rgba(0, 0, 0, 0.11)",
                                    marginBottom: 0,
                                    marginLeft: 43,
                                    marginTop: -5,
                                  }}
                                  className="DeviceListStyleText"
                                />
                              </div>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      )}

      {props.type == "deviceDetails" && (
        <>
          {[0].map((item, key) => (
            <React.Fragment key={key}>
              <div className={styles.deviceDetailsWrapper}>
                <div className={styles.backBtnWrapper}>
                  <Skeleton
                    variant="text"
                    width={150}
                    height={12}
                    style={{
                      display: "block",
                      transform: "none",
                      marginBottom: 10,
                    }}
                  />
                </div>
                <div className={styles.deviceDetailsBlk}>
                  <Grid container>
                    <Grid item md={12}>
                      <Grid container>
                        <Grid item md={7}>
                          <div className={styles.deviceDetails}>
                            <div className={styles.deviceDetailsOuterBlk}>
                              <div className={styles.deviceDetailsImg}>
                                <Skeleton
                                  variant="circle"
                                  width={100}
                                  height={100}
                                  style={{
                                    display: "block",
                                    verticalAlign: "middle",
                                    transform: "none",
                                    textAlign: "center",
                                    justifyContent: "center",
                                  }}
                                />
                                <div className={styles.deviceStatus}></div>
                              </div>

                              <div className={styles.deviceDetailsInnerBlk}>
                                <div className={styles.deviceName + " subheading "}>
                                  <Skeleton
                                    variant="text"
                                    width={150}
                                    height={18}
                                    style={{
                                      display: "block",
                                      verticalAlign: "middle",
                                      transform: "none",
                                      marginTop: -10,
                                      marginBottom: 10,
                                    }}
                                  />
                                </div>
                                <div className={styles.deviceDetailsInnerRow}>
                                  <Grid container spacing={2}>
                                    <Grid item md={4}>
                                      <div className={styles.deviceDetailsLeftBlk}>
                                        <p className={styles.deviceDetailsLeftText + " supportText "}>
                                          MAC Id
                                        </p>
                                      </div>
                                    </Grid>
                                    <Grid item md={8}>
                                      <div className={styles.deviceDetailsRightBlk}>
                                        <div className={styles.deviceDetailsMacID + " paragraph "}>
                                          <Skeleton
                                            variant="text"
                                            width={150}
                                            height={12}
                                            style={{
                                              display: "block",
                                              verticalAlign: "middle",
                                              transform: "none",
                                              marginBottom: 10,
                                            }}
                                          />
                                        </div>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={styles.deviceDetailsInnerRow}>
                                  <Grid container spacing={2}>
                                    <Grid item md={4}>
                                      <div className={styles.deviceDetailsLeftBlk}>
                                        <p className={styles.deviceDetailsRoomNameText + " supportText "}>
                                          Room name
                                        </p>
                                      </div>
                                    </Grid>
                                    <Grid item md={8}>
                                      <div className={styles.deviceDetailsRightBlk}>
                                        <div className={styles.deviceDetailsSelectRoom}>
                                          <Skeleton
                                            variant="text"
                                            width={150}
                                            height={12}
                                            style={{
                                              display: "block",
                                              verticalAlign: "middle",
                                              transform: "none",
                                              marginBottom: 10,
                                            }}
                                          />
                                        </div>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={styles.deviceDetailsInnerRow}>
                                  <Grid container spacing={2}>
                                    <Grid item md={4}>
                                      <div className={styles.deviceDetailsLeftBlk}>
                                        <p className={styles.deviceDetailsLeftText + " supportText "}>
                                          Status
                                        </p>
                                      </div>
                                    </Grid>
                                    <Grid item md={8}>
                                      <div className={styles.deviceDetailsRightBlk}>
                                        <div className={styles.deviceStatusSuccess + "paragraph"}>
                                          <Skeleton
                                            variant="text"
                                            width={150}
                                            height={12}
                                            style={{
                                              display: "block",
                                              verticalAlign: "middle",
                                              transform: "none",
                                              marginBottom: 10,
                                            }}
                                          />
                                        </div>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={styles.deviceDetailsInnerRow}>
                                  <Grid container spacing={2}>
                                    <Grid item md={4}>
                                      <div className={styles.deviceDetailsLeftBlk}>
                                        <p className={styles.deviceDetailsLeftText + " supportText "}>
                                          Subscription Period
                                        </p>
                                      </div>
                                    </Grid>
                                    <Grid item md={8}>
                                      <div className={styles.deviceDetailsRightBlk}>
                                        <div className={styles.deviceDetailsMacID + " paragraph "}>
                                          <Skeleton
                                            variant="text"
                                            width={150}
                                            height={12}
                                            style={{
                                              display: "block",
                                              verticalAlign: "middle",
                                              transform: "none",
                                              marginBottom: 10,
                                            }}
                                          />
                                        </div>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={styles.deviceDetailsInnerRow}>
                                  <Grid container spacing={2}>
                                    <Grid item md={4}>
                                      <div className={styles.deviceDetailsLeftBlk}>
                                        <p className={styles.deviceDetailsLeftText + " supportText "}>
                                          Firmware Version
                                        </p>
                                      </div>
                                    </Grid>
                                    <Grid item md={8}>
                                      <div className={styles.deviceDetailsRightBlk}>
                                        <div className={styles.deviceDetailsFirmwareVersion + " paragraph "}>
                                          <Skeleton
                                            variant="text"
                                            width={150}
                                            height={12}
                                            style={{
                                              display: "block",
                                              verticalAlign: "middle",
                                              transform: "none",
                                              marginBottom: 10,
                                            }}
                                          />
                                        </div>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                              </div>
                            </div>
                            <div className={styles.deviceSettings}>
                              <div className={styles.deviceSettingsWrapper}>
                                <div className={styles.deviceSettingsHeading}>
                                  <h3 className={styles.deviceSettingsTitle + " paragraph "}>
                                    Device Settings
                                  </h3>
                                </div>
                                {[0, 1, 2].map((item, key) => (
                                  <React.Fragment key={key}>
                                    {" "}
                                    <div className={styles.deviceSettingsRow}>
                                      <Grid container spacing={2}>
                                        <Grid item md={7}>
                                          <div className={styles.deviceSettingsLeftBlk}>
                                            <div className={styles.deviceSettingsLeftTitle + " supportText "}>
                                              <Skeleton
                                                variant="text"
                                                width={130}
                                                height={12}
                                                style={{
                                                  display: "block",
                                                  verticalAlign: "middle",
                                                  transform: "none",
                                                  marginBottom: 10,
                                                }}
                                              />
                                            </div>
                                            <div
                                              className={styles.deviceSettingsLeftSubText + " supportText "}
                                            >
                                              <Skeleton
                                                variant="text"
                                                width={200}
                                                height={12}
                                                style={{
                                                  display: "block",
                                                  verticalAlign: "middle",
                                                  transform: "none",
                                                  marginBottom: 10,
                                                }}
                                              />
                                            </div>
                                          </div>
                                        </Grid>
                                        <Grid item md={5}>
                                          <div className={styles.deviceSettingsRightBlk}>
                                            <Skeleton
                                              variant="text"
                                              width={150}
                                              height={30}
                                              style={{
                                                display: "block",
                                                verticalAlign: "middle",
                                                transform: "none",
                                                marginBottom: 10,
                                              }}
                                            />
                                          </div>
                                        </Grid>
                                      </Grid>
                                    </div>
                                  </React.Fragment>
                                ))}
                              </div>
                            </div>
                          </div>
                        </Grid>
                        <Grid item md={5}>
                          <div className={styles.deviceWorkspaceWrapper}>
                            <div className={styles.deviceWorkspaceDetails}>
                              <div className={styles.workspaceDetailsHeading}>
                                <h3 className={styles.workspaceDetailsTitle + " paragraph "}>
                                  Workspace Details
                                </h3>
                              </div>
                              <div className={styles.workspaceDetailsRow}>
                                <Grid container spacing={2}>
                                  <Grid item md={5}>
                                    <div className={styles.workspaceDetailsLeftBlk}>
                                      <p className={styles.workspaceDetailsText + " supportText "}>
                                        Operational Workspace
                                      </p>
                                    </div>
                                  </Grid>
                                  <Grid item md={7}>
                                    <div className={styles.workspaceDetailsRightBlk}>
                                      <div className={styles.workspaceIconWrapper}></div>
                                      <div className={styles.workspaceDetailsRightText + " paragraph "}>
                                        <Skeleton
                                          variant="text"
                                          width={150}
                                          height={12}
                                          style={{
                                            display: "block",
                                            verticalAlign: "middle",
                                            transform: "none",
                                            marginBottom: 10,
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={styles.workspaceDetailsRow}>
                                <Grid container spacing={2}>
                                  <Grid item md={5}>
                                    <div className={styles.workspaceDetailsLeftBlk}>
                                      <p className={styles.workspaceDetailsText + " supportText "}>
                                        Local Admins
                                      </p>
                                    </div>
                                  </Grid>
                                  <Grid item md={7}>
                                    <div className={styles.workspaceDetailsRightBlk}>
                                      <div className={styles.workspaceDetailsAdminList}>
                                        <List>
                                          {[0, 1, 2].map((item, key) => {
                                            return (
                                              <ListItem className={styles.workspaceIconWrapper} key={key}>
                                                <div
                                                  className={styles.workspaceDetailsRightText + " paragraph "}
                                                >
                                                  <Skeleton
                                                    variant="text"
                                                    width={150}
                                                    height={12}
                                                    style={{
                                                      display: "block",
                                                      verticalAlign: "middle",
                                                      transform: "none",
                                                      marginBottom: 10,
                                                    }}
                                                  />
                                                </div>
                                              </ListItem>
                                            );
                                          })}
                                        </List>
                                      </div>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                            </div>
                          </div>
                          <div className={styles.deviceRoomDetailsWrapper}>
                            <div className={styles.deviceRoomDetails}>
                              <div className={styles.roomDetailsHeading}>
                                <h3 className={styles.roomDetailsTitle + " paragraph "}>Room Details</h3>
                              </div>
                              <div className={styles.roomDetailsRow}>
                                <Grid container spacing={2}>
                                  <Grid item md={5}>
                                    <div className={styles.roomDetailsLeftBlk}>
                                      <p className={styles.roomDetailsText + " supportText "}>
                                        Seating Capacity
                                      </p>
                                    </div>
                                  </Grid>
                                  <Grid item md={7}>
                                    <div className={styles.roomDetailsRightBlk}>
                                      <div className={styles.roomDetailsSeatingCapacity + " paragraph "}>
                                        <Skeleton
                                          variant="text"
                                          width={150}
                                          height={12}
                                          style={{
                                            display: "block",
                                            verticalAlign: "middle",
                                            transform: "none",
                                            marginBottom: 10,
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={styles.roomDetailsRow}>
                                <Grid container spacing={2}>
                                  <Grid item md={5}>
                                    <div className={styles.roomDetailsLeftBlk}>
                                      <p className={styles.roomDetailsText + " supportText "}>
                                        Other Devices
                                      </p>
                                    </div>
                                  </Grid>
                                  <Grid item md={7}>
                                    <div className={styles.roomDetailsRightBlk}>
                                      <div className={styles.roomDetailsDeviceList}>
                                        <List>
                                          {[0, 1, 2].map((item, key) => {
                                            return (
                                              <ListItem className={styles.roomDetailsIconWrapper}>
                                                <p className={styles.roomDetailsLeftText + " paragraph "}>
                                                  <Skeleton
                                                    variant="text"
                                                    width={150}
                                                    height={12}
                                                    style={{
                                                      display: "block",
                                                      verticalAlign: "middle",
                                                      transform: "none",
                                                      marginBottom: 10,
                                                    }}
                                                  />
                                                </p>
                                              </ListItem>
                                            );
                                          })}
                                        </List>
                                      </div>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                            </div>
                          </div>
                        </Grid>{" "}
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </React.Fragment>
          ))}
        </>
      )}
    </>
  );
}
