import React from "react";
import { Grid, List, ListItem } from "@material-ui/core";
import styles from "../../../assets/styles/orders.module.scss";
import { Skeleton } from "@material-ui/lab";
import { imageBasePath } from "../../../constants";

export default function Shimmer(props) {
  return (
    <>
      {props.type === "orderLength" && (
        <div className={styles.orderHeading}>
          <h2 className={styles.orderTitle + " subheading "}>Orders </h2>
        </div>
      )}
      {props.type === "orderSearch" && (
        <div className={styles.orderSearchBlk}>
          <Grid container direction="row" justifyContent="flex-end" alignItems="center">
            <Grid item md={4} className={styles.orderSearchWrapper}>
              <div className={styles.orderSearchInput}></div>
            </Grid>
          </Grid>
        </div>
      )}
      {props.type === "orderHeader" && (
        <div className={styles.ordersListTableTitle}>
          <Grid container spacing={2}>
            <Grid item md={4}>
              <Grid container direction="row" spacing={2} justifyContent="flex-start" alignItems="center">
                <Grid item md={8}>
                  <div className={styles.ordersTableTitleTextWrapper}>
                    <p className={styles.ordersTableTitleText + " paragraph "}>Order No:</p>
                  </div>
                </Grid>
                <Grid item md={4}>
                  <div className={styles.ordersTableTitleTextWrapper}>
                    <p className={styles.ordersTableTitleText + " paragraph "}>Order Date</p>
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={2}>
              <Grid container direction="row" spacing={2} justifyContent="flex-start" alignItems="center">
                <Grid item md={4}>
                  <div className={styles.ordersTableTitleTextWrapper}>
                    <p className={styles.ordersTableTitleText + " paragraph "}>Units</p>
                  </div>
                </Grid>
                <Grid item md={8}>
                  <div className={styles.ordersTableTitleTextWrapper}>
                    <p className={styles.ordersTableTitleText + " paragraph "}>Subscription</p>
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={6}>
              <Grid container direction="row" spacing={2} justifyContent="flex-end" alignItems="center">
                <Grid item md={4}>
                  <div className={styles.ordersTableTitleTextWrapper}>
                    <p className={styles.ordersTableTitleText + " paragraph "}>Payment Status</p>
                  </div>
                </Grid>
                <Grid item md={4}>
                  <div className={styles.ordersTableTitleTextWrapper}>
                    <p className={styles.ordersTableTitleText + " paragraph "}>Shipment In Progress</p>
                  </div>
                </Grid>
                <Grid item md={4}>
                  <div className={styles.ordersTableTitleTextWrapper}>
                    <p className={styles.ordersTableTitleText + " paragraph "}>Shipment Fulfilled</p>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      )}
      {props.type == "singleOrderList" && (
        <>
          {[0, 1, 2, 3, 4, 5, 6, 7, 8].map((item, key) => (
            <div className={styles.orderListItem} key={key}>
              <div className={styles.ordersListTableRow}>
                <Grid container spacing={2} justifyContent="center" alignItems="center">
                  <Grid item md={4}>
                    <Grid
                      container
                      direction="row"
                      spacing={2}
                      justifyContent="flex-start"
                      alignItems="center"
                    >
                      <Grid item md={8}>
                        <div className={styles.orderContentWrapper}>
                          <div className={styles.orderImageWrapper}>
                            <Skeleton
                              variant="circle"
                              width={40}
                              height={40}
                              style={{
                                display: "block",
                                verticalAlign: "middle",
                                transform: "none",
                                marginTop: 8,
                              }}
                            />
                          </div>
                          <div className={styles.orderDetailWrapper}>
                            <div className={styles.quoteNum + " paragraphSemiBold "}>
                              <Skeleton
                                variant="text"
                                width={130}
                                height={25}
                                style={{
                                  display: "block",
                                  verticalAlign: "middle",
                                  transform: "none",
                                  // marginBottom: 10,
                                }}
                              />
                            </div>
                            <div className={styles.companyName + " paragraphSemiBold "}>
                              <Skeleton
                                variant="text"
                                width={130}
                                height={25}
                                style={{
                                  display: "block",
                                  verticalAlign: "middle",
                                  transform: "none",
                                  marginTop: 10,
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </Grid>
                      <Grid item md={4}>
                        <div className={styles.orderContentWrapper}>
                          <div className={styles.requestedOn + " paragraph "}>
                            <Skeleton
                              variant="text"
                              width={80}
                              height={25}
                              style={{
                                display: "block",
                                verticalAlign: "middle",
                                transform: "none",
                                // marginBottom: 10,
                              }}
                            />
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item md={2}>
                    <Grid
                      container
                      direction="row"
                      spacing={2}
                      justifyContent="flex-start"
                      alignItems="center"
                    >
                      <Grid item md={4}>
                        <div className={styles.orderContentWrapper}>
                          <div className={styles.unitsCount + " paragraph "}>
                            <Skeleton
                              variant="text"
                              width={40}
                              height={25}
                              style={{
                                display: "block",
                                verticalAlign: "middle",
                                transform: "none",
                                // marginBottom: 10,
                              }}
                            />
                          </div>
                        </div>
                      </Grid>
                      <Grid item md={8}>
                        <div className={styles.orderContentWrapper}>
                          <div className={styles.unitsCount + " paragraph "}>
                            <Skeleton
                              variant="text"
                              width={90}
                              height={25}
                              style={{
                                display: "block",
                                verticalAlign: "middle",
                                transform: "none",
                                // marginBottom: 10,
                              }}
                            />
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item md={6}>
                    <Grid
                      container
                      direction="row"
                      spacing={2}
                      justifyContent="flex-start"
                      alignItems="center"
                    >
                      <Grid item md={4}>
                        <div className={styles.orderContentWrapper}>
                          <div className={styles.orderStatus + " paragraph "}>
                            <Skeleton
                              variant="text"
                              width={105}
                              height={25}
                              style={{
                                display: "block",
                                verticalAlign: "middle",
                                transform: "none",
                                // marginBottom: 10,
                              }}
                            />
                          </div>
                        </div>
                      </Grid>
                      <Grid item md={4}>
                        <div className={styles.orderContentWrapper}>
                          <div className={styles.unitsCount + " paragraph "}>
                            <Skeleton
                              variant="text"
                              width={120}
                              height={25}
                              style={{
                                display: "block",
                                verticalAlign: "middle",
                                transform: "none",
                                // marginBottom: 10,
                              }}
                            />
                          </div>
                        </div>
                      </Grid>
                      <Grid item md={4}>
                        <div className={styles.orderContentWrapper}>
                          <div className={styles.unitsCount + " paragraph "}>
                            <Skeleton
                              variant="text"
                              width={120}
                              height={25}
                              style={{
                                display: "block",
                                verticalAlign: "middle",
                                transform: "none",
                                // marginBottom: 10,
                              }}
                            />
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </div>
          ))}
        </>
      )}
      {props.type == "orderDetails" && (
        <>
          {[0].map((item, key) => (
            <React.Fragment key={key}>
              <Grid container justifyContent="center" alignItems="center">
                <Grid item md={12}>
                  <div className={styles.orderRightDrawer}>
                    <div className={styles.orderDetailWrapper}>
                      <div className={styles.orderDetailBlk}>
                        <div className={styles.orderLeftBlk}>
                          <div className={styles.orderImageWrapper}>
                            <Skeleton
                              variant="circle"
                              width={40}
                              height={40}
                              style={{
                                display: "block",
                                verticalAlign: "middle",
                                transform: "none",
                                marginBottom: 16,
                                marginTop: 20,
                                marginLeft: 5,
                              }}
                            />
                          </div>
                          <div className={styles.quoteNumber + " paragraphSemiBold "}>
                            <Skeleton
                              variant="text"
                              width={200}
                              height={15}
                              style={{
                                display: "block",
                                verticalAlign: "middle",
                                transform: "none",
                                marginBottom: 10,
                                marginLeft: 600,
                              }}
                            />
                          </div>
                        </div>
                        <div className={styles.orderRightBlk}>
                          <div
                            className={styles.userDetailDownloadLink + " paragraphSemiBold"}
                            text="Download invoice"
                            leftIcon={imageBasePath + "download_invoice.svg"}
                          />
                        </div>
                      </div>
                      <Grid
                        container
                        direction="row"
                        spacing={2}
                        justifyContent="center"
                        alignItems="flex-start"
                      >
                        <Grid item md={2}>
                          <List className={styles.orderDetailTitles}>
                            <ListItem>Order Placed On</ListItem>
                            <ListItem>Quantity</ListItem>
                            <ListItem>Subcription Period</ListItem>
                          </List>
                        </Grid>
                        <Grid item md={4}>
                          <List className={styles.orderDetailDescriptions}>
                            <ListItem>
                              <Skeleton
                                variant="text"
                                width={150}
                                height={15}
                                style={{
                                  display: "block",
                                  verticalAlign: "middle",
                                  transform: "none",
                                  marginTop: 2,
                                }}
                              />
                            </ListItem>
                            <ListItem>
                              <Skeleton
                                variant="text"
                                width={150}
                                height={15}
                                style={{
                                  display: "block",
                                  verticalAlign: "middle",
                                  transform: "none",
                                  marginTop: 5,
                                }}
                              />
                            </ListItem>
                            <ListItem>
                              <Skeleton
                                variant="text"
                                width={150}
                                height={15}
                                style={{
                                  display: "block",
                                  verticalAlign: "middle",
                                  transform: "none",
                                  marginTop: 5,
                                }}
                              />
                            </ListItem>
                          </List>
                        </Grid>
                        <Grid item md={2}>
                          <List className={styles.orderDetailTitles}>
                            <ListItem>Billed To</ListItem>
                            <ListItem>Partner</ListItem>
                            <ListItem>Delivery Address</ListItem>
                          </List>
                        </Grid>
                        <Grid item md={4}>
                          <List className={styles.orderDetailDescriptions}>
                            <ListItem>
                              <Skeleton
                                variant="text"
                                width={150}
                                height={15}
                                style={{
                                  display: "block",
                                  verticalAlign: "middle",
                                  transform: "none",
                                  marginTop: 2,
                                }}
                              />
                            </ListItem>
                            <ListItem>
                              <Skeleton
                                variant="text"
                                width={150}
                                height={15}
                                style={{
                                  display: "block",
                                  verticalAlign: "middle",
                                  transform: "none",
                                  marginTop: 5,
                                }}
                              />
                            </ListItem>
                            <ListItem>
                              <Skeleton
                                variant="text"
                                width={150}
                                height={15}
                                style={{
                                  display: "block",
                                  verticalAlign: "middle",
                                  transform: "none",
                                  marginTop: 5,
                                }}
                              />
                            </ListItem>
                          </List>
                        </Grid>
                      </Grid>
                    </div>
                    <div className={styles.paymentDetailWrapper}>
                      <div className={styles.paymentDetailHeader}>
                        <div className={styles.paymentDetailTitle + " subheading2"}>
                          Payment Details
                          <div className={styles.paymentDetailDate}>
                            <Skeleton
                              variant="text"
                              width={150}
                              height={15}
                              style={{
                                display: "block",
                                verticalAlign: "middle",
                                transform: "none",
                                marginBottom: 10,
                                marginTop: -20,
                                marginLeft: 160,
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className={styles.paymentAdminUpdate}>
                        <div className={styles.shipmentBlkHeader + " paragraph"}>
                          <Skeleton
                            variant="text"
                            width={30}
                            height={30}
                            style={{
                              display: "block",
                              verticalAlign: "middle",
                              transform: "none",
                              marginBottom: 30,
                              marginTop: -2,
                            }}
                          />
                        </div>
                        <Skeleton
                          variant="text"
                          width={120}
                          height={15}
                          style={{
                            display: "block",
                            verticalAlign: "middle",
                            transform: "none",
                            marginBottom: 30,
                            marginTop: 5,
                            marginRight: 80,
                          }}
                        />
                        <div
                          className={styles.shipmentBlkHeader + " paragraph"}
                          style={{ marginLeft: "100px" }}
                        >
                          Next Payment Due Date
                        </div>
                        <Skeleton
                          variant="text"
                          width={120}
                          height={15}
                          style={{
                            display: "block",
                            verticalAlign: "middle",
                            transform: "none",
                            marginBottom: 30,
                            marginTop: 5,
                            marginRight: 80,
                          }}
                        />
                      </div>
                    </div>

                    <div className={styles.shipmentDetailWrapper}>
                      <div className={styles.shipmentDetailHeader}>
                        <div className={styles.shipmentDetailTitle + " subheading2"}>Shipment Details</div>
                      </div>
                      {[0, 1, 2, 3, 4].map((item, key) => {
                        return (
                          <div className={styles.shipmentUserSection} key={key}>
                            <Grid
                              container
                              direction="row"
                              spacing={2}
                              justifyContent="center"
                              alignItems="center"
                            >
                              <Grid item md={1}>
                                <div className={styles.shipmentImageWrapper}>
                                  <Skeleton
                                    variant="circle"
                                    width={40}
                                    height={40}
                                    style={{
                                      display: "block",
                                      verticalAlign: "middle",
                                      transform: "none",
                                      marginBottom: 16,
                                      marginTop: 20,
                                      marginLeft: 5,
                                    }}
                                  />
                                </div>
                              </Grid>
                              <Grid item md={3}>
                                <List>
                                  <ListItem className={styles.shipmentBlkHeader + " paragraph"}>
                                    Shipment No:
                                  </ListItem>
                                  <ListItem className={styles.shipmentBlkDescription + " paragraph"}>
                                    <Skeleton
                                      variant="text"
                                      width={150}
                                      height={25}
                                      style={{
                                        display: "block",
                                        verticalAlign: "middle",
                                        transform: "none",
                                        marginBottom: 10,
                                      }}
                                    />
                                  </ListItem>
                                </List>
                              </Grid>
                              <Grid item md={1}>
                                <List>
                                  <ListItem className={styles.shipmentBlkHeader + " paragraph"}>
                                    Units
                                  </ListItem>
                                  <ListItem className={styles.shipmentBlkDescription + " paragraph"}>
                                    <Skeleton
                                      variant="text"
                                      width={80}
                                      height={25}
                                      style={{
                                        display: "block",
                                        verticalAlign: "middle",
                                        transform: "none",
                                        marginBottom: 10,
                                      }}
                                    />
                                  </ListItem>
                                </List>
                              </Grid>
                              <Grid item md={3}>
                                <List>
                                  <ListItem className={styles.shipmentBlkHeader + " paragraph"}>
                                    Expected Delivery Date
                                  </ListItem>
                                  <ListItem className={styles.shipmentBlkDescription + " paragraph"}>
                                    <Skeleton
                                      variant="text"
                                      width={150}
                                      height={25}
                                      style={{
                                        display: "block",
                                        verticalAlign: "middle",
                                        transform: "none",
                                        marginBottom: 10,
                                      }}
                                    />
                                  </ListItem>
                                </List>
                              </Grid>
                              <Grid item md={2}>
                                <List>
                                  <ListItem className={styles.shipmentBlkHeader + " paragraph"}>
                                    Current Status
                                  </ListItem>
                                  <ListItem className={styles.shipmentBlkDescription + " paragraph"}>
                                    <Skeleton
                                      variant="text"
                                      width={150}
                                      height={25}
                                      style={{
                                        display: "block",
                                        verticalAlign: "middle",
                                        transform: "none",
                                        marginBottom: 10,
                                      }}
                                    />
                                  </ListItem>
                                </List>
                              </Grid>
                              <Grid item md={2}>
                                <List>
                                  <ListItem className={styles.shipmentBlkHeader + " paragraph"}>
                                    Tracking Link
                                  </ListItem>
                                  <ListItem className={styles.shipmentBlkDescription + " paragraph"}>
                                    <Skeleton
                                      variant="text"
                                      width={150}
                                      height={25}
                                      style={{
                                        display: "block",
                                        verticalAlign: "middle",
                                        transform: "none",
                                        marginBottom: 10,
                                      }}
                                    />
                                  </ListItem>
                                </List>
                              </Grid>
                            </Grid>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </Grid>
              </Grid>
            </React.Fragment>
          ))}
        </>
      )}
    </>
  );
}
