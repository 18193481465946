import React, { useState } from 'react';
import { Container ,Grid} from '@material-ui/core';
import { imageBasePath }  from '../../../constants';
import styles from '../../../assets/styles/purchase.module.scss';


const boxImages = [
    {
        img: `./images/zoom-text-logo.svg`,
        alt: 'zoom_logo',
        style: {
            padding: "10px 63.5px",
            width: "125px",
            height: "58px",
            boxSizing: "content-box"
        }
    },
    {
        img: `${imageBasePath}mircrosoft_teams.png`,
        alt: 'mircrosoft_teams_logo'
    },
    {
        img: `${imageBasePath}googlemeet.png`,
        alt: 'googlemeet_logo'
    },
    {
        img: `./images/webex.png`,
        alt: 'webex_logo'
    },
    {
        img: `${imageBasePath}logitech.png`,
        alt: 'logitech_logo'
    },
    {
        img: `${imageBasePath}poly.png`,
        alt: 'poly_logo'
    },
    {
        img: `${imageBasePath}cisco.png`,
        alt: 'cisco_logo'
    },
    {
        img: `${imageBasePath}aver.png`,
        alt: 'aver_logo'
    },
]


export default function BoxSupport() {
    return (
        <section className={styles.BoxSupport}>
            <Container maxWidth="lg">
                <h3 className={styles.BoxSupportTitle+" heading6"}>Seamless and Compatible</h3>
                <h6 className={styles.BoxSupportSubTitle+" paragraph"}>Collaborate productively on your favoured meeting platform using any OEM camera</h6>
                <Grid container direction="row" spacing={2} justifyContent="center" alignItems="center">
                    {
                        boxImages.map((item, key) => 
                        <Grid key={key} item md={3} >
                            <img className={styles.BoxImage} src={item.img} alt={item.alt} style={item.style}/>
                        </Grid>
                        )
                    }
                </Grid>
            </Container>
        </section>
    )
}
