import React from "react";
import { Grid, List, ListItem} from "@material-ui/core";
import styles from "../../../../assets/styles/admin/partner.module.scss";
import { imageBasePath } from "../../../../constants";
import { CustomLink } from "../../../../shared_ui_components";
import { PartnerDetailCard } from "../components";

export default function PartnerDetails(props) {
    return (
        <div className={styles.partnerDetails}>
            <div className={styles.backBtnWrapper}>
                <CustomLink
                    className="backBtn"
                    text="Back"
                    leftIcon={imageBasePath + "arrow_left_icon.svg"}
                    to="/partners"
                />
            </div>
            <div className={styles.partnerDetails}>
                <Grid container>
                    <Grid item md={4}>
                        <div className={styles.partnerDetailsNameWrapper}>
                            <List>
                                <ListItem>
                                    <img src={imageBasePath + "partner_profile_42.svg"} alt=""/>
                                </ListItem>
                                <ListItem>
                                    <p className={styles.partnerName + " subheading "}>
                                        Pavan Kalyan
                                    </p>
                                </ListItem>
                                <ListItem>
                                    <span className="paragraph">
                                        <img src={imageBasePath + "email_icon.svg"} alt="" />
                                        pavan@welpro.com
                                    </span>
                                </ListItem>
                                <ListItem>
                                    <span className="paragraph">
                                        <img src={imageBasePath + "phone_icon.svg"} alt="" />
                                        +91 68326 82623
                                    </span>
                                </ListItem>
                            </List>
                        </div>
                    </Grid>
                    <Grid item container md={8}>
                        <Grid item container md={12}>
                            <Grid item md={5}>
                                <p className={styles.partnerTitle + " label"}>Partner Company</p>
                            </Grid>
                            <Grid item md={7}>
                                <div className={styles.partnerDetailNameWrapper}>
                                    <img src={imageBasePath + "organisation_logo.svg"} alt="" />
                                    <p className={styles.partnerDetailName + " label"}>Welpro Private Limited</p>
                                </div>
                            </Grid>
                        </Grid>
                        <Grid item container md={12}>
                            <Grid item md={5}>
                                <p className={styles.partnerTitle + " label"}>Registered Address</p>
                            </Grid>
                            <Grid item md={7}>
                                <p className={styles.partnerDetailName + " label"}>1st Floor, A P Complex, 65/6, Agara, Sarjapur Main Road, Bengaluru, Karnataka 560102</p>
                            </Grid>
                        </Grid>
                        <Grid item container md={12}>
                            <Grid item md={5}>
                                <p className={styles.partnerTitle + " label"}>PAN Number</p>
                            </Grid>
                            <Grid item md={7}>
                                <p className={styles.partnerDetailName + " label"}>ALWPG5809L</p>
                            </Grid>
                        </Grid>
                        <Grid item container md={12}>
                            <Grid item md={5}>
                                <p className={styles.partnerTitle + " label"}>GST Number</p>
                            </Grid>
                            <Grid item md={7}>
                                <p className={styles.partnerDetailName + " label"}>22AABCU9603R1ZX</p>
                            </Grid>
                        </Grid>
                        <Grid item container md={12}>
                            <Grid item md={5}>
                                <p className={styles.partnerTitle + " label"}>TIN Number</p>
                            </Grid>
                            <Grid item md={7}>
                                <p className={styles.partnerDetailName + " label"}>123 456 789 000</p>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
            <div className={styles.partnerDetailsCardWrapper}>
                <PartnerDetailCard />
            </div>
        </div>
    )
}
