import React, { useState } from 'react';
import styles from "../../../../assets/styles/admin/reviewQuote.module.scss";
import { Grid, Container, List, ListItem } from "@material-ui/core";
import { imageBasePath } from "../../../../constants";
import { CustomLink, SquareRadioButton, InputText } from "../../../../shared_ui_components";

const quoteSteps = [
    { label: '1', text: 'Order & Contact Details', step: 1 },
    { label: '2', text: 'Review Quote', step: 2 },
]
function ReviewQuoteNavigation(props) {
    const { step } = props
    return (
        <Container maxWidth="lg">
            <Grid item md={12}>
                <div className={styles.quoteNavigationWrapper}>
                    <div className={styles.backBtnWrapper}>
                        <CustomLink
                            to="/enquiries"
                            className={" customLink" + " paragraph"}
                            text="Back to Enquiries"
                            leftIcon={imageBasePath + "arrow_left_icon.svg"}
                        />
                    </div>
                    <List className={styles.quoteNavigationList + " listInline "}>
                        {
                            quoteSteps.map((quote) => <ListItem
                                className={`${styles.quoteNavigationListItem} paragraph listInlineItem ${quote.step <= step ? styles.active : ''}`}
                            >
                                <span className={styles.quoteNavigationListNumber}>{quote.label}</span>
                                {quote.text}
                            </ListItem>)
                        }
                    </List>
                </div>
            </Grid>
        </Container>
    );
}

export default ReviewQuoteNavigation;
