import React, { useState, useEffect } from "react";
import styles from "../../../assets/styles/workspaces.module.scss";
import { imageBasePath } from "../../../constants";
import { Tabs, Tab } from "@material-ui/core";
import AddResourceManually from "./AddResourcesManually";
import { LogOutDialog } from "../../../shared_elements";
import { getOrganisationName } from "../../request_quote/apiServices";

export default function AddResourceDialog(props) {
  const { getAllWorkspaceList, editWorkspace, handleCloseWorkSpaceDrawer } = props;
  const [open, setOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const [closeStatus, setCloseStatus] = useState(false);

  const handleSuccess = () => {
    setOpen(false);
    props.handleClose();
    setCloseStatus(false);
  };

  const handleCancel = () => {
    setOpen(true);
    setCloseStatus(false);
  };

  const handleClose = () => {
    // setOpen(false);
    // props.handleClose();
    setCloseStatus(true);
  };

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  useEffect(() => {
    if (editWorkspace) {
      setSelectedTab(0);
    }
  }, [editWorkspace]);

  // useEffect(() => {
  //   function handleBrowserClose(e) {
  //     let confirmationMessage = "";
  //     (e || window.event).returnValue = confirmationMessage; //Gecko + IE
  //     return confirmationMessage; //Gecko + Webkit, Safari, Chrome etc.
  //   }
  //   window.addEventListener("beforeunload", handleBrowserClose);
  //   return () => {
  //     window.removeEventListener("beforeunload", handleBrowserClose);
  //   };
  // });

  return (
    <div className={styles.addWorkspaceDialogWrapper}>
      <div className={styles.addWorkspaceTitleWrapper}>
        <div className={styles.addWorkspaceTitle}>
          <img src={imageBasePath + "add_organisation.svg"} alt="" />
          <p className={styles.addWorkspaceHeader + " subheading"}>
            {editWorkspace ? "Edit Resource" : "Add Resource"}
          </p>
        </div>
        <div className={styles.closeDialog}>
          <img onClick={handleClose} src={imageBasePath + "user_cancel.svg"} alt="" />
        </div>
      </div>
      <div className={styles.addWorkspaceTabWrapper}>
        <Tabs value={selectedTab} onChange={handleChange}>
          {/* <Tab label="Upload CSV" className={styles.uploadCSV + " label"} /> */}
          <Tab label="Google" className={styles.addManually + " label"} />
          <Tab label="Microsoft" className={styles.uploadCSV + " label"} />
        </Tabs>
      </div>
      {/* {selectedTab === 0 && (
        <div className={styles.addWorkspaceContent}>
          <img className={styles.WorkspacePicture} src={imageBasePath + "upload_default.svg"} alt="" />
          <p className={styles.WorkspaceLink}>
            Drag and drop or <span>Upload CSV</span>
          </p>
          <CustomLink
            className={styles.WorkspaceSample}
            text="Download sample file"
            leftIcon={imageBasePath + "file-excel-2-line.svg"}
          />
        </div>
      )} */}
      {selectedTab === 0 && (
        <AddResourceManually
          getAllWorkspaceList={getAllWorkspaceList}
          selectedTab={selectedTab}
          editWorkspace={editWorkspace}
          handleClose={handleClose}
          handleCloseWorkSpaceDrawer={handleCloseWorkSpaceDrawer}
        />
      )}
      {selectedTab === 1 && (
        <AddResourceManually
          getAllWorkspaceList={getAllWorkspaceList}
          selectedTab={selectedTab}
          editWorkspace={editWorkspace}
          handleClose={handleClose}
          handleCloseWorkSpaceDrawer={handleCloseWorkSpaceDrawer}
        />
      )}
      {closeStatus && (
        <LogOutDialog
          text="Are you sure you want to close? Changes that you made may not be saved."
          handleSuccess={handleSuccess}
          handleCancel={handleCancel}
        />
      )}
    </div>
  );
}
