import React from "react";
import { Avatar, Grid } from "@material-ui/core";
import styles from "../../../assets/styles/activityLog.module.scss";

import { imageBasePath } from "../../../constants";

export default function ActivityLogListItem(props) {
  return (
    <div className={styles.activityLogListItemWrapper}>
      {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item, key) => (
        <div className={styles.activityLogListItem}>
          <Grid container spacing={2}>
            <Grid item md={8}>
              <div className={styles.activityLogListItemTextWrapper}>
                <Avatar class="avatar">P</Avatar>
                <p className={styles.activityLogListItemText + " paragraph "}>
                  <span>Pavan Kalyan</span>
                  requested for a <span>new quote</span>
                </p>
              </div>
            </Grid>
            <Grid item md={4}>
              <p className={styles.activityLogTime + " paragraph "}>
                Yesterday, 12:30 pm
              </p>
            </Grid>
          </Grid>
        </div>
      ))}
    </div>
  );
}
