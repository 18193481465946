import React from 'react'
import PropTypes from "prop-types";


export default function SquareRadioButton(props) {
    const {text,checked ,type } = props
    return (
        <div className={`squareRadioButton ${checked ? 'active' : ''} paragraph ${type}`} {...props}>
            {text}
        </div>
    )
}


SquareRadioButton.propTypes = {
    text: PropTypes.string,
    checked: PropTypes.bool,
    type:PropTypes.oneOf(['largeButton', 'mediumButton','']),
  };
  
  SquareRadioButton.defaultProps = {
    text: "",
    checked: false,
    type:''
  };
  