import { Container, Grid } from "@material-ui/core";
import React, { useState } from "react";
import styles from "../../../assets/styles/accountSettings.module.scss";
import { AccountSettingsTabContent, AccountSettingsTabs } from "../components";

export default function AccountSettings(props) {
  const [activeTab, setActiveTab] = useState(1);
  const handleTabChange = (index) => {
    setActiveTab(index);
  };

  return (
    <Container maxWidth="lg">
      <div className={styles.accountSettingsPage}>
        <div className={styles.accountSettingsWrapper}>
          <h3 className={styles.accountSettingsTitle + " subheading "}>
            Account Settings
          </h3>
          <div className={styles.accountSettingsBlk}>
            <Grid container>
              <Grid item md={3}>
                <div className={styles.accountSettingsTabWrapper}>
                  <AccountSettingsTabs
                    activeTab={activeTab}
                    handleTabChange={handleTabChange}
                  />
                </div>
              </Grid>
              <Grid item md={9}>
                <div className={styles.accountSettingsTabContentWrapper}>
                  <AccountSettingsTabContent activeTab={activeTab} />
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    </Container>
  );
}
