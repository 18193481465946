import React, { useState, useEffect } from "react";
import { Grid, List, ListItem } from "@material-ui/core";
import styles from "../../../assets/styles/cart.module.scss";
import { imageBasePath } from "../../../constants";
import { currencyFormatter } from "../../../utils";
import { fieldValidation } from "../../../utils/formValidation";
import {
  InputText,
  CustomButton,
  InputSelect,
  CustomRadioButton,
  CustomCheckbox,
} from "../../../shared_ui_components";
import ShippingAddressCard from "../components/ShippingAddressCard";
const timeZone = [
  { value: "USA", label: "USA" },
  { value: "India", label: "India" },
];

const getOrderPricing = (opt, selectedOpt) => {
  if (opt && opt.length) {
    let pricing = opt.find((pricing) => pricing._id === selectedOpt);
    if (pricing) {
      return pricing;
    }
  }
  return {};
};

// const getSubtotal = (quantity, cost, subscriptionFee) => {
//   let subtotal = quantity * (cost + subscriptionFee);
//   return subtotal;
// };

// const getTax = (subtotal, taxParcent) => {
//   let tax = (taxParcent / 100) * subtotal;
//   return tax;
// };

// const getDiscount = (subtotal, discountParcent = 0) => {
//   let tax = (discountParcent / 100) * subtotal;
//   return tax;
// };

// const getTotal = (subtotal, tax, discount, couponDiscount) => {
//   let total = subtotal + tax - discount - couponDiscount;
//   return total;
// };

function OrderSummary(props) {
  const {
    step,
    cartDetails: { units, devicePriceId },
    subscriptionPeriodOpt,
    device: { deviceName },
    handleChange,
    applyCuponCode,
    submitLoader,
    couponDiscount,
    couponCodeText,
    couponSetFlag,
    invalidateCoupon,
    couponError,
    updateCouponError,
  } = props;

  const [summary, setSummary] = useState({});
  const [couponCode, setCouponCode] = useState("");
  const [error, errorSet] = useState({});
  const [errorCode, errorCodeSet] = useState({
    couponCode: {
      0: "",
      1: "Please enter coupon code",
    },
    couponCodeObj: {
      requiredFlag: true,
    },
  });

  const handleCouponCode = (val) => {
    setCouponCode(val);
    errorSet({
      ...error,
      couponCode: "",
    });
    updateCouponError();
  };

  const validateCouponCode = () => {
    const validateNewInput = {
      couponCode:
        errorCode["couponCode"][
          fieldValidation({
            ...errorCode["couponCodeObj"],
            fieldval: couponCode,
          })
        ],
    };
    if (Object.keys(validateNewInput).every((k) => validateNewInput[k] == "")) {
      applyCuponCode(couponCode);
    } else {
      errorSet(validateNewInput);
    }
  };

  useEffect(() => {
    if (devicePriceId) {
      setSummary(getOrderPricing(subscriptionPeriodOpt, devicePriceId));
    }
    // if (units || devicePriceId) {
    //   handleChange(
    //     "amountPaid",
    //     getTotal(
    //       getSubtotal(
    //         units,
    //         getOrderPricing(subscriptionPeriodOpt, devicePriceId).price,
    //         getOrderPricing(subscriptionPeriodOpt, devicePriceId).subscriptionFee
    //       ),
    //       getTax(
    //         getSubtotal(
    //           units,
    //           getOrderPricing(subscriptionPeriodOpt, devicePriceId).price,
    //           getOrderPricing(subscriptionPeriodOpt, devicePriceId).subscriptionFee
    //         ),
    //         getOrderPricing(subscriptionPeriodOpt, devicePriceId).tax
    //       ),
    //       getDiscount(
    //         getSubtotal(
    //           units,
    //           getOrderPricing(subscriptionPeriodOpt, devicePriceId).price,
    //           getOrderPricing(subscriptionPeriodOpt, devicePriceId).subscriptionFee
    //         ),
    //         getOrderPricing(subscriptionPeriodOpt, devicePriceId).discount
    //       ),
    //       getDiscount(
    //         getSubtotal(
    //           units,
    //           getOrderPricing(subscriptionPeriodOpt, devicePriceId).price,
    //           getOrderPricing(subscriptionPeriodOpt, devicePriceId).subscriptionFee
    //         ),
    //         couponDiscount
    //       )
    //     )
    //   );
    // }
  }, [devicePriceId, subscriptionPeriodOpt, units]);

  //const _devicePrice = units * summary?.price;
  const discountOffered = (units * summary?.subscriptionFee * summary?.discount) / 100;
  const couponDiscountOffered = (units * summary?.subscriptionFee * couponDiscount) / 100;
  const discounted_sub_fee = units * summary?.subscriptionFee - discountOffered - couponDiscountOffered;
  const tax = (summary?.price + discounted_sub_fee) * 0.18;
  const totalPrice = summary?.price + discounted_sub_fee + tax;

  return (
    <>
      <div className={styles.orderSummaryWrapper}>
        <div className={styles.orderSummaryCard}>
          <div className={styles.cardHeader}>
            <h2 className={styles.orderSummaryHeading + " subheading "}>Order Summary</h2>
            {step > 1 ? (
              <CustomButton children="Edit" className={styles.editBtn + " outlinedBtn " + " small "} />
            ) : null}
          </div>
          <div className={styles.cardBody}>
            <div className={styles.orderSummaryBlk}>
              <Grid container direction="row" spacing={2}>
                <Grid item md={3} xs={6}>
                  <img src={imageBasePath + "order_set_top_box.svg"} alt="" />
                </Grid>
                <Grid item md={9} xs={6}>
                  <Grid container direction="row" spacing={2}>
                    <Grid item md={6} xs={12}>
                      <div className={styles.orderSummaryBlkDetails}>
                        <p className={styles.orderSummaryQuantity + " supportText "}>Quantity</p>
                        <p className={styles.orderSummaryQuantityCount + " supportText "}>
                          {units} <span>item(s)</span>
                        </p>
                      </div>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <div className={styles.orderSummaryBlkDetails}>
                        <p className={styles.orderSummarySubscription + " supportText "}>
                          Subscription period
                        </p>
                        <p className={styles.orderSummarySubscriptionPeriod + " supportText "}>
                          {summary.subscriptionPeriod / 12} <span> years</span>
                        </p>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>
            <div className={styles.orderSummaryDiscountWrapper}>
              {!couponSetFlag ? (
                <Grid container direction="row" spacing={3}>
                  <Grid item md={9} xs={8}>
                    <div className={styles.orderSummaryDiscountInput}>
                      <InputText
                        placeholder="Coupon Code"
                        label="Enter Discount Code"
                        onChange={(e) => {
                          handleCouponCode(e.target.value);
                        }}
                        error={
                          error.couponCode
                            ? error.couponCode
                            : couponError
                            ? "Invalid coupon code. Try to enter valid code."
                            : ""
                        }
                        onKeyPress={(e) => {
                          if (e.key === "Enter") {
                            validateCouponCode();
                          }
                        }}
                      />
                    </div>
                  </Grid>
                  <Grid item md={3} xs={4}>
                    <CustomButton
                      children={submitLoader ? "Applying..." : "Apply"}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        validateCouponCode();
                      }}
                      className={
                        styles.discountApplyBtn + " outlinedBtn " + `${submitLoader ? "disabledBtn" : ""}`
                      }
                      width="100%"
                    />
                  </Grid>
                </Grid>
              ) : (
                <div className={styles.couponSuccessWrapper}>
                  <p className={styles.successText + " label"}>Enter Discount Code</p>
                  <div className={styles.couponSuccessTag}>
                    <Grid container direction="row" justifyContent="center" alignItems="center">
                      <Grid item md={1}>
                        <img src={imageBasePath + "coupon_tag.svg"} alt="" />
                      </Grid>
                      <Grid item md={10}>
                        <p className={styles.couponCode + " paragraphSemiBold"}>{couponCodeText}</p>
                      </Grid>
                      <Grid item md={1}>
                        <a>
                          <img
                            src={imageBasePath + "coupon_cross.svg"}
                            alt=""
                            onClick={() => invalidateCoupon()}
                          />
                        </a>
                      </Grid>
                    </Grid>
                  </div>
                </div>
              )}
            </div>
            <div className={styles.orderSummaryListWrapper}>
              <div className={styles.orderSummaryListOuterBlk}>
                <Grid container direction="row" spacing={2}>
                  <Grid item md={12} xs={12}>
                    <div className={styles.orderSummaryListInnerlk}>
                      <Grid container direction="row" spacing={2}>
                        <Grid item md={9} xs={7}>
                          <div className={styles.orderSummaryDescription}>
                            <p className="supportText">Cost of {deviceName}</p>
                          </div>
                        </Grid>
                        <Grid item md={3} xs={5}>
                          <div className={styles.orderSummaryPrice}>
                            <p className="supportText">₹{currencyFormatter(summary.price)}</p>
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                    <div className={styles.orderSummaryListInnerlk}>
                      <Grid container direction="row" spacing={2}>
                        <Grid item md={9} xs={7}>
                          <div className={styles.orderSummaryDescription}>
                            <p className="supportText">Subscription Fee</p>
                          </div>
                        </Grid>
                        <Grid item md={3} xs={5}>
                          <div className={styles.orderSummaryPrice}>
                            <p className="supportText">₹{currencyFormatter(summary.subscriptionFee)}</p>
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                    {/* <div className={styles.orderSummaryListInnerlk}>
                      <Grid container direction="row" spacing={2}>
                        <Grid item md={9} xs={7}>
                          <div className={styles.orderSummaryDescription}>
                            <p className="supportText">
                              Sub total {`${units > 1 ? "(" + units + " items)" : ""} `}
                            </p>
                          </div>
                        </Grid>
                        <Grid item md={3} xs={5}>
                          <div className={styles.orderSummaryPrice}>
                            <p className="supportText">
                              ₹{currencyFormatter(getSubtotal(units, summary.price, summary.subscriptionFee))}
                            </p>
                          </div>
                        </Grid>
                      </Grid>
                    </div> */}
                    {summary.discount ? (
                      <div className={styles.orderSummaryListInnerlk}>
                        <Grid container direction="row" spacing={2}>
                          <Grid item md={9} xs={6}>
                            <div className={styles.orderSummaryDescription}>
                              <p className="supportText">Bag Discount</p>
                            </div>
                          </Grid>
                          <Grid item md={3} xs={6}>
                            <div className={styles.orderSummaryPrice}>
                              <p className="supportText">
                                <span className={styles.discountLabel}>{summary.discount}%</span> -₹
                                {/* {currencyFormatter(
                                  getDiscount(
                                    getSubtotal(units, summary.price, summary.subscriptionFee),
                                    summary.discount
                                  )
                                )} */}
                                {currencyFormatter(discountOffered)}
                              </p>
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    ) : null}
                    <div className={styles.orderSummaryListInnerlk}>
                      <Grid container direction="row" spacing={2}>
                        <Grid item md={9} xs={7}>
                          <div className={styles.orderSummaryDescription}>
                            <p className="supportText">Tax</p>
                          </div>
                        </Grid>
                        <Grid item md={3} xs={5}>
                          <div className={styles.orderSummaryPrice}>
                            <p className="supportText">
                              ₹
                              {/* {currencyFormatter(
                                getTax(
                                  getSubtotal(units, summary.price, summary.subscriptionFee),
                                  summary.tax
                                )
                              )} */}
                              {currencyFormatter(tax)}
                            </p>
                          </div>
                        </Grid>
                      </Grid>
                    </div>

                    {couponSetFlag ? (
                      <div className={styles.orderSummaryListInnerlk}>
                        <Grid container direction="row" spacing={2}>
                          <Grid item md={9} xs={6}>
                            <div className={styles.orderSummaryDescription}>
                              <p className="supportText">Discount Code ({couponCodeText})</p>
                            </div>
                          </Grid>
                          <Grid item md={3} xs={6}>
                            <div className={styles.orderSummaryPrice}>
                              <p className="supportText">
                                <span className={styles.discountLabel}>{couponDiscount}%</span> -₹
                                {/* {currencyFormatter(
                                  getDiscount(
                                    getSubtotal(units, summary.price, summary.subscriptionFee),
                                    couponDiscount
                                  )
                                )} */}
                                {currencyFormatter(couponDiscountOffered)}
                              </p>
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    ) : null}
                  </Grid>
                </Grid>
              </div>
              <div className={styles.orderSummaryTotalBlk}>
                <Grid container direction="row">
                  <Grid item md={9} xs={7}>
                    <div className={styles.orderSummaryTotalText}>
                      <p className="paragraphSemiBold">Total</p>
                    </div>
                  </Grid>
                  <Grid item md={3} xs={5}>
                    <div className={styles.orderSummaryTotalPrice}>
                      <p className="subheading">
                        ₹
                        {/* {currencyFormatter(
                          getTotal(
                            getSubtotal(units, summary.price, summary.subscriptionFee),
                            getTax(getSubtotal(units, summary.price, summary.subscriptionFee), summary.tax),
                            getDiscount(
                              getSubtotal(units, summary.price, summary.subscriptionFee),
                              summary.discount
                            ),
                            getDiscount(
                              getSubtotal(units, summary.price, summary.subscriptionFee),
                              couponDiscount
                            )
                          )
                        )} */}
                        {currencyFormatter(totalPrice)}
                      </p>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </div>
          </div>
          {step === 3 && (
            <div className={styles.cardFooter}>
              <ShippingAddressCard />
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default OrderSummary;
