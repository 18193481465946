import React, { useState } from "react";
import styles from "../../../assets/styles/dashboard.module.scss";
import { PartnerHeader, Sidebar } from "../../../shared_elements";
import DashboardContent from "../components/DashboardContent";

export default function Partner(props) {
  return (
    <div className={styles.partnerDashboard}>
      <PartnerHeader />
      <Sidebar />
      <DashboardContent />
    </div>
  );
}
