import React, { Component } from "react";
import { Container, Button, Grid, List, ListItem } from "@material-ui/core";
import { imageBasePath } from "../../../constants";
import styles from "../../../assets/styles/purchase.module.scss";

const data = [
  {
    img: `${imageBasePath}get-icon-1.svg`,
    title: "Enterprise Grade Security",
    description:
      "Our unique Scan to Join workflow adds an additional layer of security by authenticating and ensuring the right participants join the right meeting from the right room.",
  },
  {
    img: `${imageBasePath}get-icon-2.svg`,
    title: "Compatible with every meeting platform",
    description:
      "Drive adoption of VC rooms by allowing users to seamlessly host and Join meetings from any of the conferencing platforms like Zoom, Webex, MS Teams, GoogleMeet.",
  },
  {
    img: `${imageBasePath}get-icon-3.svg`,
    title: "Works with any OEM Camera",
    description:
      "Convert any OEM camera into a cross border, cross platform collaboration tool that can be centrally managed.",
  },
  {
    img: `${imageBasePath}get-icon-4.svg`,
    title: "Plug & Play",
    description:
      "Our Scan to Activate workflow registers the device under a minute saving time for IT teams that are deploying and managing VC rooms.",
  },
  {
    img: `${imageBasePath}get-icon-5.svg`,
    title: "Magical Joining Experience",
    description:
      "Enhance user experience with our proprietary Scan to Join workflow that makes it extremely simple for users to join any meeting from any room using any VC camera.",
  },
  {
    img: `${imageBasePath}get-icon-6.svg`,
    title: "Meeting Controls on Mobile Devices",
    description:
      "Remove the complexity & fail points of additional peripherals (Touch panel, Remotes). Save costs and make it easy for users to control the room device and the meeting from their mobile phones or laptops.",
  },
  {
    img: `${imageBasePath}get-icon-7.svg`,
    title: "In-built Wireless Screen Sharing",
    description:
      "Easy to use wireless screen sharing within the same platform without the need to purchase additional hardware.",
  },
  {
    img: `${imageBasePath}get-icon-8.svg`,
    title: "Centralised  Management across OEM cameras",
    description:
      "State of the art unified admin panel that empowers IT teams to deploy and control VC Room across geographies and VC cameras from different OEMs from a single pane of glass.",
  },
  {
    img: `${imageBasePath}get-icon-9.svg`,
    title: "Admin Dashboard & Analytics",
    description:
      "Real time access to user and room utilisation data that will help drive productivity and optimise costs.",
  },
];

function GetSection(props) {
  return (
    <section className={styles.getSection}>
      <Container maxWidth="lg">
        <Grid container direction="row">
          <Grid item md={12}>
            <h3 className={styles.getTitle + " heading4 textCenter"}>
              One Platform. Many Benefits.
            </h3>
          </Grid>
        </Grid>
        <Grid container direction="row" spacing={5}>
          {data.map((item, key) => (
            <Grid key={key} item md={4}>
              <div className={styles.card}>
                <img src={item.img} alt="icons" />
                <h4 className={styles.cardTitle + " subheading"}>
                  {item.title}
                </h4>
                <p className={" paragraph"}>{item.description}</p>
              </div>
            </Grid>
          ))}
        </Grid>
      </Container>
    </section>
  );
}

export default GetSection;
