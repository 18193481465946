import axios from "axios";

var axiosInstance = axios.create();
axiosInstance.defaults.baseURL = process.env.REACT_APP_HTTP_BASE_URL || 'https://api.letsving.com/api';

axiosInstance.interceptors.request.use(
  function (config) {
    if (localStorage.getItem("userInfo")) {
      config["headers"]["Authorization"] =
        "Bearer " + JSON.parse(localStorage.getItem("userInfo"))["accessToken"];
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  function (response) {
    return response.data;
  },
  function (error) {
    return error;
  }
);

export default axiosInstance;
