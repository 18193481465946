import React, { useEffect, useState } from "react";
import { Grid, TextField } from "@material-ui/core";
import styles from "../../../assets/styles/overview.module.scss";
import { imageBasePath } from "../../../constants";
import { Link } from "react-router-dom";
import { Autocomplete } from "@material-ui/lab";
import { createDeployment, getReleases } from "../../devices/apiServices";
import { toastFlashMessage } from "../../../utils";
import closeIcon from "../../../assets/images/close-square.svg";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

export default function OrganizationDetailsCard(props) {
  const { orgOverview } = props;
  const [showPopup, setShowPopup] = useState(false);
  const [releases, setReleases] = useState("");
  const [releaseId, setReleaseId] = useState("");
  const { id } = useParams();

  const orgDetails = [
    {
      id: 1,
      name: "GST Number",
      details: orgOverview?.gstNumber ? orgOverview?.gstNumber : "N/A",
    },
    {
      id: 2,
      name: "TIN Number",
      details: orgOverview?.tinNumber ? orgOverview?.tinNumber : "N/A",
    },
    {
      id: 3,
      name: "PAN Number",
      details: orgOverview?.panNumber ? orgOverview?.panNumber : "N/A",
    },
    {
      id: 4,
      name: "Admin",
      details: orgOverview?.adminDetails?.fullName
        ? orgOverview?.adminDetails?.fullName
        : "N/A",
    },
    {
      id: 5,
      name: "Admin Email",
      details: orgOverview?.adminDetails?.email
        ? orgOverview?.adminDetails?.email
        : "N/A",
    },
  ];

  const orgOrderDetails = [
    {
      id: 1,
      img: imageBasePath + "org_workspace_icon.svg",
      name: "Total Workspaces",
      count: orgOverview?.workspaceCount ? orgOverview?.workspaceCount : "N/A",
    },
    {
      id: 2,
      img: imageBasePath + "org_room_icon.svg",
      name: "Total Rooms",
      count: orgOverview?.roomCount ? orgOverview?.roomCount : "N/A",
    },
    {
      id: 3,
      img: imageBasePath + "org_total_devices_icon.svg",
      name: "Total Devices",
      count: orgOverview?.deviceCount ? orgOverview?.deviceCount : "N/A",
    },
  ];

  useEffect(() => {
    getReleases().then((response) => {
      if (response.status) {
        setReleases(response.releases);
      }
    });
  }, []);

  function deploy() {
    if (releaseId) {
      createDeployment({
        releaseId: releaseId._id,
        orgId: id,
        deployType: "organisation",
      }).then((resp) => {
        if (resp.status) {
          console.log(resp);
          setShowPopup(false);
          toastFlashMessage(`Deploy created successfully`, "success");
        } else {
          toastFlashMessage(
            `Something went wrong while creating the deploy`,
            "error"
          );
        }
      });
    }
  }
  const labelStyle = {
    fontFamily: '"Inter", sans-serif',
    fontSize: "18px",
    fontWeight: 500,
    color: "black",
    lineHeight: "21px",
    marginBottom: "3px",
  };

  function compareVersions(versionA, versionB) {
    const partsA = versionA.split(/[.-]/);
    const partsB = versionB.split(/[.-]/);
  
    for (let i = 0; i < Math.max(partsA.length, partsB.length); i++) {
      const partA = parseInt(partsA[i]) || 0;
      const partB = parseInt(partsB[i]) || 0;
  
      if (partA > partB) return -1;
      if (partA < partB) return 1;
    }
  
    return 0;
  }
  const sortedReleases=releases?releases.sort((a, b) => compareVersions(a.version, b.version)):"";

  return (
    <div className={styles.organizationDetailsCard}>
      <h3 className={styles.organizationDetailsTitle + " subheading2 "}>
        Organization details
      </h3>
      <div className={styles.cardBody}>
        <Grid container>
          <Grid item md={7}>
            <div className={styles.orgDetailsLeftBlk}>
              <img
                src={
                  orgOverview?.orgLogo
                    ? orgOverview?.orgLogo
                    : imageBasePath + "organization_logo.svg"
                }
              />
              <h4 className={styles.orgName + " subheading "}>
                {orgOverview?.orgName}
              </h4>
              <p className={styles.orgAddress + " supportText "}>
                {orgOverview?.orgAddress?.address} <br />
                {orgOverview?.orgAddress?.cityName +
                  ", " +
                  orgOverview?.orgAddress?.stateName +
                  " " +
                  orgOverview?.orgAddress?.pincode}
              </p>
            </div>
          </Grid>
          <Grid item md={5}>
            <div className={styles.orgDetailsRightBlk}>
              {orgDetails.map((org) => {
                return (
                  <div className={styles.orgDetailsRightBlkItem} key={org.id}>
                    <Grid container spacing={2}>
                      <Grid item md={5}>
                        <div className={styles.orgDetailsInnerLeftBlk}>
                          <p
                            className={
                              styles.orgDetailsInnerLeftText + " supportText "
                            }
                          >
                            {org.name}
                          </p>
                        </div>
                      </Grid>
                      <Grid item md={7}>
                        <div className={styles.orgDetailsInnerRightBlk}>
                          {org.name === "Admin Email" ? (
                            <Link
                              className={
                                styles.orgDetailsInnerRightLinkText +
                                " paragraph "
                              }
                              to="#"
                              onClick={(e) => {
                                window.location = `mailto:${org.details}`;
                                e.preventDefault();
                              }}
                            >
                              {org.details}
                            </Link>
                          ) : (
                            <p
                              className={
                                styles.orgDetailsInnerRightText + " paragraph "
                              }
                            >
                              {org.details}
                            </p>
                          )}
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                );
              })}
              <div style={{ display: "flex", alignItems: "center" }}>
                <Grid container spacing={2}>
                  <Grid item md={5}>
                    <p
                      style={{ color: "#67737d", fontWeight: "400" }}
                      className={
                        styles.orgDetailsInnerLeftText + " supportText "
                      }
                    >
                      Releases
                    </p>
                  </Grid>
                  <Grid item md={7}>
                    <button
                      className={`primaryBtn`}
                      onClick={() => {
                        setShowPopup(true);
                      }}
                    >
                      Deploy
                    </button>
                  </Grid>
                </Grid>
                <div
                  id="myModal"
                  style={{ display: `${showPopup ? "block" : ""}` }}
                  className={styles.modal}
                >
                  <div className={styles.modalContent}>
                    <img
                      className={styles.close}
                      onClick={() => setShowPopup(false)}
                      src={closeIcon}
                    />

                    <div style={labelStyle}>Select Release Version</div>
                    {/* <div style={{display:"flex",gap:"40px"}}> */}

                    <Autocomplete
                      id="autocomplete"
                      options={sortedReleases}
                      getOptionLabel={(option) => String(option.version)}
                      onChange={(e, newValue) => setReleaseId(newValue || "")}
                      value={releaseId.version}
                      style={{ margin: "20px 0px" }}
                      renderInput={(params) => (
                        <TextField {...params} label="" variant="outlined" />
                      )}
                    />
                    <button className={`primaryBtn`} onClick={deploy}>
                      Deploy
                    </button>
                    {/*
      </div> */}
                  </div>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
      <div className={styles.cardFooter}>
        <Grid container>
          {orgOrderDetails.map((orderDetails) => {
            return (
              <Grid item md={4} key={orderDetails.id}>
                <div className={styles.orgDetailsTotalBlk}>
                  <img src={orderDetails.img} />
                  <div className={styles.orgDetailsTotalWrapper}>
                    <p className={styles.orgTotalText + " supportText "}>
                      {orderDetails.name}
                    </p>
                    <p className={styles.orgTotalCount + " subheading2 "}>
                      {orderDetails.count}
                    </p>
                  </div>
                </div>
              </Grid>
            );
          })}
        </Grid>
      </div>
    </div>
  );
}
