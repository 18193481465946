import { Grid } from "@material-ui/core";
import React from "react";
import styles from "../../../assets/styles/quotations.module.scss";
import { imageBasePath } from "../../../constants";
import { CustomLink } from "../../../shared_ui_components";
import  ReviewQuotation  from "../../../shared_elements/ReviewQuotation";
import QuoteOrderForm from "./QuoteOrderForm";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";

function QuoteOrder(props) {
  const { id } = useParams();
  const history = useHistory();
  const { step, handleUpdateStep, handleQuoteOrderFormData } = props;

  const handleGoBack = () => {
    history.goBack();
  };

  return (
    <div className={styles.quoteOrderWrapper}>
      <div className={styles.backBtnWrapper}>
        <CustomLink
          className="backBtn"
          text="Back"
          leftIcon={imageBasePath + "arrow_left_icon.svg"}
          onClick={handleGoBack}
        />
      </div>
      <div className={styles.quoteOrderFormWrapper}>
        <Grid container spacing={2} justifyContent="center">
          <Grid item md={7}>
            <QuoteOrderForm 
              handleUpdateStep={handleUpdateStep}
              handleQuoteOrderFormData={handleQuoteOrderFormData}
              step={step}
            />
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default QuoteOrder;
