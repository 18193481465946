import React, { useState } from "react";
import styles from "../../../../assets/styles/admin/reviewQuote.module.scss";
import { Grid, Container } from "@material-ui/core";
import { imageBasePath } from "../../../../constants";
import { CustomLink, SquareRadioButton, InputText } from "../../../../shared_ui_components";
import PropTypes from "prop-types";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";

export default function QuotationOrderDetail() {
  const [count, setCount] = useState(0);

  const decrementCount = () => {
    if (count > 0) setCount(count - 1);
  };

  const incrementCount = () => {
    setCount(count + 1);
  };

  return (
    <div className={styles.quotationDetailWrapper}>
      <Container maxWidth="lg">
        <div className={styles.quoteDetailBlk}>
          <Grid container justifyContent="center" direction="row" alignItems="center" spacing={2}>
            <Grid item md={4}>
              <img className={styles.vingBox} src={imageBasePath + "vingbox.png"} alt="vingbox" />
            </Grid>
            <Grid item md={8}>
              <p className={styles.quoteTitle + " heading6"}>Please provide us your order details</p>
              <p className={styles.subTitle + " paragraph"}>Quantity</p>
              <div className={styles.quantitySection}>
                <button className={styles.decrementButton} onClick={decrementCount}>
                  <img src={imageBasePath + "minus-line.svg"} alt="minus" />
                </button>
                <input
                  className="paragraph"
                  type="number"
                  name="clicks"
                  value={count}
                  onChange={(event) => {
                    setCount(event.target.value);
                  }}
                />
                <button className={styles.incrementButton} onClick={incrementCount}>
                  <img src={imageBasePath + "add-line.svg"} alt="plus" />
                </button>
              </div>
              <div>
                <p className={styles.subTitle + " paragraph"}>Subscription period</p>
                <div className={styles.subscriptionPeriodBlk}>
                  <ul className={styles.subNumber + " listInline"}>
                    {/* {{subscriptionPeriodOpt.map((period) => (}
                                            <li className={`listInlineItem ${styles.subButton2}`}>
                                                {{period.discount ? (
                                                    <span className={styles.discountBanner}>
                                                        {period.discount}% OFF
                                                    </span>
                                                ) : null}}
                                                <SquareRadioButton
                                                    text={`${period.subscriptionPeriod / 12} ${period.subscriptionPeriod / 12 > 1 ? "Years" : "Year"
                                                        }` "1 Year"}
                                                    checked={
                                                        period._id === devicePriceId
                                                            ? true
                                                            : false
                                                    }
                                                    type="largeButton"
                                                    onClick={() =>
                                                        handleChange(
                                                            "devicePriceId",
                                                            period._id
                                                        )
                                                    }
                                                />
                                            </li>
                                        ))} */}
                    <li className={`listInlineItem ${styles.subButton}`}>
                      <SquareRadioButton text="1 Year" type="largeButton" />
                    </li>
                    <li className={`listInlineItem ${styles.subButton}`}>
                      <span className={styles.discountBanner}>20% OFF</span>
                      <SquareRadioButton text="3 Years" type="largeButton" />
                    </li>
                  </ul>
                </div>
              </div>
              <div>
                <p className={styles.subTitle + " paragraph"}>Contact Information</p>
                <div className={styles.organisationSection}>
                  <div className={styles.selectDropdown}>
                    <InputText label="Name of the Organisation *" placeholder="eg,  Dohn doe" />
                    <InputText label="Email ID *" placeholder="eg,  abc@xyz.com" />
                    <div className="inputGroup phoneNumberGroup">
                      <label className="label">Contact number *</label>
                      <PhoneInput
                        countryCodeEditable={false}
                        disableCountryCode={true}
                        placeholder="Enter phone number"
                        defaultCountry="IN"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <CustomLink
                  className={styles.requestButton + " primaryBtn"}
                  text="Review quote"
                  rightIcon={imageBasePath + "btn_arrow_right_icon.svg"}
                />
              </div>
            </Grid>
          </Grid>
        </div>
      </Container>
    </div>
  );
}
