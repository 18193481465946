import React, { useState, useEffect } from "react";
import { Grid, Tab, Tabs, Dialog } from "@material-ui/core";
import styles from "../../../assets/styles/devices.module.scss";
import { CustomLink, InputSelect, NoResult } from "../../../shared_ui_components";
import { imageBasePath, customMediumSelector, reactSelectStylesSmall } from "../../../constants";
import DevicesListItem from "./DevicesListItem";
import { getWorkspaceListing, getWorkspaceRoomDetails } from "../../workspaces/apiServices";
import { getDevListsByOrgId, getAllRoomList, getResListsByOrgId } from "../apiServices";
import { useParams } from "react-router";
import Shimmer from "./Shimmer";
import { sortObj, toastFlashMessage, getUserInfo } from "../../../utils";
import  AddResourceDialog  from "./AddResourceDialog";
export default function ResourcesList(props) {
  const { id } = useParams();
  const [newResource, setNewResource] = useState(false);
  const [workspaceLists, setWorkspaceLists] = useState([]);
  const [noNewDeviceList, setNoNewDeviceList] = useState(false);
  const [resourceList, setResourceList] = useState([]);
  const [loader, setLoader] = useState(true);
  const [selectedTab, setSelectedTab] = useState(0);
  const [selectedWorkspace, setSelectedWorkspace] = useState(null);
  const [roomLists, setRoomLists] = useState([]);
  const [search, setSearch] = useState({
    val: "",
    list: [],
  });
  const [sort, setSort] = useState({
    field: "",
    asc: false,
    defaultSortUpImg: "",
    defaultSortDownImg: "",
  });

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const deviceList = () => {
    getResListsByOrgId({ orgId: id }).then((response) => {
      if (response.status && response.data.length > 0) {
        setResourceList(response.data);
        setLoader(false);
        //setNoNewDeviceList(false);
      } else {
        setLoader(false);
        //setNoNewDeviceList(true);
      }
    });
  };

  const workspaceList = () => {
    getWorkspaceListing({ orgId: id }).then((response) => {
      if (response.status && response.data.length > 0) {
        const workspaceList = response.data.map((org) => {
          return { label: org?.orgLocation?.orgWorkSpaceName, value: org?.orgLocation._id };
        });
        setWorkspaceLists(workspaceList);
      }
    });
  };
  const handleClickOpen = () => {
    setNewResource(true);
  };

  const handleClose = () => {
    setNewResource(false);
  };
  useEffect(() => {
    deviceList();
    //workspaceList();
  }, [id]);

  useEffect(() => {
    if (selectedWorkspace !== null) {
      getWorkspaceRoomDetails({ workspaceId: selectedWorkspace }).then((response) => {
        if (response.status && response.data.length > 0) {
          setRoomLists(
            response.data
              .filter((rDevice) => rDevice.deviceStatus === false)
              .map((room) => {
                return { label: room.roomName, value: room._id };
              })
          );
        } else {
          toastFlashMessage("There is no room for the selected workspace", "error");
          setRoomLists([]);
        }
      });
    }
  }, [selectedWorkspace]);

  const activatedDeviceLists = resourceList
    .filter((device) => {
      return device.deviceStatus === "Active" && device.macId !== null;
    })
    .map((activatedDevice) => {
      return activatedDevice;
    });

  const handleSearch = (val) => {
    setSearch((prevSearch) => ({
      val: val,
      list: resourceList.filter(
        (device) =>
          device?.userName?.toLowerCase().includes(val.toLowerCase()) ||
          device?.roomId?.roomName?.toLowerCase().includes(val.toLowerCase())
      ),
    }));
  };
  const userInfo = getUserInfo();
  const handleSort = (sortObject) => {
    let list = sortObj(sortObject.field, resourceList, sortObject.asc, sortObject.caseSensitive);
    setSort({
      field: sortObject.field,
      asc: sortObject.asc,
      defaultSortUpImg: sortObject.asc ? imageBasePath + "asc_arrow.svg" : imageBasePath + "up_arrow.svg",
      defaultSortDownImg: sortObject.asc ? imageBasePath + "down_arrow.svg" : imageBasePath + "des_arrow.svg",
    });
    resourceList(list);
  };

  return (
    <div className={styles.devicesHeaderWrapper}>
      <div className={styles.devicesPageTitleWrapper}>
        {loader ? (
          <Shimmer type="DeviceLength" />
        ) : (
          <>
            <h2 className={styles.devicesPageTitle + " subheading "}>
              Resources
              <span className={styles.devicesCount}>
                ({(selectedTab === 1 && activatedDeviceLists ? activatedDeviceLists : resourceList).length})
              </span>
            </h2>
          </>
        )}
      </div>
      {noNewDeviceList === false ? (
        <>
          <div className={styles.devicesTabWrapper}>
            <Grid container>
              <Grid item md={12}>
                <div className={styles.devicesTab}>
                  <Tabs className={styles.quotationsListFilter} value={selectedTab} onChange={handleChange}>
                    <Tab label="All Resources" className={styles.filterItem} />
                  </Tabs>
                </div>
              </Grid>
            </Grid>
          </div>
          {loader ? (
            <Shimmer type="DeviceList" />
          ) : (
            <>
              {(selectedTab === 1 && activatedDeviceLists ? activatedDeviceLists : resourceList).length !==
              0 ? (
                <>
                  <div className={styles.devicesListWrapper} >
                  <Grid container justifyContent="space-between">
                      <Grid item md={4}>
                        <p className={styles.devicesWorkspace + " supportText "}>Workspace</p>
                        {/* <div className={styles.workspaceLocationSelect}> */}
                      </Grid>
                      <Grid item md={4}>
                      {userInfo.userData?.role?.responsibilities?.createWorkspace ? (
                        <div className={styles.deviceSearchBlk} style={{marginTop:"20px"}}>
                          <div className={styles.deviceSearchInput}>
                          <CustomLink
                          onClick={handleClickOpen}
                          className={styles.newWorkspaceButton + " outlinedBtn "}
                          text="+New Resource"
                        />
                         <Dialog open={newResource} onClose={handleClose}>
                          <AddResourceDialog
                            handleClose={handleClose}
                          />
                        </Dialog> 
                        
                          </div>
                        </div>
                         ) : null}
                      </Grid>

                    </Grid>
                    <div className={styles.devicesList}>
                      <div className={styles.devicesListItemWrapper}>
                        <div className={styles.devicesListItemTitleWrapper}>
                          <Grid container spacing={2}>
                            <Grid item md={2}>
                              <div className={styles.devicesListItemTitleTextWrapper}>
                                <p className={styles.devicesListItemTitle + " supportText "}>User Name</p>
                                <img
                                  src={
                                    sort.field === "userName"
                                      ? sort.defaultSortUpImg
                                      : imageBasePath + "up_arrow.svg"
                                  }
                                  alt=""
                                  className="sortUp"
                                  onClick={() =>
                                    handleSort({
                                      field: "userName",
                                      asc: !sort.asc,
                                      caseSensitive: true,
                                    })
                                  }
                                />
                                <img
                                  src={
                                    sort.field === "userName"
                                      ? sort.defaultSortDownImg
                                      : imageBasePath + "down_arrow.svg"
                                  }
                                  alt=""
                                  className="sortDown"
                                  onClick={() =>
                                    handleSort({
                                      field: "userName",
                                      asc: !sort.asc,
                                      caseSensitive: true,
                                    })
                                  }
                                />
                              </div>
                            </Grid>
                            <Grid item md={2}>
                              <div className={styles.devicesListItemTitleTextWrapper}>
                                <p className={styles.devicesListItemTitle + " supportText "}>Room</p>
                              </div>
                            </Grid>
                            <Grid item md={2}>
                              <div className={styles.devicesListItemTitleTextWrapper}>
                                <p className={styles.devicesListItemTitle + " supportText "}>MAC ID</p>
                                <img
                                  src={
                                    sort.field === "macId"
                                      ? sort.defaultSortUpImg
                                      : imageBasePath + "up_arrow.svg"
                                  }
                                  alt=""
                                  className="sortUp"
                                  onClick={() =>
                                    handleSort({
                                      field: "macId",
                                      asc: !sort.asc,
                                      caseSensitive: false,
                                    })
                                  }
                                />
                                <img
                                  src={
                                    sort.field === "macId"
                                      ? sort.defaultSortDownImg
                                      : imageBasePath + "down_arrow.svg"
                                  }
                                  alt=""
                                  className="sortDown"
                                  onClick={() =>
                                    handleSort({
                                      field: "macId",
                                      asc: !sort.asc,
                                      caseSensitive: false,
                                    })
                                  }
                                />
                              </div>
                            </Grid>
                            <Grid item md={2}>
                              <div className={styles.devicesListItemTitleTextWrapper}>
                                <p className={styles.devicesListItemTitle + " supportText "}>
                                  Subscription Period
                                </p>
                                <img
                                  src={
                                    sort.field === "activeSubscriptionStartDate"
                                      ? sort.defaultSortUpImg
                                      : imageBasePath + "up_arrow.svg"
                                  }
                                  alt=""
                                  className="sortUp"
                                  onClick={() =>
                                    handleSort({
                                      field: "activeSubscriptionStartDate",
                                      asc: !sort.asc,
                                      caseSensitive: false,
                                    })
                                  }
                                />
                                <img
                                  src={
                                    sort.field === "activeSubscriptionStartDate"
                                      ? sort.defaultSortDownImg
                                      : imageBasePath + "down_arrow.svg"
                                  }
                                  alt=""
                                  className="sortDown"
                                  onClick={() =>
                                    handleSort({
                                      field: "activeSubscriptionStartDate",
                                      asc: !sort.asc,
                                      caseSensitive: false,
                                    })
                                  }
                                />
                              </div>
                            </Grid>
                            <Grid item md={2}>
                              <div className={styles.devicesListItemTitleTextWrapper}>
                                <p className={styles.devicesListItemTitle + " supportText "}>
                                  Device Status
                                </p>
                                <img
                                  src={
                                    sort.field === "deviceOnlineStatus"
                                      ? sort.defaultSortUpImg
                                      : imageBasePath + "up_arrow.svg"
                                  }
                                  alt=""
                                  className="sortUp"
                                  onClick={() =>
                                    handleSort({
                                      field: "deviceOnlineStatus",
                                      asc: !sort.asc,
                                      caseSensitive: false,
                                    })
                                  }
                                />
                                <img
                                  src={
                                    sort.field === "deviceOnlineStatus"
                                      ? sort.defaultSortDownImg
                                      : imageBasePath + "down_arrow.svg"
                                  }
                                  alt=""
                                  className="sortDown"
                                  onClick={() =>
                                    handleSort({
                                      field: "deviceOnlineStatus",
                                      asc: !sort.asc,
                                      caseSensitive: false,
                                    })
                                  }
                                />
                              </div>
                            </Grid>
                            {/* <Grid item md={2}>
                              <div className={styles.devicesListItemTitleTextWrapper}>
                                <p className={styles.devicesListItemTitle + " supportText "}>Deletes</p>
                              </div>
                            </Grid> */}
                          </Grid>
                        </div>
                        <div className={styles.devicesListTableContent}>
                          {selectedTab == 0 ? (
                            search.val ? (
                              <>
                                {search.list.length === 0 ? (
                                  <NoResult image="no_order.png" title="No search result found" />
                                ) : (
                                  search.list.map((device) => (
                                    <div key={device._id}>
                                      <DevicesListItem
                                        device={device}
                                        roomLists={roomLists}
                                        deviceList={resourceList}
                                        selectedWorkspace={selectedWorkspace}
                                      />
                                    </div>
                                  ))
                                )}
                              </>
                            ) : (
                              resourceList.map((device) => (
                                <DevicesListItem
                                  device={device}
                                  roomLists={roomLists}
                                  deviceList={resourceList}
                                  selectedWorkspace={selectedWorkspace}
                                />
                              ))
                            )
                          ) : search.val ? (
                            <>
                              {search.list.length === 0 ? (
                                <NoResult image="no_order.png" title="No search result found" />
                              ) : (
                                search.list.map((device) => (
                                  <div key={device._id}>
                                    <DevicesListItem
                                      device={device}
                                      roomLists={roomLists}
                                      deviceList={deviceList}
                                      selectedWorkspace={selectedWorkspace}
                                    />
                                  </div>
                                ))
                              )}
                            </>
                          ) : (
                            activatedDeviceLists.map((device) => (
                              <DevicesListItem
                                device={device}
                                roomLists={roomLists}
                                deviceList={deviceList}
                                selectedWorkspace={selectedWorkspace}
                              />
                            ))
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                userInfo.userData?.role?.responsibilities?.createWorkspace ? (
                  <div className={styles.deviceSearchBlk} style={{marginTop:"20px"}} >
                    <div className={styles.deviceSearchInput}>
                    <CustomLink
                    onClick={handleClickOpen}
                    className={styles.newWorkspaceButton + " outlinedBtn "}
                    text="+New Resourcess"
                  />
                   <Dialog open={newResource} onClose={handleClose}>
                    <AddResourceDialog
                      handleClose={handleClose}
                    />
                  </Dialog> 
                  
                    </div>
                  </div>
                   ) : null
              )}
            </>
          )}
        </>
      ) : (
        userInfo.userData?.role?.responsibilities?.createWorkspace ? (
          <div className={styles.deviceSearchBlk} style={{marginTop:"20px"}}>
            <div className={styles.deviceSearchInput}>
            <CustomLink
            onClick={handleClickOpen}
            className={styles.newWorkspaceButton + " outlinedBtn "}
            text="+New Resourcess"
          />
           <Dialog open={newResource} onClose={handleClose}>
            <AddResourceDialog
              handleClose={handleClose}
            />
          </Dialog> 
          
            </div>
          </div>
           ) : null
      )}
    </div>
  );
}
