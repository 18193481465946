import { useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { useClearCache } from "react-clear-cache";
import GlobalContextProvider from "./context/GlobalContext";
import { ScrollToTop } from "./shared_elements";
import { purchaseRoutes } from "./features/purchase/purchaseRoutes";
import { cartRoutes } from "./features/cart/cartRoutes";
import { legalRoutes } from "./features/legal/legalRoutes";
import { partnerRoutes } from "./features/registration/partnerRoutes";
import { authRoutes } from "./features/auth/authRoutes";
import { dashboardRoutes } from "./features/dashboard/dashboardRoutes";
import { quotationsRoute } from "./features/quotations/quotationsRoute";
import { ordersRoute } from "./features/orders/ordersRoute";
import { settingsRoute } from "./features/settings/settingsRoute";
import { usersRoute } from "./features/users/usersRoute";
import { requestQuoteRoutes } from "./features/request_quote/requestQuoteRoutes";
import { devicesRoutes } from "./features/devices/devicesRoutes";
import { workspacesRoute } from "./features/workspaces/workspacesRoute";
import { overviewRoutes } from "./features/overview/overviewRoutes";
import { homeRoutes } from "./features/home/homeRoutes";
import { activityLogRoutes } from "./features/activity_log/activityLogRoutes";
import { enquiriesRoute } from "./features/admin/enquiries/enquiriesRoute";
import { reviewQuoteRoute } from "./features/admin/review_quote/reviewQuoteRoute";
import { partnerAdminRoutes } from "./features/admin/partners/partnersRoutes";
import { accountSettingsRoutes } from "./features/account_settings/accountSettingsRoutes";
import { directOrderRoutes } from "./features/admin/direct_order/directOrderRoutes";
import { teamRoutes } from "./features/team/teamRoutes";
import { activateRoutes } from "./features/activate/activateRoutes";
import { integrationRoutes } from "./features/integration_instructions/integrationRoutes"
import { reportsRoute } from "./features/reports/reportsRoute"
import { deploymentsRoute } from "./features/deployments/deploymentRoutes"
import "./assets/styles/app.scss";
import { fetchDevices } from "./features/purchase/apiServices";
import { getOrganisationName } from "./features/request_quote/apiServices";
import { getStore } from "./store/GlobalStore";
import { ToastMessage } from "./shared_ui_components";
import { resourcessRoutes } from "./features/resources/resourceRoutes";
import { allOrganisationRoutes } from "./features/all_organisation/allOrganisationRoutes";
import { addOrganisationRoutes } from "./features/add_organisation/addOrganisationRoutes";
import PrivateRoute from "./hoc/privateRoute";
import { toastFlashMessage, getUserInfo, sortCaseInsetive } from "./utils";

function App() {
  const { isLatestVersion, emptyCacheStorage } = useClearCache({
    auto: true,
    duration: 60 * 1000,
  });

  const getAllOrganisationNames = () => {
    getOrganisationName().then((response) => {
      if (response.status && response.orgList?.length) {
        const { dispatch } = getStore();
        dispatch({
          type: "SET_ORGANISATION_NAME",
          payload: response.orgList.sort((a,b) => sortCaseInsetive(a, b, "orgName"))
        });
      } else {
        toastFlashMessage("Authorization is required", "error");
      }
    });
  };

  const fetchDetails = () => {
    fetchDevices().then((response) => {
      if (response.status && response.devices?.length) {
        const { dispatch } = getStore();
        dispatch({
          type: "SET_DEVICE",
          payload: response.devices[0],
        });
      } else {
      }
    });
  };

  useEffect(() => {
    if (!isLatestVersion) {
      emptyCacheStorage();
    }
    fetchDetails();
    let userInfo = getUserInfo();
    if (userInfo?.accessToken) {
      const { state } = getStore();
      if (!state?.orgListNames?.length) {
        getAllOrganisationNames();
      }
    }
  }, []);

  return (
    <div className="pageWrapper">
      <GlobalContextProvider>
        <Router>
          <ScrollToTop />
          <Switch>
            {[...homeRoutes].map(({ path, component, key }, index) => (
              <PrivateRoute exact path={path} component={component} key={key} />
            ))}
            {[
              ...cartRoutes,
              ...purchaseRoutes,
              ...legalRoutes,
              ...partnerRoutes,
              ...quotationsRoute,
              ...ordersRoute,
              ...settingsRoute,
              ...usersRoute,
              ...workspacesRoute,
              ...authRoutes,
              ...dashboardRoutes,
              ...requestQuoteRoutes,
              ...devicesRoutes,
              ...overviewRoutes,
              ...activityLogRoutes,
              ...allOrganisationRoutes,
              ...addOrganisationRoutes,
              ...accountSettingsRoutes,
              ...enquiriesRoute,
              ...reviewQuoteRoute,
              ...partnerAdminRoutes,
              ...directOrderRoutes,
              ...teamRoutes,
              ...activateRoutes,
              ...integrationRoutes,
              ...reportsRoute,
              ...resourcessRoutes,
              ...deploymentsRoute
            ].map(({ path, component, key }, index) => (
              <Route exact path={path} component={component} key={key} />
            ))}

            {/* {cartRoutes.map(({ path, component, key }, index) => (
              <Route exact path={path} component={component} key={key} />
            ))} */}
            {/* {<Route path="*" exact={true} component={PageNotFound} />} */}
          </Switch>
          <ToastMessage />
        </Router>
      </GlobalContextProvider>
    </div>
  );
}

export default App;
