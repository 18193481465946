import React from "react";
import { Grid, List, ListItem } from "@material-ui/core";
import styles from "../../../assets/styles/cart.module.scss";

const cartSteps = [
  { label: "1", text: "Cart", step: 1 },
  { label: "2", text: "Shipping", step: 2 },
  { label: "3", text: "Checkout", step: 3 },
];
function CartNavigation(props) {
  const { step } = props;
  return (
    <Grid item md={12}>
      <div className={styles.cartNavigationWrapper}>
        <List className={styles.cartNavigationList + " listInline "}>
          {cartSteps.map((cart) => (
            <ListItem
              className={`${styles.cartNavigationListItem} paragraph listInlineItem ${
                cart.step <= step ? styles.active : ""
              }`}
            >
              <span className={styles.cartNavigationListNumber}>{cart.label}</span>
              {cart.text}
            </ListItem>
          ))}
        </List>
      </div>
    </Grid>
  );
}

export default CartNavigation;
