import { globalPostService } from "../../utils/globalApiServices";

export function deleteOrganisation(data = {}) {
  return new Promise((resolve, reject) => {
    globalPostService(`delete-org-details-v1`, data)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
