import { globalGetService, globalPostService } from "../../utils/globalApiServices";

export function getOrganisationName() {
  return new Promise((resolve, reject) => {
    globalGetService(`list-of-org-details-by-user-id-v1`)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function createQuotationApi(data = {}) {
  return new Promise((resolve, reject) => {
    globalPostService(`create-quot-req-v1`, data)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
