import React from "react";
import { Container } from "@material-ui/core";
import styles from "../../../assets/styles/team.module.scss";
import { imageBasePath } from "../../../constants";
import { CustomLink } from "../../../shared_ui_components";
import { useHistory } from "react-router-dom";
import TeamList from "../component/TeamList";

export default function Team() {
  const history = useHistory();

  const handleGoBack = () => {
    history.goBack();
  };
  return (
    <Container maxWidth="lg">
      <div className={styles.teamPage}>
        <div className={styles.teamWrapper}>
          <div className={styles.backBtnWrapper}>
            <CustomLink
              className="backBtn"
              text="Back to home"
              leftIcon={imageBasePath + "arrow_left_icon.svg"}
              onClick={handleGoBack}
            />
          </div>
          <div className={styles.teamHeaderWrapper}>
            <div className={styles.teamPageTitleWrapper}>
              <h2 className={styles.teamPageTitle + " subheading "}>All Team Members</h2>
            </div>
          </div>
          <TeamList />
        </div>
      </div>
    </Container>
  );
}
