import Quotations from "./container/Quotations";
import QuotationOrder from "./container/QuotationOrder";
import PlaceOrder from "./container/PlaceOrder";
import QuotationLayoutHoc from "../../hoc/quotationLayoutHoc";
import homeLayoutHoc from "../../hoc/homeLayoutHoc";
export const quotationsRoute = [
  {
    path: "/:id/quotations",
    component: QuotationLayoutHoc(Quotations),
    key: "quotation",
  },
  {
    path: "/:id/quotations/:_id",
    component: QuotationLayoutHoc(QuotationOrder),
    key: "quotationOrder",
  },
  // {
  //   path: "/:id/place-order",
  //   component: QuotationLayoutHoc(PlaceOrder),
  //   key: "place-order",
  // },
  {
    path: "/quotations",
    component: homeLayoutHoc(Quotations),
    key: "allQuotation",
  },
  {
    path: "/quotations/:_id",
    component: homeLayoutHoc(QuotationOrder),
    key: "quotationOrder",
  },
];
