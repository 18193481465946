import React, { Component } from "react";
import { Grid, Container } from "@material-ui/core";
import styles from "../../../assets/styles/auth.module.scss";
import { regExpression, imageBasePath } from "../../../constants";
import { fieldValidation } from "../../../utils/formValidation";
import SignInForm from "../components/SignInForm";
import { signInApi, socialSignUpApi } from "../ApiService";
import { toastFlashMessage } from "../../../utils";
import { getOrganisationName } from "../../request_quote/apiServices";
import { getStore } from "../../../store/GlobalStore";
import queryString from "query-string";

export default class SignIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {
        email: "",
        password: "",
      },
      error: {},
      errorCode: {
        email: {
          0: "",
          1: "Please add your Email Address",
          4: "Please enter valid Email Address",
        },
        emailObj: {
          requiredFlag: true,
          regexPattern: regExpression.email,
        },
        password: {
          0: "",
          1: "Please enter your Password!",
        },
        passwordObj: {
          requiredFlag: true,
        },
      },
      submitLoader: false,
    };
  }

  componentDidMount() {
    var base_url = window.location.origin;
    const querys = queryString.parse(this.props.location.search);
    const google_access_token = queryString.parse(this.props.location.hash)
      ? queryString.parse(this.props.location.hash).access_token
      : "";
    const linkedIn_token = querys.code;

    if (google_access_token) {
      this.setState({
        socialLoginLoader: true,
      });
      setTimeout(() => {
        socialSignUpApi({
          googleToken: google_access_token,
        }).then((response) => {
          this.setState({
            socialLoginLoader: false,
          });
          if (response.status) {
            localStorage.setItem("userInfo", JSON.stringify(response));
            this.props.history.push("/");
          } else {
            toastFlashMessage(response.message, "error");
          }
        });
      }, 300);
    } else if (linkedIn_token) {
      this.setState({
        socialLinkedinLoader: true,
      });
      setTimeout(() => {
        socialSignUpApi({
          redirectUri: base_url + "/signin",
          linkedInCode: linkedIn_token,
        }).then((response) => {
          this.setState({
            socialLinkedinLoader: false,
          });
          if (response.status) {
            localStorage.setItem("userInfo", JSON.stringify(response));
            this.props.history.push("/");
          } else {
            toastFlashMessage(response.message, "error");
          }
        });
      }, 300);
    } else {
      this.setState({
        submitLoader: false,
      });
    }

    const params = queryString.parse(window.location.search);
    if (params.error) {
      const errorMessage = params.error_description || "Login failed. Please try again.";
      window.opener &&
        window.opener.postMessage(
          {
            error: params.error,
            state: params.state,
            errorMessage,
            from: "Linked In",
          },
          window.location.origin
        );
      if (params.error === "user_cancelled_login") {
        window.close();
      }
    }
    if (params.code) {
      window.opener &&
        window.opener.postMessage(
          { code: params.code, state: params.state, from: "Linked In" },
          window.location.origin
        );
    }
    if (params.linkedin_redirect_url) {
      window.location.href = params.linkedin_redirect_url;
    }
  }
  handleChange = (key, val) => {
    this.setState({
      formData: {
        ...this.state.formData,
        [key]: val,
      },
      error: {
        ...this.state.error,
        [key]: "",
      },
    });
  };

  togglePassword = () => {
    this.setState({
      formData: {
        ...this.state.formData,
        password_show: !this.state.formData.password_show,
      },
    });
  };

  handleSubmit = () => {
    const { formData, errorCode } = this.state;
    const validateNewInput = {
      email:
        errorCode["email"][
          fieldValidation({
            ...errorCode["emailObj"],
            fieldval: formData.email,
          })
        ],
      password:
        errorCode["password"][
          fieldValidation({
            ...errorCode["passwordObj"],
            fieldval: formData.password,
          })
        ],
    };

    if (Object.keys(validateNewInput).every((k) => validateNewInput[k] == "")) {
      this.setState({
        submitLoader: true,
      });
      let updatedFormData = formData;
      // delete updatedFormData.password_show;
      signInApi({ ...updatedFormData, email: updatedFormData.email.trim() }).then((response) => {
        // console.log('location list ', response.message, response)
        if (response.status) {
          toastFlashMessage("Signed in successfully", "success");
          localStorage.setItem("userInfo", JSON.stringify(response));
          this.getAllOrganisationList();
          this.props.history.push("/home");
          this.setState((prevState) => ({
            ...prevState,
            submitLoader: false,
          }));
        } else {
          toastFlashMessage("Invalid email or password", "error");
          this.setState((prevState) => ({
            ...prevState,
            submitLoader: false,
          }));
        }
      });
    } else {
      this.setState({
        error: validateNewInput,
      });
    }
  };

  getAllOrganisationList = () => {
    getOrganisationName().then((response) => {
      if (response.status && response.orgList?.length) {
        const { dispatch } = getStore();
        dispatch({
          type: "SET_ORGANISATION_NAME",
          payload: response.orgList,
        });
      } else {
      }
    });
  };

  render() {
    const { formData, error, submitLoader } = this.state;
    return (
      <div className={styles.partnerSignin + " flexCentered"}>
        <Container maxWidth={"lg"}>
          <div className={styles.LogoWrapper}>
            <img src={imageBasePath + "logo.svg"} alt="logo" />
          </div>
          <Grid container direction="row" justifyContent="center" alignItems="center" spacing={2}>
            <Grid item md={6} xs={12}>
              <div className={styles.signInLeftHeading}>
                <h1 className={" heading4 "}>Sign In</h1>
                <p className={styles.leftSubHeading + " subheading "}>
                  Enter your registered email address and <br /> password to sign in
                </p>
              </div>
            </Grid>
            <Grid item md={4} xs={12}>
              <SignInForm
                formData={formData}
                error={error}
                submitLoader={submitLoader}
                handleChange={this.handleChange}
                togglePassword={this.togglePassword}
                handleSubmit={this.handleSubmit}
              />
            </Grid>
          </Grid>
        </Container>
      </div>
    );
  }
}
