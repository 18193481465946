import { TermsOfUse, PrivacyPolicy } from './containers';
import pageLayoutHoc from '../../hoc/pageLayoutHoc';
export const legalRoutes = [
    {
        path: '/terms-of-use',
        component: pageLayoutHoc(TermsOfUse),
        key: 'terms-of-use'
    },
    {
        path: '/privacy-policy',
        component: pageLayoutHoc(PrivacyPolicy),
        key: 'privacy-policy'
    }
]