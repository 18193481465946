import Partners from "./container/Partners";
import QuotationLayoutHoc from "../../../hoc/quotationLayoutHoc";
import PartnerDetails from "./container/PartnerDetails";
export const partnerAdminRoutes = [
    {
        path: "/partners",
        component: QuotationLayoutHoc(Partners),
        key: "partners",
    },
    {
        path: "/partner-details",
        component: QuotationLayoutHoc(PartnerDetails),
        key: "partner-details",
    },
];
