import Cart from "./container/Cart";
import Status from "./components/Status";
import pageLayoutHoc from "../../hoc/pageLayoutHoc";
export const cartRoutes = [
  {
    path: "/cart",
    component: pageLayoutHoc(Cart),
    key: "cart",
  },
  {
    path: "/cart/status",
    component: pageLayoutHoc(Status),
    key: "status",
  },
];
