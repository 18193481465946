import { Grid, List, ListItem } from "@material-ui/core";
import React from "react";
import styles from "../../../assets/styles/devices.module.scss";
import { imageBasePath } from "../../../constants";

export default function WorkspaceDetails(props) {
  const { deviceDetails }= props;

  //console.log(deviceDetails?.localAdmins?.fullName);
  return (
    <div className={styles.deviceWorkspaceDetails}>
      <div className={styles.workspaceDetailsHeading}>
        <h3 className={styles.workspaceDetailsTitle + " paragraph "}>
          Workspace Details
        </h3>
      </div>
      <div className={styles.workspaceDetailsRow}>
        <Grid container spacing={2}>
          <Grid item md={5}>
            <div className={styles.workspaceDetailsLeftBlk}>
              <p className={styles.workspaceDetailsText + " supportText "}>
                Operational Workspace
              </p>
            </div>
          </Grid>
          <Grid item md={7}>
            <div className={styles.workspaceDetailsRightBlk}>
              <div className={styles.workspaceIconWrapper}>
                <img src={imageBasePath + "workspace_location_icon.svg"} />
              </div>
              <p className={styles.workspaceDetailsRightText + " paragraph "}>
                {deviceDetails?.OrglocationId?.orgWorkSpaceName 
                  ? deviceDetails?.OrglocationId?.orgWorkSpaceName 
                  : "NA"
                }
              </p>
            </div>
          </Grid>
        </Grid>
      </div>
      <div className={styles.workspaceDetailsRow}>
        <Grid container spacing={2}>
          <Grid item md={5}>
            <div className={styles.workspaceDetailsLeftBlk}>
              <p className={styles.workspaceDetailsText + " supportText "}>
                Local Admins
              </p>
            </div>
          </Grid>
          <Grid item md={7}>
            <div className={styles.workspaceDetailsRightBlk}>
              <div className={styles.workspaceDetailsAdminList}>
                <List>
                  {deviceDetails?.localAdmins?.map((admin, key) => {
                    return (
                      <ListItem className={styles.workspaceIconWrapper}>
                        <img src={imageBasePath + "workspace_user_icon.svg"} />
                        <p
                          className={
                            styles.workspaceDetailsRightText + " paragraph "
                          }
                        >
                          {admin.fullName ? admin.fullName : admin.email}
                        </p>
                      </ListItem>
                    )
                  })}
                </List>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
