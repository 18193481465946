import React, { useState, useEffect } from "react";
import { Grid, Dialog } from "@material-ui/core";
import styles from "../../../assets/styles/team.module.scss";
import { imageBasePath } from "../../../constants";
import { getUserInfo, sortObj } from "../../../utils";
import { CustomLink, InputText, NoResult } from "../../../shared_ui_components";
import InviteTeamDialog from "./InviteTeamDialog";
import Shimmer from "./Shimmer";
import { SingleTeamList } from "./SingleTeamList";
import { getTeamMemberLists } from "../apiServices";

export default function TeamList() {
  const userInfo = getUserInfo();

  const [open, setOpen] = useState(false);
  const [teamMembersList, setTeamMembersList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [noStateStatus, setNoStateStatus] = useState(false);
  const [search, setSearch] = useState({
    val: "",
    list: [],
  });
  const [sort, setSort] = useState({
    field: "",
    asc: false,
    defaultSortUpImg: "",
    defaultSortDownImg: "",
  });

  const getAllTeamMemberLists = () => {
    getTeamMemberLists().then((response) => {
      if (response.status && response.data.length > 0) {
        setTeamMembersList(response.data);
        setLoading(false);
      } else {
        setLoading(false);
        setNoStateStatus(true);
      }
    });
  };

  useEffect(() => {
    getAllTeamMemberLists();
  }, []);

  const handleClickOpen = (e) => {
    e.preventDefault();
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleSearch = (val) => {
    setSearch((prevSearch) => ({
      val: val.toLowerCase(),
      list: teamMembersList.filter(
        (member) =>
          member?.userId?.fullName?.toLowerCase().includes(val.toLowerCase()) ||
          member?.userId?.email?.toLowerCase().includes(val.toLowerCase())
      ),
    }));
  };
  const handleSort = (sortObject) => {
    let data;
    if (sortObject.field === "fullName") {
      data = teamMembersList.map((user) => {
        return { fullName: user.userId.fullName, id: user._id };
      });
    } else if (sortObject.field === "roleName") {
      data = teamMembersList.map((role) => {
        return { roleName: role?.roleId?.roleName, id: role?._id };
      });
    } else if (sortObject.field === "updatedAt") {
      data = teamMembersList.map((date) => {
        return { updatedAt: date.updatedAt, id: date._id };
      });
    } else {
      data = teamMembersList.map((date) => {
        return { createdAt: date.createdAt, id: date._id };
      });
    }
    let list = sortObj(sortObject.field, data, sortObject.asc, sortObject.caseSensitive);
    let newList = [];
    list.forEach((team) => {
      newList = [
        ...newList,
        ...[
          {
            createdAt: teamMembersList.find((ele) => ele?._id === team?.id).createdAt,
            partnerId: teamMembersList.find((ele) => ele?._id === team?.id).partnerId,
            updatedAt: teamMembersList.find((ele) => ele?._id === team?.id).updatedAt,
            roleId: teamMembersList.find((ele) => ele._id === team?.id).roleId,
            userId: teamMembersList.find((ele) => ele?._id === team?.id).userId,
            _id: teamMembersList.find((ele) => ele?._id === team?.id)._id,
          },
        ],
      ];
    });

    setSort({
      field: sortObject.field,
      asc: sortObject.asc,
      defaultSortUpImg: sortObject.asc ? imageBasePath + "asc_arrow.svg" : imageBasePath + "up_arrow.svg",
      defaultSortDownImg: sortObject.asc ? imageBasePath + "down_arrow.svg" : imageBasePath + "des_arrow.svg",
    });
    if (sortObject.field === "updatedAt") {
      setTeamMembersList(newList.reverse());
    } else {
      setTeamMembersList(newList);
    }
  };

  return (
    <>
      {noStateStatus ? (
        <>
          <NoResult
            image="no_team_member.png"
            title="No team members found"
            subtext="Sharing is everything! Start inviting team members and share your work"
            btnText="+ Add team members"
            onClick={handleClickOpen}
          />
          {userInfo.userData?.role?.responsibilities?.createOrg && (
            <Dialog open={open}>
              <InviteTeamDialog handleClose={handleClose} getAllTeamMemberLists={getAllTeamMemberLists} />
            </Dialog>
          )}
        </>
      ) : (
        <>
          {loading === false && (
            <div className={styles.teamSearchBlk}>
              <Grid container direction="row" spacing={2} justifyContent="flex-end" alignItems="center">
                <Grid item md={3}>
                  <div className={styles.teamSearchInput}>
                    <InputText placeholder="Search here" onChange={(e) => handleSearch(e.target.value)} />
                    <img src={imageBasePath + "search_icon.svg"} alt="" />
                  </div>
                </Grid>
                {userInfo.userData?.role?.responsibilities?.createOrg ? (
                  <Grid item md={3}>
                    <div className={styles.addNewTeamMember}>
                      <CustomLink
                        onClick={handleClickOpen}
                        className={styles.addButton + " outlinedBtn "}
                        text="Add team members"
                        leftIcon={imageBasePath + "invite_user.svg"}
                      />
                      <Dialog open={open}>
                        <InviteTeamDialog
                          handleClose={handleClose}
                          getAllTeamMemberLists={getAllTeamMemberLists}
                        />
                      </Dialog>
                    </div>
                  </Grid>
                ) : null}
              </Grid>
            </div>
          )}
          <div className={styles.teamListBlk}>
            <Grid container>
              <Grid item md={12}>
                <div className={styles.teamList}>
                  <div className={styles.teamListInnerBlk}>
                    {loading ? (
                      <Shimmer type="teamsHeader" />
                    ) : (
                      <div className={styles.teamListTableTitle}>
                        <Grid container spacing={2}>
                          <Grid item md={12}>
                            <Grid
                              container
                              direction="row"
                              spacing={2}
                              justifyContent="flex-start"
                              alignItems="center"
                            >
                              <Grid item md={4}>
                                <div className={styles.teamTableTitleTextWrapper}>
                                  <p className={styles.teamTableTitleText + " paragraph "}>Name</p>
                                  <img
                                    src={
                                      sort.field === "fullName"
                                        ? sort.defaultSortUpImg
                                        : imageBasePath + "up_arrow.svg"
                                    }
                                    alt=""
                                    className="sortUp"
                                    onClick={() =>
                                      handleSort({
                                        field: "fullName",
                                        asc: !sort.asc,
                                        caseSensitive: true,
                                      })
                                    }
                                  />
                                  <img
                                    src={
                                      sort.field === "fullName"
                                        ? sort.defaultSortDownImg
                                        : imageBasePath + "down_arrow.svg"
                                    }
                                    alt=""
                                    className="sortDown"
                                    onClick={() =>
                                      handleSort({
                                        field: "fullName",
                                        asc: !sort.asc,
                                        caseSensitive: true,
                                      })
                                    }
                                  />
                                </div>
                              </Grid>
                              <Grid item md={3}>
                                <div className={styles.teamTableTitleTextWrapper}>
                                  <p className={styles.teamTableTitleText + " paragraph "}>Last Active</p>
                                  <img
                                    src={
                                      sort.field === "updatedAt"
                                        ? sort.defaultSortUpImg
                                        : imageBasePath + "up_arrow.svg"
                                    }
                                    alt=""
                                    className="sortUp"
                                    onClick={() =>
                                      handleSort({
                                        field: "updatedAt",
                                        asc: !sort.asc,
                                        caseSensitive: true,
                                      })
                                    }
                                  />
                                  <img
                                    src={
                                      sort.field === "updatedAt"
                                        ? sort.defaultSortDownImg
                                        : imageBasePath + "down_arrow.svg"
                                    }
                                    alt=""
                                    className="sortDown"
                                    onClick={() =>
                                      handleSort({
                                        field: "updatedAt",
                                        asc: !sort.asc,
                                        caseSensitive: true,
                                      })
                                    }
                                  />
                                </div>
                              </Grid>
                              <Grid item md={3}>
                                <div className={styles.teamTableTitleTextWrapper}>
                                  <p className={styles.teamTableTitleText + " paragraph "}>Permissions</p>
                                  <img
                                    src={
                                      sort.field === "roleName"
                                        ? sort.defaultSortUpImg
                                        : imageBasePath + "up_arrow.svg"
                                    }
                                    alt=""
                                    className="sortUp"
                                    onClick={() =>
                                      handleSort({
                                        field: "roleName",
                                        asc: !sort.asc,
                                        caseSensitive: true,
                                      })
                                    }
                                  />
                                  <img
                                    src={
                                      sort.field === "roleName"
                                        ? sort.defaultSortDownImg
                                        : imageBasePath + "down_arrow.svg"
                                    }
                                    alt=""
                                    className="sortDown"
                                    onClick={() =>
                                      handleSort({
                                        field: "roleName",
                                        asc: !sort.asc,
                                        caseSensitive: true,
                                      })
                                    }
                                  />
                                </div>
                              </Grid>
                              <Grid item md={2}>
                                <div className={styles.teamTableTitleTextWrapper}>
                                  <p className={styles.teamTableTitleText + " paragraph "}>Joined On</p>
                                  <img
                                    src={
                                      sort.field === "createdAt"
                                        ? sort.defaultSortUpImg
                                        : imageBasePath + "up_arrow.svg"
                                    }
                                    alt=""
                                    className="sortUp"
                                    onClick={() =>
                                      handleSort({
                                        field: "createdAt",
                                        asc: !sort.asc,
                                        caseSensitive: true,
                                      })
                                    }
                                  />
                                  <img
                                    src={
                                      sort.field === "createdAt"
                                        ? sort.defaultSortDownImg
                                        : imageBasePath + "down_arrow.svg"
                                    }
                                    alt=""
                                    className="sortDown"
                                    onClick={() =>
                                      handleSort({
                                        field: "createdAt",
                                        asc: !sort.asc,
                                        caseSensitive: true,
                                      })
                                    }
                                  />
                                </div>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </div>
                    )}
                    <div className={styles.teamListTableContent}>
                      {loading ? (
                        <Shimmer type="TeamLists" />
                      ) : search.val ? (
                        <>
                          {search.list.length === 0 ? (
                            <NoResult image="no_order.png" title="No search result found" />
                          ) : (
                            search.list.map((item) => (
                              <div key={item._id}>
                                <SingleTeamList {...item} />
                              </div>
                            ))
                          )}
                        </>
                      ) : (
                        teamMembersList.map((item) => (
                          <div key={item._id}>
                            <SingleTeamList {...item} />
                          </div>
                        ))
                      )}
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </>
      )}
    </>
  );
}
