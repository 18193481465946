import React from "react";
import { Grid, List, ListItem } from "@material-ui/core";
import styles from "../../../assets/styles/activityLog.module.scss";
import { InputText, InputSelect } from "../../../shared_ui_components";
import { imageBasePath } from "../../../constants";
import ActivityLogListItem from "./ActivityLogListItem";

export default function ActivityLogList(props) {
  return (
    <div className={styles.activityLogListWrapper}>
      <Grid container justifyContent="flex-end">
        <Grid item md={6}>
          <div className={styles.activityLogFilterBlk}>
            <div className={styles.activityLogSearchBlk}>
              <div className={styles.activityLogSearchInput}>
                <InputText placeholder="Search here" />
                <img src={imageBasePath + "search_icon.svg"} />
              </div>
            </div>
            <div className={styles.teamMemberSelect}>
              <InputSelect
                closeMenuOnSelect={true}
                isSearchable={false}
                placeholder={"Select Member"}
                noOptionsMessage={() => "No matches found"}
                //menuIsOpen={true}
                // value={productInfo.portfolio}
                // isCreatable={true}
                // isClearable/>
              />
            </div>
          </div>
        </Grid>
      </Grid>
      <div className={styles.activityLogListOuterWrapper}>
        <Grid>
          <Grid item md={12}>
            <div className={styles.activityLogTableTitleRow}>
              <Grid container spacing={2}>
                <Grid item md={8}>
                  <p className={styles.activityLogTableTitle + " supportText "}>
                    Team Member Activity
                  </p>
                </Grid>
                <Grid item md={4}>
                  <p className={styles.activityLogTableTitle + " supportText "}>
                    Time
                  </p>
                </Grid>
              </Grid>
            </div>
            <div className={styles.activityLogTableContent}>
              <ActivityLogListItem />
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
