import React from "react";
import styles from "../../../assets/styles/orgSettings.module.scss";
import SettingsInnerContainer from "../components/SettingsInnerContainer";

export default function Settings(props) {
  return (
    <div className={styles.settingsWrapper}>
      <div className={styles.settingsHeading}>
        <h2 className={styles.settingsTitle + " subheading "}>Settings</h2>
      </div>
      <SettingsInnerContainer />
    </div>
  );
}
