import React from "react";
import styles from "../../../assets/styles/users.module.scss";
import { Grid } from "@material-ui/core";
import { imageBasePath } from "../../../constants";
import trash from "../../../assets/images/trash.png";
import { format } from "date-fns";
import moment from "moment";
import { toastFlashMessage } from "../../../utils";
import { deleteUser } from "../apiServices";

export const UserDetail = ({ user, workspace, role, orgId, workSpaceId, getUserList}) => {
  // const diffDay = () => {
  //   return Math.ceil((new Date() - new Date(user?.updatedAt)) / (1000 * 60 * 60 * 24));
  // };
  const diffDay = () => {
    const hrs = moment().diff(moment(user?.updatedAt), "hours");
    if (hrs > 24) {
      return Math.floor(hrs / 24) === 1
        ? Math.floor(hrs / 24) + " day ago "
        : Math.floor(hrs / 24) + " days ago";
    } else {
      const showHours =
        Math.floor(hrs) === 1 ? Math.floor(hrs) + " hour ago" : Math.floor(hrs) + " hours ago";
      if (showHours == "0 hours ago") {
        const showMins =
          moment.utc(moment().diff(moment(user?.updatedAt), "mins")).format("mm") + " mins ago";
        if (showMins == "00 mins ago ") {
          return "few seconds ago";
        } else {
          return showMins;
        }
      } else {
        return showHours;
      }
    }
  };

  function deleteUserById() {
    const id = role === "Organisation Admin" ? orgId : workSpaceId;
    deleteUser({ userId: user._id, id, type: role }).then(response => {
      if (response.status) {
        getUserList();
        toastFlashMessage(response.message, "success");
      } else {
        toastFlashMessage(response.message, "error");
      }
    });
  }

    

  return (
    <div className={styles.userListItem}>
      <div className={styles.usersListTableRow}>
        <Grid container spacing={2}>
          <Grid item md={12}>
            <Grid container direction="row" spacing={2} justifyContent="flex-start" alignItems="center">
              <Grid item md={2}>
                <div className={styles.userContentWrapper}>
                  <div className={styles.userImageWrapper}>
                    <img
                      src={user?.imageURL ? user?.imageURL : imageBasePath + "user_profile.svg"}
                      className={styles.userBox}
                      alt=""
                    />
                  </div>
                  <div className={styles.userDetailWrapper}>
                    <p className={styles.userName + " paragraphSemiBold "}>
                      {user?.fullName ? user?.fullName : "N/A"}
                    </p>
                    <p className={styles.userEmail + " paragraphSemiBold "}>{user?.email}</p>
                  </div>
                </div>
              </Grid>
              <Grid item md={2}>
                <div className={styles.userContentWrapper}>
                  <p className={styles.lastActive + " paragraph "}>{diffDay()}</p>
                </div>
              </Grid>
              <Grid item md={2}>
                <div className={styles.userContentWrapper}>
                  <p className={styles.userRole + " paragraph "}>
                    {role ? role : "N/A"}
                  </p>
                </div>
              </Grid>
              <Grid item md={2}>
                <div className={styles.userContentWrapper}>
                  <p className={styles.assignedWorkspace + " paragraph "}>{workspace}</p>
                </div>
              </Grid>
              <Grid item md={2}>
                <div className={styles.userContentWrapper}>
                  <p className={styles.joinedDate + " paragraph "}>
                    {format(new Date(user?.createdAt), "dd/MM/yyyy")}
                  </p>
                </div>
              </Grid>
              <Grid item md={2}>
                <div className={styles.userContentWrapper}>
                <img
                      style={{cursor:"pointer"}}
                      src={trash}
                      alt=""
                      onClick={deleteUserById}
                    />
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};
