
export const fieldValidation = (data) => {
  if (data.requiredFlag) {
    if (data.fieldval && data.fieldval.toString().trim().length) {
      return checkForValidationPath(data);
    } else {
      return 1;
    }
  } else {
    if (data.fieldval && data.fieldval.toString().trim().length) {
      return checkForValidationPath(data);
    } else {
      return 0;
    }
  }
};

const checkForValidationPath = (data) => {
  
  if (data.regexPattern) {
    if (!checkPatternMatches(data.fieldval, data.regexPattern)) {
      return 4;
    }
  }
  return 0;
};



const checkPatternMatches = (fieldval, regexPattern) => {
  const newRegex = new RegExp(regexPattern);
  return newRegex.test(fieldval);
};
