import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import styles from "../../../assets/styles/quotations.module.scss";
import { imageBasePath, errorCodes } from "../../../constants";
import { CustomButton, InputText, CustomCheckbox, CustomLink, NoResult } from "../../../shared_ui_components";
import { currencyFormatter } from "../../../utils";
import { Link, useParams } from "react-router-dom";
import { getQuotationOrderByOrgId, getQuotationDetailById } from "../apiServices";
import { format } from "date-fns";
import { toastFlashMessage } from "../../../utils";
import { fieldValidation } from "../../../utils/formValidation";
import Shimmer from "./Shimmer";

function QuoteOrderForm(props) {
  const { step, handleUpdateStep, handleQuoteOrderFormData } = props;
  const { id, _id } = useParams();
  const [quotationRequest, setQuotationRequest] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [agree, setAgree] = useState(false);
  const [formData, setFormData] = useState({});
  const [loader, setLoader] = useState(true);
  const [error, setError] = useState({});
  const [noQuotationDetail, setNoQuotationDetail] = useState(false);

  useEffect(() => {
    getQuotationOrderByOrgId({ quotReqId: _id }).then((response) => {
      if (response.status && Object.keys(response.QuotDetails).length > 0) {
        setQuotationRequest(response.QuotDetails);
        setLoader(false);
      } else {
        setNoQuotationDetail(true);
        setLoader(false);
      }
    });
  }, [_id]);

  const handleUploadFile = (e) => {
    setSelectedFile(e.target.files[0]);
    handleChange("File", e.target.files[0]);
  };

  const handleValidate = () => {
    const form_Data = new FormData();
    form_Data.append("File", selectedFile);
    form_Data.append("poNumber", formData.poNumber);
    let validateNewInput = {};
    validateNewInput = {
      poNumber:
        errorCodes["poNumber"][fieldValidation({ ...errorCodes.poNumberObj, fieldval: formData.poNumber })],
      File: errorCodes["File"][fieldValidation({ ...errorCodes.FileObj, fieldval: selectedFile })],
    };
    if (Object.keys(validateNewInput).every((k) => validateNewInput[k] == "")) {
      handleQuoteOrderFormData(quotationRequest, selectedFile, formData.poNumber);
      handleUpdateStep(step + 1);
    } else {
      setError(validateNewInput);
    }
  };

  const handleFileDelete = () => {
    setSelectedFile(null);
  };

  const handleCheckBox = () => {
    setAgree(!agree);
  };

  const handleChange = (key, value) => {
    setFormData((form) => ({
      ...form,
      [key]: value,
    }));
    setError((preError) => ({
      ...preError,
      [key]: "",
    }));
  };

  return (
    <>
      {loader ? (
        <Shimmer type="quotationDetail" />
      ) : (
        <>
          <div className={styles.quoteOrderFormBlk}>
            <p className={styles.quoteFormLastUpdated + " supportText "}>
              Last updated on:{" "}
              <span>{format(new Date(quotationRequest?.quotationReqDetails?.updatedAt), "dd/MM/yyyy")}</span>
            </p>
            <div className={styles.quoteOrderForm}>
              <div className={styles.quoteOrderFormHeader}>
                <img src={imageBasePath + "quotation_success_icon.svg"} />
                <div className={styles.quoteOrderFormHeaderText}>
                  <p className={styles.quoteOrderNum + " subheading "}>
                    Quote{" "}
                    <span>
                      {quotationRequest?.quotationReqDetails?.quotationNo
                        ? quotationRequest?.quotationReqDetails?.quotationNo
                        : "NA"}
                    </span>
                  </p>
                  <p className={styles.requestedOn + " supportText "}>
                    Requested on{" "}
                    <span>
                      {format(new Date(quotationRequest?.quotationReqDetails?.requstedDate), "dd/MM/yyyy")}
                    </span>
                  </p>
                </div>
              </div>
              <div className={styles.PONumberWrapper}>
                <Grid container spacing={2}>
                  <Grid item md={6} xs={12}>
                    <div className={styles.PONumberFormInput}>
                      <InputText
                        label="PO number"
                        placeholder="PO number"
                        value={formData.poNumber || ""}
                        error={error.poNumber}
                        onChange={(e) => handleChange("poNumber", e.target.value)}
                      />
                    </div>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <div className={styles.attachPOWrapper}>
                      <label className={error.File ? "errorMsg" : " "}>
                        {selectedFile?.name?.length > 0 ? "Attached" : "Attach"} PO
                      </label>
                      {selectedFile?.name?.length > 0 ? (
                        <div className={styles.uploadFileWrapper}>
                          <img className={styles.fileImage} src={imageBasePath + "document_icon.svg"} />
                          <p className={styles.fileName}>{selectedFile?.name}</p>
                          <div className={styles.deleteDocument}>
                            <img
                              src={imageBasePath + "delete-bin-line.svg"}
                              style={{ width: "20px", height: "20px" }}
                              onClick={handleFileDelete}
                              alt=""
                            />
                          </div>
                        </div>
                      ) : (
                        <div className={styles.importButtonWrapper}>
                          <CustomLink className={styles.importButton + " outlinedBtn "} text="Upload" />
                          <input
                            type="file"
                            title=""
                            accept=".pdf,.doc,.docx,.jpg,.jpeg,.png,text/plain,text/html,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.xlsx, .xls, .csv"
                            className={styles.inputFile}
                            error={error.File}
                            onChange={handleUploadFile}
                          />
                          {error.File ? <h6 className="errorMsg">{error.File}</h6> : null}
                        </div>
                      )}
                    </div>
                  </Grid>
                </Grid>
              </div>
              <div className={styles.quoteOrderQuantityWrapper}>
                <div className={styles.quoteOrderQuantity}>
                  <Grid container spacing={2}>
                    <Grid item md={8}>
                      <p className={styles.quantityText + " paragraph "}>Quantity</p>
                    </Grid>
                    <Grid item md={4}>
                      <p className={styles.quantityCount + " paragraph "}>
                        {quotationRequest.Quantity}
                        <span className="paragraph">{quotationRequest.Quantity > 1 ? "Items" : "Item"}</span>
                      </p>
                    </Grid>
                  </Grid>
                </div>
                <div className={styles.quoteSubscriptionPeriod}>
                  <Grid container spacing={2}>
                    <Grid item md={8}>
                      <p className={styles.subscriptionText + " paragraph "}>Subscription period</p>
                    </Grid>
                    <Grid item md={4}>
                      <p className={styles.subscriptionCount + " paragraph "}>
                        {quotationRequest.subscriptionPeriod ? quotationRequest.subscriptionPeriod / 12 : 0}
                        <span className="paragraph">
                          {quotationRequest.subscriptionPeriod / 12 > 1 ? "years" : "year"}
                        </span>
                      </p>
                    </Grid>
                  </Grid>
                </div>
              </div>
              <div className={styles.quoteOrderPricingWrapper}>
                <div className={styles.quoteOrderPricing}>
                  <Grid container spacing={2}>
                    <Grid item md={8}>
                      <p className={styles.orderDetailsName + " paragraph "}>Hardware cost</p>
                    </Grid>
                    <Grid item md={4}>
                      <p className={styles.orderDetailsPrice + " paragraph "}>
                        ₹{currencyFormatter(quotationRequest.costOfVingBoxes)}
                      </p>
                    </Grid>
                  </Grid>
                </div>
                <div className={styles.quoteOrderPricing}>
                  <Grid container spacing={2}>
                    <Grid item md={8}>
                      <p className={styles.orderDetailsName + " paragraph "}>License cost</p>
                    </Grid>
                    <Grid item md={4}>
                      <p className={styles.orderDetailsPrice + " paragraph "}>
                        ₹{currencyFormatter(quotationRequest.subscFee)}
                      </p>
                    </Grid>
                  </Grid>
                </div>
                {/* <div className={styles.quoteOrderPricing}>
                  <Grid container spacing={2}>
                    <Grid item md={8}>
                      <p className={styles.orderDetailsName + " paragraph "}>Sub total</p>
                    </Grid>
                    <Grid item md={4}>
                      <p className={styles.orderDetailsPrice + " paragraph "}>
                        ₹{currencyFormatter(quotationRequest.SubTotalAmount)}
                      </p>
                    </Grid>
                  </Grid>
                </div> */}
                <div className={styles.quoteOrderPricing}>
                  <Grid container spacing={2}>
                    <Grid item md={8}>
                      <p className={styles.orderDetailsName + " paragraph "}>Discount Offered</p>
                    </Grid>
                    <Grid item md={4}>
                      <p className={styles.orderDetailsPrice + " paragraph "}>
                        <span className={styles.quoteOrderDiscountTag}>
                          {quotationRequest.percentageOffered}%{" "}
                        </span>{" "}
                        <span className={styles.quoteOrderDiscountNum}>
                          {quotationRequest.percentageOffered == 0 ? " " : "-"} ₹
                          {currencyFormatter(quotationRequest.discountAmount)}
                        </span>
                      </p>
                    </Grid>
                  </Grid>
                </div>
                <div className={styles.quoteOrderPricing}>
                  <Grid container spacing={2}>
                    <Grid item md={8}>
                      <p className={styles.orderDetailsName + " paragraph "}>Tax</p>
                    </Grid>
                    <Grid item md={4}>
                      <p className={styles.orderDetailsPrice + " paragraph " + styles.taxPrice}>
                        ₹{currencyFormatter(quotationRequest.taxAmount)}
                      </p>
                    </Grid>
                  </Grid>
                </div>
                <div className={styles.quoteOrderTotalPricing}>
                  <Grid container spacing={2}>
                    <Grid item md={8}>
                      <p className={styles.orderDetailsTotal + " paragraph "}>Total</p>
                    </Grid>
                    <Grid item md={4}>
                      <p className={styles.orderDetailsPrice + " subheading "}>
                        ₹{currencyFormatter(quotationRequest.TotalPrice)}
                      </p>
                    </Grid>
                  </Grid>
                </div>
              </div>
              <div className={styles.quoteOrderFormFooter}>
                <div class={styles.quoteAgree}>
                  <CustomCheckbox
                    type={"primary"}
                    label={"I agree with the quote offered and wish to proceed following"}
                    value={agree ? true : false}
                    onChange={() => handleCheckBox()}
                  />
                  <Link className={styles.quoteAgreeLink} to="/terms-of-use">
                    T&C
                  </Link>
                </div>
                <p class={styles.quoteOrderReevalText + " supportText "}>Need re-evaluation ?</p>
                <Grid container spacing={2}>
                  <Grid item md={6}>
                    <div>
                      <p className={styles.callBackBtn + " outlinedBtn "}>Give us a call</p>
                    </div>
                  </Grid>
                  <Grid item md={6}>
                    <div>
                      <CustomLink
                        text="Continue"
                        rightIcon={imageBasePath + "chevron_right_white_icon.svg"}
                        className={`${styles.continueBtn} primaryBtn ${
                          !formData?.poNumber || !agree || selectedFile == null ? "disabledBtn" : " "
                        }`}
                        onClick={() => {
                          handleValidate();
                        }}
                      />
                    </div>
                  </Grid>
                </Grid>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default QuoteOrderForm;
