import React from 'react';
import { imageBasePath } from "../constants";
function ExternalLink(props) {
    return (
        <a
      href={props.href}
      target={props.target}
      className={props.className}
      onClick={props.onClick}
      {...props}
    >
      {props.linkimg ? (
        <img src={imageBasePath + props.linkimg} alt={props.alt} />
      ) : null}
      <span>{props.linktext}</span>
    </a>
    );
}

export default ExternalLink;