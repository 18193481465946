import React, { useState, useEffect } from "react";
import { Grid, Tab, Tabs } from "@material-ui/core";
import styles from "../../../assets/styles/quotations.module.scss";
import { imageBasePath, reactSelectStylesSmall } from "../../../constants";
import { InputSelect, InputText, CustomPagination, NoResult } from "../../../shared_ui_components";
import { Link, useParams, useHistory } from "react-router-dom";
import { getQuotationListByOrgId, getAllQuotationList } from "../apiServices";
import Shimmer from "./Shimmer";
import { SingleQuotationList } from "./SingleQuotationList";
import { sortObj } from "../../../utils";

function QuotationList(props) {
  const history = useHistory();
  const { id } = useParams();

  const [selectedTab, setSelectedTab] = useState(0);
  const [allQuotationList, setAllQuotationList] = useState([]);
  const [loader, setLoader] = useState(true);
  const [noQuotationList, setNoQuotationList] = useState(false);
  const [search, setSearch] = useState({
    val: "",
    list: [],
  });
  const [sort, setSort] = useState({
    field: "",
    asc: false,
    defaultSortUpImg: "",
    defaultSortDownImg: "",
  });

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  useEffect(() => {
    if (id) {
      getQuotationListByOrgId({ orgId: id }).then((response) => {
        if (response.status && response.data.length > 0) {
          setAllQuotationList(response.data);
          setLoader(false);
          setNoQuotationList(false);
        } else {
          setLoader(false);
          setNoQuotationList(true);
        }
      });
    } else {
      getAllQuotationList().then((response) => {
        if (response.status && response.data.length > 0) {
          setAllQuotationList(response.data);
          setLoader(false);
          setNoQuotationList(false);
        } else {
          setLoader(false);
          setNoQuotationList(true);
        }
      });
    }
  }, [id]);

  const requestQuote = (e) => {
    e.preventDefault();
    e.stopPropagation();
    history.push("/request-quote");
  };

  const priceOfferedQuotationList = allQuotationList
    .filter(function (list) {
      return list.quotationStatus === "responded";
    })
    .map(function (priceOfferedList) {
      return priceOfferedList;
    });

  const handleSearch = (val) => {
    setSearch((prevSearch) => ({
      val: val,
      list: allQuotationList.filter((quotation) =>
        quotation.quotationNo?.toLowerCase().includes(val.toLowerCase())
      ),
    }));
  };

  const handleSort = (sortObject) => {
    let data = allQuotationList.map((quotation) => {
      return {
        ...quotation,
        quantity: Number(quotation.quantity),
      };
    });
    let list = sortObj(sortObject.field, data, sortObject.asc, sortObject.caseSensitive);
    setSort({
      field: sortObject.field,
      asc: sortObject.asc,
      defaultSortUpImg: sortObject.asc ? imageBasePath + `asc_arrow.svg` : imageBasePath + "up_arrow.svg",
      defaultSortDownImg: sortObject.asc ? imageBasePath + "down_arrow.svg" : imageBasePath + `des_arrow.svg`,
    });
    setAllQuotationList(list);
  };

  return (
    <div className={styles.quotationsListWrapper}>
      {loader ? (
        <Shimmer type="quotationLength" />
      ) : (
        <div className={styles.quotationHeading}>
          <h2 className={styles.quotationTitle + " subheading "}>
            Quotations{" "}
            <span className={styles.quotationTitleNum}>
              ({(selectedTab === 1 ? priceOfferedQuotationList : allQuotationList).length})
            </span>
          </h2>
        </div>
      )}
      {noQuotationList === false ? (
        <div>
          <div className={styles.quotationsListStatus}>
            <Tabs className={styles.quotationsListFilter} value={selectedTab} onChange={handleChange}>
              <Tab label="Quote Requests" className={styles.filterItem} />
              <Tab label="Price Offered" className={styles.filterItem} />
            </Tabs>
          </div>
          {loader ? (
            <Shimmer type="SearchIcon" />
          ) : (
            <div className={styles.quotationSearchSection}>
              <Grid container justifyContent="flex-end">
                <Grid item md={6}>
                  {/* <div className={styles.quotationSortInput}>
                  <InputSelect placeholder="Sort by" isSearchable={false} styles={reactSelectStylesSmall} />
                </div> */}
                  <div className={styles.quotationSearchBlk}>
                    <div className={styles.quotationSearchInput}>
                      <InputText placeholder="Search here" onChange={(e) => handleSearch(e.target.value)} />
                      <img src={imageBasePath + "search_icon.svg"} alt="" />
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
          )}
          <Grid container>
            <Grid item md={12}>
              <div className={styles.quotationsList}>
                <div className={styles.quotationsListInnerBlk}>
                  {loader ? (
                    <Shimmer type="quotationListHeader" />
                  ) : (
                    <>
                      <div className={styles.quotationsListTableTitle}>
                        <Grid container spacing={2}>
                          <Grid item md={7}>
                            <Grid container spacing={2}>
                              <Grid item md={5}>
                                <div className={styles.quotationsTableTitleTextWrapper}>
                                  <p className={styles.quotationsTableTitleText + " supportText "}>
                                    Quote Number
                                  </p>
                                  <img
                                    src={
                                      sort.field === "quotationNo"
                                        ? sort.defaultSortUpImg
                                        : imageBasePath + "up_arrow.svg"
                                    }
                                    alt=""
                                    className={styles.sortUp}
                                    onClick={() =>
                                      handleSort({
                                        field: "quotationNo",
                                        asc: !sort.asc,
                                        caseSensitive: true,
                                      })
                                    }
                                  />
                                  <img
                                    src={
                                      sort.field === "quotationNo"
                                        ? sort.defaultSortDownImg
                                        : imageBasePath + "down_arrow.svg"
                                    }
                                    alt=""
                                    className={styles.sortDown}
                                    onClick={() =>
                                      handleSort({
                                        field: "quotationNo",
                                        asc: !sort.asc,
                                        caseSensitive: true,
                                      })
                                    }
                                  />
                                </div>
                              </Grid>
                              <Grid item md={4}>
                                <div className={styles.quotationsTableTitleTextWrapper}>
                                  <p className={styles.quotationsTableTitleText + " supportText "}>
                                    Requested on
                                  </p>
                                  <img
                                    src={
                                      sort.field === "requstedDate"
                                        ? sort.defaultSortUpImg
                                        : imageBasePath + "up_arrow.svg"
                                    }
                                    alt=""
                                    className={styles.sortUp}
                                    onClick={() =>
                                      handleSort({
                                        field: "requstedDate",
                                        asc: !sort.asc,
                                        caseSensitive: true,
                                      })
                                    }
                                  />
                                  <img
                                    src={
                                      sort.field === "requstedDate"
                                        ? sort.defaultSortDownImg
                                        : imageBasePath + "down_arrow.svg"
                                    }
                                    alt=""
                                    className={styles.sortDown}
                                    onClick={() =>
                                      handleSort({
                                        field: "requstedDate",
                                        asc: !sort.asc,
                                        caseSensitive: true,
                                      })
                                    }
                                  />
                                </div>
                              </Grid>
                              <Grid item md={3}>
                                <div className={styles.quotationsTableTitleTextWrapper}>
                                  <p className={styles.quotationsTableTitleText + " supportText "}>Units</p>
                                  <img
                                    src={
                                      sort.field === "quantity"
                                        ? sort.defaultSortUpImg
                                        : imageBasePath + "up_arrow.svg"
                                    }
                                    alt=""
                                    className={styles.sortUp}
                                    onClick={() =>
                                      handleSort({
                                        field: "quantity",
                                        asc: !sort.asc,
                                        caseSensitive: false,
                                      })
                                    }
                                  />
                                  <img
                                    src={
                                      sort.field === "quantity"
                                        ? sort.defaultSortDownImg
                                        : imageBasePath + "down_arrow.svg"
                                    }
                                    alt=""
                                    className={styles.sortDown}
                                    onClick={() =>
                                      handleSort({
                                        field: "quantity",
                                        asc: !sort.asc,
                                        caseSensitive: false,
                                      })
                                    }
                                  />
                                </div>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item md={5}>
                            <Grid container spacing={2}>
                              <Grid item md={7}>
                                <div className={styles.quotationsTableTitleTextWrapper}>
                                  <p className={styles.quotationsTableTitleText + " supportText "}>
                                    Subscription Period
                                  </p>
                                  <img
                                    src={
                                      sort.field === "subscriptionPeriod"
                                        ? sort.defaultSortUpImg
                                        : imageBasePath + "up_arrow.svg"
                                    }
                                    alt=""
                                    className={styles.sortUp}
                                    onClick={() =>
                                      handleSort({
                                        field: "subscriptionPeriod",
                                        asc: !sort.asc,
                                        caseSensitive: false,
                                      })
                                    }
                                  />
                                  <img
                                    src={
                                      sort.field === "subscriptionPeriod"
                                        ? sort.defaultSortDownImg
                                        : imageBasePath + "down_arrow.svg"
                                    }
                                    alt=""
                                    className={styles.sortDown}
                                    onClick={() =>
                                      handleSort({
                                        field: "subscriptionPeriod",
                                        asc: !sort.asc,
                                        caseSensitive: false,
                                      })
                                    }
                                  />
                                </div>
                              </Grid>
                              <Grid item md={5}>
                                <div className={styles.quotationsTableTitleTextWrapper}>
                                  <p className={styles.quotationsTableTitleText + " supportText "}>
                                    Price Offered
                                  </p>
                                  <img
                                    src={
                                      sort.field === "quotationStatus"
                                        ? sort.defaultSortUpImg
                                        : imageBasePath + "up_arrow.svg"
                                    }
                                    alt=""
                                    className={styles.sortUp}
                                    onClick={() =>
                                      handleSort({
                                        field: "quotationStatus",
                                        asc: !sort.asc,
                                        caseSensitive: true,
                                      })
                                    }
                                  />
                                  <img
                                    src={
                                      sort.field === "quotationStatus"
                                        ? sort.defaultSortDownImg
                                        : imageBasePath + "down_arrow.svg"
                                    }
                                    alt=""
                                    className={styles.sortDown}
                                    onClick={() =>
                                      handleSort({
                                        field: "quotationStatus",
                                        asc: !sort.asc,
                                        caseSensitive: true,
                                      })
                                    }
                                  />
                                </div>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </div>
                    </>
                  )}
                  <div className={styles.quotationsListTableContent}>
                    {loader ? (
                      <Shimmer type="quotationList" />
                    ) : search.val ? (
                      <>
                        {search.list.length === 0 ? (
                          <NoResult image="no_order.png" title="No search result found" />
                        ) : (
                          (selectedTab === 1
                            ? search.list.filter((list) => list.quotationStatus === "responded")
                            : search.list
                          ).map((item, key) => (
                            <div key={key}>
                              <SingleQuotationList item={item} />
                            </div>
                          ))
                        )}
                      </>
                    ) : (
                      (selectedTab === 1 ? priceOfferedQuotationList : allQuotationList).map((item, key) => (
                        <>
                          <div key={key}>
                            <SingleQuotationList item={item} />
                          </div>
                        </>
                      ))
                    )}
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
          {/* <div className="customPagination">
          <CustomPagination 
            count={6}
            />
        </div> */}
        </div>
      ) : (
        <NoResult
          image="no-order-found.svg"
          title="No Quotations found"
          subtext="Looks like you have not yet raised any quotation, start requesting quotes to place bulk orders."
          btnText="Request quote"
          onClick={requestQuote}
        />
      )}
    </div>
  );
}

export default QuotationList;
