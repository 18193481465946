import { globalPostService } from "../../utils/globalApiServices";

export function updateUserProfile(query = {}) {
  return new Promise((resolve, reject) => {
    globalPostService(`update-user-details-v1`, query)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function updateUserPassword(query = {}) {
  return new Promise((resolve, reject) => {
    globalPostService(`user-change-password-v1`, query)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
