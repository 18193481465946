import React from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { createTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import Paper from "@material-ui/core/Paper";

const ParticipantsListTheme = createTheme({
  palette: {
    secondary: {
      main: "#344552",
    },
  },
  typography: {
    fontFamily: ["Inter", "sans-serif"].join(","),
  },
});

const useStyles = makeStyles({
  root: {
    marginTop: "40px",
    maxWidth: "1024px",
    margin: "auto",
  },
  paperRoot: {
    marginBottom: "40px",
    padding: "30px",
  },
  title: {
    fontWeight: "bold",
    marginTop: "30px",
    fontSize:"30px"
  }
});

function ContactSupport() {
  const classes = useStyles();
  return (
    <div className={classes.root} style={{minHeight:"540px"}}>
      <ThemeProvider theme={ParticipantsListTheme}>
        <Paper className={classes.paperRoot} style={{margin:"0px 10px"}}>
          <Typography variant="h4" align="center" className={classes.title}>
            Support
          </Typography>
          <div style={{ padding:"30px" }}>
            <Typography variant="subtitle1" color="secondary" gutterBottom style={{fontSize:"18px"}}>
              Ving application support is currently available only through
              email. You can contact us at{" "}
              <strong>
                <a
                  href="mailto:hello@letsving.com"
                  style={{ color: "#4361ee" }}
                >
                  hello@letsving.com
                </a>
              </strong>{" "}
              . Support hours are from{" "}
              <strong>9:30 AM to 5:30 PM IST (4:00AM to 12:00PM UTC)</strong>
            </Typography>
          </div>
        </Paper>
      </ThemeProvider>
    </div>
  );
}

export default ContactSupport;