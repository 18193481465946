import React, { useState } from "react";
import { imageBasePath } from "../../../constants";
import { SquareRadioButton } from "../../../shared_ui_components";
import styles from "../../../assets/styles/cart1.module.scss";
import CustomLink from "../../../shared_ui_components/CustomLink";

const NumberOfBox = [
  {
    id: 1,
    text: "1",
    checked: true,
  },
  {
    id: 2,
    text: "2",
    checked: false,
  },
  {
    id: 3,
    text: "3",
    checked: false,
  },
  {
    id: 4,
    text: "4",
    checked: false,
  },
  {
    id: 5,
    text: "5",
    checked: false,
  },
  {
    id: 6,
    text: "6",
    checked: false,
  },
  {
    id: 7,
    text: "7",
    checked: false,
  },
  {
    id: 8,
    text: "8",
    checked: false,
  },
  {
    id: 9,
    text: "9",
    checked: false,
  },
  {
    id: 10,
    text: "10",
    checked: false,
  },
];

function OrderDetail(props) {
  const {
    cartValue,
    handleCartValue,
    handleUpdateStep,
    step,
    subscriptionPeriodOpt = [],
    cartDetails: { units, devicePriceId },
    handleChange,
  } = props;

  const [quantityId, setQuantityId] = useState("");
  const [subscriptionId, setSubscriptionId] = useState("");
  const handleBoxOnClick = (id) => {
    setQuantityId(id);
  };

  return (
    <div className={styles.OrderDetail}>
      <div className={styles.OrderDetailWrapper}>
        <h6 className={styles.orderTitle + " heading6"}>Please provide us your order details</h6>
        <div>
          <p className={styles.quantityTitle + " paragraph"}>Quantity</p>
          <div className={styles.itemNumber}>
            {NumberOfBox.map((item) => (
              <SquareRadioButton
                text={item.text}
                checked={item.id === units}
                onClick={() => handleChange("units", item.id)}
              />
            ))}
          </div>
        </div>
        <div>
          <p className={styles.moreQuantity + " paragraph"}>
            Need more quantity?
            <span className={styles.moreLink + " paragraph"}>
              <a
                className="subheading2"
                href="https://forms.gle/NG1T7ept8LvxHh5W8"
                target="_blank"
                rel="noreferrer"
              >
                Enquire now
              </a>
            </span>
          </p>
        </div>
        <p className={styles.subTitle + " paragraph"}>Subscription period</p>
        <div className={styles.subscriptionPeriodBlk}>
          <ul className={styles.subNumber + " listInline"}>
            {subscriptionPeriodOpt.map((period) => (
              <li className={`listInlineItem ${styles.subButton2}`}>
                {period.discount ? (
                  <span className={styles.discountBanner}>{period.discount}% OFF</span>
                ) : null}
                <SquareRadioButton
                  text={`${period.subscriptionPeriod / 12} ${
                    period.subscriptionPeriod / 12 > 1 ? "Years" : "Year"
                  }`}
                  checked={period._id === devicePriceId ? true : false}
                  type="largeButton"
                  onClick={() => handleChange("devicePriceId", period._id)}
                />
              </li>
            ))}
          </ul>
        </div>
        <div className={styles.subInfo}>
          <img src={imageBasePath + "information-line.svg"} alt="info-icon" />
          <p className={styles.subInfoText + " paragraph"}>
            We will ship the devices along with the device <br /> subscription choosen by you.
          </p>
        </div>
        <CustomLink
          className={styles.subBtn + " primaryBtn"}
          onClick={() => handleUpdateStep(step + 1)}
          text="Continue to Shipping Address"
          rightIcon={imageBasePath + "btn_arrow_right_icon.svg"}
        />
      </div>
    </div>
  );
}

export default OrderDetail;
