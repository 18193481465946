import { useState, useRef } from "react";
import { Grid, Link, Menu, MenuItem, Tooltip } from "@material-ui/core";
import styles from "../../../assets/styles/workspaces.module.scss";
import { imageBasePath } from "../../../constants";
import { getUserInfo } from "../../../utils";
import { CustomLink } from "../../../shared_ui_components";
import { AddRoomDrawer } from ".";

function WorkspaceRoomListItem({
  _id,
  roomName,
  noOfSeats,
  deviceStatus,
  additionalDevice,
  deactivateWorkspaceRoom,
  editWorkspaceRoom,
  roomStatus,
}) {
  const [openAddRoom, setOpenAddRoom] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDeactivateRoom = (id) => {
    deactivateWorkspaceRoom(id);
    setAnchorEl(null);
  };

  const handleEditRoom = (id) => {
    editWorkspaceRoom(id);
    setAnchorEl(null);
  };

  const userInfo = getUserInfo();

  const addDevices = (additionalDevice) => {
    const deviceList = additionalDevice
      .map((ele) => (ele.deviceName ? ele.deviceName : null))
      .filter((element) => element);
    if (deviceList.length === 0) {
      return "N/A";
    } else if (deviceList.length < 4) {
      return deviceList.map((device) => device).join(", ");
    } else {
      return (
        <>
          {deviceList
            .map((device) => device)
            .slice(0, 3)
            .join(", ")}
          <Tooltip
            title={deviceList
              .map((device) => device)
              .slice(3, deviceList.length)
              .join(", ")}
            placement="top-start"
          >
            <span style={{ color: "blue" }}> +{deviceList.length - 3} more</span>
          </Tooltip>
        </>
      );
    }
  };

  return (
    <>
      <div className={styles.workspaceRoomListContentRow}>
        <div className={styles.workspaceRoomListItem}>
          <Link
            // className={
            //   deviceStatus === false
            //     ? styles.disabledRow + " " + styles.workspaceRoomListItemLink
            //     : styles.workspaceRoomListItemLink
            // }
            className={styles.workspaceRoomListItemLink}
          >
            <Grid container>
              <Grid item md={7}>
                <Grid container spacing={2}>
                  <Grid item md={4}>
                    <div className={styles.workspaceRoomNameWrapper}>
                      <img
                        src={
                          deviceStatus
                            ? imageBasePath + "room_assigned_icon.svg"
                            : imageBasePath + "room_not_assigned_icon.svg"
                        }
                      />
                      <p className={styles.workspaceRoomName + " paragraph "}>{roomName}</p>
                    </div>
                  </Grid>
                  <Grid item md={4}>
                    <p className={styles.workspaceRoomSeatingCapacity + " paragraph "}>{noOfSeats}</p>
                  </Grid>
                  <Grid item md={4}>
                    <div className={styles.workspaceRoomDeviceStatusWrapper}>
                      <CustomLink
                        text={deviceStatus ? "Assigned" : "Not Assigned"}
                        leftIcon={
                          deviceStatus
                            ? imageBasePath + "assigned_status_icon.svg"
                            : imageBasePath + "not_assigned_status_icon.svg"
                        }
                        className={
                          deviceStatus
                            ? styles.workspaceRoomDeviceStatus + " " + styles.assigned
                            : styles.workspaceRoomDeviceStatus + " " + styles.notAssigned
                        }
                      />
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={5}>
                <Grid container spacing={2}>
                  <Grid item md={10}>
                    <p className={styles.workspaceRoomAdditionalDevices + " paragraph "}>
                      {addDevices(additionalDevice)}
                    </p>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <div className={styles.moreAction}>
              <img
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={handleClick}
                src={imageBasePath + "more_vertical_dark_icon.svg"}
              />

              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                {userInfo.userData?.role?.responsibilities?.updateRoom ? (
                  <MenuItem onClick={() => handleEditRoom(_id)}>Edit</MenuItem>
                ) : null}
                {/* <MenuItem onClick={() => handleDeactivateRoom(_id)}>Deactivate</MenuItem> */}
                <MenuItem onClick={() => handleDeactivateRoom(_id)}>Delete</MenuItem>
              </Menu>
            </div>
          </Link>
        </div>
      </div>
      <AddRoomDrawer handleClose={() => setOpenAddRoom(false)} open={openAddRoom} />
    </>
  );
}

export default WorkspaceRoomListItem;
