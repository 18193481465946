import React from "react";
import { Grid, List, ListItem } from "@material-ui/core";
import styles from "../../../assets/styles/orgSettings.module.scss";
import { Skeleton } from "@material-ui/lab";

export default function Shimmer(props) {
  return (
    <>
      {props.type == "wallpaperListLength" && (
        <>
          {[0].map((item, key) => (
            <div className={styles.wallpaperCounts}>
              <Skeleton
                variant="text"
                width={120}
                height={25}
                style={{
                  display: "block",
                  verticalAlign: "middle",
                  transform: "none",
                }}
              />
            </div>
          ))}
        </>
      )}
      {props.type == "wallpaperLists" && (
        <>
          {[0, 1, 2, 3, 4, 5, 6, 7, 8].map((item, key) => (
            <div className={styles.wallpapersList} style={{margin:"10px"}}>
              <Skeleton
                variant="text"
                width={200}
                height={100}
                style={{
                  display: "block",
                  verticalAlign: "middle",
                  transform: "none",
                }}
              />
            </div>
          ))}
        </>
      )}
      {props.type == "domainLength" && (
        <>
          {[0].map((item, key) => (
            <div className={styles.domainsWrapper}>
              <Skeleton
                variant="text"
                width={120}
                height={25}
                style={{
                  display: "block",
                  verticalAlign: "middle",
                  transform: "none",
                }}
              />
            </div>
          ))}
        </>
      )}
      {props.type == "domain" && (
        <>
          {[0, 1, 2, 3, 4].map((item, key) => (
            <div className={styles.domainsWrapper}>
              <div className={styles.domainAddedListWrapper}>
                <div className={styles.addedDomainListItem}>
                  <div className={styles.addedDomainListItemLeftBlk}>
                    <Skeleton
                      variant="circle"
                      width={40}
                      height={40}
                      style={{
                        display: "block",
                        verticalAlign: "middle",
                        transform: "none",
                        marginBottom: 16,
                      }}
                    />
                    <div className={styles.addedDomainName + " subheading2"}>
                      <Skeleton
                        variant="text"
                        width={80}
                        height={25}
                        style={{
                          display: "block",
                          verticalAlign: "middle",
                          transform: "none",
                          marginBottom: 18,
                        }}
                      />
                    </div>
                  </div>
                  <div className={styles.addedDomainListItemRightBlk}>
                    <Skeleton
                      variant="circle"
                      width={40}
                      height={40}
                      style={{
                        display: "block",
                        verticalAlign: "middle",
                        transform: "none",
                        marginBottom: 16,
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </>
      )}
    </>
  );
}
