import React, { useState, useEffect } from "react";
import styles from "../../../assets/styles/workspaces.module.scss";
import { imageBasePath, reactSelectStylesLarge } from "../../../constants";
import { CustomLink, CustomButton, InputText, InputSelect } from "../../../shared_ui_components";
import { Grid, Dialog } from "@material-ui/core";
import SetWallpaper from "./SetWallpaper";
import { errorCodes } from "../../../constants";
import { fieldValidation } from "../../../utils/formValidation";
import InviteLocalAdmins from "./InviteLocalAdmins";
import { City, State } from "../../../utils/cityState";
import { createWorkspace, editWorkspaceListing, getGeoLoaction } from "../apiServices";
import { toastFlashMessage } from "../../../utils";
import { useParams } from "react-router-dom";
import useTimezone from "../../../utils/useTimezone";

export default function AddManually(props) {
  const { getAllWorkspaceList, editWorkspace } = props;
  const { id, _id } = useParams();
  const [validateLoader, setValidateLoader] = useState(false);
  const [validate, setValidate] = useState(false);
  const [formData, setFormData] = useState({
    orgId: id,
    orgLocId: _id,
    //rooms: 1,
    email: [],
  });

  const [countryOption, setCountryOption] = useState([]);
  const [cityOption, setCityOption] = useState([]);
  const [stateOption, setStateOption] = useState([]);
  const [temperatureTypes,setTemperatureTypes]=useState([{
    label:"Fahrenheit",
    value:"F"
  },{
    label:"Celsius",
    value:"C"
  }])
  const [errors, setErrors] = useState({});
  const [errCodes, setErrCodes] = useState(errorCodes);
  const timezoneStatus = useTimezone();

  useEffect(() => {
    getGeoLoaction("/countries.json").then(countryList => {
      let countries = [];
      countryList.forEach(country => {
        countries.push({
          label: country.name,
          value: country.name,
          code: country.code
        });
      });
      setCountryOption(countries);
    });
  }, []);

  useEffect(() => {
    if (formData.countryName?.code) {
      getGeoLoaction(`/${formData.countryName.code}/states.json`).then(
        statesList => {
          let states = [];
          statesList.forEach(state => {
            states.push({
              label: state.name,
              value: state.name,
              code: state.code
            });
          });
          setStateOption(states);
        }
      );
    }
  }, [formData.countryName?.code]);

  useEffect(() => {
    if (formData.countryName && formData.stateName?.code) {
      getGeoLoaction(
        `/${formData.countryName.code}/${formData.stateName.code}-cities.json`
      ).then(cityList => {
        let cities = [];
        cityList.forEach(city => {
          cities.push({
            label: city.name,
            value: city.name,
            lat: city.lat,
            lon: city.lon
          });
        });
        setCityOption(cities);
      });
    }
  }, [formData.stateName?.code]);

  const timezones = timezoneStatus?.data?.list || [];
  const handleChange = (key, val) => {
    // if (key === "workSpaceWallpaper") {
    //   setImageUrl(val);
    // }
    if (key === "stateName") {
      setFormData((formData) => ({
        ...formData,
        [key]: val,
        // cityName: null,
      }));
      setErrors((errors) => ({
        ...errors,
        [key]: "",
      }));
      // setCityList(val);
    } else if (key === "email") {
      if (formData.email.includes(val.toLowerCase())) {
        return;
      }
      setFormData((formData) => ({
        ...formData,
        [key]: val,
        email: [...formData.email, val],
      }));
      setErrors((errors) => ({
        ...errors,
        [key]: "",
      }));
    } else {
      setFormData((formData) => ({
        ...formData,
        [key]: val,
      }));
    }
    setErrors((errors) => ({
      ...errors,
      [key]: "",
    }));
  };

  const handleDeleteAdmins = (key) => {
    const updatedAdmins = formData.email.filter((email, index) => index !== key);
    setFormData((formData) => ({
      ...formData,
      email: updatedAdmins,
    }));
  };

  const handleSubmit = (newWorkspace) => {
    const validateNewInput = {
      orgWorkSpaceName:
        errCodes["orgWorkSpaceName"][
        fieldValidation({ ...errCodes.orgWorkSpaceNameObj, fieldval: formData.orgWorkSpaceName })
        ],
      pincode: errCodes["pincode"][fieldValidation({ ...errCodes.pincodeObj, fieldval: formData.pincode })],
      address: errCodes["address"][fieldValidation({ ...errCodes.addressObj, fieldval: formData.address })],
      countryName:
        errCodes["countryName"][fieldValidation({ ...errCodes.countryNameObj, fieldval: formData.countryName })],
      cityName:
        errCodes["cityName"][fieldValidation({ ...errCodes.cityNameObj, fieldval: formData.cityName })],
      stateName:
        errCodes["stateName"][fieldValidation({ ...errCodes.stateNameObj, fieldval: formData.stateName })],

    };
    //console.log(validateNewInput, "validateNewInput");
    if (Object.keys(validateNewInput).every((k) => validateNewInput[k] == "")) {
      if (newWorkspace === "addAnotherWorkspace") {
        setValidateLoader(false);
        setValidate(true);
      } else {
        setValidateLoader(true);
        setValidate(false);
      }

      if (editWorkspace) {
        //const form_data = { ...formData, workSpaceLogo: imageUrl };
        const form_data = {
          ...formData,
          countryName: formData.countryName.value,
          countryCode: formData.countryName.code,
          stateName: formData.stateName.value,
          stateCode: formData.stateName.code,
          cityName: formData.cityName.value,
          lat: formData.cityName.lat,
          lon: formData.cityName.lon
        }
        editWorkspaceListing(form_data).then((response) => {
          if (response.status) {
            setValidateLoader(false);
            toastFlashMessage("Workspace edited succesfully", "success");
            props.handleCloseWorkSpaceDrawer();
          } else {
            toastFlashMessage("Something went wrong while editing workspace", "error");
            setValidateLoader(false);
          }
        });
      } else {
        const form_data = {
          ...formData,
          countryName: formData.countryName.value,
          countryCode: formData.countryName.code,
          stateName: formData.stateName.value,
          stateCode: formData.stateName.code,
          cityName: formData.cityName.value,
          lat: formData.cityName.lat,
          lon: formData.cityName.lon
        }
        createWorkspace(form_data).then((response) => {
          if (response.status) {
            setValidateLoader(false);
            getAllWorkspaceList();
            toastFlashMessage("Workspace added succesfully", "success");
            if (newWorkspace === "addAnotherWorkspace") {
              setValidate(false);
              setFormData((formData) => ({
                ...formData,
                orgId: id,
                //rooms: 1,
                email: [],
                orgWorkSpaceName: "",
                pincode: "",
                address: "",
                countryName: "",
                cityName: "",
                stateName: "",
                workSpaceWallpaper: "",
                temperatureType:"C"
              }));
            } else {
              props.handleClose();
            }
          } else {
            toastFlashMessage("Something went wrong while adding workspace", "error");
            setValidateLoader(false);
          }
        });
      }
    } else {
      setErrors(validateNewInput);
    }
  };

  const handleAddAnotherBtn = (newWorkspace) => {
    handleSubmit(newWorkspace);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    handleSubmit();
  };

  useEffect(() => {
    if (editWorkspace) {
      // setCityList(editWorkspace?.workspaceDetails?.stateName);
      setFormData((form) => ({
        ...form,
        orgWorkSpaceName: editWorkspace?.workspaceName,
        pincode: editWorkspace?.workspaceDetails?.pincode,
        address: editWorkspace?.workspaceDetails?.address,
        countryName: {
          value: editWorkspace?.workspaceDetails?.countryName,
          code: editWorkspace?.workspaceDetails?.countryCode

        },

        stateName: {
          value: editWorkspace?.workspaceDetails?.stateName,
          code: editWorkspace?.workspaceDetails?.stateCode,
        },
        cityName: {
          value: editWorkspace?.workspaceDetails?.cityName,
          lat: editWorkspace?.workspaceDetails?.lat,
          lon: editWorkspace?.workspaceDetails?.lon
        },

       temperatureType:editWorkspace?.temperatureType || "C",
        //rooms: editWorkspace.rooms.length,
        email: editWorkspace?.workspaceLocalAdmins?.map((admin) => admin?.userId?.email),
        workSpaceWallpaper: editWorkspace?.workspaceWallpaper,
        timezone: editWorkspace?.workspaceTimezone
      }));
    }
  }, [editWorkspace]);

  // const setCityList = (state) => {
  //   const updatedCityList = City.filter((key) => key.state === state).map((city) => {
  //     return { label: city.city, value: city.city };
  //   });
  //   setCityOption(updatedCityList);
  // };

  return (
    <div className={styles.addManuallyWrapper}>
      <form onSubmit={(e) => handleFormSubmit(e)}>
        <div className={styles.addWorkspaceForm}>
          <Grid container direction="row" spacing={2} justifyContent="center" alignItems="center">
            <Grid item md={6}>
              <InputText
                label="Workspace name *"
                placeholder="Bangalore South"
                value={formData.orgWorkSpaceName}
                onChange={(e) => handleChange("orgWorkSpaceName", e.target.value)}
                error={errors.orgWorkSpaceName}
              />
            </Grid>
            <Grid item md={6}>
              <InputText
                type="number"
                label="Pincode *"
                placeholder="560102"
                onChange={(e) => handleChange("pincode", e.target.value)}
                error={errors.pincode}
                value={formData.pincode}
              />
            </Grid>
            <Grid item md={12}>
              <InputText
                label="Address *"
                //multiline
                placeholder="447, Ground Floor, 17th Cross Road, Sector 4, HSR Layout"
                onChange={(e) => handleChange("address", e.target.value)}
                error={errors.address}
                value={formData.address}
              />
            </Grid>
            <Grid item md={6}>
              <InputSelect
                label="Country *"
                placeholder="Select Country"
                options={countryOption}
                error={errors.countryName}
                value={
                  formData.countryName?.value ? countryOption.find((opt) => opt.value === formData.countryName.value) : null
                }
                onChange={(state) => handleChange("countryName", state)}
                styles={reactSelectStylesLarge}
              />
            </Grid>
            <Grid item md={6}>
              <InputSelect
                label="State *"
                placeholder="Select State"
                options={stateOption}
                error={errors.stateName}
                value={
                  formData.stateName?.value ? stateOption.find((opt) => opt.value === formData.stateName.value) : null
                }
                onChange={(state) => handleChange("stateName", state)}
                styles={reactSelectStylesLarge}
              />

            </Grid>
            <Grid item md={12}>
              <InputSelect
                label={"City *"}
                placeholder={"Select City"}
                options={cityOption}
                error={errors.cityName}
                value={formData.cityName?.value ? cityOption.find((city) => city.value === formData.cityName.value) : null}
                onChange={(city) => handleChange("cityName", city)}
                styles={reactSelectStylesLarge}
              />
            </Grid>
            <Grid item md={6}>
              <InputText
                type="string"
                label="Latitude"
                placeholder=""
                disabled={true}
                value={formData.cityName?.lat}
              />
            </Grid>
            <Grid item md={6}>
              <InputText
                type="string"
                label="Longitude"
                placeholder=""
                disabled={true}
                value={formData.cityName?.lon}
              />
            </Grid>
            <Grid item md={12}>
              <InputSelect
                label={"Temperature "}
                placeholder={"Select Temperature Type"}
                options={temperatureTypes}
                value={formData.temperatureType ? temperatureTypes.find((temp) => temp.value === formData.temperatureType) : null}
                onChange={(temp) => handleChange("temperatureType", temp.value)}
                styles={reactSelectStylesLarge}
              />
            </Grid>
            <Grid item md={12}>
              <InputSelect
                label={"TimeZone*"}
                placeholder={"Select Timezone"}
                options={timezones}
                error={errors.cityName}
                value={
                  formData.timezone
                    ? timezones.find((t) => t.value === formData.timezone)
                    : null
                }
                onChange={(timeZone) =>
                  handleChange("timezone", timeZone && timeZone.value)
                }
                styles={reactSelectStylesLarge}
              />
            </Grid>
          </Grid>
        </div>
        <div className={styles.addWorkspaceWallpaper}>
          <Grid container direction="row" spacing={2} justifyContent="space-between" alignItems="center">
            <Grid item md={7}>
              <p className={styles.wallpaperTitle + " label"}>Set Workspace Wallpaper</p>
              <p className={styles.wallpaperSubTitle + " label"}>
                This action will set the default wallpaper of all the devices in this workspace
              </p>
            </Grid>
            <Grid item md={2}>
              {formData?.workSpaceWallpaper?.includes(".mp4") ? (
                <video
                  width="100%"
                  height="100%"
                  src={formData?.workSpaceWallpaper}
                  alt=""
                />
              ) : (
                <img
                  width="100%"
                  height="100%"
                  src={
                    formData?.workSpaceWallpaper
                      ? formData?.workSpaceWallpaper
                      : imageBasePath + "workspace_wallpaper.svg"
                  }
                  alt=""
                />
              )}
            </Grid>
            <Grid item md={3}>
              <SetWallpaper handleChange={handleChange} value={formData.workSpaceWallpaper} />
            </Grid>
            {errors.workSpaceWallpaper ? <h6 className="errorMsg">{errors.workSpaceWallpaper}</h6> : null}
          </Grid>
        </div>
        <div className={styles.addWorkspaceInviteAdmin}>
          <InviteLocalAdmins handleChange={handleChange} />
          {formData?.email?.length ? (
            <>
              <p className={styles.inviteAdminTitle + " label"}>Local Admins</p>
              {formData.email.map((email, key) => (
                <Grid
                  key={key}
                  container
                  direction="row"
                  spacing={2}
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid container item md={8} justifyContent="flex-start" alignItems="center">
                    <Grid item md={1}>
                      <img
                        className={styles.inviteAdminProfile}
                        src={imageBasePath + "invite_user_profile.svg"}
                        alt=""
                      />
                    </Grid>
                    <Grid item md={11}>
                      <p className={styles.inviteAdminEmail + " paragraph"}>{email}</p>
                    </Grid>
                  </Grid>
                  <Grid container item md={4} justifyContent="flex-end" alignItems="center">
                    {/* <Grid item md={10}>
                                            <p className={styles.inviteAdminStatus + " label hidden"}>Invite Pending</p>
                                        </Grid> */}
                    <Grid item md={2}>
                      <CustomLink
                        text=""
                        leftIcon={imageBasePath + "delete-bin-line.svg"}
                        onClick={() => handleDeleteAdmins(key)}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </>
          ) : null}
        </div>
        <div className={styles.addWorkspaceActionButton}>
          {!editWorkspace && (
            <CustomLink
              className={`${styles.addButton} outlinedBtn ${validateLoader || validate ? "disabledBtn" : ""}`}
              text={validate ? "Saving..." : "Save & add another"}
              onClick={() => handleAddAnotherBtn("addAnotherWorkspace")}
            />
          )}
          <CustomButton
            type="submit"
            // onClick={() => handleFormSubmit()}
            className={`${styles.exitButton} primaryBtn ${validateLoader || validate ? "disabledBtn" : ""}`}
            children={
              editWorkspace
                ? validateLoader
                  ? "Updating..."
                  : "Update & Exit"
                : validateLoader
                  ? "Saving..."
                  : "Save & Exit"
            }
          />
        </div>
      </form>
    </div>
  );
}
