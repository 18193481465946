import { Container } from "@material-ui/core";
import React from "react";
import styles from "../../../assets/styles/activityLog.module.scss";
import { imageBasePath } from "../../../constants";
import { CustomLink } from "../../../shared_ui_components";
import { ActivityLogHeader, ActivityLogList } from "../components";

export default function ActivityLog(props) {
  return (
    <Container maxWidth="lg">
      <div className={styles.activityLogPage}>
        <div className={styles.activityLogWrapper}>
          <div className={styles.backBtnWrapper}>
            <CustomLink
              className="backBtn"
              text="Back"
              leftIcon={imageBasePath + "arrow_left_icon.svg"}
              to="/home"
            />
          </div>
          <ActivityLogHeader />
          <div className={styles.activityLogListBlk}>
            <ActivityLogList />
          </div>
        </div>
      </div>
    </Container>
  );
}
