import { Grid } from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { CustomAutoComplete, CustomButton } from "../shared_ui_components";
import { sortCaseInsetive } from "../utils";
import {
  globalGetService,
  globalPostService,
} from "../utils/globalApiServices";

const FiltersTheme = createTheme({
  typography: {
    fontFamily: ["Inter", "sans-serif"].join(","),
    subtitle1: {
      fontSize: 13,
    },
  },
  palette: {
    secondary: {
      main: "#67737D",
    },
  },
});

const defaultOption = { label: "All", value: null };

function Filters({ filter, setFilter, handleFilter, loader }) {
  const [options, setOptions] = useState({
    organisationOptions: [defaultOption],
    workspaceOptions: [defaultOption],
    roomOptions: [defaultOption],
  });

  const [loading, setLoading] = useState(false);

  const { getOrganisations, getOrgDetail, getWorkspace, getRoom } =
    useFilterApi();

  const { id } = useParams();

  const workspaceList = (id) => {
    setLoading(true);
    getWorkspace({ orgId: id })
      .then((response) => {
        setLoading(false);
        if (response.status && response?.data?.length > 0) {
          const workspaceOptions = response.data
            .map((org) => {
              return {
                label: org?.orgLocation?.orgWorkSpaceName,
                value: org?.orgLocation._id,
              };
            })
            .sort((a, b) => sortCaseInsetive(a, b, "label"));
          setOptions((prev) => ({
            ...prev,
            workspaceOptions: [defaultOption, ...workspaceOptions],
          }));
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log("err", err);
      });
  };

  const roomList = (workspaceId) => {
    setLoading(true);
    getRoom({ workspaceId })
      .then((response) => {
        setLoading(false);
        if (response.status && response?.data?.rooms.length > 0) {
          const roomOptions = response.data.rooms
            .map((room) => {
              return {
                label: room.roomName || "No Room Name",
                value: room._id,
              };
            })
            .sort((a, b) => sortCaseInsetive(a, b, "label"));
          setOptions((prev) => ({
            ...prev,
            roomOptions: [defaultOption, ...roomOptions],
          }));
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log("err", err);
      });
  };

  function getAllOrganisations() {
    getOrganisations()
      .then((response) => {
        setLoading(false);
        if (response.status && response?.orgList?.length > 0) {
          const orgData = response.orgList.sort((a, b) =>
            sortCaseInsetive(a, b, "orgName")
          );
          const organisationOptions = orgData.map((org) => {
            return {
              label: org?.orgName,
              value: org?._id,
            };
          });
          setOptions((prev) => ({
            ...prev,
            organisationOptions: [defaultOption, ...organisationOptions],
          }));
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log("err", err);
      });
  }

  function getOrganisationDetail() {
    getOrgDetail({ orgId: id })
      .then((response) => {
        setLoading(false);
        if (response.status) {
          const value = {
            value: response?.org?._id,
            label: response?.org?.orgName,
          };

          setOptions((prev) => ({
            ...prev,
            organisationOptions: [value],
          }));

          setFilter({
            organisation: value,
            workspace: defaultOption,
            room: defaultOption,
          });

          return workspaceList(value.value);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log("err", err);
      });
  }

  useEffect(() => {
    setLoading(true);

    if (id) {
      getOrganisationDetail();
    } else {
      getAllOrganisations();
    }
  }, []);

  const gridValue = 3;

  return (
    <ThemeProvider theme={FiltersTheme}>
      <Grid container alignItems="center" spacing={2}>
        <Grid item md={gridValue}>
          <CustomAutoComplete
            label={"Filter by Organisation"}
            options={options.organisationOptions}
            value={filter.organisation}
            onChange={(e, selected) => {
              let orgDefaultOption = selected || defaultOption;

              if(id){
                orgDefaultOption = options.organisationOptions[0]
              }

              setFilter({
                organisation: orgDefaultOption,
                workspace: defaultOption,
                room: defaultOption,
              });

              workspaceList(selected?.value);
            }}
          />
        </Grid>

        {filter.organisation.value && (
          <Grid item md={gridValue}>
            <CustomAutoComplete
              label={"Workspace"}
              options={options.workspaceOptions}
              value={filter.workspace}
              onChange={(e, selected) => {
                setFilter((prev) => ({
                  ...prev,
                  workspace: selected ? selected : defaultOption,
                  room: defaultOption,
                }));

                roomList(selected?.value);
              }}
            />
          </Grid>
        )}

        {filter.organisation.value && filter.workspace.value && (
          <Grid item md={gridValue}>
            <CustomAutoComplete
              label={"Room"}
              options={options.roomOptions}
              value={filter.room}
              onChange={(e, selected) => {
                setFilter((prev) => ({
                  ...prev,
                  room: selected ? selected : defaultOption,
                }));
              }}
            />
          </Grid>
        )}

        {handleFilter ? (
          <Grid item md={gridValue}>
            <CustomButton
              className={`primaryBtn ${loader && "disabledBtn"}`}
              children="Filter"
              onClick={handleFilter}
            />
          </Grid>
        ) : null}
      </Grid>
    </ThemeProvider>
  );
}

export default Filters;

function useFilterApi() {
  return {
    getOrganisations: () =>
      globalGetService(`list-of-org-details-by-user-id-v1`),
    getOrgDetail: (data = {}) =>
      globalPostService(`get-organisation-overview-v1`, data),
    getWorkspace: (data = {}) =>
      globalPostService(`get-org-locations-v1`, data),
    getRoom: (data = {}) =>
      globalPostService(`get-org-location-details-v1`, data),
  };
}
