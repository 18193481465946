import { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { Container, Grid, responsiveFontSizes } from "@material-ui/core";
import styles from "../../../assets/styles/cart.module.scss";
import { saveOrderDetails, updateTransactionID, checkCuponCode } from "../../purchase/apiServices";
import { fieldValidation } from "../../../utils/formValidation";
import { ScreenLoader } from "../../../shared_elements";
import { regExpression } from "../../../constants";
import {
  OrderDetail,
  OrderSummary,
  CartNavigation,
  ShippingDetail,
  BillingDetail,
  OrderStatus,
} from "../components";
import { GlobalContext } from "../../../context/GlobalContext";

function Cart(props) {
  const history = useHistory();
  const {
    state: { device },
  } = useContext(GlobalContext);
  const [step, setStep] = useState(1);
  const [submitLoader, setSubmitLoader] = useState(false);
  const [couponError, setCouponError] = useState(false);
  const [couponSetFlag, setCouponSetFlag] = useState(false);
  const [couponCodeText, setCouponCodeText] = useState("");
  const [loader, setLoader] = useState(false);
  const [couponDiscount, setCouponDiscount] = useState(0);
  const [subscriptionPeriodOpt, setSubscriptionPeriodOpt] = useState([]);
  const [cartDetails, setCartDetails] = useState({
    email: "",
    name: "",
    contactNumber: "",
    address: "",
    stateName: "",
    cityName: "",
    country: "India",
    pincode: "571602",
    gstNumber: "",
    units: 1,
    amountPaid: 0,
    subscriptionPeriod: 36,
    devicePriceId: "",
    shippingAddress: "",
    shippingCityName: "",
    shippingStateName: "",
    shippingCountry: "India",
    shippingPincode: "571602",
    shippingName: "",
    shippingContact: "",
    couponId: "",
  });
  const [orderUpdate, setOrderUpdateDetails] = useState({
    orderId: "",
    transactionKey: "",
    razorPayId: "",
    paymentMode: "Netbanking",
  });
  const [cityName, setCityName] = useState("");
  const [error, errorSet] = useState({});
  const [errorCode, errorCodeSet] = useState({
    email: {
      0: "",
      1: "Please enter email id",
      4: "Please enter valid Email address",
    },
    emailObj: {
      requiredFlag: true,
      regexPattern: regExpression.email,
    },
    name: {
      0: "",
      1: "Please enter full name",
    },
    nameObj: {
      requiredFlag: true,
    },
    contactNumber: {
      0: "",
      1: "Please enter contact number",
      4: "Please enter valid contact number",
    },
    contactNumberObj: {
      requiredFlag: true,
      regexPattern: regExpression.phoneNumber,
    },
    address: {
      0: "",
      1: "Please enter address",
    },
    addressObj: {
      requiredFlag: true,
    },
    cityName: {
      0: "",
      1: "Please select city from dropdown",
    },
    cityNameObj: {
      requiredFlag: true,
    },
    stateName: {
      0: "",
      1: "Please select state from dropdown",
    },
    stateNameObj: {
      requiredFlag: true,
    },
    shippingName: {
      0: "",
      1: "Please enter full name",
    },
    shippingNameObj: {
      requiredFlag: true,
    },
    shippingContact: {
      0: "",
      1: "Please enter contact number",
      4: "Please enter valid contact number",
    },
    shippingContactObj: {
      requiredFlag: true,
      regexPattern: regExpression.phoneNumber,
    },
    shippingAddress: {
      0: "",
      1: "Please enter address",
    },
    shippingAddressObj: {
      requiredFlag: true,
    },
    shippingCityName: {
      0: "",
      1: "Please select city from dropdown",
    },
    shippingCityNameObj: {
      requiredFlag: true,
    },
    shippingStateName: {
      0: "",
      1: "Please select state from dropdown",
    },
    shippingStateNameObj: {
      requiredFlag: true,
    },
  });

  const validateShipping = () => {
    const validateNewInput = {
      email:
        errorCode["email"][
          fieldValidation({
            ...errorCode["emailObj"],
            fieldval: cartDetails.email?.trim(),
          })
        ],
      shippingName:
        errorCode["shippingName"][
          fieldValidation({
            ...errorCode["shippingNameObj"],
            fieldval: cartDetails.shippingName,
          })
        ],
      shippingAddress:
        errorCode["shippingAddress"][
          fieldValidation({
            ...errorCode["shippingAddressObj"],
            fieldval: cartDetails.shippingAddress,
          })
        ],
      shippingContact:
        errorCode["shippingContact"][
          fieldValidation({
            ...errorCode["shippingContactObj"],
            fieldval: cartDetails.shippingContact,
          })
        ],
      shippingCityName:
        errorCode["shippingCityName"][
          fieldValidation({
            ...errorCode["shippingCityNameObj"],
            fieldval: cartDetails.shippingCityName,
          })
        ],
      shippingStateName:
        errorCode["shippingStateName"][
          fieldValidation({
            ...errorCode["shippingStateNameObj"],
            fieldval: cartDetails.shippingStateName,
          })
        ],
    };
    console.log(cartDetails, "details");
    console.log(validateNewInput, "validateShippingInput");
    if (Object.keys(validateNewInput).every((k) => validateNewInput[k] == "")) {
      return true;
    } else {
      errorSet(validateNewInput);
      return false;
    }
  };

  const validateBilling = () => {
    const validateBillingInput = {
      name: errorCode["name"][
        fieldValidation({
          ...errorCode["nameObj"],
          fieldval: cartDetails.name,
        })
      ],
      address:
        errorCode["address"][
          fieldValidation({
            ...errorCode["addressObj"],
            fieldval: cartDetails.address,
          })
        ],
      contactNumber:
        errorCode["contactNumber"][
          fieldValidation({
            ...errorCode["contactNumberObj"],
            fieldval: cartDetails.contactNumber,
          })
        ],
      cityName:
        errorCode["cityName"][
          fieldValidation({
            ...errorCode["cityNameObj"],
            fieldval: cartDetails.cityName,
          })
        ],
      stateName:
        errorCode["stateName"][
          fieldValidation({
            ...errorCode["stateNameObj"],
            fieldval: cartDetails.stateName,
          })
        ],
    };
    // console.log(validateBillingInput, "validateInput");
    if (Object.keys(validateBillingInput).every((k) => validateBillingInput[k] == "")) {
      return true;
    } else {
      errorSet(validateBillingInput);
      return false;
    }
  };

  const saveOrderDetailsAndPay = () => {
    console.log(cartDetails);
    let updatedCartdetails = cartDetails;
    if (!couponSetFlag) {
      delete updatedCartdetails.couponId;
    }
    history.push({
      pathname: "/cart/status",
      state: { statusDetail: orderUpdate, status: "success" },
    });
    console.log(updatedCartdetails, "details");
    // saveOrderDetails(updatedCartdetails).then((response) => {
    //   setLoader(true);
    //   if (response.status) {
    //     setOrderUpdateDetails((orderUpdate) => ({
    //       ...orderUpdate,
    //       orderId: response.orderdID,
    //       razorPayId: response.razorPayId,
    //     }));
    //     let zpUpdate = {
    //       ...orderUpdate,
    //       orderId: response.orderdID,
    //       razorPayId: response.razorPayId,
    //     };
    //     razorPayOption(response.razorPayId, response.totalAmount * 100, zpUpdate);
    //   } else {
    //     //show failed dialog
    //   }
    // });
  };

  const updateTransactionData = (razorpayOrder) => {
    setLoader(true);
    updateTransactionID(razorpayOrder).then((response) => {
      if (response.status) {
        history.push({ pathname: "/cart/status", state: { statusDetail: razorpayOrder, status: "success" } });
      } else {
        history.push({ pathname: "/cart/status", state: { statusDetail: razorpayOrder, status: "failed" } });
      }
    });
  };

  const applyCuponCode = (couponVal) => {
    setSubmitLoader(true);
    let couponObj = { couponCode: couponVal };
    checkCuponCode(couponObj).then((response) => {
      if (response.status) {
        setSubmitLoader(false);
        setCouponDiscount(response.coupondetails.couponDiscount);
        handleChange("couponId", response.coupondetails._id);
        setCouponCodeText(couponVal);
        setCouponSetFlag(true);
        setCouponError(false);
      } else {
        setCouponDiscount(0);
        setCouponCodeText("");
        setSubmitLoader(false);
        setCouponSetFlag(false);
        setCouponError(true);
        handleChange("couponId", "");
        //show failed dialog
      }
    });
  };

  useEffect(() => {
    if (device?.pricing?.length) {
      let subscriptions = device.pricing.sort((a, b) => a.subscriptionPeriod - b.subscriptionPeriod);
      handleChange("devicePriceId", subscriptions[0]._id);
      setSubscriptionPeriodOpt(subscriptions);
    } else {
      // history.push('/');
    }
  }, [device]);

  const handleChange = (key, val) => {
    setCartDetails((cartDetail) => ({
      ...cartDetail,
      [key]: val,
    }));

    errorSet({
      ...error,
      [key]: "",
    });
  };

  const handleSameAsShipping = (status) => {
    //console.log(status);
    if (status) {
      setCartDetails((cartDetail) => ({
        ...cartDetail,
        address: cartDetail.shippingAddress,
        cityName: cartDetail.shippingCityName,
        stateName: cartDetail.shippingStateName,
        country: cartDetail.shippingCountry,
        pincode: cartDetail.shippingPincode,
        name: cartDetail.shippingName,
        contactNumber: cartDetail.shippingContact,
      }));
      //console.log(cartDetails);
    }
  };

  const razorPayOption = (orderID, totalamount, zpUpdate) => {
    let options = {
      key: "rzp_test_SF8EcaixieMftx",
      amount: totalamount, // 2000 paise = INR 20, amount in paisa
      name: "Let's Ving",
      description: "Place Order",
      order_id: orderID,
      handler: function (response) {
        // alert(response.razorpay_payment_id);
        let razorPayorder = zpUpdate;
        razorPayorder = {
          ...razorPayorder,
          transactionKey: response.razorpay_payment_id,
          paymentMode: "razorPay",
        };
        updateTransactionData(razorPayorder);
      },
      prefill: {
        name: cartDetails.name,
        email: cartDetails.email,
        contact: cartDetails.contactNumber,
      },
      notes: {
        address: "Let's Ving office",
      },
    };

    let rzp = new window.Razorpay(options);
    rzp.open();
  };

  const handleUpdateStep = (val) => {
    if (val > 4) {
      val = 3;
    }
    if (val === 3) {
      if (validateShipping()) {
        setStep(val);
      }
    } else if (val === 4) {
      if (validateBilling()) {
        setStep(val);
      }
    } else {
      setStep(val);
    }
  };

  const invalidateCoupon = () => {
    setCouponDiscount(0);
    setCouponCodeText("");
    setSubmitLoader(false);
    setCouponSetFlag(false);
    handleChange("couponId", "");
  };

  const updateCouponError = () => {
    setCouponError(false);
  };

  useEffect(() => {
    errorSet({
      ...error,
      cityName: "",
      shippingCityName: "",
    });
  }, [cartDetails.shippingCityName, cartDetails.cityName, cartDetails.shippingStateName]);

  const allSteps = {
    1: (
      <Grid container direction="row" spacing={2}>
        <Grid item md={6}>
          <OrderDetail
            handleUpdateStep={handleUpdateStep}
            step={step}
            subscriptionPeriodOpt={subscriptionPeriodOpt}
            handleChange={handleChange}
            cartDetails={cartDetails}
          />
        </Grid>
        <Grid item md={6}>
          {cartDetails.units ? (
            <OrderSummary
              cartDetails={cartDetails}
              subscriptionPeriodOpt={subscriptionPeriodOpt}
              device={device}
              applyCuponCode={applyCuponCode}
              submitLoader={submitLoader}
              couponDiscount={couponDiscount}
              couponCodeText={couponCodeText}
              couponSetFlag={couponSetFlag}
              couponError={couponError}
              updateCouponError={updateCouponError}
              handleChange={handleChange}
              invalidateCoupon={invalidateCoupon}
            />
          ) : null}
        </Grid>
      </Grid>
    ),
    2: (
      <Grid container direction="row" spacing={2} className="xs-block">
        <Grid item md={6}>
          <ShippingDetail
            handleUpdateStep={handleUpdateStep}
            step={step}
            handleChange={handleChange}
            error={error}
            emailId={cartDetails.email}
          />
        </Grid>
        <Grid item md={6}>
          {cartDetails.units ? (
            <OrderSummary
              subscriptionPeriodOpt={subscriptionPeriodOpt}
              cartDetails={cartDetails}
              submitLoader={submitLoader}
              applyCuponCode={applyCuponCode}
              couponDiscount={couponDiscount}
              couponCodeText={couponCodeText}
              couponSetFlag={couponSetFlag}
              handleChange={handleChange}
              invalidateCoupon={invalidateCoupon}
              couponError={couponError}
              updateCouponError={updateCouponError}
              device={device}
            />
          ) : null}
        </Grid>
      </Grid>
    ),
    3: (
      <Grid container direction="row" spacing={2} className="xs-block">
        <Grid item md={6}>
          <BillingDetail
            handleUpdateStep={handleUpdateStep}
            step={step}
            error={error}
            cartDetails={cartDetails}
            handleSameAsShipping={handleSameAsShipping}
            handleChange={handleChange}
          />
        </Grid>
        <Grid item md={6}>
          {cartDetails.units ? (
            <OrderSummary
              cartDetails={cartDetails}
              subscriptionPeriodOpt={subscriptionPeriodOpt}
              submitLoader={submitLoader}
              applyCuponCode={applyCuponCode}
              couponDiscount={couponDiscount}
              couponCodeText={couponCodeText}
              couponSetFlag={couponSetFlag}
              handleChange={handleChange}
              invalidateCoupon={invalidateCoupon}
              x={couponError}
              updateCouponError={updateCouponError}
              device={device}
            />
          ) : null}
        </Grid>
      </Grid>
    ),
    4: (
      <Grid container direction="row" spacing={2} className="xs-block">
        <Grid item md={6}>
          <BillingDetail
            handleUpdateStep={handleUpdateStep}
            step={step}
            error={error}
            cartDetails={cartDetails}
            handleSameAsShipping={handleSameAsShipping}
            handleChange={handleChange}
          />
        </Grid>
        <Grid item md={6}>
          {cartDetails.units ? (
            <OrderSummary
              cartDetails={cartDetails}
              subscriptionPeriodOpt={subscriptionPeriodOpt}
              submitLoader={submitLoader}
              applyCuponCode={applyCuponCode}
              couponDiscount={couponDiscount}
              couponCodeText={couponCodeText}
              couponSetFlag={couponSetFlag}
              handleChange={handleChange}
              invalidateCoupon={invalidateCoupon}
              couponError={couponError}
              updateCouponError={updateCouponError}
              device={device}
            />
          ) : null}
        </Grid>
      </Grid>
    ),
  };

  useEffect(() => {
    if (step === 4) {
      saveOrderDetailsAndPay();
    }
  }, [step]);
  return (
    <div className={styles.cartPage}>
      <Container maxWidth="lg">
        <CartNavigation step={step} />
        <div className={styles.cartWrapper}>{allSteps[step]}</div>
      </Container>
      <ScreenLoader open={loader} />
    </div>
  );
}

export default Cart;
