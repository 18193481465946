import React from "react";
import { Grid, List, ListItem } from "@material-ui/core";
import styles from "../../../assets/styles/users.module.scss";
import { Skeleton } from "@material-ui/lab";
import { imageBasePath } from "../../../constants";

export default function Shimmer(props) {
  return (
    <>
      {props.type === "userSearch" && (
        <div className={styles.userSearchBlk}>
          <Grid container direction="row" justifyContent="flex-end" alignItems="center">
            <Grid item md={5} className={styles.userSearchWrapper}>
              <div className={styles.userSearchInput}></div>
              <div className={styles.addUser}>
                <div className={styles.inviteButton}></div>
              </div>
            </Grid>
          </Grid>
        </div>
      )}
      {props.type === "userHeader" && (
        <div className={styles.usersListTableTitle}>
          <Grid container spacing={2}>
            <Grid item md={12}>
              <Grid container direction="row" spacing={2} justifyContent="flex-start" alignItems="center">
                <Grid item md={3}>
                  <div className={styles.usersTableTitleTextWrapper}>
                    <p className={styles.usersTableTitleText + " paragraph "}>Name</p>
                  </div>
                </Grid>
                <Grid item md={2}>
                  <div className={styles.usersTableTitleTextWrapper}>
                    <p className={styles.usersTableTitleText + " paragraph "}>Last Active</p>
                  </div>
                </Grid>
                <Grid item md={2}>
                  <div className={styles.usersTableTitleTextWrapper}>
                    <p className={styles.usersTableTitleText + " paragraph "}>User Role</p>
                  </div>
                </Grid>
                <Grid item md={3}>
                  <div className={styles.usersTableTitleTextWrapper}>
                    <p className={styles.usersTableTitleText + " paragraph "}>Assigned Workspace</p>
                  </div>
                </Grid>
                <Grid item md={2}>
                  <div className={styles.usersTableTitleTextWrapper}>
                    <p className={styles.usersTableTitleText + " paragraph "}>Joined On</p>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      )}
      {props.type === "orgUsersLists" && (
        <>
          {[0, 1, 2, 3, 4].map((item, key) => (
            <div className={styles.userListItem} key={key}>
              <div className={styles.usersListTableRow}>
                <Grid container spacing={2}>
                  <Grid item md={12}>
                    <Grid
                      container
                      direction="row"
                      spacing={2}
                      justifyContent="flex-start"
                      alignItems="center"
                    >
                      <Grid item md={3}>
                        <div className={styles.userContentWrapper}>
                          <div className={styles.userImageWrapper}>
                            <Skeleton
                              variant="circle"
                              width={40}
                              height={40}
                              style={{
                                display: "block",
                                verticalAlign: "middle",
                                transform: "none",
                                marginTop: 0,
                                marginLeft: 0,
                              }}
                            />
                          </div>
                          <div className={styles.userDetailWrapper}>
                            <Skeleton
                              variant="text"
                              width={120}
                              height={25}
                              style={{
                                display: "block",
                                verticalAlign: "middle",
                                transform: "none",
                                marginBottom: 10,
                              }}
                            />
                            <Skeleton
                              variant="text"
                              width={120}
                              height={25}
                              style={{
                                display: "block",
                                verticalAlign: "middle",
                                transform: "none",
                                marginTop: 0,
                              }}
                            />
                          </div>
                        </div>
                      </Grid>
                      <Grid item md={2}>
                        <div className={styles.userContentWrapper}>
                          <div className={styles.lastActive + " paragraph "}>
                            <Skeleton
                              variant="text"
                              width={120}
                              height={25}
                              style={{
                                display: "block",
                                verticalAlign: "middle",
                                transform: "none",
                                marginBottom: 0,
                                marginTop: 0,
                              }}
                            />
                          </div>
                        </div>
                      </Grid>
                      <Grid item md={2}>
                        <div className={styles.userContentWrapper}>
                          <div className={styles.userRole + " paragraph "}>
                            <Skeleton
                              variant="text"
                              width={100}
                              height={25}
                              style={{
                                display: "block",
                                verticalAlign: "middle",
                                transform: "none",
                                marginTop: 0,
                              }}
                            />
                          </div>
                        </div>
                      </Grid>
                      <Grid item md={3}>
                        <div className={styles.userContentWrapper}>
                          <div className={styles.assignedWorkspace + " paragraph "}>
                            <Skeleton
                              variant="text"
                              width={180}
                              height={25}
                              style={{
                                display: "block",
                                verticalAlign: "middle",
                                transform: "none",
                                marginTop: 0,
                              }}
                            />
                          </div>
                        </div>
                      </Grid>
                      <Grid item md={2}>
                        <div className={styles.userContentWrapper}>
                          <div className={styles.joinedDate + " paragraph "}>
                            <Skeleton
                              variant="text"
                              width={120}
                              height={25}
                              style={{
                                display: "block",
                                verticalAlign: "middle",
                                transform: "none",
                                marginTop: 0,
                              }}
                            />
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </div>
          ))}
        </>
      )}
      {props.type === "OrgListHeader" && (
        <div className={styles.usersListWrapper}>
          <div className={styles.usersHeading}>
            <div className={styles.userTitle + " subheading "}>Users</div>
          </div>
        </div>
      )}
    </>
  );
}
