import React, { useState, useEffect } from "react";
import styles from "../../../assets/styles/users.module.scss";
import { imageBasePath } from "../../../constants";
import { InputSelect, InputText, CustomLink, NoResult } from "../../../shared_ui_components";
import InviteUserDialog from "./InviteUserDialog";
import { Grid, List, ListItem, Dialog } from "@material-ui/core";
import { getOrgUsersLists } from "../apiServices";
import { UserDetail } from "./UserDetail";
import Shimmer from "./Shimmer";
import { useParams } from "react-router";
import { getUserInfo, sortObj } from "../../../utils";

export default function UserList(props) {
  const [open, setOpen] = useState(false);
  const [orgUsersLists, setOrgUsersLists] = useState([]);
  const [loader, setLoader] = useState(true);
  const [search, setSearch] = useState({
    val: "",
    list: [],
  });
  const [sort, setSort] = useState({
    field: "",
    asc: false,
    defaultSortUpImg: "",
    defaultSortDownImg: "",
  });

  const { id } = useParams();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

function getUserList(id){
  getOrgUsersLists({ orgId: id }).then((response) => {
    if (response.status && response.data.length > 0) {
      setOrgUsersLists(response.data);
      setLoader(false);
    } else {
      setLoader(false);
    }
  });
}

  useEffect(() => {
  getUserList(id)
  }, [id]);

  const userInfo = getUserInfo();

  const handleSearch = (val) => {
    let str = val.toLowerCase();
    let searchList = orgUsersLists.filter((user) => user.user.fullName?.toLowerCase().includes(str));
    setSearch((prevSearch) => ({
      val: str,
      list: searchList,
    }));
  };

  const handleSort = (sortObject) => {
    let data;
    if (sortObject.field === "workspace") {
      data = orgUsersLists.map((user) => {
        return { workspace: user.workspace, id: user.user._id };
      });
    } else {
      data = orgUsersLists.map((user) => {
        return user.user;
      });
    }

    let list = sortObj(sortObject.field, data, sortObject.asc, sortObject.caseSensitive);
    //console.log(list);
    let newList = [];
    if (sortObject.field == "workspace") {
      list.forEach((workspace) => {
        newList = [
          ...newList,
          ...[
            {
              user: orgUsersLists.find((users) => users.user._id === workspace.id)?.user,
              workspace: workspace.workspace,
            },
          ],
        ];
      });
    } else {
      list.forEach((lUsr) => {
        newList = [
          ...newList,
          ...[
            {
              user: lUsr,
              workspace: orgUsersLists.find((orgUsr) => orgUsr.user._id === lUsr._id)?.workspace,
            },
          ],
        ];
      });
    }
    setSort({
      field: sortObject.field,
      asc: sortObject.asc,
      defaultSortUpImg: sortObject.asc ? imageBasePath + `asc_arrow.svg` : imageBasePath + "up_arrow.svg",
      defaultSortDownImg: sortObject.asc ? imageBasePath + "down_arrow.svg" : imageBasePath + `des_arrow.svg`,
    });
    if (sortObject.field === "updatedAt") {
      setOrgUsersLists(newList.reverse());
    } else {
      setOrgUsersLists(newList);
    }
  };

  return (
    <div className={styles.usersListWrapper}>
      {loader ? (
        <Shimmer type="OrgListHeader" />
      ) : (
        <div className={styles.usersHeading}>
          <h2 className={styles.userTitle + " subheading "}>
            {`${orgUsersLists?.length == 0 ? "User" : "Users"}`}{" "}
            <span className={styles.usersCount}>({orgUsersLists?.length})</span>
          </h2>
        </div>
      )}
      <div className={styles.usersListStatus}>
        <List className={styles.userFilter}>
          <ListItem className={styles.filterItem + " paragraphSemiBold"}>Admin Users</ListItem>
        </List>
      </div>
      {loader ? (
        <Shimmer type="userSearch" />
      ) : (
        <div className={styles.userSearchBlk}>
          <Grid container direction="row" justifyContent="flex-end" alignItems="center">
            <Grid item md={5} className={styles.userSearchWrapper}>
              <div className={styles.userSearchInput}>
                <InputText onChange={(e) => handleSearch(e.target.value)} placeholder="Search here" />
                <img src={imageBasePath + "search_icon.svg"} alt="" />
              </div>
              {userInfo.userData?.role?.responsibilities?.inviteUsers ? (
                <div className={styles.addUser}>
                  <CustomLink
                    onClick={handleClickOpen}
                    className={styles.inviteButton + " outlinedBtn "}
                    text="Invite Users"
                    leftIcon={imageBasePath + "invite_user.svg"}
                  />
                  <Dialog open={open}>
                    <InviteUserDialog handleClose={handleClose} getUserList={()=>getUserList(id)} />
                  </Dialog>
                </div>
              ) : null}
            </Grid>
          </Grid>
        </div>
      )}
      <Grid container>
        <Grid item md={12}>
          <div className={styles.usersList}>
            <div className={styles.usersListInnerBlk}>
              {loader ? (
                <Shimmer type="userHeader" />
              ) : (
                <div className={styles.usersListTableTitle}>
                  <Grid container spacing={2}>
                    <Grid item md={12}>
                      <Grid
                        container
                        direction="row"
                        spacing={2}
                        justifyContent="flex-start"
                        alignItems="center"
                      >
                        <Grid item md={2}>
                          <div className={styles.usersTableTitleTextWrapper}>
                            <p className={styles.usersTableTitleText + " paragraph "}>Name</p>
                            <img
                              src={
                                sort.field === "fullName"
                                  ? sort.defaultSortUpImg
                                  : imageBasePath + "up_arrow.svg"
                              }
                              alt=""
                              className="sortUp"
                              onClick={() =>
                                handleSort({
                                  field: "fullName",
                                  asc: !sort.asc,
                                  caseSensitive: true,
                                })
                              }
                            />
                            <img
                              src={
                                sort.field === "fullName"
                                  ? sort.defaultSortDownImg
                                  : imageBasePath + "down_arrow.svg"
                              }
                              alt=""
                              className="sortDown"
                              onClick={() =>
                                handleSort({
                                  field: "fullName",
                                  asc: !sort.asc,
                                  caseSensitive: true,
                                })
                              }
                            />
                          </div>
                        </Grid>
                        <Grid item md={2}>
                          <div className={styles.usersTableTitleTextWrapper}>
                            <p className={styles.usersTableTitleText + " paragraph "}>Last Active</p>
                            <img
                              src={
                                sort.field === "updatedAt"
                                  ? sort.defaultSortUpImg
                                  : imageBasePath + "up_arrow.svg"
                              }
                              alt=""
                              className="sortUp"
                              onClick={() =>
                                handleSort({
                                  field: "updatedAt",
                                  asc: !sort.asc,
                                  caseSensitive: true,
                                })
                              }
                            />
                            <img
                              src={
                                sort.field === "updatedAt"
                                  ? sort.defaultSortDownImg
                                  : imageBasePath + "down_arrow.svg"
                              }
                              alt=""
                              className="sortDown"
                              onClick={() =>
                                handleSort({
                                  field: "updatedAt",
                                  asc: !sort.asc,
                                  caseSensitive: true,
                                })
                              }
                            />
                          </div>
                        </Grid>
                        <Grid item md={2}>
                          <div className={styles.usersTableTitleTextWrapper}>
                            <p className={styles.usersTableTitleText + " paragraph "}>User Role</p>
                          </div>
                        </Grid>
                        <Grid item md={2}>
                          <div className={styles.usersTableTitleTextWrapper}>
                            <p className={styles.usersTableTitleText + " paragraph "}>Assigned Workspace</p>
                            <img
                              src={
                                sort.field === "workspace"
                                  ? sort.defaultSortUpImg
                                  : imageBasePath + "up_arrow.svg"
                              }
                              alt=""
                              className="sortUp"
                              onClick={() =>
                                handleSort({
                                  field: "workspace",
                                  asc: !sort.asc,
                                  caseSensitive: true,
                                })
                              }
                            />
                            <img
                              src={
                                sort.field === "workspace"
                                  ? sort.defaultSortDownImg
                                  : imageBasePath + "down_arrow.svg"
                              }
                              alt=""
                              className="sortDown"
                              onClick={() =>
                                handleSort({
                                  field: "workspace",
                                  asc: !sort.asc,
                                  caseSensitive: true,
                                })
                              }
                            />
                          </div>
                        </Grid>
                        <Grid item md={2}>
                          <div className={styles.usersTableTitleTextWrapper}>
                            <p className={styles.usersTableTitleText + " paragraph "}>Joined On</p>
                            <img
                              src={
                                sort.field === "createdAt"
                                  ? sort.defaultSortUpImg
                                  : imageBasePath + "up_arrow.svg"
                              }
                              alt=""
                              className="sortUp"
                              onClick={() =>
                                handleSort({
                                  field: "createdAt",
                                  asc: !sort.asc,
                                  caseSensitive: true,
                                })
                              }
                            />
                            <img
                              src={
                                sort.field === "createdAt"
                                  ? sort.defaultSortDownImg
                                  : imageBasePath + "down_arrow.svg"
                              }
                              alt=""
                              className="sortDown"
                              onClick={() =>
                                handleSort({
                                  field: "createdAt",
                                  asc: !sort.asc,
                                  caseSensitive: true,
                                })
                              }
                            />
                          </div>
                        </Grid>
                        <Grid item md={2}>
                        <div className={styles.usersTableTitleTextWrapper}>
                        <p className={styles.usersTableTitleText + " paragraph "}>Delete User</p>
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
              )}
              <div className={styles.usersListTableContent}>
                {loader ? (
                  <Shimmer type="orgUsersLists" />
                ) : search.val ? (
                  <>
                    {search.list.length === 0 ? (
                      <NoResult image="no_order.png" title="No user found" />
                    ) : (
                      search.list.map((users) => <UserDetail {...users} key={users._id} getUserList={()=>getUserList(id)} orgId={id}  />)
                    )}
                  </>
                ) : (
                  orgUsersLists.map((users) => <UserDetail {...users} key={users._id} getUserList={()=>getUserList(id)} orgId={id}  />)
                )}
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
