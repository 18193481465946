import Home from "./container/Home";
import HomeLayoutHoc from "../../hoc/homeLayoutHoc";
export const homeRoutes = [
  {
    path: "/home",
    component: HomeLayoutHoc(Home),
    key: "home",
  },
  // {
  //   path: "/networkcheck",
  //   component: NetworkCheck,
  //   key: "home",
  // },
];
