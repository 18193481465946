import React, { useState, useEffect } from "react";
import styles from "../../../assets/styles/orgSettings.module.scss";
import closeIcon from "../../../assets/images/close-square.svg";
import { Grid } from "@material-ui/core";
import { imageBasePath } from "../../../constants";
import {
  CustomButton,
  CustomLink,
  InputText,
  NoResult,
} from "../../../shared_ui_components";
import {
  createWallpaperApi,
  deleteWallpaper,
  confirmWallpaper,
} from "../apiServices";
import { getWorkspaceWallpaperApi } from "../../workspaces/apiServices";
import { toastFlashMessage } from "../../../utils";
import { useParams } from "react-router";
import { WallpaperImages } from "./WallpaperImages";
import Shimmer from "./Shimmer";

export default function DisplayConfig(props) {
  const { orgLists } = props;
  const { id } = useParams();

  // const [formD, setFormD] = useState({
  //   orgRefId: id && id,
  // });
  const [formData, setFormData] = useState({
    orgId: id,
    orgWallpaper: [],
    orgWallpapers: [],
    link: "",
  });

  const [newImgs, setNewImgs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(true);
  const [wallpaperLink, setWallpaperLink] = useState("");
  const [imageLinkError, setImageLinkError] = useState(false);
  const [videoLinkError, setVideoLinkError] = useState(false);

  const [wallpaperType, setWallpaperType] = useState("image");
  const [showPopup, setShowPopup] = useState(false);
  const getWelcomeDisplay = (wallpapers) => {
    setFormData((form) => ({
      ...form,
      orgWallpaper: wallpapers,
    }));
  };
// const isWallpaperTypeAppLink=formData?.orgWallpaper?.filter((item)=>item.wallpaperType!=="appLink")
  useEffect(() => {
    getWorkspaceWallpaperApi({ orgRefId: id }).then((response) => {
      if (response.status) {
        console.log(response.data);
        // if(response.data.wallpaperType!=="appLink"){
          getWelcomeDisplay(response.data.wallpapers);

        // }
        setLoading(false);
      } else {
        setLoading(false);
      }
    });
  }, [id]);

  const handleChange = (e) => {
    setError(false);
    setFormData((form) => ({
      ...form,
      orgWallpapers: [],
    }));
    let files = e.target.files;

    let importError = false;
    for (let i = 0; i < files.length; i++) {
      let filesize = (files[i].size / 1024 / 1024).toFixed(4); // MB

      if (filesize >= 25) {
        importError = true;
        setError("File Size must be below 25Mb");
      }
    }

    if (!importError) {
      for (let i = 0; i < files.length; i++) {
        setFormData((form) => ({
          ...form,
          orgWallpaper: [...form.orgWallpaper, URL.createObjectURL(files[i])],
          orgWallpapers: [...form.orgWallpapers, files[i]],
        }));
        setNewImgs([files[i]]);
      }
    }
  };

  useEffect(() => {
    setLoading(true);
    if (newImgs.length > 0 && !error) {
      handleImportWallpapers();
    }
  }, [newImgs]);

  const handleImportWallpapers = (wallpaperLink) => {
    let form_data = new FormData();
    form_data.append("orgId", formData.orgId);

    if (wallpaperLink) {
      form_data.append("link", wallpaperLink);
      form_data.append("wallpaperType", wallpaperType);
    } else {
      formData.orgWallpapers.forEach((element) => {
        const newFile = {
          file: element,
          name: element.name,
        };
        form_data.append(`orgWallpaper`, newFile.file, newFile.name);
      });
    }
    createWallpaperApi(form_data).then((response) => {
      if (response.status) {
        setWallpaperLink("");
        // if(response.data.wallpaperType!=="appLink"){
      
        getWelcomeDisplay(response.data.wallpapers);
        // }
        setLoading(false);
        toastFlashMessage("Wallpaper images uploaded successfully", "success");
      } else if (!response.status) {
        toastFlashMessage(response.response.data.message, "error");
      } else {
        setLoading(false);
        toastFlashMessage(
          "Something went wrong while uploading wallpaper images",
          "error"
        );
      }
    });
  };

  const handleDeleteWallpaper = (wallpaper) => {
    const form = {
      orgRefId: id && id,
      wallpaperId: wallpaper,
    };
    deleteWallpaper(form).then((response) => {
      if (response.status) {
        getWelcomeDisplay(response.wallpapersList.wallpapers);
        toastFlashMessage("Wallpaper image deleted successfully", "success");
      } else {
        toastFlashMessage(
          "Something went wrong while deleteing wallpaper image",
          "error"
        );
      }
    });
  };

  const defaultWallpapers = (wallpaper) => {
    const getDefaultWallpaper = formData.orgWallpaper.find(
      (item) => item._id == wallpaper
    )._id;
    //console.log(wallpaper, "wallpaper");
    const form = {
      orgId: id,
      wallpaperId: getDefaultWallpaper,
    };
    confirmWallpaper(form).then((response) => {
      if (response.status) {
        props.handleUpdateForm(response.wallpaper);
        toastFlashMessage(
          "Selected image has been set as default wallpaper successfully",
          "success"
        );
      } else {
        toastFlashMessage(
          "Something went wrong while setting default wallpaper",
          "error"
        );
      }
    });
  };

  const isVideo = orgLists?.orgWallpaper?.includes(".mp4");

  const handleWallpaperType = (event) => {
    setImageLinkError(false);
    setVideoLinkError(false);
    setWallpaperLink("");
    setWallpaperType(event.target.value);
  };

  return (
    <div className={styles.setWallpaperWrapper}>
      <div className={styles.addWallpaperContentWrapper}>
        <div className={styles.addWallpaperFrameWrapper}>
          <div className={styles.addWallpaperFrameImage}>
            <img src={imageBasePath + "wallpaper_frame.png"} alt="" />
            {isVideo ? (
              <video
                src={
                  orgLists?.orgWallpaper ??
                  imageBasePath + "wallpaper_demo_1.png"
                }
                className={styles.frameUpdatedPicture}
                alt=""
                autoPlay
                muted
                loop
              />
            ) : (
              <img
                src={
                  orgLists?.orgWallpaper ??
                  imageBasePath + "wallpaper_demo_1.png"
                }
                className={styles.frameUpdatedPicture}
                alt=""
              />
            )}
          </div>
          <div className={styles.addWallpaperConfigWrapper}>
            <p className={styles.ConfigTitle + " label"}>
              Wallpaper Configuration
            </p>
            <p className={styles.ConfigSubTitle + " label"}>
              Upload wallpapers to set as default wallpaper
            </p>
            <div className={styles.importButtonWrapper}>
              <CustomLink
                className={styles.importButton + " outlinedBtn "}
                text="Import wallpapers"
              />
              <input
                type="file"
                multiple
                accept="image/png, image/jpg, image/jpeg, video/mp4"
                className={styles.inputFile}
                onChange={handleChange}
              />
             </div>
              <div style={{ marginTop: "20px" }} className={styles.importButtonWrapper}>
                <button
                  style={{ width: "165px" }}
                  className={styles.importButton + " outlinedBtn "}
                  onClick={() => {
                    setShowPopup(true);
                    setWallpaperLink("");
                  }}
                >
                  Link Wallpaper
                </button>
                <div
                  id="myModal"
                  style={{ display: `${showPopup ? "block" : ""}` }}
                  className={styles.modal}
                >
                  <div className={styles.modalContent}>
                    <img
                      className={styles.close}
                      onClick={() => setShowPopup(false)}
                      src={closeIcon}
                    />
                    <p
                      style={{ fontSize: "18px", marginBottom: "20px" }}
                      className={styles.ConfigTitle + " label"}
                    >
                      Enter wallpaper Link
                    </p>
                    <InputText
                      className={styles.ConfigTitle + " label"}
                      placeholder="eg, http://example.com"
                      value={wallpaperLink ? wallpaperLink : ""}
                      onChange={(e) => {
                        setWallpaperLink(e.target.value);
                        if (imageLinkError) {
                          setImageLinkError(false);
                        }
                        if (videoLinkError) {
                          setVideoLinkError(false);
                        }
                      }}
                    />
                    <RadioButtonGroup
                      wallpaperType={wallpaperType}
                      handleWallpaperType={handleWallpaperType}
                    />
                    {imageLinkError && <p>Invalid Image Link</p>}
                    {videoLinkError && <p>Invalid Video Link</p>}
                    {wallpaperLink &&
                    !imageLinkError &&
                    wallpaperType === "image" ? (
                      <div className="{styles.wallpapersList}">
                        <img
                          className={styles.wallpapers}
                          onError={() => setImageLinkError(true)}
                          src={wallpaperLink}
                          alt=""
                        />
                      </div>
                    ) : null}
                    {wallpaperLink &&
                    !videoLinkError &&
                    wallpaperType === "video" ? (
                      <div className="{styles.wallpapersList}">
                        <video
                          src={wallpaperLink}
                          className={styles.wallpapers}
                          alt=""
                          autoPlay
                          onError={() => setVideoLinkError(true)}
                          muted
                          loop
                        />
                      </div>
                    ) : null}

                    <div
                      className={styles.addDomainBtnWrapper}
                      style={{ marginTop: `${wallpaperLink ? "60px" : ""}` }}
                    >
                      <CustomButton
                        children={"save wallpaper"}
                        className={`${styles.addDomainBtn} primaryBtn ${
                          imageLinkError || videoLinkError || !wallpaperLink
                            ? "disabledBtn"
                            : null
                        }`}
                        disabled={
                          imageLinkError || videoLinkError || !wallpaperLink
                        }
                        onClick={() => {
                          handleImportWallpapers(wallpaperLink);
                          setShowPopup(false);
                        }}
                        type="button"
                      />
                    </div>
                  </div>
                </div>
              </div>

              {error ? (
                <div className="errorMsg" style={{ marginTop: "2px" }}>
                  {error}
                </div>
              ) : null}
          </div>
        </div>
        {loading ? (
          <Shimmer type="wallpaperListLength" />
        ) : (
          <p className={styles.wallpaperCounts + " label"}>
            Wallpapers (<span>{formData?.orgWallpaper.length}</span>)
          </p>
        )}
        <Grid
          container
          spacing={2}
          direction="row"
          alignItems="center"
          justifyContent="center"
        >
          {loading ? (
            <Shimmer type="wallpaperLists" />
          ) : formData?.orgWallpaper?.length === 0? (
            <div style={{ marginTop: "-100px" }}>
              <NoResult
                image="no_workspace_room.png"
                title="No Wallpapers found"
                subtext="Looks like you have no wallpapers. Start importing wallpapers"
              />
            </div>
          ) : (
            formData?.orgWallpaper?.map((item, key) => (
             <Grid item md={4}>
               <WallpaperImages
                  {...item}
                  key={key}
                  handleDeleteWallpaper={handleDeleteWallpaper}
                  defaultWallpapers={defaultWallpapers}
                  wallpaper={orgLists?.orgWallpaper}
                />
              </Grid>
            ))
          )}
        </Grid>
      </div>
    </div>
  );
}

const RadioButtonGroup = ({ wallpaperType, handleWallpaperType }) => {
  const options = [
    { value: "image", label: "Image" },
    { value: "video", label: "Video" },
    { value: "appLink", label: "App Link" },
  ];

  return (
    <>
      <p
        style={{ fontSize: "18px", marginBottom: "10px" }}
        className={styles.ConfigTitle + " label"}
      >
        Link Type
      </p>

      <div style={{ display: "flex", gap: "20px", marginBottom: "20px" }}>
        {options.map((option) => (
          <label
            key={option.value}
            style={{
              display: "flex",
              gap: "5px",
              fontSize: "14px",
              color: "black",
            }}
          >
            <input
              type="radio"
              value={option.value}
              checked={wallpaperType === option.value}
              onChange={handleWallpaperType}
            />
            {option.label}
          </label>
        ))}
      </div>
    </>
  );
};
