import { getStore } from "../store/GlobalStore";
import sortObjectsArray from "sort-objects-array";

export const toastFlashMessage = (message, type, delay = 5000) => {
  const { dispatch } = getStore();
  dispatch({
    type: "CLEAR_TOAST",
    payload: "",
  });
  setTimeout(function () {
    dispatch({
      type: "SHOW_TOAST",
      payload: {
        message: message,
        toastType: type,
      },
    });
    setTimeout(function () {
      dispatch({
        type: "HIDE_TOAST",
        payload: {},
      });
    }, delay);
  }, 200);
};

export const currencyFormatter = (number) => {
  let x = number?.isInteger ? number : parseFloat(number?.toFixed(2));
  x = x.toString().replace(/(\d)(?=(\d\d)+\d$)/g, "$1,");
  return x;
};

export const getUserInfo = () => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  if (userInfo) {
    return userInfo;
  }
  return {};
};

export const sortObj = (field, data, asc, caseSensitive) => {
  if (data && data.length) {
    let sortedArr = sortObjectsArray(data, field, {
      order: asc ? "asc" : "desc",
      caseinsensitive: caseSensitive,
    });

    if (asc) {
      let nullArr = sortedArr.filter((sArr) => !sArr[field]);
      let valueArr = sortedArr.filter((sArr) => sArr[field]);

      return [...nullArr, ...valueArr];
    } else {
      return sortedArr;
    }
  }
  return [];
};

export const sortCaseInsetive = (a, b, fieldName) => {
  const aValue = fieldName ? a[fieldName] || "" : a;
  const bValue = fieldName ? b[fieldName] || "" : b;
  return aValue.toLowerCase().localeCompare(bValue.toLowerCase());
}
