import { Grid } from "@material-ui/core";
import React from "react";
import { format} from "date-fns";
import styles from "../../../assets/styles/devices.module.scss";
import Message from "../../../shared_ui_components/StatusMessage";

function DeviceList({ deployment,timestamp}) {
  const { deploymentStatus, macId, roomName, workspaceName } = deployment;
  const display = format(new Date(timestamp), "dd-MMM-yyyy hh:mm a");

  return (
    <div className={styles.devicesListTableRow}>
      <Grid container spacing={2}>
        <Grid item md={3}>
          <div className={styles.devicesContentWrapper}>
            <p className={styles.deviceRoom + " paragraph "}>{workspaceName}</p>
          </div>
        </Grid>
        <Grid item md={3}>
          <div className={styles.devicesContentWrapper}>
            <p className={styles.deviceRoom + " paragraph "}>{roomName}</p>
          </div>
        </Grid>
        <Grid item md={2}>
          <div className={styles.devicesContentWrapper}>
            <p className={styles.deviceRoom + " paragraph "}>{macId}</p>
          </div>
        </Grid>
        <Grid item md={2}>
          <div className={styles.devicesContentWrapper}>
            <p className={styles.deviceRoom + " paragraph "}>
              <Message message={deploymentStatus}/>
            </p>
          </div>
        </Grid>
        <Grid item md={2}>
          <div className={styles.devicesContentWrapper}>
            <p
              className={styles.deviceRoom + " paragraph "}
              style={{ display: "flex", flexDirection: "column" }}
            >
              {display}
            </p>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default DeviceList;
