import React from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { createTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Paper from "@material-ui/core/Paper";
import FiberManualRecordOutlinedIcon from "@material-ui/icons/FiberManualRecordOutlined";
import { Link } from "react-router-dom";

const ParticipantsListTheme = createTheme({
  palette: {
    secondary: {
      main: "#344552",
    },
  },
  typography: {
    fontFamily: ["Inter", "sans-serif"].join(","),
  },
});

const useStyles = makeStyles({
  root: {
    marginTop: "40px",
    maxWidth: "1024px",
    margin: "auto",
  },
  paperRoot: {
    margin:"10px",
    marginBottom: "40px",
    padding: "30px",
  },
  headingWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  img: {
    marginTop: "30px",
    marginRight: "15px",
    width: "40px",
  },
  title: {
    fontWeight: "bold",
    marginTop: "30px",
  },
  subtitle: {
    fontWeight: "bold",
    marginTop: "10px",
  },
  subtitleChild: {
    marginLeft: "20px",
  },
  icon: {
    minWidth: "30px",
  },
  listItem: {
    padding: "0px",
  },
});

function Zoom() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <ThemeProvider theme={ParticipantsListTheme}>
        <Paper className={classes.paperRoot}>
          <div className={classes.headingWrapper}>
            <img
              src="../images/zoom-icon.svg"
              alt="Profile"
              className={classes.img}
            />
            <Typography variant="h4" align="center" className={classes.title}>
              Zoom Integration
            </Typography>
          </div>
          <div style={{ padding: "40px", paddingBottom: "0px" }}>
            <Typography variant="h6" color="secondary">
              Zoom meeting is a virtual conferencing software that allows you to
              virtually meet with other people. Zoom integration for Ving allows
              seamless integration of Ving devices for Zoom meetings.
            </Typography>
            <List style={{ marginLeft: "18px" }}>
              <ListItem className={classes.listItem}>
                <ListItemIcon className={classes.icon}>
                  <FiberManualRecordIcon fontSize="inherit" />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography variant="subtitle1" color="secondary">
                      View your scheduled meetings
                    </Typography>
                  }
                  color="secondary"
                />
              </ListItem>
              <ListItem className={classes.listItem}>
                <ListItemIcon className={classes.icon}>
                  <FiberManualRecordIcon fontSize="inherit" />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography variant="subtitle1" color="secondary">
                      Join Zoom calls in the device remotely
                    </Typography>
                  }
                />
              </ListItem>
              <ListItem className={classes.listItem}>
                <ListItemIcon className={classes.icon}>
                  <FiberManualRecordIcon fontSize="inherit" />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography variant="subtitle1" color="secondary">
                      Instantly share content in the device using adhoc meetings
                      that are created automatically
                    </Typography>
                  }
                />
              </ListItem>
            </List>

            <Typography
              variant="h6"
              color="secondary"
              className={classes.subtitle}
              gutterBottom
            >
              How to add/link Zoom Account to Ving
            </Typography>
            <div
              className={classes.subtitleChild}
              style={{ marginTop: "10px" }}
            >
              <Typography variant="subtitle1" color="secondary" gutterBottom>
                1. If you don't have a Ving device or subscription, then please
                visit{" "}
                <strong>
                  <a
                    href="https://letsving.com/"
                    style={{ color: "#4361ee" }}
                    target="_blank"
                    rel="noreferrer"
                  >
                    letsving.com
                  </a>
                </strong>{" "}
                to order a device and subscription.
              </Typography>
              <Typography variant="subtitle1" color="secondary" gutterBottom>
                2. Once the device is received, then set it up using the
                instructions provided with the device.
              </Typography>
              <Typography variant="subtitle1" color="secondary" gutterBottom>
                3. Go to{" "}
                <strong>
                  <a
                    href="https://meet.letsving.com/"
                    style={{ color: "#4361ee" }}
                    target="_blank"
                    rel="noreferrer"
                  >
                    meet.letsving.com
                  </a>
                </strong>{" "}
                and sign in with your credentials or use social login.
              </Typography>
              <Typography variant="subtitle1" color="secondary" gutterBottom>
                4. To link a Zoom account to Ving account, please go to{" "}
                <strong> Settings =&#62; Providers</strong> under user name
                section in the top navigation bar.
              </Typography>
              <Typography variant="subtitle1" color="secondary" gutterBottom>
                5. Select <strong> Sign In</strong> button against Zoom to link
                your Zoom Account.
              </Typography>
            </div>
          </div>

          <div
            style={{
              padding: "40px",
              paddingTop: "10px",
              paddingBottom: "0px",
            }}
          >
            <Typography
              variant="h6"
              color="secondary"
              className={classes.subtitle}
              gutterBottom
              style={{ marginLeft: "0px", marginTop: "0px" }}
            >
              Features
            </Typography>

            <Typography
              variant="h6"
              color="secondary"
              className={classes.subtitle}
              gutterBottom
              style={{ fontSize: "1.10rem", color: "#666666" }}
            >
              Prescheduled meeting:
            </Typography>
            <div style={{ display: "flex" }}>
              <Typography variant="subtitle1" color="secondary" gutterBottom>
                To connect to the prescheduled meeting on the device follow the
                below steps,
              </Typography>
            </div>
            <div className={classes.subtitleChild}>
              <div style={{ display: "flex" }}>
                <FiberManualRecordOutlinedIcon
                  fontSize="inherit"
                  style={{
                    marginBottom: "4px",
                    marginRight: "10px",
                    color: "#344552",
                    marginTop: "8px",
                  }}
                />
                <Typography variant="subtitle1" color="secondary" gutterBottom>
                  Click on Meet Now in the home page.
                </Typography>
              </div>
              <div style={{ display: "flex" }}>
                <FiberManualRecordOutlinedIcon
                  fontSize="inherit"
                  style={{
                    marginBottom: "4px",
                    marginRight: "10px",
                    color: "#344552",
                    marginTop: "8px",
                  }}
                />
                <Typography variant="subtitle1" color="secondary" gutterBottom>
                  Enter the room id from device and select Next.
                </Typography>
              </div>
              <div style={{ display: "flex" }}>
                <FiberManualRecordOutlinedIcon
                  fontSize="inherit"
                  style={{
                    marginBottom: "4px",
                    marginRight: "10px",
                    color: "#344552",
                    marginTop: "8px",
                  }}
                />
                <Typography variant="subtitle1" color="secondary" gutterBottom>
                  Select Zoom and add your meeting url and click next.
                </Typography>
              </div>
              <div style={{ display: "flex" }}>
                <FiberManualRecordOutlinedIcon
                  fontSize="inherit"
                  style={{
                    marginBottom: "4px",
                    marginRight: "10px",
                    color: "#344552",
                    marginTop: "8px",
                  }}
                />
                <Typography variant="subtitle1" color="secondary" gutterBottom>
                  The meeting will start. You can control the device volume,
                  camera and microphone from the application and also share
                  content from your PC.
                </Typography>
              </div>
              <div style={{ display: "flex" }}>
                <FiberManualRecordOutlinedIcon
                  fontSize="inherit"
                  style={{
                    marginBottom: "4px",
                    marginRight: "10px",
                    color: "#344552",
                    marginTop: "8px",
                  }}
                />
                <Typography variant="subtitle1" color="secondary" gutterBottom>
                  You can end the meeting using "Leave Meeting" option.
                </Typography>
              </div>
            </div>

            <Typography
              variant="h6"
              color="secondary"
              className={classes.subtitle}
              gutterBottom
              style={{ fontSize: "1.10rem", color: "#666666" }}
            >
              Adhoc meeting
            </Typography>

            <div style={{ display: "flex" }}>
              <Typography variant="subtitle1" color="secondary" gutterBottom>
                To connect to a adhoc meeting on the device with Zoom, you
                should select{" "}
                <strong> "Set as Default for Share Screen" </strong> option for
                Zoom in the Settings screen. If Zoom is the only account linked,
                then it is not required. To join an adhoc meeting,
              </Typography>
            </div>

            <div className={classes.subtitleChild}>
              <div style={{ display: "flex" }}>
                <FiberManualRecordOutlinedIcon
                  fontSize="inherit"
                  style={{
                    marginBottom: "4px",
                    marginRight: "10px",
                    color: "#344552",
                    marginTop: "8px",
                  }}
                />
                <Typography variant="subtitle1" color="secondary" gutterBottom>
                  Click on Share Screen in the home page.
                </Typography>
              </div>
              <div style={{ display: "flex" }}>
                <FiberManualRecordOutlinedIcon
                  fontSize="inherit"
                  style={{
                    marginBottom: "4px",
                    marginRight: "10px",
                    color: "#344552",
                    marginTop: "8px",
                  }}
                />
                <Typography variant="subtitle1" color="secondary" gutterBottom>
                  Enter the room id from device and select Next.
                </Typography>
              </div>
              <div style={{ display: "flex" }}>
                <FiberManualRecordOutlinedIcon
                  fontSize="inherit"
                  style={{
                    marginBottom: "4px",
                    marginRight: "10px",
                    color: "#344552",
                    marginTop: "8px",
                  }}
                />
                <Typography variant="subtitle1" color="secondary" gutterBottom>
                  Ving will automatically will create an adhoc meeting and join
                  the meeting in the Ving application and the device.
                </Typography>
              </div>
              <div style={{ display: "flex" }}>
                <FiberManualRecordOutlinedIcon
                  fontSize="inherit"
                  style={{
                    marginBottom: "4px",
                    marginRight: "10px",
                    color: "#344552",
                    marginTop: "8px",
                  }}
                />
                <Typography variant="subtitle1" color="secondary" gutterBottom>
                  Similar to Prescheduled meetings, you can control the device
                  accessories and share content from PC.
                </Typography>
              </div>
            </div>

            <Typography
              variant="subtitle1"
              color="secondary"
              gutterBottom
              style={{ fontStyle: "italic", fontWeight: "bold" }}
            >
              User manual with screenshots is available
              <a
                href="https://meet.letsving.com/ving-meet-user-guide.pdf"
                target="_blank"
                rel="noreferrer"
              >
                {" "}
                here{" "}
              </a>
            </Typography>
          </div>

          <div
            style={{
              padding: "40px",
              paddingTop: "10px",
              paddingBottom: "0px",
            }}
          >
            <Typography
              variant="h6"
              color="secondary"
              className={classes.subtitle}
              gutterBottom
              style={{ marginLeft: "0px", marginTop: "0px" }}
            >
              How to remove/unlink Zoom Account from Ving
            </Typography>

            <Typography variant="subtitle1" color="secondary" gutterBottom>
              Follow the following steps to remove Zoom from Ving application,
            </Typography>
            <div
              className={classes.subtitleChild}
              style={{ marginTop: "10px" }}
            >
              <Typography variant="subtitle1" color="secondary" gutterBottom>
                1. Go to{" "}
                <strong>
                  <a
                    href="https://letsving.com/"
                    style={{ color: "#4361ee" }}
                    target="_blank"
                    rel="noreferrer"
                  >
                    meet.letsving.com
                  </a>
                </strong>{" "}
                and sign in with your credentials or use social login.
              </Typography>
              <Typography variant="subtitle1" color="secondary" gutterBottom>
                2. Go to <strong> Settings =&#62; Providers</strong> under user
                name section in the top navigation bar.
              </Typography>
              <Typography variant="subtitle1" color="secondary" gutterBottom>
                3. If the entry against Zoom doesn't have{" "}
                <strong>Signed In</strong>, then you have already disconnected
                from Zoom.
              </Typography>
              <Typography variant="subtitle1" color="secondary" gutterBottom>
                4. Click on the three dots image in the right side of the Zoom
                row. It will show options including <strong>Sign Out</strong>.
              </Typography>
              <Typography variant="subtitle1" color="secondary" gutterBottom>
                5. Select <strong>Sign Out</strong> Option.
              </Typography>
            </div>
            <Typography variant="subtitle1" color="secondary" gutterBottom>
              The steps above will remove Zoom linkage with Ving application.
              All the data obtained from Zoom will be deleted. But user saved
              items like favourite meetings will still be available. If you want
              to remove them, then to go to{" "}
              <strong>Favorite Links or IDs</strong> in Settings and remove
              them.
            </Typography>
          </div>
          <div
            style={{
              padding: "40px",
            }}
          >
            <Typography variant="subtitle1" color="secondary" gutterBottom>
              Please
              <Link
                to="/support"
                style={{ color: "#4361ee", fontWeight: "bold" }}
              >
                {" "}
                contact us
              </Link>{" "}
              if you need any support.
            </Typography>
          </div>
        </Paper>
      </ThemeProvider>
    </div>
  );
}

export default Zoom;
