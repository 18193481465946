import React from "react";
import styles from "../../../assets/styles/quotations.module.scss";
import QuotationList from "../components/QuotationList";
import { useLocation } from "react-router-dom";

export default function Quotations(props) {
  let location = useLocation();
  return (
    <div className={`${styles.quotationsPage} 
    ${location.pathname === "/quotations"
      ? styles.quotationsHomePage : ""}`}
    >
      <QuotationList />
    </div>
  );
}
