import React from 'react';
import styles from "../../../assets/styles/auth.module.scss";
import { imageBasePath } from "../../../constants";
import { CustomButton } from "../../../shared_ui_components";
import { useHistory } from "react-router-dom";

export default function CreatePasswordSuccess(props) {
    const history = useHistory();

    const goToSignin = () => {
        history.push('/signin');
    }

    return (
        <div className={styles.resetPasswordSuccess}>
            <div className={styles.successWrapper}>
                <div className={styles.imageWrapper}>
                    <img src={imageBasePath + "password_reset_success.svg"} alt="success" />
                </div>
                <div className={styles.contentWrapper}>
                    <p className={styles.contentPara + " paragraph"}>Your password has been reset. Please sign <br /> back in with the new credentials.</p>
                    <CustomButton
                        onClick={() => goToSignin()}
                        className={styles.contentBtn + " primaryBtn"}
                        children={"Back to Sign In"}
                    />
                </div>
            </div>
        </div>
    )
}
