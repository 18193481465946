import React, { useState } from "react";
import { Grid } from "@material-ui/core";
import styles from "../../../assets/styles/accountSettings.module.scss";
import { imageBasePath } from "../../../constants";
import { CustomButton, CustomLink } from "../../../shared_ui_components";
import CustomRadioButton from "../../../shared_ui_components/CustomRadioButton";
import { UserProfile } from "./UserProfile";
import { PasswordTabContent } from "./PasswordTabContent";

export default function AccountSettingsTabContent(props) {
  const { activeTab } = props;

  return (
    <div className={styles.accountSettingsTabContent}>
      {activeTab == 1 ? <UserProfile /> : null}

      {activeTab == 2 ? (
        // <div className={styles.passwordTab}>
        //   <Grid container>
        //     <Grid item md={7}>
        //       <div className={styles.passwordTabInput}>
        //         <InputText
        //           label="Old password"
        //           type="password"
        //           value={formData.password || ""}
        //           error={error.password}
        //           onChange={(e) => {
        //             handleChange("password", e.target.value);
        //           }}
        //         />
        //       </div>
        //       <div className={styles.passwordTabInput}>
        //         <InputText
        //           label="New password"
        //           type="password"
        //           value={formData.newPassword || ""}
        //           error={error.newPassword}
        //           onChange={(e) => {
        //             handleChange("newPassword", e.target.value);
        //           }}
        //         />
        //       </div>
        //       <div className={styles.passwordBtnWrapper}>
        //         <CustomButton
        //           children="Change"
        //           className={styles.saveBtn + " primaryBtn "}
        //           onClick={handleSavePassword}
        //         />
        //       </div>
        //     </Grid>
        //   </Grid>
        // </div>
        <PasswordTabContent />
      ) : null}

      {activeTab == 3 ? (
        <div className={styles.addressTab}>
          <div className={styles.addNewAddress}>
            <CustomLink
              text="Add new address"
              leftIcon={imageBasePath + "plus_icon.svg"}
              className={styles.addNewAddressBtn + " outlinedBtn "}
            />
          </div>
          <Grid container spacing={3}>
            <Grid item md={6}>
              <div className={styles.addressCard}>
                <div className={styles.addressCardSelect}>
                  <CustomRadioButton checked className={styles.addressCardRadioBtn} />
                  <div className={styles.addressDetailsWrapper}>
                    <p className={styles.addressName + " paragraph "}>Nithish</p>
                    <p className={styles.address + " supportText "}>
                      1st Floor, A P Complex, 65/6, Agara, Sarjapur Main Road, Bengaluru, Karnataka 560102
                    </p>
                  </div>
                </div>
                <div className={styles.addressCardFooter}>
                  <div className={styles.btnWrapper}>
                    <CustomButton children="Remove" className={styles.removeBtn + " linkBtn dangerBtn"} />
                    <CustomButton children="Edit" className={styles.editBtn + " outlinedBtn "} />
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item md={6}>
              <div className={styles.addressCard}>
                <div className={styles.addressCardSelect}>
                  <CustomRadioButton className={styles.addressCardRadioBtn} />
                  <div className={styles.addressDetailsWrapper}>
                    <p className={styles.addressName + " paragraph "}>Nithish</p>
                    <p className={styles.address + " supportText "}>
                      1st Floor, A P Complex, 65/6, Agara, Sarjapur Main Road, Bengaluru, Karnataka 560102
                    </p>
                  </div>
                </div>
                <div className={styles.addressCardFooter}>
                  <div className={styles.btnWrapper}>
                    <CustomButton children="Remove" className={styles.removeBtn + " linkBtn dangerBtn"} />
                    <CustomButton children="Edit" className={styles.editBtn + " outlinedBtn "} />
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item md={6}>
              <div className={styles.addressCard}>
                <div className={styles.addressCardSelect}>
                  <CustomRadioButton className={styles.addressCardRadioBtn} />
                  <div className={styles.addressDetailsWrapper}>
                    <p className={styles.addressName + " paragraph "}>Nithish</p>
                    <p className={styles.address + " supportText "}>
                      1st Floor, A P Complex, 65/6, Agara, Sarjapur Main Road, Bengaluru, Karnataka 560102
                    </p>
                  </div>
                </div>
                <div className={styles.addressCardFooter}>
                  <div className={styles.btnWrapper}>
                    <CustomButton children="Remove" className={styles.removeBtn + " linkBtn dangerBtn"} />
                    <CustomButton children="Edit" className={styles.editBtn + " outlinedBtn "} />
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item md={6}>
              <div className={styles.addressCard}>
                <div className={styles.addressCardSelect}>
                  <CustomRadioButton className={styles.addressCardRadioBtn} />
                  <div className={styles.addressDetailsWrapper}>
                    <p className={styles.addressName + " paragraph "}>Nithish</p>
                    <p className={styles.address + " supportText "}>
                      1st Floor, A P Complex, 65/6, Agara, Sarjapur Main Road, Bengaluru, Karnataka 560102
                    </p>
                  </div>
                </div>
                <div className={styles.addressCardFooter}>
                  <div className={styles.btnWrapper}>
                    <CustomButton children="Remove" className={styles.removeBtn + " linkBtn dangerBtn"} />
                    <CustomButton children="Edit" className={styles.editBtn + " outlinedBtn "} />
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      ) : null}
    </div>
  );
}
