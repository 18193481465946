import React from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";

export default function CustomButtonNew(props) {
  return (
    <Button
      className={props.className}
      onClick={props.onClick}
      style={{ width: props.width, height: props.height }}
      {...props}
    >
      {props.children}
    </Button>
  );
}

Button.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.element, PropTypes.func]),
};

Button.defaultProps = {
  className: "primaryBtn",
  onClick: (e) => {},
  width: "100%",
  children: <p>Button</p>,
};
