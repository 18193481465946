import { Grid } from "@material-ui/core";
import React from "react";
import styles from "../../../assets/styles/quotations.module.scss";
import { imageBasePath } from "../../../constants";
//import ShippingDetail from "./ShippingDetail";
import AddressCard from "./AddressCard";
import OrderSummary from "./OrderSummary";
function PlaceOrder(props) {
  const { step, handleUpdateStep, allData, handleQuoteOrderFormData, setSubmitQuoteSuccess } = props;

  return (
    <div className={styles.placeOrder}>
      <Grid container spacing={3}>
        <Grid item md={6}>
          {/* <ShippingDetail /> */}
          <AddressCard 
            handleUpdateStep={handleUpdateStep}
            handleQuoteOrderFormData={handleQuoteOrderFormData}
            setSubmitQuoteSuccess={setSubmitQuoteSuccess}
            step={step}
            allData={allData}
          />
        </Grid>
        <Grid item md={6}>
          <OrderSummary 
            allData={allData}
          />
        </Grid>
      </Grid>
    </div>
  );
}

export default PlaceOrder;
