import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import GetAppIcon from "@material-ui/icons/GetApp";
import { imageBasePath } from "../../../constants/index";
import { exportToExcel, roomsList, meetingsList } from "../exportsData";
import { getRoomsData, getMeetingsData, getMeetingsDatafromParams } from "../apiServices";
import { toastFlashMessage } from "../../../utils";
import { useHistory, useParams } from "react-router-dom";
import moment from "moment";
import { ReportsDatePicker } from "../../../shared_ui_components";
import { Filters } from "../../../shared_elements";
import { useEffect } from "react";

// import TodayOutlinedIcon from "@material-ui/icons/TodayOutlined";


 const ReportsTheme = createTheme({
  typography: {
    fontFamily: ["Inter", "sans-serif"].join(","),
    subtitle1: {
      fontSize: 13,
    },
  },
  palette: {
    secondary: {
      main: "#67737D",
    },
  },
});

const useStyles = makeStyles((theme) => ({
  paperContainer: {
    padding: "10px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  title: {
    fontWeight: "bold",
    color: "#67737D",
  },
  content: {
    display: "flex",
    alignItems: "center",
    height: "75px",
    marginLeft: "10px",
  },
  count: {
    fontWeight: "bold",
    marginTop: "4px",
    fontSize: "16px",
  },
  imgBackground: {
    backgroundColor: "#f5f9f8",
    borderRadius: "4px",
    border: "1px solid #dcedf0",
  },
  imgMaterial: {
    color: "#99a2a9",
    fontSize: "30px",
    margin: "10px 10px 4px 10px",
  },
  downloadIcon: {
    color: "#3b5dff",
    marginTop: "8px",
  },
  wrapper: {
    display: "flex",
    alignItems: "center",
  },
  downloadBtnWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  downloadingTxt: {
    fontSize: "12px",
    fontFamily: "'Inter', sans-serif",
    color: "#3b5dff",
    textAlign: "center",
  },
  dateWrapper: {
    display: "flex",
    // justifyContent: "space-around",
  },
  date: {
    marginLeft: "40px",
  },
  reportWrapper: {
    width: "100%",
    padding: "20px",
  },
  reportTitleWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  reportTitle: {
    color: "#011627",
    fontSize: "18px",
    fontWeight: "600",
    textAlign: "center",
    paddingBottom: "30px",
  },
  downloadReportBtnWrapper: {
    display: "flex",
    float: "right",
    marginTop: "20px",
  },
  downloadReportIcon: {
    marginLeft: "10px",
  },
}));

function isToDateLesserThanFromDate(toDate, fromDate) {
  const days = moment(toDate).diff(moment(fromDate), "days");
  if (days < 0) {
    return true;
  }
  return false;
}

function Reports() {
  const classes = useStyles();
  const [reports, setReports] = React.useState({});
  const { id, duration } = useParams();
  useEffect(()=>{

    
    if(duration) {
    if(duration === 'last24h') {
      
    } else if (duration === 'last30d') {
  
    } else {
  
    }
    getMeetingsDatafromParams({
      orgId: id,
      duration
      
    }).then((response) => {
      if (response.status && Object.keys(response).length > 0) {
        if (response.info) {
          toastFlashMessage(response.info?.toUpperCase(), "error");
          return;
        }
        setReports((prev) => ({
          ...prev,
          meetingData: response.data,
          meetingsDataLoader: false,
        }));
        response.data.forEach((dt) => {
          console.log("dt", dt.meetingDate);
        });
        exportToExcel("List-of-meetings", meetingsList, response.data);
      } else {
        setReports((prev) => ({
          ...prev,
          meetingsDataLoader: false,
        }));
        toastFlashMessage("Something went wrong! Please try again.", "error");
      }
    });
  }
  }, []);
    function exportRoomsData() {
    setReports((prev) => ({
      ...prev,
      roomsDataLoader: true,
    }));
    getRoomsData({ orgId: id || "" }).then((response) => {
      if (response.status && Object.keys(response).length > 0) {
        if (response.info) {
          toastFlashMessage(response.info?.toUpperCase(), "error");
          return;
        }
        setReports((prev) => ({
          ...prev,
          roomData: response.data,
          roomsDataLoader: false,
        }));
        exportToExcel("List-of-rooms", roomsList, response.data);
      } else {
        setReports((prev) => ({
          ...prev,
          roomsDataLoader: false,
        }));
        toastFlashMessage("Something went wrong! Please try again.", "error");
      }
    });
  }

  return (
    <div style={{ padding: "114px 0px 0px 240px" }}>
      <ThemeProvider theme={ReportsTheme}>
        <Grid container className={classes.content} spacing={2}>
          <Grid item xl={11} lg={11} md={8} sm={12}>
            <ListMeetings
              classes={classes}
              reports={reports}
              setReports={setReports}
              id={id}
            />
          </Grid>
          <Grid item xl={4} lg={5} md={8} sm={12}>
            <Paper className={classes.paperContainer}>
              <div className={classes.wrapper}>
                <img
                  src={imageBasePath + "org_room_icon.svg"}
                  alt=""
                  style={{ width: "50px" }}
                />
              </div>
              <div
                className={classes.reportTitle}
                style={{ paddingBottom: "0px" }}
              >
                List of rooms
              </div>
              <div className={classes.downloadBtnWrapper}>
                <button
                  onClick={() => exportRoomsData()}
                  style={
                    reports.roomsDataLoader
                      ? { opacity: "0.4", cursor: "not-allowed" }
                      : {}
                  }
                  disabled={reports.roomsDataLoader}
                >
                  <GetAppIcon
                    fontSize="large"
                    className={classes.downloadIcon}
                  />
                </button>
                {reports.roomsDataLoader ? (
                  <div className={classes.downloadingTxt}>downloading...</div>
                ) : null}
              </div>
            </Paper>
          </Grid>
        </Grid>
      </ThemeProvider>
    </div>
  );
}

export default Reports;

const defaultOption = { label: "All", value: null };

function ListMeetings({ classes, reports, setReports, id }) {
  const date = new Date();
  const history = useHistory();

  //get current month first day
  const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);

  const [fromDate, setFromDate] = React.useState(firstDay);
  const [toDate, setToDate] = React.useState();
  const [dateError, setDateError] = React.useState();

  const [filter, setFilter] = React.useState({
    organisation: defaultOption,
    workspace: defaultOption,
    room: defaultOption,
  });

  function convertDateFormat(date) {
    return moment(date).format("YYYY-MM-DD");
  }

  function exportMeetingsData() {
    setReports((prev) => ({
      ...prev,
      meetingsDataLoader: true,
    }));
    getMeetingsData({
      orgId: id || filter.organisation?.value || "",
      workspaceId: filter.workspace?.value,
      roomId: filter.room?.value,
      fromDate: fromDate ? convertDateFormat(fromDate) : undefined,
      toDate: toDate ? convertDateFormat(toDate) : undefined,
    }).then((response) => {
      if (response.status && Object.keys(response).length > 0) {
        if (response.info) {
          toastFlashMessage(response.info?.toUpperCase(), "error");
          return;
        }
        setReports((prev) => ({
          ...prev,
          meetingData: response.data,
          meetingsDataLoader: false,
        }));
        response.data.forEach((dt) => {
          console.log("dt", dt.meetingDate);
        });
        exportToExcel("List-of-meetings", meetingsList, response.data);
      } else {
        setReports((prev) => ({
          ...prev,
          meetingsDataLoader: false,
        }));
        toastFlashMessage("Something went wrong! Please try again.", "error");
      }
    });
  }

  return (
    <Paper className={classes.paperContainer} style={{ margin: "20px 0" }}>
      <div className={classes.reportWrapper}>
        <div className={classes.reportTitle}>List of Meetings</div>
        <div className={classes.dateWrapper}>
          <ReportsDatePicker
            label="From Date"
            value={fromDate}
            onChange={(date) => {
              setFromDate(date);
            }}
            id="date-picker-inline"
            style={{ width: "90%" }}
            placeholder={"DD/MM/YYYY"}
            maxDate={new Date()}
            okLabel={""}
          />
          <ReportsDatePicker
            label="To Date"
            value={toDate}
            onChange={(date) => {
              setDateError(false);
              const result = isToDateLesserThanFromDate(date, fromDate);
              if (result) {
                setDateError(true);
              } else {
                setToDate(date);
              }
            }}
            id="date-picker-inline"
            style={{ width: "90%" }}
            error={dateError ? "To Date must be greater then From Date" : ""}
            maxDate={new Date()}
            okLabel={""}
          />
        </div>

        <div>
          <Filters filter={filter} setFilter={setFilter} />
        </div>

        <div className={classes.downloadReportBtnWrapper}>
          <div className={classes.downloadReportIcon}>
            <button
              className={`primaryBtn ${
                reports.meetingsDataLoader && "disabledBtn"
              }`}
              onClick={() => exportMeetingsData()}
              disabled={reports.meetingsDataLoader}
            >
              {reports.meetingsDataLoader
                ? "Downloading Report..."
                : "Download Report"}
            </button>
          </div>
          {id&&<button
              className={`outlinedBtn`}
              onClick={  ()=>{
                const convertedFromDate = moment(fromDate).toISOString();
                const convertedToDate = moment(toDate).toISOString();

                history.push(`/${id}/reports/meetingDetails?from=${convertedFromDate}&to=${convertedToDate}`)}}
              style={{ cursor: "pointer", marginLeft: "20px" ,padding:"0px 16px"}}
            >
            View Report
            </button>}
        </div>

       
      </div>
    </Paper>
  );
}
