import React from "react";
import { Grid, Divider } from "@material-ui/core";
import { imageBasePath } from "../constants";
import ReactEcharts from "echarts-for-react";

export default function DevicesCard(props) {
  const { activatedDevices, notActivatedDeviceCount, disconnectedDeviceCount, deviceOnlineCount, deviceOfflineCount } = props;

  return (
    <div className="devicesCard">
      <h3 className="devicesTitle subheading2 ">Devices</h3>
      <div className="cardBody">
        <Grid container>
          <Grid item xl={4} lg={4} md={6}>
            <Grid container>
              <Grid item md={8}>
                <div className="activatedDevicesWrapper" style={{marginTop:"20px"}}>
                  <div className="deviceImgWrapper">
                    <img src={imageBasePath + "device_activated_img.svg"} />
                    <div className="deviceStatusIconWrapper">
                      <img src={imageBasePath + "device_activated_icon.svg"} />
                    </div>
                  </div>
                  <div className="activatedDeviceTextWrapper" >
                    <p className="activatedDevicesText paragraph">Activated Devices</p>
                    <p className="activatedDevicesCount subheading">
                      {activatedDevices ? activatedDevices : "N/A"}
                    </p>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xl={5}  lg={5} md={6}> 
            <div style={{ display:"flex"}}>
              <ReactEcharts
                style={{ width: "400px", height: "200px" }}
                option={{
                  legend: {
                    type: "scroll",
                    orient: "vertical",
                    left: "0%",
                    top: "center",
                    icon: "circle",
                    itemGap: 30,
                    textStyle: {
                      fontFamily: '"Inter", sans-serif',
                      fontSize: 14,
                      color: "#415766",
                    },
                  },
                  series: [
                    {
                      name: "Access From",
                      type: "pie",
                      radius: ["45%", "60%"],
                      avoidLabelOverlap: false,
                      color: ["#2E9940", "#B3B9BE"],
                      label: {
                        show: false,
                      },
                      labelLine: {
                        show: false,
                      },
                      data: [
                        { value: deviceOnlineCount || 0, name: `Online ${deviceOnlineCount || "N/A"}` },
                        { value: deviceOfflineCount || 0, name: `Offline ${deviceOfflineCount || "N/A"}` },
                      ],
                    },
                  ],
                }}
              />
              <div className="divider" style={{marginRight:"50px"}}>
              <Divider orientation="vertical" />{" "}
              </div>
            </div>
          </Grid>
          <Grid item xl={3} lg={3} md={4}>
            <div className="deviceStatusRightBlk" style={notActivatedDeviceCount === "" ? {marginTop:"50px"} : {}}>
              {notActivatedDeviceCount === "" ? null :(
                <div className="deviceStatusBlk">
                  <div className="deviceImgWrapper">
                    <img src={imageBasePath + "device_not_activated_img.svg"} className="deviceImg" />
                    <img src={imageBasePath + "device_not_activated_icon.svg"} className="deviceStatusIcon" />
                  </div>
                  <div className="textBlkWrapper">
                    <p className="notActivatedDevicesText paragraph">Not Activated Devices</p>
                    <p className="notActivatedDevicesCount subheading">
                      {notActivatedDeviceCount ? notActivatedDeviceCount : "N/A"}
                    </p>
                  </div>
                </div>
              )}
              <div className="deviceStatusBlk">
                <div className="deviceImgWrapper">
                  <img src={imageBasePath + "device_deactivated_img.svg"} className="deviceImg" />
                  <img src={imageBasePath + "device_deactivated_icon.svg"} className="deviceStatusIcon" />
                </div>
                <div className="textBlkWrapper">
                  <p className="notActivatedDevicesText paragraph">Deactivated Devices</p>
                  <p className="notActivatedDevicesCount subheading">
                    {disconnectedDeviceCount ? disconnectedDeviceCount : "N/A"}
                  </p>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
