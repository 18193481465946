
import { useEffect, useState } from 'react';
import { timezone } from './../features/workspaces/apiServices';

function transformTimeZone(timezones) {
    const timezoneInfo = { list: [], labels: {} }
    timezones.forEach((a) => {
        function z(n) {
            return (n < 10 ? "0" : "") + n;
        }
        var offset = a.offset;
        var sign = offset < 0 ? "+" : "-";
        offset = Math.abs(offset);
        const label = `(GMT${sign + z((offset / 60) | 0) + z(offset % 60)}) ${a.name}`;
        timezoneInfo.list.push({
            label, value: a.key
        });
        timezoneInfo.labels[a.key] = label;
    })
    return timezoneInfo;
}


function useTimezone() {
    const [status, setStatus] = useState({ loading: true })
    
    useEffect(() => {
        timezone()
        .then(data => setStatus({ success: true, data: transformTimeZone(data.timezones) }))
        .catch(e => setStatus({ error: true, message: e.message }))
    }, []);

    return status;
}

export default useTimezone;