import { globalGetService, globalPostService } from "../../utils/globalApiServices";

export function getTeamMemberLists(data = {}) {
  return new Promise((resolve, reject) => {
    globalGetService(`list-of-team-members-v1`, data)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function inviteTeamMembersLists(data = {}) {
  return new Promise((resolve, reject) => {
    globalPostService(`invite-team-members-v1`, data)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
