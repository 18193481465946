import React from "react";
import { Grid, List, ListItem } from "@material-ui/core";
import styles from "../../../assets/styles/overview.module.scss";
import { Skeleton } from "@material-ui/lab";
import { imageBasePath } from "../../../constants";

const orgDetails = [
  { id: 1, name: "GST number" },
  { id: 2, name: "TIN number" },
  { id: 3, name: "PAN number" },
  { id: 4, name: "Admin" },
  { id: 5, name: "Admin Email" },
];

export default function Shimmer(props) {
  return (
    <>
      {props.type == "orgOverview" && (
        <>
          {[0].map((item, key) => (
            <React.Fragment key={key}>
              <div className={styles.organizationDetailsCardWrapper}>
                <div className={styles.organizationDetailsCard}>
                  <h3 className={styles.organizationDetailsTitle + " subheading2 "}>Organization details</h3>
                  <div className={styles.cardBody}>
                    <Grid container>
                      <Grid item md={7}>
                        <div className={styles.orgDetailsLeftBlk}>
                          <Skeleton
                            variant="circle"
                            width={70}
                            height={70}
                            style={{
                              display: "block",
                              verticalAlign: "middle",
                              transform: "none",
                              textAlign: "center",
                              justifyContent: "center",
                            }}
                          />
                          <div className={styles.orgName + " subheading "}>
                            <Skeleton
                              variant="text"
                              width={200}
                              height={20}
                              style={{
                                display: "block",
                                transform: "none",
                                marginTop: 20,
                              }}
                            />
                          </div>
                          <div className={styles.orgAddress + " supportText "}>
                            <Skeleton
                              variant="text"
                              width={300}
                              height={12}
                              style={{
                                display: "block",
                                transform: "none",
                                marginBottom: 10,
                              }}
                            />
                            <Skeleton
                              variant="text"
                              width={250}
                              height={12}
                              style={{
                                display: "block",
                                transform: "none",
                                marginBottom: 10,
                              }}
                            />
                          </div>
                        </div>
                      </Grid>
                      <Grid item md={5}>
                        <div className={styles.orgDetailsRightBlk}>
                          {orgDetails.map((org) => {
                            return (
                              <div className={styles.orgDetailsRightBlkItem} key={org.id}>
                                <Grid container spacing={2}>
                                  <Grid item md={5}>
                                    <div className={styles.orgDetailsInnerLeftBlk}>
                                      <p className={styles.orgDetailsInnerLeftText + " supportText "}>
                                        {org.name}
                                      </p>
                                    </div>
                                  </Grid>
                                  <Grid item md={7}>
                                    <div className={styles.orgDetailsInnerRightBlk}>
                                      <div className={styles.orgDetailsInnerRightText + " paragraph "}>
                                        <Skeleton
                                          variant="text"
                                          width={150}
                                          height={12}
                                          style={{
                                            display: "block",
                                            transform: "none",
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                            );
                          })}
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                  <div className={styles.cardFooter}>
                    <Grid container>
                      {[0, 1, 2].map((item, key) => {
                        return (
                          <React.Fragment key={key}>
                            <Grid item md={4}>
                              <div className={styles.orgDetailsTotalBlk}>
                                <Skeleton
                                  variant="circle"
                                  style={{
                                    width: 48,
                                    height: 48,
                                    objectFit: "cover",
                                    position: "absolute",
                                    left: 24,
                                    top: 24,
                                  }}
                                />
                                <div className={styles.orgDetailsTotalWrapper}>
                                  <div className={styles.orgTotalText + " supportText "}>
                                    <Skeleton
                                      variant="text"
                                      width={150}
                                      height={12}
                                      style={{
                                        display: "block",
                                        transform: "none",
                                        marginTop: 10,
                                      }}
                                    />
                                  </div>
                                  <div className={styles.orgTotalCount + " subheading2 "}>
                                    <Skeleton
                                      variant="text"
                                      width={75}
                                      height={12}
                                      style={{
                                        display: "block",
                                        transform: "none",
                                        marginTop: 10,
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </Grid>
                          </React.Fragment>
                        );
                      })}
                    </Grid>
                  </div>
                </div>
              </div>
              <div className={styles.devicesCardWrapper}>
                <div className="devicesCard">
                  <h3 className="devicesTitle subheading2 ">Devices</h3>
                  <div className="cardBody">
                    <Grid container>
                      <Grid item md={7}>
                        <Grid container>
                          <Grid item md={6}>
                            <div className="activatedDevicesWrapper">
                              <div className="deviceImgWrapper">
                                <Skeleton
                                  variant="circle"
                                  style={{
                                    width: 85,
                                    height: 85,
                                    objectFit: "cover",
                                    position: "absolute",
                                  }}
                                />
                              </div>
                              <div className="activatedDeviceTextWrapper">
                                <div className="activatedDevicesText paragraph">
                                  <Skeleton
                                    variant="text"
                                    width={150}
                                    height={12}
                                    style={{
                                      display: "block",
                                      transform: "none",
                                    }}
                                  />
                                </div>
                                <div className="activatedDevicesCount subheading">
                                  <Skeleton
                                    variant="text"
                                    width={75}
                                    height={12}
                                    style={{
                                      display: "block",
                                      transform: "none",
                                      marginTop: 10,
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item md={5}>
                        <div className="deviceStatusRightBlk">
                          <div className="deviceStatusBlk">
                            <div className="deviceImgWrapper">
                              <Skeleton
                                variant="circle"
                                style={{
                                  width: 50,
                                  height: 50,
                                  objectFit: "cover",
                                  position: "absolute",
                                }}
                              />
                            </div>
                            <div className="textBlkWrapper">
                              <div className="notActivatedDevicesText paragraph">
                                <Skeleton
                                  variant="text"
                                  width={150}
                                  height={12}
                                  style={{
                                    display: "block",
                                    transform: "none",
                                    position: "relative",
                                    top: 10,
                                  }}
                                />
                              </div>
                              <div className="notActivatedDevicesCount subheading">
                                <Skeleton
                                  variant="text"
                                  width={75}
                                  height={12}
                                  style={{
                                    display: "block",
                                    transform: "none",
                                    position: "relative",
                                    top: 10,
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="deviceStatusBlk">
                            <div className="deviceImgWrapper">
                              <Skeleton
                                variant="circle"
                                style={{
                                  width: 50,
                                  height: 50,
                                  objectFit: "cover",
                                  position: "absolute",
                                }}
                              />
                            </div>
                            <div className="textBlkWrapper">
                              <div className="notActivatedDevicesText paragraph">
                                <Skeleton
                                  variant="text"
                                  width={150}
                                  height={12}
                                  style={{
                                    display: "block",
                                    transform: "none",
                                    position: "relative",
                                    top: 10,
                                  }}
                                />
                              </div>
                              <div className="notActivatedDevicesCount subheading">
                                <Skeleton
                                  variant="text"
                                  width={75}
                                  height={12}
                                  style={{
                                    display: "block",
                                    transform: "none",
                                    position: "relative",
                                    top: 10,
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </div>
              </div>
            </React.Fragment>
          ))}
        </>
      )}
    </>
  );
}
