import React, { useState } from "react";
import { Container, Grid } from "@material-ui/core";
import styles from "../../../assets/styles/addOrganisation.module.scss";
import { imageBasePath } from "../../../constants";
import { CustomLink } from "../../../shared_ui_components";
import AddOrganisationForm from "../components/AddOrganisationForm";
import AddOrganisationSuccess from "../components/AddOrganisationSuccess";
import { useHistory } from "react-router-dom";

export default function AddOrganisation(props) {
  const history = useHistory();

  const [isAddOrganisationSuccess, setIsAddOrganisationSuccess] = useState(false);
  const [organisationData, setOrganisationData] = useState({});

  const handleChange = (value) => {
    setOrganisationData(value);
    setIsAddOrganisationSuccess(true);
  };
  const handleGoBack = () => {
    history.goBack();
  };

  return (
    <Container maxWidth="lg">
      {isAddOrganisationSuccess ? (
        <AddOrganisationSuccess
          organisationData={organisationData}
          // organisationData={{ orgName: "DesignString", orgId: "618df47379882c5ff3f182a2" }}
          setIsAddOrganisationSuccess={setIsAddOrganisationSuccess}
        />
      ) : (
        <div className={styles.addOrganisationPage} handleChange={handleChange}>
          <div className={styles.addOrganisationWrapper}>
            <div className={styles.backBtnWrapper}>
              <CustomLink
                className="backBtn"
                text="Back"
                leftIcon={imageBasePath + "arrow_left_icon.svg"}
                onClick={handleGoBack}
              />
            </div>
            <AddOrganisationForm handleChange={handleChange} />
          </div>
        </div>
      )}
    </Container>
  );
}
