import React from "react";
import { Grid } from "@material-ui/core";
import styles from "../../../assets/styles/quotations.module.scss";
import { imageBasePath } from "../../../constants";
import { Link } from "react-router-dom";
import moment from "moment";
import { toastFlashMessage } from "../../../utils";

export const SingleQuotationList = (props) => {
  const { item } = props;

  return (
    <Link
      to={item.quotationStatus === "responded" && `./quotations/${item._id}`}
      className={
        item.quotationStatus === "responded" ? styles.quotationListItem : styles.quotationListItemBlock
      }
    >
      <div className={styles.quotationsListTableRow}>
        <Grid container spacing={2} alignItems="center" justifyContent="center">
          <Grid item md={7}>
            <Grid container spacing={2} alignItems="center" justifyContent="center">
              <Grid item md={5}>
                <div className={styles.quotationContentWrapper}>
                  <img src={imageBasePath + "quotation_order.svg"} class={styles.quotationImg} alt="" />
                  <div className={styles.quotationStatus}>
                    <img
                      src={
                        imageBasePath +
                        `${
                          item.quotationStatus === "pending"
                            ? "quotation_warning.svg"
                            : "quotation_success.svg"
                        }`
                      }
                      alt=""
                    />
                  </div>
                  <p className={styles.quoteNum + " paragraph "}>{item.quotationNo}</p>
                </div>
              </Grid>
              <Grid item md={4}>
                <div className={styles.quotationContentWrapper}>
                  <p className={styles.requestedOn + " paragraph "}>
                    {moment(item.requstedDate, "YYYY-MM-DD").format("DD/MM/YYYY")}
                  </p>
                </div>
              </Grid>
              <Grid item md={3}>
                <div className={styles.quotationContentWrapper}>
                  <p className={styles.unitsCount + " paragraph "}>{item.quantity}</p>
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={5}>
            <Grid container spacing={2} alignItems="center" justifyContent="center">
              <Grid item md={7}>
                <div className={styles.quotationContentWrapper}>
                  <p className={styles.subscriptionPeriod + " paragraph "}>
                    {item.subscriptionPeriod && (
                      <>
                        <span>{item.subscriptionPeriod / 12}</span>{" "}
                        {item?.subscriptionPeriod / 12 > 1 ? "years" : "year"}
                      </>
                    )}
                  </p>
                </div>
              </Grid>
              <Grid item md={5}>
                <div className={styles.quotationContentWrapper}>
                  <p
                    className={
                      styles.priceStatus +
                      " paragraph " +
                      `${item.quotationStatus === "pending" ? styles.info : styles.success}`
                    }
                  >
                    {item.quotationStatus === "pending" ? "No" : "Yes"}
                  </p>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </Link>
  );
};
