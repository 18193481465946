import { globalPostService, globalGetService } from "../../utils/globalApiServices";

export function createOrgApi(data = {}) {
  return new Promise((resolve, reject) => {
    globalPostService(`add-organisation-details-v1`, data)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
export function updateOrgApi(data = {}) {
  return new Promise((resolve, reject) => {
    globalPostService(`update-organisation-details-by-id-v1`, data)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
