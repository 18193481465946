import React, { useContext, useEffect, useState } from "react";
import { getDeviceDeploymentDetails } from "../apiServices";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import styles from "../../../assets/styles/devices.module.scss";
import { Grid } from "@material-ui/core";
import DeviceList from "../components/DeviceList";
import { CustomLink } from "../../../shared_ui_components";
import { imageBasePath } from "../../../constants";
import { GlobalContext } from "../../../context/GlobalContext";
import { toastFlashMessage } from "../../../utils";

function DeviceDeploymentDetails() {
  const { id, _id } = useParams();
  const [deviceDeployDetails, setDeviceDeployDetails] = useState("");
  const [release, setRelease] = useState("");
  const [orgNames, setOrgNames] = useState([]);
  const [timestamp,setTimestamp]=useState("")

  const {
    state: { orgListNames },
  } = useContext(GlobalContext);

  useEffect(() => {
    const data = orgListNames.map((name) => {
      return { name: name.orgName, id: name._id };
    });
    setOrgNames(data);
  }, [orgListNames]);

  useEffect(() => {
    getDeviceDeploymentDetails({ deploymentId: _id, orgId: id }).then(
      (resp) => {
        if (resp.status) {
          setDeviceDeployDetails(resp.devices);
          setRelease(resp.releaseName);
          setTimestamp(resp.createdAt)
        }else{
          toastFlashMessage(
            `Something went wrong while fetching the deployment`,
            "error"
          );
        }
      }
    ).catch((error)=>{
      console.log(error);
      toastFlashMessage(
        error.message,
        "error"
      );
    });;
  }, []);

  return (
    <div className={styles.devicesPage}>
      <div className={styles.devicesWrapper}>
        <div className={styles.devicesHeaderWrapper}>
          <div className={styles.devicesPageTitleWrapper}>
            <div className={styles.backBtnWrapper}>
              <CustomLink
                className="backBtn"
                text="Back"
                leftIcon={imageBasePath + "arrow_left_icon.svg"}
                to={`/${id}/deployments`}
              />
            </div>
            <h2
              className={styles.devicesPageTitle + " subheading "}
              style={{ marginTop: "20px" }}
            >
              {orgNames?.find((org) => org.id === id)?.name} - {release} -
              Deployment
            </h2>
          </div>
          <div className={styles.devicesListWrapper}>
            <div className={styles.devicesList}>
              <div className={styles.devicesListItemWrapper}>
                <div className={styles.devicesListItemTitleWrapper}>
                  <Grid container spacing={2}>
                    <Grid item md={3}>
                      <div className={styles.devicesListItemTitleTextWrapper}>
                        <p
                          className={
                            styles.devicesListItemTitle + " supportText "
                          }
                        >
                          Workspace Name
                        </p>
                      </div>
                    </Grid>
                    <Grid item md={3}>
                      <div className={styles.devicesListItemTitleTextWrapper}>
                        <p
                          className={
                            styles.devicesListItemTitle + " supportText "
                          }
                        >
                          Room Name
                        </p>
                      </div>
                    </Grid>
                    <Grid item md={2}>
                      <div className={styles.devicesListItemTitleTextWrapper}>
                        <p
                          className={
                            styles.devicesListItemTitle + " supportText "
                          }
                        >
                          MacId
                        </p>
                      </div>
                    </Grid>
                    <Grid item md={2}>
                      <div className={styles.devicesListItemTitleTextWrapper}>
                        <p
                          className={
                            styles.devicesListItemTitle + " supportText "
                          }
                        >
                          Status
                        </p>
                      </div>
                    </Grid>
                    <Grid item md={2}>
                      <div className={styles.devicesListItemTitleTextWrapper}>
                        <p
                          className={
                            styles.devicesListItemTitle + " supportText "
                          }
                        >
                          Date
                        </p>
                      </div>
                    </Grid>
                  </Grid>
                </div>
                {/*  */}
                <div className={styles.devicesListTableContent}>
                  {deviceDeployDetails.length &&
                    deviceDeployDetails.map((deployment) => {
                      return (
                        <DeviceList
                          deployment={deployment}
                          timestamp={timestamp}
                        />
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DeviceDeploymentDetails;
