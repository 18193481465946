import React from 'react';
import styles from "../../../../assets/styles/admin/enquiries.module.scss";
import { Grid } from "@material-ui/core";
import { imageBasePath } from "../../../../constants";
import { InputText } from "../../../../shared_ui_components";
import EnquiriesList from "../components/EnquiriesList";

export default function Enquiries(props) {
    return (
        <div className={styles.enquiriesPage}>
            <div className={styles.enquiriesHeading}>
                <h2 className={styles.enquiriesTitle + " subheading "}>Enquiries</h2>
            </div>
            <div className={styles.enquiriesSearchBlk}>
                <Grid container direction="row" justifyContent="flex-end" alignItems="center">
                    <Grid item md={2}>
                        <div className={styles.enquiriesSearchInput}>
                            <InputText placeholder="Search here" />
                            <img src={imageBasePath + "search_icon.svg"} alt="" />
                        </div>
                    </Grid>
                </Grid>
            </div>
            {/*  <NoResult
                image="no-order-found.svg"
                title="No orders found"
                subtext="Looks like you have not yet placed any order. Start requesting quotes to place bulk orders."
                btnText="Request quote"
            /> */}
            <EnquiriesList />
        </div>
    )
}
