import React from "react";
import styles from "../../../assets/styles/devices.module.scss";
import { ResourcesList } from "../components";

export default function Resources(props) {
  return (
    <div className={styles.devicesPage} style={{padding:"0px"}}>
      <div className={styles.devicesWrapper}>
        <ResourcesList />
      </div>
    </div>
  );
}
