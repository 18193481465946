import React from "react";
import { Grid, Tabs, Tab } from "@material-ui/core";
import styles from "../../../assets/styles/orders.module.scss";
import { imageBasePath } from "../../../constants";
import { Link } from "react-router-dom";
import { format } from "date-fns";
import { getUserInfo } from "../../../utils";

export const SingleOrderList = (props) => {
  const userInfo = getUserInfo();
  const { orderDetails, order } = props;

  const handleOrderDetails = (orderDetailId) => {
    orderDetails(orderDetailId);
  };
  return (
    <Link onClick={() => handleOrderDetails(order?._id)} className={styles.orderListItem}>
      <div className={styles.ordersListTableRow}>
        <Grid container spacing={2} justifyContent="center" alignItems="center">
          <Grid item md={4}>
            <Grid container direction="row" spacing={2} justifyContent="flex-start" alignItems="center">
              <Grid item md={8}>
                <div className={styles.orderContentWrapper}>
                  {/* <div className={styles.orderImageWrapper}>
                    <img src={imageBasePath + "order-box.svg"} className={styles.orderBox} alt="" />
                    <img
                      src={imageBasePath + "order_status_pending.svg"}
                      className={styles.orderBoxStatus}
                      alt=""
                    />
                  </div> */}
                  <img
                    src={
                      order?.paymentStatus === "Pending" || order?.paymentStatus === "pending"
                        ? imageBasePath + "Order_Not_Shipped.svg"
                        : order?.paymentStatus === "Paid"
                        ? imageBasePath + "Order_Delivered.svg"
                        : imageBasePath + "Order_Shipped.svg"
                    }
                    className={styles.shipmentBox}
                    alt=""
                  />
                  <div className={styles.orderDetailWrapper}>
                    <p className={styles.quoteNum + " paragraphSemiBold "}>#{order?._id.slice(-4)}</p>
                    <p className={styles.companyName + " paragraphSemiBold "}>{order?.orgName}</p>
                  </div>
                </div>
              </Grid>
              <Grid item md={4}>
                <div className={styles.orderContentWrapper}>
                  <p className={styles.requestedOn + " paragraph "}>
                    {format(new Date(order?.createdAt), "dd/MM/yyyy")}
                  </p>
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={2}>
            <Grid container direction="row" spacing={2} justifyContent="flex-start" alignItems="center">
              <Grid item md={4}>
                <div className={styles.orderContentWrapper}>
                  <p className={styles.unitsCount + " paragraph "}>{order?.units}</p>
                </div>
              </Grid>
              <Grid item md={8}>
                <div className={styles.orderContentWrapper}>
                  <p className={styles.unitsCount + " paragraph "}>
                    {order?.subscriptionPeriod / 12 < 1
                      ? `${order?.subscriptionPeriod} Months`
                      : order?.subscriptionPeriod / 12 == 1
                      ? `${order?.subscriptionPeriod / 12} Year`
                      : `${order?.subscriptionPeriod / 12} Years`}
                  </p>
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={6}>
            <Grid container direction="row" spacing={2} justifyContent="flex-start" alignItems="center">
              <Grid item md={4}>
                <div className={styles.orderContentWrapper}>
                  <img
                    src={
                      order?.paymentStatus === "Pending" || order?.paymentStatus === "pending"
                        ? imageBasePath + "order_status_hold.svg"
                        : order?.paymentStatus === "Paid"
                        ? imageBasePath + "order_status_paid.svg"
                        : imageBasePath + "order_status_partially_paid.svg"
                    }
                    alt=""
                  />
                  <p
                    className={
                      order?.paymentStatus === "Pending" || order?.paymentStatus === "pending"
                        ? styles.orderStatus
                        : order?.paymentStatus === "Paid"
                        ? styles.orderStatusPaid
                        : styles.orderStatusPartillayPaid + " paragraph "
                    }
                  >
                    {order?.paymentStatus === "Pending" || order?.paymentStatus === "pending"
                      ? "Yet To Pay"
                      : order?.paymentStatus === "Paid"
                      ? "Paid"
                      : "Partially Paid"}
                  </p>
                </div>
              </Grid>
              <Grid item md={4}>
                <div className={styles.orderContentWrapper}>
                  <p className={styles.unitsCount + " paragraph "}>{order?.shippingInProgress}</p>
                </div>
              </Grid>
              <Grid item md={4}>
                <div className={styles.orderContentWrapper}>
                  <p className={styles.unitsCount + " paragraph "}>{order?.shippedSuccessfully}</p>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </Link>
  );
};
