import React, { useState, useEffect } from "react";
import styles from "../../../assets/styles/users.module.scss";
import {
  imageBasePath,
  errorCodes,
  reactSelectStylesLarge,
  reactSelectStylesMultiSelect,
} from "../../../constants";
import { InputSelect, InputText, CustomLink, CustomButton } from "../../../shared_ui_components";
import { getWorkspaceListing } from "../../workspaces/apiServices";
import { useParams } from "react-router";
import { inviteOrgUsersLists, inviteWorkSpaceUsersLists } from "../apiServices";
import { fieldValidation } from "../../../utils/formValidation";
import { toastFlashMessage } from "../../../utils";
import { getDomain } from "../../settings/apiServices";

const orgAdmins = [
  { value: "61260e08c04da62578ea6e68", label: "Organisation Admin" },
  { value: "615d71fdb1c9a44820f03474", label: "Local Admin" },
];

export default function InviteUserDialog(props) {
  const { id } = useParams();
  const [workspaceLists, setWorkspaceLists] = useState([]);
  const [count, setCount] = useState(2);
  const [validateLoader, setValidateLoader] = useState(false);
  const [error, setError] = useState({});
  const [domainLists, setDomainLists] = useState([]);

  const [form, setForm] = useState({
    domainError: "",
    inviteUsers: [
      {
        id: 1,
        email: "",
        roleId: "",
        workspaceId: [],
        orgId: [id],
        isEmailError: false,
        isRoleError: false,
        isWorkspaceError: false,
      },
    ],
  });

  useEffect(() => {
    getWorkspaceListing({ orgId: id }).then((response) => {
      if (response.status) {
        const workspace = response.data.map((org) => {
          return { label: org?.orgLocation?.orgWorkSpaceName, value: org?.orgLocation._id };
        });
        setWorkspaceLists(workspace);
      }
    });

    getDomain({ orgId: id }).then((response) => {
      if (response.status) {
        setDomainLists(response.domainList);
      } else {
      }
    });
  }, [id]);

  const handleAddUsers = () => {
    setCount((prev) => prev + 1);
    setForm({
      ...form,
      inviteUsers: [
        ...form.inviteUsers,
        ...[
          {
            id: count,
            email: "",
            roleId: "",
            workspaceId: [],
            orgId: [id],
            isEmailError: false,
            isRoleError: false,
            isWorkspaceError: false,
          },
        ],
      ],
    });
    setError({});
  };

  const handleChange = (key, value, index) => {
    setForm((preForm) => ({
      ...preForm,
      inviteUsers: preForm.inviteUsers.map((users, _index) =>
        index === _index ? { ...users, [key]: value } : { ...users }
      ),
    }));
    setError((preError) => ({
      ...preError,
      [key]: value,
    }));
  };

  const handleRemoveUsers = (id) => {
    const updatedUsers = form?.inviteUsers?.filter((users) => users.id !== id);
    setForm({
      ...form,
      inviteUsers: updatedUsers,
    });
  };

  async function inviteUsersAndHandleResponse(form) {
    const inviteUsers = [];
    const workspaceUsers = [];
    let localadmin = false;
  
    form.inviteUsers.forEach((ele) => {
      if (ele.workspaceId.length > 0) {
        localadmin = true;
        workspaceUsers.push({ workspaceId: ele.workspaceId, email: ele.email, roleId: ele.roleId });
      } else {
        inviteUsers.push({ orgId: ele.orgId, email: ele.email, roleId: ele.roleId });
      }
    });
  
    try {
      let response;
  
      if (localadmin) {
        response = await inviteWorkSpaceUsersLists({ workspaceUsers });
      } else {
        response = await inviteOrgUsersLists({ inviteUsers, orgId : id});
      }
  
      if (response.status) {
        setValidateLoader(false);
        props.getUserList()
        toastFlashMessage("Users invited successfully", "success");
        props.handleClose();
      } else {
        toastFlashMessage("Something went wrong while inviting users", "error");
        setValidateLoader(false);
      }
    } catch (error) {
      console.log(error);
      toastFlashMessage("Something went wrong while inviting users", "error");
      setValidateLoader(false);
    }
  }
  

  const   handleSubmit = (e) => {
    e.preventDefault();
    let validateNewInput = {};
    form?.inviteUsers?.forEach(async (item, idx) => {
      const getEmail = item.email.slice(item.email.indexOf("@") + 1, item.email.length);
      if (domainLists.find((ele) => ele.domainName?.includes(getEmail))) {
        if (item.roleId == "615d71fdb1c9a44820f03474") {
          if (idx + 1 === item.id) {
            validateNewInput = {
              ...validateNewInput,
              [`email-${idx}`]:
                errorCodes["email"][
                  fieldValidation({
                    ...errorCodes.emailObj,
                    fieldval: item.email,
                  })
                ],
              [`roleId-${idx}`]:
                errorCodes["roleId"][
                  fieldValidation({
                    ...errorCodes.roleIdObj,
                    fieldval: item.roleId,
                  })
                ],
              [`workspaceId-${idx}`]:
                errorCodes["workspaceId"][
                  fieldValidation({
                    ...errorCodes.workspaceIdObj,
                    fieldval: item.workspaceId,
                  })
                ],
            };
          }
        } else {
          if (idx + 1 === item.id) {
            validateNewInput = {
              ...validateNewInput,
              [`email-${idx}`]:
                errorCodes["email"][
                  fieldValidation({
                    ...errorCodes.emailObj,
                    fieldval: item.email,
                  })
                ],
              [`roleId-${idx}`]:
                errorCodes["roleId"][
                  fieldValidation({
                    ...errorCodes.roleIdObj,
                    fieldval: item.roleId,
                  })
                ],
            };
          }
        }
        if (Object.keys(validateNewInput).every((k) => validateNewInput[k] == "")) {
          setValidateLoader(true);
         await inviteUsersAndHandleResponse(form)
         
        } else {
          setValidateLoader(false);
          setError(validateNewInput);
        }
      } else {
        // toastFlashMessage("Entered domain is not present in domain list", "error");
        setForm((form) => ({
          ...form,
          domainError: "Entered domain is not present in domain list",
        }));
        setValidateLoader(false);
      }
    });
  };

  return (
    <div className={styles.userDialogWrapper}>
      <div className={styles.userDialogTitle}>
        <p className={styles.userDialogHeader + " subheading"}>Invite users</p>
        <div onClick={props.handleClose} style={{ cursor: "pointer" }}>
          <img src={imageBasePath + "user_cancel.svg"} alt="" />
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className={styles.userDialogDetail}>
          {form?.inviteUsers?.map((item, index) => {
            return (
              <React.Fragment key={index}>
                <div className={styles.userDialogInput}>
                  <div>
                    <InputText
                      className={styles.userInputEmail}
                      placeholder="Johndoe@gmail.com"
                      value={item.email.trim()}
                      onChange={(e) => handleChange("email", e.target.value.trim(), index)}
                      // error={
                      //   item.isEmailError &&
                      //   item.email.length != 0 &&
                      //   item.email.includes("@") &&
                      //   item.email.includes(".")
                      //     ? ""
                      //     : error?.[`email-${index}`]
                      // }
                      error={form.domainError ? form.domainError : error?.[`email-${index}`]}
                    />
                  </div>
                  <div>
                    <InputSelect
                      className={styles.userInputOrg}
                      placeholder="Organisation Admin"
                      options={orgAdmins}
                      styles={reactSelectStylesLarge}
                      value={item.roleId ? orgAdmins.find((org) => org.value === item.roleId) : null}
                      onChange={(org) => handleChange("roleId", org.value, index)}
                      error={item.isRoleError && item.roleId.length != 0 ? "" : error?.[`roleId-${index}`]}
                      // error={error?.[`roleId-${index}`]}
                      isSearchable={false}
                    />
                  </div>
                  {index > 0 && (
                    <img
                      src={imageBasePath + "delete-bin-line.svg"}
                      alt=""
                      onClick={() => handleRemoveUsers(item.id)}
                      className={styles.removeUsersBtn}
                    />
                  )}
                </div>

                {item.roleId === "615d71fdb1c9a44820f03474" && (
                  <div className={styles.userDialogDropdown}>
                    <InputSelect
                      placeholder="All Workspaces"
                      options={workspaceLists}
                      value={
                        item.workspaceId
                          ? workspaceLists.filter((workspace) => item.workspaceId.includes(workspace.value))
                          : []
                      }
                      onChange={(workspace) => {
                        handleChange(
                          "workspaceId",
                          workspace.map((ele) => ele.value),
                          index
                        );
                      }}
                      error={
                        item.isWorkspaceError && item.workspaceId.length != 0
                          ? ""
                          : error?.[`workspaceId-${index}`]
                      }
                      isMulti={true}
                      styles={reactSelectStylesMultiSelect}
                    />
                  </div>
                )}
              </React.Fragment>
            );
          })}
        </div>
        <div className={styles.userDialogButtonWrapper}>
          <CustomLink
            className={styles.userButtonAddMore + " paragraphSemiBold"}
            text="Add User"
            leftIcon={imageBasePath + "invite_user.svg"}
            onClick={handleAddUsers}
          />
          <CustomButton
            className={`${styles.inviteBtn} primaryBtn ${validateLoader ? "disabledBtn" : ""}`}
            children={validateLoader ? "Inviting..." : "Invite"}
            rightIcon={imageBasePath + "btn_arrow_right_icon.svg"}
            type="submit"
          />
        </div>
      </form>
    </div>
  );
}
