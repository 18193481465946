import React from "react";
import { Grid, Container } from "@material-ui/core";
import styles from "../../../assets/styles/registration.module.scss";
import { imageBasePath } from "../../../constants";
import { CustomLink } from "../../../shared_ui_components";

export default function PartnerSuccess(props) {
    return (
        <div className={styles.PartnerSuccessWrapper}>
            <img className={styles.SuccessConfetti} src={imageBasePath + "partner_success.png"} alt="partner_success" />
            <p className={styles.SuccessHeading+" heading6"}>Your account creation request has been submitted successfully</p>
            <p className={styles.SuccessSubHeading+" subheading"}>We’ll connect with you soon to proceed further</p>
            <CustomLink
                to="/"
                className={styles.backToHomeBtn+" primaryBtn"}
                text="Back to Store"
            />
        </div>
    );
}
