import React from "react";
import styles from "../../../assets/styles/devices.module.scss";
import { DeviceDetails } from "../components";

export default function DeviceInfo(props) {
  return (
    <div className={styles.deviceInfoPage}>
      <div className={styles.deviceInfoWrapper}>
        <DeviceDetails />
      </div>
    </div>
  );
}
