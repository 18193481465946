import React, { useState } from "react";
import styles from "../../../assets/styles/allOrganisation.module.scss";
import { Grid, Menu, MenuItem } from "@material-ui/core";
import { imageBasePath } from "../../../constants";
import { deleteOrganisation } from "../apiServices";
import { getStore } from "../../../store/GlobalStore";
import { toastFlashMessage } from "../../../utils";
import { useHistory } from "react-router";
import { getUserInfo } from "../../../utils";
import { Link } from "react-router-dom";

export const SingleOrganisation = ({ _id, orgName, deviceCount, workSpaceCount, userData }) => {
  const history = useHistory();
  const userInfo = getUserInfo();
  const [anchorEl, setAnchorEl] = useState(null);

  const lSRecentlyVisitedOrg = JSON.parse(localStorage.getItem("recentlyVisitedOrgs"));

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEditOrg = (_id) => {
    setAnchorEl(null);
    history.push(`/${_id}/edit-organisation`);
  };

  const handleDeleteOrg = (_id) => {
    deleteOrganisation({ orgId: _id }).then((response) => {
      if (response.status) {
        const { dispatch } = getStore();
        dispatch({
          type: "DELETE_ORGANISATION_NAME",
          payload: _id,
        });
        toastFlashMessage("Organisation deleted succussfully ", "success");
        localStorage.setItem(
          "recentlyVisitedOrgs",
          JSON.stringify(lSRecentlyVisitedOrg.filter((orgId) => orgId._id !== _id))
        );
      } else {
        toastFlashMessage("Something went wrong while deleting organisation!", "error");
      }
    });
  };
  return (
    <div className={styles.allOrganisationListItem}>
      <div className={styles.allOrganisationListTableRow}>
        <Link to={`/${_id}/edit-organisation`}>
          <Grid container direction="row" spacing={2} justifyContent="flex-start" alignItems="center">
            <Grid item md={4}>
              <div className={styles.allOrganisationContentWrapper}>
                <div className={styles.allOrganisationImageWrapper}>
                  <img
                    src={imageBasePath + "add_organisation.svg"}
                    className={styles.allOrganisationBox}
                    alt=""
                  />
                </div>
                <div className={styles.allOrganisationDetailWrapper}>
                  <p className={styles.allOrganisationName + " paragraphSemiBold "}>{orgName}</p>
                </div>
              </div>
            </Grid>
            <Grid container item md={3}>
              <Grid item md={6}>
                <div className={styles.allOrganisationContentWrapper}>
                  <p className={styles.noOfWorkspace + " paragraph "}>{workSpaceCount}</p>
                </div>
              </Grid>
              <Grid item md={6}>
                <div className={styles.allOrganisationContentWrapper}>
                  <p className={styles.noOfBox + " paragraph "}>{deviceCount}</p>
                </div>
              </Grid>
            </Grid>
            <Grid item md={5}>
              <div className={styles.allOrganisationContentWrapper}>
                <p className={styles.adminEmail + " paragraph "}>
                  {/* aaditya_s128@welpro.com, nithish@welpro.com
                <span>+2 more</span> */}
                  {userData?.email ? userData?.email : "-"}
                </p>
              </div>
            </Grid>
          </Grid>
        </Link>
        {userInfo.userData?.role?.responsibilities?.updateOrg ? (
          <div className={styles.organisationEditOption}>
            <img
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={handleClick}
              alt=""
              src={imageBasePath + "more_vertical_icon.svg"}
            />
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={() => handleEditOrg(_id)}>Edit Details</MenuItem>
              <MenuItem onClick={() => handleDeleteOrg(_id)} style={{ color: "#CF2116" }}>
                Remove
              </MenuItem>
            </Menu>
          </div>
        ) : null}
      </div>
    </div>
  );
};
