import React from "react";
import { Grid } from "@material-ui/core";
import styles from "../../../assets/styles/team.module.scss";
import { imageBasePath } from "../../../constants";
import moment from "moment";
import { format } from "date-fns";

export const SingleTeamList = ({ userId, roleId, createdAt, updatedAt }) => {
  const hrsFuc = () => {
    const hrs = moment().diff(moment(updatedAt), "hours");
    if (hrs > 24) {
      return Math.floor(hrs / 24) === 1
        ? Math.floor(hrs / 24) + " day ago "
        : Math.floor(hrs / 24) + " days ago";
    } else {
      const showHours =
        Math.floor(hrs) === 1 ? Math.floor(hrs) + " hour ago" : Math.floor(hrs) + " hours ago";
      if (showHours == "0 hours ago") {
        const showMins = moment.utc(moment().diff(moment(updatedAt), "mins")).format("mm") + " mins ago";
        if (showMins == "00 mins ago ") {
          return "few seconds ago";
        } else {
          return showMins;
        }
      } else {
        return showHours;
      }
    }
  };

  return (
    <div className={styles.teamListItem}>
      <div className={styles.teamListTableRow}>
        <Grid container direction="row" spacing={2} justifyContent="flex-start" alignItems="center">
          <Grid item md={4}>
            <div className={styles.teamContentWrapper}>
              <div className={styles.teamImageWrapper}>
                <img
                  src={userId?.imageURL ? userId?.imageURL : imageBasePath + "team_profile.svg"}
                  className={styles.teamBox}
                  alt=""
                />
              </div>
              <div className={styles.teamDetailWrapper}>
                <p className={styles.teamName + " paragraphSemiBold "}>
                  {userId?.fullName ? userId?.fullName : "N/A"}
                </p>
                <p className={styles.teamEmail + " paragraphSemiBold "}>{userId?.email}</p>
              </div>
            </div>
          </Grid>

          <Grid item md={3}>
            <div className={styles.teamContentWrapper}>
              <p className={styles.lastActive + " paragraph "}>{hrsFuc()}</p>
            </div>
          </Grid>

          <Grid item md={3}>
            <div className={styles.teamContentWrapper}>
              <p className={styles.permission + " paragraph "}>
                {roleId?.roleName ? roleId?.roleName : "N/A"}
              </p>
            </div>
          </Grid>

          <Grid item md={2}>
            <div className={styles.teamContentWrapper}>
              <p className={styles.joinedOn + " paragraph "}>{format(new Date(createdAt), "dd/MM/yyyy")}</p>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};
