import Overview from "./container/Overview";
import QuotationLayoutHoc from "../../hoc/quotationLayoutHoc";

export const overviewRoutes = [
  {
    path: "/:id/overview",
    component: QuotationLayoutHoc(Overview),
    key: "overview",
  },
];
