import React from "react";
import styles from "../../../assets/styles/auth.module.scss";
import { InputText, CustomButton, CustomLink, ExternalLink } from "../../../shared_ui_components";
import {  googleOAuthClientId } from "../../../constants";
export default function SignInForm(props) {
  const { formData, error, submitLoader } = props;
  const socialLoginLoader = false;
  var base_url = window.location.origin;
  return (
    <div className={styles.signinFormWrapper}>
      <div className={styles.socialAuth}>
          {/* <ExternalLink
            className={styles.socialAuthBtn + " externalLinkBtn "}
            linkimg={imageBasePath + "sso_icon.svg"}
            linktext="Sign in with SSO"
          /> */}
          <ExternalLink
            className={styles.socialAuthBtn + " externalLinkBtn "}
            linkimg={"google_icon.svg"}
            linktext={socialLoginLoader ? "Signing in with Google..." : "Sign in with Google"}
            target=""
            href={`https://accounts.google.com/o/oauth2/v2/auth?scope=email%20profile&include_granted_scopes=true&response_type=token&state=1CXFac0eAkqBarEbqCbABBUkFppA788811uX7jE&redirect_uri=${base_url}/signin&client_id=${googleOAuthClientId}`}
          />
        </div>
      <InputText
        label="Email ID *"
        placeholder="eg, johndoe@email.com"
        value={formData.email ? formData.email : ""}
        onChange={(e) => props.handleChange("email", e.target.value.trim())}
        error={error.email}
        className={error.email ? "errorGroup" : " "}
        onKeyPress={(e) => {
          if (e.key === "Enter") {
            props.handleSubmit();
          }
        }}
      />
      <div className={styles.passwordSection}>
        <InputText
          label="Password *"
          placeholder="********"
          type={formData.password_show ? "text" : "password"}
          autoComplete="current-password"
          fullWidth
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(e) => props.handleChange("password", e.target.value)}
          value={formData.password}
          error={error.password}
          className={error.password ? "errorGroup" : " "}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              props.handleSubmit();
            }
          }}
        />
        <CustomLink
          to="/forgot-password"
          className={styles.forgotPasswordBlk + " label"}
          text="Forgot Password?"
        />
      </div>
      <CustomButton
        children={submitLoader ? "Signing In.." : "Sign In"}
        className={`primaryBtn submitBtn ${submitLoader ? "disabledBtn" : ""}`}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          props.handleSubmit();
        }}
      />
      <CustomButton
        children="Register As Partner"
        className="registerOutlinedBtn"
        onClick={()=>window.location.href="https://www.letsving.com/partner/registration"}
      />
    </div>
  );
}
