import React from "react";
import { Grid } from "@material-ui/core";
import { InputText } from "../../../../shared_ui_components";
import { imageBasePath } from "../../../../constants";
import styles from "../../../../assets/styles/admin/directOrder.module.scss";
import { Link } from "react-router-dom";
function DirectOrderList(props) {
  return (
    <div className={styles.directOrderListWrapper}>
      <div className={styles.directOrderHeading}>
        <h2 className={styles.directOrderTitle + " subheading "}>Customers</h2>
      </div>
      <div className={styles.directOrderSearchBlk}>
        <Grid container direction="row" justifyContent="flex-end">
          <Grid item md={4}>
            <div className={styles.directOrderSearchInput}>
              <InputText placeholder="Search here" />
              <img src={imageBasePath + "search_icon.svg"} />
            </div>
          </Grid>
        </Grid>
      </div>
      <Grid container>
        <Grid item md={12}>
          <div className={styles.directOrderList}>
            <div className={styles.directOrderListInnerBlk}>
              <div className={styles.directOrderListTableTitle}>
                <Grid container>
                  <Grid item md={6}>
                    <Grid container spacing={2}>
                      <Grid item md={6}>
                        <div
                          className={styles.directOrderTableTitleTextWrapper}
                        >
                          <p
                            className={
                              styles.directOrderTableTitleText + " supportText "
                            }
                          >
                            Customer Name
                          </p>
                          <img src={imageBasePath + "sort_icon.svg"} />
                        </div>
                      </Grid>
                      <Grid item md={6}>
                        <div
                          className={styles.directOrderTableTitleTextWrapper}
                        >
                          <p
                            className={
                              styles.directOrderTableTitleText + " supportText "
                            }
                          >
                            Email ID
                          </p>
                          <img src={imageBasePath + "sort_icon.svg"} />
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item md={6}>
                    <Grid container>
                      <Grid item md={4}>
                        <div
                          className={styles.directOrderTableTitleTextWrapper}
                        >
                          <p
                            className={
                              styles.directOrderTableTitleText + " supportText "
                            }
                          >
                            Phone Number
                          </p>
                          <img src={imageBasePath + "sort_icon.svg"} />
                        </div>
                      </Grid>
                      <Grid item md={4}>
                        <div
                          className={styles.directOrderTableTitleTextWrapper}
                        >
                          <p
                            className={
                              styles.directOrderTableTitleText + " supportText "
                            }
                          >
                            Organizations
                          </p>
                          <img src={imageBasePath + "sort_icon.svg"} />
                        </div>
                      </Grid>
                      <Grid item md={4}>
                        <div
                          className={styles.directOrderTableTitleTextWrapper}
                        >
                          <p
                            className={
                              styles.directOrderTableTitleText + " supportText "
                            }
                          >
                            Orders
                          </p>
                          <img src={imageBasePath + "sort_icon.svg"} />
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
              <div className={styles.directOrderListTableContent}>
                {[0].map((item, key) => (
                  <>
                    <Link
                      to="./direct-order-details"
                      className={styles.directOrderListItem}
                    >
                      <div className={styles.directOrderListTableRow}>
                        <Grid container>
                          <Grid item md={6}>
                            <Grid container spacing={2}>
                              <Grid item md={6}>
                                <div
                                  className={styles.directOrderContentWrapper}
                                >
                                  <img
                                    src={
                                      imageBasePath + "direct_order_user.svg"
                                    }
                                    class={styles.directOrderUserImg}
                                  />

                                  <p
                                    className={
                                      styles.customerName + " paragraph "
                                    }
                                  >
                                    George Louis
                                  </p>
                                </div>
                              </Grid>
                              <Grid item md={6}>
                                <div
                                  className={styles.directOrderContentWrapper}
                                >
                                  <p
                                    className={
                                      styles.customerEmail + " paragraph "
                                    }
                                  >
                                    george.louis@welpro.com
                                  </p>
                                </div>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item md={6}>
                            <Grid container spacing={2}>
                              <Grid item md={4}>
                                <div
                                  className={styles.directOrderContentWrapper}
                                >
                                  <p
                                    className={
                                      styles.customerDetails + " paragraph "
                                    }
                                  >
                                    +91 97842 42421
                                  </p>
                                </div>
                              </Grid>
                              <Grid item md={4}>
                                <div
                                  className={styles.directOrderContentWrapper}
                                >
                                  <p
                                    className={
                                      styles.customerDetails + " paragraph "
                                    }
                                  >
                                    02
                                  </p>
                                </div>
                              </Grid>
                              <Grid item md={4}>
                                <div
                                  className={styles.directOrderContentWrapper}
                                >
                                  <p
                                    className={
                                      styles.customerDetails + " paragraph "
                                    }
                                  >
                                    30
                                  </p>
                                </div>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </div>
                    </Link>
                    <Link
                      to="./direct-order-details"
                      className={styles.directOrderListItem}
                    >
                      <div className={styles.directOrderListTableRow}>
                        <Grid container>
                          <Grid item md={6}>
                            <Grid container spacing={2}>
                              <Grid item md={6}>
                                <div
                                  className={styles.directOrderContentWrapper}
                                >
                                  <img
                                    src={
                                      imageBasePath + "direct_order_user.svg"
                                    }
                                    class={styles.directOrderUserImg}
                                  />

                                  <p
                                    className={
                                      styles.customerName + " paragraph "
                                    }
                                  >
                                    George Louis
                                  </p>
                                </div>
                              </Grid>
                              <Grid item md={6}>
                                <div
                                  className={styles.directOrderContentWrapper}
                                >
                                  <p
                                    className={
                                      styles.customerEmail + " paragraph "
                                    }
                                  >
                                    george.louis@welpro.com
                                  </p>
                                </div>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item md={6}>
                            <Grid container spacing={2}>
                              <Grid item md={4}>
                                <div
                                  className={styles.directOrderContentWrapper}
                                >
                                  <p
                                    className={
                                      styles.customerDetails + " paragraph "
                                    }
                                  >
                                    +91 97842 42421
                                  </p>
                                </div>
                              </Grid>
                              <Grid item md={4}>
                                <div
                                  className={styles.directOrderContentWrapper}
                                >
                                  <p
                                    className={
                                      styles.customerDetails + " paragraph "
                                    }
                                  >
                                    02
                                  </p>
                                </div>
                              </Grid>
                              <Grid item md={4}>
                                <div
                                  className={styles.directOrderContentWrapper}
                                >
                                  <p
                                    className={
                                      styles.customerDetails + " paragraph "
                                    }
                                  >
                                    30
                                  </p>
                                </div>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </div>
                    </Link>
                    <Link
                      to="./direct-order-details"
                      className={styles.directOrderListItem}
                    >
                      <div className={styles.directOrderListTableRow}>
                        <Grid container>
                          <Grid item md={6}>
                            <Grid container spacing={2}>
                              <Grid item md={6}>
                                <div
                                  className={styles.directOrderContentWrapper}
                                >
                                  <img
                                    src={
                                      imageBasePath + "direct_order_user.svg"
                                    }
                                    class={styles.directOrderUserImg}
                                  />

                                  <p
                                    className={
                                      styles.customerName + " paragraph "
                                    }
                                  >
                                    George Louis
                                  </p>
                                </div>
                              </Grid>
                              <Grid item md={6}>
                                <div
                                  className={styles.directOrderContentWrapper}
                                >
                                  <p
                                    className={
                                      styles.customerEmail + " paragraph "
                                    }
                                  >
                                    george.louis@welpro.com
                                  </p>
                                </div>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item md={6}>
                            <Grid container spacing={2}>
                              <Grid item md={4}>
                                <div
                                  className={styles.directOrderContentWrapper}
                                >
                                  <p
                                    className={
                                      styles.customerDetails + " paragraph "
                                    }
                                  >
                                    +91 97842 42421
                                  </p>
                                </div>
                              </Grid>
                              <Grid item md={4}>
                                <div
                                  className={styles.directOrderContentWrapper}
                                >
                                  <p
                                    className={
                                      styles.customerDetails + " paragraph "
                                    }
                                  >
                                    02
                                  </p>
                                </div>
                              </Grid>
                              <Grid item md={4}>
                                <div
                                  className={styles.directOrderContentWrapper}
                                >
                                  <p
                                    className={
                                      styles.customerDetails + " paragraph "
                                    }
                                  >
                                    30
                                  </p>
                                </div>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </div>
                    </Link>
                  </>
                ))}
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default DirectOrderList;
