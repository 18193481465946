import React, { useState } from "react";
import { PartnerHeader } from "../../../shared_elements";
import QuotationDetail from "../components/QuotationDetail";
import QuotationRequestSuccess from "../components/QuotationRequestSuccess";

export default function RequestQuote() {
  const [isQuotationRequestSuccess, setIsQuotationRequestSuccess] = useState(false);

  const [quotationData, setQuotationData] = useState({});

  const handleChange = (value) => {
    setQuotationData(value);
    setIsQuotationRequestSuccess(true);
  };
  return (
    <div>
      <PartnerHeader />
      {isQuotationRequestSuccess ? (
        <QuotationRequestSuccess quotationData={quotationData} />
      ) : (
        <QuotationDetail handleChange={handleChange} />
      )}
      {/* <QuotationRequestSuccess /> */}
    </div>
  );
}
