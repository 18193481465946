import React, { useState, useEffect } from "react";
import { Grid, Drawer, Button } from "@material-ui/core";
import styles from "../../../assets/styles/workspaces.module.scss";
import { imageBasePath, errorCodes } from "../../../constants";
import { Link } from "react-router-dom";
import { CustomButton, CustomLink, InputText } from "../../../shared_ui_components";
import { fieldValidation } from "../../../utils/formValidation";
import { addWorkspaceRoomDetails, updateWorkspaceRoomDetails } from "../apiServices";
import { useParams, useLocation } from "react-router";
import { toastFlashMessage } from "../../../utils";
import { LogOutDialog } from "../../../shared_elements";

export default function AddRoomDrawer(props) {
  const { id, _id } = useParams();
  const location = useLocation();
  const {
    open,
    addworkspaceRoomDetails,
    updateworkspaceRoomDetail,
    updateRoomDetail,
    deactivateWorkspaceRoom,
    removeUpdatedRommDetails,
    handleClose,
    getWorkSpaceDetail,
  } = props;

  const [state, setState] = useState({
    right: false,
  });
  const [count, setCount] = useState(4);
  const [error, setError] = useState({});
  const [validateLoader, setValidateLoader] = useState(false);
  const [closeStatus, setCloseStatus] = useState(false);
  const [form, setForm] = useState({
    orgId: id,
    workspaceId: _id,
    noOfSeats: 1,
    additionalDevice: [
      {
        id: 1,
        deviceType: "Video Camera",
        deviceName: "",
        img: "camera_icon.svg",
        isNameError: false,
        isTypeError: false,
      },
      {
        id: 2,
        deviceType: "Microphone",
        deviceName: "",
        img: "mic_icon.svg",
        isNameError: false,
        isTypeError: false,
      },
      {
        id: 3,
        deviceType: "Speaker",
        deviceName: "",
        img: "speaker_icon.svg",
        isNameError: false,
        isTypeError: false,
      },
    ],
  });

  const handleSuccess = (event) => {
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }
    props.handleClose(open);
    setState({ ...state, right: open });
    setForm({
      orgId: id,
      workspaceId: _id,
      noOfSeats: 1,
      additionalDevice: [
        {
          id: 1,
          deviceType: "Video Camera",
          deviceName: "",
          img: "camera_icon.svg",
          isNameError: false,
          isTypeError: false,
        },
        {
          id: 2,
          deviceType: "Microphone",
          deviceName: "",
          img: "mic_icon.svg",
          isNameError: false,
          isTypeError: false,
        },
        {
          id: 3,
          deviceType: "Speaker",
          deviceName: "",
          img: "speaker_icon.svg",
          isNameError: false,
          isTypeError: false,
        },
      ],
    });
    setCount(4);
    setError({});
    removeUpdatedRommDetails();
    setCloseStatus(false);
  };
  const handleCancel = () => {
    setCloseStatus(false);
  };

  const toggleDrawer = (anchor, open) => (event) => {
    setCloseStatus(true);
  };

  useEffect(() => {
    setState({ ...state, ["right"]: open });
  }, [open]);

  const handleChange = (key, value, index, deviceType) => {
    // debugger;
    if (key === "additionalDevice") {
      setForm((preForm) => ({
        ...preForm,
        additionalDevice:
          deviceType === "NAME"
            ? preForm.additionalDevice.map((device, _index) =>
                index === _index ? { ...device, deviceName: value, isNameError: true } : { ...device }
              )
            : preForm.additionalDevice.map((device, _index) =>
                index === _index ? { ...device, deviceType: value, isTypeError: true } : { ...device }
              ),
      }));
    } else {
      setForm((preForm) => ({
        ...preForm,
        [key]: value,
      }));
    }
    setError((preError) => ({
      ...preError,
      [key]: "",
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let validateNewInput = {
      roomName:
        errorCodes["roomName"][fieldValidation({ ...errorCodes.roomNameObj, fieldval: form.roomName })],
      noOfSeats:
        errorCodes["noOfSeats"][fieldValidation({ ...errorCodes.noOfSeatsObj, fieldval: form.noOfSeats })],
    };
    form?.additionalDevice?.forEach((item, idx) => {
      <>
        {item.id === idx + 1 && item.id > 3
          ? (validateNewInput = {
              ...validateNewInput,
              [`additionalDeviceName-${idx}`]:
                errorCodes["additionalDeviceName"][
                  fieldValidation({
                    ...errorCodes.additionalDeviceNameObj,
                    fieldval: item.deviceName,
                  })
                ],
              [`additionalDeviceType-${idx}`]:
                errorCodes["additionalDeviceType"][
                  fieldValidation({
                    ...errorCodes.additionalDeviceTypeObj,
                    fieldval: item.deviceType,
                  })
                ],
            })
          : ""}
        ;
      </>;
    });

    if (Object.keys(validateNewInput).every((k) => validateNewInput[k] == "")) {
      setValidateLoader(true);
      if (updateworkspaceRoomDetail) {
        const updatedDetails = {
          roomId: form.roomId,
          updateRoomDetails: form,
        };
        updateWorkspaceRoomDetails(updatedDetails).then((response) => {
          if (response.status) {
            updateRoomDetail(response.roomDetails);
            setState({ right: false });
            toastFlashMessage("Room details updated succesfully", "success");
            setForm({
              orgId: id,
              workspaceId: _id,
              noOfSeats: 1,
              additionalDevice: [
                {
                  id: 1,
                  deviceType: "Video Camera",
                  deviceName: "",
                  img: "camera_icon.svg",
                  isNameError: false,
                  isTypeError: false,
                },
                {
                  id: 2,
                  deviceType: "Microphone",
                  deviceName: "",
                  img: "mic_icon.svg",
                  isNameError: false,
                  isTypeError: false,
                },
                {
                  id: 3,
                  deviceType: "Speaker",
                  deviceName: "",
                  img: "speaker_icon.svg",
                  isNameError: false,
                  isTypeError: false,
                },
              ],
            });
            setValidateLoader(false);
            removeUpdatedRommDetails();
          } else {
            toastFlashMessage("Something went wrong while updating room details", "error");
            setValidateLoader(false);
          }
        });
      } else {
        // const formData = {
        //   orgId: id,
        //   workspaceId: _id,
        //   noOfSeats: form.noOfSeats,
        //   roomName: form.roomName,
        //   additionalDevice: form.additionalDevice.map((ele) => {
        //     return { deviceType: ele.deviceType, deviceName: ele.deviceName };
        //   }),
        // };
        addWorkspaceRoomDetails(form).then((response) => {
          if (response.status) {
            addworkspaceRoomDetails(response.roomDetails);
            setState({ right: false });
            setValidateLoader(false);
            setForm({
              orgId: id,
              workspaceId: _id,
              noOfSeats: 1,
              additionalDevice: [
                {
                  id: 1,
                  deviceType: "Video Camera",
                  deviceName: "",
                  img: "camera_icon.svg",
                  isNameError: false,
                  isTypeError: false,
                },
                {
                  id: 2,
                  deviceType: "Microphone",
                  deviceName: "",
                  img: "mic_icon.svg",
                  isNameError: false,
                  isTypeError: false,
                },
                {
                  id: 3,
                  deviceType: "Speaker",
                  deviceName: "",
                  img: "speaker_icon.svg",
                  isNameError: false,
                  isTypeError: false,
                },
              ],
            });
            toastFlashMessage("Room details added succesfully", "success");
            removeUpdatedRommDetails();
            handleClose();
            getWorkSpaceDetail();
          } else {
            toastFlashMessage("Something went wrong while adding room details", "error");
            setValidateLoader(false);
          }
        });
      }
    } else {
      setError(validateNewInput);
    }
  };

  const handleAddDevices = () => {
    setCount((prev) => prev + 1);
    setForm({
      ...form,
      additionalDevice: [
        ...form.additionalDevice,
        ...[{ id: count, deviceType: "", deviceName: "", isNameError: false, isTypeError: false }],
      ],
    });
  };

  const handleRemoveDevices = (id) => {
    const updatedForm = form?.additionalDevice?.filter((device) => device.id !== id);
    setForm({
      ...form,
      additionalDevice: updatedForm,
    });
  };

  useEffect(() => {
    if (updateworkspaceRoomDetail) {
      setState({ right: true });
      setForm({
        orgId: updateworkspaceRoomDetail?.orgId,
        workspaceId: updateworkspaceRoomDetail?.orgLocId,
        roomName: updateworkspaceRoomDetail?.roomName,
        noOfSeats: updateworkspaceRoomDetail?.noOfSeats,
        additionalDevice: updateworkspaceRoomDetail?.additionalDevice?.map((roomDetails) => roomDetails),
        roomId: updateworkspaceRoomDetail?._id,
      });
    }
  }, [updateworkspaceRoomDetail]);

  const handleDeactivateRoom = (id) => {
    deactivateWorkspaceRoom(id);
    setState({ right: false });
    getWorkSpaceDetail();
  };

  useEffect(() => {
    function handleBrowserClose(e) {
      let confirmationMessage = "";
      (e || window.event).returnValue = confirmationMessage; //Gecko + IE
      return confirmationMessage; //Gecko + Webkit, Safari, Chrome etc.
    }
    window.addEventListener("beforeunload", handleBrowserClose);
    return () => {
      window.removeEventListener("beforeunload", handleBrowserClose);
    };
  });

  return (
    <div>
      {["right"].map((anchor) => (
        <div key={anchor}>
          <Drawer anchor={anchor} open={state[anchor]} onClose={toggleDrawer(anchor, false)}>
            <form onSubmit={handleSubmit}>
              <div className={styles.addRoomDrawer}>
                <div className={styles.drawerHeader}>
                  <img src={imageBasePath + "close_icon.svg"} onClick={toggleDrawer(anchor, false)} />
                  <h2 className={styles.drawerHeaderTitle + " subheading2 "}>
                    {updateworkspaceRoomDetail ? `Edit room details` : `Add room details`}
                  </h2>
                </div>
                <div className={styles.drawerBody}>
                  <div className={styles.addRoomTopBlk}>
                    {/* <div className={styles.workspaceWrapper}>
                    <Grid container>
                      <Grid item md={3}>
                        <p className={styles.workspaceText + " supportText "}>Workspace</p>
                      </Grid>
                      <Grid item md={3}>
                        <p className={styles.workspaceLocationText + " paragraph "}>Bangalore South</p>
                      </Grid>
                    </Grid>
                  </div> */}
                    {/* <div className={styles.deviceWrapper}>
                    <Grid container>
                      <Grid item md={3}>
                        <p className={styles.deviceText + " supportText "}>Device</p>
                      </Grid>
                      <Grid item md={3}>
                        <CustomLink
                          className="workspaceStatus notAssigned"
                          text="Not Assigned"
                          leftIcon={imageBasePath + "not_assigned_status_icon.svg"}
                        />
                      </Grid>
                    </Grid>
                  </div> */}
                    <div className={styles.roomNameInputWrapper}>
                      <Grid container spacing={2}>
                        <Grid item md={6}>
                          <InputText
                            type="text"
                            placeholder="Mercury"
                            label="Room Name *"
                            value={form.roomName}
                            onChange={(e) => handleChange("roomName", e.target.value)}
                            error={error.roomName}
                          />
                        </Grid>
                        <Grid item md={6}>
                          <label>Room Seating Capacity *</label>
                          <div className={styles.seatingCapacityInput}>
                            <div className="customIncrementInput">
                              <button
                                type="button"
                                className="decrementButton"
                                onClick={() => {
                                  form.noOfSeats > 1
                                    ? handleChange("noOfSeats", Number(form.noOfSeats - 1))
                                    : handleChange("noOfSeats", 1);
                                }}
                              >
                                <img src={imageBasePath + "minus-line.svg"} alt="minus" />
                              </button>

                              <input
                                className="paragraph"
                                type="number"
                                name="clicks"
                                value={form.noOfSeats}
                                // onChange={(e) => {
                                //   e.target.value < 1
                                //     ? handleChange("noOfSeats", 1)
                                //     : handleChange("noOfSeats", Number(e.target.value));
                                // }}
                                onChange={(e) => {
                                  handleChange("noOfSeats", parseInt(e.target.value));
                                }}
                                onWheel={(e) => e.target.blur()}
                              />
                              <button
                                type="button"
                                className="incrementButton"
                                onClick={() => {
                                  handleChange("noOfSeats", Number(form.noOfSeats + 1));
                                }}
                              >
                                <img src={imageBasePath + "add-line.svg"} alt="plus" />
                              </button>
                            </div>
                          </div>
                          {error.noOfSeats ? <h6 className="errorMsg">{error.noOfSeats}</h6> : null}
                        </Grid>
                      </Grid>
                    </div>
                  </div>

                  <div className={styles.additionalDevicesBlk}>
                    <Grid container spacing={2} justifyContent="space-between">
                      <Grid item md={6}>
                        <h4 className={styles.additionalDevicesTitle + " paragraph "}>Additional Devices</h4>
                      </Grid>
                      <Grid item md={6}>
                        <CustomLink
                          className={styles.addNewDeviceBtn}
                          text="Add New"
                          leftIcon={imageBasePath + "plus_icon.svg"}
                          onClick={handleAddDevices}
                        />
                      </Grid>
                    </Grid>
                    <div className={styles.additionalDevicesContent}>
                      {form?.additionalDevice?.map((item, index) => {
                        return (
                          <React.Fragment key={index}>
                            {index > 2 && (
                              <Link className={styles.removeBtn} onClick={() => handleRemoveDevices(item.id)}>
                                Remove
                              </Link>
                            )}
                            <Grid container spacing={2}>
                              <Grid item md={6}>
                                {index < 3 ? (
                                  <div className={styles.additionalDeviceName}>
                                    <img src={imageBasePath + item.img} />
                                    <p>
                                      {item.deviceType
                                        ? item.deviceType
                                        : item.img === "camera_icon.svg"
                                        ? "Video Camera"
                                        : item.img === "mic_icon.svg"
                                        ? "Microphone"
                                        : "Speaker"}
                                    </p>
                                  </div>
                                ) : (
                                  <div className={styles.additionalDeviceInput}>
                                    <InputText
                                      placeholder="Device Type"
                                      name={`deviceType`}
                                      value={item.deviceType}
                                      onChange={(e) =>
                                        handleChange("additionalDevice", e.target.value, index, "TYPE")
                                      }
                                      error={
                                        item?.isTypeError && item?.deviceType?.length != 0
                                          ? ""
                                          : error?.[`additionalDeviceType-${index}`]
                                      }
                                    />
                                  </div>
                                )}
                              </Grid>
                              <Grid item md={6}>
                                <div className={styles.additionalDeviceInput}>
                                  <InputText
                                    placeholder="Device Name"
                                    name={`deviceName`}
                                    value={item.deviceName}
                                    onChange={(e) =>
                                      handleChange("additionalDevice", e.target.value, index, "NAME")
                                    }
                                    error={
                                      item?.isNameError && item.deviceName?.length != 0
                                        ? ""
                                        : error?.[`additionalDeviceName-${index}`]
                                    }
                                  />
                                </div>
                              </Grid>
                            </Grid>
                          </React.Fragment>
                        );
                      })}
                    </div>
                  </div>
                </div>
                <div className={styles.drawerFooter}>
                  <Grid container spacing={2}>
                    {location.pathname !== `/${id}/devices/${_id}` && updateworkspaceRoomDetail && (
                      <Grid item md={6}>
                        <CustomButton
                          onClick={() => handleDeactivateRoom(updateworkspaceRoomDetail._id)}
                          children="Delete Room"
                          className={styles.deleteRoomBtn + " outlinedBtn dangerBtn"}
                        />
                      </Grid>
                    )}
                    <Grid item md={6}>
                      <CustomButton
                        type="submit"
                        className={`${styles.updateBtn} primaryBtn ${validateLoader ? "disabledBtn" : ""}`}
                        children={
                          updateworkspaceRoomDetail
                            ? validateLoader
                              ? "Updating..."
                              : "Update"
                            : validateLoader
                            ? "Adding..."
                            : "Add"
                        }
                      />
                    </Grid>
                  </Grid>
                </div>
              </div>
            </form>
          </Drawer>
        </div>
      ))}
      {closeStatus && (
        <LogOutDialog
          text="Are you sure you want to close? Changes that you made may not be saved."
          handleSuccess={handleSuccess}
          handleCancel={handleCancel}
        />
      )}
    </div>
  );
}
