import React from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { createTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Paper from "@material-ui/core/Paper";
import { Link } from "react-router-dom";

const ParticipantsListTheme = createTheme({
  palette: {
    secondary: {
      main: "#344552",
    },
  },
  typography: {
    fontFamily: ["Inter", "sans-serif"].join(","),
  },
});

const useStyles = makeStyles({
  root: {
    marginTop: "40px",
    maxWidth: "1024px",
    margin: "auto",
  },
  paperRoot: {
    margin:"10px",
    marginBottom: "40px",
    padding: "30px",
  },
  headingWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  img: {
    marginTop: "26px",
    marginRight: "10px",
  },
  title: {
    fontWeight: "bold",
    marginTop: "30px",
  },
  subtitle: {
    fontWeight: "bold",
    marginTop: "10px",
  },
  subtitleChild: {
    marginLeft: "20px",
  },
  icon: {
    minWidth: "30px",
  },
  listItem: {
    padding: "0px",
  },
});

function Webex() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <ThemeProvider theme={ParticipantsListTheme}>
        <Paper className={classes.paperRoot}>
          <div className={classes.headingWrapper}>
            <img
              src="../images/webex-logo.svg"
              alt="Profile"
              className={classes.img}
            />
            <Typography variant="h4" align="center" className={classes.title}>
              Webex Integration
            </Typography>
          </div>
          <div style={{ padding: "40px" }}>
            <Typography variant="h6" color="secondary">
              Webex meeting is a virtual conferencing software that allows you
              to virtually meet with other people. Webex integration for Ving
              allows seamless integration of Ving devices for Webex meetings.
            </Typography>
            <List style={{ marginLeft: "18px" }}>
              <ListItem className={classes.listItem}>
                <ListItemIcon className={classes.icon}>
                  <FiberManualRecordIcon fontSize="inherit" />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography variant="subtitle1" color="secondary">
                      View your scheduled meetings
                    </Typography>
                  }
                  color="secondary"
                />
              </ListItem>
              <ListItem className={classes.listItem}>
                <ListItemIcon className={classes.icon}>
                  <FiberManualRecordIcon fontSize="inherit" />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography variant="subtitle1" color="secondary">
                      Join Webex calls in the device remotely
                    </Typography>
                  }
                />
              </ListItem>
              <ListItem className={classes.listItem}>
                <ListItemIcon className={classes.icon}>
                  <FiberManualRecordIcon fontSize="inherit" />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography variant="subtitle1" color="secondary">
                      Instantly share content in the device using adhoc meetings
                      that are created automatically
                    </Typography>
                  }
                />
              </ListItem>
            </List>

            <Typography
              variant="h6"
              color="secondary"
              className={classes.subtitle}
              gutterBottom
            >
              How to add Webex Meetings to Ving
            </Typography>
            <div
              className={classes.subtitleChild}
              style={{ marginTop: "10px" }}
            >
              <Typography variant="subtitle1" color="secondary" gutterBottom>
                1. If you don't have a Ving device or subscription, then please
                visit{" "}
                <strong>
                  <a
                    href="https://letsving.com/"
                    style={{ color: "#4361ee" }}
                    target="_blank"
                    rel="noreferrer"
                  >
                    letsving.com
                  </a>
                </strong>{" "}
                to order a device and subscription.
              </Typography>
              <Typography variant="subtitle1" color="secondary" gutterBottom>
                2. Once the device is received, then set it up using the
                instructions provided with the device.
              </Typography>
              <Typography variant="subtitle1" color="secondary" gutterBottom>
                3. Go to{" "}
                <strong>
                  <a
                    href="https://meet.letsving.com/"
                    style={{ color: "#4361ee" }}
                    target="_blank"
                    rel="noreferrer"
                  >
                    meet.letsving.com
                  </a>
                </strong>{" "}
                and sign in with your credentials or use social login.
              </Typography>
              <Typography variant="subtitle1" color="secondary" gutterBottom>
                4. To link a Webex account to Ving account, please go to{" "}
                <strong> Settings =&#62; Providers</strong> under user name
                section in the top navigation bar.
              </Typography>
              <Typography variant="subtitle1" color="secondary" gutterBottom>
                5. To connect to the prescheduled meeting on the device follow
                the below steps,
              </Typography>
              <div className={classes.subtitleChild}>
                <Typography variant="subtitle1" color="secondary" gutterBottom>
                  a. Click on Meet Now in the home page
                </Typography>
                <Typography variant="subtitle1" color="secondary" gutterBottom>
                  b. Enter the room id from device and select Next
                </Typography>
                <Typography variant="subtitle1" color="secondary" gutterBottom>
                  c. Select Webex and add your meeting url and click next.
                </Typography>
                <Typography variant="subtitle1" color="secondary" gutterBottom>
                  d. The meeting will start. You can control the device volume,
                  camera and microphone from the application and also share
                  content from your PC.
                </Typography>
                <Typography variant="subtitle1" color="secondary" gutterBottom>
                  e. You can end the meeting using "Leave Meeting" option.
                </Typography>
              </div>
              <Typography variant="subtitle1" color="secondary" gutterBottom>
                6. To connect to a adhoc meeting on the device with Webex, you
                should select{" "}
                <strong> "Set as Default for Share Screen" </strong> option for
                Webex in the Settings screen. If Webex is the only account
                linked, then it is not required. To join an adhoc meeting,
              </Typography>

              <div className={classes.subtitleChild}>
                <Typography variant="subtitle1" color="secondary" gutterBottom>
                  a. Click on Share Screen in the home page
                </Typography>
                <Typography variant="subtitle1" color="secondary" gutterBottom>
                  b. Enter the room id from device and select Next
                </Typography>
                <Typography variant="subtitle1" color="secondary" gutterBottom>
                  c. Ving will automatically will create an adhoc meeting and
                  join the meeting in the Ving application and the device.
                </Typography>
                <Typography variant="subtitle1" color="secondary" gutterBottom>
                  d. Similar to Prescheduled meetings, you can control the
                  device accessories and share content from PC.
                </Typography>
              </div>
            </div>
          </div>
          <div
            style={{
              padding: "40px",
              paddingTop:"0px"
            }}
          >
            <Typography variant="subtitle1" color="secondary" gutterBottom>
              Please
              <Link
                to="/support"
                style={{ color: "#4361ee", fontWeight: "bold" }}
              >
                {" "}
                contact us
              </Link>{" "}
              if you need any support.
            </Typography>
          </div>
        </Paper>
      </ThemeProvider>
    </div>
  );
}

export default Webex;
