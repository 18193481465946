import {
    globalPostService,
    globalGetService,
    globalDeleteService,
    globalGetServiceNoAuth
  } from "../../utils/globalApiServices";
  

  export function registerpartnerApi(query = {}) {
    return new Promise((resolve, reject) => {
      globalPostService(`partner-signup-v1`, query)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
  

  


  
  

  