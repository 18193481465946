import React from "react";
import { Grid, List, ListItem } from "@material-ui/core";
import styles from "../../../assets/styles/team.module.scss";
import { Skeleton } from "@material-ui/lab";
import { imageBasePath } from "../../../constants";

export default function Shimmer(props) {
  return (
    <>
      {props.type === "teamsHeader" && (
        <div className={styles.teamListTableTitle}>
          <Grid container spacing={2}>
            <Grid item md={12}>
              <Grid container direction="row" spacing={2} justifyContent="flex-start" alignItems="center">
                <Grid item md={4}>
                  <div className={styles.teamTableTitleTextWrapper}>
                    <p className={styles.teamTableTitleText + " paragraph "}>Name</p>
                  </div>
                </Grid>
                <Grid item md={3}>
                  <div className={styles.teamTableTitleTextWrapper}>
                    <p className={styles.teamTableTitleText + " paragraph "}>Last Active</p>
                  </div>
                </Grid>
                <Grid item md={3}>
                  <div className={styles.teamTableTitleTextWrapper}>
                    <p className={styles.teamTableTitleText + " paragraph "}>Permissions</p>
                  </div>
                </Grid>
                <Grid item md={2}>
                  <div className={styles.teamTableTitleTextWrapper}>
                    <p className={styles.teamTableTitleText + " paragraph "}>Joined On</p>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      )}
      {props.type == "TeamLists" && (
        <>
          {[0, 1, 2, 3, 4].map((item, key) => (
            <React.Fragment key={key}>
              <div className={styles.teamListItem}>
                <div className={styles.teamListTableRow}>
                  <Grid container direction="row" spacing={2} justifyContent="flex-start" alignItems="center">
                    <Grid item md={4}>
                      <div className={styles.teamContentWrapper}>
                        <div className={styles.teamImageWrapper}>
                          <Skeleton
                            variant="circle"
                            width={40}
                            height={40}
                            style={{
                              display: "block",
                              verticalAlign: "middle",
                              transform: "none",
                              marginTop: 10,
                              marginLeft: 5,
                            }}
                          />
                        </div>
                        <div className={styles.teamDetailWrapper}>
                          <div className={styles.teamName + " paragraphSemiBold "}>
                            <Skeleton
                              variant="text"
                              width={200}
                              height={25}
                              style={{
                                display: "block",
                                verticalAlign: "middle",
                                transform: "none",
                                marginTop: 0,
                              }}
                            />
                          </div>
                          <div className={styles.teamEmail + " paragraphSemiBold "}>
                            <Skeleton
                              variant="text"
                              width={200}
                              height={25}
                              style={{
                                display: "block",
                                verticalAlign: "middle",
                                transform: "none",
                                marginTop: 10,
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </Grid>

                    <Grid item md={3}>
                      <div className={styles.teamContentWrapper}>
                        <div className={styles.lastActive + " paragraph "}>
                          <Skeleton
                            variant="text"
                            width={100}
                            height={25}
                            style={{
                              display: "block",
                              verticalAlign: "middle",
                              transform: "none",
                              marginTop: 0,
                            }}
                          />
                        </div>
                      </div>
                    </Grid>

                    <Grid item md={3}>
                      <div className={styles.teamContentWrapper}>
                        <div className={styles.permission + " paragraph "}>
                          <Skeleton
                            variant="text"
                            width={150}
                            height={25}
                            style={{
                              display: "block",
                              verticalAlign: "middle",
                              transform: "none",
                              marginTop: 0,
                            }}
                          />
                        </div>
                      </div>
                    </Grid>

                    <Grid item md={2}>
                      <div className={styles.teamContentWrapper}>
                        <div className={styles.joinedOn + " paragraph "}>
                          <Skeleton
                            variant="text"
                            width={100}
                            height={25}
                            style={{
                              display: "block",
                              verticalAlign: "middle",
                              transform: "none",
                              marginTop: 0,
                            }}
                          />
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </React.Fragment>
          ))}
        </>
      )}
    </>
  );
}
