import React, { useState, useEffect } from "react";
import styles from "../../../assets/styles/devices.module.scss";
import { CustomButton, CustomLink } from "../../../shared_ui_components";
import closeIcon from "../../../assets/images/close-square.svg";
import { imageBasePath } from "../../../constants";
import { Grid, TextField } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import Shimmer from "./Shimmer";
import DeviceSettings from "./DeviceSettings";
import WorkspaceDetails from "./WorkspaceDetails";
import RoomDetails from "./RoomDetails";
import {
  createDeployment,
  deviceDetailById,
  getReleases,
} from "../apiServices";
import { useParams } from "react-router";
import moment from "moment";
import { Autocomplete } from "@material-ui/lab";
import { toastFlashMessage } from "../../../utils";

export default function DeviceDetails(props) {
  let history = useHistory();
  const { id, _id } = useParams();
  const [deviceDetails, setDeviceDetails] = useState({});
  const [loader, setLoader] = useState(true);
  const [showPopup, setShowPopup] = useState(false);
  const [releases, setReleases] = useState("");
  const [releaseId, setReleaseId] = useState("");

  useEffect(() => {
    getReleases().then((response) => {
      if (response.status) {
        setReleases(response.releases);
      }
    });
  }, []);

  const handleDeviceDetail = () => {
    deviceDetailById({ deviceId: _id }).then((response) => {
      if (response.status & (Object.keys(response.data).length > 0)) {
        setDeviceDetails(response.data);
        console.log(response.data);
        setLoader(false);
      } else {
        setLoader(false);
      }
    });
  };

  useEffect(() => {
    handleDeviceDetail();
  }, [_id]);

  const handleGoBack = () => {
    history.goBack();
  };
  function deploy(deviceId, workSpaceId) {
    if (releaseId && deviceId) {
      createDeployment({
        releaseId: releaseId._id,
        deviceId,
        workSpaceId,
        orgId:id,
        deployType: "device",
      }).then((resp) => {
        if (resp.status) {
          console.log(resp);
          setShowPopup(false);
          toastFlashMessage(`Deploy created successfully`, "success");
        } else {
          toastFlashMessage(
            `Something went wrong while creating the deploy`,
            "error"
          );
        }
      });
    }
  }
  const labelStyle = {
    fontFamily: '"Inter", sans-serif',
    fontSize: "18px",
    fontWeight: 500,
    color: "black",
    lineHeight: "21px",
    marginBottom: "3px",
  };

  function compareVersions(versionA, versionB) {
    const partsA = versionA.split(/[.-]/);
    const partsB = versionB.split(/[.-]/);
  
    for (let i = 0; i < Math.max(partsA.length, partsB.length); i++) {
      const partA = parseInt(partsA[i]) || 0;
      const partB = parseInt(partsB[i]) || 0;
  
      if (partA > partB) return -1;
      if (partA < partB) return 1;
    }
  
    return 0;
  }
  const sortedReleases=releases?releases.sort((a, b) => compareVersions(a.version, b.version)):"";

  return (
    <>
      {loader ? (
        <Shimmer type="deviceDetails" />
      ) : (
        <>
          <div className={styles.deviceDetailsWrapper}>
            <div className={styles.backBtnWrapper}>
              <CustomLink
                className="backBtn"
                text="Back"
                leftIcon={imageBasePath + "arrow_left_icon.svg"}
                onClick={handleGoBack}
              />
            </div>
            <div className={styles.deviceDetailsBlk}>
              <Grid container>
                <Grid item md={12}>
                  <Grid container>
                    <Grid item md={7}>
                      <div className={styles.deviceDetails}>
                        <div className={styles.deviceDetailsOuterBlk}>
                          <div className={styles.deviceDetailsImg}>
                            <img
                              src={imageBasePath + "vingbox_icon.svg"}
                              class={styles.deviceImg}
                            />
                            <div className={styles.deviceStatus}>
                              <img
                                src={
                                  imageBasePath +
                                  `${
                                    deviceDetails.deviceStatus === "Active"
                                      ? "quotation_success.svg"
                                      : "device_deactivated.svg"
                                  }`
                                }
                                alt=""
                              />
                            </div>
                          </div>
                          {/* <Link className={styles.viewDeviceLog + " paragraph " + " linkBtn "} >
                      View Device Log
                    </Link> */}
                          <div className={styles.deviceDetailsInnerBlk}>
                            <p className={styles.deviceName + " subheading "}>
                              {deviceDetails?.deviceName}
                            </p>
                            <div className={styles.deviceDetailsInnerRow}>
                              <Grid container spacing={2}>
                                <Grid item md={4}>
                                  <div className={styles.deviceDetailsLeftBlk}>
                                    <p
                                      className={
                                        styles.deviceDetailsLeftText +
                                        " supportText "
                                      }
                                    >
                                      MAC Id{" "}
                                    </p>
                                  </div>
                                </Grid>
                                <Grid item md={8}>
                                  <div className={styles.deviceDetailsRightBlk}>
                                    <p
                                      className={
                                        styles.deviceDetailsMacID +
                                        " paragraph "
                                      }
                                    >
                                      {deviceDetails?.macId
                                        ? deviceDetails?.macId
                                        : "NA"}
                                    </p>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={styles.deviceDetailsInnerRow}>
                              <Grid container spacing={2}>
                                <Grid item md={4}>
                                  <div className={styles.deviceDetailsLeftBlk}>
                                    <p
                                      className={
                                        styles.deviceDetailsRoomNameText +
                                        " supportText "
                                      }
                                    >
                                      Room name
                                    </p>
                                  </div>
                                </Grid>
                                <Grid item md={8}>
                                  <div className={styles.deviceDetailsRightBlk}>
                                    <div
                                      className={styles.deviceDetailsSelectRoom}
                                    >
                                      {/* {deviceDetails?.roomId?.roomName ? (
                                  deviceDetails?.roomId?.roomName
                                ) : (
                                  <InputSelect
                                    closeMenuOnSelect={true}
                                    isSearchable={false}
                                    options={selectRoom}
                                    placeholder={"Select room"}
                                    noOptionsMessage={() => "No matches found"}
                                    // menuIsOpen={true}
                                    // value={productInfo.portfolio}
                                    // isCreatable={true}
                                    // isClearable/>
                                  />
                                )} */}
                                      {deviceDetails?.roomId?.roomName}
                                    </div>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={styles.deviceDetailsInnerRow}>
                              <Grid container spacing={2}>
                                <Grid item md={4}>
                                  <div className={styles.deviceDetailsLeftBlk}>
                                    <p
                                      className={
                                        styles.deviceDetailsRoomNameText +
                                        " supportText "
                                      }
                                    >
                                      Room Id
                                    </p>
                                  </div>
                                </Grid>
                                <Grid item md={8}>
                                  <div className={styles.deviceDetailsRightBlk}>
                                    <div
                                      className={styles.deviceDetailsSelectRoom}
                                    >
                                      {deviceDetails?.roomId?.roomShortCode}-
                                      {deviceDetails?.deviceDynamicCode}
                                    </div>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={styles.deviceDetailsInnerRow}>
                              <Grid container spacing={2}>
                                <Grid item md={4}>
                                  <div className={styles.deviceDetailsLeftBlk}>
                                    <p
                                      className={
                                        styles.deviceDetailsLeftText +
                                        " supportText "
                                      }
                                    >
                                      Status
                                    </p>
                                  </div>
                                </Grid>
                                <Grid item md={8}>
                                  <div className={styles.deviceDetailsRightBlk}>
                                    <p
                                      className={`${
                                        deviceDetails.deviceStatus === "Active"
                                          ? styles.deviceStatusSuccess
                                          : styles.deviceStatusDeactivate
                                      }   paragraph `}
                                    >
                                      <img
                                        src={
                                          imageBasePath +
                                          `${
                                            deviceDetails.deviceStatus ===
                                            "Active"
                                              ? "status_active_icon.svg"
                                              : "device_deactivated.svg"
                                          }`
                                        }
                                      />
                                      {deviceDetails?.deviceStatus}
                                    </p>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={styles.deviceDetailsInnerRow}>
                              <Grid container spacing={2}>
                                <Grid item md={4}>
                                  <div className={styles.deviceDetailsLeftBlk}>
                                    <p
                                      className={
                                        styles.deviceDetailsLeftText +
                                        " supportText "
                                      }
                                    >
                                      Subscription Period
                                    </p>
                                  </div>
                                </Grid>
                                <Grid item md={8}>
                                  <div className={styles.deviceDetailsRightBlk}>
                                    <p
                                      className={
                                        styles.deviceDetailsMacID +
                                        " paragraph "
                                      }
                                    >
                                      <span>
                                        {deviceDetails?.activeSubscriptionStartDate
                                          ? moment(
                                              deviceDetails?.activeSubscriptionStartDate,
                                              "DD-MM-YYYY"
                                            ).format("DD/MM/YYYY")
                                          : "N/A"}
                                      </span>{" "}
                                      -{" "}
                                      <span>
                                        {deviceDetails?.activeSubscriptionStartDate
                                          ? moment(
                                              deviceDetails?.activeSubscriptionEndtDate,
                                              "DD-MM-YYYY"
                                            ).format("DD/MM/YYYY")
                                          : "N/A"}
                                      </span>
                                    </p>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={styles.deviceDetailsInnerRow}>
                              <Grid container spacing={2}>
                                <Grid item md={4}>
                                  <div className={styles.deviceDetailsLeftBlk}>
                                    <p
                                      className={
                                        styles.deviceDetailsLeftText +
                                        " supportText "
                                      }
                                    >
                                      Firmware Version
                                    </p>
                                  </div>
                                </Grid>
                                <Grid item md={8}>
                                  <div
                                    style={{
                                      display: "flex",
                                      gap: "20px",
                                      alignItems: "center",
                                    }}
                                  >
                                    <div
                                      className={styles.deviceDetailsRightBlk}
                                    >
                                      <p
                                        className={
                                          styles.deviceDetailsFirmwareVersion +
                                          " paragraph "
                                        }
                                      >
                                        Ver{" "}
                                        <span>
                                          {deviceDetails?.firmwareVersion}
                                        </span>
                                      </p>
                                    </div>
                                    <div>
                                      <button
                                        className={`primaryBtn`}
                                        onClick={() => {
                                          setShowPopup(true);
                                        }}
                                      >
                                        Deploy
                                      </button>
                                      {/*<CustomButton className={styles.changeWallpaperBtn + " outlinedBtn " } children="Deploy" /> */}
                                      <div
                                        id="myModal"
                                        style={{
                                          display: `${
                                            showPopup ? "block" : ""
                                          }`,
                                        }}
                                        className={styles.modal}
                                      >
                                        <div className={styles.modalContent}>
                                          <img
                                            className={styles.close}
                                            onClick={() => setShowPopup(false)}
                                            src={closeIcon}
                                          />

                                          <div style={labelStyle}>
                                            Select Release Version
                                          </div>
                                          {/* <div style={{display:"flex",gap:"40px"}}> */}

                                          <Autocomplete
                                            id="autocomplete"
                                            options={sortedReleases}
                                            getOptionLabel={(option) =>
                                              String(option.version)
                                            }
                                            onChange={(e, newValue) =>
                                              setReleaseId(newValue || "")
                                            }
                                            value={releaseId.version}
                                            style={{ margin: "20px 0px" }}
                                            renderInput={(params) => (
                                              <TextField
                                                {...params}
                                                label=""
                                                variant="outlined"
                                              />
                                            )}
                                          />
                                          <button
                                            className={`primaryBtn`}
                                            onClick={() => {
                                              deploy(deviceDetails._id, deviceDetails.roomId.orgLocId);
                                            }}
                                          >
                                            Deploy
                                          </button>
                                          {/* </div> */}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                          </div>
                        </div>
                        <div className={styles.deviceSettings}>
                          <DeviceSettings
                            deviceDetails={deviceDetails}
                            handleDeviceDetail={handleDeviceDetail}
                          />
                        </div>
                      </div>
                    </Grid>
                    <Grid item md={5}>
                      <div className={styles.deviceWorkspaceWrapper}>
                        <WorkspaceDetails deviceDetails={deviceDetails} />
                      </div>
                      <div className={styles.deviceRoomDetailsWrapper}>
                        <RoomDetails deviceDetails={deviceDetails} />
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </div>
        </>
      )}
    </>
  );
}
