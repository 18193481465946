import React from 'react';
import styles from "../../../../assets/styles/admin/partner.module.scss";
import PartnerList from '../components/PartnerList';

export default function Partners(props) {
    return (
        <div className={styles.partnerPage}>
            <div className={styles.partnerListWrapper}>
                <PartnerList />
            </div>
        </div>
    )
}
