import React, { useState } from "react";
import styles from "../../../assets/styles/auth.module.scss";
import { errorCodes } from "../../../constants";
import { InputText, CustomButton, CustomLink } from "../../../shared_ui_components";
import { fieldValidation } from "../../../utils/formValidation";
import { forgetPasswordApi } from "../ApiService";
import { toastFlashMessage } from "../../../utils";

export default function PasswordReset(props) {
  const [formData, setFormData] = useState({
    host: window.location.origin,
  });
  const [error, setError] = useState({});
  const [validateLoader, setValidateLoader] = useState(false);

  const handleChange = (key, value) => {
    setFormData((formData) => ({
      ...formData,
      [key]: value,
    }));
    setError((preError) => ({
      ...preError,
      [key]: "",
    }));
  };

  const handleSubmit = () => {
    let validateNewInput = {};
    validateNewInput = {
      email: errorCodes["email"][fieldValidation({ ...errorCodes.emailObj, fieldval: formData.email })],
    };
    if (Object.keys(validateNewInput).every((k) => validateNewInput[k] == "")) {
      setValidateLoader(true);
      forgetPasswordApi(formData).then((response) => {
        if (response.status) {
          setValidateLoader(false);
          props.handleChangeStatus(formData.email);
        } else {
          setValidateLoader(false);
          toastFlashMessage("Something went wrong while sending email", "error");
        }
      });
    } else {
      setError(validateNewInput);
    }
  };
  return (
    <div className={styles.forgotPasswordFormWrapper}>
      <InputText
        label="Email ID *"
        placeholder="eg,  johndoe@email.com"
        value={formData.email || ""}
        onChange={(e) => handleChange("email", e.target.value.trim())}
        error={error.email}
        onKeyPress={(e) => {
          if (e.key === "Enter") {
            handleSubmit();
          }
        }}
      />
      <CustomButton
        className={`${styles.signInButton} primaryBtn ${validateLoader ? "disabledBtn" : ""}`}
        children={validateLoader ? "Sending..." : "Send"}
        onClick={handleSubmit}
      />
      <div className={styles.backButtonWrapper + " paragraph"}>
        <p>back to</p>
        <CustomLink className={styles.backButtonLink + " backBtn"} text="Sign In" to="/signin" />
      </div>
    </div>
  );
}
