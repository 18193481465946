import { Route, Redirect, useLocation } from "react-router-dom";
import queryString from "query-string";
import { enableWebsiteContent } from "../constants";

export default function PrivateRoute({ component: Component, path: path, ...rest }) {
  let location = useLocation();

  const isUserLoggedIn = () => {
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    if (userInfo && userInfo.accessToken) {
      //debugger
      return true;
    } else {
      const querys = queryString.parse(location.search);
      const redirection = querys.redirection;
      if (redirection) {
        localStorage.setItem("redirection", location.pathname);
      }
      return false;
    }
  };

  return (
    <Route
      {...rest}
      render={(props) =>
        isUserLoggedIn() ? (
          <Component role="ADMIN" {...props} />
        ) : (
          <Redirect
            to={{
              pathname: enableWebsiteContent ? "/" : "/signin",
            }}
          />
        )
      }
    />
  );
}
