import { useState, useEffect } from "react";
import { Grid, TextField } from "@material-ui/core";
import styles from "../../../assets/styles/workspaces.module.scss";
import { CustomLink, InputText, NoResult } from "../../../shared_ui_components";
import { imageBasePath } from "../../../constants";
import { getUserInfo, toastFlashMessage } from "../../../utils";
import { WorkspaceRoomListItem, AddRoomDrawer } from "../components";
import Shimmer from "./Shimmer";
import { sortObj } from "../../../utils";
import { Autocomplete } from "@material-ui/lab";
import { createDeployment, getReleases } from "../../devices/apiServices";
import closeIcon from "../../../assets/images/close-square.svg";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

function WorkspaceRoomList(props) {
  const {
    workspaceRoomDetails,
    addworkspaceRoomDetails,
    editWorkspaceRoom,
    updateworkspaceRoomDetail,
    updateRoomDetail,
    deactivateWorkspaceRoom,
    removeUpdatedRommDetails,
    sortRoomDetails,
    getWorkSpaceDetail,
  } = props;
  const { id, _id } = useParams();

  console.log(id, _id);

  const userInfo = getUserInfo();

  const [openAddRoom, setOpenAddRoom] = useState(false);
  const [roomLoader, setRoomLoader] = useState(true);
  const [showPopup, setShowPopup] = useState(false);
  const [releases, setReleases] = useState("");
  const [releaseId, setReleaseId] = useState("");
  const [search, setSearch] = useState({
    val: "",
    list: [],
  });
  const [sort, setSort] = useState({
    field: "",
    asc: false,
    defaultSortUpImg: "",
    defaultSortDownImg: "",
  });

  useEffect(() => {
    if (workspaceRoomDetails) {
      setTimeout(() => {
        setRoomLoader(false);
      }, 2000);
    }
    getReleases().then((response) => {
      if (response.status) {
        setReleases(response.releases);
      }
    });
  }, []);

  const handleSearch = (val) => {
    setSearch((prevSearch) => ({
      val: val.toLowerCase(),
      list: workspaceRoomDetails.filter((room) =>
        room.roomName?.toLowerCase().includes(val.toLowerCase())
      ),
    }));
  };

  const handleSort = (sortObject) => {
    let list = sortObj(
      sortObject.field,
      workspaceRoomDetails,
      sortObject.asc,
      sortObject.caseSensitive
    );
    setSort({
      field: sortObject.field,
      asc: sortObject.asc,
      defaultSortUpImg: sortObject.asc
        ? imageBasePath + `asc_arrow.svg`
        : imageBasePath + "up_arrow.svg",
      defaultSortDownImg: sortObject.asc
        ? imageBasePath + "down_arrow.svg"
        : imageBasePath + `des_arrow.svg`,
    });
    sortRoomDetails(list);
  };

  function deploy() {
    if (releaseId) {
      createDeployment({
        releaseId: releaseId._id,
        workSpaceId: _id,
        orgId:id,
        deployType: "workspace",
      }).then((resp) => {
        if (resp.status) {
          console.log(resp);
          setShowPopup(false);
          toastFlashMessage(`Deploy created successfully`, "success");
        } else {
          toastFlashMessage(
            `Something went wrong while creating the deploy`,
            "error"
          );
        }
      });
    }
  }
  const labelStyle = {
    fontFamily: '"Inter", sans-serif',
    fontSize: "18px",
    fontWeight: 500,
    color: "black",
    lineHeight: "21px",
    marginBottom: "3px",
  };

  function compareVersions(versionA, versionB) {
    const partsA = versionA.split(/[.-]/);
    const partsB = versionB.split(/[.-]/);
  
    for (let i = 0; i < Math.max(partsA.length, partsB.length); i++) {
      const partA = parseInt(partsA[i]) || 0;
      const partB = parseInt(partsB[i]) || 0;
  
      if (partA > partB) return -1;
      if (partA < partB) return 1;
    }
  
    return 0;
  }
  const sortedReleases=releases?releases.sort((a, b) => compareVersions(a.version, b.version)):"";

  return (
    <div className={styles.workspaceRoomListBlk}>
      <>
        {roomLoader ? (
          <Shimmer type="workspaceRoomSearch" />
        ) : (
          <Grid container justifyContent="flex-end">
            <Grid item md={6}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "end",
                  flexDirection: "row-reverse",
                }}
              >
                <div style={{ float: "right", marginLeft: "14px" }}>
                  <button
                    className={`primaryBtn`}
                    onClick={() => {
                      setShowPopup(true);
                    }}
                  >
                    Deploy
                  </button>
                  <div
                    id="myModal"
                    style={{ display: `${showPopup ? "block" : ""}` }}
                    className={styles.modal}
                  >
                    <div className={styles.modalContent}>
                      <img
                        className={styles.close}
                        onClick={() => setShowPopup(false)}
                        src={closeIcon}
                      />

                      <div style={labelStyle}>Select Release Version</div>
                      {/* <div style={{display:"flex",gap:"40px"}}> */}

                      <Autocomplete
                        id="autocomplete"
                        options={sortedReleases}
                        getOptionLabel={(option) => String(option.version)}
                        onChange={(e, newValue) => setReleaseId(newValue || "")}
                        value={releaseId.version}
                        style={{ margin: "20px 0px" }}
                        renderInput={(params) => (
                          <TextField {...params} label="" variant="outlined" />
                        )}
                      />
                      <button className={`primaryBtn`} onClick={deploy}>
                        Deploy
                      </button>
                      {/* </div> */}
                    </div>
                  </div>
                </div>

                {userInfo.userData?.role?.responsibilities?.createRoom ? (
                  <div className={styles.WorkspaceAddNewRoomBtnWrapper}>
                    <CustomLink
                      onClick={() => setOpenAddRoom(!openAddRoom)}
                      text="Add New Room"
                      leftIcon={imageBasePath + "plus_icon.svg"}
                      className={styles.addNewRoomBtn + " outlinedBtn "}
                    />
                  </div>
                ) : null}
                <div className={styles.workspaceRoomSearchBlk}>
                  <div className={styles.workspaceRoomSearchInput}>
                    <InputText
                      placeholder="Search here"
                      onChange={(e) => handleSearch(e.target.value)}
                    />
                    <img src={imageBasePath + "search_icon.svg"} />
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
        )}
        <Grid container>
          <Grid item md={12}>
            <div className={styles.workspaceRoomListItemWrapper}>
              <div className={styles.workspaceRoomListItemBlk}>
                {roomLoader ? (
                  <Shimmer type="workspaceRoomHeader" />
                ) : (
                  <div className={styles.workspaceRoomListTitleRow}>
                    <Grid container>
                      <Grid item md={7}>
                        <Grid container spacing={2}>
                          <Grid item md={4}>
                            <div
                              className={
                                styles.workspaceRoomTableTitleTextWrapper
                              }
                            >
                              <p
                                className={
                                  styles.workspaceRoomTableTitleText +
                                  " supportText "
                                }
                              >
                                Room Name
                              </p>
                              <img
                                src={
                                  sort.field === "roomName"
                                    ? sort.defaultSortUpImg
                                    : imageBasePath + "up_arrow.svg"
                                }
                                alt=""
                                className={styles.sortUp}
                                onClick={() =>
                                  handleSort({
                                    field: "roomName",
                                    asc: !sort.asc,
                                    caseSensitive: true,
                                  })
                                }
                              />
                              <img
                                src={
                                  sort.field === "roomName"
                                    ? sort.defaultSortDownImg
                                    : imageBasePath + "down_arrow.svg"
                                }
                                alt=""
                                className={styles.sortDown}
                                onClick={() =>
                                  handleSort({
                                    field: "roomName",
                                    asc: !sort.asc,
                                    caseSensitive: true,
                                  })
                                }
                              />
                            </div>
                          </Grid>
                          <Grid item md={4}>
                            <div
                              className={
                                styles.workspaceRoomTableTitleTextWrapper
                              }
                            >
                              <p
                                className={
                                  styles.workspaceRoomTableTitleText +
                                  " supportText "
                                }
                              >
                                Seating Capacity
                              </p>
                              <img
                                src={
                                  sort.field === "noOfSeats"
                                    ? sort.defaultSortUpImg
                                    : imageBasePath + "up_arrow.svg"
                                }
                                alt=""
                                className={styles.sortUp}
                                onClick={() =>
                                  handleSort({
                                    field: "noOfSeats",
                                    asc: !sort.asc,
                                    caseSensitive: false,
                                  })
                                }
                              />
                              <img
                                src={
                                  sort.field === "noOfSeats"
                                    ? sort.defaultSortDownImg
                                    : imageBasePath + "down_arrow.svg"
                                }
                                alt=""
                                className={styles.sortDown}
                                onClick={() =>
                                  handleSort({
                                    field: "noOfSeats",
                                    asc: !sort.asc,
                                    caseSensitive: false,
                                  })
                                }
                              />
                            </div>
                          </Grid>
                          <Grid item md={4}>
                            <div
                              className={
                                styles.workspaceRoomTableTitleTextWrapper
                              }
                            >
                              <p
                                className={
                                  styles.workspaceRoomTableTitleText +
                                  " supportText "
                                }
                              >
                                Device
                              </p>
                              <img
                                src={
                                  sort.field === "device"
                                    ? sort.defaultSortUpImg
                                    : imageBasePath + "up_arrow.svg"
                                }
                                alt=""
                                className={styles.sortUp}
                                // onClick={() =>
                                //   handleSort({
                                //     field: "additionalDevice",
                                //     asc: !sort.asc,
                                //     caseSensitive: true,
                                //   })
                                // }
                              />
                              <img
                                src={
                                  sort.field === "device"
                                    ? sort.defaultSortDownImg
                                    : imageBasePath + "down_arrow.svg"
                                }
                                alt=""
                                className={styles.sortDown}
                                // onClick={() =>
                                //   handleSort({
                                //     field: "additionalDevice",
                                //     asc: !sort.asc,
                                //     caseSensitive: true,
                                //   })
                                // }
                              />
                            </div>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item md={5}>
                        <Grid container spacing={2}>
                          <Grid item md={12}>
                            <div
                              className={
                                styles.workspaceRoomTableTitleTextWrapper
                              }
                            >
                              <p
                                className={
                                  styles.workspaceRoomTableTitleText +
                                  " supportText "
                                }
                              >
                                Additional Devices
                              </p>
                              <img
                                src={
                                  sort.field === "additionalDevice"
                                    ? sort.defaultSortUpImg
                                    : imageBasePath + "up_arrow.svg"
                                }
                                alt=""
                                className={styles.sortUp}
                                onClick={() =>
                                  handleSort({
                                    field: "additionalDevice",
                                    asc: !sort.asc,
                                    caseSensitive: true,
                                  })
                                }
                              />
                              <img
                                src={
                                  sort.field === "additionalDevice"
                                    ? sort.defaultSortDownImg
                                    : imageBasePath + "down_arrow.svg"
                                }
                                alt=""
                                className={styles.sortDown}
                                onClick={() =>
                                  handleSort({
                                    field: "additionalDevice",
                                    asc: !sort.asc,
                                    caseSensitive: true,
                                  })
                                }
                              />
                            </div>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </div>
                )}
                {roomLoader ? (
                  <Shimmer type="workspaceRoom" />
                ) : search.val ? (
                  <>
                    {search.list.length === 0 ? (
                      <NoResult
                        image="no_workspace.png"
                        title="No search result found"
                      />
                    ) : (
                      search.list.map((roomDetails, idx) => (
                        <div key={idx}>
                          <WorkspaceRoomListItem
                            {...roomDetails}
                            editWorkspaceRoom={editWorkspaceRoom}
                            deactivateWorkspaceRoom={deactivateWorkspaceRoom}
                          />
                        </div>
                      ))
                    )}
                  </>
                ) : (
                  workspaceRoomDetails?.map((roomDetails, idx) => (
                    <div key={idx}>
                      <WorkspaceRoomListItem
                        {...roomDetails}
                        editWorkspaceRoom={editWorkspaceRoom}
                        deactivateWorkspaceRoom={deactivateWorkspaceRoom}
                      />
                    </div>
                  ))
                )}
              </div>
            </div>
          </Grid>
        </Grid>
      </>
      <AddRoomDrawer
        handleClose={() => setOpenAddRoom(false)}
        open={openAddRoom}
        addworkspaceRoomDetails={addworkspaceRoomDetails}
        updateworkspaceRoomDetail={updateworkspaceRoomDetail}
        updateRoomDetail={updateRoomDetail}
        deactivateWorkspaceRoom={deactivateWorkspaceRoom}
        removeUpdatedRommDetails={removeUpdatedRommDetails}
        getWorkSpaceDetail={getWorkSpaceDetail}
      />
    </div>
  );
}

export default WorkspaceRoomList;
