import React from "react";
import { Grid } from "@material-ui/core";
import styles from "../../../assets/styles/quotations.module.scss";
import { Skeleton } from "@material-ui/lab";

export default function Shimmer(props) {
  return (
    <>
      {props.type == "quotationLength" && (
        <div className={styles.quotationHeading}>
          <h2 className={styles.quotationTitle + " subheading "}>Quotations</h2>
        </div>
      )}
      {props.type == "SearchIcon" && (
        <div className={styles.quotationSearchSection}>
          <Grid container justifyContent="flex-end">
            <Grid item md={6}>
              <div className={styles.quotationSearchBlk}>
                <div className={styles.quotationSearchInput}></div>
              </div>
            </Grid>
          </Grid>
        </div>
      )}
      {props.type === "quotationListHeader" && (
        <>
          <div className={styles.quotationsListTableTitle}>
            <Grid container spacing={2}>
              <Grid item md={7}>
                <Grid container spacing={2}>
                  <Grid item md={5}>
                    <div className={styles.quotationsTableTitleTextWrapper}>
                      <p className={styles.quotationsTableTitleText + " supportText "}>Quotes Number</p>
                    </div>
                  </Grid>
                  <Grid item md={4}>
                    <div className={styles.quotationsTableTitleTextWrapper}>
                      <p className={styles.quotationsTableTitleText + " supportText "}>Requesteds on</p>
                    </div>
                  </Grid>
                  <Grid item md={3}>
                    <div className={styles.quotationsTableTitleTextWrapper}>
                      <p className={styles.quotationsTableTitleText + " supportText "}>Units</p>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={5}>
                <Grid container spacing={2}>
                  <Grid item md={7}>
                    <div className={styles.quotationsTableTitleTextWrapper}>
                      <p className={styles.quotationsTableTitleText + " supportText "}>Subscription Period</p>
                    </div>
                  </Grid>
                  <Grid item md={5}>
                    <div className={styles.quotationsTableTitleTextWrapper}>
                      <p className={styles.quotationsTableTitleText + " supportText "}>Price Offered</p>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </>
      )}
      {props.type == "quotationList" &&
        [...Array(9).keys()].map((item, key) => (
          <div className={styles.quotationListItem} key={key}>
            <div className={styles.quotationsListTableRow}>
              <Grid container>
                <Grid item md={7}>
                  <Grid container spacing={2}>
                    <Grid item md={1}>
                      <div className={styles.quotationContentWrapper}>
                        <Skeleton
                          variant="circle"
                          width={30}
                          height={30}
                          style={{
                            display: "block",
                            verticalAlign: "middle",
                            transform: "none",
                          }}
                        />
                      </div>
                    </Grid>
                    <Grid item md={4}>
                      <div className={styles.quotationContentWrapper}>
                        <Skeleton
                          variant="text"
                          width={100}
                          height={25}
                          style={{
                            display: "block",
                            verticalAlign: "middle",
                            transform: "none",
                            marginTop: 3,
                          }}
                        />
                      </div>
                    </Grid>
                    <Grid item md={4}>
                      <div className={styles.quotationContentWrapper}>
                        <Skeleton
                          variant="text"
                          width={100}
                          height={25}
                          style={{
                            display: "block",
                            verticalAlign: "middle",
                            transform: "none",
                          }}
                        />
                      </div>
                    </Grid>
                    <Grid item md={3}>
                      <div className={styles.quotationContentWrapper}>
                        <Skeleton
                          variant="text"
                          width={50}
                          height={25}
                          style={{
                            display: "block",
                            verticalAlign: "middle",
                            transform: "none",
                          }}
                        />
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item md={5}>
                  <Grid container spacing={2}>
                    <Grid item md={7}>
                      <div className={styles.quotationContentWrapper}>
                        <Skeleton
                          variant="text"
                          width={150}
                          height={25}
                          style={{
                            display: "block",
                            verticalAlign: "middle",
                            transform: "none",
                          }}
                        />
                      </div>
                    </Grid>
                    <Grid item md={5}>
                      <div className={styles.quotationContentWrapper}>
                        <Skeleton
                          variant="text"
                          width={120}
                          height={25}
                          style={{
                            display: "block",
                            verticalAlign: "middle",
                            transform: "none",
                          }}
                        />
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </div>
        ))}
      {props.type == "quotationDetail" && (
        <div className={styles.quoteOrderFormBlk}>
          <Skeleton
            variant="text"
            width={160}
            height={20}
            style={{
              float: "right",
              display: "block",
              verticalAlign: "middle",
              transform: "none",
              margin: 16,
              marginRight: 0,
            }}
          />
          <div className={styles.quoteOrderForm}>
            <div className={styles.quoteOrderFormHeader}>
              <Skeleton
                variant="circular"
                width={64}
                height={64}
                style={{
                  display: "block",
                  verticalAlign: "middle",
                  transform: "none",
                  margin: 8,
                  position: "absolute",
                  top: 0,
                  left: 0,
                  borderRadius: 8,
                }}
              />
              <div className={styles.quoteOrderFormHeaderText}>
                <Skeleton
                  variant="text"
                  width={140}
                  height={25}
                  style={{
                    display: "block",
                    verticalAlign: "middle",
                    transform: "none",
                    margin: 8,
                  }}
                />
                <Skeleton
                  variant="text"
                  width={140}
                  height={20}
                  style={{
                    display: "block",
                    verticalAlign: "middle",
                    transform: "none",
                    margin: 8,
                  }}
                />
              </div>
            </div>
            <div className={styles.PONumberWrapper}>
              <Grid container spacing={2}>
                <Grid item md={6} xs={12}>
                  <div className={styles.PONumberFormInput}>
                    <Skeleton
                      variant="text"
                      width={240}
                      height={48}
                      style={{
                        display: "block",
                        verticalAlign: "middle",
                        transform: "none",
                        margin: 16,
                        marginLeft: 8,
                        borderRadius: 8,
                      }}
                    />
                  </div>
                </Grid>
                <Grid item md={6} xs={12}>
                  <div className={styles.attachPOWrapper}>
                    <Skeleton
                      variant="text"
                      width={120}
                      height={48}
                      style={{
                        display: "block",
                        verticalAlign: "middle",
                        transform: "none",
                        margin: 16,
                        marginLeft: 8,
                        borderRadius: 8,
                      }}
                    />
                  </div>
                </Grid>
              </Grid>
            </div>
            <div className={styles.quoteOrderQuantityWrapper}>
              <div className={styles.quoteOrderQuantity}>
                <Grid container spacing={2}>
                  <Grid item md={8}>
                    <p className={styles.quantityText + " paragraph "}>Quantity</p>
                  </Grid>
                  <Grid item md={4}>
                    <Skeleton
                      variant="text"
                      width={60}
                      height={20}
                      style={{
                        float: "right",
                        display: "block",
                        verticalAlign: "middle",
                        transform: "none",
                        margin: 2,
                      }}
                    />
                  </Grid>
                </Grid>
              </div>
              <div className={styles.quoteSubscriptionPeriod}>
                <Grid container spacing={2}>
                  <Grid item md={8}>
                    <p className={styles.subscriptionText + " paragraph "}>Subscription period</p>
                  </Grid>
                  <Grid item md={4}>
                    <Skeleton
                      variant="text"
                      width={60}
                      height={20}
                      style={{
                        float: "right",
                        display: "block",
                        verticalAlign: "middle",
                        transform: "none",
                        margin: 2,
                      }}
                    />
                  </Grid>
                </Grid>
              </div>
            </div>
            <div className={styles.quoteOrderPricingWrapper}>
              <div className={styles.quoteOrderPricing}>
                <Grid container spacing={2}>
                  <Grid item md={8}>
                    <p className={styles.orderDetailsName + " paragraph "}>Hardware cost</p>
                  </Grid>
                  <Grid item md={4}>
                    <Skeleton
                      variant="text"
                      width={80}
                      height={20}
                      style={{
                        float: "right",
                        display: "block",
                        verticalAlign: "middle",
                        transform: "none",
                        margin: 2,
                      }}
                    />
                  </Grid>
                </Grid>
              </div>
              <div className={styles.quoteOrderPricing}>
                <Grid container spacing={2}>
                  <Grid item md={8}>
                    <p className={styles.orderDetailsName + " paragraph "}>License cost</p>
                  </Grid>
                  <Grid item md={4}>
                    <Skeleton
                      variant="text"
                      width={100}
                      height={20}
                      style={{
                        float: "right",
                        display: "block",
                        verticalAlign: "middle",
                        transform: "none",
                        margin: 2,
                      }}
                    />
                  </Grid>
                </Grid>
              </div>
              <div className={styles.quoteOrderPricing}>
                <Grid container spacing={2}>
                  <Grid item md={8}>
                    <p className={styles.orderDetailsName + " paragraph "}>Sub total</p>
                  </Grid>
                  <Grid item md={4}>
                    <Skeleton
                      variant="text"
                      width={100}
                      height={20}
                      style={{
                        float: "right",
                        display: "block",
                        verticalAlign: "middle",
                        transform: "none",
                        margin: 2,
                      }}
                    />
                  </Grid>
                </Grid>
              </div>
              <div className={styles.quoteOrderPricing}>
                <Grid container spacing={2}>
                  <Grid item md={8}>
                    <p className={styles.orderDetailsName + " paragraph "}>Tax</p>
                  </Grid>
                  <Grid item md={4}>
                    <Skeleton
                      variant="text"
                      width={40}
                      height={20}
                      style={{
                        float: "right",
                        display: "block",
                        verticalAlign: "middle",
                        transform: "none",
                        margin: 2,
                      }}
                    />
                  </Grid>
                </Grid>
              </div>
              <div className={styles.quoteOrderPricing}>
                <Grid container spacing={2}>
                  <Grid item md={8}>
                    <p className={styles.orderDetailsName + " paragraph "}>Discount Offered</p>
                  </Grid>
                  <Grid item md={4}>
                    <Skeleton
                      variant="text"
                      width={50}
                      height={20}
                      style={{
                        float: "right",
                        display: "block",
                        verticalAlign: "middle",
                        transform: "none",
                        margin: 2,
                      }}
                    />
                  </Grid>
                </Grid>
              </div>
              <div className={styles.quoteOrderTotalPricing}>
                <Grid container spacing={2}>
                  <Grid item md={8}>
                    <p className={styles.orderDetailsTotal + " paragraph "}>Total</p>
                  </Grid>
                  <Grid item md={4}>
                    <Skeleton
                      variant="text"
                      width={120}
                      height={20}
                      style={{
                        float: "right",
                        display: "block",
                        verticalAlign: "middle",
                        transform: "none",
                        margin: 2,
                      }}
                    />
                  </Grid>
                </Grid>
              </div>
            </div>
            <div className={styles.quoteOrderFormFooter}>
              <Skeleton
                variant="text"
                width={500}
                height={120}
                style={{
                  display: "block",
                  verticalAlign: "middle",
                  transform: "none",
                  margin: 16,
                }}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
}
