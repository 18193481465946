import React from "react";
import { Grid, List, ListItem } from "@material-ui/core";
import styles from "../../../assets/styles/workspaces.module.scss";
import { Skeleton } from "@material-ui/lab";

export default function Shimmer(props) {
  return (
    <>
      {props.type === "workspaceSearch" && (
        <div className={styles.workspaceSearchBlk}>
          <Grid container direction="row" justifyContent="flex-end" alignItems="center">
            <Grid item md={5} className={styles.workspaceSearchWrapper}>
              <div className={styles.workspaceSearchInput}></div>
              <div className={styles.newWorkspace}>
                <div className={styles.newWorkspaceButton}></div>
              </div>
            </Grid>
          </Grid>
        </div>
      )}
      {props.type === "workspaceHeader" && (
        <div className={styles.workspacesListTableTitle}>
          <Grid container spacing={2}>
            <Grid item md={12}>
              <Grid container direction="row" spacing={2} justifyContent="flex-start" alignItems="center">
                <Grid item md={3}>
                  <div className={styles.workspacesTableTitleTextWrapper}>
                    <p className={styles.workspacesTableTitleText + " paragraph "}>Workpace Name</p>
                  </div>
                </Grid>
                <Grid item md={4}>
                  <div className={styles.workspacesTableTitleTextWrapper}>
                    <p className={styles.workspacesTableTitleText + " paragraph "}>Address</p>
                  </div>
                </Grid>
                <Grid item md={2}>
                  <div className={styles.workspacesTableTitleTextWrapper}>
                    <p className={styles.workspacesTableTitleText + " paragraph "}>Rooms</p>
                  </div>
                </Grid>
                <Grid item md={3}>
                  <div className={styles.workspacesTableTitleTextWrapper}>
                    <p className={styles.workspacesTableTitleText + " paragraph "}>Local Admins</p>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      )}
      {props.type === "singleWorkSpaceList" && (
        <>
          {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((item, key) => (
            <div className={styles.workspaceListItem} key={key}>
              <div className={styles.workspacesListTableRow}>
                <Grid container spacing={2}>
                  <Grid item md={12}>
                    <Grid
                      container
                      direction="row"
                      spacing={2}
                      justifyContent="flex-start"
                      alignItems="center"
                    >
                      <Grid item md={3}>
                        <div className={styles.workspaceContentWrapper}>
                          <div className={styles.workspaceImageWrapper}>
                            <Skeleton
                              variant="circle"
                              width={40}
                              height={40}
                              style={{
                                display: "block",
                                verticalAlign: "middle",
                                transform: "none",
                              }}
                            />
                          </div>
                          <div className={styles.workspaceDetailWrapper}>
                            <div className={styles.workspaceName + " paragraphSemiBold "}>
                              <Skeleton
                                variant="text"
                                width={100}
                                height={25}
                                style={{
                                  display: "block",
                                  verticalAlign: "middle",
                                  transform: "none",
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </Grid>
                      <Grid item md={4}>
                        <div className={styles.workspaceContentWrapper}>
                          <div className={styles.workspaceAddress + " paragraph "}>
                            <Skeleton
                              variant="text"
                              width={250}
                              height={25}
                              style={{
                                display: "block",
                                verticalAlign: "middle",
                                transform: "none",
                              }}
                            />
                          </div>
                        </div>
                      </Grid>
                      <Grid item md={2}>
                        <div className={styles.workspaceContentWrapper}>
                          <div className={styles.workspaceRoom + " paragraph "}>
                            <Skeleton
                              variant="text"
                              width={60}
                              height={25}
                              style={{
                                display: "block",
                                verticalAlign: "middle",
                                transform: "none",
                              }}
                            />
                          </div>
                        </div>
                      </Grid>
                      <Grid item md={3}>
                        <div className={styles.workspaceContentWrapper}>
                          <div className={styles.workspaceAdmin + " paragraph "}>
                            <Skeleton
                              variant="text"
                              width={200}
                              height={25}
                              style={{
                                display: "block",
                                verticalAlign: "middle",
                                transform: "none",
                              }}
                            />
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </div>
          ))}
        </>
      )}
      {props.type === "workspaceDetailsCard" && (
        <>
          {[0].map((item, key) => (
            <div className={styles.workspaceDetailsCard}>
              <Grid container>
                <Grid item md={6}>
                  <Grid container spacing={4}>
                    <Grid item md={9}>
                      <div className={styles.workspaceDetailsIconWrapper}>
                        <Skeleton
                          variant="circle"
                          width={40}
                          height={40}
                          style={{
                            display: "block",
                            verticalAlign: "middle",
                            transform: "none",
                            marginBottom: 8,
                          }}
                        />
                        <div className={styles.workspaceDetailsTextWrapper}>
                          <div className={styles.workspaceName + " paragraph "}>
                            <Skeleton
                              variant="text"
                              width={150}
                              height={24}
                              style={{
                                position: "relative",
                                display: "block",
                                verticalAlign: "middle",
                                transform: "none",
                                marginBottom: 10,
                                marginTop: -50,
                              }}
                            />
                          </div>
                          <div className={styles.workspaceAddress + " supportText "}>
                            <Skeleton
                              variant="text"
                              width={240}
                              height={40}
                              style={{
                                display: "block",
                                verticalAlign: "middle",
                                transform: "none",
                                marginBottom: 8,
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </Grid>
                    <Grid item md={3}>
                      <div className={styles.workspaceRoomCount + " paragraph "}>
                        <Skeleton
                          variant="text"
                          width={70}
                          height={10}
                          style={{
                            display: "block",
                            verticalAlign: "middle",
                            transform: "none",
                            marginBottom: 8,
                          }}
                        />
                        <Skeleton
                          variant="text"
                          width={70}
                          height={30}
                          style={{
                            display: "block",
                            verticalAlign: "middle",
                            transform: "none",
                            marginBottom: 8,
                          }}
                        />
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item md={6}>
                  <Grid container spacing={4}>
                    <Grid item md={7}>
                      <div className={styles.workspaceLocalAdminWrapper}>
                        <div className={styles.workspaceLocalAdminText + " supportText "}>
                          <Skeleton
                            variant="text"
                            width={150}
                            height={12}
                            style={{
                              display: "block",
                              verticalAlign: "middle",
                              transform: "none",
                              marginBottom: 8,
                            }}
                          />
                        </div>
                        <List>
                          <ListItem className="supportText">
                            <Skeleton
                              variant="circle"
                              width={10}
                              height={10}
                              style={{
                                display: "block",
                                verticalAlign: "middle",
                                transform: "none",
                              }}
                            />
                            <Skeleton
                              variant="text"
                              width={130}
                              height={10}
                              style={{
                                display: "block",
                                verticalAlign: "middle",
                                transform: "none",
                                marginLeft: 10,
                              }}
                            />
                          </ListItem>
                          <ListItem className="supportText">
                            <Skeleton
                              variant="circle"
                              width={10}
                              height={10}
                              style={{
                                display: "block",
                                verticalAlign: "middle",
                                transform: "none",
                              }}
                            />
                            <Skeleton
                              variant="text"
                              width={130}
                              height={10}
                              style={{
                                display: "block",
                                verticalAlign: "middle",
                                transform: "none",
                                marginLeft: 10,
                              }}
                            />
                          </ListItem>
                        </List>
                      </div>
                    </Grid>
                    <Grid item md={5}>
                      <div className={styles.defaultWallpaperWrapper}>
                        <div className={styles.workspaceWallpaperText + " supportText "}>
                          <Skeleton
                            variant="text"
                            width={150}
                            height={12}
                            style={{
                              display: "block",
                              verticalAlign: "middle",
                              transform: "none",
                              marginBottom: 10,
                            }}
                          />
                        </div>
                        <Skeleton
                          variant="circle"
                          width={40}
                          height={40}
                          style={{
                            display: "block",
                            verticalAlign: "middle",
                            transform: "none",
                            marginLeft: 60,
                          }}
                        />
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <div className={styles.workspaceDetailsMoreOption}>
                <Skeleton
                  variant="text"
                  width="15%"
                  height={40}
                  style={{
                    display: "block",
                    verticalAlign: "left",
                    transform: "rotateY(60deg)",
                    marginTop: 8,
                    marginLeft: 100,
                  }}
                />
              </div>
            </div>
          ))}
        </>
      )}
      {props.type === "workspaceRoomSearch" && (
        <Grid container justifyContent="flex-end">
          <Grid item md={6}>
            <div className={styles.WorkspaceAddNewRoomBtnWrapper}></div>
            <div className={styles.workspaceRoomSearchBlk}>
              <div className={styles.workspaceRoomSearchInput}></div>
            </div>
          </Grid>
        </Grid>
      )}
      {props.type === "workspaceRoomHeader" && (
        <div className={styles.workspaceRoomListTitleRow}>
          <Grid container>
            <Grid item md={7}>
              <Grid container spacing={2}>
                <Grid item md={4}>
                  <div className={styles.workspaceRoomTableTitleTextWrapper}>
                    <p className={styles.workspaceRoomTableTitleText + " supportText "}>Room Name</p>
                  </div>
                </Grid>
                <Grid item md={4}>
                  <div className={styles.workspaceRoomTableTitleTextWrapper}>
                    <p className={styles.workspaceRoomTableTitleText + " supportText "}>Seating Capacity</p>
                  </div>
                </Grid>
                <Grid item md={4}>
                  <div className={styles.workspaceRoomTableTitleTextWrapper}>
                    <p className={styles.workspaceRoomTableTitleText + " supportText "}>Device</p>
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={5}>
              <Grid container spacing={2}>
                <Grid item md={12}>
                  <div className={styles.workspaceRoomTableTitleTextWrapper}>
                    <p className={styles.workspaceRoomTableTitleText + " supportText "}>Additional Devices</p>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      )}
      {props.type === "workspaceRoom" && (
        <>
          {[0, 1, 2, 3, 4].map((item, key) => (
            <div className={styles.workspaceRoomListBlk} style={{ borderBottom: "1px solid #e6e8e9" }}>
              <div className={styles.workspaceRoomListContentRow}>
                <div className={styles.workspaceRoomListItem}>
                  <Grid container>
                    <Grid item md={7}>
                      <Grid container spacing={2}>
                        <Grid item md={4}>
                          <div className={styles.workspaceRoomNameWrapper}>
                            <Skeleton
                              variant="circle"
                              width={40}
                              height={40}
                              style={{
                                display: "block",
                                verticalAlign: "middle",
                                transform: "none",
                                marginTop: 30,
                                marginLeft: 5,
                              }}
                            />
                            <div className={styles.workspaceRoomName + " paragraph "}>
                              <Skeleton
                                variant="text"
                                width={100}
                                height={25}
                                style={{
                                  display: "block",
                                  verticalAlign: "middle",
                                  transform: "none",
                                  position: "relative",
                                  marginLeft: 50,
                                  top: -32,
                                  bottom: 50,
                                }}
                              />
                            </div>
                          </div>
                        </Grid>
                        <Grid item md={4}>
                          <div className={styles.workspaceRoomSeatingCapacity + " paragraph "}>
                            <Skeleton
                              variant="text"
                              width={130}
                              height={25}
                              style={{
                                display: "block",
                                verticalAlign: "middle",
                                transform: "none",
                                marginBottom: 8,
                                marginTop: 36,
                              }}
                            />
                          </div>
                        </Grid>
                        <Grid item md={4}>
                          <div className={styles.workspaceRoomDeviceStatusWrapper}>
                            <Skeleton
                              variant="text"
                              width={150}
                              height={25}
                              style={{
                                display: "block",
                                verticalAlign: "middle",
                                transform: "none",
                                marginBottom: 8,
                                marginTop: 36,
                              }}
                            />
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item md={5}>
                      <Grid container spacing={2}>
                        <Grid item md={12}>
                          <div className={styles.workspaceRoomAdditionalDevices + " paragraph "}>
                            <Skeleton
                              variant="text"
                              width={320}
                              height={25}
                              style={{
                                display: "block",
                                verticalAlign: "middle",
                                transform: "none",
                                marginBottom: 8,
                                marginTop: 36,
                              }}
                            />
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </div>
          ))}
        </>
      )}
      {props.type === "wallpapers" && (
        <>
          {[0, 1, 2, 3, 4, 6, 7, 8, 9].map((item, key) => (
            <Grid key={key} item md={3}>
              <>
                <Skeleton
                  variant="circle"
                  width={100}
                  height={100}
                  style={{
                    display: "block",
                    verticalAlign: "middle",
                    transform: "none",
                    textAlign: "center",
                    justifyContent: "center",
                  }}
                />
              </>
            </Grid>
          ))}
        </>
      )}
    </>
  );
}
