import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Container, Grid } from "@material-ui/core";
import { imageBasePath } from "../../../constants";
import styles from "../../../assets/styles/cart.module.scss";
import OrderStatus from "../components/OrderStatus";

export default function Status(props) {
  const[status, setStatus] = useState({});
  const location = useLocation();
  useEffect(() => {
    if(location && location.state?.statusDetail){
      setStatus(location.state);
    }
  }, [location]);
  return (
    <>
      <div className={styles.orderStatusWrapper}>
        <OrderStatus status={status} />
      </div>
    </>
  );
}
