import { useState } from "react";
import { Dialog, DialogContent, DialogTitle, Grid, List, ListItem } from "@material-ui/core";
import { CustomButton } from "../shared_ui_components";

export default function LogOutDialog(props) {
  const [open, setOpen] = useState(false);
  const [loader, setLoader] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        open={true}
        onClose={handleClose}
        aria-labelledby="scroll-dialog-title"
        className={" dsp-dialogs logOutDialog"}
      >
        <DialogTitle className="modalTitle" id="scroll-dialog-title">
          <h3 className="heading6 textCenter">{props.text}</h3>
        </DialogTitle>
        <DialogContent>
          <Grid container justifyContent="flex-end" direction="row" alignItems="center" spacing={2}>
            <Grid item xs={12}>
              <List className="listInline">
                <ListItem className="listInlineItem">
                  <CustomButton
                    children={`No`}
                    className={`tertiaryBtn cancelBtn`}
                    onClick={() => props.handleCancel(false)}
                  />
                </ListItem>
                <ListItem className="listInlineItem">
                  <CustomButton
                    children={loader ? "Please wait..." : "Yes"}
                    className={`primaryBtn ${loader ? "disabledSubmit" : ""}`}
                    onClick={(e) => {
                      setLoader(true);
                      props.handleSuccess(e);
                    }}
                  />
                </ListItem>
              </List>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
}
