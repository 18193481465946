import React from 'react';
import { Grid, List, ListItem } from "@material-ui/core";
import styles from "../../../../assets/styles/admin/partner.module.scss";
import { imageBasePath } from "../../../../constants";
import { CustomButton } from "../../../../shared_ui_components";

export default function PartnerRequestDetail(props) {
    
    return (
        <Grid container justifyContent="center" alignItems="center">
            <Grid item md={12}>
                <div className={styles.partnerDetailWrapper}>
                    <div className={styles.partnerDetailBlk}>
                        <List>
                            <ListItem>
                                <img src={imageBasePath + "partner_profile_42.svg"} alt="" />
                                <p className={styles.customerName + " subheading "}>
                                    Pavan Kalyan
                                </p>
                            </ListItem>
                            <ListItem>
                                <span className={styles.emailblk+" paragraph"}>
                                    <img src={imageBasePath + "email_icon.svg"} alt="" />
                                    pavan@welpro.com
                                </span>
                            </ListItem>
                            <ListItem>
                                <span className="paragraph">
                                    <img src={imageBasePath + "phone_icon.svg"} alt="" />
                                    +91 68326 82623
                                </span>
                            </ListItem>
                        </List>
                    </div>
                    <Grid item container md={12}>
                        <div className={styles.partnerOrgDetail}>
                            <Grid item container md={12}>
                                <Grid item md={5}>
                                    <p className={styles.partnerTitle + " label"}>Partner Company</p>
                                </Grid>
                                <Grid item md={7}>
                                    <div className={styles.partnerDetailNameWrapper}>
                                        <img src={imageBasePath + "organisation_logo.svg"} alt="" />
                                        <p className={styles.partnerDetailName + " label"}>Welpro Private Limited</p>
                                    </div>
                                </Grid>
                            </Grid>
                            <Grid item container md={12}>
                                <Grid item md={5}>
                                    <p className={styles.partnerTitle + " label"}>Registered Address</p>
                                </Grid>
                                <Grid item md={7}>
                                    <p className={styles.partnerDetailName + " label"}>1st Floor, A P Complex, 65/6, Agara, Sarjapur Main Road, Bengaluru, Karnataka 560102</p>
                                </Grid>
                            </Grid>
                            <Grid item container md={12}>
                                <Grid item md={5}>
                                    <p className={styles.partnerTitle + " label"}>PAN Number</p>
                                </Grid>
                                <Grid item md={7}>
                                    <p className={styles.partnerDetailName + " label"}>ALWPG5809L</p>
                                </Grid>
                            </Grid>
                            <Grid item container md={12}>
                                <Grid item md={5}>
                                    <p className={styles.partnerTitle + " label"}>GST Number</p>
                                </Grid>
                                <Grid item md={7}>
                                    <p className={styles.partnerDetailName + " label"}>22AABCU9603R1ZX</p>
                                </Grid>
                            </Grid>
                            <Grid item container md={12}>
                            <Grid item md={5}>
                                <p className={styles.partnerTitle + " label"}>TIN Number</p>
                            </Grid>
                            <Grid item md={7}>
                                <p className={styles.partnerDetailName + " label"}>123 456 789 000</p>
                            </Grid>
                        </Grid>
                        </div>
                    </Grid>
                </div>
                <div className={styles.partnerButtonWrapper}>
                    <Grid container justifyContent="flex-end" alignItems="center">
                        <Grid item md={6}>
                            <CustomButton
                                children="Approve"
                                className={
                                    styles.approvePartnerBtn + " primaryBtn "
                                }
                            />
                        </Grid>
                    </Grid>
                </div>
            </Grid>
        </Grid>
    )
}
