import { globalPostService, globalGetService } from "../../utils/globalApiServices";

export function getOrdersList(data = {}) {
  return new Promise((resolve, reject) => {
    globalPostService(`get-order-details-v1`, data)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function getOrderDetail(data = {}) {
  return new Promise((resolve, reject) => {
    globalPostService(`single-order-details`, data)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function getAdminOrdersList() {
  return new Promise((resolve, reject) => {
    globalGetService(`list-of-oreder-admin-v1`)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function updateAdminOrderPaymentStatus(data = {}) {
  return new Promise((resolve, reject) => {
    globalPostService(`update-payement-status-admin-v1`, data)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
