import React from "react";
import styles from "../../../assets/styles/auth.module.scss";
import { imageBasePath } from "../../../constants";
import { InputText, CustomButton, CustomLink } from "../../../shared_ui_components";
import { useHistory } from "react-router-dom";

export default function ResetLinkSent(props) {
  const { emailId } = props;
  const history = useHistory();

  const goToSignin = () => {
    history.push("/signin");
  };

  return (
    <div className={styles.successSection}>
      <div className={styles.successWrapper}>
        <div className={styles.imageWrapper}>
          <img src={imageBasePath + "verified_success.svg"} alt="success" />
        </div>
        <div className={styles.contentWrapper}>
          <p className={styles.contentPara + " paragraph"}>
            We've sent password reset instructions to your email :{" "}
            <span>{emailId ? emailId : "johndoe@email.com"}</span>
          </p>
          <p className={styles.contentSubPara + " paragraph"}>Check spam folder if no email is received.</p>
          <CustomButton
            onClick={() => goToSignin()}
            className={styles.contentBtn + " primaryBtn"}
            children={"Back to Sign In"}
          />
        </div>
        <div className={styles.backButtonWrapper + " paragraph"}>
          <p>Still didn’t receive email?</p>
          <CustomLink className={styles.backButtonLink + " backBtn"} text="Send it again" />
        </div>
      </div>
    </div>
  );
}
