import React from "react";
import { TextField } from "@material-ui/core";

export default function InputText(props) {
  return (
    <div className={`inputGroup ${props.noMargin ? "noMargin" : ""}`}>
      <TextField
        type={props.type ? props.type : "text"}
        fullWidth
        margin="normal"
        InputLabelProps={{
          shrink: true,
        }}
        disabled={props.disabled}
        accept={props.accept}
        {...props}
        error={props.error ? true : false}
        // value={props.value ? props.value : ""}
        // onChange={props.onChange}
        // name={props.name}
      />
      {props.error ? <h6 className="errorMsg">{props.error}</h6> : null}
    </div>
  );
}
