import React, { useState, useEffect, useContext } from "react";
import { Container, Grid } from "@material-ui/core";
import styles from "../../../assets/styles/allOrganisation.module.scss";
import { imageBasePath } from "../../../constants";
import { getUserInfo, sortObj } from "../../../utils";
import { CustomLink, InputText, NoResult } from "../../../shared_ui_components";
import { useHistory } from "react-router-dom";
import { GlobalContext } from "../../../context/GlobalContext";
import { SingleOrganisation } from "../components/SingleOrganisation";
import Shimmer from "../components/Shimmer";

export default function AllOrganisation() {
  const history = useHistory();
  const userInfo = getUserInfo();

  const {
    state: { orgListNames, loading, noState },
  } = useContext(GlobalContext);
  const [orgNames, setOrgNames] = useState([]);
  const [search, setSearch] = useState({
    val: "",
    list: [],
  });
  const [sort, setSort] = useState({
    field: "",
    asc: false,
    defaultSortUpImg: "",
    defaultSortDownImg: "",
  });

  const handleGoBack = () => {
    history.push("/home");
  };

  const handleSearch = (val) => {
    setSearch((prevSearch) => ({
      val: val,
      list: orgListNames.filter((org) => org?.orgName?.toLowerCase().includes(val.toLowerCase())),
    }));
  };

  const handleSort = (sortObject) => {
    let list = sortObj(sortObject.field, orgListNames, sortObject.asc, sortObject.caseSensitive);
    setSort({
      field: sortObject.field,
      asc: sortObject.asc,
      defaultSortUpImg: sortObject.asc ? imageBasePath + "asc_arrow.svg" : imageBasePath + "up_arrow.svg",
      defaultSortDownImg: sortObject.asc ? imageBasePath + "down_arrow.svg" : imageBasePath + "des_arrow.svg",
    });
    setOrgNames(list);
  };

  return (
    <Container maxWidth="lg">
      <div className={styles.allOrganisationPage}>
        <div className={styles.allOrganisationWrapper}>
          <div className={styles.backBtnWrapper}>
            <CustomLink
              className="backBtn"
              text="Back to home"
              leftIcon={imageBasePath + "arrow_left_icon.svg"}
              onClick={handleGoBack}
            />
          </div>
          <div className={styles.allOrganisationHeaderWrapper}>
            <div className={styles.allOrganisationPageTitleWrapper}>
              <h2 className={styles.allOrganisationPageTitle + " subheading "}>All organisations</h2>
            </div>
          </div>
          {noState ? (
            <NoResult
              image="no_workspace.png"
              title="No Organisation found"
              subtext="Looks like you have not yet added any organisation. Start adding organisation by clicking below button."
              btnText="Add Organisation"
              link="/add-organisation"
            />
          ) : (
            <>
              {loading ? (
                <Shimmer type="orgSearch" />
              ) : (
                <div className={styles.organisationSearchBlk}>
                  <Grid container direction="row" spacing={2} justifyContent="flex-end" alignItems="center">
                    <Grid item md={3}>
                      <div className={styles.organisationSearchInput}>
                        <InputText placeholder="Search here" onChange={(e) => handleSearch(e.target.value)} />
                        <img src={imageBasePath + "search_icon.svg"} alt="" />
                      </div>
                    </Grid>
                    {userInfo.userData?.role?.responsibilities?.createOrg ? (
                      <Grid item md={3}>
                        <div className={styles.addNewOrganisation}>
                          <CustomLink
                            to="/add-organisation"
                            className={styles.addButton + " outlinedBtn "}
                            text="Add new organisation"
                            leftIcon={imageBasePath + "invite_user.svg"}
                          />
                        </div>
                      </Grid>
                    ) : null}
                  </Grid>
                </div>
              )}
            </>
          )}

          <div className={styles.allOrganisationListBlk}>
            <Grid container>
              <Grid item md={12}>
                <div className={styles.allOrganisationList}>
                  <div className={styles.allOrganisationListInnerBlk}>
                    {loading ? (
                      <Shimmer type="orgHeader" />
                    ) : (
                      <div className={styles.allOrganisationListTableTitle}>
                        <Grid container spacing={2}>
                          <Grid item md={12}>
                            <Grid
                              container
                              direction="row"
                              spacing={2}
                              justifyContent="flex-start"
                              alignItems="center"
                            >
                              <Grid item md={4}>
                                <div className={styles.allOrganisationTableTitleTextWrapper}>
                                  <p className={styles.allOrganisationTableTitleText + " paragraph "}>
                                    Organisation Name
                                  </p>
                                </div>
                              </Grid>
                              <Grid container item md={3}>
                                <Grid item md={6}>
                                  <div className={styles.allOrganisationTableTitleTextWrapper}>
                                    <p className={styles.allOrganisationTableTitleText + " paragraph "}>
                                      Workspaces
                                    </p>
                                    <img
                                      src={
                                        sort.field === "workSpaceCount"
                                          ? sort.defaultSortUpImg
                                          : imageBasePath + "up_arrow.svg"
                                      }
                                      alt=""
                                      className="sortUp"
                                      onClick={() =>
                                        handleSort({
                                          field: "workSpaceCount",
                                          asc: !sort.asc,
                                          caseSensitive: false,
                                        })
                                      }
                                    />
                                    <img
                                      src={
                                        sort.field === "workSpaceCount"
                                          ? sort.defaultSortDownImg
                                          : imageBasePath + "down_arrow.svg"
                                      }
                                      alt=""
                                      className="sortDown"
                                      onClick={() =>
                                        handleSort({
                                          field: "workSpaceCount",
                                          asc: !sort.asc,
                                          caseSensitive: false,
                                        })
                                      }
                                    />
                                  </div>
                                </Grid>
                                <Grid item md={6}>
                                  <div className={styles.allOrganisationTableTitleTextWrapper}>
                                    <p className={styles.allOrganisationTableTitleText + " paragraph "}>
                                      No. of Devices
                                    </p>
                                    <img
                                      src={
                                        sort.field === "deviceCount"
                                          ? sort.defaultSortUpImg
                                          : imageBasePath + "up_arrow.svg"
                                      }
                                      alt=""
                                      className="sortUp"
                                      onClick={() =>
                                        handleSort({
                                          field: "deviceCount",
                                          asc: !sort.asc,
                                          caseSensitive: false,
                                        })
                                      }
                                    />
                                    <img
                                      src={
                                        sort.field === "deviceCount"
                                          ? sort.defaultSortDownImg
                                          : imageBasePath + "down_arrow.svg"
                                      }
                                      alt=""
                                      className="sortDown"
                                      onClick={() =>
                                        handleSort({
                                          field: "deviceCount",
                                          asc: !sort.asc,
                                          caseSensitive: false,
                                        })
                                      }
                                    />
                                  </div>
                                </Grid>
                              </Grid>
                              <Grid item md={5}>
                                <div className={styles.allOrganisationTableTitleTextWrapper}>
                                  <p className={styles.allOrganisationTableTitleText + " paragraph "}>
                                    Admin Email ID
                                  </p>
                                </div>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </div>
                    )}
                    <div className={styles.allOrganisationListTableContent}>
                      {loading ? (
                        <Shimmer type="orgLists" />
                      ) : search.val ? (
                        <>
                          {search.list.length === 0 ? (
                            <NoResult image="no_order.png" title="No search result found" />
                          ) : (
                            search.list.map((item) => (
                              <div key={item._id}>
                                <SingleOrganisation {...item} />
                              </div>
                            ))
                          )}
                        </>
                      ) : orgNames.length > 0 ? (
                        orgNames.map((item) => (
                          <div key={item._id}>
                            <SingleOrganisation {...item} />
                          </div>
                        ))
                      ) : (
                        orgListNames.map((item) => (
                          <div key={item._id}>
                            <SingleOrganisation {...item} />
                          </div>
                        ))
                      )}
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    </Container>
  );
}
