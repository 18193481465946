import { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import styles from "../../../assets/styles/registration.module.scss";
import { errorCodes, reactSelectStylesLarge } from "../../../constants";
import { City, State } from "../../../utils/cityState";
import { registerpartnerApi } from "../ApiService";
import { toastFlashMessage } from "../../../utils";
import { fieldValidation } from "../../../utils/formValidation";

import {
  InputText,
  InputSelect,
  CustomLink,
  CustomButton,
  CustomCheckbox,
} from "../../../shared_ui_components";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";

export default function BecomePartner(props) {
  const { handleChangeStatus } = props;
  const [formData, setFormData] = useState({
    workEmailID: "",
    fullName: "",
    contactNumber: "",
    address: "",
    stateName: "",
    cityName: "",
    pincode: "12345",
    gstNumber: "",
    tinNumber: "",
    recordStatus: "1",
    partnershipStatus: "Pending",
    organisationName: "",
    panNumber: "",
  });
  const [error, setError] = useState({});

  const [cityOption, setCityOption] = useState([]);
  const [stateOption, setStateOption] = useState([]);
  const [validateLoader, setValidateLoader] = useState(false);

  useEffect(() => {
    let states = [];
    State.forEach((state) => states.push({ label: state, value: state }));
    setStateOption(states);
  }, []);

  const setCityList = (state) => {
    const updatedCityList = City.filter((key) => key.state === state).map((city) => {
      return { label: city.city, value: city.city };
    });
    setCityOption(updatedCityList);
  };

  const handleChange = (key, value) => {
    if (key === "stateName") {
      setFormData((formData) => ({
        ...formData,
        [key]: value,
        cityName: null,
      }));
      setError((preError) => ({
        ...preError,
        [key]: "",
      }));
      setCityList(value);
    } else {
      setFormData((formData) => ({
        ...formData,
        [key]: value,
      }));
      setError((preError) => ({
        ...preError,
        [key]: "",
      }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let validateNewInput;
    validateNewInput = {
      workEmailID:
        errorCodes["email"][
          fieldValidation({
            ...errorCodes["emailObj"],
            fieldval: formData.workEmailID.trim(),
          })
        ],
      fullName:
        errorCodes["fullName"][
          fieldValidation({
            ...errorCodes["fullNameObj"],
            fieldval: formData.fullName,
          })
        ],
      address:
        errorCodes["address"][
          fieldValidation({
            ...errorCodes["addressObj"],
            fieldval: formData.address,
          })
        ],
      contactNumber:
        errorCodes["contactNumber"][
          fieldValidation({
            ...errorCodes["contactNumberObj"],
            fieldval: formData.contactNumber,
          })
        ],
      cityName:
        errorCodes["cityName"][
          fieldValidation({
            ...errorCodes["cityNameObj"],
            fieldval: formData.cityName,
          })
        ],
      stateName:
        errorCodes["stateName"][
          fieldValidation({
            ...errorCodes["stateNameObj"],
            fieldval: formData.stateName,
          })
        ],
      gstNumber:
        errorCodes["gstNumber"][
          fieldValidation({
            ...errorCodes["gstNumberObj"],
            fieldval: formData.gstNumber,
            panNumber: formData.panNumber,
          })
        ],
      // tinNumber:
      //   errorCodes["tinNumber"][
      //     fieldValidation({
      //       ...errorCodes["tinNumberObj"],
      //       fieldval: pertnerDetails.tinNumber,
      //     })
      //   ],
      organisationName:
        errorCodes["orgName"][
          fieldValidation({
            ...errorCodes["orgNameObj"],
            fieldval: formData.organisationName,
          })
        ],
      panNumber:
        errorCodes["panNumber"][
          fieldValidation({
            ...errorCodes["panNumberObj"],
            fieldval: formData.panNumber,
          })
        ],
    };
    if (Object.keys(validateNewInput).every((k) => validateNewInput[k] == "")) {
      //console.log(formData);
      setValidateLoader(true);
      registerpartnerApi(formData).then((response) => {
        setValidateLoader(false);
        if (response.status) {
          //  console.log(response, "response");
          toastFlashMessage("Partner details submitted successfully", "success");
          handleChangeStatus();
        } else {
          if (response.message.includes("EmailId")) {
            toastFlashMessage("Email already exists", "error");
          } else {
            toastFlashMessage("Something went wrong while submitting partner details", "error");
          }
        }
      });
    } else {
      setError(validateNewInput);
    }
  };
  return (
    <div className={styles.becomePartnerWrapper}>
      <form className={styles.PartnerForm} onSubmit={handleSubmit}>
        <h6 className={styles.becomePartnerHeading + " heading6"}>Please provide your details</h6>
        <InputText
          label="Full Name *"
          placeholder="eg,  Dohn doe"
          value={formData.fullName || ""}
          error={error.fullName}
          onChange={(e) => handleChange("fullName", e.target.value)}
        />
        <div className="inputGroup phoneNumberGroup">
          <label className="label">Contact number *</label>
          <PhoneInput
            countryCodeEditable={false}
            disableCountryCode={true}
            placeholder="Enter phone number"
            defaultCountry="IN"
            value={formData.contactNumber || ""}
            error={error.contactNumber}
            onChange={(value) => {
              handleChange("contactNumber", value);
            }}
          />
          {error.contactNumber ? <h6 className="errorMsg">{error.contactNumber}</h6> : null}
        </div>
        <InputText
          label="Organisation Name *"
          placeholder="eg,  Dohn doe"
          error={error.organisationName}
          value={formData.organisationName || ""}
          onChange={(e) => {
            handleChange("organisationName", e.target.value);
          }}
        />
        <InputText
          label="Work Email ID *"
          placeholder="eg,  abc@xyz.com"
          error={error.workEmailID}
          value={formData.workEmailID.trim() || ""}
          onChange={(e) => {
            handleChange("workEmailID", e.target.value);
          }}
        />
        <InputText
          label="Registered Address *"
          placeholder="Street address"
          error={error.address}
          value={formData.address || ""}
          onChange={(e) => {
            handleChange("address", e.target.value);
          }}
        />
        <Grid container spacing={4}>
          <Grid item md={6} xs={12}>
            <InputSelect
              closeMenuOnSelect={true}
              isSearchable={true}
              label={"State *"}
              styles={reactSelectStylesLarge}
              error={error.stateName}
              placeholder={"Select State"}
              noOptionsMessage={() => "No matches found"}
              //options={getStateOptions(State, City)}
              //value={getStateOptions(State, City).find((opt) => opt.value === selectedState)}
              // onChange={(selectedOption) => {
              //   handleChange("stateName", selectedOption ? selectedOption.value : []);
              //   setSelectedState(selectedOption ? selectedOption.value : "");
              // }}
              options={stateOption}
              value={formData.stateName ? stateOption.find((opt) => opt.value === formData.stateName) : null}
              onChange={(state) => handleChange("stateName", state.value)}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <InputSelect
              closeMenuOnSelect={true}
              isSearchable={true}
              label={"City *"}
              styles={reactSelectStylesLarge}
              placeholder={"Select City"}
              error={error.cityName}
              noOptionsMessage={() => "No matches found"}
              //options={getCityOptions(City)}
              // value={getCityOptions(City).find((opt) => opt.value === selectedCity)}
              // onChange={(selectedOption) => {
              //   handleChange("cityName", selectedOption ? selectedOption.value : []);
              //   setSelectedUpdateCity(selectedOption ? selectedOption.value : "");
              // }}

              options={cityOption}
              value={formData.cityName ? cityOption.find((city) => city.value === formData.cityName) : null}
              onChange={(city) => handleChange("cityName", city.value)}
            />
          </Grid>
        </Grid>
        <InputText
          label="PAN number *"
          placeholder="eg,  PAN112345678"
          error={error.panNumber}
          value={formData.panNumber || ""}
          onChange={(e) => {
            handleChange("panNumber", e.target.value);
          }}
        />
        <InputText
          label="GST Number *"
          placeholder="eg, 12345678"
          error={error.gstNumber}
          value={formData.gstNumber || ""}
          onChange={(e) => {
            handleChange("gstNumber", e.target.value);
          }}
        />
        <InputText
          label="TIN number"
          placeholder="eg, 12345678"
          // error={error.tinNumber}
          value={formData.tinNumber || ""}
          onChange={(e) => {
            handleChange("tinNumber", e.target.value);
          }}
        />
        <div className={styles.registerCheckbox}>
          <CustomCheckbox type="primary" />
          <p className={styles.checkCondition + " paragraph"}>
            I agree to disclose the correct details and{" "}
            <span className={styles.checkConditionBlue + " paragraph"}>Terms & conditions</span>
          </p>
        </div>
        <div className={styles.buttonSection}>
          <CustomButton
            type="submit"
            children={validateLoader ? "Registering.." : "Register"}
            className={`primaryBtn submitBtn ${validateLoader ? "disabledBtn" : ""}`}
            //onClick={() => handleSubmit()}
          />
        </div>
      </form>
    </div>
  );
}
