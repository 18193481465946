import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import styles from "../../../assets/styles/orgSettings.module.scss";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import DisplayConfig from "./DisplayConfig";
import WelcomeDisplay from "./WelcomeDisplay";
import Domains from "./Domains";
import { imageBasePath } from "../../../constants";
import { useParams, useLocation } from "react-router";
import { GlobalContext } from "../../../context/GlobalContext";
import Customization from "./Customization";
import Resources from "../../resources/container/Resources";


function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      className={styles.tabPanel}
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

export default function SettingsInnerContainer(props) {
  const { handleClose, openEdit } = props;
  const {
    state: { orgListNames },
  } = useContext(GlobalContext);

  const { id } = useParams();
  const [value, setValue] = useState(0);
  const [orgLists, setOrgLists] = useState([]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const location = useLocation();
  //console.log(location.pathname === `/${id}/settings`);

  useEffect(() => {
    setOrgLists(orgListNames.find((orgName) => orgName._id === id));
  }, [orgListNames]);

  const handleUpdateForm = (data) => {
    setOrgLists(data);
  };

  useEffect(() => {
    setValue(openEdit == 2 ? 1 : 0);
  }, [openEdit]);

  return (
    <>
      {location.pathname === `/${id}/settings` ? null : (
        <div className={styles.addWorkspaceTitleWrapper}>
          <div className={styles.addWorkspaceTitle}>
            <p className={styles.addWorkspaceHeader + " subheading"}>Device Settings</p>
          </div>
          <div className={styles.closeDialog}>
            <img onClick={handleClose} src={imageBasePath + "user_cancel.svg"} alt="" />
          </div>
        </div>
      )}
      <div className={styles.settingsInnerBlk}>
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={value}
          onChange={handleChange}
          aria-label="Vertical tabs example"
          className={styles.settingsInnerLeftSidebar}
        >
          <Tab className={styles.filterItem + " " + styles.active} label="Display" {...a11yProps(0)} />
          <Tab className={styles.filterItem} label="Company Logo" {...a11yProps(1)} />
          {location.pathname === `/${id}/settings` ? (
            <Tab className={styles.filterItem} label="Domains" {...a11yProps(2)} />
          ) : null}
          <Tab className={styles.filterItem} label="Customization" {...a11yProps(3)} />
          <Tab className={styles.filterItem} label="Room Controllers" {...a11yProps(4)} />


        </Tabs>
        <TabPanel value={value} index={0}>
          <DisplayConfig orgLists={orgLists} handleUpdateForm={handleUpdateForm} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <WelcomeDisplay orgLists={orgLists} handleUpdateForm={handleUpdateForm} />
        </TabPanel>
        {location.pathname === `/${id}/settings` ? (
          <TabPanel value={value} index={2}>
            <Domains />
          </TabPanel>
        ) : null}
         <TabPanel value={value} index={3}>
          <Customization/>
        </TabPanel>
        <TabPanel value={value} index={4}>
       <Resources/>
        </TabPanel>
      </div>
    </>
  );
}
