import React from "react";
import styles from "../../../assets/styles/workspaces.module.scss";
import WorkspacesList from "../components/WorkspacesList";

export default function Workspaces(props) {
  return (
    <div className={styles.workspacesPage}>
      <div className={styles.workspacesWrapper}>
        <div className={styles.workspacesHeading}>
          <h2 className={styles.workspacesTitle + " subheading "}>Workspaces</h2>
        </div>
        <WorkspacesList />
      </div>
    </div>
  );
}
