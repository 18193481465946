import React, { useState, useEffect } from "react";
import styles from "../../../assets/styles/orgSettings.module.scss";
import { imageBasePath } from "../../../constants";
import { CustomLink, InputText, CustomButton } from "../../../shared_ui_components";
import { createWelcomeMessage } from "../apiServices";
import { toastFlashMessage } from "../../../utils";
import { useParams } from "react-router";

export default function WelcomeDisplay(props) {
  const { orgLists } = props;
  const { id } = useParams();
  const [formData, setFormData] = useState({
    orgId: id && id,
  });
  const [image, setImage] = useState("");
  const [displayImage, setDisplayImage] = useState(imageBasePath + "profile_placeholder.svg");
  const [fieldError, setFieldError] = useState(false);
  const [validateLoader, setValidateLoader] = useState(false);

  const getWelcomeDisplay = (data) => {
    setFormData((form) => ({
      ...form,
      body: data?.body ? data?.body : "",
      title: data?.title ? data?.title : "",
      orgLogo: data?.orgLogo ? data?.orgLogo : "",
    }));
  };

  useEffect(() => {
    getWelcomeDisplay(orgLists);
  }, [orgLists]);

  // const handleChange = (key, value) => {
  //   setFormData((formData) => ({
  //     ...formData,
  //     [key]: value,
  //   }));
  // };

  const handleImgChange = (e) => {
    setImage(e.target.files[0]);
    setDisplayImage(URL.createObjectURL(e.target.files[0]));
  };

  const handleSubmit = () => {
    setValidateLoader(true);
    let form_data = new FormData();
    image ? form_data.append("orgLogo", image, image.name) : form_data.append("orgLogo", formData.orgLogo);
    form_data.append("orgId", formData.orgId);
    createWelcomeMessage(form_data).then((response) => {
      if (response.status) {
        setValidateLoader(false);
        props.handleUpdateForm(response.orgTitleDetaisl);
        toastFlashMessage("Welcome message updated succesfully", "success");
      } else {
        setValidateLoader(false);
        toastFlashMessage("Something went wrong while updating welcome message", "error");
      }
    });
    // form_data.append("title", formData.title);
    //form_data.append("body", formData.body);
    // if (formData.title == "" && formData.body == "" && image == "") {
    //   setFieldError(true);
    // } else {
    //   createWelcomeMessage(form_data).then((response) => {
    //     if (response.status) {
    //       setValidateLoader(false);
    //       props.handleUpdateForm(response.orgTitleDetaisl);
    //       toastFlashMessage("Welcome message updated succesfully", "success");
    //     } else {
    //       setValidateLoader(false);
    //       toastFlashMessage("Something went wrong while updating welcome message", "error");
    //     }
    //   });
    // }
  };

  const handleFocus = () => {
    setFieldError(false);
  };

  const displayOrgWallpaper = () => {
    if (displayImage == imageBasePath + "profile_placeholder.svg") {
      return formData.orgLogo ? formData.orgLogo : imageBasePath + "profile_placeholder.svg";
    } else {
      return displayImage;
    }
  };

  return (
    <div className={styles.welcomeDisplayWrapper}>
      <div className={styles.addWallpaperFrameWrapper}>
        <div className={styles.addWallpaperFrameImage}>
          <img src={imageBasePath + "wallpaper_frame.png"} alt="" />
          {orgLists?.orgWallpaper !== null && orgLists?.orgWallpaper.includes(".mp4") ? (
                      <video
                      src={orgLists?.orgWallpaper}
                      alt=""
                      className={styles.frameUpdatedPicture}
                      autoPlay
                      muted
                      loop
                    />
                    ):(
                      <img
                        src={
                          orgLists?.orgWallpaper
                            ? orgLists?.orgWallpaper
                            : imageBasePath + "welcome_display_default.png"
                        }
                        alt=""
                        className={styles.frameUpdatedPicture}
                      />
                    )}
          <div className={styles.imageOverlay}>
            <img
              src={formData.orgLogo ? formData.orgLogo : imageBasePath + "company_logo_demo.svg"}
              alt=""
              style={{ width: "25px" }}
            />
            {/* <p className={styles.overlayTitle + " paragraph"}>
              {formData.title ? formData.title : "Welcome to Cisco"}
            </p>
            <p className={styles.overlaySubTitle + " label"}>
              {formData.body
                ? formData.body
                : `Cisco's networking solutions connect people, computing devices and computer networks, allowing
              people to access or transfer information.`}
            </p> */}
          </div>
        </div>
        <div className={styles.addWallpaperConfigWrapper}>
          <p className={styles.ConfigTitle + " label"}>Welcome Display</p>
          <p className={styles.ConfigSubTitle + " label"}>
            {/* Edit the default welcome message and <br /> */}
            Company logo that will be displayed on the all the <br />
            devices of an organisation
          </p>
        </div>
      </div>
      <div className={styles.displayUpdateWrapper}>
        <div className={styles.displayUpdateImage}>
          {fieldError && <h6 className="errorMsg">All the fields cannot be empty</h6>}
          <p className={styles.displayUpdateTitle + " label"}>Company Logo</p>
          <div className={styles.displayUpdateButton}>
            <img src={displayOrgWallpaper()} alt="" style={{ width: "20%" }} />
            <div className={styles.importButtonWrapper}>
              <CustomLink className={styles.importButton + " outlinedBtn "} text="Upload" />
              <input
                type="file"
                title=""
                accept="image/png, image/jpg, image/jpeg"
                className={styles.inputFile}
                onChange={handleImgChange}
                onFocus={handleFocus}
              />
            </div>
          </div>
        </div>
        {/* <div className={styles.displayTitleUpdateSection}>
          <InputText
            className={styles.displayBodyInput}
            label="Title"
            placeholder="Welcome to Cisco"
            value={formData.title || ""}
            onChange={(e) => handleChange("title", e.target.value)}
            onFocus={handleFocus}
          />
        </div>
        <div className={styles.displayBodyUpdateSection}>
          <InputText
            className={styles.displayBodyInput}
            label="Body"
            placeholder="Cisco's networking solutions connect people, computing devices and computer networks, allowing
              people to access or transfer information"
            value={formData?.body?.substring(0, 300) || ""}
            onChange={(e) => handleChange("body", e.target.value)}
            onFocus={handleFocus}
          />
          <p className={styles.displayBodyInputCounter + " label"}>
            <span>
              {formData?.body?.length > 300
                ? 0
                : formData?.body?.length > 0
                ? 300 - formData?.body?.length
                : 300}
            </span>
            /300
          </p>
        </div> */}
        <div className={styles.displayButtonUpdateSection}>
          <CustomButton
            children={validateLoader ? "Updating..." : "Update"}
            className={`${styles.updateBtn} primaryBtn ${validateLoader ? "disabledBtn" : ""}`}
            onClick={(e) => handleSubmit()}
          />
        </div>
      </div>
    </div>
  );
}
