import { globalPostService, globalGetService } from "../../utils/globalApiServices";

const GEO_LOCATION_URL_PREFIX = "https://letsving-web.s3.ap-south-1.amazonaws.com/letsving/geo";

export function timezone() {
  return new Promise((resolve, reject) => {
    globalGetService(`meta/timezone`).then((res) => {
      resolve(res)
    }).catch((err) => {
      reject(err)
    })
  })
}

export function getGeoLoaction(path) {
  const url = `${GEO_LOCATION_URL_PREFIX}${path}`
  return fetch(url)
    .then(response => {
      if (response.status === 200) {
        return response.json();
      }
      console.log(response);
      return [];
    })
    .then(data => {
      return data;
    });
}

export function createWorkspace(data = {}) {
  return new Promise((resolve, reject) => {
    globalPostService(`add-orglocations-details-v1`, data)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function getWorkspaceWallpaperApi(data = {}) {
  return new Promise((resolve, reject) => {
    globalPostService(`get-org-wallpapers-v1`, data)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function getWorkspaceListing(data = {}) {
  return new Promise((resolve, reject) => {
    globalPostService(`get-org-locations-v1`, data)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
export function editWorkspaceListing(data = {}) {
  return new Promise((resolve, reject) => {
    globalPostService(`update-orglocation-details-by-id-v1`, data)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
export function getSingleWorkspaceList(data = {}) {
  return new Promise((resolve, reject) => {
    globalPostService(`get-org-location-details-v1`, data)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function deleteWorkspaceListing(data = {}) {
  return new Promise((resolve, reject) => {
    globalPostService(`workspace-delete-v1`, data)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function getWorkspaceRoomDetails(data = {}) {
  return new Promise((resolve, reject) => {
    globalPostService(`get-room-details-by-worspace-v1`, data)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
export function addWorkspaceRoomDetails(data = {}) {
  return new Promise((resolve, reject) => {
    globalPostService(`add-room-details-v1`, data)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function updateWorkspaceRoomDetails(data = {}) {
  return new Promise((resolve, reject) => {
    globalPostService(`update-room-details-v1`, data)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function deactiveWorkspaceRoomDetails(data = {}) {
  return new Promise((resolve, reject) => {
    globalPostService(`deactivate-room-v1`, data)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
