import React, { useState } from "react";
import { Grid, Container } from "@material-ui/core";
import styles from "../../../assets/styles/auth.module.scss";
import { imageBasePath } from "../../../constants";
import PasswordReset from "../components/PasswordReset";
import ResetLinkSent from "../components/ResetLinkSent";

export default function ForgotPassword(props) {
  const [status, setStatus] = useState(false);
  const [emailId, setEmailId] = useState("");

  const handleChangeStatus = (email) => {
    setStatus(!status);
    setEmailId(email);
  };

  return (
    <div className={styles.forgotPassword + " flexCentered"}>
      <Container maxWidth={"lg"}>
        <div className={styles.LogoWrapper}>
          <img src={imageBasePath + "logo.svg"} alt="logo" />
        </div>

        {status ? (
          <div className={styles.forgotPasswordWrapper}>
            <Grid container direction="row" justifyContent="center" alignItems="center" spacing={2}>
              <Grid item md={6} xs={12}>
                <div className={styles.forgotPasswordLeftHeading}>
                  <h1 className={" heading4 "}>Thank You!</h1>
                  <p className={styles.leftSubHeading + " subheading "}>
                    Successfully sent the ‘Password Reset’ Link.
                  </p>
                </div>
              </Grid>
              <Grid item md={4} xs={12}>
                <ResetLinkSent emailId={emailId} />
              </Grid>
            </Grid>
          </div>
        ) : (
          <div className={styles.forgotPasswordWrapper}>
            <Grid container direction="row" justifyContent="center" alignItems="center" spacing={2}>
              <Grid item md={6} xs={12}>
                <div className={styles.forgotPasswordLeftHeading}>
                  <h1 className={" heading4 "}>Reset your Password</h1>
                  <p className={styles.leftSubHeading + " subheading "}>
                    Enter your registered email address. And we will send the ‘Password Reset’ link to the
                    provided email address.
                  </p>
                </div>
              </Grid>
              <Grid item md={4} xs={12}>
                <PasswordReset handleChangeStatus={handleChangeStatus} />
              </Grid>
            </Grid>
          </div>
        )}
      </Container>
    </div>
  );
}
