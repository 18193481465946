import React, { useState } from "react";
import { Container, Grid } from "@material-ui/core";
import { imageBasePath } from "../../../constants";
import styles from "../../../assets/styles/cart1.module.scss";
import { AddressForm } from "../../../shared_elements";
import { InputText, CustomButton, InputSelect } from "../../../shared_ui_components";

export default function ShippingDetail(props) {
  const { handleUpdateStep, step, handleChange, error, emailId } = props;
  return (
    <div className={styles.ShippingDetail}>
      <p className={styles.ShippingTitle + " heading6"}>Where do we deliver ?</p>
      <p className={styles.orderTitle1 + " subheading2"}>Contact information</p>
      <div className={styles.formWrapper}>
        <p className={styles.signInSection}>
          Already have an account? <span className={styles.signInLink}>Sign In</span>
        </p>
        <InputText
          label="Contact Email address"
          placeholder="eg, johndoe@example.com"
          error={error.email}
          value={emailId.trim()}
          onChange={(e) => {
            handleChange("email", e.target.value.trim());
          }}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              handleUpdateStep(step + 1);
            }
          }}
        />
      </div>
      <p className={styles.orderTitle2 + " subheading2"}>Shipping address</p>
      <AddressForm
        handleUpdateStep={handleUpdateStep}
        step={step}
        handleChange={handleChange}
        error={error}
      />
    </div>
  );
}
