import React from "react";
import { Chip } from "@material-ui/core";

const STYLES = {
  SUCCESS: {
    color: "rgb(21 128 61)",
    backgroundColor: "rgb(220 252 231)"
  },
  INPROGRESS: {
    color: "rgb(133 77 14)",
    backgroundColor: "rgb(254 249 195)"
  },
  FAILURE: {
    color: "rgb(185 28 28)",
    backgroundColor: "rgb(254 226 226)"
  },
  DEFAULT: {
    color: "rgb(75 85 99)",
    backgroundColor: "rgb(243 244 246)"
  }
};

const STATUS_STYLES = {
  success: STYLES.SUCCESS,
  created: STYLES.SUCCESS,
  started: STYLES.SUCCESS,
  inprogress: STYLES.INPROGRESS,
  failure: STYLES.FAILURE
};

const Message = ({ message }) => {
  const messageStyle = STATUS_STYLES[message.toLowerCase()] || STYLES.DEFAULT;
  return (
    <Chip
      size="small"
      label={message.charAt(0).toUpperCase() + message.slice(1)}
      style={{ ...messageStyle }}
    />
  );
};

export default Message;
