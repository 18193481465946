import React, { useState, useEffect } from "react";
import { Grid, Checkbox, FormControlLabel } from "@material-ui/core";
import styles from "../../../assets/styles/quotations.module.scss";
import { imageBasePath, errorCodes, reactSelectStylesLarge } from "../../../constants";
import { InputText, CustomButton, InputSelect, CustomLink } from "../../../shared_ui_components";
import { Visibility } from "@material-ui/icons";
import PropTypes from "prop-types";
import PhoneInput, { formatPhoneNumberIntl, parsePhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { fieldValidation } from "../../../utils/formValidation";
import { City, State } from "../../../utils/cityState";
import { toastFlashMessage } from "../../../utils";
import { getStore } from "../../../store/GlobalStore";
import { savePurchaseOrder, getQuotationOrderByOrgId } from "../apiServices";
import { useParams, useHistory } from "react-router-dom";

export default function AddressCard(props) {
  const { step, handleUpdateStep, allData, handleQuoteOrderFormData, setSubmitQuoteSuccess } = props;
  const [error, errorSet] = useState({});
  const [billingError, setBillingError] = useState({});
  const [cityOption, setCityOption] = useState([]);
  const [stateOption, setStateOption] = useState([]);
  const [checked, setChecked] = useState(false);
  const [editBillAddress, setEditBillAddress] = useState(false);
  const [purchaseOrderDetails, setPurchaseOrderDetails] = useState([]);
  const [billingFormData, setBillingFormData] = useState({});
  const [billingCityOption, setBillingCityOption] = useState([]);
  const [submitLoader, setSubmitLoader] = useState(false);
  const { id, _id } = useParams();
  const [formData, setFormData] = useState({
    fullName: "",
    contactNumber: "",
    address: "",
    stateName: "",
    cityName: "",
    pincode: "",
    shippingName: "",
    shippingContact: "",
    shippingAddress: "",
    shippingCityName: "",
    shippingStateName: "",
    shippingPincode: "",
  });

  useEffect(() => {
    let states = [];
    State.forEach((state) => states.push({ label: state, value: state }));
    setStateOption(states);
  }, []);

  const setCityList = (state, billingCity) => {
    const updatedCityList = City.filter((key) => key.state === state).map((city) => {
      return { label: city.city, value: city.city };
    });
    if (billingCity) {
      setBillingCityOption(updatedCityList);
    } else {
      setCityOption(updatedCityList);
    }
  };

  const handleCheck = (event) => {
    setChecked(!checked);
  };

  const handleEditBillAddress = (e) => {
    setEditBillAddress(!editBillAddress);
    setBillingCityOption([
      {
        label: allData?.quotationRequest?.billingAddress?.cityName,
        value: allData?.quotationRequest?.billingAddress?.cityName,
      },
    ]);
    setBillingFormData((form) => ({
      ...form,
      fullName: allData?.quotationRequest?.quotationReqDetails?.partnerId?.fullName,
      mobile: allData?.quotationRequest?.quotationReqDetails?.partnerId?.mobile,
      address: allData?.quotationRequest?.billingAddress?.address,
      stateName: allData?.quotationRequest?.billingAddress?.stateName,
      cityName: allData?.quotationRequest?.billingAddress?.cityName,
      pincode: allData?.quotationRequest?.billingAddress?.pincode,
    }));
  };

  const handleSubmitBillAddress = (e) => {
    e.preventDefault();
    const validateNewInput = {
      fullName:
        errorCodes["fullName"][
          fieldValidation({
            ...errorCodes["fullNameObj"],
            fieldval: billingFormData.fullName,
          })
        ],
      mobile:
        errorCodes["mobile"][
          fieldValidation({
            ...errorCodes["mobileObj"],
            fieldval: billingFormData.mobile,
          })
        ],
      address:
        errorCodes["address"][
          fieldValidation({
            ...errorCodes["addressObj"],
            fieldval: billingFormData.address,
          })
        ],
      cityName:
        errorCodes["cityName"][
          fieldValidation({
            ...errorCodes["cityNameObj"],
            fieldval: billingFormData.cityName,
          })
        ],
      stateName:
        errorCodes["stateName"][
          fieldValidation({
            ...errorCodes["stateNameObj"],
            fieldval: billingFormData.stateName,
          })
        ],
      pincode:
        errorCodes["pincode"][
          fieldValidation({
            ...errorCodes["pincodeObj"],
            fieldval: billingFormData.pincode,
          })
        ],
    };
    if (Object.keys(validateNewInput).every((k) => validateNewInput[k] == "")) {
      setEditBillAddress(!editBillAddress);
    } else {
      setBillingError(validateNewInput);
    }
  };

  const handleQuotationSubmit = (e) => {
    e.preventDefault();
    if (checked) {
      setSubmitQuoteSuccess(true);
    } else {
      const form_Data = new FormData();
      form_Data.append("pdfDocument", allData.pdfFile);
      form_Data.append("amountPaid", 0); //hardcoded for now
      form_Data.append("units", allData.quotationRequest.Quantity);
      form_Data.append("subscriptionPeriod", 12); //hardcoded for now
      form_Data.append("orgId", allData.quotationRequest.quotationReqDetails.orgId);
      //form_Data.append("addressId", allData.quotationRequest.quotationReqDetails.partnerId.addressId[0]);
      form_Data.append("addressId", "6139ee19742d2b74fcc0a116"); //hardcoded for now
      //form_Data.append("fullName", formData.fullName);
      billingFormData.fullName
        ? form_Data.append("name", billingFormData.fullName)
        : form_Data.append("name", allData.quotationRequest.quotationReqDetails.partnerId.fullName);
      form_Data.append("poNumber", allData.poNumber);
      form_Data.append("deviceTypeId", allData.quotationRequest.quotationReqDetails.devicePriceId);
      //form_Data.append("contactNumber", formData.contactNumber);
      billingFormData.mobile
        ? form_Data.append("shippingContact", billingFormData.mobile)
        : form_Data.append("shippingContact", allData.quotationRequest.quotationReqDetails.partnerId.mobile);
      form_Data.append("contactNumber", formData.contactNumber);
      billingFormData.stateName
        ? form_Data.append("stateName", billingFormData.stateName)
        : form_Data.append("stateName", allData?.quotationRequest?.billingAddress?.stateName);
      //form_Data.append("stateName", allData?.quotationRequest?.billingAddress?.stateName);
      billingFormData.cityName
        ? form_Data.append("cityName", billingFormData.cityName)
        : form_Data.append("cityName", allData?.quotationRequest?.billingAddress?.cityName);
      //form_Data.append("cityName", allData?.quotationRequest?.billingAddress?.cityName);
      billingFormData.cityName
        ? form_Data.append("pincode", billingFormData.pincode)
        : form_Data.append("pincode", allData?.quotationRequest?.billingAddress?.pincode);
      //form_Data.append("pincode", allData?.quotationRequest?.billingAddress?.pincode);
      billingFormData.cityName
        ? form_Data.append("address", billingFormData.address)
        : form_Data.append("address", allData?.quotationRequest?.billingAddress?.address);
      //form_Data.append("address", allData?.quotationRequest?.billingAddress?.address);
      form_Data.append("shippingPincode", formData.pincode);
      form_Data.append("shippingCityName", formData.cityName);
      form_Data.append("shippingAddress", formData.address);
      form_Data.append("shippingStateName", formData.stateName);
      form_Data.append("email", allData.quotationRequest.quotationReqDetails.partnerId.email);
      const validateNewInput = {
        fullName:
          errorCodes["fullName"][
            fieldValidation({
              ...errorCodes["fullNameObj"],
              fieldval: formData.fullName,
            })
          ],
        contactNumber:
          errorCodes["contactNumber"][
            fieldValidation({
              ...errorCodes["contactNumberObj"],
              fieldval: formData.contactNumber,
            })
          ],
        address:
          errorCodes["address"][
            fieldValidation({
              ...errorCodes["addressObj"],
              fieldval: formData.address,
            })
          ],
        cityName:
          errorCodes["cityName"][
            fieldValidation({
              ...errorCodes["cityNameObj"],
              fieldval: formData.cityName,
            })
          ],
        stateName:
          errorCodes["stateName"][
            fieldValidation({
              ...errorCodes["stateNameObj"],
              fieldval: formData.stateName,
            })
          ],
        pincode:
          errorCodes["pincode"][
            fieldValidation({
              ...errorCodes["pincodeObj"],
              fieldval: formData.pincode,
            })
          ],
      };
      if (Object.keys(validateNewInput).every((k) => validateNewInput[k] == "")) {
        setSubmitLoader(true);
        savePurchaseOrder(form_Data).then((response) => {
          if (response.status) {
            toastFlashMessage("Order purchased successfully", "success");
            setSubmitQuoteSuccess(true);
            setSubmitLoader(false);
          } else {
            toastFlashMessage("Something went wrong while purchasing order", "error");
            setSubmitLoader(false);
          }
        });
      } else {
        errorSet(validateNewInput);
      }
    }
  };

  const handleChange = (key, value) => {
    setFormData((form) => ({
      ...form,
      [key]: value,
    }));
    errorSet((preError) => ({
      ...preError,
      [key]: "",
    }));
    if (key === "stateName") {
      setFormData((formData) => ({
        ...formData,
        [key]: value,
        cityName: null,
      }));
      errorSet((preError) => ({
        ...preError,
        [key]: "",
      }));
      setCityList(value);
    } else {
      setFormData((formData) => ({
        ...formData,
        [key]: value,
      }));
      errorSet((preError) => ({
        ...preError,
        [key]: "",
      }));
    }
  };

  const handleBillingChange = (key, value) => {
    setBillingFormData((form) => ({
      ...form,
      [key]: value,
    }));
    setBillingError((preError) => ({
      ...preError,
      [key]: "",
    }));
    if (key === "stateName") {
      setBillingFormData((formData) => ({
        ...formData,
        [key]: value,
        cityName: null,
      }));
      setBillingError((preError) => ({
        ...preError,
        [key]: "",
      }));
      setCityList(value, "billingCity");
    } else {
      setBillingFormData((formData) => ({
        ...formData,
        [key]: value,
      }));
      setBillingError((preError) => ({
        ...preError,
        [key]: "",
      }));
    }
  };

  return (
    <div>
      <form onSubmit={handleQuotationSubmit}>
        <div className={styles.ShippingDetail}>
          <p className={styles.ShippingTitle + " heading6"}>Where do we deliver ?</p>
          <div className={"flexCentered flexSpaceBetween"}>
            <p className={styles.orderTitle2 + " subheading2"}>Shipping address</p>
            {/* <FormControlLabel
            value="top"
            className={styles.checkBox + " paragraph"}
            control={<Checkbox color="primary"
              onChange={(e) =>
                handleCheck(e)
              } />}
            label="Same as billing address"
            labelPlacement="end"
          /> */}
          </div>
          {!checked ? (
            <div className={styles.ShippingForm}>
              <InputText
                label="Receiver name *"
                placeholder="Full name"
                // value={formData.fullName || ""}
                error={error.fullName}
                onChange={(e) => handleChange("fullName", e.target.value)}
              />
              <div className="inputGroup phoneNumberGroup">
                <label className={error.contactNumber ? "errorMsg" : " "}>Contact number *</label>
                <PhoneInput
                  countryCodeEditable={false}
                  disableCountryCode={true}
                  placeholder="Enter phone number"
                  defaultCountry="IN"
                  onChange={(e) => {
                    handleChange("contactNumber", e);
                  }}
                />
                {error.contactNumber ? <h6 className="errorMsg">{error.contactNumber}</h6> : null}
              </div>
              <InputText
                label="Address *"
                placeholder="Street address"
                error={error.address}
                onChange={(e) => handleChange("address", e.target.value)}
              />
              <Grid container spacing={4}>
                <Grid item md={6} xs={12}>
                  <InputSelect
                    closeMenuOnSelect={true}
                    isSearchable={true}
                    styles={reactSelectStylesLarge}
                    label={"State *"}
                    placeholder={"Select State"}
                    noOptionsMessage={() => "No matches found"}
                    options={stateOption}
                    error={error.stateName}
                    value={
                      formData.stateName ? stateOption.find((opt) => opt.value === formData.stateName) : null
                    }
                    onChange={(state) => handleChange("stateName", state && state.value)}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <InputSelect
                    closeMenuOnSelect={true}
                    isSearchable={true}
                    styles={reactSelectStylesLarge}
                    label={"City *"}
                    placeholder={"Select City"}
                    noOptionsMessage={() => "No matches found"}
                    options={cityOption}
                    error={error.cityName}
                    value={
                      formData.cityName ? cityOption.find((city) => city.value === formData.cityName) : null
                    }
                    onChange={(city) => handleChange("cityName", city && city.value)}
                  />
                </Grid>
              </Grid>
              <InputText
                type="number"
                label="Pincode *"
                placeholder="560102"
                onChange={(e) => handleChange("pincode", e.target.value)}
                error={error.pincode}
                //value={formData.pincode}
              />
            </div>
          ) : (
            <div className={styles.shippingCardWrapper}>
              <div className={styles.addressCardDetailsItem}>
                <Grid container direction="row">
                  <Grid item md={4}>
                    <div className={styles.addressCardDetailsLeft}>
                      <p className={styles.addressCardDetailsLeftText + " supportText "}>Name:</p>
                    </div>
                  </Grid>
                  <Grid item md={8}>
                    <div className={styles.addressCardDetailsRight}>
                      <p className={styles.addressCardDetailsRightText + " supportText "}>
                        {billingFormData.fullName
                          ? billingFormData.fullName
                          : allData?.quotationRequest?.quotationReqDetails?.partnerId?.fullName}
                      </p>
                    </div>
                  </Grid>
                </Grid>
              </div>
              <div className={styles.addressCardDetailsItem}>
                <Grid container direction="row">
                  <Grid item md={4}>
                    <div className={styles.addressCardDetailsLeft}>
                      <p className={styles.addressCardDetailsLeftText + " supportText "}>Contact Number:</p>
                    </div>
                  </Grid>
                  <Grid item md={8}>
                    <div className={styles.addressCardDetailsRight}>
                      <p className={styles.addressCardDetailsRightText + " supportText "}>
                        {billingFormData.mobile
                          ? billingFormData.mobile
                          : allData?.quotationRequest?.quotationReqDetails?.partnerId?.mobile}
                      </p>
                    </div>
                  </Grid>
                </Grid>
              </div>
              <div className={styles.addressCardDetailsItem}>
                <Grid container direction="row">
                  <Grid item md={4}>
                    <div className={styles.addressCardDetailsLeft}>
                      <p className={styles.addressCardDetailsLeftText + " supportText "}>Address:</p>
                    </div>
                  </Grid>
                  <Grid item md={8}>
                    <div className={styles.addressCardDetailsRight}>
                      <p className={styles.addressCardDetailsRightText + " supportText "}>
                        {billingFormData.address
                          ? billingFormData.address
                          : allData?.quotationRequest?.billingAddress?.address}
                        {<br />}
                        {billingFormData.cityName
                          ? billingFormData.cityName
                          : allData?.quotationRequest?.billingAddress?.cityName}
                        {<br />}
                        {billingFormData.stateName
                          ? billingFormData.stateName
                          : allData?.quotationRequest?.billingAddress?.stateName}
                        {<br />}
                      </p>
                    </div>
                  </Grid>
                </Grid>
              </div>
              <div className={styles.addressCardDetailsItem}>
                <Grid container direction="row">
                  <Grid item md={4}>
                    <div className={styles.addressCardDetailsLeft}>
                      <p className={styles.addressCardDetailsLeftText + " supportText "}>Pincode:</p>
                    </div>
                  </Grid>
                  <Grid item md={8}>
                    <div className={styles.addressCardDetailsRight}>
                      <p className={styles.addressCardDetailsRightText + " supportText "}>
                        {billingFormData.pincode
                          ? billingFormData.pincode
                          : allData?.quotationRequest?.billingAddress?.pincode}
                      </p>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </div>
          )}
        </div>
        <div className={styles.addressCardWrapper}>
          <div className={styles.addressCardHeadingWrapper}>
            <h3 className={styles.addressCardHeading + " subheading2 "}>Billing address</h3>
            {editBillAddress ? (
              <CustomButton
                children={"Done"}
                className={styles.editBtn + " outlinedBtn " + " small "}
                onClick={(e) => handleSubmitBillAddress(e)}
              />
            ) : (
              <CustomButton
                children={"Edit"}
                className={styles.editBtn + " outlinedBtn " + " small "}
                onClick={(e) => handleEditBillAddress(e)}
              />
            )}
          </div>
          {!editBillAddress ? (
            <div className={styles.addressCardDetailsItemWrapper}>
              <div className={styles.addressCardDetailsItem}>
                <Grid container direction="row">
                  <Grid item md={4}>
                    <div className={styles.addressCardDetailsLeft}>
                      <p className={styles.addressCardDetailsLeftText + " supportText "}>Name:</p>
                    </div>
                  </Grid>
                  <Grid item md={8}>
                    <div className={styles.addressCardDetailsRight}>
                      <p className={styles.addressCardDetailsRightText + " supportText "}>
                        {billingFormData.fullName
                          ? billingFormData.fullName
                          : allData?.quotationRequest?.quotationReqDetails?.partnerId?.fullName}
                      </p>
                    </div>
                  </Grid>
                </Grid>
              </div>
              <div className={styles.addressCardDetailsItem}>
                <Grid container direction="row">
                  <Grid item md={4}>
                    <div className={styles.addressCardDetailsLeft}>
                      <p className={styles.addressCardDetailsLeftText + " supportText "}>Contact Number:</p>
                    </div>
                  </Grid>
                  <Grid item md={8}>
                    <div className={styles.addressCardDetailsRight}>
                      <p className={styles.addressCardDetailsRightText + " supportText "}>
                        {billingFormData.mobile
                          ? billingFormData?.mobile
                          : allData?.quotationRequest?.quotationReqDetails?.partnerId?.mobile}
                      </p>
                    </div>
                  </Grid>
                </Grid>
              </div>
              <div className={styles.addressCardDetailsItem}>
                <Grid container direction="row">
                  <Grid item md={4}>
                    <div className={styles.addressCardDetailsLeft}>
                      <p className={styles.addressCardDetailsLeftText + " supportText "}>Address:</p>
                    </div>
                  </Grid>
                  <Grid item md={8}>
                    <div className={styles.addressCardDetailsRight}>
                      <p className={styles.addressCardDetailsRightText + " supportText "}>
                        {billingFormData.address
                          ? billingFormData.address
                          : allData?.quotationRequest?.billingAddress?.address}
                        {<br />}
                        {billingFormData.cityName
                          ? billingFormData.cityName
                          : allData?.quotationRequest?.billingAddress?.cityName}
                        {<br />}
                        {billingFormData.stateName
                          ? billingFormData.stateName
                          : allData?.quotationRequest?.billingAddress?.stateName}
                      </p>
                    </div>
                  </Grid>
                </Grid>
              </div>
              <div className={styles.addressCardDetailsItem}>
                <Grid container direction="row">
                  <Grid item md={4}>
                    <div className={styles.addressCardDetailsLeft}>
                      <p className={styles.addressCardDetailsLeftText + " supportText "}>Pincode:</p>
                    </div>
                  </Grid>
                  <Grid item md={8}>
                    <div className={styles.addressCardDetailsRight}>
                      <p className={styles.addressCardDetailsRightText + " supportText "}>
                        {billingFormData.pincode
                          ? billingFormData.pincode
                          : allData?.quotationRequest?.billingAddress?.pincode}
                      </p>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </div>
          ) : (
            <div className={styles.ShippingForm}>
              <InputText
                label="Receiver name *"
                placeholder="Full name"
                value={billingFormData.fullName || ""}
                error={billingError.fullName}
                onChange={(e) => handleBillingChange("fullName", e.target.value)}
              />
              <div className="inputGroup phoneNumberGroup">
                <label className={billingError.mobile ? "errorMsg" : " "}>Contact number *</label>
                <PhoneInput
                  countryCodeEditable={false}
                  disableCountryCode={true}
                  placeholder="Enter phone number"
                  value={billingFormData.mobile || ""}
                  defaultCountry="IN"
                  onChange={(e) => {
                    handleBillingChange("mobile", e);
                  }}
                />
                {billingError.mobile ? <h6 className="errorMsg">{billingError.mobile}</h6> : null}
              </div>
              <InputText
                label="Address *"
                placeholder="Street address"
                value={billingFormData.address || ""}
                error={billingError.address}
                onChange={(e) => handleBillingChange("address", e.target.value)}
              />
              <Grid container spacing={4}>
                <Grid item md={6} xs={12}>
                  <InputSelect
                    closeMenuOnSelect={true}
                    isSearchable={true}
                    label={"State *"}
                    placeholder={"Select State"}
                    noOptionsMessage={() => "No matches found"}
                    options={stateOption}
                    error={billingError.stateName}
                    value={
                      billingFormData.stateName
                        ? stateOption.find((opt) => opt.value === billingFormData.stateName)
                        : null
                    }
                    onChange={(state) => handleBillingChange("stateName", state && state.value)}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <InputSelect
                    closeMenuOnSelect={true}
                    isSearchable={true}
                    label={"City *"}
                    placeholder={"Select City"}
                    noOptionsMessage={() => "No matches found"}
                    options={billingCityOption}
                    error={billingError.cityName}
                    value={
                      billingFormData.cityName
                        ? billingCityOption.find((city) => city.value === billingFormData.cityName)
                        : null
                    }
                    onChange={(city) => handleBillingChange("cityName", city && city.value)}
                  />
                </Grid>
              </Grid>
              <InputText
                type="number"
                label="Pincode *"
                placeholder="560102"
                onChange={(e) => handleBillingChange("pincode", e.target.value)}
                error={billingError.pincode}
                value={billingFormData.pincode || ""}
              />
            </div>
          )}
        </div>
        <div className={styles.buttonSection}>
          <CustomButton
            className={`primaryBtn  ${styles.firstBtn} ${submitLoader ? "disabledBtn" : ""}`}
            //onClick={handleQuotationSubmit}
            children={submitLoader ? "Placing Order..." : "Place Order"}
            rightIcon={imageBasePath + "btn_arrow_right_icon.svg"}
            type="submit"
          />
          <CustomButton
            className={styles.backBtn + " outlinedBtn "}
            onClick={() => handleUpdateStep(step - 1)}
            children="back"
          />
        </div>
      </form>
    </div>
  );
}
