import React, { useEffect, useState } from 'react';
import Drawer from '@material-ui/core/Drawer';
import styles from "../../../../assets/styles/admin/enquiries.module.scss";
import { imageBasePath } from "../../../../constants";
import EnquiriesListDetail from "./EnquiriesListDetail";

export default function EnquiriesListDrawer(props) {
    const { open } = props;
    const [state, setState] = useState({
        right: false,
    });

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        props.handleClose(open);
        setState({ ...state, [anchor]: open });
    };
    useEffect(() => {
        setState({ ...state, ["right"]: open });
    }, [open]);

    return (
        <div>
            {['right'].map((anchor) => (
                <div key={anchor}>
                    {/* <Button onClick={toggleDrawer(anchor, true)}>{anchor}</Button> */}
                    <Drawer
                        anchor={anchor}
                        open={state[anchor]}
                        onClose={toggleDrawer(anchor, false)}
                    >
                        <div className={styles.enquiryDrawerDetailWrapper}>
                            <div className={styles.enquiryDrawerDetailTitle}>
                                <img 
                                    src={imageBasePath + "user_cancel.svg"} 
                                    alt="" 
                                    onClick={toggleDrawer(anchor, false)}
                                />
                                <p className={styles.enquiryDrawerDetailHeader + " subheading"}>Enquiry Details</p>
                            </div>
                            <EnquiriesListDetail />
                        </div>
                    </Drawer>
                </div>
            ))}
        </div>
    )
}
