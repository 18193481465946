import React from "react";
import styles from "../../../assets/styles/home.module.scss";

export default function HomeHeader(props) {
  return (
    <div className={styles.homeHeaderWrapper}>
      <div className={styles.homePageTitleWrapper}>
        <h2 className={styles.homePageTitle + " subheading "}>Home</h2>
      </div>
    </div>
  );
}
