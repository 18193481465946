import "date-fns";
import React,{ useState } from "react";
import MomentUtils from "@date-io/moment";
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from "@material-ui/pickers";
import { TextField } from "@material-ui/core";
import { imageBasePath } from "../constants";
export default function CustomDatePicker(props) {
    const [open, setOpen] = useState(false);
    
    return (
        <div className="customDatePicker">
            <div className={`inputGroup ${props.noMargin ? "noMargin" : ""}`}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardDatePicker
                        open={open}
                        onOpen={() => setOpen(true)}
                        onClose={() => setOpen(false)}
                        TextFieldComponent={(props) => (
                            <TextField {...props} onClick={(e) => setOpen(true)} />
                        )}
                        disableToolbar
                        disablePast
                        format="MM/DD/yyyy"
                        margin="normal"
                        id="date-picker-inline"
                        label="Date picker inline"
                        KeyboardButtonProps={{
                            "aria-label": "change date",
                        }}
                        keyboardIcon={<img src={imageBasePath + "calendar-icon.svg"} alt=""/>}
                        {...props}
                    />
                </MuiPickersUtilsProvider>
            </div>
        </div>
    );
}
