import React from "react";
import styles from "../../../assets/styles/activityLog.module.scss";

export default function ActivityLogHeader(props) {
  return (
    <div className={styles.activityLogHeaderWrapper}>
      <div className={styles.activityLogPageTitleWrapper}>
        <h2 className={styles.activityLogPageTitle + " subheading "}>
          Activity Log
        </h2>
      </div>
    </div>
  );
}
