import React, { useState, useEffect } from "react";
import { Grid, List, ListItem } from "@material-ui/core";
import styles from "../../../assets/styles/quotations.module.scss";
import { imageBasePath } from "../../../constants";
import { currencyFormatter } from "../../../utils";

export default function OrderSummary(props) {
  const { allData } = props;

  return (
    <>
      <div className={styles.orderSummaryWrapper}>
        <div className={styles.orderSummaryCard}>
          <div className={styles.cardHeader}>
            <h2 className={styles.orderSummaryHeading + " subheading "}>Order Summary</h2>
          </div>
          <div className={styles.cardBody}>
            <div className={styles.orderSummaryBlk}>
              <Grid container direction="row" spacing={2}>
                <Grid item md={3} xs={6}>
                  <img src={imageBasePath + "order_set_top_box.svg"} alt="" />
                </Grid>
                <Grid item md={9} xs={6}>
                  <Grid container direction="row" spacing={2}>
                    <Grid item md={6} xs={12}>
                      <div className={styles.orderSummaryBlkDetails}>
                        <p className={styles.orderSummaryQuantity + " supportText "}>Quantity</p>
                        <p className={styles.orderSummaryQuantityCount + " supportText "}>
                          {allData.quotationRequest.Quantity}{" "}
                          <span>{allData.quotationRequest.Quantity > 1 ? "items" : "item"}</span>
                        </p>
                      </div>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <div className={styles.orderSummaryBlkDetails}>
                        <p className={styles.orderSummarySubscription + " supportText "}>
                          Subscription period
                        </p>
                        <p className={styles.orderSummarySubscriptionPeriod + " supportText "}>
                          {allData.quotationRequest.subscriptionPeriod / 12}{" "}
                          <span>
                            {allData.quotationRequest.subscriptionPeriod / 12 > 1 ? "years" : "year"}
                          </span>
                        </p>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>

            <div className={styles.orderSummaryListWrapper}>
              <div className={styles.orderSummaryListOuterBlk}>
                <Grid container direction="row" spacing={2}>
                  <Grid item md={12} xs={12}>
                    <div className={styles.orderSummaryListInnerlk}>
                      <Grid container direction="row" spacing={2}>
                        <Grid item md={8} xs={7}>
                          <div className={styles.orderSummaryDescription}>
                            <p className="supportText">Cost of Ving Box</p>
                          </div>
                        </Grid>
                        <Grid item md={4} xs={5}>
                          <div className={styles.orderSummaryPrice}>
                            <p className="supportText">
                              ₹ {currencyFormatter(allData.quotationRequest.costOfVingBoxes)}
                            </p>
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                    <div className={styles.orderSummaryListInnerlk}>
                      <Grid container direction="row" spacing={2}>
                        <Grid item md={8} xs={7}>
                          <div className={styles.orderSummaryDescription}>
                            <p className="supportText">Subscription Fee</p>
                          </div>
                        </Grid>
                        <Grid item md={4} xs={5}>
                          <div className={styles.orderSummaryPrice}>
                            <p className="supportText">
                              ₹ {currencyFormatter(allData.quotationRequest.subscFee)}
                            </p>
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                    {/* <div className={styles.orderSummaryListInnerlk}>
                      <Grid container direction="row" spacing={2}>
                        <Grid item md={8} xs={7}>
                          <div className={styles.orderSummaryDescription}>
                            <p className="supportText">Sub total</p>
                          </div>
                        </Grid>
                        <Grid item md={4} xs={5}>
                          <div className={styles.orderSummaryPrice}>
                            <p className="supportText">
                              ₹ {currencyFormatter(allData.quotationRequest.SubTotalAmount)}
                            </p>
                          </div>
                        </Grid>
                      </Grid>
                    </div> */}
                    <div className={styles.orderSummaryListInnerlk}>
                      <Grid container direction="row" spacing={2}>
                        <Grid item md={8} xs={6}>
                          <div className={styles.orderSummaryDescription}>
                            <p className="supportText">Discount</p>
                          </div>
                        </Grid>
                        <Grid item md={4} xs={6}>
                          <div className={styles.orderSummaryPrice}>
                            <p className="supportText">
                              <span className={styles.discountLabel}>
                                {allData.quotationRequest.percentageOffered}%
                              </span>
                              - ₹{currencyFormatter(allData.quotationRequest.discountAmount)}
                            </p>
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                    <div className={styles.orderSummaryListInnerlk}>
                      <Grid container direction="row" spacing={2}>
                        <Grid item md={8} xs={7}>
                          <div className={styles.orderSummaryDescription}>
                            <p className="supportText">Tax</p>
                          </div>
                        </Grid>
                        <Grid item md={4} xs={5}>
                          <div className={styles.orderSummaryPrice}>
                            <p className="supportText">
                              ₹ {currencyFormatter(allData.quotationRequest.taxAmount)}
                            </p>
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                  </Grid>
                </Grid>
              </div>
              <div className={styles.orderSummaryTotalBlk}>
                <Grid container direction="row">
                  <Grid item md={8} xs={7}>
                    <div className={styles.orderSummaryTotalText}>
                      <p className="paragraphSemiBold">Total</p>
                    </div>
                  </Grid>
                  <Grid item md={4} xs={5}>
                    <div className={styles.orderSummaryTotalPrice}>
                      <p className="subheading">₹ {currencyFormatter(allData.quotationRequest.TotalPrice)}</p>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
