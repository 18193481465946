import React, { useState } from "react";
import { Grid, List, ListItem, Menu, MenuItem } from "@material-ui/core";
import { imageBasePath } from "../../../constants";
import styles from "../../../assets/styles/dashboard.module.scss";
import { Link, useHistory } from "react-router-dom";
import { CustomButton } from "../../../shared_elements"

export default function DashboardContent() {
    return (
        <main>
            <div>
                <div className={styles.dashboardHomeWrapper}>
                    <div>
                        <h5 className={styles.contentHeading+" heading5"}>Overview</h5>
                    </div>
                    <div className={styles.dashboardHomeContentWrapper}>
                        <div>
                            <p>Overview display section</p>
                        </div>
                    </div>

                </div>
            </div>
        </main>
    )
}
