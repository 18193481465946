import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Container, Grid } from "@material-ui/core";
import { imageBasePath } from "../../../constants";
import styles from "../../../assets/styles/cart.module.scss";
import { CustomButton } from "../../../shared_ui_components";
export default function OrderStatus(props) {
  const { status } = props;
  const history = useHistory();

  const goToHome = () => {
    history.push("/");
  };

  return (
    <div className={styles.orderStatusBlk}>
      <Grid container direction="row" spacing={2}>
        {status.status === "success" ? (
          <Grid item md={4}>
            <div className={styles.orderStatus + " " + styles.orderConfirm}>
              <div className={styles.statusHeader}>
                <img src={imageBasePath + "order_confirm.svg"} />
              </div>
              <div className={styles.statusBody}>
                <h2 className={styles.statusTitle + " heading6 "}>Order confirmed!</h2>
                <p className={styles.statusSubtext + " paragraph "}>
                  {/* Thank you! Your order <span>#{status?.statusDetail?.orderId}</span> was successfully placed. */}
                  Thank you! Your order <span>#12345</span> was successfully placed.
                </p>
                <div className={styles.statusActionWrapper}>
                  {/* <CustomButton
                      className={styles.actionBtn + " primaryBtn "}
                      children="Track your order"
                    /> */}
                  <CustomButton
                    onClick={() => goToHome()}
                    className={styles.backBtn + " linkBtn "}
                    children="Back to home"
                  />
                </div>
              </div>
            </div>
          </Grid>
        ) : (
          <Grid item md={4}>
            <div className={styles.orderStatus + " " + styles.paymentFailed}>
              <div className={styles.statusHeader}>
                <img src={imageBasePath + "payment_failed.svg"} />
              </div>
              <div className={styles.statusBody}>
                <h2 className={styles.statusTitle + " heading6 "}>Payment Failed!</h2>
                <p className={styles.statusSubtext + " paragraph "}>
                  Your transaction has failed due to some technical error. Please try again.
                </p>
                <div className={styles.statusActionWrapper}>
                  <CustomButton
                    onClick={() => goToHome()}
                    className={styles.actionBtn + " primaryBtn "}
                    children="Back to store"
                  />
                </div>
              </div>
            </div>
          </Grid>
        )}

        {/* <Grid item md={4}>
          <div className={styles.orderStatus + " " + styles.connectionError}>
            <div className={styles.statusHeader}>
              <img
                src={imageBasePath + "connection_error.svg"}
                className={styles.connectionErrorImg}
              />
            </div>
            <div className={styles.statusBody}>
              <h2 className={styles.statusTitle + " heading6 "}>Oh snap!</h2>
              <p className={styles.statusSubtext + " paragraph "}>
                Something went wrong with your connection. Please try again.
              </p>
              <div className={styles.statusActionWrapper}>
                <CustomButton
                  className={styles.actionBtn + " primaryBtn "}
                  children="Retry"
                />
              </div>
            </div>
          </div>
        </Grid> */}
      </Grid>
    </div>
  );
}
