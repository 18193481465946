import React from "react";
import { Grid, List, ListItem } from "@material-ui/core";
import styles from "../../../assets/styles/cart1.module.scss";
import { imageBasePath } from "../../../constants";
import { InputText, CustomButton, InputSelect } from "../../../shared_ui_components";
import { Visibility } from "@material-ui/icons";

export default function ShippingAddressCard(props) {
  const { billingFlag, cartDetails } = props;
  return (
    <div>
      <div className="orderSummaryShippingAddressWrapper ">
        {billingFlag ? null : (
          <div className="shippingAddressHeadingWrapper">
            <h3 className=" shippingAddressHeading subheading2 ">Shipping address</h3>
            <CustomButton children="Edit" className="editBtn outlinedBtn small " />
          </div>
        )}
        <div className="shippingAddressDetailsItemWrapper">
          <div className="shippingAddressDetailsItem">
            <Grid container direction="row">
              <Grid item md={4}>
                <div className="shippingDetailsLeft">
                  <p className="shippingDetailsLeftText supportText ">Name:</p>
                </div>
              </Grid>
              <Grid item md={8}>
                <div className="shippingDetailsRight">
                  <p className={"shippingDetailsRightText supportText "}>{cartDetails.shippingName}</p>
                </div>
              </Grid>
            </Grid>
          </div>
          <div className="shippingAddressDetailsItem">
            <Grid container direction="row">
              <Grid item md={4}>
                <div className="shippingDetailsLeft">
                  <p className="shippingDetailsLeftText supportText ">Contact Number:</p>
                </div>
              </Grid>
              <Grid item md={8}>
                <div className="shippingDetailsRight">
                  <p className={"shippingDetailsRightText supportText "}>{cartDetails.shippingContact}</p>
                </div>
              </Grid>
            </Grid>
          </div>
          <div className="shippingAddressDetailsItem">
            <Grid container direction="row">
              <Grid item md={4}>
                <div className="shippingDetailsLeft">
                  <p className="shippingDetailsLeftText supportText ">GST number:</p>
                </div>
              </Grid>
              <Grid item md={8}>
                <div className="shippingDetailsRight">
                  <p className={"shippingDetailsRightText supportText "}>
                    {cartDetails.gstNumber ? cartDetails.gstNumber : "-"}
                  </p>
                </div>
              </Grid>
            </Grid>
          </div>
          <div className="shippingAddressDetailsItem">
            <Grid container direction="row">
              <Grid item md={4}>
                <div className="shippingDetailsLeft">
                  <p className="shippingDetailsLeftText supportText ">Address:</p>
                </div>
              </Grid>
              <Grid item md={8}>
                <div className="shippingDetailsRight">
                  <p className="shippingDetailsRightText paragraph ">
                    {cartDetails.shippingAddress}, {cartDetails.shippingCityName},{" "}
                    {cartDetails.shippingStateName}
                  </p>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    </div>
  );
}
