import { globalPostService, globalGetServiceNoAuth } from "../../utils/globalApiServices";

export function signInApi(query = {}) {
  return new Promise((resolve, reject) => {
    globalPostService(`user-signin-v1`, query)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function forgetPasswordApi(query = {}) {
  query.host = 'https://letsving.com'
  console.log(query)
  return new Promise((resolve, reject) => {
    globalPostService(`forget-password-v1`, query)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function socialSignUpApi(query = {}) {
  return new Promise((resolve, reject) => {
    globalPostService(`socialSignup-v1`, query)
    .then((response) => {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
  });
}
export function updatePassword(query = {}) {
  return new Promise((resolve, reject) => {
    globalPostService(`update-new-password-v1`, query, {})
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function verifyTokeApi(headers = {}) {
  return new Promise((resolve, reject) => {
    globalGetServiceNoAuth(`verify-user-v1`, headers)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}