import React from 'react';
import { Grid, List, ListItem } from "@material-ui/core";
import styles from "../../../../assets/styles/admin/enquiries.module.scss";
import { imageBasePath } from "../../../../constants";
import { CustomButton, CustomLink } from "../../../../shared_ui_components";
import { Link, useLocation,useHistory} from "react-router-dom";

export default function EnquiriesListDetail(props) {
    const history = useHistory();
    let location = useLocation();

    const goToReviewQuote = () => {
        history.push("/review-quote");
    };
    return (
        <Grid container justifyContent="center" alignItems="center">
            <Grid item md={12}>
                <div className={styles.enquiryDetailWrapper}>
                    <div className={styles.enquiryDetailBlk}>
                        <div className={styles.enquiryLeftBlk}>
                            <div className={styles.enquiryImageWrapper}>
                                <img
                                    src={imageBasePath + "enquiry-box.svg"}
                                    className={styles.enquiryBox}
                                    alt=""
                                />
                                <img
                                    src={
                                        imageBasePath + "enquiry_open_status.svg"
                                    }
                                    className={styles.enquiryBoxStatus}
                                    alt=""
                                />
                            </div>
                            <div className={styles.enquiryNumberWrapper}>
                                <p
                                    className={styles.enquiryDrawerDetailHeader + " label "}
                                >
                                    Enquiry no:
                                </p>
                                <p
                                    className={styles.enquiryNumber + " paragraphSemiBold "}
                                >
                                    #168787
                                </p>
                            </div>
                        </div>
                        <div className={styles.enquiryRightBlk}>
                            <p
                                className={
                                    styles.enquiryStatusOpenTitle + " label "
                                }
                            >
                                Status:
                            </p>
                            <div className={styles.enquiryRightStatusWrapper}>
                                <img
                                    src={
                                        imageBasePath + "enquiry_open.svg" /* : "enquiry_close.svg" */
                                    }
                                    alt=""
                                />
                                <p
                                    className={
                                        styles.enquiryStatusOpen + " paragraph " /* styles.enquiryStatusClose */
                                    }
                                >
                                    Open
                                </p>
                            </div>
                        </div>
                    </div>
                    <Grid container direction="row" spacing={2} justifyContent="center" alignItems="flex-start">
                        <Grid item md={4}>
                            <List className={styles.enquiryDetailTitles}>
                                <ListItem>
                                    <p>Received on</p>
                                </ListItem>
                                <ListItem>
                                    <p>Email ID</p>
                                </ListItem>
                                <ListItem>
                                    <p>Contact Number</p>
                                </ListItem>
                            </List>
                        </Grid>
                        <Grid item md={8}>
                            <List className={styles.enquiryDetailDescriptions}>
                                <ListItem>
                                    <p>23/02/2021</p>
                                </ListItem>
                                <ListItem>
                                    <p>mohitpal1782@email.com</p>
                                </ListItem>
                                <ListItem>
                                    <p>+91 98320 83290</p>
                                </ListItem>
                            </List>
                        </Grid>
                    </Grid>
                    <div className={styles.enquiryNoteWrapper}>
                        <div>
                            <p
                                className={
                                    styles.enquiryNoteTitle + " label "
                                }
                            >
                                Note
                            </p>
                        </div>
                        <div className={styles.enquiryNoteDetail}>
                            <p>Hello,</p>
                            <p className={styles.enquiryNoteMessage}>I am interested in buying 100 products.We are business based out of Pune. Our company is called Dunder & Mifflin Paper Company. Looking forward to hearing from your team.</p>
                            <p>Thanks</p>
                            <p>Mohit Jai</p>
                            <p>Regional Manager @ Dunder Mifflin Paper Company</p>
                        </div>
                    </div>
                </div>
                <div className={styles.enquiryButtonWrapper}>
                    <Grid container justifyContent="flex-end" alignItems="center">
                        <Grid item md={6}>
                            <CustomButton
                                onClick={() => goToReviewQuote()}
                            children="Generate Quote"
                            className={
                                styles.generateEnquiryBtn + " primaryBtn "
                            }
                            />
                        </Grid>
                    </Grid>
                </div>
            </Grid>
        </Grid>
    )
}
