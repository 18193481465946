import React from 'react';
import { Redirect } from 'react-router-dom';
import { enableWebsiteContent } from '../../../constants';
import {HeroSection, PlaceOrder, VideoSection, GetSection, Carousel, BoxSupport} from '../components';

function Purchase(props) {
    return enableWebsiteContent ? (
        <div>
            <HeroSection />
            <PlaceOrder />
            <VideoSection />
            <GetSection />
            {/* <Carousel /> */}
            <BoxSupport />
        </div>
    ) : (
        <Redirect
          to={{
            pathname: "/home",
          }}
        />);
}

export default Purchase;