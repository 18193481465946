import React, { useState } from "react";
import { Grid, List, ListItem, Menu, MenuItem } from "@material-ui/core";
import styles from "../../../assets/styles/workspaces.module.scss";
import { imageBasePath } from "../../../constants";
import { deleteWorkspaceListing } from "../apiServices";
import { useHistory } from "react-router-dom";
import { toastFlashMessage, getUserInfo } from "../../../utils";
import { useParams } from "react-router";
import { Tooltip, Dialog } from "@material-ui/core";
import AddWorkspaceDialog from "./AddWorkspaceDialog";
import useTimezone from "../../../utils/useTimezone";

function WorkspaceDetailsCard(props) {
  const history = useHistory();
  const { id, _id } = useParams();
  const { workspaceRooms, workspaceDetails, workspaceLocalAdmins, workspaceName, workspaceWallpaper, timeZone, temperatureType } = props;
  //console.log(workspaceDetails, "details");
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState(1);
  const [editWorkspace, setEditWorkspace] = useState(null);

  const timezoneStatus = useTimezone();
  const timezoneKeys = timezoneStatus?.data?.labels || {};
  const timezoneLabel = timezoneKeys[timeZone] || timeZone;


  const data = workspaceLocalAdmins?.map((item) => item?.userId?.email);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
    setEditWorkspace(null);
  };

  const handleEditWorkspace = () => {
    setEditWorkspace({
      workspaceDetails: workspaceDetails,
      workspaceLocalAdmins: workspaceLocalAdmins,
      workspaceName: workspaceName,
      workspaceWallpaper: workspaceWallpaper,
      workspaceTimezone:timeZone,
      temperatureType: temperatureType
    });
    setOpen(true);
    setSelectedTab(1);
    setAnchorEl(null);
  };
  const handleCloseWorkSpaceDrawer = () => {
    setOpen(false);
    props.getUpdatedWorkspace();
  };

  const handleDeleteWorkspace = () => {
    // alert(_id);
    deleteWorkspaceListing({ workspaceId: _id }).then((response) => {
      if (response.status) {
        history.push(`/${id}/workspaces`);
        toastFlashMessage("Workspace deleted successfully", "success");
      } else {
        toastFlashMessage("Something went wrong while deleting workspace", "error");
      }
    });

    setAnchorEl(null);
  };

  const userInfo = getUserInfo();

  return (
    <>
      <div className={styles.workspaceDetailsCard}>
        <Grid container>
          <Grid item md={6}>
            <Grid container spacing={5}>
              <Grid item md={6}>
                <div className={styles.workspaceDetailsIconWrapper}>
                  <img src={imageBasePath + "workspace_icon.svg"} />
                  <div className={styles.workspaceDetailsTextWrapper}>
                    <h3 className={styles.workspaceName + " paragraph "}>{workspaceName}</h3>
                    <p className={styles.workspaceAddress + " supportText "}>
                      {workspaceDetails?.address}, {workspaceDetails?.cityName}
                      {", "}
                      {workspaceDetails?.stateName}, {workspaceDetails?.pincode}
                    </p>
                  </div>
                </div>
              </Grid>
              <Grid item md={4}>
                <div>
                  <p className={styles.timezoneText + " paragraph "}>
                    Timezone
                  </p>
                  <p className={styles.timezoneValue + " supportText "}>{timezoneLabel}</p>
                </div>
              </Grid>

              <Grid item md={2}>
                <p className={styles.workspaceRoomText + " supportText "}>
                  {workspaceRooms > 1 ? "Rooms" : "Room"}
                </p>
                <p className={styles.workspaceRoomCount + " paragraph "}>{workspaceRooms}</p>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={6}>
            <Grid container spacing={4}>
              <Grid item md={7}>
                <div className={styles.workspaceLocalAdminWrapper}>
                  <p className={styles.workspaceLocalAdminText + " supportText "}>Local Admins</p>
                  <List>
                    {data?.length === 0 ? (
                      "..."
                    ) : data?.length < 3 ? (
                      data?.map((admin) => {
                        return (
                          <ListItem className="supportText">
                            <img src={imageBasePath + "workspace_user_icon.svg"} />
                            {admin}
                          </ListItem>
                        );
                      })
                    ) : (
                      <>
                        {data
                          ?.map((admin) => (
                            <ListItem className="supportText">
                              <img src={imageBasePath + "workspace_user_icon.svg"} />
                              {admin}
                            </ListItem>
                          ))
                          .slice(0, 2)}

                        <Tooltip
                          title={data
                            ?.map((admin) => admin)
                            .slice(2, data.length)
                            .join(", ")}
                          placement="top-start"
                        >
                          <span className={styles.moreList}> +{data?.length - 2} more</span>
                        </Tooltip>
                      </>
                    )}
                  </List>
                </div>
              </Grid>
              <Grid item md={5}>
                <div className={styles.defaultWallpaperWrapper}>
                  <p className={styles.workspaceWallpaperText + " supportText "}>Default Wallpaper</p>
                  <img
                    src={workspaceWallpaper ? workspaceWallpaper : imageBasePath + "workspace_wallpaper.svg"}
                    className={styles.workspaceWallpaper}
                  />
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <div className={styles.workspaceDetailsMoreOption}>
          <img
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={handleClick}
            src={imageBasePath + "more_vertical_icon.svg"}
          />
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            {userInfo.userData?.role?.responsibilities?.updateWorkspace ? (
              <MenuItem onClick={() => handleEditWorkspace(workspaceDetails?._id)}>Edit</MenuItem>
            ) : null}
            <MenuItem onClick={() => handleDeleteWorkspace(workspaceDetails?._id)}>Delete</MenuItem>
          </Menu>
        </div>
      </div>
      {open && (
        <Dialog open={open} handleClose={handleClose}>
          <AddWorkspaceDialog
            editWorkspace={editWorkspace}
            handleClose={handleClose}
            handleCloseWorkSpaceDrawer={handleCloseWorkSpaceDrawer}
          />
        </Dialog>
      )}
    </>
  );
}

export default WorkspaceDetailsCard;
