import Resources from "./container/Resources";
import ResourceInfo from "./container/ResourcesInfo";
import QuotationLayoutHoc from "../../hoc/quotationLayoutHoc";
export const resourcessRoutes = [
  {
    path: "/:id/resources",
    component: QuotationLayoutHoc(Resources),
    key: "resources",
  },
  {
    path: "/:id/resources/:_id",
    component: QuotationLayoutHoc(ResourceInfo),
    key: "resources-info",
  },
];
