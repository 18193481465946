import { createContext, useState, useReducer } from "react";
import produce from "immer";
import { saveStore } from "../store/GlobalStore";

export const GlobalContext = createContext(); // central context store

const initialState = {
  toastLists: [],
  device: {},
  orgListNames: [],
  loading: true,
  noState: false,
};

function reducer(state, action) {
  switch (action.type) {
    case "SHOW_TOAST":
      if (action.payload.toastType === "error") {
        if (state.toastLists.filter((toast) => toast.toastType === "error").length < 1) {
          return {
            ...state,
            toastLists: produce(state.toastLists, (draft) => {
              draft.push(action.payload);
            }),
          };
        } else {
          return state;
        }
      } else {
        return {
          ...state,
          toastLists: produce(state.toastLists, (draft) => {
            draft.push(action.payload);
          }),
        };
      }
    case "CLEAR_TOAST":
      return {
        ...state,
        toastLists: [],
      };
    case "HIDE_TOAST":
      if (state.toastLists) {
        return {
          ...state,
          toastLists: [],
        };
      } else {
        return state;
      }
    case "SET_DEVICE":
      return {
        ...state,
        device: action.payload,
      };
    case "SET_ORGANISATION_NAME":
      return {
        ...state,
        orgListNames: action.payload,
        loading: false,
        noState: action.payload.length > 0 ? false : true,
      };
    case "ADD_ORGANISATION_NAME":
      return {
        ...state,
        orgListNames: [...state.orgListNames, action.payload],
      };
    case "UPDATE_ORGANISATION_NAME":
      return {
        ...state,
        orgListNames: state.orgListNames.map((org) =>
          org._id === action.payload._id ? { ...org, ...action.payload } : { ...org }
        ),
      };

    case "DELETE_ORGANISATION_NAME":
      return {
        ...state,
        orgListNames: state.orgListNames.filter((org) => org._id !== action.payload),
      };
    default:
      return state;
  }
}

const GlobalContextProvider = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { Provider } = GlobalContext;
  saveStore({ state, dispatch });
  return <Provider value={{ state, dispatch }}>{props.children}</Provider>;
};
export default GlobalContextProvider;
