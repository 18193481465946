import React, { Component } from "react";
import { withRouter, useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { PartnerHeader, Sidebar } from "../shared_elements";
const noSidebarPaths = ["/review-quote"];

export default function quotationLayoutHoc(HocComponent) {
  class QuotationLayoutHoc extends Component {
    constructor(props) {
      super(props);
    }
    render() {
      const currentPath = this.props?.location?.pathname;
      const id = this.props?.match?.params?.id;
      return (
        <div>
          <PartnerHeader />
          {!noSidebarPaths.includes(currentPath) ? <Sidebar id={id} /> : null}
          <HocComponent role="ADMIN" {...this.props} />
        </div>
      );
    }
  }
  return withRouter(QuotationLayoutHoc);
}
