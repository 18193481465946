import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import styles from "../../../assets/styles/accountSettings.module.scss";
import { imageBasePath } from "../../../constants";
import { CustomButton, InputText } from "../../../shared_ui_components";
import { fieldValidation } from "../../../utils/formValidation";
import { errorCodes } from "../../../constants";
import { toastFlashMessage } from "../../../utils";
import { updateUserProfile } from "../apiServices";

export const UserProfile = () => {
  let userDetails = JSON.parse(localStorage.getItem("userInfo"));
  const [formData, setFormData] = useState({
    fullName: JSON.parse(localStorage.getItem("userInfo"))?.userData?.fullName || "",
  });
  const [displayImage, setDisplayImage] = useState(
    JSON.parse(localStorage.getItem("userInfo")).userData?.imageURL == "null"
      ? imageBasePath + "profile_placeholder.svg"
      : JSON.parse(localStorage.getItem("userInfo")).userData?.imageURL
  );
  const [image, setImage] = useState("");
  const [errors, setErrors] = useState({});
  const [errCodes, setErrCodes] = useState(errorCodes);
  const [validateLoader, setValidateLoader] = useState(false);

  const handleImgChange = (e) => {
    setImage(e.target.files[0]);
    setDisplayImage(URL.createObjectURL(e.target.files[0]));
  };

  const handleChange = (key, value) => {
    setFormData((formData) => ({
      ...formData,
      [key]: value,
    }));
    setErrors((errors) => ({
      ...errors,
      [key]: "",
    }));
  };

  const handleSubmit = () => {
    const validateNewInput = {
      fullName:
        errCodes["fullName"][
          fieldValidation({
            ...errCodes.fullNameObj,
            fieldval: formData.fullName,
          })
        ],
    };
    if (Object.keys(validateNewInput).every((k) => validateNewInput[k] == "")) {
      setValidateLoader(true);
      let form_data = new FormData();
      image
        ? form_data.append("imageURL", image, image.name)
        : form_data.append("imageURL", userDetails.userData.imageURL);
      form_data.append("fullName", formData.fullName);
      updateUserProfile(form_data).then((response) => {
        setValidateLoader(false);
        if (response.status) {
          userDetails = {
            ...userDetails,
            userData: {
              ...userDetails.userData,
              fullName: response?.userUpdatedDetails?.fullName,
              imageURL: response?.userUpdatedDetails?.imageURL,
            },
          };
          localStorage.setItem("userInfo", JSON.stringify(userDetails));
          //history.push("/settings/profile");
          toastFlashMessage("Profile updated successfully", "success");
        } else {
          toastFlashMessage("Something went wrong while updating profile", "error");
        }
      });
    } else {
      setErrors(validateNewInput);
    }
  };
  return (
    <div className={styles.profileTab}>
      <Grid container>
        <Grid item md={7}>
          <div className={styles.profileImgWrapper}>
            <img src={displayImage ? displayImage : formData?.imageURL} />
            <CustomButton children="Upload" className={styles.uploadBtn + " outlinedBtn "} />
            <input
              type="file"
              accept="image/png, image/jpeg, image/jpg"
              className={styles.inputFile}
              onChange={handleImgChange}
            />
          </div>

          <div className={styles.profileTabInput}>
            <InputText
              placeholder="Enter Name"
              label="Name"
              value={formData.fullName || ""}
              onChange={(e) => handleChange("fullName", e.target.value)}
              error={errors.fullName}
            />
          </div>
          <div className={styles.profileTabInput}>
            <InputText
              label="Email address"
              type="email"
              value={JSON.parse(localStorage.getItem("userInfo")).userData?.email}
              disabled={true}
            />
          </div>
          <div className={styles.btnWrapper}>
            <CustomButton
              children={validateLoader ? "validating.." : "Save changes"}
              className={`${styles.saveBtn} primaryBtn ${validateLoader ? "disabledBtn" : ""}`}
              onClick={() => handleSubmit()}
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};
