import React, { Component } from "react";
import { Container, Button, Grid } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { imageBasePath } from "../constants";
import { Link, useLocation } from "react-router-dom";
import { CustomButton, ExternalLink } from "../shared_ui_components";


function Header(props) {
  const { path } = props;
  const history = useHistory();
  //console.log('history', history.location.pathname);
  const redirectToReg = () => {
    history.push('/registration');
  }

  const hideHeaderButton = ['/cart', '/terms-of-use', '/privacy-policy'];

  return (
    <header /* className={path === '/partner' ? 'partnerHeader' : ''} */>
            <Container maxWidth="lg">
              <Grid container direction="row" spacing={2}>
                <Grid item md={8} xs={6}>
                  <Link to="/">
                    <img src={imageBasePath + "logo.svg"} alt="Letsving" />
                  </Link>
                </Grid>
                <Grid item md={4} xs={6}>
                  <ul className="listInline">
                    <li className="listInlineItem">
                      <>
                        {
                          hideHeaderButton.includes(history.location.pathname) ?
                            null
                            :
                            <CustomButton
                              className={"becomePartnerBtn" + " primaryBtn"}
                              children="Become a partner"
                              onClick={() =>
                                redirectToReg()
                              }
                            />
                        }
                      </>
                    </li>
                    <li className="listInlineItem">
                      {
                        hideHeaderButton.includes(history.location.pathname) ?
                          null
                          :
                          <ExternalLink 
                              title="Sign in"
                              href="/signin"
                          />
                      }
                    </li>
                  </ul>
                </Grid>
              </Grid>
            </Container>
          </header>
  );
}

export default Header;
