import React, { useState} from 'react';
import { Grid } from "@material-ui/core";
import QuotationOrderDetail from "../components/QuotationOrderDetail";
import ReviewQuotation from '../../../../shared_elements/ReviewQuotation';
import ReviewQuoteNavigation from '../components/ReviewQuoteNavigation';
import ReviewQuoteSuccess from '../components/ReviewQuoteSuccess';

export default function ReviewQuote(props) {
    return (
        <div>
            <ReviewQuoteNavigation />
            <QuotationOrderDetail />
            {/* <ReviewQuotation /> */}
            {/* <ReviewQuoteSuccess /> */}
        </div>
    )
}
