import React, { useState } from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { createTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import Paper from "@material-ui/core/Paper";
import {
  CustomButton,
  CustomCheckbox,
  InputText,
} from "../../../shared_ui_components";
import { regExpression } from "../../../constants";
import { globalPostService } from "../../../utils/globalApiServices";
import { toastFlashMessage } from "../../../utils";
import { Grid } from "@material-ui/core";

const ParticipantsListTheme = createTheme({
  palette: {
    secondary: {
      main: "#344552",
    },
  },
  typography: {
    fontFamily: ["Inter", "sans-serif"].join(","),
  },
});

const useStyles = makeStyles({
  root: {
    marginTop: "40px",
    margin: "auto",
    paddingBottom: "4rem",
  },
  paperRoot: {
    marginBottom: "40px",
    padding: "30px",
  },
  title: {
    fontWeight: "bold",
    marginTop: "30px",
    fontSize: "26px",
  },
  flexCol: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    marginBottom: "5px",
  },
  flex: {
    display: "flex",
    justifyContent: "center",
  },
});

const CONTACT_DETAILS = [
  {
    label: "Phone",
    link: "tel:+916363488600",
    value: "+91 6363488600",
  },
  {
    label: "Email",
    link: "mailto:hello@letsving.com",
    value: "hello@letsving.com",
  },
];

function ContactUs() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <ThemeProvider theme={ParticipantsListTheme}>
        <Paper className={classes.paperRoot} style={{ margin: "0px 10px" }}>
          <Grid container spacing={2} justifyContent="center">
            <Grid
              item
              md={5}
              sm={12}
              style={{ width: "100%", padding: "0 1rem" }}
            >
              <div style={{ padding: "0 1rem" }}>
                <Typography variant="h4" className={classes.title}>
                  Contact us
                </Typography>

                <div
                  style={{
                    fontSize: "18px",
                    marginTop: "10px",
                  }}
                >
                  {CONTACT_DETAILS.map((contact) => (
                    <div
                      style={{
                        paddingTop: "10px",
                        display: "flex",
                        gap: "0.25rem"
                      }}
                    >
                      <div style={{minWidth: "4rem"}}>{contact.label}:</div> <a href={contact.link}>{contact.value}</a>
                    </div>
                  ))}
                </div>
              </div>
            </Grid>

            <Grid item md={7} sm={12} className={classes.flexCol}>
              <div style={{ padding: "0 1rem" }}>
                <Typography variant="h4" className={classes.title}>
                  Contact me
                </Typography>
                
                <Typography variant="subtitle1">
                If you want us to reach out to you, please leave your details below.
                </Typography>

                <div style={{ fontSize: "18px", marginTop: "20px" }}>
                  <ContactMeForm />
                </div>
              </div>
            </Grid>

            <Grid item md={12} sm={12} style={{ width: "100%" }}>
              <div
                style={{
                  marginTop: "2rem",
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <Typography
                  variant="h4"
                  align="center"
                  className={classes.title}
                >
                  Book a Demo
                </Typography>
                <iframe
                  width="100%"
                  height="700px"
                  src="https://calendly.com/adityapisupati/ving-room-demo?month=2022-10"
                  title="calendly"
                  frameborder="0"
                  allowfullscreen
                  style={{ margin: "auto" }}
                ></iframe>
              </div>
            </Grid>
          </Grid>
        </Paper>
      </ThemeProvider>
    </div>
  );
}

export default ContactUs;

const CONTACT_FORM = [
  { label: "Name", type: "text", key: "name" },
  { label: "Email Id", type: "text", key: "email" },
  { label: "Phone", type: "number", key: "phone" },
];

const checkPatternMatches = (fieldval) => {
  const newRegex = new RegExp(regExpression.email);
  return newRegex.test(fieldval);
};

const sendContactDetails = (data, setApiStatus) => {
  setApiStatus({loading: true});
  globalPostService(`contact-me`, data)
    .then((response) => {
      if (response.status) {
        setApiStatus({success: true});
      } else {
        setApiStatus({error: true});
        toastFlashMessage("Something went wrong while sending!", "error");
      }
    })
    .catch((error) => {
      setApiStatus({error: true});
      toastFlashMessage("Something went wrong while sending!", "error");
    });
};

const COMMENTS = [
  "Please share the product details",
  "Call me to discuss",
  "Reach out to me to fix a demo",
];

function ContactMeForm() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    comments: [],
  });

  const [error, setError] = useState({
    name: "",
    email: "",
    phone: "",
  });

  const [apiStatus, setApiStatus] = useState({success: false});

  const classes = useStyles();

  const handleChange = (key, value) => {
    setError((prev) => ({ ...prev, [key]: "" }));
    setFormData((prev) => ({ ...prev, [key]: value }));
  };

  const handleFormSubmit = () => {
    const { name, email } = formData;

    const errorMsg = {};

    if (!name) {
      errorMsg.name = "Please enter your name.";
    }

    if (!email) {
      errorMsg.email = "Please enter your email.";
    }

    if (email && !checkPatternMatches(email)) {
      errorMsg.email = "Please enter a valid email.";
    }

    if (Object.keys(errorMsg).length) {
      setError(errorMsg);
      return;
    }

    sendContactDetails(formData, setApiStatus);
  };

  if(apiStatus.success) {
    return <strong>Thanks for contacting us. We'll get back to you shortly.</strong>
  }
  return (
    <div>
      <Grid container>
        <Grid item md={6} style={{ width: "100%" }}>
          {CONTACT_FORM.map((contact) => (
            <div style={{ width: "85%" }}>
              <InputText
                label={contact.label}
                type={contact.type}
                value={formData[contact.key]}
                error={error[contact.key]}
                onChange={(e) => handleChange(contact.key, e.target.value)}
                inputProps={{
                  style: {
                    fontSize: 16,
                    border: "1px solid #7e7e7e",
                    width: "100%",
                  },
                }}
                InputLabelProps={{
                  style: { fontSize: 16, color: "#344552" },
                }}
                style={{ fontSize: "40px" }}
              />
            </div>
          ))}
        </Grid>
        <Grid item md={6} className={classes.flexCol}>
          {COMMENTS.map((comment) => (
            <CustomCheckbox
              type="primary"
              label={<span style={{ fontSize: 16, color: "#344552" }}>{comment}</span>}
              value={formData.comments.includes(comment)}
              onChange={(checked) => {
                let comments = [...formData.comments];

                if (checked) {
                  comments.push(comment);
                } else {
                  comments = comments.filter((com) => com !== comment);
                }
                setFormData((preForm) => ({
                  ...preForm,
                  comments,
                }));
              }}
            />
          ))}
          <CustomButton
            onClick={() => handleFormSubmit()}
            children={apiStatus.loading ? "Sending..." : "Send"}
            className={`primaryBtn ${apiStatus.loading ? " disabledBtn" : ""}`}
          />
        </Grid>
      </Grid>
    </div>
  );
}
