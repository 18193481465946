import React, { useState, useEffect } from "react";
import styles from "../../../assets/styles/workspaces.module.scss";
import { Grid } from "@material-ui/core";
import { CustomLink } from "../../../shared_ui_components";
import { imageBasePath } from "../../../constants";
import { WorkspaceDetailsCard, WorkspaceRoomList } from "../components";
import {
  getSingleWorkspaceList,
  getWorkspaceRoomDetails,
  deactiveWorkspaceRoomDetails,
} from "../apiServices";
import { toastFlashMessage, getUserInfo } from "../../../utils";
import Shimmer from "../components/Shimmer";
import { AddRoomDrawer } from "../components";

function WorkspaceDetails(props) {
  const { id, _id } = props.match.params;
  const [workspaceRoomDetails, setWorkspaceRoomDetails] = useState([]);
  const [updateworkspaceRoomDetail, setUpdateWorkspaceRoomDetail] = useState(null);
  const [selectedWorkspace, setSelectedWorkspace] = useState(null);
  const [workspaceLoader, setWorkspaceLoader] = useState(true);
  const [loader, setLoader] = useState(false);
  const [openAddRoom, setOpenAddRoom] = useState(false);
  const [workspaceStatus, setWorkspaceStatus] = useState(false);
  const userInfo = getUserInfo();

  const workspaceAllRoomDetails = () => {
    getWorkspaceRoomDetails({ workspaceId: _id }).then((response) => {
      if (response.status && response.data.length > 0) {
        setWorkspaceRoomDetails(response.data);
      } else {
        setLoader(true);
      }
    });
  };

  useEffect(() => {
    workspaceAllRoomDetails();
  }, [_id]);

  const getWorkSpaceDetail = () => {
    getSingleWorkspaceList({ workspaceId: _id }).then((response) => {
      if (response.status) {
        setWorkspaceLoader(false);
        setSelectedWorkspace(response.data);
      }
    });
  };

  useEffect(() => {
    getWorkSpaceDetail();
  }, [_id, workspaceStatus]);

  const getUpdatedWorkspace = () => {
    setWorkspaceStatus(!workspaceStatus);
  };

  const addworkspaceRoomDetails = (roomDetails) => {
    setWorkspaceRoomDetails([...workspaceRoomDetails, roomDetails]);
    setLoader(false);
  };

  const editWorkspaceRoom = (id) => {
    setUpdateWorkspaceRoomDetail(workspaceRoomDetails.find((room) => room._id === id));
  };

  const updateRoomDetail = (roomDetails) => {
    setWorkspaceRoomDetails(
      workspaceRoomDetails.map((room) =>
        room._id === roomDetails._id ? { ...room, ...roomDetails } : { ...room }
      )
    );
  };

  const deactivateWorkspaceRoom = (id) => {
    deactiveWorkspaceRoomDetails({ roomId: id }).then((response) => {
      if (response.status) {
        // setWorkspaceRoomDetails(
        //   workspaceRoomDetails.map((room) => (room._id === id ? { ...room, roomStatus: false } : { ...room }))
        // );
        const updatedRoomList = workspaceRoomDetails.filter((room) => room._id !== id);
        setWorkspaceRoomDetails(updatedRoomList);
        toastFlashMessage("Room deleted successully", "success");
        if (updatedRoomList.length === 0) {
          workspaceAllRoomDetails();
        } else {
        }
      } else {
        toastFlashMessage("Something went wrong while deleting domain", "error");
      }
    });
  };
  const removeUpdatedRommDetails = () => {
    setUpdateWorkspaceRoomDetail(null);
  };

  const sortRoomDetails = (roomDetails) => {
    setWorkspaceRoomDetails(roomDetails);
  };

  return (
    <div className={styles.workspaceDetailsPage}>
      <div className={styles.workspaceDetailsWrapper}>
        <div className={styles.backBtnWrapper}>
          <CustomLink
            className="backBtn"
            text="Back"
            leftIcon={imageBasePath + "arrow_left_icon.svg"}
            to={`/${id}/workspaces`}
          />
        </div>
        <div className={styles.workspaceDetailsCardWrapper}>
          {workspaceLoader ? (
            <Shimmer type="workspaceDetailsCard" />
          ) : (
            <WorkspaceDetailsCard
              workspaceRooms={selectedWorkspace?.rooms?.length}
              workspaceDetails={selectedWorkspace?.addressId}
              workspaceLocalAdmins={selectedWorkspace?.localAdmins}
              workspaceName={selectedWorkspace?.orgWorkSpaceName}
              workspaceWallpaper={selectedWorkspace?.workSpaceWallpaper}
              getUpdatedWorkspace={getUpdatedWorkspace}
              timeZone={selectedWorkspace?.timezone}
              temperatureType={selectedWorkspace?.temperatureType}
            />
          )}
        </div>
        <div className={styles.workspaceRoomListWrapper}>
          <div className={styles.workspaceRoomListBlk}>
            {loader ? (
              <>
                {userInfo.userData?.role?.responsibilities?.createRoom ? (
                  <>
                    <div className={styles.noMeetingWrapper}>
                      <img src={`${imageBasePath}no_workspace_room.png`} alt="" />
                      <p className={styles.providerNoMeeting + " subheading2"}>No rooms found</p>
                      <p className={styles.providerNoMeetingSubpara + " paragraph"}>
                        Looks like there are no rooms for workspace
                      </p>
                      <p className={styles.providerNoMeetingSubpara + " paragraph"}>
                        <b>{selectedWorkspace?.orgWorkSpaceName}.</b> Start adding rooms here.
                      </p>
                    </div>
                    <Grid container justifyContent="flex-middle" className={styles.workspaceAddBtn}>
                      <div className={styles.WorkspaceAddNewRoomBtn}>
                        <CustomLink
                          onClick={() => {
                            setOpenAddRoom(true);
                          }}
                          text="Add New Room"
                          leftIcon={imageBasePath + "plus_icon.svg"}
                          className={styles.addNewRoomBtn + " outlinedBtn "}
                        />
                      </div>
                    </Grid>
                    <AddRoomDrawer
                      handleClose={() => setOpenAddRoom(false)}
                      open={openAddRoom}
                      removeUpdatedRommDetails={removeUpdatedRommDetails}
                      addworkspaceRoomDetails={addworkspaceRoomDetails}
                      getWorkSpaceDetail={getWorkSpaceDetail}
                    />
                  </>
                ) : null}
              </>
            ) : (
              <WorkspaceRoomList
                workspaceRoomDetails={workspaceRoomDetails}
                addworkspaceRoomDetails={addworkspaceRoomDetails}
                editWorkspaceRoom={editWorkspaceRoom}
                updateworkspaceRoomDetail={updateworkspaceRoomDetail}
                updateRoomDetail={updateRoomDetail}
                deactivateWorkspaceRoom={deactivateWorkspaceRoom}
                removeUpdatedRommDetails={removeUpdatedRommDetails}
                sortRoomDetails={sortRoomDetails}
                getWorkSpaceDetail={getWorkSpaceDetail}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default WorkspaceDetails;
