import { globalPostService, globalGetService } from "../../utils/globalApiServices";

export function getDevListsByOrgId(data = {}) {
  return new Promise((resolve, reject) => {
    globalPostService(`list-devices-by-orgId-v1`, data)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function selectDeviceType(data) {
  return new Promise((res, rej) => {
    globalPostService(`device-type-v1`, data)
      .then((resp) => {
        res(resp);
      })
      .catch((err) => {
        rej(err);
      });
  });
}

export function getAllRoomList() {
  return new Promise((resolve, reject) => {
    globalGetService(`list-of-room-details-v1`)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function activateDeviceById(data = {}) {
  return new Promise((resolve, reject) => {
    globalPostService(`device-add-v1`, data)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function deviceDetailById(data = {}) {
  return new Promise((resolve, reject) => {
    globalPostService(`devices-detail-by-Id-v1`, data)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function activateAndDeactivateDeviceById(data = {}) {
  return new Promise((resolve, reject) => {
    globalPostService(`device-activate-deactivate-by-Id-v1`, data)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function resetDeviceById(data = {}) {
  return new Promise((resolve, reject) => {
    globalPostService(`reset-device-by-Id-v1`, data)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
export function addDeviceWallpaper(data = {}) {
  return new Promise((resolve, reject) => {
    globalPostService(`update-device-wallpaper-v1`, data)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function getReleases() {
  return new Promise((resolve, reject) => {
    globalGetService("device-firmware-releases-v1")
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function createDeployment(data = {}) {
  return new Promise((resolve, reject) => {
    globalPostService(`create-device-firmware-deploy-v1`, data)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
