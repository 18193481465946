import { useState, useEffect } from "react";
import { List, ListItem } from "@material-ui/core";
import styles from "../../../assets/styles/accountSettings.module.scss";

export default function AccountSettingsTabs(props) {
  const { activeTab } = props;

  return (
    <div className={styles.accountSettingsTabs}>
      <List>
        <ListItem
          className={`accountSettingsTabList ${activeTab === 1 ? styles.active : null} `}
          onClick={() => props.handleTabChange(1)}
        >
          Profile
        </ListItem>
        <ListItem
          className={`accountSettingsTabList ${activeTab === 2 ? styles.active : null} `}
          onClick={() => props.handleTabChange(2)}
        >
          Password
        </ListItem>
        {/* <ListItem
          className={`accountSettingsTabList ${
            activeTab === 3 ? styles.active : null
          } `}
          onClick={() => props.handleTabChange(3)}
        >
          Addresses
        </ListItem> */}
      </List>
    </div>
  );
}
