import React, { useState, useEffect } from "react";
import styles from "../../../assets/styles/workspaces.module.scss";
import { imageBasePath, reactSelectStylesLarge } from "../../../constants";
import { CustomLink, CustomButton, InputText, InputSelect} from "../../../shared_ui_components";
import { Grid, Dialog } from "@material-ui/core";
import { errorCodes } from "../../../constants";
import { fieldValidation } from "../../../utils/formValidation";
import { createResource, editWorkspaceListing, getroomsbyOrg } from "../apiServices";
import { toastFlashMessage } from "../../../utils";
import { useParams } from "react-router-dom";
import useTimezone from "../../../utils/useTimezone";
export default function AddResourcesManually(props) {
  const { getAllWorkspaceList, editWorkspace } = props;
  const { id, _id } = useParams();
  const [validateLoader, setValidateLoader] = useState(false);
  const [validate, setValidate] = useState(false);
  
  const [formData, setFormData] = useState({
    orgId: id,
    orgLocId: _id,
    resourceId: '',
    userName: '',
    password: '',
    user: '',
  });
  const [roomOption, setRoomOption] = useState([]);
  const [userName, setUserName] = useState([]);
  const [errors, setErrors] = useState({});
  const [errCodes, setErrCodes] = useState(errorCodes);
  const timezoneStatus = useTimezone();
  const [selectedValue, setSelectedValue] = React.useState('a');
  useEffect(() => {
    let states = [];
    getroomsbyOrg({orgId: id}).then(res => {
      console.log(res);
      res.data = res.data.filter(ele => ele.resourceStatus == false);
      console.log(res.data)
      res.data.forEach((state) => states.push({ label: state.roomName, value: state._id }));
      setRoomOption(states);
    })
   
  }, []);

  const handleChange = (key, val) => {
    // if (key === "workSpaceWallpaper") {
    //   setImageUrl(val);
    // }
    if (key === "roomId") {
     
      setFormData((formData) => ({
        ...formData,
        [key]: val,
      }));
      setErrors((errors) => ({
        ...errors,
        [key]: "",
      })); 
    } else if (key === "email") {
      if (formData.email.includes(val.toLowerCase())) {
        return;
      }
      setFormData((formData) => ({
        ...formData,
        [key]: val,
        email: [...formData.email, val],
      }));
      setErrors((errors) => ({
        ...errors,
        [key]: "",
      }));
    } 
    else {
      setFormData((formData) => ({
        ...formData,
        [key]: val,
      }));
    
    setErrors((errors) => ({
      ...errors,
      [key]: "",
    }));
  }
  };


  const handleSubmit = (newWorkspace) => {
    let validateNewInput;
    if(props.selectedTab === 0) {
     validateNewInput = {
      password: errCodes["password"][fieldValidation({ ...errCodes.passwordObj, fieldval: formData.password })],
      resourceId: errCodes["address"][fieldValidation({ ...errCodes.resourceIdObj, fieldval: formData.resourceId })],
      user: errCodes["email"][fieldValidation({ ...errCodes.userObj, fieldval: formData.user })],
      roomId:
        errCodes["address"][fieldValidation({ ...errCodes.roomIdObj, fieldval: formData.roomId })],
        
    };
  } else {
    validateNewInput = {
      password: errCodes["password"][fieldValidation({ ...errCodes.passwordObj, fieldval: formData.password })],
      resourceId: errCodes["email"][fieldValidation({ ...errCodes.resourceIdObj, fieldval: formData.resourceId })],
      user: errCodes["email"][fieldValidation({ ...errCodes.userObj, fieldval: formData.user })],
      roomId:
        errCodes["address"][fieldValidation({ ...errCodes.roomIdObj, fieldval: formData.roomId })],
        
    };
  }
    //console.log(validateNewInput, "validateNewInput");
    if (Object.keys(validateNewInput).every((k) => validateNewInput[k] == "")) {
      if (newWorkspace === "addAnotherWorkspace") {
        setValidateLoader(false);
        setValidate(true);
      } else {
        setValidateLoader(true);
        setValidate(false);
      }

      if (editWorkspace) {
        //const form_data = { ...formData, workSpaceLogo: imageUrl };
        editWorkspaceListing(formData).then((response) => {
          if (response.status) {
            setValidateLoader(false);
            toastFlashMessage("Workspace edited succesfully", "success");
            props.handleCloseWorkSpaceDrawer();
          } else {
            toastFlashMessage("Something went wrong while editing workspace", "error");
            setValidateLoader(false);
          }
        });
      } else {
        createResource(formData).then((response) => {
          if (response.status) {
            setValidateLoader(false);
            window.location.reload()
            toastFlashMessage("Resource added succesfully", "success");
            if (newWorkspace === "addAnotherWorkspace") {
              setValidate(false);
              setFormData((formData) => ({
                ...formData,
                orgId: id,
                //rooms: 1,
                //userName: userName,
                user: userName,
                password: "",
                resourceId: "",
                stateName: "",
              }));
            } else {
              // props.handleClose();
            }
          } else {
            toastFlashMessage("Something went wrong while adding workspace", "error");
            setValidateLoader(false);
          }
        });
      }
    } else {
      setErrors(validateNewInput);
    }
  };

  const handleAddAnotherBtn = (newWorkspace) => {
    handleSubmit(newWorkspace);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    handleSubmit();
  };

  useEffect(() => {
    if (editWorkspace) {
      //setCityList(editWorkspace?.workspaceDetails?.stateName);
      setFormData((form) => ({
        ...form,
        password: editWorkspace?.workspaceDetails?.password,
        resourceId: editWorkspace?.workspaceDetails?.resourceId,
        stateName: editWorkspace?.workspaceDetails?.stateName,
        //rooms: editWorkspace.rooms.length,
        email: editWorkspace?.workspaceLocalAdmins?.map((admin) => admin?.userId?.email),
        workSpaceWallpaper: editWorkspace?.workspaceWallpaper,
        timezone:editWorkspace?.workspaceTimezone
      }));
    }
  }, [editWorkspace]);



  return (
    <div className={styles.addManuallyWrapper}>
      <form onSubmit={(e) => handleFormSubmit(e)}>
        <div className={styles.addWorkspaceForm}>
          <Grid container direction="row" spacing={2} justifyContent="center" alignItems="center">
          <Grid item md={12}>
          <InputSelect
                label="Room Name *"
                placeholder="Select Room"
                options={roomOption}
                error={errors.stateName}
                value={
                  formData.roomId ? roomOption.find((opt) => opt.value === formData.roomId) : null
                }
                onChange={(state) => {handleChange("roomId", state && state.value); handleChange("userName", state && state.label);}}
                styles={reactSelectStylesLarge}
              />
            </Grid>
            <Grid item md={12}>
              <InputText
                label={props.selectedTab === 0 ? "Google Resource ID *": " Microsoft Email ID *"}
                //multiline
                placeholder={props.selectedTab === 0 ? "Enter Google Resource ID" : "Enter Microsoft Email ID"}
                onChange={(e) => handleChange("resourceId", e.target.value)}
                error={errors.resourceIdObj}
                value={formData.resourceId}
              />
            </Grid>
            <Grid item md={12}>
              <InputText
                type="text"
                label="Username"
                placeholder="Username"
                disabled={true}
                value={
                  formData.userName
                }
              />
            </Grid>
            <Grid item md={12}>
              <InputText
                type="password"
                label="Password *"
                placeholder="Password"
                onChange={(e) => {handleChange("password", e.target.value);}}
                error={errors.passwordObj}
                value={formData.password}
              />
            </Grid>
            <Grid item md={12}>
              <InputText
                label="Admin User Email *"
                //multiline
                placeholder="Enter Admin User Email"
                onChange={(e) => handleChange("user", e.target.value)}
                error={errors.addressObj}
                value={formData.address}
              />
            </Grid>
          </Grid>
        </div>
        <div className={styles.addWorkspaceActionButton}>
          {!editWorkspace && (
            <CustomLink
              className={`${styles.addButton} outlinedBtn ${validateLoader || validate ? "disabledBtn" : ""}`}
              text={validate ? "Saving..." : "Save & add another"}
            />
          )}
          <CustomButton
            type="submit"
            // onClick={() => handleFormSubmit()}
            className={`${styles.exitButton} primaryBtn ${validateLoader || validate ? "disabledBtn" : ""}`}
            children={
              editWorkspace
                ? validateLoader
                  ? "Updating..."
                  : "Update & Exit"
                : validateLoader
                ? "Saving..."
                : "Save & Exit"
            }
          />
        </div>
      </form>
    </div>
  );
}
