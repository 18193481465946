import { Grid, Dialog } from "@material-ui/core";
import React, { useState } from "react";
import styles from "../../../assets/styles/devices.module.scss";
import settingsStyles from "../../../assets/styles/orgSettings.module.scss";
import { imageBasePath } from "../../../constants";
import { CustomButton } from "../../../shared_ui_components";
import { activateAndDeactivateDeviceById, resetDeviceById } from "../apiServices";
import { toastFlashMessage } from "../../../utils";
import { useParams } from "react-router";
import { Wallpapers } from "./Wallpapers";

export default function DeviceSettings(props) {
  const { deviceDetails, handleDeviceDetail } = props;
  const { id, _id } = useParams();
  const [loader, setLoader] = useState(false);
  const [resetLoader, setResetLoader] = useState(false);
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleEditWallpaper = (value) => {
    setOpen(true);
  };

  const handleResetDevice = () => {
    setResetLoader(true);
    resetDeviceById({
      deviceId: deviceDetails._id,
      macId: deviceDetails.macId,
    }).then((response) => {
      if (response.status) {
        setResetLoader(false);
        handleDeviceDetail();
        toastFlashMessage(`Device reset successfully`, "success");
      } else {
        setResetLoader(false);
        toastFlashMessage(`Something went wrong while resetting the device`, "error");
      }
    });
  };

  const handleActivateDeactivateDevice = () => {
    setLoader(true);
    activateAndDeactivateDeviceById({
      deviceId: deviceDetails._id,
      type: `${deviceDetails.deviceStatus === "Active" ? "deactivate" : "activate"}`,
    }).then((response) => {
      if (response.status) {
        setLoader(false);
        handleDeviceDetail();
        toastFlashMessage(
          `Device ${deviceDetails.deviceStatus === "Active" ? "deactived" : "activated"} successfully`,
          "success"
        );
      } else {
        setLoader(false);
        toastFlashMessage(
          `Something went wrong while ${
            deviceDetails.deviceStatus === "Active" ? "deactivating" : "activating"
          } the device`,
          "error"
        );
      }
    });
  };

  const closeDialog = (value) => {
    setOpen(value);
  };

  return (
    <>
      <div className={styles.deviceSettingsWrapper}>
        <div className={styles.deviceSettingsHeading}>
          <h3 className={styles.deviceSettingsTitle + " paragraph "}>Device Settings</h3>
        </div>
        <div className={styles.deviceSettingsRow}>
          <Grid container spacing={2}>
            <Grid item md={7}>
              <div className={styles.deviceSettingsLeftBlk}>
                <h4 className={styles.deviceSettingsLeftTitle + " supportText "}>Wallpaper Configuration</h4>
                <p className={styles.deviceSettingsLeftSubText + " supportText "}>
                  This action will change the wallpaper of the device
                </p>
              </div>
            </Grid>
            <Grid item md={5}>
              <div className={styles.deviceSettingsRightBlk}>
                    {deviceDetails?.deviceWallpaper?.backgroundImageUrl?.includes(".mp4") ? (
                      <video
                      src={deviceDetails?.deviceWallpaper?.backgroundImageUrl}
                      alt=""
                    />
                    ):(
                      <img
                        src={
                          deviceDetails?.deviceWallpaper
                            ? deviceDetails?.deviceWallpaper?.backgroundImageUrl
                            : imageBasePath + "device_settings_wallpaper.svg"
                        }
                        alt=""
                      />
                    )}
                <CustomButton
                  className={styles.changeWallpaperBtn + " outlinedBtn "}
                  children="Change"
                  onClick={() => handleEditWallpaper(1)}
                />
              </div>
            </Grid>
          </Grid>
        </div>
        {/* <div className={styles.deviceSettingsRow}>
          <Grid container spacing={2}>
            <Grid item md={7}>
              <div className={styles.deviceSettingsLeftBlk}>
                <h4 className={styles.deviceSettingsLeftTitle + " supportText "}>Welcome Display</h4>
                <p className={styles.deviceSettingsLeftSubText + " supportText "}>
                  This action will change the welcome message on the device
                </p>
              </div>
            </Grid>
            <Grid item md={5}>
              <div className={styles.deviceSettingsRightBlk}>
                <CustomButton
                  className={styles.editBtn + " outlinedBtn "}
                  children="Edit"
                  onClick={() => handleEditWallpaper(2)}
                />
              </div>
            </Grid>
          </Grid> 
       </div> */}
        <div className={styles.deviceSettingsRow}>
          <Grid container spacing={2}>
            <Grid item md={7}>
              <div className={styles.deviceSettingsLeftBlk}>
                <h4 className={styles.deviceSettingsLeftTitle + " supportText "}>Reset Device</h4>
                <p className={styles.deviceSettingsLeftSubText + " supportText "}>
                  This action will change the Ving Box settings to default
                </p>
              </div>
            </Grid>
            <Grid item md={5}>
              <div className={styles.deviceSettingsRightBlk}>
                <CustomButton
                  className={`${styles.resetBtn} ${resetLoader ? "disabledBtn" : ""} outlinedBtn `}
                  children={`${resetLoader ? "Resetting..." : "Reset"}`}
                  onClick={handleResetDevice}
                />
              </div>
            </Grid>
          </Grid>
        </div>
        <div className={styles.deviceSettingsRow}>
          <Grid container spacing={2}>
            <Grid item md={7}>
              <div className={styles.deviceSettingsLeftBlk}>
                <h4 className={styles.deviceSettingsLeftTitle + " supportText "}>
                  {deviceDetails.deviceStatus === "Active" ? "Deactivate device" : "Activate device"}
                </h4>
                <p className={styles.deviceSettingsLeftSubText + " supportText "}>
                  {/* `This action will make this Ving Box inactive` */}
                  {`This actiion will make this Ving Box ${
                    deviceDetails.deviceStatus === "Active" ? "inactive" : "active"
                  }`}
                </p>
              </div>
            </Grid>
            <Grid item md={5}>
              <div className={styles.deviceSettingsRightBlk}>
                <CustomButton
                  className={`${styles.resetBtn} ${loader ? "disabledBtn" : ""} ${
                    deviceDetails.deviceStatus === true ? "dangerBtn" : ""
                  } outlinedBtn `}
                  children={`${deviceDetails.deviceStatus === "Active" ? "Deactivate" : "Activate"}`}
                  onClick={handleActivateDeactivateDevice}
                />
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      {open && (
        <Dialog open={open} onClose={handleClose} className="wallpaperDialogWrapper" maxWidth="sm">
          <div className={settingsStyles.settingsWrapper} style={{ padding: 0, width: "600px" }}>
            <Wallpapers
              open={open}
              deviceId={deviceDetails._id}
              closeDialog={closeDialog}
              handleDeviceDetail={handleDeviceDetail}
            />
          </div>
        </Dialog>
      )}
    </>
  );
}
