import React, { useState } from "react";
import styles from "../../../assets/styles/orgSettings.module.scss";
import { imageBasePath } from "../../../constants";
import LanguageIcon from '@material-ui/icons/Language';

export const WallpaperImages = (props) => {
  const { img, _id, wallpaper ,wallpaperType} = props;

  const [isHovered, setHover] = useState(false);

  const handleDelete = () => {
    props.handleDeleteWallpaper(_id);
  };

  const handleSetDefaultWallpaper = () => {
    props.defaultWallpapers(_id);
  };

  const isVideo = img?.includes(".mp4");

  return (
    <>
    {<div
      className={styles.wallpapersList}
      onMouseOver={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      {wallpaperType!=="appLink"?!isVideo ? (
        <img
          className={
            styles.wallpapers +
            " " +
            `${img === wallpaper ? styles.active : " "}`
          }
          src={img}
          alt=""
          checked={img === wallpaper ? true : false}
        />
      ) : (
        <video
          src={img}
          autoPlay
          muted
          loop
          className={
            styles.wallpapers +
            " " +
            `${img === wallpaper ? styles.active : " "}`
          }
        ></video>
      ):<div className={`${styles.wallpapersList} ${styles.appLinkWallpaper}`} style={{border:`${wallpaper===img?"2px solid #4361ee":"1px solid #e6e8e9"}`,height:"117px"}}>
      <div style={{border:"0px"}} className={`${styles.wallpapers} ${img===wallpaper ? styles.active : null }`}
        checked={img===wallpaper ? true : false}>
        <div className={styles.appLinkIconContainer}>
          <LanguageIcon />
          <p>App Link</p>
    
        </div>
        <a href={img} target="_blank" className={styles.appLinkText}>{img}</a>
      </div>
    </div>}

      {isHovered && (
        <>
          {img === wallpaper ? (
            <button className={styles.currentBtn}>Current wallpaper</button>
          ) : (
            <button
              className={styles.defaultBtn}
              onClick={handleSetDefaultWallpaper}
            >
              Set as default
            </button>
          )}
          <div className={styles.deleteBtn}>
            <img
              src={imageBasePath + "delete-bin-line.svg"}
              alt=""
              style={{ width: "25px", height: "25px" }}
              onClick={handleDelete}
            />
          </div>
        </>
      )}
    </div>}
    </>
    
  );
};
