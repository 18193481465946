import React from 'react';
import styles from "../../../assets/styles/users.module.scss";
import UserList from "../components/UserList";

export default function Users() {
    return (
        <div className={styles.usersPage}>
            <UserList />
        </div>
    )
}
