import { Grid, List, ListItem, Dialog } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import styles from "../../../assets/styles/devices.module.scss";
import { imageBasePath } from "../../../constants";
import { CustomButton } from "../../../shared_ui_components";
import AddRoomDrawer from "../../workspaces/components/AddRoomDrawer";

export default function RoomDetails(props) {
  const { deviceDetails } = props;
  const [open, setOpen] = useState(false);
  const [updateworkspaceRoomDetail, setUpdateworkspaceRoomDetail] = useState({});
  const [newRoomDetail, setNewRoomDetail] = useState(false);
  const [editWorkspace, setEditWorkspace] = useState(null);

  const handleClose = () => {
    setOpen(false);
  };

  const handleEditRoomDetail = () => {
    setOpen(true);
  };

  const removeUpdatedRommDetails = () => {
    setNewRoomDetail(null);
  };

  const updateRoomDetail = (roomDetails) => {
    setUpdateworkspaceRoomDetail(roomDetails);
    setOpen(false);
  };

  useEffect(() => {
    if (Object.keys(deviceDetails).length > 0) {
      const data = {
        orgId: deviceDetails?.orgId,
        orgLocId: deviceDetails?.roomId?.orgLocId,
        roomName: deviceDetails?.roomId?.roomName,
        noOfSeats: deviceDetails?.roomId?.noOfSeats,
        additionalDevice: deviceDetails?.roomId?.additionalDevice,
        _id: deviceDetails?.roomId?._id,
      };
      setUpdateworkspaceRoomDetail(data);
    }
  }, [deviceDetails]);

  return (
    <>
      <div className={styles.deviceRoomDetails}>
        <div className={styles.roomDetailsHeading}>
          <h3 className={styles.roomDetailsTitle + " paragraph "}>Room Details</h3>
          <CustomButton
            className={styles.editBtn + " outlinedBtn "}
            children="Edit"
            onClick={() => handleEditRoomDetail(deviceDetails?._id)}
          />
        </div>
        <div className={styles.roomDetailsRow}>
          <Grid container spacing={2}>
            <Grid item md={5}>
              <div className={styles.roomDetailsLeftBlk}>
                <p className={styles.roomDetailsText + " supportText "}>Seating Capacity</p>
              </div>
            </Grid>
            <Grid item md={7}>
              <div className={styles.roomDetailsRightBlk}>
                <p className={styles.roomDetailsSeatingCapacity + " paragraph "}>
                  {deviceDetails?.roomId?.noOfSeats ? deviceDetails?.roomId?.noOfSeats : "NA"}
                </p>
              </div>
            </Grid>
          </Grid>
        </div>
        <div className={styles.roomDetailsRow}>
          <Grid container spacing={2}>
            <Grid item md={5}>
              <div className={styles.roomDetailsLeftBlk}>
                <p className={styles.roomDetailsText + " supportText "}>Other Devices</p>
              </div>
            </Grid>
            <Grid item md={7}>
              <div className={styles.roomDetailsRightBlk}>
                <div className={styles.roomDetailsDeviceList}>
                  <List>
                    {updateworkspaceRoomDetail?.additionalDevice?.map((deviceItem, key) => {
                      return (
                        <ListItem className={styles.roomDetailsIconWrapper}>
                          {deviceItem.img ? (
                            <img src={imageBasePath + deviceItem.img} />
                          ) : (
                            <p className={styles.roomDetailsLeftText + " paragraph "}>
                              {deviceItem.deviceType && deviceItem.deviceType}
                            </p>
                          )}
                          <p className={styles.roomDetailsRightText + " paragraph "}>
                            {deviceItem.deviceName ? deviceItem.deviceName : "No details found"}
                          </p>
                        </ListItem>
                      );
                    })}
                  </List>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      {open && (
        <Dialog open={open} handleClose={handleClose}>
          <AddRoomDrawer
            handleClose={() => setOpen(false)}
            open={open}
            updateworkspaceRoomDetail={updateworkspaceRoomDetail}
            updateRoomDetail={updateRoomDetail}
            removeUpdatedRommDetails={removeUpdatedRommDetails}
          />
        </Dialog>
      )}
    </>
  );
}
