import React, { useState } from "react";
import { Grid, Container } from "@material-ui/core";
import styles from "../../../assets/styles/auth.module.scss";
import { regExpression, imageBasePath } from "../../../constants";
import CreatePasswordForm from "../components/CreatePasswordForm";
import CreatePasswordSuccess from "../components/CreatePasswordSuccess";

export default function ResetPassword(props) {
    const [status, setStatus] = useState(false);

    const handleStatusChange = () => {
      setStatus(true);
    };
    return (
        <div className={styles.forgotPassword + ' flexCentered'}>
            <Container maxWidth={'lg'}>
                <div className={styles.LogoWrapper}>
                    <img src={imageBasePath + "logo.svg"} alt="logo" />
                </div>
                <div className={styles.resetPasswordWrapper}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        spacing={2}
                    >
                        <Grid item md={6} xs={12}>
                            <div className={styles.resetPasswordLeftHeading}>
                                <h1 className={" heading4 "}>Create New Password</h1>
                                <p className={styles.leftSubHeading + " subheading "}>
                                    Enter a new password that is atleast 8 characters and different from your previously used passwords
                                </p>
                            </div>
                        </Grid>
                        <Grid item md={4} xs={12}>
                        {!status ? (
                <CreatePasswordForm handleStatusChange={handleStatusChange} />
              ) : (
                <CreatePasswordSuccess />
              )}
                        </Grid>
                    </Grid>
                </div>
            </Container>
        </div>
    )
}
