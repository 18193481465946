import React, { useState } from "react";
import { Grid } from "@material-ui/core";
import { CustomLink, CustomButton, InputText } from "../../../shared_ui_components";
import styles from "../../../assets/styles/workspaces.module.scss";
import { errorCodes } from "../../../constants";
import { fieldValidation } from "../../../utils/formValidation";

function InviteLocalAdmins(props) {
  const [emails, setEmails] = useState("");
  const [error, setError] = useState({});
  const [loader, setLoader] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoader(true);
    let validateNewInput = {};
    let allEmails = emails.split(",");
    allEmails.forEach((email) => {
      validateNewInput = {
        email: errorCodes["email"][fieldValidation({ ...errorCodes.emailObj, fieldval: email.trim() })],
      };
    });
    // if (!emails) {
    //   validateNewInput = {
    //     email: "Please enter email address",
    //   };
    // } else {
    //   let allEmails = emails.split(",");
    //   allEmails.forEach((email) => {
    //     validateNewInput = {
    //       email: errorCodes["email"][fieldValidation({ ...errorCodes.emailObj, fieldval: email.trim() })],
    //     };
    //     if (validateNewInput.email) {
    //       return;
    //     }
    //   });
    // }
    if (Object.keys(validateNewInput).every((k) => validateNewInput[k] == "")) {
      props.handleChange("email", emails);
      setEmails("");
    } else {
      setError(validateNewInput);
      setLoader(false);
    }
  };

  const handleKeyPress = (e) => {
    if (e.charCode == 13) {
      e.preventDefault();
      handleSubmit(e);
    }
  };

  return (
    <>
      <Grid container direction="row" spacing={2} justifyContent="flex-start">
        <Grid item md={9}>
          <InputText
            label="Invite Local Admins"
            placeholder="Enter email seperated by comma"
            //multiline
            value={emails?.trim()}
            onChange={(e) => {
              setEmails(e.target.value);
              setError("");
            }}
            error={error.email}
            onKeyPress={handleKeyPress}
          />
        </Grid>
        <Grid item md={3}>
          <CustomButton
            className={styles.inviteAdminButton + " outlinedBtn "}
            children="Add admin"
            onClick={handleSubmit}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default InviteLocalAdmins;
