import React from "react";
import { imageBasePath } from "../constants";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
export default function CustomLink(props) {
  return (
    <>
      {props.to ? (
        <Link to={props.to} className={props.className} onClick={props.onClick}>
          {props.leftIcon ? (
            <img src={props.leftIcon} alt={props.alt ? props.alt : "image"} className="defaultImg" />
          ) : null}
          {props.leftIconhover ? (
            <img
              //src={imageBasePath + props.leftIconhover}
              src={props.leftIconhover}
              alt={props.alt ? props.alt : "image"}
              className="hoverImg"
            />
          ) : null}
          <span> {props.text} </span>
          {props.rightIcon ? (
            <img
              src={props.rightIcon}
              alt={props.alt ? props.alt : "image"}
              className="defaultImgg"
              style={{ marginLeft: "2px", verticalAlign: "middle" }}
            />
          ) : null}
        </Link>
      ) : (
        <a className={props.className} onClick={props.onClick}>
          {props.leftIcon ? (
            <img src={props.leftIcon} alt={props.alt ? props.alt : "image"} className="defaultImg" />
          ) : null}
          {props.leftIconhover ? (
            <img src={props.leftIconhover} alt={props.alt ? props.alt : "image"} className="hoverImg" />
          ) : null}
          <span> {props.text} </span>
          {props.rightIcon ? (
            <img
              src={props.rightIcon}
              alt={props.alt ? props.alt : "image"}
              className="defaultImgg"
              style={{ marginLeft: "2px", verticalAlign: "middle" }}
            />
          ) : null}
        </a>
      )}
    </>
  );
}

CustomLink.propTypes = {
  to: PropTypes.string,
  className: PropTypes.string,
  alt: PropTypes.string,
  onClick: PropTypes.func,
  leftIcon: PropTypes.string,
  leftIconhover: PropTypes.string,
  rightIcon: PropTypes.string,
  text: PropTypes.string,
};

CustomLink.defaultProps = {
  to: "",
  className: "",
  alt: "",
  onClick: () => { },
  leftIcon: "",
  leftIconhover: "",
  rightIcon: "",
  text: "",
};
