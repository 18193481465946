import { globalPostService } from "../../utils/globalApiServices";

export function getOrgUsersLists(data = {}) {
  return new Promise((resolve, reject) => {
    globalPostService(`get-users-of-org-v1`, data)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function deleteUser(data = {}) {
  return new Promise((resolve, reject) => {
    globalPostService(`delete-user-v1`, data)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}


export function inviteOrgUsersLists(data = {}) {
  return new Promise((resolve, reject) => {
    globalPostService(`invite-org-Admin-v1`, data)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}


export function inviteWorkSpaceUsersLists(data = {}) {
  return new Promise((resolve, reject) => {
    globalPostService(`invite-local-admin-v1`, data)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}