import React from "react";
import { List, ListItem } from "@material-ui/core";
import { Link, useLocation, useParams } from "react-router-dom";
import { imageBasePath } from "../constants";
import AssessmentIcon from '@material-ui/icons/Assessment';

export default function HomeSidebar() {
  let location = useLocation();
  const { id, _id } = useParams();

  return (
    <div className="homeSidebar">
      <div className="sidebarWrapper">
        <div className="menuListBlk">
          <List className="listUnstyled">
            <ListItem className={`listItem ${location.pathname === "/team" ? "active" : ""}`}>
              <Link to="/team">
                <img className="activeImg" src={imageBasePath + "home_team_icon_default.svg"} alt="Team" />
                <img className="defaultImg" src={imageBasePath + "home_team_icon_default.svg"} alt="Team" />
                <p className="supportText">Team</p>
              </Link>
            </ListItem>
            <ListItem className={`listItem ${location.pathname === "/activity-log" ? "active" : ""}`}>
              <Link to="/activity-log">
                <img
                  className="activeImg"
                  src={imageBasePath + "home_activity_icon_default.svg"}
                  alt="Activity Log"
                />
                <img
                  className="defaultImg"
                  src={imageBasePath + "home_activity_icon_default.svg"}
                  alt="Activity Log"
                />
                <p className="supportText">Activity Log</p>
              </Link>
            </ListItem>
            <ListItem
              className={`listItem ${
                location.pathname === "/quotations" || location.pathname === `/quotations/${_id}`
                  ? "active"
                  : ""
              }`}
            >
              <Link to="/quotations">
                <img
                  className="activeImg"
                  src={imageBasePath + "home_quotation_icon_default.svg"}
                  alt="Workspaces"
                />
                <img
                  className="defaultImg"
                  src={imageBasePath + "home_quotation_icon_default.svg"}
                  alt="Quotations"
                />
                <p className="supportText">Quotations</p>
              </Link>
            </ListItem>
            <ListItem className={`listItem ${location.pathname === "/orders" ? "active" : ""}`}>
              <Link to="/orders">
                <img className="activeImg" src={imageBasePath + "home_order_icon_default.svg"} alt="Orders" />
                <img
                  className="defaultImg"
                  src={imageBasePath + "home_order_icon_default.svg"}
                  alt="Orders"
                />
                <p className="supportText">Orders</p>
              </Link>
            </ListItem>
            <ListItem className={`listItem ${location.pathname === "/reports" ? "active" : ""}`}>
              <Link to="/reports">
              <AssessmentIcon fontSize="small" style={{margin:"16px", color:"#99a2a9"}} />
                <p className="supportText">Reports</p>
              </Link>
            </ListItem>
          </List>
        </div>
      </div>
    </div>
  );
}
