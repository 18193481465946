import React, { useState } from "react";
import { Container, Grid, Checkbox, FormControlLabel } from "@material-ui/core";
import { imageBasePath } from "../../../constants";
import styles from "../../../assets/styles/cart1.module.scss";
import { AddressForm } from "../../../shared_elements";
import { InputText, CustomButton, InputSelect, CustomLink } from "../../../shared_ui_components";
import ShippingAddressCard from "../components/ShippingAddressCard";

export default function BillingDetail(props) {
  const { handleUpdateStep, step, handleSameAsShipping, handleChange, error, cartDetails } = props;
  const [checked, setChecked] = React.useState(false);
  const [billingFlag, setBillingFlag] = React.useState(true);

  const handleCheck = (event) => {
    setChecked(event.target.checked);
    handleSameAsShipping(event.target.checked);
  };

  return (
    <div className={styles.ShippingDetail}>
      <Grid item md={12}>
        <p className={styles.ShippingTitle + " heading6"}>Who is it billed to?</p>
        <div className={styles.billingAddressHeader + " flexCentered " + " flexSpaceBetween "}>
          <p className={styles.orderTitle1 + " subheading2"}>Billing address</p>
          <FormControlLabel
            value="top"
            className={styles.checkBox + " paragraph"}
            control={<Checkbox color="primary" onChange={(e) => handleCheck(e)} />}
            label="Same as Shipping address"
            labelPlacement="end"
          />
        </div>
        {checked ? (
          <div className="cardFooter">
            <ShippingAddressCard billingFlag={billingFlag} cartDetails={cartDetails} />
            <form className={styles.ShippingForm}>
              <div className={styles.buttonSection}>
                <CustomLink
                  className={styles.firstBtn + " primaryBtn"}
                  onClick={() => handleUpdateStep(step + 1)}
                  text="Continue to Checkout"
                  rightIcon={imageBasePath + "btn_arrow_right_icon.svg"}
                />
                {/* <CustomButton
            className={styles.backBtn + " outlinedBtn "}
            children="back"
          /> */}
                <button className={styles.backBtn} onClick={() => handleUpdateStep(step - 1)}>
                  back
                </button>
              </div>
            </form>
          </div>
        ) : (
          <AddressForm
            handleUpdateStep={handleUpdateStep}
            step={step}
            handleChange={handleChange}
            error={error}
          />
        )}
      </Grid>
    </div>
  );
}
