import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { PartnerHeader, HomeSidebar } from "../shared_elements";

export default function homeLayoutHoc(HocComponent) {
  class HomeLayoutHoc extends Component {
    constructor(props) {
      super(props);
    }

    render() {
      const noSidebarPaths = [
        "/activity-log",
        "/account-settings",
        "/all-organisation",
        "/add-organisation",
        `/${this.props.match.params.id}/edit-organisation`,
        "/team",
        "/activate",
      ];
      const currentPath = this.props?.location?.pathname;
      return (
        <div>
          <PartnerHeader />
          {!noSidebarPaths.includes(currentPath) ? <HomeSidebar /> : null}
          <HocComponent role="ADMIN" {...this.props} />
        </div>
      );
    }
  }
  return withRouter(HomeLayoutHoc);
}
