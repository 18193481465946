import { Grid } from "@material-ui/core";
import React from "react";
import styles from "../../../../assets/styles/admin/directOrder.module.scss";
import { imageBasePath } from "../../../../constants";
import { CustomLink } from "../../../../shared_ui_components";
import { DirectOrderDetailsCard } from "../components";
function DirectOrderDetails(props) {
  return (
    <div className={styles.directOrderDetails}>
      <div className={styles.backBtnWrapper}>
        <CustomLink
          className="backBtn"
          text="Back"
          leftIcon={imageBasePath + "arrow_left_icon.svg"}
          to="/direct-order"
        />
      </div>
      <div className={styles.customerDetails}>
        <Grid container>
          <Grid item md={4}>
            <div className={styles.customerDetailsNameWrapper}>
              <img src={imageBasePath + "direct_order_user.svg"} />
              <p className={styles.customerName + " subheading "}>
                George Louis
              </p>
            </div>
          </Grid>
          <Grid item md={4}>
            <div className={styles.customerEmailWrapper}>
              <p className={styles.customerEmail}>Email ID</p>
              <span className="paragraph">
                <img src={imageBasePath + "email_icon.svg"} />
                george.louis@welpro.com
              </span>
            </div>
          </Grid>
          <Grid item md={4}>
            <div className={styles.customerPhoneWrapper}>
              <p className={styles.customerPhone}>Phone Number</p>
              <span className="paragraph">
                <img src={imageBasePath + "phone_icon.svg"} />
                +91 68326 82623
              </span>
            </div>
          </Grid>
        </Grid>
      </div>
      <div className={styles.directOrderDetailsCardWrapper}>
        <DirectOrderDetailsCard />
      </div>
    </div>
  );
}

export default DirectOrderDetails;
