import React, { useState, useEffect } from "react";
import { withRouter } from "react-router";
import styles from "../../../assets/styles/home.module.scss";
import tick from "../../../assets/images/download.png";
import wrong from "../../../assets/images/images.png";
import Ping from 'ping-url';
Ping.config({
    protocol: 'https'
});

function NetworkCheck(props) {
  const [api, setApi] = useState("");
  const [mqtt, setMqtt] = useState("");
  //debugger

  useEffect(() => {
    Ping.check('api.letsving.com').then(res => {
      console.log(`status: ${res.status} and time: ${res.time}`);
      setApi(res.status + '||' +res.time+' ms')
  }, res => {
      console.log(`error msg: ${res.msg}`);
      setApi(res.msg+'||')
  });
  Ping.check('mqtt.letsving.com').then(res => {
    console.log(`status: ${res.status} and time: ${res.time}`);
    setMqtt(res.status + '||'+ res.time+' ms')
  }, res => {
    console.log(`error msg: ${res.msg}`);
    setMqtt(res.msg+'||')
  });
  
  }, []);

  return (
    <div className={styles.homePage}>
      <div className={styles.homeWrapper}>
      <div className={styles.homeHeaderWrapper}>
      <div className={styles.homePageTitleWrapper}>
        <h4>Please check on WIRED LAN connection on which VING  box will be connected</h4><br/>
        <h2 className={styles.homePageTitle + " subheading "}>Network Check</h2>
       
      </div>
    </div>
        <div
          className={styles.homeDevicesWrapper}
          style={{ marginRight: "20px", width: "100%" }}
        >
         <p>api.letsving.com</p> {
         api.split('||')[0] === 'true' ? 
           <p>Connected<img width={20} src={tick} alt='Connected'/></p>: 
           <p>api.split('||')[0]<img width={20} src={wrong} alt={api.split('||')[0]}/></p>
        }
         <br/>
         <p>mqtt.letsving.com</p> { mqtt.split('||')[0] === 'true' ? 
           <p>Connected<img width={20} src={tick} alt='Connected'/></p>: 
           <p>mqtt.split('||')[0]<img width={20} src={wrong} alt={mqtt.split('||')[0]}/></p>}
           <br/>
           <h4>Check with the Network Admin Team for the whitelisting of MAC ID</h4>
        </div>
        
      </div>
    </div>
  );
}

export default withRouter(NetworkCheck);
