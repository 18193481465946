import React, { useState, useEffect } from "react";
import styles from "../../src/assets/styles/workspaces.module.scss";
import { imageBasePath } from "../../src/constants";
import { Grid } from "@material-ui/core";
import { LogOutDialog } from "../../src/shared_elements";
import { errorCodes } from "../../src/constants";
import { CustomButton, InputText, CustomLink } from "../shared_ui_components";
import { fieldValidation } from "../utils/formValidation";
import { toastFlashMessage } from "../utils";
import { createDemoOrg } from "../features/resources/apiServices";
import { NavigateBeforeSharp } from "@material-ui/icons";
export default function AddOrgDialog(props) {
  const { getAllWorkspaceList, editWorkspace } = props;
  const [open, setOpen] = useState(false);
  const [closeStatus, setCloseStatus] = useState(false);
  const [errors, setErrors] = useState({});
  const [validateLoader, setValidateLoader] = useState(false);
  const [errCodes, setErrCodes] = useState(errorCodes);
  const [validate, setValidate] = useState(false);
  const [formData, setFormData] = useState({
    orgName: '',
    orgWorkSpaceName: '',
    roomName: '',
    macId: '',
    devicetypeId:"6114ce7a9db7a7671d8251c4"
   
  });
  const handleSuccess = () => {
    setOpen(false);
    props.handleClose();
    setCloseStatus(false);
  };

  const handleCancel = () => {
    setOpen(true);
    setCloseStatus(false);
  };

  const handleChange = (key, val) => {
    // if (key === "workSpaceWallpaper") {
    //   setImageUrl(val);
    // }
    
      setFormData((formData) => ({
        ...formData,
        [key]: val,
      }));
    
    setErrors((errors) => ({
      ...errors,
      [key]: "",
    }));
  
  };
  
  const handleClose = () => {
    // setOpen(false);
    // props.handleClose();
    setCloseStatus(true);
  };

  
  const handleFormSubmit = (e) => {
    e.preventDefault();
    handleSubmit();
  };
  const handleSubmit = (newWorkspace) => {
    let validateNewInput;
    if(props.selectedTab === 0) {
     
  } else {
    validateNewInput = {
      orgName: errCodes["orgName"][fieldValidation({ ...errCodes.orgNameObj, fieldval: formData.orgName })],
      orgWorkSpaceName: errCodes["orgWorkSpaceName"][fieldValidation({ ...errCodes.orgWorkSpaceNameObj, fieldval: formData.orgWorkSpaceName })],
      roomName: errCodes["roomName"][fieldValidation({ ...errCodes.roomNameObj, fieldval: formData.roomName })],
      macId:
        errCodes["macId"][fieldValidation({ ...errCodes.macIdObj, fieldval: formData.macId })],
        
    };
  }
    //console.log(validateNewInput, "validateNewInput");
    if (Object.keys(validateNewInput).every((k) => validateNewInput[k] == "")) {
      if (newWorkspace === "addAnotherWorkspace") {
        setValidateLoader(false);
        setValidate(true);
      } else {
        setValidateLoader(true);
        setValidate(false);
      }

      if (editWorkspace) {
       
      } else {
        createDemoOrg(formData).then((response) => {
          if (response.status) {
            setValidateLoader(false);
            window.location.reload()
            toastFlashMessage("Resource added succesfully", "success");
            if (newWorkspace === "addAnotherWorkspace") {
              setValidate(false);
              setFormData((formData) => ({
                ...formData,
                devicetypeId:"6114ce7a9db7a7671d8251c4"
              }));
            } else {
              //props.handleClose();
            }
          } else {
            toastFlashMessage("Something went wrong while adding workspace", "error");
            setValidateLoader(false);
          }
        });
      }
    } else {
      setErrors(validateNewInput);
    }
  };
  // useEffect(() => {
  //   function handleBrowserClose(e) {
  //     let confirmationMessage = "";
  //     (e || window.event).returnValue = confirmationMessage; //Gecko + IE
  //     return confirmationMessage; //Gecko + Webkit, Safari, Chrome etc.
  //   }
  //   window.addEventListener("beforeunload", handleBrowserClose);
  //   return () => {
  //     window.removeEventListener("beforeunload", handleBrowserClose);
  //   };
  // });

  return (
    <div className={styles.addWorkspaceDialogWrapper}>
      <div className={styles.addWorkspaceTitleWrapper}>
        <div className={styles.addWorkspaceTitle}>
          <img src={imageBasePath + "add_organisation.svg"} alt="" />
          <p className={styles.addWorkspaceHeader + " subheading"}>
          Add Org
          </p>
        </div>
        <div className={styles.closeDialog}>
          <img onClick={handleClose} src={imageBasePath + "user_cancel.svg"} alt="" />
        </div>
      </div>
      <Grid container direction="row" spacing={2} justifyContent="center" alignItems="center" style={{padding: '15px'}}>
      <Grid item md={12}>
              <InputText
                label={props.selectedTab === 0 ? "Org Name *": "Org Name *"}
                //multiline
                placeholder={props.selectedTab === 0 ? "Enter Org Name" : "Enter Org Name"}
                onChange={(e) => handleChange("orgName", e.target.value)}
                error={errors.orgNameObj}
                value={formData.orgName}
              />
              </Grid>

<Grid item md={12}>
              <InputText
                label={props.selectedTab === 0 ? "Workspace Name *": "Workspace Name *"}
                //multiline
                placeholder={props.selectedTab === 0 ? "Enter Workspace Name" : "Enter Workspace Name"}
                onChange={(e) => handleChange("orgWorkSpaceName", e.target.value)}
                error={errors.orgWorkSpaceNameObj}
                value={formData.orgWorkSpaceName}
              />
              </Grid>
              <Grid item md={12}>
              <InputText
                label={props.selectedTab === 0 ? "Room Name *": " Room Name *"}
                //multiline
                placeholder={props.selectedTab === 0 ? "Enter Room Name" : "Enter Room Name"}
                onChange={(e) => handleChange("roomName", e.target.value)}
                error={errors.roomNameObj}
                value={formData.roomName}
              />
              </Grid>
              <Grid item md={12}>
              <InputText
                label={props.selectedTab === 0 ? "Mac ID *": " Mac ID *"}
                //multiline
                placeholder={props.selectedTab === 0 ? "Mac ID" : "Mac ID"}
                onChange={(e) => handleChange("macId", e.target.value)}
                error={errors.macIdObj}
                value={formData.macId}
              />
              </Grid>
              <div className={styles.addWorkspaceActionButton}>
          {!editWorkspace && (
            <CustomLink style={{margin: '5px'}}
              className={`${styles.addButton} outlinedBtn ${validateLoader || validate ? "disabledBtn" : ""}`}
              text={validate ? "Saving..." : "Save & add another"}
            /> 
          )}
          &nbsp;&nbsp;
          <CustomButton
            type="submit"
            onClick={(e) => handleFormSubmit(e)}
            className={`${styles.exitButton} primaryBtn ${validateLoader || validate ? "disabledBtn" : ""}`}
            children={
              editWorkspace
                ? validateLoader
                  ? "Updating..."
                  : "Update & Exit"
                : validateLoader
                ? "Saving..."
                : "Save & Exit"
            }
          />
        </div>
      {/* {selectedTab === 0 && (
        <div className={styles.addWorkspaceContent}>
          <img className={styles.WorkspacePicture} src={imageBasePath + "upload_default.svg"} alt="" />
          <p className={styles.WorkspaceLink}>
            Drag and drop or <span>Upload CSV</span>
          </p>
          <CustomLink
            className={styles.WorkspaceSample}
            text="Download sample file"
            leftIcon={imageBasePath + "file-excel-2-line.svg"}
          />
        </div>
      )} */}
     
      {closeStatus && (
        <LogOutDialog
          text="Are you sure you want to close? Changes that you made may not be saved."
          handleSuccess={handleSuccess}
          handleCancel={handleCancel}
        />
      )}
      </Grid>
    </div>
  );
}
