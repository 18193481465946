import React, { useState, useEffect } from "react";
import { withRouter } from "react-router";
import styles from "../../../assets/styles/home.module.scss";
import { DevicesCard, AnalyticsCard, Filters } from "../../../shared_elements";
import { HomeHeader } from "../components";
import { partnerDashboardAnalyticsApi } from "../apiServices";
import Shimmer from "../components/Shimmer";

const defaultOption = { label: "All", value: null };

function Home(props) {
  //debugger
  const [analytics, setAnalytics] = useState({});
  const [loader, setLoader] = useState(false);
  const [filter, setFilter] = useState({
    organisation: defaultOption,
    workspace: defaultOption,
    room: defaultOption,
  });

  function partnerDashboard() {
    setLoader(true);
    partnerDashboardAnalyticsApi({
      orgId: filter.organisation?.value,
      workspaceId: filter.workspace?.value,
      roomId: filter.room?.value,
    }).then((response) => {
      if (response.status && Object.keys(response).length > 0) {
        setAnalytics(response.data);
        setLoader(false);
      } else {
        setLoader(false);
      }
    });
  }

  useEffect(() => {
    partnerDashboard();
  }, []);

  return (
    <div className={styles.homePage}>
      <div className={styles.homeWrapper}>
        <HomeHeader />
        <div
          className={styles.homeDevicesWrapper}
          style={{ marginRight: "20px", width: "100%" }}
        >
          <Filters
            filter={filter}
            setFilter={setFilter}
            loader={loader}
            handleFilter={partnerDashboard}
          />
        </div>
        {loader ? (
          <Shimmer type="deviceCount" />
        ) : (
          <div className={styles.homeDevicesWrapper}>
            <DevicesCard
              activatedDevices={analytics?.activeRooms}
              notActivatedDeviceCount=""
              disconnectedDeviceCount={analytics?.inactiveRooms}
              deviceOnlineCount={analytics?.onlineRoomsCount}
              deviceOfflineCount={analytics?.offlineRoomCount}
            />
            {loader ? null : <AnalyticsCard analytics={analytics} />}
          </div>
        )}
      </div>
    </div>
  );
}

export default withRouter(Home);
