import React, { useState, useEffect } from "react";
import styles from "../../../assets/styles/orgSettings.module.scss";
import { domainSuffix, regExpression } from "../../../constants";
import { InputText, CustomButton } from "../../../shared_ui_components";
import { toastFlashMessage } from "../../../utils";
import { getOrgSettings, updateOrgSettings } from "../apiServices";
import { useParams } from "react-router-dom";
import { FormControl, Grid, MenuItem, NativeSelect, Select, Switch, TextField } from "@material-ui/core";
import { fieldValidation } from "./formValidation";
import { set } from "date-fns/esm";

export default function Customization() {
  const { id } = useParams();
  

  const [error, setError] = useState({});
  const [formData, setFormData] = useState({

    orgId: id,
    newDomain:"",
    loginLink:"",
    issuerId:"",
    certificate:"",
    nameField:"",
    emailField:"",
    loginLabel:"Single Sign On",
    
  
  });
  const [orgSettings, setOrgSettings] = useState({
    enableOneTimeRegistration: true,
    enableInstantMeeting: true,
    enableTeamsDeviceSignIn: false
  });
  const [validateLoader, setValidateLoader] = useState(false);
  const [enableOneTimeRegistration, setEnableOneTimeRegisteration] = useState(true);
  const [enableInstantMeeting, setEnableInstantMeeting] = useState(true);
  const [enableGoogleSignIn,setEnableGoogleSignIn]=useState(true)
  const [enableMicrosoftSignIn, setEnableMicrosoftSignIn] = useState(true);
  const [enableEmailSignIn,setEnableEmailSignIn]=useState(true)
  const [enableTeamsDeviceSignIn,setEnableTeamsDeviceSignIn]=useState(false)
  const [enableSaml,setEnableSaml]=useState(false)
  const [samlProvider, setSamlProvider] = useState("");

  const defaultData={
    microsoft:{
      nameField:"http://schemas.microsoft.com/identity/claims/displayname",
      emailField: "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress"
    },

  }
  
  
 
  const [errorCode,setErrorCode]=useState(
    {
      loginLink: {
        0: '',
        1: 'Please enter your loginLink!',
        4:"Invalid Url,Please try again"
        
      },
      loginLinkObj: {
        requiredFlag: true
      },
      issuerId: {
        0: '',
        1: 'Please enter issuerId',
      },
      issuerIdObj: {
        requiredFlag: true,
      },
      certificate: {
        0: '',
        1: 'Please enter certificate',
      },
      certificateObj: {
        requiredFlag: true,
      },
      nameField:{
        0:'',
        1:"please enter name"
      },
      nameFieldObj: {
        requiredFlag: true,
      },
      emailField:{

        0: '',
        1: 'Please enter email!',
        
      },
      emailFieldObj: {
        requiredFlag: true,
        
      },
      loginLabel:{
        0: '',
        1: 'Please enter loginLabel!',
      },
      loginLabelObj:{
        requiredFlag: true,
      }
      
     
     
    }
  )
  

  

  useEffect(() => {
    getOrgSettings({orgId: id}).then((response) => {
      if (response.status) {
        setOrgSettings(response?.organisationSettings);
        
        setFormData(prevState => {
          return {
            ...prevState,
            newDomain: response?.organisationSettings?.subdomain?.replace(domainSuffix,""),
            loginLink:response?.organisationSettings?.samlConfig?.loginLink,
            issuerId:response?.organisationSettings?.samlConfig?.issuerId,
            certificate:response?.organisationSettings?.samlConfig?.certificate,
            nameField:response?.organisationSettings?.samlConfig?.nameField,
            emailField:response?.organisationSettings?.samlConfig?.emailField,
            loginLabel:response?.organisationSettings?.samlConfig?.loginLabel
          }
        })
        
      }
    });
  }, [id]);

  useEffect(()=>{
    if(orgSettings){
      setEnableOneTimeRegisteration(orgSettings?.enableOneTimeRegistration)
      setEnableInstantMeeting(orgSettings?.enableInstantMeeting)
      setEnableGoogleSignIn(orgSettings?.enableGoogleSignIn)
      setEnableMicrosoftSignIn(orgSettings?.enableMicrosoftSignIn)
      setEnableEmailSignIn(orgSettings?.enableEmailSignIn)
      setEnableTeamsDeviceSignIn(orgSettings?.enableTeamsDeviceSignIn || false)
      setEnableSaml(orgSettings?.enableSaml)
      setSamlProvider(orgSettings?.samlConfig?.samlProvider)
    }
  },[orgSettings])

  const handleChange = (key, value) => {
        setFormData((form) => ({
            ...form,
            [key]: value,

          }))
         
    setError((preError) => ({
      ...preError,
      [key]: "",
    }));
  };
  const handleSubmit = (e) => {
    e.preventDefault();
  
    if(!formData.newDomain) {
      return toastFlashMessage("Please enter sub domain name", "error");
    }

    if(!regExpression.validDomainNameRegex.test(formData.newDomain)) {
      return toastFlashMessage("Please enter a valid sub domain name. Valid input is a combination of characters, numeric digits, hyphen and period", "error");
    }
   


    let validateNewInput= {}


    if(enableSaml){
      validateNewInput = {
        loginLink:
        errorCode['loginLink'][
          fieldValidation({
            ...errorCode['loginLinkObj'],
            fieldval: formData.loginLink,
          })
        ],
        issuerId:
          errorCode['issuerId'][
            fieldValidation({
              ...errorCode['issuerIdObj'],
              fieldval: formData.issuerId,
            })
          ],
        certificate:
          errorCode['certificate'][
            fieldValidation({
              ...errorCode['certificateObj'],
              fieldval: formData.certificate,
            })
          ],
          nameField:
          errorCode["nameField"][
            fieldValidation({
              ...errorCode['nameFieldObj'],
              fieldval: formData.nameField,
            })
          ],
          emailField:
          errorCode["emailField"][
            fieldValidation({
              ...errorCode['emailFieldObj'],
              fieldval: formData.emailField,
            })
          ],
          loginLabel:errorCode["loginLabel"]
          [fieldValidation({
            ...errorCode["loginLabelObj"],
            fieldval:formData.loginLabel
          })
        ]
      };
      if(samlProvider === "microsoft"){
        delete validateNewInput?.nameField
        delete validateNewInput?.emailField
      }
      }
     

      const errors = Object.values(validateNewInput).filter(d=>   d)

      if(errors.length){
        setError(validateNewInput)
        toastFlashMessage("please fill all the mandatory fields","error")
        return 
      }
    
     
let updObject={
  ...formData,
  newDomain : `${formData.newDomain}${domainSuffix}`,
  enableOneTimeRegistration,
  enableInstantMeeting,
  enableGoogleSignIn,
  enableMicrosoftSignIn,
  enableEmailSignIn,
  enableTeamsDeviceSignIn,
  enableSaml,
  samlProvider
}


    setValidateLoader(true);
    
   
    updateOrgSettings(updObject).then((response) => {
        if (response.status) {
          setValidateLoader(false);
          setOrgSettings(response?.organisationSetting);
          toastFlashMessage("Organization Customization has been saved successfully", "success");
          setFormData((form) => ({
            ...form,
            newDomain: formData.newDomain,
            loginLink:formData.loginLink,
    issuerId:formData.issuerId,
    certificate:formData.certificate,
    nameField:formData.nameField,
    emailField:formData.emailField,
    loginLabel:formData.loginLabel

          }));
        } else {
          toastFlashMessage("Something went wrong while adding subDomain", "error");
          setValidateLoader(false);
        }
      }).catch(function (error) {
        toastFlashMessage(`${error.message}`,"error")
        setValidateLoader(false);
    });
  };



  function samlHandleChange(){
   setEnableSaml(!enableSaml)
   setFormData((form) => ({
    ...form,
   
    loginLink:"",
issuerId:"",
certificate:"",
nameField:"",
emailField:"",

  }));

  }

  const selectionChangeHandler = (e) => {
    const key=e.target.value
    setSamlProvider(key);
    const fieldValue = { ...defaultData[key]}
    if(key === "microsoft"){
      setFormData((prevState)=>({
        ...prevState,
        nameField:fieldValue.nameField,
        emailField:fieldValue.emailField
      }))
    }else{
      setFormData((prevState)=>({
        ...prevState,
        nameField:"",
        emailField:""
      }))
    }
  
  };


  return (
    <div className={styles.domainsWrapper}>
      <div className={styles.addDomainWrapper}>
        <h1 className={styles.customizationTitle}>Customization</h1>
      <div className={styles.subDomainSection}>
          <InputText
            className={styles.subDomainInput}
            label="Enter Organisation SubDomain "
            placeholder="E.g. Use commonly used org name like infosys, tcs"
            value={formData.newDomain}
            error={error.newDomain}
            onChange={(e) => {
              handleChange("newDomain", e.target.value);
            }}
          />
          <h3 className={styles.subDomainText}>{domainSuffix}</h3>
        </div>

        <Grid component="label" container alignItems="center" spacing={2}>
      <Grid item  xs={10}><strong strong className="domainAddedTitleWrapper">Enable OneTime Registration</strong></Grid>
      <Grid item>
      <Switch checked={enableOneTimeRegistration} color="primary" defaultChecked onChange={()=>setEnableOneTimeRegisteration(!enableOneTimeRegistration)}  />
      </Grid>
      </Grid>
        <Grid  component="label" container alignItems="center" spacing={2}>
      <Grid item xs={10}><strong className="domainAddedTitleWrapper">Enable Instant Meeting</strong></Grid>
      <Grid item>
      <Switch   checked={enableInstantMeeting} color="primary" defaultChecked  onChange={()=>setEnableInstantMeeting(!enableInstantMeeting)}/>
      </Grid>
     </Grid>
     <Grid component="label" container alignItems="center" spacing={2}>
      <Grid item  xs={10}><strong strong className="domainAddedTitleWrapper">Enable Device MS Teams Signin </strong></Grid>
      <Grid item>
      <Switch checked={enableTeamsDeviceSignIn} color="primary" onChange={()=>setEnableTeamsDeviceSignIn(!enableTeamsDeviceSignIn)}  />
      </Grid>
      </Grid>
     <Grid component="label" container alignItems="center" spacing={2}>
      <Grid item  xs={10}><strong strong className="domainAddedTitleWrapper">Enable Google SignIn</strong></Grid>
      <Grid item>
      <Switch checked={enableGoogleSignIn} color="primary" defaultChecked onChange={()=>setEnableGoogleSignIn(!enableGoogleSignIn)}  />
      </Grid>
      </Grid>
      <Grid component="label" container alignItems="center" spacing={2}>
      <Grid item  xs={10}><strong strong className="domainAddedTitleWrapper">Enable Microsoft SignIn</strong></Grid>
      <Grid item>
      <Switch checked={enableMicrosoftSignIn} color="primary" defaultChecked onChange={()=>setEnableMicrosoftSignIn(!enableMicrosoftSignIn)}  />
      </Grid>
      </Grid>
      <Grid component="label" container alignItems="center" spacing={2}>
      <Grid item  xs={10}><strong strong className="domainAddedTitleWrapper">Enable Email SignIn</strong></Grid>
      <Grid item>
      <Switch checked={enableEmailSignIn} color="primary" onChange={()=>setEnableEmailSignIn(!enableEmailSignIn)}  />
      </Grid>
      </Grid>
     <Grid  component="label" container alignItems="center" spacing={2}>
      <Grid item xs={10}><strong className="domainAddedTitleWrapper">Enable Saml</strong></Grid>
      <Grid item>
      <Switch   checked={enableSaml} color="primary" defaultChecked  onChange={samlHandleChange}/>
      </Grid>
     </Grid>
    

     
    

     {enableSaml? <> 
     <Grid style={{marginTop:"5px"}} component="label" container alignItems="center" spacing={1}>
<Grid item xs={10}><strong className="domainAddedTitleWrapper">Select Saml Provider</strong></Grid>
      <Grid item>
      <FormControl>
                
                <Select
                    value={samlProvider}
                    onChange={selectionChangeHandler}
                >
                    <MenuItem value="microsoft"
                    >Microsoft</MenuItem>
                    <MenuItem value="Google"
                    >Google</MenuItem>
                    <MenuItem value="other">Other</MenuItem>
                </Select>
      
            </FormControl>
      </Grid>
      </Grid>
     
          
          < div className={styles.subDomainSection}>
          <InputText
            className={styles.subDomainInput}
            label="Enter Issuer Id* "
            placeholder="E.g. https://saml-azure-ad-test.letsving.com"
            value={formData.issuerId}
            error={error.issuerId}
            onChange={(e) => {
              handleChange("issuerId", e.target.value);
            }}
          />
          </div>
          <div className={styles.subDomainSection}>
          <InputText
            className={styles.subDomainInput}
            label="Enter Login Url* "
            placeholder="E.g. https://www.example.com"
            value={formData.loginLink}
            error={error.loginLink}
            onChange={(e) => {
              handleChange("loginLink", e.target.value);
            }}
          />
          </div>
          < div className={styles.subDomainSection}>
          <TextField
            className={styles.subDomainInput}
            id="outlined-multiline-static"
            label="certificate* "
            multiline
            rows={5}
            variant="outlined"
            placeholder=""
            value={formData.certificate}
            
            error={error.certificate}
            onChange={(e) => {
              handleChange("certificate", e.target.value);
            }}
          />
          </div>
          <div className={styles.subDomainSection}>
          <InputText
            className={styles.subDomainInput}
            label="Enter Login Label "
            placeholder=""
            value={formData.loginLabel }
            error={error.loginLabel}
            
            onChange={(e) => {
              handleChange("loginLabel", e.target.value);
            }}
          />
          </div>
          <div className={styles.subDomainSection}>
          <InputText
            className={styles.subDomainInput}
            label="Enter Name Field"
            placeholder="E.g. letsving"
            value={formData.nameField }
            error={samlProvider !=="microsoft"?error.nameField:""}
            
            onChange={(e) => {
              handleChange("nameField", e.target.value);
            }}
          />
          </div>
          
          <div className={styles.subDomainSection}>
          <InputText
            className={styles.subDomainInput}
            label="Enter Email Field "
            placeholder="E.g. hello@letsving.com"
            value={formData.emailField }
            error={samlProvider !=="microsoft"?error.emailField:""}

            onChange={(e) => {
              handleChange("emailField", e.target.value);
            }}
          />
        </div></>:null}
       
        <div className={styles.saveSubDomainBtnWrapper}>
          <CustomButton
            children={validateLoader ? "Saving..." : "Save"}
            className={`${styles.addDomainBtn} primaryBtn ${validateLoader  ? "disabledBtn" : ""}`}
            onClick={(e) => handleSubmit(e)}
          />
        </div>
      </div>


    </div>
  );
}

