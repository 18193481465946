import React, { useState, useEffect } from "react";
import styles from "../../../assets/styles/workspaces.module.scss";
import { imageBasePath } from "../../../constants";
import { InputText, CustomLink, NoResult } from "../../../shared_ui_components";
import { Grid, Dialog } from "@material-ui/core";
import AddWorkspaceDialog from "./AddWorkspaceDialog";
import { useParams } from "react-router";
import { getWorkspaceListing } from "../apiServices";
import { SingleWorkSpaceList } from "./SingleWorkSpaceList";
import Shimmer from "./Shimmer";
import { getUserInfo, sortObj } from "../../../utils";

export default function WorkspacesList(props) {
  const { id } = useParams();

  const [newWorkspace, setNewWorkspace] = useState(false);
  const [workspaceLists, setWorkspaceLists] = useState([]);
  const [loading, setLoading] = useState(true);
  const [noNewWorkspaceList, setNoNewWorkspaceList] = useState(false);
  const [search, setSearch] = useState({
    val: "",
    list: [],
  });
  const [sort, setSort] = useState({
    field: "",
    asc: false,
    defaultSortUpImg: "",
    defaultSortDownImg: "",
  });

  const getAllWorkspaceList = () => {
    getWorkspaceListing({ orgId: id }).then((response) => {
      if (response.status && response.data.length > 0) {
        setWorkspaceLists(response.data);
        setLoading(false);
        setNoNewWorkspaceList(false);
      } else {
        setLoading(false);
        setNoNewWorkspaceList(true);
      }
    });
  };

  useEffect(() => {
    getAllWorkspaceList();
  }, [id]);

  const handleClickOpen = () => {
    setNewWorkspace(true);
  };

  const handleClose = () => {
    setNewWorkspace(false);
  };

  const userInfo = getUserInfo();

  const handleSearch = (val) => {
    setSearch((prevSearch) => ({
      val: val.toLowerCase(),
      list: workspaceLists.filter((workspace) =>
        workspace?.orgLocation?.orgWorkSpaceName?.toLowerCase().includes(val.toLowerCase())
      ),
    }));
  };

  const handleSort = (sortObject) => {
    // debugger;
    let data = workspaceLists.map((workspace) => {
      return workspace.orgLocation;
    });
    let list = sortObj(sortObject.field, data, sortObject.asc, sortObject.caseSensitive);
    let newList = [];
    list.forEach((workspace) => {
      newList = [
        ...newList,
        ...[
          {
            orgLocation: workspace,
            rooms: workspaceLists.find((wspace) => wspace.orgLocation._id == workspace._id)?.rooms,
            localAdmins: workspaceLists.find((wspace) => wspace.orgLocation._id == workspace._id)
              ?.localAdmins,
          },
        ],
      ];
    });

    setSort({
      field: sortObject.field,
      asc: sortObject.asc,
      defaultSortUpImg: sortObject.asc ? imageBasePath + `asc_arrow.svg` : imageBasePath + "up_arrow.svg",
      defaultSortDownImg: sortObject.asc ? imageBasePath + "down_arrow.svg" : imageBasePath + `des_arrow.svg`,
    });
    setWorkspaceLists(newList);
  };

  return (
    <>
      {noNewWorkspaceList === false ? (
        <div>
          {loading ? (
            <Shimmer type="workspaceSearch" />
          ) : (
            <div className={styles.workspaceSearchBlk}>
              <Grid container direction="row" justifyContent="flex-end" alignItems="center">
                <Grid item md={5} className={styles.workspaceSearchWrapper}>
                  <div className={styles.workspaceSearchInput}>
                    <InputText placeholder="Search here" onChange={(e) => handleSearch(e.target.value)} />
                    <img src={imageBasePath + "search_icon.svg"} alt="" />
                  </div>
                  <div className={styles.newWorkspace}>
                    {userInfo.userData?.role?.responsibilities?.createWorkspace ? (
                      <>
                        <CustomLink
                          onClick={handleClickOpen}
                          className={styles.newWorkspaceButton + " outlinedBtn "}
                          text="New Workspace"
                        />
                        <Dialog open={newWorkspace} onClose={handleClose}>
                          <AddWorkspaceDialog
                            handleClose={handleClose}
                            getAllWorkspaceList={getAllWorkspaceList}
                          />
                        </Dialog>
                      </>
                    ) : null}
                  </div>
                </Grid>
              </Grid>
            </div>
          )}
          <Grid container>
            <Grid item md={12}>
              <div className={styles.workspacesList}>
                <div className={styles.workspacesListInnerBlk}>
                  {loading ? (
                    <Shimmer type="workspaceHeader" />
                  ) : (
                    <div className={styles.workspacesListTableTitle}>
                      <Grid container spacing={2}>
                        <Grid item md={12}>
                          <Grid
                            container
                            direction="row"
                            spacing={2}
                            justifyContent="flex-start"
                            alignItems="center"
                          >
                            <Grid item md={3}>
                              <div className={styles.workspacesTableTitleTextWrapper}>
                                <p className={styles.workspacesTableTitleText + " paragraph "}>
                                  Workpace Name
                                </p>
                                <img
                                  src={
                                    sort.field === "orgWorkSpaceName"
                                      ? sort.defaultSortUpImg
                                      : imageBasePath + "up_arrow.svg"
                                  }
                                  alt=""
                                  className="sortUp"
                                  onClick={() =>
                                    handleSort({
                                      field: "orgWorkSpaceName",
                                      asc: !sort.asc,
                                      caseSensitive: true,
                                    })
                                  }
                                />
                                <img
                                  src={
                                    sort.field === "orgWorkSpaceName"
                                      ? sort.defaultSortDownImg
                                      : imageBasePath + "down_arrow.svg"
                                  }
                                  alt=""
                                  className="sortDown"
                                  onClick={() =>
                                    handleSort({
                                      field: "orgWorkSpaceName",
                                      asc: !sort.asc,
                                      caseSensitive: true,
                                    })
                                  }
                                />
                              </div>
                            </Grid>
                            <Grid item md={4}>
                              <div className={styles.workspacesTableTitleTextWrapper}>
                                <p className={styles.workspacesTableTitleText + " paragraph "}>Address</p>
                              </div>
                            </Grid>
                            <Grid item md={2}>
                              <div className={styles.workspacesTableTitleTextWrapper}>
                                <p className={styles.workspacesTableTitleText + " paragraph "}>Rooms</p>
                              </div>
                            </Grid>
                            <Grid item md={3}>
                              <div className={styles.workspacesTableTitleTextWrapper}>
                                <p className={styles.workspacesTableTitleText + " paragraph "}>
                                  Local Admins
                                </p>
                              </div>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </div>
                  )}

                  <div className={styles.workspacesListTableContent}>
                    {loading ? (
                      <Shimmer type="singleWorkSpaceList" />
                    ) : search.val ? (
                      <>
                        {search.list.length === 0 ? (
                          <NoResult image="no_workspace.png" title="No workspace found" />
                        ) : (
                          search.list.map((item) => (
                            <div key={item?.orgLocation?._id}>
                              <SingleWorkSpaceList {...item} />
                            </div>
                          ))
                        )}
                      </>
                    ) : (
                      workspaceLists?.map((item) => (
                        <div key={item?.orgLocation?._id}>
                          <SingleWorkSpaceList {...item} />
                        </div>
                      ))
                    )}
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      ) : (
        <div className={styles.noWorkspaceWrapper}>
          <img src={`${imageBasePath}no_workspace.png`} alt="" />
          <p className={styles.noWorkspace + " subheading2"}>You have no workspace setup yet</p>
          <p className={styles.noWorkspaceSubpara + " paragraph"}>
            Start by adding information about your organisation's operational locations
          </p>
          {userInfo.userData?.role?.responsibilities?.createWorkspace ? (
            <div className={styles.newWorkspaceBtnWrapper}>
              <CustomLink
                onClick={handleClickOpen}
                className={styles.newWorkspaceBtn + " outlinedBtn "}
                text="+ Add Workspace"
              />
              <Dialog open={newWorkspace} onClose={handleClose}>
                <AddWorkspaceDialog handleClose={handleClose} getAllWorkspaceList={getAllWorkspaceList} />
              </Dialog>
            </div>
          ) : null}
          <p className={styles.noWorkspaceSubpara + " paragraph"}>
            You can download the <span style={{ color: "blue" }}>CSV Template file</span> here
          </p>
        </div>
      )}
    </>
  );
}
