import React, { useState, useEffect } from "react";
import { Grid, List, ListItem } from "@material-ui/core";
import styles from "../../../assets/styles/orders.module.scss";
import { imageBasePath, errorCodes, reactSelectStylesMediumSize } from "../../../constants";
import moment from "moment";
import {
  CustomButton,
  CustomLink,
  InputSelect,
  CustomDatePicker,
  InputText,
  NoResult,
} from "../../../shared_ui_components";
import { DownloadLink, withRouter } from "react-router-dom";
import { OrderShippingDetail } from "./OrderShippingDetail";
import { getUserInfo, toastFlashMessage } from "../../../utils";
import { fieldValidation } from "../../../utils/formValidation";
import { updateAdminOrderPaymentStatus, getOrderDetail } from "../apiServices";
import Shimmer from "./Shimmer";

function OrderListDetail(props) {
  const { handleClose, getAllAdminOrdersList, orderId } = props;
  const userInfo = getUserInfo();

  const [orderDetailList, setOrderDetailLits] = useState({});
  const [paymenstStatusOptions, setPaymenstStatusOptions] = useState([]);
  const [shipmentStatusOptions, setShipmentStatusOptions] = useState([]);
  const [error, setError] = useState({});
  const [validateLoader, setValidateLoader] = useState(false);
  const [loading, setLoading] = useState(true);
  const [count, setCount] = useState(1);
  const [formData, setFormData] = useState({
    paymentStatus: "",
    nextPaymentDueDate: "",
    shipmentDetails: [],
  });

  const paymentStatusSelection = (status) => {
    setPaymenstStatusOptions([
      {
        value: status === "Pending" ? status : "Pending",
        label: status === "Pending" ? status : "Pending",
      },
      {
        value: status === "paid" ? status : "Paid",
        label: status === "paid" ? status : "Paid",
      },
      {
        value: status === "partiallyPaid" ? status : "Partially Paid",
        label: status === "partiallyPaid" ? status : "Partially Paid",
      },
    ]);
  };

  const shipmentStatusSelection = (status) => {
    setShipmentStatusOptions([
      {
        value: status === "success" ? status : "success",
        label: status === "success" ? "Delivered" : "Delivered",
      },
      {
        value: status === "Pending" ? status : "Pending",
        label: status === "Pending" ? "Pending" : "Pending",
      },
      {
        value: status === "dispatched" ? status : "dispatched",
        label: status === "dispatched" ? "Dispatched" : "Dispatched",
      },
    ]);
  };

  useEffect(() => {
    getOrderDetail({ orderId: orderId }).then((response) => {
      if (response.status && Object.keys(response.orderDetails).length > 0) {
        setOrderDetailLits(response.orderDetails);
        paymentStatusSelection(response.orderDetails?.paymentStatus);
        shipmentStatusSelection(response.orderDetails?.shippingDetails?.map((ele) => ele.deliveredStatus));
        const updatedShippingDetails = [];
        response.orderDetails?.shippingDetails.map((ele, idx) =>
          updatedShippingDetails.push({
            ...ele,
            expectedDeliveryDate: moment(ele.expectedDeliveryDate, "DD-MM-YYYY"),
            deliveredStatus: ele.deliveredStatus,
            id: idx + 1,
          })
        );
        setCount(updatedShippingDetails.length + 1);

        setFormData((form) => ({
          ...form,
          paymentStatus: response.orderDetails?.paymentStatus,
          nextPaymentDueDate: response.orderDetails?.nextPaymentDueDate
            ? moment(response.orderDetails?.nextPaymentDueDate).local().toDate()
            : null,
          shipmentDetails: updatedShippingDetails,
        }));
        setLoading(false);
      } else {
        setLoading(false);
      }
    });
  }, [orderId]);

  const handleChange = (key, value, index) => {
    if (index > -1) {
      setFormData((form) => ({
        ...form,
        shipmentDetails: form.shipmentDetails.map((sDetails, _index) =>
          index === _index ? { ...sDetails, [key]: value } : { ...sDetails }
        ),
      }));
    } else {
      setFormData((form) => ({
        ...form,
        [key]: value,
      }));
    }
    setError((preError) => ({
      ...preError,
      [key]: "",
    }));
  };

  const handleAddNewShipment = () => {
    setCount((prev) => prev + 1);
    setFormData((form) => ({
      ...form,
      shipmentDetails: [
        ...[
          {
            id: count,
            units: "",
            expectedDeliveryDate: null,
            trackikngLink: "",
          },
        ],
        ...form.shipmentDetails,
      ],
    }));
    // setError({});
  };
  const handleRemoveShippingDetails = (id) => {
    setFormData((form) => ({
      ...form,
      shipmentDetails: formData?.shipmentDetails?.filter((shippingDetails) => shippingDetails.id !== id),
    }));
  };

  const handleSubmit = () => {
    // debugger;
    let validateNewInput = {};
    validateNewInput = {
      ...validateNewInput,
      [`paymentStatus`]:
        errorCodes["paymentStatus"][
          fieldValidation({
            ...errorCodes.paymentStatusObj,
            fieldval: formData.paymentStatus,
          })
        ],
      [`nextPaymentDueDate`]:
        errorCodes["nextPaymentDueDate"][
          fieldValidation({
            ...errorCodes.nextPaymentDueDateObj,
            fieldval: formData.nextPaymentDueDate,
          })
        ],
    };
    formData?.shipmentDetails?.forEach((item, idx) => {
      if (item?._id ? idx : formData?.shipmentDetails?.length - 1 - idx + 1 === item.id && item._id) {
        validateNewInput = {
          ...validateNewInput,
          [`deliveredStatus-${idx}`]:
            errorCodes["deliveredStatus"][
              fieldValidation({
                ...errorCodes.deliveredStatusObj,
                fieldval: item.deliveredStatus,
              })
            ],
        };
      }
      if (item?._id ? idx : formData?.shipmentDetails?.length - 1 - idx + 1 === item.id) {
        validateNewInput = {
          ...validateNewInput,
          [`units-${idx}`]:
            errorCodes["units"][
              fieldValidation({
                ...errorCodes.unitsObj,
                fieldval: item.units,
              })
            ],
          [`expectedDeliveryDate-${idx}`]:
            errorCodes["expectedDeliveryDate"][
              fieldValidation({
                ...errorCodes.expectedDeliveryDateObj,
                fieldval: item.expectedDeliveryDate,
              })
            ],
          [`trackikngLink-${idx}`]:
            errorCodes["trackikngLink"][
              fieldValidation({
                ...errorCodes.trackikngLinkObj,
                fieldval: item.trackikngLink,
              })
            ],
        };
      }
    });

    if (Object.keys(validateNewInput).every((k) => validateNewInput[k] == "")) {
      const newShippingDetails = [];
      formData.shipmentDetails.map((ele) =>
        newShippingDetails.push({
          ...ele,
          expectedDeliveryDate: moment(ele?.expectedDeliveryDate).format("DD-MM-yyyy"),
        })
      );
      const updateOrderDetails = {
        orderId: orderDetailList?._id,
        paymentStatus: formData.paymentStatus,
        nextPaymentDueDate: formData.nextPaymentDueDate,
        shipmentDetails: newShippingDetails,
      };
      setValidateLoader(true);
      updateAdminOrderPaymentStatus(updateOrderDetails).then((response) => {
        if (response.status) {
          getAllAdminOrdersList();
          toastFlashMessage("order details updated succesfully", "success");
          setValidateLoader(false);
          handleClose(false);
        } else {
          toastFlashMessage("something went wrong while updating order details", "error");
          setValidateLoader(false);
        }
      });
    } else {
      setError(validateNewInput);
      setValidateLoader(false);
    }
  };

  const handleClickLink = (trackingLink) => {
    window.open(trackingLink);
  };

  return (
    <>
      {loading ? (
        <Shimmer type="orderDetails" />
      ) : (
        <Grid container justifyContent="center" alignItems="center">
          <Grid item md={12}>
            <div className={styles.orderRightDrawer}>
              <div className={styles.orderDetailWrapper}>
                <div className={styles.orderDetailBlk}>
                  <div className={styles.orderLeftBlk}>
                    <div className={styles.orderImageWrapper}>
                      {/* <img src={imageBasePath + "order_box.svg"} className={styles.orderBox} alt="" />
                      <img
                        src={imageBasePath + "order_status_pending.svg"}
                        className={styles.orderBoxStatus}
                        alt=""
                      /> */}
                      <img
                        src={
                          orderDetailList?.paymentStatus === "Pending" ||
                          orderDetailList?.paymentStatus === "pending"
                            ? imageBasePath + "Order_Not_Shipped.svg"
                            : orderDetailList?.paymentStatus === "Paid"
                            ? imageBasePath + "Order_Delivered.svg"
                            : imageBasePath + "Order_Shipped.svg"
                        }
                        className={styles.shipmentBox}
                        alt=""
                      />
                    </div>
                    <p className={styles.quoteNumber + " paragraphSemiBold "}>
                      #{orderDetailList?._id?.slice(-4)}
                    </p>
                  </div>
                  <div className={styles.orderRightBlk}>
                    <a href={""} download target="_blank">
                      <CustomLink
                        className={styles.userDetailDownloadLink + " paragraphSemiBold"}
                        text="Download invoice"
                        leftIcon={imageBasePath + "download_invoice.svg"}
                      />
                    </a>
                  </div>
                </div>
                <Grid container direction="row" spacing={2} justifyContent="center" alignItems="flex-start">
                  <Grid item md={2}>
                    <List className={styles.orderDetailTitles}>
                      <ListItem>Order Placed On</ListItem>
                      <ListItem>Quantity</ListItem>
                      <ListItem>Subcription Period</ListItem>
                    </List>
                  </Grid>
                  <Grid item md={4}>
                    <List className={styles.orderDetailDescriptions}>
                      <ListItem>{moment(orderDetailList?.purchaseDate).format("DD/MM/YYYY")}</ListItem>
                      <ListItem>{orderDetailList?.units}</ListItem>
                      <ListItem>
                        {orderDetailList?.subscriptionPeriod / 12 < 1
                          ? `${orderDetailList?.subscriptionPeriod} Months`
                          : orderDetailList?.subscriptionPeriod / 12 == 1
                          ? `${orderDetailList?.subscriptionPeriod / 12} Year`
                          : `${orderDetailList?.subscriptionPeriod / 12} Years`}
                      </ListItem>
                    </List>
                  </Grid>
                  <Grid item md={2}>
                    <List className={styles.orderDetailTitles}>
                      <ListItem>Billed To</ListItem>
                      <ListItem>Partner</ListItem>
                      <ListItem>Delivery Address</ListItem>
                    </List>
                  </Grid>
                  <Grid item md={4}>
                    <List className={styles.orderDetailDescriptions}>
                      <ListItem>
                        {/* {orderDetailList?.orgDetails?.orgName ? (
                          orderDetailList?.orgDetails[0]?.orgName
                        ) : (
                          <span>-</span>
                        )} */}
                        {orderDetailList?.orgId?.orgName}
                      </ListItem>
                      <ListItem>{orderDetailList?.name}</ListItem>
                      <ListItem>
                        {orderDetailList?.addressId?.address}, {orderDetailList?.addressId?.cityName},{" "}
                        {orderDetailList?.addressId?.stateName}, {orderDetailList?.addressId?.pincode}
                      </ListItem>
                    </List>
                  </Grid>
                </Grid>
              </div>
              <div className={styles.paymentDetailWrapper}>
                <div className={styles.paymentDetailHeader}>
                  <p className={styles.paymentDetailTitle + " subheading2"}>
                    Payment Details
                    <span className={styles.paymentDetailDate}>{`Last Updated On ${moment(
                      orderDetailList?.updatedAt
                    ).format("DD/MM/YYYY")}`}</span>
                  </p>
                </div>
                {userInfo?.userData?.role?.roleName === "vingAdmin" ? (
                  <div className={styles.paymentAdminUpdate}>
                    <InputSelect
                      //className={styles.selectStatus}
                      label="Payment Status"
                      placeholder="Select status"
                      isSearchable={false}
                      styles={reactSelectStylesMediumSize}
                      noOptionsMessage={() => "No payment status"}
                      options={paymenstStatusOptions}
                      onChange={(pStatus) => handleChange("paymentStatus", pStatus.value)}
                      value={
                        formData.paymentStatus
                          ? paymenstStatusOptions.find((pStatus) => pStatus.value === formData.paymentStatus)
                          : null
                      }
                      error={error.paymentStatus}
                    />
                    <CustomDatePicker
                      label="Next Payment Due Date"
                      className={styles.selectDate}
                      keyboardIcon={<img src={imageBasePath + "calendar-icon.svg"} alt="" />}
                      value={formData.nextPaymentDueDate}
                      onChange={(date) => handleChange("nextPaymentDueDate", date)}
                      error={error.nextPaymentDueDate}
                      id={formData.nextPaymentDueDate === null ? "date-picker" : "date-picker-inline"}
                    />
                  </div>
                ) : (
                  <div className={styles.paymentCardWrapper}>
                    <div className={styles.paymentCardStatus}>
                      <img src={imageBasePath + "payment_info.svg"} alt="" />
                      <p
                        className={
                          orderDetailList?.paymentStatus === "Pending" ||
                          orderDetailList?.paymentStatus === "pending"
                            ? styles.cardStatusPending
                            : orderDetailList?.paymentStatus === "Paid"
                            ? styles.cardStatusPaid
                            : styles.cardStatusPartiallyPaid + " paragraph"
                        }
                      >
                        {orderDetailList?.paymentStatus === "pending"
                          ? "Pending"
                          : orderDetailList?.paymentStatus}
                      </p>
                    </div>
                    <div>
                      {orderDetailList?.nextPaymentDueDate && (
                        <p className={styles.paymentDueWrapper + " paragraph"}>
                          Next Payment Due Date
                          <span className={styles.paymentDueDate + " paragraph"}>
                            {moment(orderDetailList?.nextPaymentDueDate).format("DD/MM/YYYY")}
                          </span>
                        </p>
                      )}
                    </div>
                  </div>
                )}
                {/*  */}
              </div>

              <div className={styles.shipmentDetailWrapper}>
                <div className={styles.shipmentDetailHeader}>
                  <p className={styles.shipmentDetailTitle + " subheading2"}>
                    Shipment Details
                    {userInfo?.userData?.role?.roleName === "vingAdmin" && (
                      <span className={styles.shipmentDetailDate}>
                        Last Updated On {"pending from backend"}
                      </span>
                    )}
                  </p>
                  {userInfo?.userData?.role?.roleName === "vingAdmin" && (
                    <CustomLink
                      className={styles.newShipmentButton + " outlinedBtn "}
                      text="New Shipment"
                      leftIcon={imageBasePath + "invite_user.svg"}
                      onClick={handleAddNewShipment}
                    />
                  )}
                </div>
                {userInfo?.userData?.role?.roleName === "vingAdmin" ? (
                  formData?.shipmentDetails?.map((item, index) => (
                    <div className={styles.shipmentAdminDetailList}>
                      {item._id && (
                        <>
                          <div className={styles.shipmentNumberWrapper}>
                            <p className={styles.shipmentNumberTitle + " label"}>Shipment No: </p>
                            <p className={styles.shipmentNumber + " paragraph"}>
                              {item._id ? `#${item._id.slice(-4)}` : "-"}
                            </p>
                          </div>
                          <div>
                            <InputSelect
                              //className={styles.selectShipmentStatus}
                              label="Shipment Status"
                              isSearchable={false}
                              placeholder="Select Status"
                              noOptionsMessage={() => "No shipment status found"}
                              styles={reactSelectStylesMediumSize}
                              options={shipmentStatusOptions}
                              value={
                                item.deliveredStatus
                                  ? shipmentStatusOptions.find(
                                      (sStatus) => sStatus.value === item.deliveredStatus
                                    )
                                  : null
                              }
                              onChange={(sStatus) => handleChange("deliveredStatus", sStatus.value, index)}
                              error={error?.[`deliveredStatus-${index}`]}
                            />
                          </div>
                        </>
                      )}
                      <div>
                        <InputText
                          type="number"
                          label="Units Shipped"
                          className={styles.shipmentNumStatus}
                          placeholder="50"
                          value={item.units}
                          onChange={(e) => handleChange("units", e.target.value, index)}
                          error={error?.[`units-${index}`]}
                        />
                      </div>
                      <div>
                        <CustomDatePicker
                          label="Expected Delivery Date"
                          className={styles.selectDeliveryDate}
                          placeholder="Select a date"
                          keyboardIcon={<img src={imageBasePath + "calendar-icon.svg"} alt="" />}
                          value={item.expectedDeliveryDate}
                          onChange={(date) => handleChange("expectedDeliveryDate", date, index)}
                          error={error?.[`expectedDeliveryDate-${index}`]}
                          id={item.expectedDeliveryDate === null ? "date-picker" : "date-picker-inline"}
                        />
                      </div>
                      <div>
                        <InputText
                          label="Tracking Link"
                          className={styles.shipmentTrackLink}
                          placeholder="https//www.bluedart.com..."
                          value={item.trackikngLink}
                          onChange={(e) => handleChange("trackikngLink", e.target.value, index)}
                          error={error?.[`trackikngLink-${index}`]}
                          onClick={() => handleClickLink(item.trackikngLink)}
                        />
                      </div>
                      {!item._id && (
                        <img
                          src={imageBasePath + "delete-bin-line.svg"}
                          alt=""
                          onClick={() => handleRemoveShippingDetails(item.id)}
                          className={styles.removeUsersBtn}
                        />
                      )}
                    </div>
                  ))
                ) : orderDetailList?.shippingDetails?.length === 0 ? (
                  <NoResult image="no_order.png" title="No Shipping details found" subtext="" />
                ) : (
                  orderDetailList?.shippingDetails?.map((shippingDetail, key) => {
                    return <OrderShippingDetail key={key} {...shippingDetail} />;
                  })
                )}
                {/* {
                  !orderDetailList?.shippingDetails?.map((shippingDetail, key) => {
                    return (
                      !userInfo?.userData?.role?.roleName === "vingAdmin" && (
                        <OrderShippingDetail key={key} {...shippingDetail} />
                      )
                    );
                  })
                } */}
              </div>
            </div>
            {userInfo?.userData?.role?.roleName === "vingAdmin" && (
              <div className={styles.updateButtonWrapper}>
                <Grid container justifyContent="flex-end" alignItems="center">
                  <Grid item md={6}>
                    <CustomButton
                      children={validateLoader ? "Updating..." : "Update"}
                      className={`${styles.updateBtn} primaryBtn ${validateLoader ? "disabledBtn" : ""}`}
                      onClick={handleSubmit}
                    />
                  </Grid>
                </Grid>
              </div>
            )}
          </Grid>
        </Grid>
      )}
    </>
  );
}

export default withRouter(OrderListDetail);
