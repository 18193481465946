import AddOrganisation from "./container/AddOrganisation";
import HomeLayoutHoc from "../../hoc/homeLayoutHoc";
export const addOrganisationRoutes = [
  {
    path: "/add-organisation",
    component: HomeLayoutHoc(AddOrganisation),
    key: "add-organisation",
  },
  {
    path: "/:id/edit-organisation",
    component: HomeLayoutHoc(AddOrganisation),
    key: "edit-organisation",
  },
];
