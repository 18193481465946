import React, { useState, useEffect, useContext } from "react";
import styles from "../../../assets/styles/team.module.scss";
import { imageBasePath, errorCodes, reactSelectStylesLarge } from "../../../constants";
import { InputSelect, InputText, CustomLink, CustomButton } from "../../../shared_ui_components";
import { inviteTeamMembersLists } from "../apiServices";
import { fieldValidation } from "../../../utils/formValidation";
import { toastFlashMessage } from "../../../utils";
import { GlobalContext } from "../../../context/GlobalContext";

export default function InviteTeamDialog(props) {
  const [workspaceLists, setWorkspaceLists] = useState([]);
  const [count, setCount] = useState(2);
  const [validateLoader, setValidateLoader] = useState(false);
  const [error, setError] = useState({});
  const [orgNames, setOrgNames] = useState([]);
  const {
    state: { orgListNames },
  } = useContext(GlobalContext);
  console.log(orgListNames)
  useEffect(() => {
    const data = orgListNames.map((name) => {
      return { label: name.orgName, value: name._id };
    });
    setOrgNames(data);
    console.log(orgNames)
  }, [orgListNames]);
  const [form, setForm] = useState({
    inviteUsers: [
      {
        id: 1,
        email: "",
        roleId: "612692d6b0e7af1c107dd337",
        orgId: ''
      },
    ],
  });

  const handleAddUsers = () => {
    setCount((prev) => prev + 1);
    setForm({
      ...form,
      inviteUsers: [
        ...form.inviteUsers,
        ...[
          {
            id: count,
            email: "",
            roleId: "612692d6b0e7af1c107dd337"
          },
        ],
      ],
    });
    setError({});
  };

  const handleChange = (key, value, index) => {
    if (key === "orgId") {
     
      setForm((formData) => ({
        ...formData,
        [key]: value,
      }));
      setForm((errors) => ({
        ...errors,
        [key]: "",
      })); 
      console.log(form)
    }
    setForm((preForm) => ({
      ...preForm,
      inviteUsers: preForm.inviteUsers.map((users, _index) =>
        index === _index
          ? {
              ...users,
              [key]: value,
              roleName: orgNames.find((ele) => ele.value === value)?.label,
            }
          : { ...users }
      ),
    }));
  };
  const handleRemoveUsers = (id) => {
    const updatedUsers = form?.inviteUsers?.filter((users) => users.id !== id);
    setForm({
      ...form,
      inviteUsers: updatedUsers,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let validateNewInput = {};
    form?.inviteUsers?.forEach((item, idx) => {
      if (idx + 1 === item.id) {
        validateNewInput = {
          ...validateNewInput,
          [`email-${idx}`]:
            errorCodes["email"][
              fieldValidation({
                ...errorCodes.emailObj,
                fieldval: item.email,
              })
            ]
        };
      }
    });

    if (Object.keys(validateNewInput).every((k) => validateNewInput[k] == "")) {
      setValidateLoader(true);
      inviteTeamMembersLists({ teamMembers: [...form.inviteUsers] }).then((response) => {
        if (response.data) {
          setValidateLoader(false);
          toastFlashMessage("User invited succesfully", "success");
          props.handleClose();
          props.getAllTeamMemberLists();
        } else {
          toastFlashMessage("Something went wrong while inviting users", "error");
          setValidateLoader(false);
        }
      });
    } else {
      setError(validateNewInput);
    }
  };

  return (
    <div className={styles.userDialogWrapper}>
      <div className={styles.userDialogTitle}>
        <p className={styles.userDialogHeader + " subheading"}>Invite team member</p>
        <div onClick={props.handleClose} style={{ cursor: "pointer" }}>
          <img src={imageBasePath + "user_cancel.svg"} alt="" />
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className={styles.userDialogDetail}>
          {form?.inviteUsers?.map((item, index) => {
            return (
              <React.Fragment key={index}>
                <div className={styles.userDialogInput}>
                  <InputText
                    className={styles.userInputEmail}
                    placeholder="Johndoe@gmail.com"
                    value={item.email}
                    onChange={(e) => handleChange("email", e.target.value.trim(), index)}
                    //   error={
                    //     item.isEmailError && item.email.length != 0 && item.email.includes("@")
                    //       ? ""
                    //       : error?.[`email-${index}`]
                    //   }
                    error={error?.[`email-${index}`]}
                  />
                   <InputSelect
                    className={styles.userInputOrg}
                    placeholder="Choose Org"
                    options={orgNames}
                    styles={reactSelectStylesLarge}
                    value={item.orgId ? orgListNames.find((org) => org.value === item.orgId) : null}
                    onChange={(org) => handleChange("orgId", org.value, index)}
                    //value={item.roleId ? orgListNames.find((org) => org.value === item.roleId) : null}
                    error={error?.[`roleId-${index}`]}
                    isSearchable={false}
                  />
                  {index > 0 && (
                    <img
                      src={imageBasePath + "delete-bin-line.svg"}
                      alt=""
                      onClick={() => handleRemoveUsers(item.id)}
                      className={styles.removeUsersBtn}
                    />
                  )}
                </div>
              </React.Fragment>
            );
          })}
        </div>
        <div className={styles.userDialogButtonWrapper}>
          <CustomLink
            className={styles.userButtonAddMore + " paragraphSemiBold"}
            text="Add User"
            leftIcon={imageBasePath + "invite_user.svg"}
            onClick={handleAddUsers}
          />
          <CustomButton
            className={`${styles.inviteBtn} primaryBtn ${validateLoader ? "disabledBtn" : ""}`}
            children={validateLoader ? "Inviting..." : "Invite"}
            rightIcon={imageBasePath + "btn_arrow_right_icon.svg"}
            type="submit"
            //onClick={() => handleSubmit()}
          />
        </div>
      </form>
    </div>
  );
}
