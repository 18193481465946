import React, { useState, useRef } from 'react'
import { Container , Button,Grid, List, ListItem} from '@material-ui/core';
import styles from '../../../assets/styles/purchase.module.scss';
import { imageBasePath, videoBasePath }  from '../../../constants';
import VideoDialog from './VideoDialog'


function VideoSection(props) {

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
      setOpen(true);
    };

    const handleClose = () => {
      setOpen(false);
      vidRef.current.play();
    };

    const vidRef = useRef(null);

    const handlePauseVideo = () => {
      vidRef.current.pause();
    }

    return (
        <section className={styles.videoSection}>
            <Container maxWidth="lg" >
                <div className={styles.videoWrapper+' backgroundProp'}>
                    <img onClick={() => {handleClickOpen(); handlePauseVideo()}} className={styles.playIcon + ' ' +styles.playIconWebsite} src={imageBasePath+'play_button.svg'} alt="Play icon"/>
                    
                    <video
                        key={"landing_intro"}
                        loop={true}
                        autoPlay=""
                        preload="auto"
                        muted
                        poster={imageBasePath+'thumbnail.png'}
                        className="videoPlayer"
                        ref={vidRef}
                        >
                        <source
                            src={videoBasePath+'take_assessment.mp4'}
                            type="video/mp4"
                        />
                        Your browser does not support the video tag.
                    </video>
                </div>
            {
            <VideoDialog
              open={open}
              onClose={handleClose}
              activeMedia={"take_assessment.mp4"}
            />
            }
            </Container>
        </section>
    );
}

export default VideoSection;