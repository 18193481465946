import DeploymentsDetails from "./container/DeploymentsDetails";
import QuotationLayoutHoc from "../../hoc/quotationLayoutHoc";
import DeviceDeploymentDetails from "./container/DeviceDeploymentDetails";
export const deploymentsRoute = [
  {
    path: "/:id/deployments",
    component: QuotationLayoutHoc(DeploymentsDetails),
    key: "deployments-org",
  },
  {
    path: "/:id/deployments/:_id",
    component: QuotationLayoutHoc(DeviceDeploymentDetails),
    key: "deployment-info",
  },
];
