import React from 'react';
import { Container, Grid } from "@material-ui/core";
import styles from "../../../assets/styles/legal.module.scss";

export default function PrivacyPolicy() {
    return (
        <div>
            <Container maxWidth="lg">
                <Grid container direction="row" justifyContent="center" spacing={2} >
                    <Grid item md={12}>
                        <h1 className={styles.legalHeading+" heading4"}>Privacy Policy</h1>
                        <h4 className={styles.legalOverview+" heading6"}>Overview</h4>
                        <p className={styles.termText + " paragraph"}>
                            LetsVing Technologies India Pvt Ltd.. (our legal entity) respects and cares 
                            about your personal data and information, and we appreciate your trust 
                            that we will do so carefully and sensibly. This notice describes our privacy 
                            policy for www.LetsVing.com web site (the "Website"), including how 
                            LetsVing Technologies treats personal information that LetsVing 
                            Technologies collects and receives. Pursuant to LetsVing Technologies 's 
                            Terms of Use, you must be 18 years and older to use this site or have 
                            permission from a legal guardian. Therefore, this policy makes no 
                            provision for individuals under 18 years old. This policy does not apply to
                            the practices of third parties that LetsVing Technologies does not own or
                            control, or individuals that LetsVing Technologies does not employ or 
                            manage. This policy is effective on August 30, 2020. If you have any 
                            questions or concerns regarding this statement, you should contact us at 
                            {" "}<a className={styles.legalLink}>hello@letsving.com</a>{" "}.
                        </p>
                        <h4 className={styles.legalPrivacy + " heading6"}>PRIVACY STATEMENT</h4>
                        <h5 className={styles.termTitles + " subheading"}>SECTION 1 - WHAT DO WE DO WITH YOUR INFORMATION?</h5>
                        <p className={styles.termText + " paragraph"}>
                            When you purchase something from our store, as part of the buying and 
                            selling process, we collect the personal information you give us such as 
                            your name, address and email address. When you browse our store, we 
                            also automatically receive your computer’s internet protocol (IP) address 
                            in order to provide us with information that helps us learn about your 
                            browser and operating system. Email marketing (if applicable): With your 
                            permission, we may send you emails about our store, new products and 
                            other updates.
                        </p>
                        <h5 className={styles.termTitles + " subheading"}>SECTION 2 - CONSENT</h5>
                        <h6 className={styles.subTitle}>How do you get my consent?</h6>
                        <p className={styles.termText + " paragraph"}>
                            When you provide us with personal information to complete a transaction,
                            verify your credit card, place an order, arrange for a delivery or 
                            return a purchase, we imply that you consent to our collecting it 
                            and using it for that specific reason only. If we ask for your personal
                             information for a secondary reason, like marketing, we will either 
                             ask you directly for your expressed consent, or provide you with 
                             an opportunity to say no.
                        </p>
                        <h6 className={styles.subTitle}>How do I withdraw my consent?</h6>
                        <p className={styles.termText + " paragraph"}>
                            If after you opt-in, you change your mind, you may withdraw your 
                            consent for us to contact you, for the continued collection, use or
                             disclosure of your information, at anytime, by contacting us at 
                            {" "}<a className={styles.legalLink}>hello@letsving.com</a>{" "}.
                        </p>
                        <h5 className={styles.termTitles + " subheading"}>SECTION 3 - DISCLOSURE</h5>
                        <p className={styles.termText + " paragraph"}>
                            We may disclose your personal information if we are required by
                             law to do so or if you violate our Terms of Service.
                        </p>
                        <h5 className={styles.termTitles + " subheading"}>SECTION 4 - Hosting</h5>
                        <p className={styles.termText + " paragraph"}>
                            Our store is hosted on Amazon S3 servers. Your data is stored through
                             Amazon’s data storage, databases. They store your data on a secure 
                             server behind a firewall.
                        </p>
                        <h6 className={styles.subTitle}>Payment:</h6>
                        <p className={styles.termText + " paragraph"}>
                            If you choose a direct payment gateway to complete your purchase, 
                            then the payment gateway stores your credit card data. It is encrypted 
                            through the Payment Card Industry Data Security Standard (PCI-DSS). 
                            Your purchase transaction data is stored only as long as is necessary 
                            to complete your purchase transaction. After that is complete, your 
                            purchase transaction information is deleted. All direct payment gateways 
                            adhere to the standards set by PCI-DSS as managed by the PCI Security 
                            Standards Council, which is a joint effort of brands like Visa, MasterCard, 
                            American Express and Discover. PCI-DSS requirements help ensure the secure 
                            handling of credit card information by our store and its service providers.
                        </p>
                        <h5 className={styles.termTitles + " subheading"}>SECTION 5 - THIRD-PARTY SERVICES</h5>
                        <p className={styles.termText + " paragraph"}>
                            In general, the third-party providers used by us will only collect, use 
                            and disclose your information to the extent necessary to allow them to 
                            perform the services they provide to us.However, certain third-party 
                            service providers, such as payment gateways and other payment transaction 
                            processors, have their own privacy policies in respect to the information 
                            we are required to provide to them for your purchase-related transactions. 
                            For these providers, we recommend that you read their privacy policies so 
                            you can understand the manner in which your personal information will be 
                            handled by these providers.In particular, remember that certain providers 
                            may be located in or have facilities that are located a different 
                            jurisdiction than either you or us. So if you elect to proceed with a 
                            transaction that involves the services of a third-party service provider, 
                            then your information may become subject to the laws of the jurisdiction(s) 
                            in which that service provider or its facilities are located.Once you 
                            leave our store’s website or are redirected to a third-party website or 
                            application, you are no longer governed by this Privacy Policy or our 
                            website’s Terms of Service.
                        </p>
                        <h6 className={styles.subTitle}>Links</h6>
                        <p className={styles.termText + " paragraph"}>
                            When you click on links on our store, they may direct you away from our 
                            site. We are not responsible for the privacy practices of other sites 
                            and encourage you to read their privacy statements.
                        </p>
                        <h5 className={styles.termTitles + " subheading"}>SECTION 6 - MEETING PROVIDER DATA</h5>
                        <p className={styles.termText + " paragraph"}>
                            Ving application provides ability to connect to meeting platforms like 
                            Zoom, Webex, etc to enable some of the features. As part of the linkage, 
                            the application might obtain your data from the meeting platforms. 
                            Real time data like today's meetings, etc are not stored. Certain data 
                            like authentication tokens, user email, etc are kept in the system until 
                            the user disconnects the linkage in the application.
                        </p>
                        <h5 className={styles.termTitles + " subheading"}>SECTION 7 - SECURITY</h5>
                        <p className={styles.termText + " paragraph"}>
                            To protect your personal information, we take reasonable precautions and 
                            follow industry best practices to make sure it is not inappropriately 
                            lost, misused, accessed, disclosed, altered or destroyed.If you provide 
                            us with your credit card information, the information is encrypted using 
                            secure socket layer technology (SSL) and stored with a AES-256 encryption. 
                            Although no method of transmission over the Internet or electronic storage 
                            is 100% secure, we follow all PCI-DSS requirements and implement additional 
                            generally accepted industry standards.
                        </p>
                        <h5 className={styles.termTitles + " subheading"}>SECTION 8 - COOKIES</h5>
                        <p className={styles.termText + " paragraph"}>
                            Here is a list of cookies that we use. We’ve listed them here so you that 
                            you can choose if you want to opt-out of cookies or not._session_id, unique 
                            token, sessional, Allows www.LetsVing.com to store information about your 
                            session (referrer, landing page, etc).
                            _ www.LetsVing.com _visit, no data held, Persistent for 30 minutes from the 
                            last visit, Used by our website provider’s internal stats tracker to record 
                            the number of visits _ www.LetsVing.com _uniq, no data held, expires midnight 
                            (relative to the visitor) of the next day, Counts the number of visits to a 
                            store by a single customer.Cart, unique token, persistent for 2 weeks, Stores 
                            information about the contents of your cart._secure_session_id, unique token, 
                            sessional storefront_digest, unique token, indefinite If the shop has a password, 
                            this is used to determine if the current visitor has access.
                        </p>
                        <h5 className={styles.termTitles + " subheading"}>SECTION 9 - AGE OF CONSENT</h5>
                        <p className={styles.termText + " paragraph"}>
                            By using this site, you represent that you are at least the age of majority in 
                            your state or province of residence, or that you are the age of majority in 
                            your state or province of residence and you have given us your consent to allow 
                            any of your minor dependents to use this site.
                        </p>
                        <h5 className={styles.termTitles + " subheading"}>SECTION 10 - CHANGES TO THIS PRIVACY POLICY</h5>
                        <p className={styles.termText + " paragraph"}>
                            We reserve the right to modify this privacy policy at any time, so please review 
                            it frequently. Changes and clarifications will take effect immediately upon their 
                            posting on the website. If we make material changes to this policy, we will notify 
                            you here that it has been updated, so that you are aware of what information we 
                            collect, how we use it, and under what circumstances, if any, we use and/or 
                            disclose it.If our store is acquired or merged with another company, your 
                            information may be transferred to the new owners so that we may continue to 
                            sell products to you.If you would like to: access, correct, amend or delete 
                            any personal information we have about you, register a complaint, or simply want 
                            more information contact us at {" "}<a className={styles.legalLink}>hello@letsving.com</a>{" "} <br /> or by mail at
                        </p>
                        <p className={styles.companyAddress + " paragraph"}>
                            LetsVing Technologies India Pvt Ltd..TEC, <br />
                            Level 7, Mfar Greenheart Building, <br />
                            Manyata Tech Park, Hebbal <br />
                            Bangalore - 560045 <br />
                            <span className={styles.effectiveDate}>Effective Date</span> 
                            March 18, 2022
                        </p>
                       
                    </Grid>
                </Grid>
            </Container>
        </div>
    )
}
