import React, { Component } from "react";
import { Container, Button, Grid, List, ListItem } from "@material-ui/core";
import { imageBasePath } from "../../../constants";
import styles from "../../../assets/styles/purchase.module.scss";
function HeroSection(props) {
    return (
        <section className={styles.heroSection}>
            <Container maxWidth="lg">
                <div className={styles.backgroundPadding}>
                <div className={styles.background + ' backgroundProp'}>
                    <div className={styles.heading}>
                        <Grid container direction="row" spacing={2} justifyContent="center">
                            <Grid item md={8}>
                                <h1 className={styles.heroTitle+" heading3"}>Unified Video Conference Platform For Hybrid Work</h1>
                                <h2 className={styles.subheading+" heading6"}>Any OEM Camera. Any Meeting Platform. Anytime. Anywhere</h2>
                            </Grid>
                        </Grid>
                    </div>
                    <div>
                        <Grid container justifyContent="center" direction="row" spacing={2}>
                            <Grid md={8}> 
                                <Grid container direction="row" spacing={2}>
                                    <Grid item md={4} xs={12}>
                                        <img src={ imageBasePath+'banner-icon1.svg' } alt="icon1"/>
                                        <p  className="paragraph">Drive adoption by simplifying <br /> user experience</p>
                                    </Grid>
                                    <Grid item md={4} xs={12}>
                                        <img src={ imageBasePath+'banner-icon2.svg' } alt="icon2"/>
                                        <p className="paragraph">Saves costs on VC <br />room hardware</p>
                                    </Grid>
                                    <Grid item md={4} xs={12}>
                                        <img src={ imageBasePath+'banner-icon3.svg' } alt="icon3"/>
                                        <p className="paragraph">Unified management layer across <br /> OEM cameras and rooms</p>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                </div>
                </div>
            </Container>
        </section>
    );
}

export default HeroSection;
