import React from "react";
import styles from "../../../../assets/styles/admin/directOrder.module.scss";
import { DirectOrderList } from "../components";
function DirectOrder(props) {
  return (
    <div className={styles.directOrderPage}>
      <div className={styles.directOrderWrapper}>
        <DirectOrderList />
      </div>
    </div>
  );
}

export default DirectOrder;
