import React, { useState } from "react";
import { Grid } from "@material-ui/core";
import styles from "../../../assets/styles/accountSettings.module.scss";
import { imageBasePath } from "../../../constants";
import { CustomButton, InputText } from "../../../shared_ui_components";
import { fieldValidation } from "../../../utils/formValidation";
import { errorCodes } from "../../../constants";
import { updateUserPassword } from "../apiServices";
import { toastFlashMessage } from "../../../utils";

export const PasswordTabContent = (props) => {
  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState({});
  const [errCodes, setErrCodes] = useState(errorCodes);
  const [passwordError, setPasswordError] = useState(false);
  const [validateLoader, setValidateLoader] = useState(false);

  const handleChange = (key, value) => {
    setFormData((formData) => ({
      ...formData,
      [key]: value,
    }));
    setErrors((errors) => ({
      ...errors,
      [key]: "",
    }));
  };

  const togglePassword = (key) => {
    setFormData((form) => ({
      ...form,
      show_password: key === form.show_password ? "" : key,
    }));
  };

  const handleSubmit = () => {
    const validateNewInput = {
      password:
        errCodes["password"][fieldValidation({ ...errCodes.passwordObj, fieldval: formData.password })],
      newPassword:
        errCodes["newPassword"][
          fieldValidation({ ...errCodes.newPasswordObj, fieldval: formData.newPassword })
        ],
      confirmPassword:
        errCodes["confirmPassword"][
          fieldValidation({ ...errCodes.confirmPasswordObj, fieldval: formData.confirmPassword })
        ],
    };
    //console.log(validateNewInput, "validateNewInput");
    if (Object.keys(validateNewInput).every((k) => validateNewInput[k] == "")) {
      if (formData.newPassword !== formData.confirmPassword) {
        setPasswordError(true);
      } else {
        setValidateLoader(true);
        if (formData.password) {
          updateUserPassword({
            password: formData.password,
            newPassword: formData.newPassword,
          }).then((response) => {
            setValidateLoader(false);
            if (response.status) {
              toastFlashMessage("password updated successfully", "success");
              setFormData({
                password: "",
                newPassword: "",
                confirmPassword: "",
              });
            } else {
              toastFlashMessage("invalid Password", "error");
            }
          });
        } else {
          updateUserPassword({ newPassword: formData.newPassword }).then((response) => {
            setValidateLoader(false);
            if (response.status) {
              toastFlashMessage("password updated successfully", "success");
              setFormData({
                password: "",
                newPassword: "",
                confirmPassword: "",
              });
            } else {
              toastFlashMessage("invalid Password", "error");
            }
          });
        }
      }
    } else {
      setErrors(validateNewInput);
    }
  };

  return (
    <div className={styles.passwordTab}>
      <Grid container>
        <Grid item md={7}>
          <div className={styles.passwordTabInput}>
            <InputText
              label="Old Password"
              placeholder="Enter Old Password"
              type={formData.show_password === "password" ? "text" : "password"}
              value={formData.password}
              error={errors.password}
              onChange={(e) => handleChange("password", e.target.value)}
              onFocus={() => setPasswordError(false)}
            />
            <span className={styles.showPassword} onClick={() => togglePassword("password")}>
              {formData.show_password === "password" ? (
                <img src={imageBasePath + "eye_hide.svg"} alt="Hide" />
              ) : (
                <img src={imageBasePath + "eye_show.svg"} alt="Show" />
              )}
            </span>
          </div>
          <div className={styles.passwordTabInput}>
            <InputText
              label="New Password"
              placeholder="Enter New Password"
              type={formData.show_password === "newPassword" ? "text" : "password"}
              value={formData.newPassword}
              error={errors.newPassword}
              onChange={(e) => handleChange("newPassword", e.target.value)}
              onFocus={() => setPasswordError(false)}
            />
            <span className={styles.showPassword} onClick={() => togglePassword("newPassword")}>
              {formData.show_password === "newPassword" ? (
                <img src={imageBasePath + "eye_hide.svg"} alt="Hide" />
              ) : (
                <img src={imageBasePath + "eye_show.svg"} alt="Show" />
              )}
            </span>
          </div>
          <div className={styles.passwordTabInput}>
            <InputText
              label="Confirm Password"
              placeholder="Enter Confirm Password"
              type={formData.show_password === "confirmPassword" ? "text" : "password"}
              value={formData.confirmPassword}
              error={errors.confirmPassword}
              onChange={(e) => handleChange("confirmPassword", e.target.value)}
              onFocus={() => setPasswordError(false)}
            />
            <span className={styles.showPassword} onClick={() => togglePassword("confirmPassword")}>
              {formData.show_password === "confirmPassword" ? (
                <img src={imageBasePath + "eye_hide.svg"} alt="Hide" />
              ) : (
                <img src={imageBasePath + "eye_show.svg"} alt="Show" />
              )}
            </span>
          </div>
          {passwordError && <h6 className="errorMsg">Password is not matching</h6>}
          <div className={styles.passwordBtnWrapper}>
            <CustomButton
              children={validateLoader ? "validating.." : "Change"}
              className={`${styles.saveBtn} primaryBtn ${validateLoader ? "disabledBtn" : ""}`}
              onClick={() => handleSubmit()}
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};
