import { globalGetService, globalPostService } from "../../utils/globalApiServices";

export function getAllQuotationList() {
  return new Promise((resolve, reject) => {
    globalPostService(`get-all-quotations-v1`, {})
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function getQuotationListByOrgId(data = {}) {
  return new Promise((resolve, reject) => {
    globalPostService(`get-all-quotations-v1`, data)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function getQuotationOrderByOrgId(data = {}) {
  return new Promise((resolve, reject) => {
    globalPostService(`get-quot-res-v1`, data)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function savePurchaseOrder(data = {}) {
  return new Promise((resolve, reject) => {
    globalPostService(`save-purchase-orders-v1`, data)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function submitQuotation(data = {}) {
  return new Promise((resolve, reject) => {
    globalPostService(`create-quot-req-v1`, data)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function getQuotationDetailById(data = {}) {
  return new Promise((resolve, reject) => {
    globalPostService(`get-single-quotation-v1`, data)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
