import React, { useState } from 'react';
import styles from "../../../../assets/styles/admin/partner.module.scss";
import { Grid, List, ListItem } from "@material-ui/core";
import { InputText } from "../../../../shared_ui_components";
import { imageBasePath } from "../../../../constants";
import { Link } from "react-router-dom";
import PartnerRequestDrawer from "./PartnerRequestDrawer";

export default function PartnerRequest(props) {
    const [partnerRequest, setPartnerRequest] = useState(false);

    return (
        <Grid container>
            <Grid item md={12}>
                <div className={styles.partnerList}>
                    <div className={styles.partnerListInnerBlk}>
                        <div className={styles.partnerListTableTitle}>
                            <Grid container>
                                <Grid item md={3}>
                                    <div
                                        className={styles.partnerTableTitleTextWrapper}
                                    >
                                        <p
                                            className={
                                                styles.partnerTableTitleText + " supportText "
                                            }
                                        >
                                            Partner Name
                                        </p>
                                        <img src={imageBasePath + "sort_icon.svg"} alt="" />
                                    </div>
                                </Grid>
                                <Grid item md={3}>
                                    <div
                                        className={styles.partnerTableTitleTextWrapper}
                                    >
                                        <p
                                            className={
                                                styles.partnerTableTitleText + " supportText "
                                            }
                                        >
                                            Partner Company
                                        </p>
                                        <img src={imageBasePath + "sort_icon.svg"} alt="" />
                                    </div>
                                </Grid>
                                <Grid item md={3}>
                                    <div
                                        className={styles.partnerTableTitleTextWrapper}
                                    >
                                        <p
                                            className={
                                                styles.partnerTableTitleText + " supportText "
                                            }
                                        >
                                            Phone Number
                                        </p>
                                        <img src={imageBasePath + "sort_icon.svg"} alt="" />
                                    </div>
                                </Grid>
                                <Grid item md={3}>
                                    <div
                                        className={styles.partnerTableTitleTextWrapper}
                                    >
                                        <p
                                            className={
                                                styles.partnerTableTitleText + " supportText "
                                            }
                                        >
                                            Requested on
                                        </p>
                                        <img src={imageBasePath + "sort_icon.svg"} alt="" />
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                        <div className={styles.partnerListTableContent}>
                            {[0, 1, 2, 3, 4,5,6,7].map((item, key) => (
                                <>
                                    <Link
                                        onClick={() => setPartnerRequest(true)}
                                        className={styles.partnerListItem}
                                    >
                                        <div className={styles.partnerListTableRow}>
                                            <Grid container justifyContent="center" alignItems="center">
                                                <Grid item md={3}>
                                                    <div
                                                        className={styles.partnerContentWrapper}
                                                    >
                                                        <img
                                                            src={
                                                                imageBasePath + "partner_profile.svg"
                                                            }
                                                            alt=""
                                                            class={styles.partnerUserImg}
                                                        />
                                                        <div className={styles.partnerDetailWrapper}>
                                                            <p
                                                                className={styles.partnerName + " paragraphSemiBold "}
                                                            >
                                                                Pavan Kalyan
                                                            </p>
                                                            <p className={styles.partnerEmail + " label "}>
                                                                pavan@welpro.com
                                                            </p>
                                                        </div>
                                                    </div>
                                                </Grid>
                                                <Grid item md={3}>
                                                    <div
                                                        className={styles.partnerContentWrapper}
                                                    >
                                                        <p
                                                            className={
                                                                styles.companyName + " paragraph "
                                                            }
                                                        >
                                                            Welpro Pvt. Ltd.
                                                        </p>
                                                    </div>
                                                </Grid>
                                                <Grid item md={3}>
                                                    <div
                                                        className={styles.partnerContentWrapper}
                                                    >
                                                        <p
                                                            className={
                                                                styles.numberCounts + " paragraph "
                                                            }
                                                        >
                                                            +91 97842 42421
                                                        </p>
                                                    </div>
                                                </Grid>
                                                <Grid item md={3}>
                                                    <div
                                                        className={styles.partnerContentWrapper}
                                                    >
                                                        <p
                                                            className={
                                                                styles.numberCounts + " paragraph "
                                                            }
                                                        >
                                                            23/02/2021
                                                        </p>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </Link>

                                </>
                            ))}
                            <PartnerRequestDrawer
                                handleClose={() => setPartnerRequest(false)}
                                open={partnerRequest}
                            />
                        </div>
                    </div>
                </div>
            </Grid>
        </Grid>
    )
}
