import Devices from "./container/Devices";
import DeviceInfo from "./container/DeviceInfo";
import QuotationLayoutHoc from "../../hoc/quotationLayoutHoc";
export const devicesRoutes = [
  {
    path: "/:id/devices",
    component: QuotationLayoutHoc(Devices),
    key: "devices",
  },
  {
    path: "/:id/devices/:_id",
    component: QuotationLayoutHoc(DeviceInfo),
    key: "device-info",
  },
];
