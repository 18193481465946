import { TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";

const labelStyle = {
  fontFamily: '"Inter", sans-serif',
  fontSize: "12px",
  fontWeight: 400,
  color: "#4d5c68",
  lineHeight: "21px",
  marginBottom: "3px",
};

export default function CustomAutoComplete({
  value,
  options,
  onChange,
  label,
}) {
  return (
    <>
      <div style={labelStyle}>{label}</div>
      <Autocomplete
        options={options}
        getOptionLabel={(option) => option.label}
        style={{
          backgroundColor: "#fff",
          marginBottom: "20px",
        }}
        value={value}
        onChange={onChange}
        renderInput={(params) => (
          <TextField {...params} label="" variant="outlined" />
        )}
      />
    </>
  );
}
