import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import TodayOutlinedIcon from "@material-ui/icons/TodayOutlined";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import { imageBasePath } from "../constants";
import { getUserInfo } from "../utils";

const AnalyticsCardTheme = createTheme({
  typography: {
    fontFamily: ["Inter", "sans-serif"].join(","),
    subtitle1: {
      fontSize: 13,
    },
  },
  palette: {
    secondary: {
      main: "#67737D",
    },
  },
});

const useStyles = makeStyles((theme) => ({
  container: {
    padding: "10px 0px",
  },
  table: {
    maxWidth: 570,
    margin: theme.spacing(1),
    marginTop: 8,
  },
  tableHeadFont: {
    color: "#67737D",
  },
  title: {
    fontWeight: "bold",
    color: "#67737D",
  },
  formControl: {
    margin: theme.spacing(1),
  },
  gridContainer: {
    margin: theme.spacing(2),
    marginTop: theme.spacing(3.9),
  },
  margin: {
    marginLeft: theme.spacing(3),
    marginTop: theme.spacing(3),
  },
  trend: {
    color: "#2E9940",
  },
  content: {
    display: "flex",
    alignItems: "center",
    height: "75px",
  },
  count: {
    fontWeight: "bold",
    marginTop: "4px",
    fontSize: "16px",
  },
  analytics: {
    marginLeft: "18px",
  },
  imgBackground: {
    backgroundColor: "#f5f9f8",
    borderRadius: "4px",
    border: "1px solid #dcedf0",
  },
  imgMaterial: {
    color: "#99a2a9",
    fontSize: "30px",
    margin: "10px 10px 4px 10px",
  },
}));

function AnalyticsCard({ analytics, orgId }) {
  const userInfo = getUserInfo();
  const classes = useStyles();

  return (
    <div className="dashboardLastHalfWrapper">
      <ThemeProvider theme={AnalyticsCardTheme}>
        <Grid container className={classes.content} spacing={2}>
          {userInfo.userData?.role?.roleName === "vingPartner" && !orgId ? (
            <Grid item lg={4} md={6} sm={12} xs={12}>
              <Paper
                style={{
                  padding: "14px",
                }}
                className={classes.content}
              >
                <div className={classes.imgBackground}>
                  <img
                    src={imageBasePath + "organisation-icon.svg"}
                    alt=""
                    style={{ width: "30px", margin: "10px 10px 4px 10px" }}
                  />
                </div>

                <div className={classes.analytics}>
                  <Typography
                    color="secondary"
                    variant="body1"
                    style={{
                      color: "#67737D",
                      fontFamily: "'Inter', sans-serif",
                    }}
                  >
                    Number of organisations
                  </Typography>
                  <Typography variant="h5" className={classes.count}>
                    {analytics?.orgCount || 0}
                  </Typography>
                </div>
              </Paper>
            </Grid>
          ) : null}
          <Grid item lg={4} md={6} sm={12} xs={12}>
            <Paper
              style={{
                padding: "10px",
              }}
              className={classes.content}
            >
              <div className={classes.imgBackground}>
                <TodayOutlinedIcon className={classes.imgMaterial} />
              </div>
              <div className={classes.analytics}>
                <Typography
                  color="secondary"
                  variant="body1"
                  style={{
                    color: "#67737D",
                    fontFamily: "'Inter', sans-serif",
                  }}
                >
                  Number of meetings
                </Typography>
                <Typography variant="h5" className={classes.count}>
                  {analytics?.meetings || 0}
                </Typography>
              </div>
            </Paper>
          </Grid>
          <Grid item lg={4} md={6} sm={12} xs={12}>
            <Paper
              style={{
                padding: "10px",
              }}
              className={classes.content}
            >
              <div className={classes.imgBackground}>
                <AccessTimeIcon className={classes.imgMaterial} />
              </div>
              <div className={classes.analytics}>
                <Typography
                  color="secondary"
                  variant="body1"
                  style={{
                    color: "#67737D",
                    fontFamily: "'Inter', sans-serif",
                  }}
                >
                  Total meeting minutes
                </Typography>
                <Typography variant="h5" className={classes.count}>
                  {analytics?.roomUsageinTime?.reduce(
                    (total, item) => total + item?.time,
                    0
                  ) || 0}
                </Typography>
              </div>
            </Paper>
          </Grid>
        </Grid>
      </ThemeProvider>
    </div>
  );
}

export default AnalyticsCard;
