import { globalGetService, globalPostService } from "../../utils/globalApiServices";

export function getRoomsData(query = {}) {
  return new Promise((resolve, reject) => {
    globalPostService(`complete-room-data-v1`, query)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function getMeetingsData(query = {}) {
  return new Promise((resolve, reject) => {
    globalPostService(`complete-meeting-data-v1`, query)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
export function getMeetingsDatafromParams(query = {}) {
  return new Promise((resolve, reject) => {
    globalPostService(`complete-meeting-data-wrt-paramas-v1`, query)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function getMeetingsDetailsfromParams(query = {}) {
  return new Promise((resolve, reject) => {
    globalGetService(`${query.orgId}/get-meeting-reports-v1?from=${query.fromDate}&to=${query.toDate}`)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
