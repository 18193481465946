import React, { useState } from 'react';
import styles from "../../../../assets/styles/admin/partner.module.scss";
import { Grid, Tab, Tabs} from "@material-ui/core";
import { InputText } from "../../../../shared_ui_components";
import { imageBasePath } from "../../../../constants";
import PartnerOnLetsving from "./PartnerOnLetsving";
import PartnerRequest from "./PartnerRequest";

export default function PartnerList(props) {
    const [selectedTab, setSelectedTab] = useState(0);

    const handleChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

    return (
        <div className={styles.partnerListWrapper}>
            <div className={styles.partnerHeading}>
                <h2 className={styles.partnerTitle + " subheading "}>Partners</h2>
            </div>
            <div className={styles.partnerListStatus}>
                <Tabs className={styles.partnerListFilter} value={selectedTab} onChange={handleChange}>
                    <Tab
                        label="Partners on LetsVing"
                        className={styles.filterItem + " " + styles.active}
                    />
                    <Tab
                        label="Partner Requests (09)"
                        className={styles.filterItem}
                    />
                </Tabs>
                {/* <List className={styles.quotationsListFilter}>
                    <ListItem className={styles.filterItem + " " + styles.active}>Partners on LetsVing</ListItem>
                    <ListItem className={styles.filterItem}>Partner Requests <span>(09)</span></ListItem>
                </List> */}
            </div>
            <div className={styles.partnerSearchBlk}>
                <Grid container direction="row" justifyContent="flex-end">
                    <Grid item md={4}>
                        <div className={styles.partnerSearchInput}>
                            <InputText placeholder="Search here" />
                            <img src={imageBasePath + "search_icon.svg"} alt=""/>
                        </div>
                    </Grid>
                </Grid>
            </div>
            {
                selectedTab === 0 && <PartnerOnLetsving />
            }
            {
                selectedTab === 1 && <PartnerRequest />
            }
        </div>
    )
}
