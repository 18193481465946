import Orders from "./container/Orders";
import QuotationLayoutHoc from "../../hoc/quotationLayoutHoc";
import homeLayoutHoc from "../../hoc/homeLayoutHoc";
export const ordersRoute = [
  {
    path: "/:id/orders",
    component: QuotationLayoutHoc(Orders),
    key: "orders",
  },
  {
    path: "/orders",
    component: homeLayoutHoc(Orders),
    key: "homeOrders",
  },
];
