import React, { Component } from "react";
import styles from "../../../assets/styles/auth.module.scss";
import { imageBasePath } from "../../../constants";
import VerifiedSuccess from "../components/VerifiedSuccess";
import { verifyTokeApi } from "../ApiService"
import { toastFlashMessage } from '../../../utils'
import queryString from 'query-string';


export default class VerifiedEmailSuccessfully extends Component {
    constructor(props){
        super(props);
        this.state = {
          image: '',
          title: 'Email Verification',
          description: 'Please wait we are verifying your email..',
          loader:false,
          emailVerified: false,
        }
      }


    componentDidMount() {
        const querys = queryString.parse(this.props.location.search, {decode: false});
        const token = querys.accessToken;
        
        if (token) {
         this.setState({loader:true})
          verifyTokeApi({'Authorization' : 'Bearer ' + token})
            .then(response => {
              if (response.status) {
                const userData  = {...response?.userData}
                userData.password = undefined
                userData.serviceProviderStatus=null
                const userInfo = {  
                  accessToken : token,  
                  userData : userData
                }
                console.log("userInfo", userInfo, response)
                localStorage.setItem('userInfo', JSON.stringify(userInfo));
                this.setState({
                  image: 'verified_success.svg',
                  emailVerified: true,
                  loader: false,
                  title: 'Your email has been verified!',
                  description: 'Welcome to LetsVing! Let’s get you started with your easy video conferencing journey.'
                });
                toastFlashMessage('Your email has been verified!', 'success');
               } else {
                this.setState({
                  image: 'verified_error.svg',
                  emailVerified: false,
                  loader: false,
                  title: 'Your email verification failed!',
                  description: 'We are not able to verify your email, either link is expired or invalid. Please signup and try again'
                });
                toastFlashMessage('Your email verification failed!', 'error');
              }
            })
        } else {
            toastFlashMessage('Invalid URL', 'error');
            this.props.history.push('/signin');
        }
      };

    render() {
        const { loader, title, image, description } = this.state;
        return (
            <div className={styles.signUpPage}>
                <div className={styles.LogoWrapper}>
                    <img src={imageBasePath + "logo.svg"} alt="" />
                </div>
                    <VerifiedSuccess 
                      loader={loader} 
                      title={title}
                      description={description}
                      image={image}
                       />
            </div>
        );
    }
}