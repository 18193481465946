import "date-fns";
import React, { useState } from "react";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import { TextField } from "@material-ui/core";
import { imageBasePath } from "../constants";

export default function ReportsDatePicker(props) {
  const [open, setOpen] = useState(false);
  return (
    <div className="customDatePicker">
      <div className={`inputGroup ${props.noMargin ? "noMargin" : ""}`}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            autoOk={true}
            error={false}
            helperText={null}
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            TextFieldComponent={(props) => (
              <TextField className="datePicker" {...props} onClick={(e) => setOpen(true)} />
            )}
            disableToolbar
            format="dd-MM-yyyy"
            margin="normal"
            id={props.id ? props.id : "date-picker-inline"}
            label="Date picker inline"
            value={props.value}
            onChange={props.onChange}
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
            emptyLabel="dd-mm-yyyy"
            keyboardIcon={<img src={imageBasePath + "calendar-icon.svg"} alt="" />}
            {...props}
          />
        </MuiPickersUtilsProvider>
        {props.error ? <h6 className="errorMsg">{props.error}</h6> : null}
      </div>
    </div>
  );
}
