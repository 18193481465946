import { globalDeleteService, globalGetService, globalPostService } from "../../utils/globalApiServices";

export function createWallpaperApi(data = {}) {
  return new Promise((resolve, reject) => {
    globalPostService(`upload-org-wallpapers-v1`, data)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function createWelcomeMessage(data = {}) {
  return new Promise((resolve, reject) => {
    globalPostService(`update-org-body-title-v1`, data)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function createNewDomain(data = {}) {
  return new Promise((resolve, reject) => {
    globalPostService(`update-org-domains-v1`, data)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function getDomain(query = {}) {
  return new Promise((resolve, reject) => {
    globalPostService(`get-org-domains-v1`, query)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function deleteDomain(query = {}) {
  return new Promise((resolve, reject) => {
    globalPostService(`delete-org-domain-v1`, query)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function deleteWallpaper(query = {}) {
  return new Promise((resolve, reject) => {
    globalPostService(`delete-org-wallpaper-v1`, query)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function confirmWallpaper(query = {}) {
  return new Promise((resolve, reject) => {
    globalPostService(`add-org-wallpaper-v1`, query)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}



export function getOrgSettings(query={}) {
  return new Promise((resolve, reject) => {
    globalGetService(`organisation/settings/${query.orgId}`)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function updateOrgSettings(data={}) {
  return new Promise((resolve, reject) => {
    globalPostService(`organisation/settings/${data.orgId}`, data)
      .then((response) => {
        if(response.isAxiosError) {
          let message;
          if(response?.response?.data) {
            message = response?.response?.data?.message;            
          }
          reject(new Error(message || "Unknown error"))
        }
        else {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
}
