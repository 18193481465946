import { globalPostService, globalGetService } from "../../utils/globalApiServices";

export function getOverview(data = {}) {
  return new Promise((resolve, reject) => {
    globalGetService(`get-overview-v1`, data)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function partnerDashboardAnalyticsApi(data = {}) {
    return new Promise((resolve, reject) => {
        globalPostService(`partner-dashboard-analytics-v1`, data)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }  