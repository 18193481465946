import React, { useState, useEffect } from "react";
import styles from "../../../assets/styles/workspaces.module.scss";
import { imageBasePath } from "../../../constants";
import { CustomLink, CustomButton, InputText, NoResult } from "../../../shared_ui_components";
import { Grid, Dialog } from "@material-ui/core";
import { getWorkspaceWallpaperApi } from "../apiServices";
import { useParams } from "react-router";
import Shimmer from "./Shimmer";

export default function SetWallpaper(props) {
  const { id } = useParams();

  const [activeImg, setActiveImg] = useState(false);
  const [workspaceWallpaper, setWorkspaceWallpaper] = useState(false);
  const [wallpapers, setWallpapers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [noWallPaperStatus, setNoWallPaperStatus] = useState(false);

  const handleClickOpen = () => {
    setWorkspaceWallpaper(true);
  };

  const handleClose = () => {
    setWorkspaceWallpaper(false);
    setActiveImg(null);
  };

  const getAllWallpapers = () => {
    getWorkspaceWallpaperApi({ orgRefId: id }).then((response) => {
      if (response.status && response.data.wallpapers.length > 0) {
        setWallpapers(response.data.wallpapers);
        setLoading(false);
      } else {
        setLoading(false);
        setNoWallPaperStatus(true);
      }
    });
  };

  useEffect(() => {
    if (workspaceWallpaper) {
      getAllWallpapers();
    } else {
    }
  }, [workspaceWallpaper]);

  const handleSave = () => {
    props.handleChange("workSpaceWallpaper", activeImg);
    handleClose();
    setActiveImg(null);
  };

  const handleSelectImage = (img) => {
    setActiveImg(img);
  };

  return (
    <>
      <CustomLink
        onClick={handleClickOpen}
        className={styles.addWallpaperButton + " outlinedBtn "}
        text="Add"
      />
      <Dialog open={workspaceWallpaper} onClose={handleClose}>
        <div className={styles.setWallpaperWrapper}>
          <div className={styles.addWallpaperTitleWrapper}>
            <div className={styles.addWallpaperTitle}>
              <img src={imageBasePath + "set_wallpaper.svg"} alt="" />
              <p className={styles.addWallpaperHeader + " subheading"}>Set workspace wallpaper</p>
            </div>
            <div className={styles.closeDialog}>
              <img onClick={handleClose} src={imageBasePath + "user_cancel.svg"} alt="" />
            </div>
          </div>
          <div className={styles.addWallpaperContentWrapper}>
            <Grid container spacing={2} direction="row" justifyContent="center" alignItems="center">
              {loading ? (
                <Shimmer type="wallpapers" />
              ) : noWallPaperStatus ? (
                <div style={{ marginTop: "-100px" }}>
                  <NoResult
                    image="no_workspace_room.png"
                    title="No Wallpapers found"
                    subtext="Looks like you have no wallpapers. Start importing wallpapers"
                    btnText="Go to Settings"
                    link={`/${id}/settings`}
                  />
                </div>
              ) : (
                wallpapers?.map((item, key) => (
                  <Grid key={key} item md={6}>
                    <div className={styles.wallpapersList}>
                    {item.img?.includes(".mp4") ? (
                      <video
                      src={item.img}
                      onClick={() => handleSelectImage(item.img, item._id)}
                      className = {`${styles.wallpapers} ${activeImg === item.img ? styles.active : null }`}
                      alt=""
                      autoPlay
                      muted
                      loop
                    />
                    ):(
                      <img
                        className = {`${styles.wallpapers} ${activeImg === item.img ? styles.active : null }`}
                        src={item.img}
                        alt=""
                        onClick={() => handleSelectImage(item.img, item._id)}
                      />
                    )}
                  </div>
                  </Grid>
                ))
              )}
            </Grid>
          </div>
          {!noWallPaperStatus && (
            <div className={styles.setWallpaperActionButton}>
              <CustomLink
                className={styles.saveButton + " primaryBtn "}
                text="Save"
                onClick={() => handleSave()}
              />
            </div>
          )}
        </div>
      </Dialog>
    </>
  );
}
