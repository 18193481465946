import React from "react";
import { Grid, List, ListItem } from "@material-ui/core";
import styles from "../../../assets/styles/allOrganisation.module.scss";
import { Skeleton } from "@material-ui/lab";

export default function Shimmer(props) {
  return (
    <>
      {props.type === "orgSearch" && (
        <div className={styles.organisationSearchBlk}>
          <Grid container direction="row" spacing={2} justifyContent="flex-end" alignItems="center">
            <Grid item md={3}>
              <div className={styles.organisationSearchInput}></div>
            </Grid>
            <Grid item md={3}>
              <div className={styles.addNewOrganisation}>
                <div className={styles.addButton}></div>
              </div>
            </Grid>
          </Grid>
        </div>
      )}
      {props.type === "orgHeader" && (
        <div className={styles.allOrganisationListTableTitle}>
          <Grid container spacing={2}>
            <Grid item md={12}>
              <Grid container direction="row" spacing={2} justifyContent="flex-start" alignItems="center">
                <Grid item md={4}>
                  <div className={styles.allOrganisationTableTitleTextWrapper}>
                    <p className={styles.allOrganisationTableTitleText + " paragraph "}>Organisation Name</p>
                  </div>
                </Grid>
                <Grid container item md={3}>
                  <Grid item md={6}>
                    <div className={styles.allOrganisationTableTitleTextWrapper}>
                      <p className={styles.allOrganisationTableTitleText + " paragraph "}>Workspaces</p>
                    </div>
                  </Grid>
                  <Grid item md={6}>
                    <div className={styles.allOrganisationTableTitleTextWrapper}>
                      <p className={styles.allOrganisationTableTitleText + " paragraph "}>No. of Devices</p>
                    </div>
                  </Grid>
                </Grid>
                <Grid item md={5}>
                  <div className={styles.allOrganisationTableTitleTextWrapper}>
                    <p className={styles.allOrganisationTableTitleText + " paragraph "}>Admin Email ID</p>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      )}
      {props.type === "orgLists" && (
        <>
          {[0, 1, 2, 3, 4].map((item, key) => (
            <React.Fragment key={key}>
              <div className={styles.allOrganisationListItem}>
                <div className={styles.allOrganisationListTableRow}>
                  <Grid container direction="row" spacing={2} justifyContent="flex-start" alignItems="center">
                    <Grid item md={4}>
                      <div className={styles.allOrganisationContentWrapper}>
                        <div className={styles.allOrganisationImageWrapper}>
                          <Skeleton
                            variant="circle"
                            width={40}
                            height={40}
                            style={{
                              display: "block",
                              verticalAlign: "middle",
                              transform: "none",
                              marginTop: 20,
                              marginLeft: 5,
                            }}
                          />
                        </div>
                        <div className={styles.allOrganisationDetailWrapper}>
                          <div className={styles.allOrganisationName + " paragraphSemiBold "}>
                            <Skeleton
                              variant="text"
                              width={180}
                              height={25}
                              style={{
                                display: "block",
                                verticalAlign: "middle",
                                transform: "none",
                                marginTop: 15,
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </Grid>
                    <Grid container item md={3}>
                      <Grid item md={6}>
                        <div className={styles.allOrganisationContentWrapper}>
                          <div className={styles.noOfWorkspace + " paragraph "}>
                            <Skeleton
                              variant="text"
                              width={100}
                              height={25}
                              style={{
                                display: "block",
                                verticalAlign: "middle",
                                transform: "none",
                                marginTop: 15,
                                marginLeft: -10,
                              }}
                            />
                          </div>
                        </div>
                      </Grid>
                      <Grid item md={6}>
                        <div className={styles.allOrganisationContentWrapper}>
                          <div className={styles.noOfBox + " paragraph "}>
                            <Skeleton
                              variant="text"
                              width={100}
                              height={25}
                              style={{
                                display: "block",
                                verticalAlign: "middle",
                                transform: "none",
                                marginTop: 15,
                              }}
                            />
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                    <Grid item md={5}>
                      <div className={styles.allOrganisationContentWrapper}>
                        <div className={styles.adminEmail + " paragraph "}>
                          <Skeleton
                            variant="text"
                            width={350}
                            height={25}
                            style={{
                              display: "block",
                              verticalAlign: "middle",
                              transform: "none",
                              marginTop: 15,
                            }}
                          />
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </React.Fragment>
          ))}
        </>
      )}
    </>
  );
}
