import React, { useState, useEffect } from "react";
import { Grid, Tab, Tabs } from "@material-ui/core";
import styles from "../../../assets/styles/devices.module.scss";
import { InputText, InputSelect, NoResult } from "../../../shared_ui_components";
import { imageBasePath, customMediumSelector, reactSelectStylesSmall } from "../../../constants";
import DevicesListItem from "./DevicesListItem";
import { getWorkspaceListing, getWorkspaceRoomDetails } from "../../workspaces/apiServices";
import { getDevListsByOrgId, getAllRoomList } from "../apiServices";
import { useParams } from "react-router";
import Shimmer from "./Shimmer";
import { sortObj, toastFlashMessage } from "../../../utils";

export default function DevicesList(props) {
  const { id } = useParams();

  const [workspaceLists, setWorkspaceLists] = useState([]);
  const [noNewDeviceList, setNoNewDeviceList] = useState(false);
  const [deviceLists, setDeviceLists] = useState([]);
  const [loader, setLoader] = useState(true);
  const [selectedTab, setSelectedTab] = useState(0);
  const [selectedWorkspace, setSelectedWorkspace] = useState(null);
  const [roomLists, setRoomLists] = useState([]);
  const [search, setSearch] = useState({
    val: "",
    list: [],
  });
  const [sort, setSort] = useState({
    field: "",
    asc: false,
    defaultSortUpImg: "",
    defaultSortDownImg: "",
  });

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const deviceList = () => {
    getDevListsByOrgId({ orgId: id }).then((response) => {
      if (response.status && response.data.length > 0) {
        setDeviceLists(response.data);
        setLoader(false);
        setNoNewDeviceList(false);
      } else {
        setLoader(false);
        setNoNewDeviceList(true);
      }
    });
  };

  const workspaceList = () => {
    getWorkspaceListing({ orgId: id }).then((response) => {
      if (response.status && response.data.length > 0) {
        const workspaceList = response.data.map((org) => {
          return { label: org?.orgLocation?.orgWorkSpaceName, value: org?.orgLocation._id };
        });
        setWorkspaceLists(workspaceList);
      }
    });
  };

  useEffect(() => {
    deviceList();
    workspaceList();
  }, [id]);

  useEffect(() => {
    if (selectedWorkspace !== null) {
      getWorkspaceRoomDetails({ workspaceId: selectedWorkspace }).then((response) => {
        if (response.status && response.data.length > 0) {
          setRoomLists(
            response.data
              .filter((rDevice) => rDevice.deviceStatus === false)
              .map((room) => {
                return { label: room.roomName, value: room._id };
              })
          );
        } else {
          toastFlashMessage("There is no room for the selected workspace", "error");
          setRoomLists([]);
        }
      });
    }
  }, [selectedWorkspace]);

  const activatedDeviceLists = deviceLists
    .filter((device) => {
      return device.deviceStatus === "Active" && device.macId !== null;
    })
    .map((activatedDevice) => {
      return activatedDevice;
    });

  const handleSearch = (val) => {
    setSearch((prevSearch) => ({
      val: val,
      list: deviceLists.filter(
        (device) =>
          device?.deviceName?.toLowerCase().includes(val.toLowerCase()) ||
          device?.roomId?.roomName?.toLowerCase().includes(val.toLowerCase())
      ),
    }));
  };

  const handleSort = (sortObject) => {
    let list = sortObj(sortObject.field, deviceLists, sortObject.asc, sortObject.caseSensitive);
    setSort({
      field: sortObject.field,
      asc: sortObject.asc,
      defaultSortUpImg: sortObject.asc ? imageBasePath + "asc_arrow.svg" : imageBasePath + "up_arrow.svg",
      defaultSortDownImg: sortObject.asc ? imageBasePath + "down_arrow.svg" : imageBasePath + "des_arrow.svg",
    });
    setDeviceLists(list);
  };

  return (
    <div className={styles.devicesHeaderWrapper}>
      <div className={styles.devicesPageTitleWrapper}>
        {loader ? (
          <Shimmer type="DeviceLength" />
        ) : (
          <>
            <h2 className={styles.devicesPageTitle + " subheading "}>
              Devices
              <span className={styles.devicesCount}>
                ({(selectedTab === 1 && activatedDeviceLists ? activatedDeviceLists : deviceLists).length})
              </span>
            </h2>
          </>
        )}
      </div>
      {noNewDeviceList === false ? (
        <>
          <div className={styles.devicesTabWrapper}>
            <Grid container>
              <Grid item md={12}>
                <div className={styles.devicesTab}>
                  <Tabs className={styles.quotationsListFilter} value={selectedTab} onChange={handleChange}>
                    <Tab label="All Devices" className={styles.filterItem} />
                    <Tab label="Activated" className={styles.filterItem} />
                  </Tabs>
                </div>
              </Grid>
            </Grid>
          </div>
          {loader ? (
            <Shimmer type="DeviceList" />
          ) : (
            <>
              {(selectedTab === 1 && activatedDeviceLists ? activatedDeviceLists : deviceLists).length !==
              0 ? (
                <>
                  <div className={styles.devicesListWrapper}>
                    <Grid container justifyContent="space-between">
                      <Grid item md={4}>
                        <p className={styles.devicesWorkspace + " supportText "}>Workspace</p>
                        {/* <div className={styles.workspaceLocationSelect}> */}
                        <div className={styles.workspaceLocationSelect}>
                          <InputSelect
                            closeMenuOnSelect={true}
                            isSearchable={false}
                            placeholder={"Select Workspace"}
                            styles={reactSelectStylesSmall}
                            noOptionsMessage={() => "No matches found"}
                            options={workspaceLists}
                            value={
                              selectedWorkspace
                                ? workspaceLists.find((opt) => opt.value === selectedWorkspace)
                                : null
                            }
                            onChange={(workspace) => {
                              setSelectedWorkspace(workspace.value);
                            }}
                          />
                        </div>
                      </Grid>
                      <Grid item md={4}>
                        <div className={styles.deviceSearchBlk}>
                          <div className={styles.deviceSearchInput}>
                            <InputText
                              placeholder="Search here"
                              onChange={(e) => handleSearch(e.target.value)}
                            />
                            <img src={imageBasePath + "search_icon.svg"} />
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                    <div className={styles.devicesList}>
                      <div className={styles.devicesListItemWrapper}>
                        <div className={styles.devicesListItemTitleWrapper}>
                          <Grid container spacing={2}>
                            <Grid item md={3}>
                              <div className={styles.devicesListItemTitleTextWrapper}>
                                <p className={styles.devicesListItemTitle + " supportText "}>Device Name</p>
                                <img
                                  src={
                                    sort.field === "deviceName"
                                      ? sort.defaultSortUpImg
                                      : imageBasePath + "up_arrow.svg"
                                  }
                                  alt=""
                                  className="sortUp"
                                  onClick={() =>
                                    handleSort({
                                      field: "deviceName",
                                      asc: !sort.asc,
                                      caseSensitive: true,
                                    })
                                  }
                                />
                                <img
                                  src={
                                    sort.field === "deviceName"
                                      ? sort.defaultSortDownImg
                                      : imageBasePath + "down_arrow.svg"
                                  }
                                  alt=""
                                  className="sortDown"
                                  onClick={() =>
                                    handleSort({
                                      field: "deviceName",
                                      asc: !sort.asc,
                                      caseSensitive: true,
                                    })
                                  }
                                />
                              </div>
                            </Grid>
                            {/* <Grid item md={1}>
                              <div className={styles.devicesListItemTitleTextWrapper}>
                                <p className={styles.devicesListItemTitle + " supportText "}>Room Id</p>
                              </div>
                            </Grid> */}
                            <Grid item md={2}>
                              <div className={styles.devicesListItemTitleTextWrapper}>
                                <p className={styles.devicesListItemTitle + " supportText "}>Room</p>
                              </div>
                            </Grid>
                            <Grid item md={2}>
                              <div className={styles.devicesListItemTitleTextWrapper}>
                                <p className={styles.devicesListItemTitle + " supportText "}>MAC ID</p>
                                <img
                                  src={
                                    sort.field === "macId"
                                      ? sort.defaultSortUpImg
                                      : imageBasePath + "up_arrow.svg"
                                  }
                                  alt=""
                                  className="sortUp"
                                  onClick={() =>
                                    handleSort({
                                      field: "macId",
                                      asc: !sort.asc,
                                      caseSensitive: false,
                                    })
                                  }
                                />
                                <img
                                  src={
                                    sort.field === "macId"
                                      ? sort.defaultSortDownImg
                                      : imageBasePath + "down_arrow.svg"
                                  }
                                  alt=""
                                  className="sortDown"
                                  onClick={() =>
                                    handleSort({
                                      field: "macId",
                                      asc: !sort.asc,
                                      caseSensitive: false,
                                    })
                                  }
                                />
                              </div>
                            </Grid>
                            <Grid item md={2}>
                              <div className={styles.devicesListItemTitleTextWrapper}>
                                <p className={styles.devicesListItemTitle + " supportText "}>
                                  Subscription Period
                                </p>
                                <img
                                  src={
                                    sort.field === "activeSubscriptionStartDate"
                                      ? sort.defaultSortUpImg
                                      : imageBasePath + "up_arrow.svg"
                                  }
                                  alt=""
                                  className="sortUp"
                                  onClick={() =>
                                    handleSort({
                                      field: "activeSubscriptionStartDate",
                                      asc: !sort.asc,
                                      caseSensitive: false,
                                    })
                                  }
                                />
                                <img
                                  src={
                                    sort.field === "activeSubscriptionStartDate"
                                      ? sort.defaultSortDownImg
                                      : imageBasePath + "down_arrow.svg"
                                  }
                                  alt=""
                                  className="sortDown"
                                  onClick={() =>
                                    handleSort({
                                      field: "activeSubscriptionStartDate",
                                      asc: !sort.asc,
                                      caseSensitive: false,
                                    })
                                  }
                                />
                              </div>
                            </Grid>
                            <Grid item md={2}>
                              <div className={styles.devicesListItemTitleTextWrapper}>
                                <p className={styles.devicesListItemTitle + " supportText "}>
                                  Device Status
                                </p>
                                <img
                                  src={
                                    sort.field === "deviceOnlineStatus"
                                      ? sort.defaultSortUpImg
                                      : imageBasePath + "up_arrow.svg"
                                  }
                                  alt=""
                                  className="sortUp"
                                  onClick={() =>
                                    handleSort({
                                      field: "deviceOnlineStatus",
                                      asc: !sort.asc,
                                      caseSensitive: false,
                                    })
                                  }
                                />
                                <img
                                  src={
                                    sort.field === "deviceOnlineStatus"
                                      ? sort.defaultSortDownImg
                                      : imageBasePath + "down_arrow.svg"
                                  }
                                  alt=""
                                  className="sortDown"
                                  onClick={() =>
                                    handleSort({
                                      field: "deviceOnlineStatus",
                                      asc: !sort.asc,
                                      caseSensitive: false,
                                    })
                                  }
                                />
                              </div>
                            </Grid>
                             <div className={styles.deviceTypeTitle}>
                              <div
                                className={
                                  styles.devicesListItemTitleTextWrapper
                                }
                              >
                                <p
                                  className={
                                    styles.devicesListItemTitle +
                                    " supportText "
                                  }
                                >
                                  Device Type
                                </p>
                                <img
                                  src={
                                    sort.field === "deviceOnlineStatus"
                                      ? sort.defaultSortUpImg
                                      : imageBasePath + "up_arrow.svg"
                                  }
                                  alt=""
                                  className="sortUp"
                                />
                                <img
                                  src={
                                    sort.field === "deviceOnlineStatus"
                                      ? sort.defaultSortDownImg
                                      : imageBasePath + "down_arrow.svg"
                                  }
                                  alt=""
                                  className="sortDown"
                                />
                              </div>
                            </div>
                          </Grid>
                        </div>
                        <div className={styles.devicesListTableContent}>
                          {selectedTab == 0 ? (
                            search.val ? (
                              <>
                                {search.list.length === 0 ? (
                                  <NoResult image="no_order.png" title="No search result found" />
                                ) : (
                                  search.list.map((device) => (
                                    <div key={device._id}>
                                      <DevicesListItem
                                        device={device}
                                        roomLists={roomLists}
                                        deviceList={deviceList}
                                        selectedWorkspace={selectedWorkspace}
                                      />
                                    </div>
                                  ))
                                )}
                              </>
                            ) : (
                              deviceLists.map((device) => (
                                <DevicesListItem
                                  device={device}
                                  roomLists={roomLists}
                                  deviceList={deviceList}
                                  selectedWorkspace={selectedWorkspace}
                                />
                              ))
                            )
                          ) : search.val ? (
                            <>
                              {search.list.length === 0 ? (
                                <NoResult image="no_order.png" title="No search result found" />
                              ) : (
                                search.list.map((device) => (
                                  <div key={device._id}>
                                    <DevicesListItem
                                      device={device}
                                      roomLists={roomLists}
                                      deviceList={deviceList}
                                      selectedWorkspace={selectedWorkspace}
                                    />
                                  </div>
                                ))
                              )}
                            </>
                          ) : (
                            activatedDeviceLists.map((device) => (
                              <DevicesListItem
                                device={device}
                                roomLists={roomLists}
                                deviceList={deviceList}
                                selectedWorkspace={selectedWorkspace}
                              />
                            ))
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <NoResult
                  image="no-order-found.svg"
                  title="No devices found"
                  subtext="Start by requesting for quotes and placing orders to view devices under an organisation."
                  btnText="Request quote"
                  link="/request-quote"
                />
              )}
            </>
          )}
        </>
      ) : (
        <NoResult
          image="no-order-found.svg"
          title="No devices found"
          subtext="Start by requesting for quotes and placing orders to view devices under an organisation."
          btnText="Request quote"
          link="/request-quote"
        />
      )}
    </div>
  );
}
