export const State = [
    "Andaman and Nicobar Islands",
	"Andhra Pradesh",
	"Arunachal Pradesh",
	"Assam",
	"Bihar",
	"Chandigarh",
	"Chhattisgarh",
	"Dadra and Nagar Haveli",
	"Daman and Diu",
	"Delhi",
	"Goa",
	"Gujarat",
	"Haryana",
	"Himachal Pradesh",
	"Jammu and Kashmir",
	"Jharkhand",
	"Karnataka",
	"Kerala",
	"Lakshadweep",
	"Madhya Pradesh",
	"Maharashtra",
	"Manipur",
	"Meghalaya",
	"Mizoram",
	"Nagaland",
	"Odisha",
	"Puducherry",
	"Punjab",
	"Rajasthan",
	"Sikkim",
	"Tamil Nadu",
	"Telangana",
	"Tripura",
	"Uttar Pradesh",
	"Uttarakhand",
	"West Bengal"
];
  
export const City = [
        {
          "city": "Delhi",
          "country": "India",
          "state": "Delhi"
        },
        {
          "city": "Mumbai",
          "country": "India",
          "state": "Maharashtra"
        },
        {
          "city": "Kolkata",
          "country": "India",
          "state": "West Bengal"
        },
        {
          "city": "Bangalore",
          "country": "India",
          "state": "Karnataka"
        },
        {
          "city": "Chennai",
          "country": "India",
          "state": "Tamil Nadu"
        },
        {
          "city": "Hyderabad",
          "country": "India",
          "state": "Telangana"
        },
        {
          "city": "Pune",
          "country": "India",
          "state": "Maharashtra"
        },
        {
          "city": "Ahmedabad",
          "country": "India",
          "state": "Gujarat"
        },
        {
          "city": "Surat",
          "country": "India",
          "state": "Gujarat"
        },
        {
          "city": "Lucknow",
          "country": "India",
          "state": "Uttar Pradesh"
        },
        {
          "city": "Jaipur",
          "country": "India",
          "state": "Rajasthan"
        },
        {
          "city": "Cawnpore",
          "country": "India",
          "state": "Uttar Pradesh"
        },
        {
          "city": "Mirzapur",
          "country": "India",
          "state": "Uttar Pradesh"
        },
        {
          "city": "Nagpur",
          "country": "India",
          "state": "Maharashtra"
        },
        {
          "city": "Ghaziabad",
          "country": "India",
          "state": "Uttar Pradesh"
        },
        {
          "city": "Indore",
          "country": "India",
          "state": "Madhya Pradesh"
        },
        {
          "city": "Vadodara",
          "country": "India",
          "state": "Gujarat"
        },
        {
          "city": "Vishakhapatnam",
          "country": "India",
          "state": "Andhra Pradesh"
        },
        {
          "city": "Bhopal",
          "country": "India",
          "state": "Madhya Pradesh"
        },
        {
          "city": "Chinchvad",
          "country": "India",
          "state": "Maharashtra"
        },
        {
          "city": "Patna",
          "country": "India",
          "state": "Bihar"
        },
        {
          "city": "Ludhiana",
          "country": "India",
          "state": "Punjab"
        },
        {
          "city": "agra",
          "country": "India",
          "state": "Uttar Pradesh"
        },
        {
          "city": "Kalyan",
          "country": "India",
          "state": "Maharashtra"
        },
        {
          "city": "Madurai",
          "country": "India",
          "state": "Tamil Nadu"
        },
        {
          "city": "Jamshedpur",
          "country": "India",
          "state": "Jharkhand"
        },
        {
          "city": "Nasik",
          "country": "India",
          "state": "Maharashtra"
        },
        {
          "city": "Faridabad",
          "country": "India",
          "state": "Haryana"
        },
        {
          "city": "Aurangabad",
          "country": "India",
          "state": "Maharashtra"
        },
        {
          "city": "Rajkot",
          "country": "India",
          "state": "Gujarat"
        },
        {
          "city": "Meerut",
          "country": "India",
          "state": "Uttar Pradesh"
        },
        {
          "city": "Jabalpur",
          "country": "India",
          "state": "Madhya Pradesh"
        },
        {
          "city": "Thane",
          "country": "India",
          "state": "Maharashtra"
        },
        {
          "city": "Dhanbad",
          "country": "India",
          "state": "Jharkhand"
        },
        {
          "city": "Allahabad",
          "country": "India",
          "state": "Uttar Pradesh"
        },
        {
          "city": "Varanasi",
          "country": "India",
          "state": "Uttar Pradesh"
        },
        {
          "city": "Srinagar",
          "country": "India",
          "state": "Jammu and Kashmir"
        },
        {
          "city": "Amritsar",
          "country": "India",
          "state": "Punjab"
        },
        {
          "city": "Aligarh",
          "country": "India",
          "state": "Uttar Pradesh"
        },
        {
          "city": "Bhiwandi",
          "country": "India",
          "state": "Maharashtra"
        },
        {
          "city": "Gwalior",
          "country": "India",
          "state": "Madhya Pradesh"
        },
        {
          "city": "Bhilai",
          "country": "India",
          "state": "Chhattisgarh"
        },
        {
          "city": "Haora",
          "country": "India",
          "state": "West Bengal"
        },
        {
          "city": "Ranchi",
          "country": "India",
          "state": "Jharkhand"
        },
        {
          "city": "Bezwada",
          "country": "India",
          "state": "Andhra Pradesh"
        },
        {
          "city": "Chandigarh",
          "country": "India",
          "state": "Chandigarh"
        },
        {
          "city": "Mysore",
          "country": "India",
          "state": "Karnataka"
        },
        {
          "city": "Raipur",
          "country": "India",
          "state": "Chhattisgarh"
        },
        {
          "city": "Kota",
          "country": "India",
          "state": "Rajasthan"
        },
        {
          "city": "Bareilly",
          "country": "India",
          "state": "Uttar Pradesh"
        },
        {
          "city": "Jodhpur",
          "country": "India",
          "state": "Rajasthan"
        },
        {
          "city": "Coimbatore",
          "country": "India",
          "state": "Tamil Nadu"
        },
        {
          "city": "Dispur",
          "country": "India",
          "state": "Assam"
        },
        {
          "city": "Guwahati",
          "country": "India",
          "state": "Assam"
        },
        {
          "city": "Solapur",
          "country": "India",
          "state": "Maharashtra"
        },
        {
          "city": "Trichinopoly",
          "country": "India",
          "state": "Tamil Nadu"
        },
        {
          "city": "Hubli",
          "country": "India",
          "state": "Karnataka"
        },
        {
          "city": "Jalandhar",
          "country": "India",
          "state": "Punjab"
        },
        {
          "city": "Bhubaneshwar",
          "country": "India",
          "state": "Odisha"
        },
        {
          "city": "Bhayandar",
          "country": "India",
          "state": "Maharashtra"
        },
        {
          "city": "Moradabad",
          "country": "India",
          "state": "Uttar Pradesh"
        },
        {
          "city": "Kolhapur",
          "country": "India",
          "state": "Maharashtra"
        },
        {
          "city": "Thiruvananthapuram",
          "country": "India",
          "state": "Kerala"
        },
        {
          "city": "Saharanpur",
          "country": "India",
          "state": "Uttar Pradesh"
        },
        {
          "city": "Warangal",
          "country": "India",
          "state": "Telangana"
        },
        {
          "city": "Salem",
          "country": "India",
          "state": "Tamil Nadu"
        },
        {
          "city": "Malegaon",
          "country": "India",
          "state": "Maharashtra"
        },
        {
          "city": "Kochi",
          "country": "India",
          "state": "Kerala"
        },
        {
          "city": "Gorakhpur",
          "country": "India",
          "state": "Uttar Pradesh"
        },
        {
          "city": "Shimoga",
          "country": "India",
          "state": "Karnataka"
        },
        {
          "city": "Tiruppur",
          "country": "India",
          "state": "Tamil Nadu"
        },
        {
          "city": "Guntur",
          "country": "India",
          "state": "Andhra Pradesh"
        },
        {
          "city": "Raurkela",
          "country": "India",
          "state": "Odisha"
        },
        {
          "city": "Mangalore",
          "country": "India",
          "state": "Karnataka"
        },
        {
          "city": "Nanded",
          "country": "India",
          "state": "Maharashtra"
        },
        {
          "city": "Cuttack",
          "country": "India",
          "state": "Odisha"
        },
        {
          "city": "Chanda",
          "country": "India",
          "state": "Maharashtra"
        },
        {
          "city": "Dehra Dun",
          "country": "India",
          "state": "Uttarakhand"
        },
        {
          "city": "Durgapur",
          "country": "India",
          "state": "West Bengal"
        },
        {
          "city": "asansol",
          "country": "India",
          "state": "West Bengal"
        },
        {
          "city": "Bhavnagar",
          "country": "India",
          "state": "Gujarat"
        },
        {
          "city": "Amravati",
          "country": "India",
          "state": "Maharashtra"
        },
        {
          "city": "Nellore",
          "country": "India",
          "state": "Andhra Pradesh"
        },
        {
          "city": "Ajmer",
          "country": "India",
          "state": "Rajasthan"
        },
        {
          "city": "Tinnevelly",
          "country": "India",
          "state": "Tamil Nadu"
        },
        {
          "city": "Bikaner",
          "country": "India",
          "state": "Rajasthan"
        },
        {
          "city": "Agartala",
          "country": "India",
          "state": "Tripura"
        },
        {
          "city": "Ujjain",
          "country": "India",
          "state": "Madhya Pradesh"
        },
        {
          "city": "Jhansi",
          "country": "India",
          "state": "Uttar Pradesh"
        },
        {
          "city": "Ulhasnagar",
          "country": "India",
          "state": "Maharashtra"
        },
        {
          "city": "Davangere",
          "country": "India",
          "state": "Karnataka"
        },
        {
          "city": "Jammu",
          "country": "India",
          "state": "Jammu and Kashmir"
        },
        {
          "city": "Belgaum",
          "country": "India",
          "state": "Karnataka"
        },
        {
          "city": "Gulbarga",
          "country": "India",
          "state": "Karnataka"
        },
        {
          "city": "Jamnagar",
          "country": "India",
          "state": "Gujarat"
        },
        {
          "city": "Dhulia",
          "country": "India",
          "state": "Maharashtra"
        },
        {
          "city": "Gaya",
          "country": "India",
          "state": "Bihar"
        },
        {
          "city": "Jalgaon",
          "country": "India",
          "state": "Maharashtra"
        },
        {
          "city": "Kurnool",
          "country": "India",
          "state": "Andhra Pradesh"
        },
        {
          "city": "Udaipur",
          "country": "India",
          "state": "Rajasthan"
        },
        {
          "city": "Bellary",
          "country": "India",
          "state": "Karnataka"
        },
        {
          "city": "Sangli",
          "country": "India",
          "state": "Maharashtra"
        },
        {
          "city": "Tuticorin",
          "country": "India",
          "state": "Tamil Nadu"
        },
        {
          "city": "Calicut",
          "country": "India",
          "state": "Kerala"
        },
        {
          "city": "Akola",
          "country": "India",
          "state": "Maharashtra"
        },
        {
          "city": "Bhagalpur",
          "country": "India",
          "state": "Bihar"
        },
        {
          "city": "Sikar",
          "country": "India",
          "state": "Rajasthan"
        },
        {
          "city": "Tumkur",
          "country": "India",
          "state": "Karnataka"
        },
        {
          "city": "Quilon",
          "country": "India",
          "state": "Kerala"
        },
        {
          "city": "Muzaffarnagar",
          "country": "India",
          "state": "Uttar Pradesh"
        },
        {
          "city": "Bhilwara",
          "country": "India",
          "state": "Rajasthan"
        },
        {
          "city": "Nizamabad",
          "country": "India",
          "state": "Telangana"
        },
        {
          "city": "Bhatpara",
          "country": "India",
          "state": "West Bengal"
        },
        {
          "city": "Kakinada",
          "country": "India",
          "state": "Andhra Pradesh"
        },
        {
          "city": "Parbhani",
          "country": "India",
          "state": "Maharashtra"
        },
        {
          "city": "Panihati",
          "country": "India",
          "state": "West Bengal"
        },
        {
          "city": "Latur",
          "country": "India",
          "state": "Maharashtra"
        },
        {
          "city": "Rohtak",
          "country": "India",
          "state": "Haryana"
        },
        {
          "city": "Rajapalaiyam",
          "country": "India",
          "state": "Tamil Nadu"
        },
        {
          "city": "Ahmadnagar",
          "country": "India",
          "state": "Maharashtra"
        },
        {
          "city": "Cuddapah",
          "country": "India",
          "state": "Andhra Pradesh"
        },
        {
          "city": "Rajahmundry",
          "country": "India",
          "state": "Andhra Pradesh"
        },
        {
          "city": "Alwar",
          "country": "India",
          "state": "Rajasthan"
        },
        {
          "city": "Muzaffarpur",
          "country": "India",
          "state": "Bihar"
        },
        {
          "city": "Bilaspur",
          "country": "India",
          "state": "Chhattisgarh"
        },
        {
          "city": "Mathura",
          "country": "India",
          "state": "Uttar Pradesh"
        },
        {
          "city": "Kamarhati",
          "country": "India",
          "state": "West Bengal"
        },
        {
          "city": "Patiala",
          "country": "India",
          "state": "Punjab"
        },
        {
          "city": "Saugor",
          "country": "India",
          "state": "Madhya Pradesh"
        },
        {
          "city": "Bijapur",
          "country": "India",
          "state": "Karnataka"
        },
        {
          "city": "Brahmapur",
          "country": "India",
          "state": "Odisha"
        },
        {
          "city": "Shahjanpur",
          "country": "India",
          "state": "Uttar Pradesh"
        },
        {
          "city": "Trichur",
          "country": "India",
          "state": "Kerala"
        },
        {
          "city": "Barddhaman",
          "country": "India",
          "state": "West Bengal"
        },
        {
          "city": "Kulti",
          "country": "India",
          "state": "West Bengal"
        },
        {
          "city": "Sambalpur",
          "country": "India",
          "state": "Odisha"
        },
        {
          "city": "Purnea",
          "country": "India",
          "state": "Bihar"
        },
        {
          "city": "Hisar",
          "country": "India",
          "state": "Haryana"
        },
        {
          "city": "Firozabad",
          "country": "India",
          "state": "Uttar Pradesh"
        },
        {
          "city": "Bidar",
          "country": "India",
          "state": "Karnataka"
        },
        {
          "city": "Rampur",
          "country": "India",
          "state": "Uttar Pradesh"
        },
        {
          "city": "Shiliguri",
          "country": "India",
          "state": "West Bengal"
        },
        {
          "city": "Bali",
          "country": "India",
          "state": "West Bengal"
        },
        {
          "city": "Panipat",
          "country": "India",
          "state": "Haryana"
        },
        {
          "city": "Karimnagar",
          "country": "India",
          "state": "Telangana"
        },
        {
          "city": "Bhuj",
          "country": "India",
          "state": "Gujarat"
        },
        {
          "city": "Ichalkaranji",
          "country": "India",
          "state": "Maharashtra"
        },
        {
          "city": "Tirupati",
          "country": "India",
          "state": "Andhra Pradesh"
        },
        {
          "city": "Hospet",
          "country": "India",
          "state": "Karnataka"
        },
        {
          "city": "aizawl",
          "country": "India",
          "state": "Mizoram"
        },
        {
          "city": "Sannai",
          "country": "India",
          "state": "Madhya Pradesh"
        },
        {
          "city": "Barasat",
          "country": "India",
          "state": "West Bengal"
        },
        {
          "city": "Ratlam",
          "country": "India",
          "state": "Madhya Pradesh"
        },
        {
          "city": "Handwara",
          "country": "India",
          "state": "Jammu and Kashmir"
        },
        {
          "city": "Drug",
          "country": "India",
          "state": "Chhattisgarh"
        },
        {
          "city": "Imphal",
          "country": "India",
          "state": "Manipur"
        },
        {
          "city": "Anantapur",
          "country": "India",
          "state": "Andhra Pradesh"
        },
        {
          "city": "Etawah",
          "country": "India",
          "state": "Uttar Pradesh"
        },
        {
          "city": "Raichur",
          "country": "India",
          "state": "Karnataka"
        },
        {
          "city": "Ongole",
          "country": "India",
          "state": "Andhra Pradesh"
        },
        {
          "city": "Bharatpur",
          "country": "India",
          "state": "Rajasthan"
        },
        {
          "city": "Begusarai",
          "country": "India",
          "state": "Bihar"
        },
        {
          "city": "Sonipat",
          "country": "India",
          "state": "Haryana"
        },
        {
          "city": "Ramgundam",
          "country": "India",
          "state": "Telangana"
        },
        {
          "city": "Hapur",
          "country": "India",
          "state": "Uttar Pradesh"
        },
        {
          "city": "Uluberiya",
          "country": "India",
          "state": "West Bengal"
        },
        {
          "city": "Porbandar",
          "country": "India",
          "state": "Gujarat"
        },
        {
          "city": "Pali",
          "country": "India",
          "state": "Rajasthan"
        },
        {
          "city": "Vizianagaram",
          "country": "India",
          "state": "Andhra Pradesh"
        },
        {
          "city": "Puducherry",
          "country": "India",
          "state": "Puducherry"
        },
        {
          "city": "Karnal",
          "country": "India",
          "state": "Haryana"
        },
        {
          "city": "Nagercoil",
          "country": "India",
          "state": "Tamil Nadu"
        },
        {
          "city": "Tanjore",
          "country": "India",
          "state": "Tamil Nadu"
        },
        {
          "city": "Sambhal",
          "country": "India",
          "state": "Uttar Pradesh"
        },
        {
          "city": "Naihati",
          "country": "India",
          "state": "West Bengal"
        },
        {
          "city": "Secunderabad",
          "country": "India",
          "state": "Telangana"
        },
        {
          "city": "Kharagpur",
          "country": "India",
          "state": "West Bengal"
        },
        {
          "city": "Dindigul",
          "country": "India",
          "state": "Tamil Nadu"
        },
        {
          "city": "Shimla",
          "country": "India",
          "state": "Himachal Pradesh"
        },
        {
          "city": "Ingraj Bazar",
          "country": "India",
          "state": "West Bengal"
        },
        {
          "city": "Ellore",
          "country": "India",
          "state": "Andhra Pradesh"
        },
        {
          "city": "Puri",
          "country": "India",
          "state": "Odisha"
        },
        {
          "city": "Haldia",
          "country": "India",
          "state": "West Bengal"
        },
        {
          "city": "Nandyal",
          "country": "India",
          "state": "Andhra Pradesh"
        },
        {
          "city": "Bulandshahr",
          "country": "India",
          "state": "Uttar Pradesh"
        },
        {
          "city": "Chakradharpur",
          "country": "India",
          "state": "Jharkhand"
        },
        {
          "city": "Bhiwani",
          "country": "India",
          "state": "Haryana"
        },
        {
          "city": "Gurgaon",
          "country": "India",
          "state": "Haryana"
        },
        {
          "city": "Burhanpur",
          "country": "India",
          "state": "Madhya Pradesh"
        },
        {
          "city": "Khammam",
          "country": "India",
          "state": "Telangana"
        },
        {
          "city": "Madhyamgram",
          "country": "India",
          "state": "West Bengal"
        },
        {
          "city": "Ghandinagar",
          "country": "India",
          "state": "Gujarat"
        },
        {
          "city": "Baharampur",
          "country": "India",
          "state": "West Bengal"
        },
        {
          "city": "Mahbubnagar",
          "country": "India",
          "state": "Telangana"
        },
        {
          "city": "Mahesana",
          "country": "India",
          "state": "Gujarat"
        },
        {
          "city": "adoni",
          "country": "India",
          "state": "Andhra Pradesh"
        },
        {
          "city": "Raiganj",
          "country": "India",
          "state": "West Bengal"
        },
        {
          "city": "Bhusaval",
          "country": "India",
          "state": "Maharashtra"
        },
        {
          "city": "Bahraigh",
          "country": "India",
          "state": "Uttar Pradesh"
        },
        {
          "city": "Shrirampur",
          "country": "India",
          "state": "West Bengal"
        },
        {
          "city": "Tonk",
          "country": "India",
          "state": "Rajasthan"
        },
        {
          "city": "Sirsa",
          "country": "India",
          "state": "Haryana"
        },
        {
          "city": "Jaunpur",
          "country": "India",
          "state": "Uttar Pradesh"
        },
        {
          "city": "Madanapalle",
          "country": "India",
          "state": "Andhra Pradesh"
        },
        {
          "city": "Hugli",
          "country": "India",
          "state": "West Bengal"
        },
        {
          "city": "Vellore",
          "country": "India",
          "state": "Tamil Nadu"
        },
        {
          "city": "Alleppey",
          "country": "India",
          "state": "Kerala"
        },
        {
          "city": "Cuddalore",
          "country": "India",
          "state": "Tamil Nadu"
        },
        {
          "city": "Deo",
          "country": "India",
          "state": "Bihar"
        },
        {
          "city": "Chirala",
          "country": "India",
          "state": "Andhra Pradesh"
        },
        {
          "city": "Machilipatnam",
          "country": "India",
          "state": "Andhra Pradesh"
        },
        {
          "city": "Medinipur",
          "country": "India",
          "state": "West Bengal"
        },
        {
          "city": "Baramula",
          "country": "India",
          "state": "Jammu and Kashmir"
        },
        {
          "city": "Chandannagar",
          "country": "India",
          "state": "West Bengal"
        },
        {
          "city": "Fatehpur",
          "country": "India",
          "state": "Uttar Pradesh"
        },
        {
          "city": "Udipi",
          "country": "India",
          "state": "Karnataka"
        },
        {
          "city": "Tenali",
          "country": "India",
          "state": "Andhra Pradesh"
        },
        {
          "city": "Sitalpur",
          "country": "India",
          "state": "Uttar Pradesh"
        },
        {
          "city": "Conjeeveram",
          "country": "India",
          "state": "Tamil Nadu"
        },
        {
          "city": "Proddatur",
          "country": "India",
          "state": "Andhra Pradesh"
        },
        {
          "city": "Navsari",
          "country": "India",
          "state": "Gujarat"
        },
        {
          "city": "Godhra",
          "country": "India",
          "state": "Gujarat"
        },
        {
          "city": "Budaun",
          "country": "India",
          "state": "Uttar Pradesh"
        },
        {
          "city": "Chittoor",
          "country": "India",
          "state": "Andhra Pradesh"
        },
        {
          "city": "Haripur",
          "country": "India",
          "state": "Punjab"
        },
        {
          "city": "Saharsa",
          "country": "India",
          "state": "Bihar"
        },
        {
          "city": "Vidisha",
          "country": "India",
          "state": "Madhya Pradesh"
        },
        {
          "city": "Pathankot",
          "country": "India",
          "state": "Punjab"
        },
        {
          "city": "Nalgonda",
          "country": "India",
          "state": "Telangana"
        },
        {
          "city": "Dibrugarh",
          "country": "India",
          "state": "Assam"
        },
        {
          "city": "Balurghat",
          "country": "India",
          "state": "West Bengal"
        },
        {
          "city": "Krishnanagar",
          "country": "India",
          "state": "West Bengal"
        },
        {
          "city": "Fyzabad",
          "country": "India",
          "state": "Uttar Pradesh"
        },
        {
          "city": "Silchar",
          "country": "India",
          "state": "Assam"
        },
        {
          "city": "Shantipur",
          "country": "India",
          "state": "West Bengal"
        },
        {
          "city": "Hindupur",
          "country": "India",
          "state": "Andhra Pradesh"
        },
        {
          "city": "Erode",
          "country": "India",
          "state": "Tamil Nadu"
        },
        {
          "city": "Jamuria",
          "country": "India",
          "state": "West Bengal"
        },
        {
          "city": "Habra",
          "country": "India",
          "state": "West Bengal"
        },
        {
          "city": "Ambala",
          "country": "India",
          "state": "Haryana"
        },
        {
          "city": "Mauli",
          "country": "India",
          "state": "Punjab"
        },
        {
          "city": "Kolar",
          "country": "India",
          "state": "Karnataka"
        },
        {
          "city": "Shillong",
          "country": "India",
          "state": "Meghalaya"
        },
        {
          "city": "Bhimavaram",
          "country": "India",
          "state": "Andhra Pradesh"
        },
        {
          "city": "New Delhi",
          "country": "India",
          "state": "Delhi"
        },
        {
          "city": "Mandsaur",
          "country": "India",
          "state": "Madhya Pradesh"
        },
        {
          "city": "Kumbakonam",
          "country": "India",
          "state": "Tamil Nadu"
        },
        {
          "city": "Tiruvannamalai",
          "country": "India",
          "state": "Tamil Nadu"
        },
        {
          "city": "Chicacole",
          "country": "India",
          "state": "Andhra Pradesh"
        },
        {
          "city": "Bankura",
          "country": "India",
          "state": "West Bengal"
        },
        {
          "city": "Mandya",
          "country": "India",
          "state": "Karnataka"
        },
        {
          "city": "Hassan",
          "country": "India",
          "state": "Karnataka"
        },
        {
          "city": "Yavatmal",
          "country": "India",
          "state": "Maharashtra"
        },
        {
          "city": "Pilibhit",
          "country": "India",
          "state": "Uttar Pradesh"
        },
        {
          "city": "Palghat",
          "country": "India",
          "state": "Kerala"
        },
        {
          "city": "Abohar",
          "country": "India",
          "state": "Punjab"
        },
        {
          "city": "Palakollu",
          "country": "India",
          "state": "Andhra Pradesh"
        },
        {
          "city": "Kanchrapara",
          "country": "India",
          "state": "West Bengal"
        },
        {
          "city": "Port Blair",
          "country": "India",
          "state": "Andaman and Nicobar Islands"
        },
        {
          "city": "Alipur Duar",
          "country": "India",
          "state": "West Bengal"
        },
        {
          "city": "Hathras",
          "country": "India",
          "state": "Uttar Pradesh"
        },
        {
          "city": "Guntakal",
          "country": "India",
          "state": "Andhra Pradesh"
        },
        {
          "city": "Navadwip",
          "country": "India",
          "state": "West Bengal"
        },
        {
          "city": "Basirhat",
          "country": "India",
          "state": "West Bengal"
        },
        {
          "city": "Halisahar",
          "country": "India",
          "state": "West Bengal"
        },
        {
          "city": "Rishra",
          "country": "India",
          "state": "West Bengal"
        },
        {
          "city": "Dharmavaram",
          "country": "India",
          "state": "Andhra Pradesh"
        },
        {
          "city": "Baidyabati",
          "country": "India",
          "state": "West Bengal"
        },
        {
          "city": "Darjeeling",
          "country": "India",
          "state": "West Bengal"
        },
        {
          "city": "Sopur",
          "country": "India",
          "state": "Jammu and Kashmir"
        },
        {
          "city": "Gudivada",
          "country": "India",
          "state": "Andhra Pradesh"
        },
        {
          "city": "Adilabad",
          "country": "India",
          "state": "Telangana"
        },
        {
          "city": "Titagarh",
          "country": "India",
          "state": "West Bengal"
        },
        {
          "city": "Chittaurgarh",
          "country": "India",
          "state": "Rajasthan"
        },
        {
          "city": "Narasaraopet",
          "country": "India",
          "state": "Andhra Pradesh"
        },
        {
          "city": "Dam Dam",
          "country": "India",
          "state": "West Bengal"
        },
        {
          "city": "Valparai",
          "country": "India",
          "state": "Tamil Nadu"
        },
        {
          "city": "Osmanabad",
          "country": "India",
          "state": "Maharashtra"
        },
        {
          "city": "Champdani",
          "country": "India",
          "state": "West Bengal"
        },
        {
          "city": "Bangaon",
          "country": "India",
          "state": "West Bengal"
        },
        {
          "city": "Khardah",
          "country": "India",
          "state": "West Bengal"
        },
        {
          "city": "Tadpatri",
          "country": "India",
          "state": "Andhra Pradesh"
        },
        {
          "city": "Jalpaiguri",
          "country": "India",
          "state": "West Bengal"
        },
        {
          "city": "Suriapet",
          "country": "India",
          "state": "Telangana"
        },
        {
          "city": "Tadepallegudem",
          "country": "India",
          "state": "Andhra Pradesh"
        },
        {
          "city": "Bansbaria",
          "country": "India",
          "state": "West Bengal"
        },
        {
          "city": "Negapatam",
          "country": "India",
          "state": "Tamil Nadu"
        },
        {
          "city": "Bhadreswar",
          "country": "India",
          "state": "West Bengal"
        },
        {
          "city": "Chilakalurupet",
          "country": "India",
          "state": "Andhra Pradesh"
        },
        {
          "city": "Kalyani",
          "country": "India",
          "state": "West Bengal"
        },
        {
          "city": "Gangtok",
          "country": "India",
          "state": "Sikkim"
        },
        {
          "city": "Kohima",
          "country": "India",
          "state": "Nagaland"
        },
        {
          "city": "Khambhat",
          "country": "India",
          "state": "Gujarat"
        },
        {
          "city": "Aurangabad",
          "country": "India",
          "state": "Bihar"
        },
        {
          "city": "Emmiganur",
          "country": "India",
          "state": "Andhra Pradesh"
        },
        {
          "city": "Rayachoti",
          "country": "India",
          "state": "Andhra Pradesh"
        },
        {
          "city": "Kavali",
          "country": "India",
          "state": "Andhra Pradesh"
        },
        {
          "city": "Mancheral",
          "country": "India",
          "state": "Telangana"
        },
        {
          "city": "Kadiri",
          "country": "India",
          "state": "Andhra Pradesh"
        },
        {
          "city": "Ootacamund",
          "country": "India",
          "state": "Tamil Nadu"
        },
        {
          "city": "Anakapalle",
          "country": "India",
          "state": "Andhra Pradesh"
        },
        {
          "city": "Sirsilla",
          "country": "India",
          "state": "Telangana"
        },
        {
          "city": "Kamareddipet",
          "country": "India",
          "state": "Telangana"
        },
        {
          "city": "Paloncha",
          "country": "India",
          "state": "Telangana"
        },
        {
          "city": "Kottagudem",
          "country": "India",
          "state": "Telangana"
        },
        {
          "city": "Tanuku",
          "country": "India",
          "state": "Andhra Pradesh"
        },
        {
          "city": "Bodhan",
          "country": "India",
          "state": "Telangana"
        },
        {
          "city": "Karur",
          "country": "India",
          "state": "Tamil Nadu"
        },
        {
          "city": "Mangalagiri",
          "country": "India",
          "state": "Andhra Pradesh"
        },
        {
          "city": "Kairana",
          "country": "India",
          "state": "Uttar Pradesh"
        },
        {
          "city": "Markapur",
          "country": "India",
          "state": "Andhra Pradesh"
        },
        {
          "city": "Malaut",
          "country": "India",
          "state": "Punjab"
        },
        {
          "city": "Bapatla",
          "country": "India",
          "state": "Andhra Pradesh"
        },
        {
          "city": "Badvel",
          "country": "India",
          "state": "Andhra Pradesh"
        },
        {
          "city": "Jorhat",
          "country": "India",
          "state": "Assam"
        },
        {
          "city": "Koratla",
          "country": "India",
          "state": "Telangana"
        },
        {
          "city": "Pulivendla",
          "country": "India",
          "state": "Andhra Pradesh"
        },
        {
          "city": "Jaisalmer",
          "country": "India",
          "state": "Rajasthan"
        },
        {
          "city": "Tadepalle",
          "country": "India",
          "state": "Andhra Pradesh"
        },
        {
          "city": "Armur",
          "country": "India",
          "state": "Telangana"
        },
        {
          "city": "Jatani",
          "country": "India",
          "state": "Odisha"
        },
        {
          "city": "Gadwal",
          "country": "India",
          "state": "Telangana"
        },
        {
          "city": "Nagari",
          "country": "India",
          "state": "Andhra Pradesh"
        },
        {
          "city": "Wanparti",
          "country": "India",
          "state": "Telangana"
        },
        {
          "city": "Ponnuru",
          "country": "India",
          "state": "Andhra Pradesh"
        },
        {
          "city": "Vinukonda",
          "country": "India",
          "state": "Andhra Pradesh"
        },
        {
          "city": "Itanagar",
          "country": "India",
          "state": "Arunachal Pradesh"
        },
        {
          "city": "Tezpur",
          "country": "India",
          "state": "Assam"
        },
        {
          "city": "Narasapur",
          "country": "India",
          "state": "Andhra Pradesh"
        },
        {
          "city": "Kothapet",
          "country": "India",
          "state": "Telangana"
        },
        {
          "city": "Macherla",
          "country": "India",
          "state": "Andhra Pradesh"
        },
        {
          "city": "Kandukur",
          "country": "India",
          "state": "Andhra Pradesh"
        },
        {
          "city": "Samalkot",
          "country": "India",
          "state": "Andhra Pradesh"
        },
        {
          "city": "Bobbili",
          "country": "India",
          "state": "Andhra Pradesh"
        },
        {
          "city": "Sattenapalle",
          "country": "India",
          "state": "Andhra Pradesh"
        },
        {
          "city": "Vrindavan",
          "country": "India",
          "state": "Uttar Pradesh"
        },
        {
          "city": "Mandapeta",
          "country": "India",
          "state": "Andhra Pradesh"
        },
        {
          "city": "Belampalli",
          "country": "India",
          "state": "Telangana"
        },
        {
          "city": "Bhimunipatnam",
          "country": "India",
          "state": "Andhra Pradesh"
        },
        {
          "city": "Nandod",
          "country": "India",
          "state": "Gujarat"
        },
        {
          "city": "Pithapuram",
          "country": "India",
          "state": "Andhra Pradesh"
        },
        {
          "city": "Punganuru",
          "country": "India",
          "state": "Andhra Pradesh"
        },
        {
          "city": "Puttur",
          "country": "India",
          "state": "Andhra Pradesh"
        },
        {
          "city": "Jalor",
          "country": "India",
          "state": "Rajasthan"
        },
        {
          "city": "Palmaner",
          "country": "India",
          "state": "Andhra Pradesh"
        },
        {
          "city": "Dholka",
          "country": "India",
          "state": "Gujarat"
        },
        {
          "city": "Jaggayyapeta",
          "country": "India",
          "state": "Telangana"
        },
        {
          "city": "Tuni",
          "country": "India",
          "state": "Andhra Pradesh"
        },
        {
          "city": "Amalapuram",
          "country": "India",
          "state": "Andhra Pradesh"
        },
        {
          "city": "Jagtial",
          "country": "India",
          "state": "Telangana"
        },
        {
          "city": "Vikarabad",
          "country": "India",
          "state": "Telangana"
        },
        {
          "city": "Venkatagiri",
          "country": "India",
          "state": "Andhra Pradesh"
        },
        {
          "city": "Sihor",
          "country": "India",
          "state": "Gujarat"
        },
        {
          "city": "Jangaon",
          "country": "India",
          "state": "Telangana"
        },
        {
          "city": "Mandamari",
          "country": "India",
          "state": "Telangana"
        },
        {
          "city": "Metpalli",
          "country": "India",
          "state": "Telangana"
        },
        {
          "city": "Repalle",
          "country": "India",
          "state": "Andhra Pradesh"
        },
        {
          "city": "Bhainsa",
          "country": "India",
          "state": "Telangana"
        },
        {
          "city": "Jasdan",
          "country": "India",
          "state": "Gujarat"
        },
        {
          "city": "Jammalamadugu",
          "country": "India",
          "state": "Andhra Pradesh"
        },
        {
          "city": "Rameswaram",
          "country": "India",
          "state": "Tamil Nadu"
        },
        {
          "city": "Addanki",
          "country": "India",
          "state": "Andhra Pradesh"
        },
        {
          "city": "Nidadavole",
          "country": "India",
          "state": "Andhra Pradesh"
        },
        {
          "city": "Bodupal",
          "country": "India",
          "state": "Telangana"
        },
        {
          "city": "Rajgir",
          "country": "India",
          "state": "Bihar"
        },
        {
          "city": "Rajaori",
          "country": "India",
          "state": "Jammu and Kashmir"
        },
        {
          "city": "Naini Tal",
          "country": "India",
          "state": "Uttarakhand"
        },
        {
          "city": "Channarayapatna",
          "country": "India",
          "state": "Karnataka"
        },
        {
          "city": "Maihar",
          "country": "India",
          "state": "Madhya Pradesh"
        },
        {
          "city": "Panaji",
          "country": "India",
          "state": "Goa"
        },
        {
          "city": "Junnar",
          "country": "India",
          "state": "Maharashtra"
        },
        {
          "city": "Amudalavalasa",
          "country": "India",
          "state": "Andhra Pradesh"
        },
        {
          "city": "Daman",
          "country": "India",
          "state": "Gujarat"
        },
        {
          "city": "Kovvur",
          "country": "India",
          "state": "Andhra Pradesh"
        },
        {
          "city": "Solan",
          "country": "India",
          "state": "Himachal Pradesh"
        },
        {
          "city": "Dwarka",
          "country": "India",
          "state": "Gujarat"
        },
        {
          "city": "Pathanamthitta",
          "country": "India",
          "state": "Kerala"
        },
        {
          "city": "Kodaikanal",
          "country": "India",
          "state": "Tamil Nadu"
        },
        {
          "city": "Udhampur",
          "country": "India",
          "state": "Jammu and Kashmir"
        },
        {
          "city": "Giddalur",
          "country": "India",
          "state": "Andhra Pradesh"
        },
        {
          "city": "Yellandu",
          "country": "India",
          "state": "Telangana"
        },
        {
          "city": "Shrirangapattana",
          "country": "India",
          "state": "Karnataka"
        },
        {
          "city": "Angamali",
          "country": "India",
          "state": "Kerala"
        },
        {
          "city": "Umaria",
          "country": "India",
          "state": "Madhya Pradesh"
        },
        {
          "city": "Fatehpur Sikri",
          "country": "India",
          "state": "Uttar Pradesh"
        },
        {
          "city": "Mangur",
          "country": "India",
          "state": "Telangana"
        },
        {
          "city": "Pedana",
          "country": "India",
          "state": "Andhra Pradesh"
        },
        {
          "city": "Uran",
          "country": "India",
          "state": "Maharashtra"
        },
        {
          "city": "Chimakurti",
          "country": "India",
          "state": "Andhra Pradesh"
        },
        {
          "city": "Devarkonda",
          "country": "India",
          "state": "Telangana"
        },
        {
          "city": "Bandipura",
          "country": "India",
          "state": "Jammu and Kashmir"
        },
        {
          "city": "Silvassa",
          "country": "India",
          "state": "Dadra and Nagar Haveli"
        },
        {
          "city": "Pamidi",
          "country": "India",
          "state": "Andhra Pradesh"
        },
        {
          "city": "Narasannapeta",
          "country": "India",
          "state": "Andhra Pradesh"
        },
        {
          "city": "Jaynagar-Majilpur",
          "country": "India",
          "state": "West Bengal"
        },
        {
          "city": "Khed Brahma",
          "country": "India",
          "state": "Gujarat"
        },
        {
          "city": "Khajuraho",
          "country": "India",
          "state": "Madhya Pradesh"
        },
        {
          "city": "Koilkuntla",
          "country": "India",
          "state": "Andhra Pradesh"
        },
        {
          "city": "Diu",
          "country": "India",
          "state": "Daman and Diu"
        },
        {
          "city": "Kulgam",
          "country": "India",
          "state": "Jammu and Kashmir"
        },
        {
          "city": "Gauripur",
          "country": "India",
          "state": "Assam"
        },
        {
          "city": "Abu",
          "country": "India",
          "state": "Rajasthan"
        },
        {
          "city": "Curchorem",
          "country": "India",
          "state": "Goa"
        },
        {
          "city": "Kavaratti",
          "country": "India",
          "state": "Lakshadweep"
        },
        {
          "city": "Panchkula",
          "country": "India",
          "state": "Haryana"
        },
        {
          "city": "Kagaznagar",
          "country": "India",
          "state": "Telangana"
        }
      ];
  