import React, { useState } from "react";
import styles from "../../../assets/styles/quotations.module.scss";
import { QuoteOrder, PlaceOrder, SubmitQuoteSuccess } from "../components";
import { useLocation, useParams } from "react-router-dom";

function QuotationOrder(props) {
  const [step, setStep] = useState(1);
  const [allData, setAllData] = useState({});
  const [submitQuoteSuccess, setSubmitQuoteSuccess] = useState(false);
  let location = useLocation();
  const { _id } = useParams();

  const handleUpdateStep = (val) => {
    if (val === 2) {
      setStep(2);
    } else {
      setStep(1);
    }
  };

  const handleQuoteOrderFormData = (quotationRequest, pdfFile, poNumber) => {
    setAllData({ quotationRequest: quotationRequest, pdfFile: pdfFile, poNumber: poNumber});
  }
  //console.log(allData);

  const allSteps = {
    1: (
      <div className={styles.quotationOrderWrapper}>
        <QuoteOrder 
          handleUpdateStep={handleUpdateStep}
          handleQuoteOrderFormData={handleQuoteOrderFormData}
          step={step}
        />
      </div>
    ),
    2: (
      <div className={styles.placeOrderWrapper}>
        <PlaceOrder 
          handleUpdateStep={handleUpdateStep}
          handleQuoteOrderFormData={handleQuoteOrderFormData}
          setSubmitQuoteSuccess={setSubmitQuoteSuccess}
          allData={allData}
          step={step}
        />
      </div>
    ),
  };

  return (
    <div className={`${styles.quotationOrderPage} 
    ${location.pathname === `/quotations/${_id}` ? styles.quotationsHomePage : ""}`}
    >
      {submitQuoteSuccess ? (
        <SubmitQuoteSuccess 
          allData={allData}
        />
        ) : (
          allSteps[step]
          )}
    </div>
  );
}

export default QuotationOrder;
