import React from "react";
import { Grid, List, ListItem } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

export default function Shimmer(props) {
  return (
    <>
      {props.type == "activate" && (
        <>
          {[0].map((item, key) => (
            <React.Fragment key={key}>
              <div className="organisationDropdownSearchInput">
                <Skeleton
                  variant="text"
                  width={250}
                  height={25}
                  style={{
                    display: "block",
                    verticalAlign: "middle",
                    transform: "none",
                    marginTop: 25,
                    marginLeft: 20,
                  }}
                />
              </div>
              <div className="companyListWrapper">
                <div className="companyAllListWrapper">
                  <div className="companyAllTitleWrapper">
                    <div className="companyAllListTitle">
                      <Skeleton
                        variant="text"
                        width={150}
                        height={20}
                        style={{
                          display: "block",
                          verticalAlign: "middle",
                          transform: "none",
                          marginTop: 5,
                          marginLeft: 5,
                        }}
                      />
                    </div>
                    <div className="companyAllViewLink">
                      <Skeleton
                        variant="text"
                        width={40}
                        height={20}
                        style={{
                          display: "block",
                          verticalAlign: "middle",
                          transform: "none",
                          marginTop: 5,
                          marginRight: 0,
                          marginBottom: 10,
                        }}
                      />
                    </div>
                  </div>
                  {[0, 1, 2, 3, 4].map((orgName, key) => (
                    <div>
                      <div className="companyAllListBlk">
                        <div className="companyAllListName">
                          <Skeleton
                            variant="text"
                            width={200}
                            height={10}
                            style={{
                              display: "block",
                              verticalAlign: "middle",
                              transform: "none",
                              marginTop: 2,
                              marginLeft: 0,
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="addOrgButtonWrapper">
                <div className="addButton label"></div>
              </div>
            </React.Fragment>
          ))}
        </>
      )}
      {props.type == "orgName" && (
        <>
          {[0].map((item, key) => (
            <React.Fragment key={key}>
              <div className="companyNameSection">
                <Skeleton
                  variant="circle"
                  width={30}
                  height={30}
                  style={{
                    display: "block",
                    verticalAlign: "middle",
                    transform: "none",
                    marginLeft: 20,
                  }}
                />
                <div className=" companyName subheading2">
                  <Skeleton
                    variant="text"
                    width={180}
                    height={20}
                    style={{
                      display: "block",
                      verticalAlign: "middle",
                      transform: "none",
                      marginLeft: 5,
                    }}
                  />
                </div>
              </div>
            </React.Fragment>
          ))}
        </>
      )}
    </>
  );
}
