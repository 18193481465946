import React, { useState } from "react";
import styles from "../../../assets/styles/auth.module.scss";
import { imageBasePath } from "../../../constants";
import { InputText, CustomButton } from "../../../shared_ui_components";
import { fieldValidation } from "../../../utils/formValidation";
import { updatePassword, verifyTokeApi } from "../ApiService";
import { errorCodes } from "../../../constants";
import { toastFlashMessage } from "../../../utils";
import { useLocation } from "react-router-dom";

export default function CreatePasswordForm({ handleStatusChange }) {
  const [formData, setFormData] = useState({});
  const [error, setError] = useState({});
  const [errorPassword, setErrorPassword] = useState(false);
  const [validateLoader, setValidateLoader] = useState(false);

  const search = useLocation().search;
  const access_token = new URLSearchParams(search).get("accessToken");

  const handleChange = (key, value) => {
    setFormData((form) => ({
      ...form,
      [key]: value,
    }));
    setError((preError) => ({
      ...preError,
      [key]: "",
    }));
  };

  const togglePassword = (key) => {
    setFormData((form) => ({
      ...form,
      show_password: key === form.show_password ? "" : key,
    }));
  };
  const handleUpdatePassword = (e) => {
    e.preventDefault();
    let validateNewInput = {};
    validateNewInput = {
      newPassword:
        errorCodes["newPassword"][
          fieldValidation({ ...errorCodes.newPasswordObj, fieldval: formData?.newPassword })
        ],
      confirmPassword:
        errorCodes["confirmPassword"][
          fieldValidation({ ...errorCodes.confirmPasswordObj, fieldval: formData?.confirmPassword })
        ],
    };
    if (formData.newPassword !== formData.confirmPassword) {
      setErrorPassword(true);
    } else if (Object.keys(validateNewInput).every((k) => validateNewInput[k] === "")) {
      setValidateLoader(true);
      if (access_token) {
        verifyTokeApi({ Authorization: "Bearer " + access_token }).then((response) => {
          if (response.status) {
            const userInfo = {
              accessToken: access_token,
              userData: response.userData,
            };
            localStorage.setItem("userInfo", JSON.stringify(userInfo));
            updatePassword({ newPassword: formData.newPassword }).then((response) => {
              if (response.status) {
                toastFlashMessage("Successfully Updated the password", "success");
                handleStatusChange();
                setValidateLoader(false);
              } else {
                toastFlashMessage("Something went wrong. Please try again!", "error");
                setValidateLoader(false);
              }
            });
          }
        });
      }
    } else {
      setError(validateNewInput);
    }
  };

  return (
    <div className={styles.resetPasswordFormWrapper}>
      {/* <div className={styles.resetPasswordEmailWrapper}>
                <p className={styles.resetPasswordEmail+" subheading2"}>Email :<span>johndoe@email.com</span></p>
            </div> */}
      <form onSubmit={handleUpdatePassword}>
        <div className={styles.passwordTabInput}>
          <InputText
            type={formData.show_password === "newPassword" ? "text" : "password"}
            className={styles.passwordInputField}
            label="New Password"
            placeholder="********"
            value={formData.newPassword || ""}
            error={error.newPassword}
            onChange={(e) => {
              handleChange("newPassword", e.target.value);
            }}
          />
          <span className={styles.showPassword} onClick={() => togglePassword("newPassword")}>
            {formData.show_password === "newPassword" ? (
              <img src={imageBasePath + "eye_hide.svg"} alt="Hide" />
            ) : (
              <img src={imageBasePath + "eye_show.svg"} alt="Show" />
            )}
          </span>
        </div>
        <div className={styles.passwordTabInput}>
          <InputText
            type={formData.show_password === "confirmPassword" ? "text" : "password"}
            className={styles.passwordInputField}
            label="Confirm Password"
            placeholder="********"
            value={formData.confirmPassword || ""}
            error={error.confirmPassword}
            onChange={(e) => {
              handleChange("confirmPassword", e.target.value);
            }}
          />
          <span className={styles.showPassword} onClick={() => togglePassword("confirmPassword")}>
            {formData.show_password === "confirmPassword" ? (
              <img src={imageBasePath + "eye_hide.svg"} alt="Hide" />
            ) : (
              <img src={imageBasePath + "eye_show.svg"} alt="Show" />
            )}
          </span>
        </div>
        {errorPassword && <h6 className="errorMsg">Password is not matching</h6>}
        <CustomButton
          className={`${styles.signInButton} primaryBtn ${validateLoader ? "disabledBtn" : ""}`}
          children={validateLoader ? "Submitting..." : "Submit"}
          //onClick={handleUpdatePassword}
          type="submit"
        />
      </form>
    </div>
  );
}
