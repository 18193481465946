import React from "react";
import styles from "../../../assets/styles/workspaces.module.scss";
import { Grid, Tooltip } from "@material-ui/core";
import { imageBasePath } from "../../../constants";
import { Link } from "react-router-dom";

export const SingleWorkSpaceList = ({ localAdmins, orgLocation, rooms }) => {
  const data = localAdmins?.map((item) => item?.userId?.email);
  return (
    <Link to={`./workspaces/${orgLocation?._id}`}>
      <div className={styles.workspaceListItem}>
        <div className={styles.workspacesListTableRow}>
          <Grid container spacing={2}>
            <Grid item md={12}>
              <Grid container direction="row" spacing={2} justifyContent="flex-start" alignItems="center">
                <Grid item md={3}>
                  <div className={styles.workspaceContentWrapper}>
                    <div className={styles.workspaceImageWrapper}>
                      <img src={imageBasePath + "building-fill.svg"} className={styles.workspaceBox} alt="" />
                    </div>
                    <div className={styles.workspaceDetailWrapper}>
                      <p className={styles.workspaceName + " paragraphSemiBold "}>
                        {orgLocation?.orgWorkSpaceName}
                      </p>
                    </div>
                  </div>
                </Grid>
                <Grid item md={4}>
                  <div className={styles.workspaceContentWrapper}>
                    {orgLocation?.addressId?.address?.length > 30 ? (
                      <Tooltip title={orgLocation?.addressId?.address} placement="top-start">
                        <p className={styles.workspaceAddress + " paragraph "}>
                          {orgLocation?.addressId?.address.substring(0, 30)}...
                        </p>
                      </Tooltip>
                    ) : (
                      <p className={styles.workspaceAddress + " paragraph "}>
                        {orgLocation?.addressId?.address}
                      </p>
                    )}
                  </div>
                </Grid>
                <Grid item md={2}>
                  <div className={styles.workspaceContentWrapper}>
                    <p className={styles.workspaceRoom + " paragraph "}>{rooms}</p>
                  </div>
                </Grid>
                <Grid item md={3}>
                  <div className={styles.workspaceContentWrapper}>
                    <p className={styles.workspaceAdmin + " paragraph "}>
                      {data.length === 0 ? (
                        "..."
                      ) : data.length < 3 ? (
                        data.map((admin) => admin).join(", ")
                      ) : (
                        <>
                          {data
                            .map((admin) => admin)
                            .slice(0, 2)
                            .join(", ")}
                          <Tooltip
                            title={data
                              .map((admin) => admin)
                              .slice(2, data.length)
                              .join(", ")}
                            placement="top-start"
                          >
                            <span style={{ color: "blue" }}> +{data.length - 2} more</span>
                          </Tooltip>
                        </>
                      )}
                    </p>
                  </div>
                </Grid>
                {/* <Grid item md={1}>
                                              <div className={styles.moreAction+" hidden"}>
                                                  <img
                                                      src={imageBasePath + "more_vertical_dark_icon.svg"}
                                                      alt=""
                                                  />
                                              </div>
                                          </Grid> */}
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
    </Link>
  );
};
