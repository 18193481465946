import React, { useContext, useState, useEffect, Fragment } from "react";
import { List, ListItem } from "@material-ui/core";
import { Link, useLocation, useParams } from "react-router-dom";
import { imageBasePath } from "../constants";
import AssessmentIcon from '@material-ui/icons/Assessment';

export default function Sidebar(props) {
  const { id, _id } = useParams();
  let location = useLocation();

  return (
    <div className="sideBar">
      <div className="sidebarWrapper">
        <div className="menuListBlk">
          <List className="listUnstyled">
            <ListItem className={`listItem ${location.pathname === `/${id}/overview` ? "active" : ""}`}>
              <Link to={`/${id}/overview`}>
                <img className="activeImg" src={imageBasePath + "overview_active.svg"} alt="Overview" />
                <img className="defaultImg" src={imageBasePath + "overview_default.svg"} alt="Overview" />
                <p className="supportText">Overview</p>
              </Link>
            </ListItem>
            <ListItem
              className={`listItem ${
                location.pathname === `/${id}/devices` || location.pathname === `/${id}/devices/${_id}`
                  ? "active"
                  : ""
              }`}
            >
              <Link to={`/${id}/devices`}>
                <img className="activeImg" src={imageBasePath + "device_active.svg"} alt="Devices" />
                <img className="defaultImg" src={imageBasePath + "device_default.svg"} alt="Devices" />
                <p className="supportText">Devices</p>
              </Link>
            </ListItem>
            <ListItem
              className={`listItem ${
                location.pathname === `/${id}/workspaces` || location.pathname === `/${id}/workspaces/${_id}`
                  ? "active"
                  : ""
              }`}
            >
              <Link to={`/${id}/workspaces`}>
                <img className="activeImg" src={imageBasePath + "workspaces_active.svg"} alt="Workspaces" />
                <img className="defaultImg" src={imageBasePath + "workspaces_default.svg"} alt="Workspaces" />
                <p className="supportText">Workspaces</p>
              </Link>
            </ListItem>
            <ListItem className={`listItem ${location.pathname === `/${id}/orders` ? "active" : ""}`}>
              <Link to={`/${id}/orders`}>
                <img className="activeImg" src={imageBasePath + "order_active.svg"} alt="Orders" />
                <img className="defaultImg" src={imageBasePath + "order_default.svg"} alt="Orders" />
                <p className="supportText">Orders</p>
              </Link>
            </ListItem>
            <ListItem
              className={`listItem ${
                location.pathname === `/${id}/quotations` || location.pathname === `/${id}/quotations/${_id}`
                  ? "active"
                  : ""
              }`}
            >
              <Link to={`/${id}/quotations`}>
                <img className="activeImg" src={imageBasePath + "quotation_active.svg"} alt="Quotations" />
                <img className="defaultImg" src={imageBasePath + "quotation_default.svg"} alt="Quotations" />
                <p className="supportText">Quotations</p>
              </Link>
            </ListItem>
            <ListItem className={`listItem ${location.pathname === `/${id}/users` ? "active" : ""}`}>
              <Link to={`/${id}/users`}>
                <img className="activeImg" src={imageBasePath + "user_active.svg"} alt="Users" />
                <img className="defaultImg" src={imageBasePath + "user_default.svg"} alt="Users" />
                <p className="supportText">Users</p>
              </Link>
            </ListItem>
            <ListItem className={`listItem ${location.pathname === `/${id}/settings` ? "active" : ""}`}>
              <Link to={`/${id}/settings`}>
                <img className="activeImg" src={imageBasePath + "settings_active.svg"} alt="Settings" />
                <img className="defaultImg" src={imageBasePath + "settings_default.svg"} alt="Settings" />
                <p className="supportText">Settings</p>
              </Link>
            </ListItem>
            <ListItem className={`listItem ${location.pathname === `/${id}/reports` ? "active" : ""}`} style={{marginTop:"4px"}}>
              <Link to={`/${id}/reports`} >
                <AssessmentIcon fontSize="small" style={{margin:"15px", color:"#99a2a9"}} />
                <p className="supportText">Reports</p>
              </Link>
            </ListItem>
            {/* <ListItem className={`listItem ${location.pathname === `/${id}/resources` ? "active" : ""}`} style={{marginTop:"4px"}}>
              <Link to={`/${id}/resources`} >
                <AssessmentIcon fontSize="small" style={{margin:"15px", color:"#99a2a9"}} />
                <p className="supportText">Resources</p>
              </Link>
            </ListItem> */}
             <ListItem className={`listItem ${location.pathname === `/${id}/deployments` ? "active" : ""}`} style={{marginTop:"4px"}}>
              <Link to={`/${id}/deployments`} >
                <img className="" src="../images/rocket-launch-outline.svg" alt="" style={{margin:"15px",color: "rgb(153, 162, 169)"}} />
                <p className="supportText">Deployments</p>
              </Link>
            </ListItem>
          </List>
        </div>
      </div>
    </div>
  );
}
