import pageLayoutHoc from "../../hoc/pageLayoutHoc";
import Webex from "./components/Webex";
import Zoom from "./components/Zoom";
import ContactSupport from "./components/ContactSupport";
import ContactUs from "./components/ContactUs";

export const integrationRoutes = [
  {
    path: "/apps/webex",
    component: pageLayoutHoc(Webex),
    key: "webex",
  },
  {
    path: "/apps/zoom",
    component: pageLayoutHoc(Zoom),
    key: "zoom",
  },
  {
    path: "/support",
    component: pageLayoutHoc(ContactSupport),
    key: "support",
  },
  {
    path: "/contact-us",
    component: pageLayoutHoc(ContactUs),
    key: "contact-us",
  },
];
