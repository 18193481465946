import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { Header, Footer } from "../shared_elements";
import {getUserInfo}  from "../utils";
export default function pageLayoutHoc(HocComponent) {
  class PageLayoutHoc extends Component {
    constructor(props) {
      super(props);
    }

    componentWillMount(){
      const userInfo = getUserInfo();
      if(userInfo?.accessToken){
        this.props.history.push('/home');
      }
    }

    render() {
      const currentPath = this.props?.location?.pathname;
      return (
        <div>
          {currentPath === "/registration" ? null : <Header />}
          <HocComponent role="ADMIN" {...this.props} />
          <Footer />
        </div>
      );
    }
  }
  return withRouter(PageLayoutHoc);
}
