import { Grid } from "@material-ui/core";
import React from "react";
import { format} from "date-fns";
import styles from "../../../assets/styles/devices.module.scss";
import { Link } from "react-router-dom";
import Message from "../../../shared_ui_components/StatusMessage";


function DeploymentList({ deployment, orgName, workspaceName, release ,createdAt}) {
  const display = format(new Date(createdAt), "dd-MMM-yyyy hh:mm a");

  return (
    <Link to={`./deployments/${deployment._id}`}>
      <div className={styles.devicesListTableRow}>
        <Grid container spacing={2}>
          <Grid item md={2}>
            <div className={styles.devicesContentWrapper}>
              <p className={styles.deviceRoom + " paragraph "}>{orgName}</p>
            </div>
          </Grid>
          <Grid item md={2}>
            <div className={styles.devicesContentWrapper}>
              <p className={styles.deviceRoom + " paragraph "}>
                {workspaceName ? workspaceName : "-"}
              </p>
            </div>
          </Grid>
          <Grid item md={2}>
            <div className={styles.devicesContentWrapper}>
              <p className={styles.deviceRoom + " paragraph "}>{release}</p>
            </div>
          </Grid>
          <Grid item md={2}>
            <div className={styles.devicesContentWrapper}>
              <Message message={deployment.status}/>
            </div>
          </Grid>
          <Grid item md={2}>
            <div className={styles.devicesContentWrapper}>
              <p className={styles.deviceRoom + " paragraph "}>
                {deployment.devices.length}
              </p>
            </div>
          </Grid>
          <Grid item md={2}>
            <div className={styles.devicesContentWrapper}>
              <p
              className={styles.deviceRoom + " paragraph "}
              style={{ display: "flex", flexDirection: "column" }}
            >
              {display}
            </p>
            </div>
          </Grid>
        </Grid>
      </div>
    </Link>
  );
}

export default DeploymentList;
