import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Container, Grid } from "@material-ui/core";
import { imageBasePath } from "../../../constants";
import styles from "../../../assets/styles/quotationDetail.module.scss";
import { CustomButton } from "../../../shared_ui_components";

export default function QuotationRequestSuccess(props) {
  const history = useHistory();

  const handleGoToHome = () => {
    history.push("/home");
  };

  const goToQuotations = () => {
    history.push("/quotations");
  };

  return (
    <div className={styles.quotationStatusBlk}>
      <Grid container direction="row" spacing={2} justifyContent="center" alignItems="center">
        <Grid item md={4}>
          <div className={styles.quotationStatus}>
            <div className={styles.statusImage}>
              <img src={imageBasePath + "quotation_success.png"} alt="quotation_confirm" />
            </div>
            <div className={styles.statusBody}>
              <h2 className={styles.statusTitle + " heading6 "}>Quotation is Requested</h2>
              <p className={styles.statusSubtext + " paragraph "}>
                Quote Request for <span>{props.quotationData}.</span> <br /> shared successfully
              </p>
              <div className={styles.statusButtonWrapper}>
                <CustomButton
                  onClick={goToQuotations}
                  className={styles.actionBtn + " primaryBtn "}
                  children="Go to Quotations"
                />
                <CustomButton onClick={handleGoToHome} className=" linkBtn " children="Back to home" />
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
