import Workspaces from "./container/Workspaces";
import WorkspaceDetails from "./container/WorkspaceDetails";
import QuotationLayoutHoc from "../../hoc/quotationLayoutHoc";

export const workspacesRoute = [
  {
    path: "/:id/workspaces",
    component: QuotationLayoutHoc(Workspaces),
    key: "workspaces",
  },
  // {
  //   path: "/:id/workspace-details/:_id",
  //   component: QuotationLayoutHoc(WorkspaceDetails),
  //   key: "workspace-details",
  // },
  {
    path: "/:id/workspaces/:_id",
    component: QuotationLayoutHoc(WorkspaceDetails),
    key: "workspaces",
  },
];
