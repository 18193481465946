import { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Container, Grid } from "@material-ui/core";
import styles from "../../../assets/styles/quotations.module.scss";
import { imageBasePath } from "../../../constants";
import { CustomButton } from "../../../shared_ui_components";

export default function SubmitQuoteSuccess(props) {
    const { allData } = props;
    const history = useHistory();
    const { id } = useParams();

    const handleGoToHome = () => {
        history.push("/home");
    };

    const goToOrders = () => {
        history.push(`/${id}/orders`);
    };

    return (
        <div className={styles.quotationStatusBlk}>
            <Grid container direction="row" spacing={2} justifyContent="center" alignItems="center">
                <Grid item md={4}>
                    <div className={styles.quotationStatus}>
                        <div className={styles.statusImage}>
                            <img src={imageBasePath + "quotation_success.png"} alt="quotation_confirm" />
                        </div>
                        <div className={styles.statusBody}>
                            <h2 className={styles.statusTitle + " heading6 "}>Thank you</h2>
                            <p className={styles.statusSubtext + " paragraph "}>
                                Your order {" "}
                                <span>
                                    {allData.quotationRequest?.quotationReqDetails?.quotationNo ?
                                        allData.quotationRequest?.quotationReqDetails?.quotationNo 
                                        : "NA"
                                    }
                                </span> 
                                <br />was placed successfully.
                            </p>
                            <div className={styles.statusButtonWrapper}>
                                <CustomButton
                                    onClick={goToOrders}
                                    className={styles.actionBtn + " primaryBtn "}
                                    children="Track you order"
                                />
                                <CustomButton onClick={handleGoToHome} className=" linkBtn " children="Back to home" />
                            </div>
                        </div>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
}
