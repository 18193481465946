import React from "react";
import { FormControl, InputLabel } from "@material-ui/core";
import ReactSelect from "react-select";
import CreatableSelect from "react-select/creatable";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { reactSelectStyles } from "../constants";
import PropTypes from "prop-types";

const defaultOptions = {
  closeMenuOnSelect: true,
  maxMenuHeight: 200,
  components: { DropdownIndicator: ExpandMoreIcon },
  className: "reactSelect",
  styles: reactSelectStyles,
};

export default function InputSelect(props) {
  return (
    <div className={`inputGroup `}>
      <FormControl className="formControl" error={props.error ? true : false} required={props.required}>
        {props.label ? (
          <InputLabel shrink id="actionLabel">
            {props.label}
          </InputLabel>
        ) : null}
        {props.isCreatable ? <CreatableSelect {...defaultOptions} {...props} /> : <ReactSelect {...defaultOptions} {...props} />}
      </FormControl>
      {props.error ? <h6 className="errorMsg">{props.error}</h6> : null}
    </div>
  );
}

InputSelect.propTypes = {
  label: PropTypes.string,
  isCreatable: PropTypes.bool,
};

InputSelect.defaultProps = {
  label: "",
  isCreatable: false,
};
