import React, { useState } from "react";
import { Grid, Container } from "@material-ui/core";
import styles from "../../../assets/styles/registration.module.scss";
import BecomePartner from "../components/BecomePartner";
import PartnerSuccess from "../components/PartnerSuccess";
import { imageBasePath } from "../../../constants";
import { Link } from "react-router-dom";
import { CustomLink } from "../../../shared_ui_components";

export default function Partner(props) {
  const [isRegistrationSuccess, setIsRegistrationSuccess] = useState(false);

  const handleChangeStatus = () => {
    setIsRegistrationSuccess(!isRegistrationSuccess);
  };

  console.log(isRegistrationSuccess);

  return (
    <div className={styles.partnerPage}>
      <Container maxWidth="lg">
        <div className={styles.partnerPageWrapper}>
          <Grid container spacing={2}>
            <Grid item md={5} xs={12}>
              <div className={styles.partnerLeftBlk}>
                <Link to="/">
                  <img className={styles.logoInPartner} src={imageBasePath + "logo.svg"} alt="Letsving" />
                </Link>
                <CustomLink
                  to="/"
                  className={styles.backBtn + " customLink" + " paragraph"}
                  text="Back to Home"
                  leftIcon={imageBasePath + "arrow_left_icon.svg"}
                />
                <p className={styles.partnerLeftBlkHeader + " heading4"}>Become a partner</p>
                <p className={styles.partnerLeftBlkSubHeader + " subheading2"}>
                  Thank you for your interest in becoming a partner.
                </p>
                {isRegistrationSuccess === false && (
                  <p className={styles.partnerLeftBlkText + " paragraph"}>
                    Please fill out this form to begin your journey to becoming <br /> a Ving Partner.
                  </p>
                )}
                <img src={imageBasePath + "partner_left_image.png"} alt="partner_image" />
              </div>
            </Grid>
            <Grid item md={7} xs={12}>
              <div className={styles.partnerRightBlk}>
                {isRegistrationSuccess ? (
                  <PartnerSuccess />
                ) : (
                  <BecomePartner handleChangeStatus={handleChangeStatus} />
                )}
              </div>
            </Grid>
          </Grid>
        </div>
      </Container>
    </div>
  );
}
