import React from "react";
import { Grid } from "@material-ui/core";
import styles from "../../../../assets/styles/admin/partner.module.scss";
import { imageBasePath } from "../../../../constants";

export default function PartnerDetailCard(props) {
    return (
        <div className={styles.partnerDetailsCardBlk}>
            <Grid container spacing={2}>
                <Grid item md={4}>
                    <div className={styles.partnerDetailsCard}>
                        <div className={styles.partnerCardWrapper}>
                            <img src={imageBasePath + "organization_logo.svg"} alt=""/>
                            <div className={styles.partnerCardContent}>
                                <p className="supportText">Total Organizations</p>
                                <span className="subheading2"> 5</span>
                            </div>
                        </div>
                        <div className={styles.partnerCardArrow}>
                            <img src={imageBasePath + "arrow_right_gray_icon.svg"} alt=""/>
                        </div>
                    </div>
                </Grid>
                <Grid item md={4}>
                    <div className={styles.partnerDetailsCard}>
                        <div className={styles.partnerCardWrapper}>
                            <img src={imageBasePath + "organization_members_icon.svg"} alt=""/>
                            <div className={styles.partnerCardContent}>
                                <p className="supportText">Total Team Members</p>
                                <span className="subheading2"> 5</span>
                            </div>
                        </div>
                        <div className={styles.partnerCardArrow}>
                            <img src={imageBasePath + "arrow_right_gray_icon.svg"} alt=""/>
                        </div>
                    </div>
                </Grid>
                <Grid item md={4}>
                    <div className={styles.partnerDetailsCard}>
                        <div className={styles.partnerCardWrapper}>
                            <img src={imageBasePath + "organization_workspace_icon.svg"} alt=""/>
                            <div className={styles.partnerCardContent}>
                                <p className="supportText">Total Workspaces</p>
                                <span className="subheading2"> 5</span>
                            </div>
                        </div>
                        <div className={styles.partnerCardArrow}>
                            <img src={imageBasePath + "arrow_right_gray_icon.svg"} alt=""/>
                        </div>
                    </div>
                </Grid>
                <Grid item md={4}>
                    <div className={styles.partnerDetailsCard}>
                        <div className={styles.partnerCardWrapper}>
                            <img src={imageBasePath + "org_room_icon.svg"} alt=""/>
                            <div className={styles.partnerCardContent}>
                                <p className="supportText">Total Rooms</p>
                                <span className="subheading2"> 35</span>
                            </div>
                        </div>
                        <div className={styles.partnerCardArrow}>
                            <img src={imageBasePath + "arrow_right_gray_icon.svg"} alt=""/>
                        </div>
                    </div>
                </Grid>
                <Grid item md={4}>
                    <div className={styles.partnerDetailsCard}>
                        <div className={styles.partnerCardWrapper}>
                            <img src={imageBasePath + "org_total_devices_icon.svg"} alt=""/>
                            <div className={styles.partnerCardContent}>
                                <p className="supportText">Total Devices</p>
                                <span className="subheading2"> 45</span>
                            </div>
                        </div>
                        <div className={styles.partnerCardArrow}>
                            <img src={imageBasePath + "arrow_right_gray_icon.svg"} alt=""/>
                        </div>
                    </div>
                </Grid>
                <Grid item md={4}>
                    <div className={styles.partnerDetailsCard}>
                        <div className={styles.partnerCardWrapper}>
                            <img src={imageBasePath + "organization_order_icon.svg"} alt=""/>
                            <div className={styles.partnerCardContent}>
                                <p className="supportText">Total Orders</p>
                                <span className="subheading2"> 35</span>
                            </div>
                        </div>
                        <div className={styles.partnerCardArrow}>
                            <img src={imageBasePath + "arrow_right_gray_icon.svg"} alt=""/>
                        </div>
                    </div>
                </Grid>
                <Grid item md={4}>
                    <div className={styles.partnerDetailsCard}>
                        <div className={styles.partnerCardWrapper}>
                            <img src={imageBasePath + "organization_total_users_icon.svg"} alt=""/>
                            <div className={styles.partnerCardContent}>
                                <p className="supportText">Total Users</p>
                                <span className="subheading2"> 45</span>
                            </div>
                        </div>
                        <div className={styles.partnerCardArrow}>
                            <img src={imageBasePath + "arrow_right_gray_icon.svg"} alt=""/>
                        </div>
                    </div>
                </Grid>
            </Grid>
        </div>
    )
}
