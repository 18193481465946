import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import { imageBasePath, reactSelectStylesLarge } from "../constants";
import { City, State } from "../utils/cityState";
import styles from "../../src/assets/styles/cart1.module.scss";
import { InputText, InputSelect, CustomLink, CustomButton } from "../shared_ui_components";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";

export default function AddressForm(props) {
  const { handleUpdateStep, step, handleChange, error } = props;

  const [selectedCity, setSelectedCity] = useState("");
  const [selectedState, setSelectedState] = useState("");

  const setSelectedUpdateCity = (cityName) => {
    setSelectedCity(cityName);
    let cityObj = City.find((opt) => opt.city === cityName);
    setSelectedState(cityObj.state);
    handleChange(step == 2 ? "shippingStateName" : "stateName", cityObj.state);
  };

  const getCityOptions = (cityList) => {
    if (cityList && Object.keys(cityList).length) {
      let options = [];
      Object.keys(cityList).forEach((key) => {
        if (selectedState === "") {
          options = [...options, ...[{ value: cityList[key].city, label: cityList[key].city }]];
        } else if (selectedState === cityList[key].state) {
          options = [...options, ...[{ value: cityList[key].city, label: cityList[key].city }]];
        }
      });
      return options;
    } else {
      return [];
    }
  };

  // useEffect(() => {
  //   setSelectedCity("");
  // }, [selectedState]);

  const getStateOptions = (stateList, cityList) => {
    if (stateList && Object.keys(stateList).length) {
      let options = [];
      Object.keys(stateList).forEach((key) => {
        options = [...options, ...[{ value: stateList[key], label: stateList[key] }]];
      });
      return options;
    } else {
      return [];
    }
  };

  const handleClick = () => {
    handleUpdateStep(step - 1);
  };

  return (
    <div>
      <form
        className={styles.ShippingForm}
        onSubmit={(e) => {
          e.preventDefault();
          handleUpdateStep(step + 1);
        }}
      >
        <InputText
          label="Receiver name *"
          placeholder="Full name"
          onChange={(e) => {
            handleChange(step == 2 ? "shippingName" : "name", e.target.value);
          }}
          error={step == 2 ? error.shippingName : error.name}
        />
        <div className="inputGroup phoneNumberGroup">
          <label
            className="label"
            style={{ color: error.shippingContact || error.contactNumber ? "red" : "" }}
          >
            Contact number *
          </label>
          <PhoneInput
            countryCodeEditable={false}
            disableCountryCode={true}
            placeholder="Enter phone number"
            defaultCountry="IN"
            //error={step == 2 ? error.shippingContact : error.contactNumber}
            onChange={(value) => {
              handleChange(step == 2 ? "shippingContact" : "contactNumber", value);
            }}
          />
          {error ? (
            <h6 className="errorMsg">{step == 2 ? error.shippingContact : error.contactNumber}</h6>
          ) : null}
        </div>
        <InputText
          label="Address *"
          placeholder="Street address"
          onChange={(e) => {
            handleChange(step == 2 ? "shippingAddress" : "address", e.target.value);
          }}
          error={step == 2 ? error.shippingAddress : error.address}
        />
        <Grid container spacing={4}>
          <Grid item md={6} xs={12}>
            <InputSelect
              closeMenuOnSelect={true}
              options={getStateOptions(State, City)}
              isSearchable={true}
              label={"State *"}
              value={getStateOptions(State, City).find((opt) => opt.value === selectedState)}
              error={step == 2 ? error.shippingStateName : error.stateName}
              placeholder={"Select State"}
              styles={reactSelectStylesLarge}
              noOptionsMessage={() => "No matches found"}
              onChange={(selectedOption) => {
                handleChange(
                  step == 2 ? "shippingStateName" : "stateName",
                  selectedOption ? selectedOption.value : []
                );
                setSelectedState(selectedOption ? selectedOption.value : "");
              }}
              // value={productInfo.portfolio}
              // isCreatable={true}
              // isClearable
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <InputSelect
              closeMenuOnSelect={true}
              options={getCityOptions(City)}
              isSearchable={true}
              label={"City *"}
              value={selectedCity ? getCityOptions(City).find((opt) => opt.value === selectedCity) : null}
              placeholder={"Select City"}
              styles={reactSelectStylesLarge}
              error={step == 2 ? error.shippingCityName : error.cityName}
              noOptionsMessage={() => "No matches found"}
              onChange={(selectedOption) => {
                handleChange(
                  step == 2 ? "shippingCityName" : "cityName",
                  selectedOption ? selectedOption.value : []
                );
                setSelectedCity("");
                setSelectedUpdateCity(selectedOption ? selectedOption.value : "");
              }}
              // value={productInfo.portfolio}
              // isCreatable={true}
              // isClearable
            />
          </Grid>
        </Grid>
        <InputText
          label="GST Number"
          placeholder="eg, 12345678"
          onChange={(e) => {
            handleChange("gstNumber", e.target.value);
          }}
        />
        <div className={styles.buttonSection}>
          <CustomButton
            className={styles.firstBtn + " primaryBtn"}
            // onClick={() => handleUpdateStep(step + 1)}
            children="Continue to Checkout"
            rightIcon={imageBasePath + "btn_arrow_right_icon.svg"}
            type="submit"
          />
          {/* <CustomButton
            className={styles.backBtn + " outlinedBtn "}
            children="back"
          /> */}
          <button className={styles.backBtn} onClick={handleClick}>
            back
          </button>
        </div>
      </form>
    </div>
  );
}

/* AddressForm.propTypes = {
    text: PropTypes.string,
    checked: PropTypes.bool,
    type:PropTypes.oneOf(['largeButton', 'mediumButton','']),
  };
  
  AddressForm.defaultProps = {
    text: "",
    checked: false,
    type:''
  }; */
