import React from "react";
import { Grid, List, ListItem } from "@material-ui/core";
import styles from "../../../assets/styles/orders.module.scss";
import { imageBasePath } from "../../../constants";
import { CustomButton } from "../../../shared_ui_components";
import moment from "moment";
import { toastFlashMessage } from "../../../utils";

export const OrderShippingDetail = ({ _id, units, expectedDeliveryDate, deliveredStatus, trackikngLink }) => {
  const handleOpenTackingLink = () => {
    if (trackikngLink.length > 0) {
      if (trackikngLink.includes("https://")) {
        return window.open(trackikngLink);
      } else if (trackikngLink.includes("http://")) {
        return window.open(trackikngLink);
      } else {
        return window.open("https://" + trackikngLink);
      }
    } else {
      toastFlashMessage("no tracking link provided for this shipment", "error");
    }
  };

  return (
    <div className={styles.shipmentUserSection}>
      <Grid container direction="row" spacing={2} justifyContent="center" alignItems="center">
        <Grid item md={1}>
          {/* <div className={styles.shipmentImageWrapper}>
            <img src={imageBasePath + "order_box.svg"} className={styles.shipmentBox} alt="" />
            <img
              src={
                deliveredStatus === "Pending"
                  ? imageBasePath + "order_status_pending.svg"
                  : deliveredStatus === "success"
                  ? imageBasePath + "order_status_paid.svg"
                  : imageBasePath + "order_status_process.svg"
              }
              className={styles.shipmentBoxStatus}
              alt=""
            />
          </div> */}
          <img
            style={{ position: "relative", left: "20px" }}
            src={
              deliveredStatus === "Pending"
                ? imageBasePath + "Order_Not_Shipped.svg"
                : deliveredStatus === "Success"
                ? imageBasePath + "Order_Delivered.svg"
                : imageBasePath + "Order_Shipped.svg"
            }
            className={styles.shipmentBox}
            alt=""
          />
        </Grid>
        <Grid item md={3}>
          <List>
            <ListItem className={styles.shipmentBlkHeader + " paragraph"}>Shipment No: </ListItem>
            <ListItem className={styles.shipmentBlkDescription + " paragraph"}>#{_id.slice(-4)}</ListItem>
          </List>
        </Grid>
        <Grid item md={1}>
          <List>
            <ListItem className={styles.shipmentBlkHeader + " paragraph"}>Units</ListItem>
            <ListItem className={styles.shipmentBlkDescription + " paragraph"}>{units ? units : 0}</ListItem>
          </List>
        </Grid>
        <Grid item md={3}>
          <List>
            <ListItem className={styles.shipmentBlkHeader + " paragraph"}>Expected Delivery Date</ListItem>
            <ListItem className={styles.shipmentBlkDescription + " paragraph"}>
              {moment(expectedDeliveryDate, "DD-MM-YYYY").format("Do MMM, YYYY")}
            </ListItem>
          </List>
        </Grid>
        <Grid item md={2}>
          <List>
            <ListItem className={styles.shipmentBlkHeader + " paragraph"}>Current Status</ListItem>
            <ListItem
              className={
                deliveredStatus === "Pending"
                  ? styles.shipmentBlkStatusPending + " paragraph"
                  : deliveredStatus === "Success"
                  ? styles.shipmentBlkStatusReceived + " paragraph"
                  : styles.shipmentBlkStatusShipped + " paragraph"
              }
            >
              {deliveredStatus === "Pending"
                ? "Not shipped"
                : deliveredStatus === "Success"
                ? "Received"
                : "Shipped"}
            </ListItem>
          </List>
        </Grid>
        <Grid item md={2}>
          <CustomButton
            children="Track Order"
            className={styles.trackButton + " outlinedBtn "}
            onClick={handleOpenTackingLink}
          />
        </Grid>
      </Grid>
    </div>
  );
};
