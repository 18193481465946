import React, { useEffect, useState } from "react";
import Drawer from "@material-ui/core/Drawer";
import styles from "../../../assets/styles/orders.module.scss";
import { imageBasePath } from "../../../constants";
import OrderListDetail from "./OrderListDetail";

export default function OrderListDrawer(props) {
  const { open, orderDetailList, emptyOrderDetails, handleClose, getAllAdminOrdersList, orderId } = props;
  const [state, setState] = useState({
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }
    handleClose(open);
    setState({ ...state, [anchor]: open });
    emptyOrderDetails();
  };
  useEffect(() => {
    setState({ ...state, ["right"]: open });
  }, [open]);

  return (
    <div>
      {["right"].map((anchor) => (
        <div key={anchor}>
          {/* <Button onClick={toggleDrawer(anchor, true)}>{anchor}</Button> */}
          <Drawer anchor={anchor} open={state[anchor]} onClose={toggleDrawer(anchor, false)}>
            <div className={styles.orderDrawerDetailWrapper}>
              <div className={styles.orderDetailTitle}>
                <p className={styles.orderDetailHeader + " subheading"}>Order Details</p>
                <img src={imageBasePath + "user_cancel.svg"} alt="" onClick={toggleDrawer(anchor, false)} />
              </div>
              <OrderListDetail
                orderDetailList={orderDetailList}
                handleClose={handleClose}
                getAllAdminOrdersList={getAllAdminOrdersList}
                orderId={orderId}
              />
            </div>
          </Drawer>
        </div>
      ))}
    </div>
  );
}
