import React, { useState, useEffect, useContext } from "react";
import { Grid, Divider } from "@material-ui/core";
import styles from "../../../assets/styles/addOrganisation.module.scss";
import { imageBasePath, errorCodes, reactSelectStylesLarge } from "../../../constants";
import { CustomButton, InputText, InputSelect } from "../../../shared_ui_components";
import { fieldValidation } from "../../../utils/formValidation";
import { City, State } from "../../../utils/cityState";
import { createOrgApi, updateOrgApi } from "../apiServices";
import { toastFlashMessage } from "../../../utils";
import { getStore } from "../../../store/GlobalStore";
import { GlobalContext } from "../../../context/GlobalContext";
import { useParams } from "react-router-dom";

export default function AddOrganisationForm(props) {
  const { id } = useParams();
  const {
    state: { orgListNames },
  } = useContext(GlobalContext);

  const [formData, setFormData] = useState({});
  const [error, setError] = useState({});
  const [cityOption, setCityOption] = useState([]);
  const [stateOption, setStateOption] = useState([]);
  const [validateLoader, setValidateLoader] = useState(false);

  useEffect(() => {
    const editOrg = orgListNames.find((org) => org._id === id);
    setCityList(editOrg?.orgAddress?.stateName);
    setFormData((form) => ({
      ...form,
      orgName: editOrg?.orgName,
      address: editOrg?.orgAddress?.address,
      stateName: editOrg?.orgAddress?.stateName,
      cityName: editOrg?.orgAddress?.cityName,
      pincode: editOrg?.orgAddress?.pincode,
      panNumber: editOrg?.panNumber,
      gstNumber: editOrg?.gstNumber,
      tinNumber: editOrg?.tinNumber,
      fullName: editOrg?.userData?.fullName,
      email: editOrg?.userData?.email,
      addressId: editOrg?.orgAddress?._id,
      orgId: id,
    }));
  }, [orgListNames, id]);

  useEffect(() => {
    let states = [];
    State.forEach((state) => states.push({ label: state, value: state }));
    setStateOption(states);
  }, []);

  const handleChange = (key, value) => {
    if (key === "stateName") {
      setFormData((formData) => ({
        ...formData,
        [key]: value,
        cityName: null,
      }));
      setError((preError) => ({
        ...preError,
        [key]: "",
      }));
      setCityList(value);
    } else {
      setFormData((formData) => ({
        ...formData,
        [key]: value,
      }));
      setError((preError) => ({
        ...preError,
        [key]: "",
      }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let validateNewInput = {};
    validateNewInput = {
      orgName:
        errorCodes["orgName"][fieldValidation({ ...errorCodes.orgNameObj, fieldval: formData.orgName })],
      address:
        errorCodes["address"][fieldValidation({ ...errorCodes.addressObj, fieldval: formData.address })],
      stateName:
        errorCodes["stateName"][
          fieldValidation({ ...errorCodes.stateNameObj, fieldval: formData.stateName })
        ],
      cityName:
        errorCodes["cityName"][fieldValidation({ ...errorCodes.cityNameObj, fieldval: formData.cityName })],
      pincode:
        errorCodes["pincode"][fieldValidation({ ...errorCodes.pincodeObj, fieldval: formData.pincode })],
      // panNumber:
      //   errorCodes["panNumber"][
      //     fieldValidation({ ...errorCodes.panNumberObj, fieldval: formData.panNumber })
      //   ],
      // gstNumber:
      //   errorCodes["gstNumber"][
      //     fieldValidation({ ...errorCodes.gstNumberObj, fieldval: formData.gstNumber })
      //   ],
      // tinNumber:
      //   errorCodes["tinNumber"][
      //     fieldValidation({ ...errorCodes.tinNumberObj, fieldval: formData.tinNumber })
      //   ],
      fullName:
        errorCodes["fullName"][fieldValidation({ ...errorCodes.fullNameObj, fieldval: formData.fullName })],
      email:
        errorCodes["email"][fieldValidation({ ...errorCodes.emailObj, fieldval: formData?.email?.trim() })],
    };
    if (Object.keys(validateNewInput).every((k) => validateNewInput[k] == "")) {
      setValidateLoader(true);

      if (id) {
        updateOrgApi(formData).then((response) => {
          if (response.status && Object.keys(response.orgDetails).length > 0) {
            const { dispatch } = getStore();
            dispatch({
              type: "UPDATE_ORGANISATION_NAME",
              payload: response.orgDetails,
            });
            props.handleChange({ orgName: formData.orgName, orgId: response.orgDetails._id, edit: "edit" });
            toastFlashMessage("Organisation updated successfully", "success");
            setValidateLoader(false);
          } else {
            toastFlashMessage("Something went wrong while updating organisation", "error");
            setValidateLoader(false);
          }
        });
      } else {
        createOrgApi(formData).then((response) => {
          if (response.status) {
            const { dispatch } = getStore();
            dispatch({
              type: "ADD_ORGANISATION_NAME",
              payload: response.organisation,
            });
            props.handleChange({ orgName: formData.orgName, orgId: response.organisation._id });
            toastFlashMessage("New organisation added successfully", "success");
            setValidateLoader(false);
          } else {
            toastFlashMessage("Something went wrong while adding new organisation", "error");
            setValidateLoader(false);
          }
        });
      }
    } else {
      setError(validateNewInput);
    }
  };

  const setCityList = (state) => {
    const updatedCityList = City.filter((key) => key.state === state).map((city) => {
      return { label: city.city, value: city.city };
    });
    setCityOption(updatedCityList);
  };

  return (
    <div className={styles.addOrganisationFormWrapper}>
      <p className={styles.addOrganisationFormHeader + " heading6"}>Please provide us organisation details</p>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2} direction="row" justifyContent="center" alignItems="flex-start">
          <Grid item md={6}>
            <div className={styles.addOrgFormLeftBlk}>
              <p className={styles.addOrgFormInnerHeader + " paragraph"}>Organisation Details</p>
              <Grid container spacing={2} direction="row" alignItems="center">
                <Grid item md={12}>
                  <InputText
                    label="Name of the Organisation *"
                    placeholder="eg,  Welpro Pvt. Ltd."
                    value={formData.orgName || ""}
                    onChange={(e) => handleChange("orgName", e.target.value)}
                    error={error.orgName}
                  />
                </Grid>
                <Grid item md={12}>
                  <InputText
                    label="Registered Address *"
                    placeholder="eg,  7 Liberty Road Holbrook, NY 11741"
                    value={formData.address || ""}
                    onChange={(e) => handleChange("address", e.target.value)}
                    error={error.address}
                  />
                </Grid>
                <Grid container spacing={4}>
                  <Grid item md={6} xs={12}>
                    <InputSelect
                      label={"State *"}
                      placeholder={"Select State"}
                      styles={reactSelectStylesLarge}
                      options={stateOption}
                      isSearchable={true}
                      error={error.stateName}
                      value={
                        formData.stateName
                          ? stateOption.find((opt) => opt.value === formData.stateName)
                          : null
                      }
                      onChange={(state) => handleChange("stateName", state && state.value)}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <InputSelect
                      label={"City *"}
                      placeholder={"Select City"}
                      styles={reactSelectStylesLarge}
                      isSearchable={true}
                      options={cityOption}
                      error={error.cityName}
                      value={
                        formData.cityName ? cityOption.find((city) => city.value === formData.cityName) : null
                      }
                      onChange={(city) => handleChange("cityName", city && city.value)}
                    />
                  </Grid>
                </Grid>
                <Grid item md={12}>
                  <InputText
                    label="Pincode *"
                    placeholder="eg,  560050"
                    value={formData.pincode || ""}
                    onChange={(e) => handleChange("pincode", e.target.value)}
                    error={error.pincode}
                    type="number"
                  />
                </Grid>
                <Grid item md={12}>
                  <InputText
                    label="PAN number"
                    placeholder="eg,  PAN112345678"
                    value={formData.panNumber || ""}
                    onChange={(e) => handleChange("panNumber", e.target.value)}
                    //error={error.panNumber}
                  />
                </Grid>
                <Grid item md={12}>
                  <InputText
                    label="GST number"
                    placeholder="eg, 12345678"
                    value={formData.gstNumber || ""}
                    onChange={(e) => handleChange("gstNumber", e.target.value)}
                    //error={error.gstNumber}
                  />
                </Grid>
                <Grid item md={12}>
                  <InputText
                    label="TIN number"
                    placeholder="eg, 12345678"
                    value={formData.tinNumber || ""}
                    onChange={(e) => handleChange("tinNumber", e.target.value)}
                    //error={error.tinNumber}
                  />
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Divider orientation="vertical" flexItem />
          <Grid item>
            <div className={styles.addOrgFormRightBlk}>
              <p className={styles.addOrgFormInnerHeader + " paragraph"}>Organisation Admin Details</p>
              <Grid container spacing={2} direction="row" alignItems="center">
                <Grid item md={12}>
                  <InputText
                    label="Full Name *"
                    placeholder="eg, John Doe"
                    value={formData.fullName || ""}
                    onChange={(e) => handleChange("fullName", e.target.value)}
                    error={error.fullName}
                    disabled={id ? true : false}
                  />
                </Grid>
                <Grid item md={12}>
                  <InputText
                    label="Email address *"
                    placeholder="eg,  johndoe@welpro.com"
                    value={formData?.email?.trim() || ""}
                    onChange={(e) => handleChange("email", e.target.value)}
                    error={error.email}
                    disabled={id ? true : false}
                  />
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
        <div className={styles.addOrgFormSubmitButtonWrapper}>
          <CustomButton
            className={`${styles.submitButton} primaryBtn ${validateLoader ? "disabledBtn" : ""}`}
            children={validateLoader ? "Submiting..." : "Submit"}
            rightIcon={imageBasePath + "btn_arrow_right_icon.svg"}
            type="submit"
          />
        </div>
      </form>
    </div>
  );
}
