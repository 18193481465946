import React, { Component, useState, useContext } from "react";

import { Container, Button, Grid, List, ListItem } from "@material-ui/core";
import styles from "../../../assets/styles/purchase.module.scss";
import { imageBasePath } from "../../../constants";
import { fetchDevices } from "../apiServices";
import { useHistory } from "react-router-dom";
import { getStore } from "../../../store/GlobalStore";
import { GlobalContext } from "../../../context/GlobalContext";
import { currencyFormatter } from "../../../utils";
import { CustomButton, CustomLink } from "../../../shared_ui_components";

function PlaceOrder(props) {
  const {
    state: { device },
  } = useContext(GlobalContext);
  const history = useHistory();
  const redirectToCart = () => {
    history.push("/cart");
  };

  const registerPartner = () => {
    history.push("/registration");
    //window.open('https://forms.gle/v3i9E3Tzpzp1byzJA', '_blank');
  };

  return (
    <section className={styles.placeOrder}>
      <Container maxWidth="lg">
        <div>
          <Grid container direction="row" spacing={2}>
            <Grid item md={6}>
              <img src="./images/set-top-box.png" alt="Profile" />
            </Grid>
            <Grid item md={6}>
              <div className={styles.pricingSection}>
                <p className="supportText">Starting at</p>
                <p className={styles.PricingTag + " paragraph"}>
                  <span className={styles.pricingLabel}>
                    {/* {device?.pricing ? `₹${currencyFormatter(device?.pricing[0].price)}`: "7,000"}  */}
                    ₹4,000
                  </span>{" "}
                  / month <span className={styles.PricingSubText}>(billed annually)</span>{" "}
                </p>
                <p className={styles.PricingOneTime + " paragraph"}>+ one-time setup fee</p>
                <Grid container direction="row" alignItems="center" spacing={2}>
                  <Grid item md={4}>
                    <button className={styles.pricingBtn + " primaryBtn"} onClick={() => redirectToCart()}>
                      Place order
                    </button>
                  </Grid>
                  <Grid item md={8}>
                    <p className={styles.textLink}>
                      Got a bulk order?{" "}
                      <a className="subheading2" href="https://forms.gle/NG1T7ept8LvxHh5W8" target="_blank">
                        Enquire now{" "}
                      </a>
                      <span>
                        <img src={imageBasePath + "arrow-right-landingPage.svg"} alt="right-arrow" />
                      </span>
                    </p>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>
        </div>
        <div className={styles.partnerWrapper}>
          <Grid container direction="row" spacing={2} alignItems="center">
            <Grid item md={8}>
              <h2 className={styles.partnerHeading + " heading6"}>Register as a partner</h2>
              <p className={styles.partnerSubHeading + " paragraph"}>
                And get customized pricing, exclusive benefits and many more perks.{" "}
              </p>
            </Grid>
            <Grid item md={4}>
              <div className={styles.buttonWrapper}>
                <CustomButton
                  className={styles.partnerButton + " outlinedBtn"}
                  children="Register as partner"
                  onClick={() => registerPartner()}
                />
              </div>
            </Grid>
          </Grid>
          {/* <p className={styles.partnerBanner+" label"}>Coming Soon!</p> */}
        </div>
      </Container>
    </section>
  );
}

export default PlaceOrder;
