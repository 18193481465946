import DirectOrder from "./container/DirectOrder";
import QuotationLayoutHoc from "../../../hoc/quotationLayoutHoc";
import DirectOrderDetails from "./container/DirectOrderDetails";
export const directOrderRoutes = [
  {
    path: "/direct-order",
    component: QuotationLayoutHoc(DirectOrder),
    key: "direct-order",
  },
  {
    path: "/direct-order-details",
    component: QuotationLayoutHoc(DirectOrderDetails),
    key: "direct-order-details",
  },
];
