import {
  globalPostService,
  globalGetService,
  globalDeleteService,
} from "../../utils/globalApiServices";


export function fetchDevices(query = {}) {
  return new Promise((resolve, reject) => {
    globalGetService(`get-devices-type-v1`, query)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function saveOrderDetails(query = {}) {
  return new Promise((resolve, reject) => {
    globalPostService(`save-order-details-v1`, query)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function updateTransactionID(query = {}) {
  return new Promise((resolve, reject) => {
    globalPostService(`update-transaction-details-v1`, query)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function checkCuponCode(query = {}) {
  return new Promise((resolve, reject) => {
    globalPostService(`validate-coupon-v1`, query)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}