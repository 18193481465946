import React from "react";
import { useHistory } from "react-router-dom";
import { Grid } from "@material-ui/core";
import { imageBasePath } from "../../../constants";
import styles from "../../../assets/styles/addOrganisation.module.scss";
import { CustomButton, CustomLink } from "../../../shared_ui_components";

export default function AddOrganisationSuccess(props) {
  const history = useHistory();

  const goToNewOrganisation = () => {
    history.push(`/${props?.organisationData?.orgId}/overview`);
  };

  const handleGoBack = () => {
    props.setIsAddOrganisationSuccess(false);
  };

  return (
    <div className={styles.addOrgStatusBlk}>
      <Grid container direction="row" spacing={2} justifyContent="center" alignItems="center">
        <Grid item md={4}>
          <div className={styles.addOrgStatus}>
            <div className={styles.statusImage}>
              <img src={imageBasePath + "add_org_success.png"} alt="add_org_confirm" />
            </div>
            <div className={styles.statusBody}>
              {props?.organisationData?.edit ? (
                <>
                  <h2 className={styles.statusTitle + " heading6 "}> organisation updated</h2>
                  <p className={styles.statusSubtext + " paragraph "}>
                    You have successfully updated <span>{props?.organisationData?.orgName}</span> <br />
                    organisation.
                  </p>
                </>
              ) : (
                <>
                  <h2 className={styles.statusTitle + " heading6 "}>New organisation added</h2>
                  <p className={styles.statusSubtext + " paragraph "}>
                    You have successfully added <span>{props?.organisationData?.orgName}</span> <br /> as a
                    new organisation.
                  </p>
                </>
              )}
              <div className={styles.statusButtonWrapper}>
                <CustomButton
                  onClick={() => goToNewOrganisation()}
                  className={styles.actionBtn + " primaryBtn "}
                  children="Switch to new organisation "
                />
                <CustomLink
                  className={styles.actionBtn + " primaryBtn "}
                  text="Switch to req quote"
                  to={{
                    pathname: "/request-quote",
                    state: {
                      orgId: props?.organisationData?.orgId,
                      orgName: props?.organisationData?.orgName,
                    },
                  }}
                />
                <CustomButton onClick={handleGoBack} className=" linkBtn " children="No, go back" />
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
