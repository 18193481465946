import { Grid } from "@material-ui/core";
import React from "react";
import styles from "../../../../assets/styles/admin/directOrder.module.scss";
import { imageBasePath } from "../../../../constants";
function DirectOrderDetailsCard(props) {
  return (
    <div className={styles.directOrderDetailsCardBlk}>
      <Grid container spacing={2}>
        <Grid item md={4}>
          <div className={styles.directOrderDetailsCard}>
            <div className={styles.directOrderCardWrapper}>
              <img src={imageBasePath + "organization_logo.svg"} />
              <div className={styles.directOrderCardContent}>
                <p className="supportText">Total Organizations</p>
                <span className="subheading2"> 5</span>
              </div>
            </div>
            <div className={styles.directOrderCardArrow}>
              <img src={imageBasePath + "arrow_right_gray_icon.svg"} />
            </div>
          </div>
        </Grid>
        <Grid item md={4}>
          <div className={styles.directOrderDetailsCard}>
            <div className={styles.directOrderCardWrapper}>
              <img src={imageBasePath + "organization_members_icon.svg"} />
              <div className={styles.directOrderCardContent}>
                <p className="supportText">Total Team Members</p>
                <span className="subheading2"> 5</span>
              </div>
            </div>
            <div className={styles.directOrderCardArrow}>
              <img src={imageBasePath + "arrow_right_gray_icon.svg"} />
            </div>
          </div>
        </Grid>
        <Grid item md={4}>
          <div className={styles.directOrderDetailsCard}>
            <div className={styles.directOrderCardWrapper}>
              <img src={imageBasePath + "organization_workspace_icon.svg"} />
              <div className={styles.directOrderCardContent}>
                <p className="supportText">Total Workspaces</p>
                <span className="subheading2"> 5</span>
              </div>
            </div>
            <div className={styles.directOrderCardArrow}>
              <img src={imageBasePath + "arrow_right_gray_icon.svg"} />
            </div>
          </div>
        </Grid>
        <Grid item md={4}>
          <div className={styles.directOrderDetailsCard}>
            <div className={styles.directOrderCardWrapper}>
              <img src={imageBasePath + "org_room_icon.svg"} />
              <div className={styles.directOrderCardContent}>
                <p className="supportText">Total Rooms</p>
                <span className="subheading2"> 35</span>
              </div>
            </div>
            <div className={styles.directOrderCardArrow}>
              <img src={imageBasePath + "arrow_right_gray_icon.svg"} />
            </div>
          </div>
        </Grid>
        <Grid item md={4}>
          <div className={styles.directOrderDetailsCard}>
            <div className={styles.directOrderCardWrapper}>
              <img src={imageBasePath + "org_total_devices_icon.svg"} />
              <div className={styles.directOrderCardContent}>
                <p className="supportText">Total Devices</p>
                <span className="subheading2"> 45</span>
              </div>
            </div>
            <div className={styles.directOrderCardArrow}>
              <img src={imageBasePath + "arrow_right_gray_icon.svg"} />
            </div>
          </div>
        </Grid>
        <Grid item md={4}>
          <div className={styles.directOrderDetailsCard}>
            <div className={styles.directOrderCardWrapper}>
              <img src={imageBasePath + "organization_order_icon.svg"} />
              <div className={styles.directOrderCardContent}>
                <p className="supportText">Total Orders</p>
                <span className="subheading2"> 35</span>
              </div>
            </div>
            <div className={styles.directOrderCardArrow}>
              <img src={imageBasePath + "arrow_right_gray_icon.svg"} />
            </div>
          </div>
        </Grid>
        <Grid item md={4}>
          <div className={styles.directOrderDetailsCard}>
            <div className={styles.directOrderCardWrapper}>
              <img src={imageBasePath + "organization_total_users_icon.svg"} />
              <div className={styles.directOrderCardContent}>
                <p className="supportText">Total Users</p>
                <span className="subheading2"> 45</span>
              </div>
            </div>
            <div className={styles.directOrderCardArrow}>
              <img src={imageBasePath + "arrow_right_gray_icon.svg"} />
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default DirectOrderDetailsCard;
