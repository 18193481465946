import React from "react";
import styles from "../../../assets/styles/devices.module.scss";
import { ResourceDetails } from "../components";

export default function ResourceInfo(props) {
  return (
    <div className={styles.deviceInfoPage}>
      <div className={styles.deviceInfoWrapper}>
        <ResourceDetails />
      </div>
    </div>
  );
}
