import React from "react";
import { Grid } from "@material-ui/core";
import { imageBasePath } from "../../../constants";
import { Skeleton } from "@material-ui/lab";

export default function Shimmer(props) {
  return (
    <>
      {props.type === "deviceCount" && (
        <div className="devicesCard" style={{ margin: "24px" }}>
          <h3 className="devicesTitle subheading2 ">Devices</h3>
          <div className="cardBody">
            <Grid container>
              <Grid item md={7}>
                <Grid container>
                  <Grid item md={6}>
                    <div className="activatedDevicesWrapper">
                      <div className="deviceImgWrapper">
                        <Skeleton
                          variant="circle"
                          width={75}
                          height={75}
                          style={{
                            objectFit: "cover",
                            position: "absolute",
                            top: "12px",
                            right: "-90px",
                          }}
                        />
                      </div>

                      <div className="activatedDeviceTextWrapper">
                        <div className="activatedDevicesText paragraph">
                          <Skeleton
                            variant="text"
                            width={200}
                            height={20}
                            style={{
                              display: "block",
                              transform: "none",
                            }}
                          />
                        </div>
                        <div className="activatedDevicesCount subheading">
                          <Skeleton
                            variant="text"
                            width={100}
                            height={20}
                            style={{
                              display: "block",
                              transform: "none",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={5}>
                <div className="deviceStatusRightBlk">
                  <div className="deviceStatusBlk">
                    <div className="deviceImgWrapper">
                      <Skeleton
                        variant="circle"
                        width={75}
                        height={75}
                        style={{
                          objectFit: "cover",
                          position: "absolute",
                          top: "-16px",
                          right: "-50px",
                        }}
                      />
                    </div>
                    <div className="textBlkWrapper">
                      <div className="notActivatedDevicesText paragraph">
                        <Skeleton
                          variant="text"
                          width={200}
                          height={20}
                          style={{
                            display: "block",
                            transform: "none",
                          }}
                        />
                      </div>
                      <div className="notActivatedDevicesCount subheading">
                        <Skeleton
                          variant="text"
                          width={100}
                          height={20}
                          style={{
                            display: "block",
                            transform: "none",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="deviceStatusBlk">
                    <div className="deviceImgWrapper">
                      <Skeleton
                        variant="circle"
                        width={75}
                        height={75}
                        style={{
                          objectFit: "cover",
                          position: "absolute",
                          top: "-16px",
                          right: "-50px",
                        }}
                      />
                    </div>
                    <div className="textBlkWrapper">
                      <p className="notActivatedDevicesText paragraph">
                        <Skeleton
                          variant="text"
                          width={200}
                          height={20}
                          style={{
                            display: "block",
                            transform: "none",
                          }}
                        />
                      </p>
                      <p className="notActivatedDevicesCount subheading">
                        <Skeleton
                          variant="text"
                          width={100}
                          height={20}
                          style={{
                            display: "block",
                            transform: "none",
                          }}
                        />
                      </p>
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      )}
    </>
  );
}
