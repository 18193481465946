import React, { useState, useEffect, useContext } from "react";
import { Grid, Container } from "@material-ui/core";
import styles from "../../../assets/styles/quotationDetail.module.scss";
import { imageBasePath, errorCodes, reactSelectStylesLarge } from "../../../constants";
import { useHistory, useLocation } from "react-router-dom";
import { CustomLink, SquareRadioButton, CustomButton, InputSelect } from "../../../shared_ui_components";
import { GlobalContext } from "../../../context/GlobalContext";
import { fieldValidation } from "../../../utils/formValidation";
import { toastFlashMessage, getUserInfo } from "../../../utils";
import { createQuotationApi } from "../apiServices";

export default function QuotationDetail(props) {
  const history = useHistory();
  const location = useLocation();
  const userInfo = getUserInfo();

  const {
    state: { device, orgListNames },
  } = useContext(GlobalContext);
  const [formData, setFormData] = useState({
    subscriptionPeriod: 12,
    quantity: 1,
    orgId: location?.state?.orgId ? location?.state?.orgId : "",
  });
  const [error, setError] = useState({});
  const [organisations, setOrganisations] = useState([]);
  const [validateLoader, setValidateLoader] = useState(false);
  const [errCodes, setErrCodes] = useState({
    ...errorCodes,
    orgId: {
      0: "",
      1: "Please select Organisation",
    },
    orgIdObj: { requiredFlag: true },
    quantity: {
      0: "",
      1: "Please enter Quantity",
    },
    quantityObj: { requiredFlag: true },
  });
  const [selectedOrgName, setSelectedOrgName] = useState(
    location?.state?.orgName ? location?.state?.orgName : ""
  );

  useEffect(() => {
    const data = orgListNames?.map((name) => {
      return { label: name.orgName, value: name._id };
    });
    setOrganisations(data);
  }, [orgListNames]);

  useEffect(() => {
    if (device?.pricing?.length) {
      let SubscriptionPeriod = device.pricing.map(device.pricing.pop, [...device.pricing]);
      handleChange("devicePriceId", SubscriptionPeriod[0]._id);
    } else {
    }
  }, [device]);

  const handleChange = (key, value, orgName) => {
    orgName && setSelectedOrgName(orgName);
    setFormData((formData) => ({
      ...formData,
      [key]: value,
      subscriptionPeriod:
        key === "devicePriceId"
          ? device?.pricing?.find((price) => price._id === value)?.subscriptionPeriod
          : formData.subscriptionPeriod,
    }));
    setError((preError) => ({
      ...preError,
      [key]: "",
    }));
  };

  const handleSubmit = () => {
    let validateNewInput = {};
    validateNewInput = {
      orgId: errCodes["orgId"][fieldValidation({ ...errCodes.orgIdObj, fieldval: formData.orgId })],
      quantity:
        errCodes["quantity"][fieldValidation({ ...errCodes.quantityObj, fieldval: formData.quantity })],
    };
    console.log(validateNewInput, "newInput");
    if (Object.keys(validateNewInput).every((k) => validateNewInput[k] == "")) {
      setValidateLoader(true);
      createQuotationApi(formData).then((response) => {
        if (response.status) {
          props.handleChange(selectedOrgName);
          toastFlashMessage("Quotation requested successfully", "success");
          setValidateLoader(false);
        } else {
          toastFlashMessage("Something went wrong while requesting quotation", "error");
          setValidateLoader(false);
        }
      });
    } else {
      setError(validateNewInput);
    }
  };

  const goToAddOrganisation = () => {
    history.push("/add-organisation");
  };

  const handleGoBack = () => {
    history.goBack();
  };

  return (
    <div className={styles.quotationDetailWrapper}>
      <Container maxWidth="lg">
        <div className={styles.backBtnWrapper}>
          <CustomLink
            onClick={handleGoBack}
            className={" customLink" + " paragraph"}
            text="Back"
            leftIcon={imageBasePath + "arrow_left_icon.svg"}
          />
        </div>
        <div className={styles.orderDetailWrapper}>
          <Grid container justifyContent="center" direction="row" alignItems="center" spacing={2}>
            <Grid item md={4}>
              <img className={styles.vingBox} src={imageBasePath + "vingbox.png"} alt="vingbox" />
            </Grid>
            <Grid item md={8}>
              <p className={styles.orderTitle + " heading6"}>Please provide us your order details</p>
              <p className={styles.subTitle + " paragraph"}>Quantity</p>
              <div className={styles.quantitySection}>
                <button
                  className={styles.decrementButton}
                  onClick={() => {
                    formData.quantity > 1
                      ? handleChange("quantity", Number(formData.quantity - 1))
                      : handleChange("quantity", 1);
                  }}
                >
                  <img src={imageBasePath + "minus-line.svg"} alt="minus" />
                </button>
                <input
                  className="paragraph"
                  type="number"
                  name="clicks"
                  value={formData.quantity}
                  onChange={(e) => {
                    handleChange("quantity", parseInt(e.target.value));
                  }}
                  onWheel={(e) => e.target.blur()}
                />
                <button
                  className={styles.incrementButton}
                  onClick={() => {
                    handleChange("quantity", Number(formData.quantity + 1));
                  }}
                >
                  <img src={imageBasePath + "add-line.svg"} alt="plus" />
                </button>
              </div>
              {error.quantity ? <h6 className="errorMsg">{error.quantity}</h6> : null}
              <div>
                <p className={styles.subTitle + " paragraph"}>Subscription period</p>
                <div className={styles.subscriptionPeriodBlk}>
                  <ul className={styles.subNumber + " listInline"}>
                    {device?.pricing?.map(device.pricing.pop, [...device.pricing]).map((period) => {
                      return (
                        <React.Fragment key={period._id}>
                          <li className={`listInlineItem ${styles.subButton}`} key={period._id}>
                            {period.discount && (
                              <span className={styles.discountBanner}>{period.discount}% OFF</span>
                            )}
                            <SquareRadioButton
                              text={`${period.subscriptionPeriod / 12} ${
                                period.subscriptionPeriod / 12 > 1 ? "Years" : "Year"
                              }`}
                              type="largeButton"
                              value={formData.devicePriceId}
                              type="largeButton"
                              onClick={() => handleChange("devicePriceId", period._id)}
                              checked={period._id === formData.devicePriceId ? true : false}
                            />
                          </li>
                        </React.Fragment>
                      );
                    })}
                  </ul>
                </div>
              </div>
              <div>
                <p className={styles.subTitle + " paragraph"}>Organisation</p>
                <div className={styles.organisationSection}>
                  <div className={styles.selectDropdown}>
                    <InputSelect
                      closeMenuOnSelect={true}
                      isSearchable={true}
                      label=""
                      placeholder="Select an organisation"
                      noOptionsMessage={() => "No matches found"}
                      styles={reactSelectStylesLarge}
                      options={organisations}
                      onChange={(orgId) => handleChange("orgId", orgId.value, orgId.label)}
                      value={
                        formData.orgId ? organisations.find((opt) => opt.value === formData.orgId) : null
                      }
                      error={error.orgId}
                    />
                  </div>
                  {userInfo.userData?.role?.responsibilities?.createOrg ? (
                    <>
                      <p>or</p>
                      <CustomButton
                        children="+ Add New Organisation"
                        className={styles.addOrganisation + " outlinedBtn"}
                        onClick={() => goToAddOrganisation()}
                      />
                    </>
                  ) : null}
                </div>
              </div>
              <div>
                <CustomLink
                  className={`${styles.requestButton} primaryBtn ${validateLoader ? "disabledBtn" : ""}`}
                  text={validateLoader ? "Requesting for a quote..." : "Request for a quote"}
                  rightIcon={imageBasePath + "btn_arrow_right_icon.svg"}
                  onClick={() => handleSubmit()}
                />
              </div>
            </Grid>
          </Grid>
        </div>
      </Container>
    </div>
  );
}
