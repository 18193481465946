import React, { useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Radio, FormControlLabel } from "@material-ui/core";

export default function CustomRadioButton(props) {
  const { value, handleChange } = props;

  return (
    <div>
      <FormControlLabel
        className="supportText radioButton"
        checked={value}
        control={<Radio />}
        label={props.label}
        onChange={(e) => handleChange(e.target.value)}
        {...props}
        // checked
      />
    </div>
  );
}
