import React from "react";
import styles from "../../../assets/styles/devices.module.scss";
import { DevicesList } from "../components";

export default function Devices(props) {
  return (
    <div className={styles.devicesPage}>
      <div className={styles.devicesWrapper}>
        <DevicesList />
      </div>
    </div>
  );
}
