import React from "react";
import styles from "../../../assets/styles/orders.module.scss";
import OrderList from "../components/OrderList";
import { useLocation } from "react-router-dom";

export default function Orders(props) {
  let location = useLocation();
  return (
    <div className={`${styles.ordersPage} 
    ${location.pathname === "/orders" 
    ? styles.ordersHomePage : ""}`}
    >
      <OrderList />
    </div>
  );
}
