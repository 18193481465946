import React, { useState } from 'react';
import styles from "../../../../assets/styles/admin/enquiries.module.scss";
import { Grid } from "@material-ui/core";
import { imageBasePath } from "../../../../constants";
import { Link } from "react-router-dom";
import EnquiriesListDrawer from "./EnquiriesListDrawer";

export default function EnquiriesList(props) {
    const [orderDetail, setOrderDetail] = useState(false);

    return (
        <div className={styles.enquiriesListWrapper}>
            <Grid container >
                <Grid item md={12}>
                    <div className={styles.enquiriesList}>
                        <div className={styles.enquiriesListInnerBlk}>
                            <div className={styles.enquiriesListTableTitle}>
                                <Grid container spacing={2}>
                                    <Grid item md={2}>
                                        <div className={styles.enquiriesTableTitleTextWrapper}>
                                            <p
                                                className={
                                                    styles.enquiriesTableTitleText + " paragraph "
                                                }
                                            >
                                                Enquiry Number
                                            </p>
                                            <img src={imageBasePath + "sort_icon.svg"} alt="" />
                                        </div>
                                    </Grid>
                                    <Grid item md={2}>
                                        <div className={styles.enquiriesTableTitleTextWrapper}>
                                            <p
                                                className={
                                                    styles.enquiriesTableTitleText + " paragraph "
                                                }
                                            >
                                                Recieved on
                                            </p>
                                            <img src={imageBasePath + "sort_icon.svg"} alt="" />
                                        </div>
                                    </Grid>
                                    <Grid item md={3}>
                                        <div className={styles.enquiriesTableTitleTextWrapper}>
                                            <p
                                                className={
                                                    styles.enquiriesTableTitleText + " paragraph "
                                                }
                                            >
                                                Email ID
                                            </p>
                                            <img src={imageBasePath + "sort_icon.svg"} alt="" />
                                        </div>
                                    </Grid>
                                    <Grid item md={3}>
                                        <div className={styles.enquiriesTableTitleTextWrapper}>
                                            <p
                                                className={
                                                    styles.enquiriesTableTitleText + " paragraph "
                                                }
                                            >
                                                Contact Number
                                            </p>
                                        </div>
                                    </Grid>
                                    <Grid item md={2}>
                                        <div className={styles.enquiriesTableTitleTextWrapper}>
                                            <p
                                                className={
                                                    styles.enquiriesTableTitleText + " paragraph "
                                                }
                                            >
                                                Status
                                            </p>
                                            <img src={imageBasePath + "sort_icon.svg"} alt="" />
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                            <div className={styles.enquiriesListTableContent}>
                                {[0, 1, 2, 3, 4, 5, 6].map((item, key) => (
                                    <>
                                        <Link
                                            onClick={() => setOrderDetail(true)}
                                            className={styles.enquiryListItem}
                                        >
                                            <div className={styles.enquiryListTableRow}>
                                                <Grid container spacing={2} direction="row" justifyContent="center" alignItems="center">
                                                    <Grid item md={2}>
                                                        <div className={styles.enquiryContentWrapper}>
                                                            <div className={styles.enquiryImageWrapper}>
                                                                <img
                                                                    src={imageBasePath + "enquiry-box.svg"}
                                                                    className={styles.enquiryBox}
                                                                    alt=""
                                                                />
                                                                <img
                                                                    src={
                                                                        imageBasePath + "enquiry_open_status.svg" /* : "enquiry_close_status.svg" */
                                                                    }
                                                                    className={styles.enquiryBoxStatus}
                                                                    alt=""
                                                                />
                                                            </div>
                                                            <div className={styles.enquiryDetailWrapper}>
                                                                <p
                                                                    className={styles.enquiryNumber + " paragraphSemiBold "}
                                                                >
                                                                    #168787
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </Grid>
                                                    <Grid item md={2}>
                                                        <div className={styles.enquiryContentWrapper}>
                                                            <p
                                                                className={
                                                                    styles.enquiryDate + " paragraph "
                                                                }
                                                            >
                                                                23/02/2021
                                                            </p>
                                                        </div>
                                                    </Grid>
                                                    <Grid item md={3}>
                                                        <div className={styles.enquiryContentWrapper}>
                                                            <p
                                                                className={
                                                                    styles.contactEmail+ " paragraph "
                                                                }
                                                            >
                                                                mohitpal1782@email.com
                                                            </p>
                                                        </div>
                                                    </Grid>
                                                    <Grid item md={3}>
                                                        <div className={styles.enquiryContentWrapper}>
                                                            <p
                                                                className={
                                                                    styles.contactNumber + " paragraph "
                                                                }
                                                            >
                                                                +91 98320 83290
                                                            </p>
                                                        </div>
                                                    </Grid>
                                                    <Grid item md={2}>
                                                        <div className={styles.enquiryContentWrapper}>
                                                            <img
                                                                src={
                                                                    imageBasePath + "enquiry_open.svg" /* : "enquiry_close.svg" */
                                                                }
                                                                alt=""
                                                            />
                                                            <p
                                                                className={
                                                                    styles.enquiryStatusOpen + " paragraph " /* styles.enquiryStatusClose */
                                                                }
                                                            >
                                                                Open
                                                            </p>
                                                        </div>
                                                    </Grid>
                                                </Grid>
                                            </div>
                                        </Link>

                                    </>
                                ))}
                                <EnquiriesListDrawer
                                    handleClose={() => setOrderDetail(false)}
                                    open={orderDetail}
                                />
                            </div>
                        </div>
                    </div>
                </Grid>
            </Grid>
        </div>
    )
}
