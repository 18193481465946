import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import styles from "../../../assets/styles/devices.module.scss";
import { InputText, InputSelect, CustomButton } from "../../../shared_ui_components";
import { imageBasePath, errorCodes, reactSelectStylesMediumSize } from "../../../constants";
import { Link } from "react-router-dom";
import { activateDeviceById, activateAndDeactivateDeviceById } from "../apiServices";
import { toastFlashMessage } from "../../../utils";
import { fieldValidation } from "../../../utils/formValidation";
import moment from "moment";
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

export default function DevicesListItem(props) {
  const { device, roomLists, deviceList } = props;
  const [submitLoader, setSubmitLoader] = useState(false);
  const [error, setError] = useState({});
  const [formData, setFormData] = useState({
    selectRoom: "",
    macId: "",
  });

  useEffect(() => {
    setFormData((form) => ({
      ...form,
      selectRoom: null,
    }));
  }, [roomLists]);

  const handleActivateDevice = () => {
    const form_data = {
      roomId: formData.selectRoom,
      macId: formData.macId,
      deviceId: device._id,
    };
    const validateNewInput = {
      selectRoom:
        errorCodes["selectRoom"][
          fieldValidation({
            ...errorCodes["selectRoomObj"],
            fieldval: formData.selectRoom,
          })
        ],
      macId:
        errorCodes["macId"][
          fieldValidation({
            ...errorCodes["macIdObj"],
            fieldval: formData.macId,
          })
        ],
    };
    if (Object.keys(validateNewInput).every((k) => validateNewInput[k] == "")) {
      setSubmitLoader(true);
      activateDeviceById(form_data).then((response) => {
        if (response.status) {
          setSubmitLoader(false);
          activateAndDeactivateDeviceById({ deviceId: device._id, type: "activate" }).then((response) => {
            if (response.status) {
              toastFlashMessage("Device activated successfully", "success");
              deviceList();
            } else {
              toastFlashMessage("Something went wrong while activating the device", "error");
            }
          });
        } else {
          setSubmitLoader(false);
          toastFlashMessage("Something went wrong while activating the device", "error");
        }
      });
    } else {
      setError(validateNewInput);
    }
  };

  const handleChange = (key, value) => {
    setFormData((form) => ({
      ...form,
      [key]: value,
    }));
    setError((preError) => ({
      ...preError,
      [key]: "",
    }));
  };

const deviceTypeNameMapping={
  VING_ROOM:"Ving Room",
  SHARE_ROOM:"Share Room",
  VING_SIGNAGE:"Ving Signage"
}

  return (
    <>
      {device.macId === null ? (
        <div className={styles.devicesListTableRow}>
          <Grid container spacing={2}>
            <Grid item md={3}>
              <div className={styles.devicesContentWrapper}>
                <div className={styles.devicePath}>
                  <img src={imageBasePath + "vingbox_icon.svg"} class={styles.deviceImg} />
                  <div className={styles.devicesStatus}>
                    <img src={imageBasePath + "quotation_warning.svg"} alt="" />
                  </div>
                  <p className={styles.deviceName + " paragraph "}>
                    {device?.deviceName ? device?.deviceName : "-"}
                  </p>
                </div>
              </div>
            </Grid>
            <Grid item md={3}>
              <div className={styles.devicesContentWrapper}>
                <div className={styles.selectRoomInput}>
                  <InputSelect
                    styles={reactSelectStylesMediumSize}
                    closeMenuOnSelect={true}
                    isSearchable={false}
                    options={roomLists}
                    placeholder={"Select room"}
                    noOptionsMessage={() => "No matches found"}
                    error={error.selectRoom}
                    value={
                      formData?.selectRoom
                        ? roomLists?.find((opt) => opt.value === formData?.selectRoom)
                        : null
                    }
                    onChange={(opt) => handleChange("selectRoom", opt.value)}
                  />
                </div>
              </div>
            </Grid>
            <Grid item md={3}>
              <div className={styles.devicesContentWrapper}>
                <InputText
                  placeholder="Enter  MAC ID *"
                  error={error.macId}
                  onChange={(e) => handleChange("macId", e.target.value)}
                />
              </div>
            </Grid>
            <Grid item md={3}>
              <div className={styles.devicesContentWrapper}>
                <CustomButton
                  className={`${styles.activateBtn}  primaryBtn  ${submitLoader ? "disabledBtn" : ""}`}
                  children={submitLoader ? "Activating.." : "Activate"}
                  onClick={handleActivateDevice}
                />
              </div>
            </Grid>
          </Grid>
        </div>
      ) : (
        <Link to={`./devices/${device._id}`}>
          <div className={styles.devicesListTableRow}>
            <Grid container spacing={2}>
              <Grid item md={3}>
                <div className={styles.devicesContentWrapper}>
                  <div className={styles.devicePath}>
                    <img src={imageBasePath + "vingbox_icon.svg"} class={styles.deviceImg} />
                    <div className={styles.devicesStatus}>
                      <img
                        src={
                          imageBasePath +
                          `${
                            device.deviceStatus === "Active"
                              ? "quotation_success.svg"
                              : "device_deactivated.svg"
                          }`
                        }
                        alt=""
                      />
                    </div>
                    <p className={styles.deviceName + " paragraph "} style={{display:"flex",flexDirection:"column"}}>
                       {device?.deviceName ? device?.deviceName : "NA"}
                       <span style={{color:"#67737d"}}>{device?.roomId?.roomShortCode }-{device?.deviceDynamicCode}</span>
                      
                    </p>
                  </div>
                </div>
              </Grid>
              <Grid item md={2}>
                <div className={styles.devicesContentWrapper}>
                  <p className={styles.deviceRoom + " paragraph "}>
                    {device?.roomId?.roomName ? device?.roomId?.roomName : "NA"}
                  </p>
                </div>
              </Grid>
              <Grid item md={2}>
                <div className={styles.devicesContentWrapper}>
                  <p className={styles.deviceMacID + " paragraph "}>{device?.macId ? device?.macId : "NA"}</p>
                </div>
              </Grid>
              <Grid item md={2}>
                <div className={styles.devicesContentWrapper}>
                  <p className={styles.deviceSubPeriod + " paragraph "}>
                    <span>
                      {device?.activeSubscriptionStartDate
                        ? moment(device?.activeSubscriptionStartDate, "DD-MM-YYYY").format("DD/MM/YYYY")
                        : "NA"}
                    </span>{" "}
                    -{" "}
                    <span>
                      {device?.activeSubscriptionEndtDate
                        ? moment(device?.activeSubscriptionEndtDate, "DD-MM-YYYY").format("DD/MM/YYYY")
                        : "NA"}
                    </span>
                  </p>
                </div>
              </Grid>
              <Grid item md={2}>
                <div className={styles.devicesContentWrapper} style={{display:"flex"}}>
                  <FiberManualRecordIcon fontSize="small" style={{ color: device?.deviceOnlineStatus  ? "#2e9940" : "#b3b9be", margin:"9px 10px" }}/>
                  <p className={styles.deviceSubPeriod + " paragraph "}>
                  {device?.deviceOnlineStatus ? "Online" : "Offline"}
                  </p>
                </div>
              </Grid>
              <div className={styles.devicesContentWrapper} style={{display:"flex"}}>
                  
                  <p className={styles.deviceSubPeriod + " paragraph "}>
                    {deviceTypeNameMapping[device?.deviceType]}
                  </p>
                </div>
              
            </Grid>
           
          </div>
        </Link>
      )}
    </>
  );
}
