import React, { useState, useEffect } from "react";
import { Grid, Tabs, Tab } from "@material-ui/core";
import styles from "../../../assets/styles/orders.module.scss";
import { imageBasePath, reactSelectStylesSmall } from "../../../constants";
import {
  InputSelect,
  InputText,
  CustomPagination,
  CustomLink,
  NoResult,
} from "../../../shared_ui_components";
import OrderListDrawer from "./OrderListDrawer";
import { SingleOrderList } from "./SingleOrderList";
import { useParams } from "react-router-dom";
import { getOrdersList, getOrderDetail, getAdminOrdersList } from "../apiServices";
import Shimmer from "./Shimmer";
import { sortObj, getUserInfo } from "../../../utils";

export default function OrderList(props) {
  const { id } = useParams();

  const [orderDetail, setOrderDetail] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const [ordersList, setOrdersList] = useState([]);
  const [ordersDeliveredList, setOrdersDeliveredList] = useState([]);
  const [orderDetailList, setOrderDetailList] = useState(null);
  const [loading, setLoading] = useState(true);
  const [noOrderStatus, setNoOrderStatus] = useState(false);
  const [orderId, setOrderId] = useState(null);
  const [search, setSearch] = useState({
    val: "",
    list: [],
  });
  const [sort, setSort] = useState({
    field: "",
    asc: false,
    defaultSortUpImg: "",
    defaultSortDownImg: "",
  });

  const userInfo = getUserInfo();

  const getAllAdminOrdersList = () => {
    setLoading(true);
    getAdminOrdersList().then((response) => {
      if (response.status && response.data.length > 0) {
        setOrdersList(response.data);
        setLoading(false);
        setNoOrderStatus(false);
      } else {
        setLoading(false);
        setNoOrderStatus(true);
      }
    });
  };

  const getAllOrdersList = (id) => {
    if (id) {
      getOrdersList({ orgId: id }).then((response) => {
        if (response.status && response.data.length > 0) {
          setOrdersList(response.data);
          setLoading(false);
          setNoOrderStatus(false);
        } else {
          setLoading(false);
          setNoOrderStatus(true);
        }
      });
    } else {
      getOrdersList().then((response) => {
        if (response.status && response.data.length > 0) {
          setOrdersList(response.data);
          setLoading(false);
          setNoOrderStatus(false);
        } else {
          setLoading(false);
          setNoOrderStatus(true);
        }
      });
    }
  };

  useEffect(() => {
    if (userInfo?.userData?.role?.roleName === "vingAdmin") {
      getAllAdminOrdersList();
    } else {
      getAllOrdersList(id);
    }
  }, [id]);

  const handleChange = (event, newValue) => {
    if (newValue === 1) {
      const updatedOrdersList = ordersList?.filter(
        (order) => order.shippedSuccessfully > 0 && order.shippingInProgress === 0
      );
      setOrdersDeliveredList(updatedOrdersList);
      setSelectedTab(newValue);
    } else {
      setSelectedTab(newValue);
    }
  };

  const orderDetails = (orderDetailId) => {
    setOrderId(orderDetailId);
    setOrderDetail(true);
  };

  const emptyOrderDetails = () => {
    setOrderDetailList(null);
  };

  const handleSearch = (val) => {
    setSearch((prevSearch) => ({
      val: val,
      list: ordersList.filter(
        (order) => order._id.includes(val) || order.orgName?.toLowerCase().includes(val.toLowerCase())
      ),
    }));
  };

  const handleSort = (sortObject) => {
    let list = sortObj(sortObject.field, ordersList, sortObject.asc, sortObject.caseSensitive);
    setSort({
      field: sortObject.field,
      asc: sortObject.asc,
      defaultSortUpImg: sortObject.asc ? imageBasePath + `asc_arrow.svg` : imageBasePath + "up_arrow.svg",
      defaultSortDownImg: sortObject.asc ? imageBasePath + "down_arrow.svg" : imageBasePath + `des_arrow.svg`,
    });
    setOrdersList(list);
  };

  return (
    <>
      <div className={styles.ordersListWrapper}>
        {loading ? (
          <Shimmer type="orderLength" />
        ) : (
          <div className={styles.orderHeading}>
            <h2 className={styles.orderTitle + " subheading "}>
              Orders{" "}
              <span className={styles.orderTitleNum}>
                ({(selectedTab === 1 ? ordersDeliveredList : ordersList).length})
              </span>
            </h2>
          </div>
        )}
        {noOrderStatus === false ? (
          <div className={styles.ordersListWrapper}>
            {/* <div className={styles.orderHeading}>
              <h2 className={styles.orderTitle + " subheading "}>Orders</h2>
            </div> */}
            <div className={styles.ordersListStatus}>
              <Tabs className={styles.orderFilter} value={selectedTab} onChange={handleChange}>
                <Tab label="Pending orders" className={styles.filterItem} />
                <Tab label="Delivered orders" className={styles.filterItem} />
              </Tabs>
              {/* <List className={styles.orderFilter}>
                      <ListItem className={styles.filterItem + " " + styles.active}>Pending orders</ListItem>
                      <ListItem className={styles.filterItem}>Delivered orders</ListItem>
                  </List> */}
            </div>
            {loading ? (
              <Shimmer type="orderSearch" />
            ) : (
              <div className={styles.orderSearchBlk}>
                <Grid container direction="row" justifyContent="flex-end" alignItems="center">
                  <Grid item md={4} className={styles.orderSearchWrapper}>
                    <div className={styles.orderSearchInput}>
                      <InputText placeholder="Search here" onChange={(e) => handleSearch(e.target.value)} />
                      <img src={imageBasePath + "search_icon.svg"} alt="" />
                    </div>
                    {/* <div className={styles.orderFilterInput}>
                    <InputSelect
                      placeholder="Filter by"
                      //menuIsOpen={true}
                      isSearchable={false}
                      styles={reactSelectStylesSmall}
                    />
                  </div> */}
                  </Grid>
                </Grid>
              </div>
            )}
            <Grid container>
              <Grid item md={12}>
                <div className={styles.ordersList}>
                  <div className={styles.ordersListInnerBlk}>
                    {loading ? (
                      <Shimmer type="orderHeader" />
                    ) : (
                      <div className={styles.ordersListTableTitle}>
                        <Grid container spacing={2}>
                          <Grid item md={4}>
                            <Grid
                              container
                              direction="row"
                              spacing={2}
                              justifyContent="flex-start"
                              alignItems="center"
                            >
                              <Grid item md={8}>
                                <div className={styles.ordersTableTitleTextWrapper}>
                                  <p className={styles.ordersTableTitleText + " paragraph "}>Order No:</p>
                                </div>
                              </Grid>
                              <Grid item md={4}>
                                <div className={styles.ordersTableTitleTextWrapper}>
                                  <p className={styles.ordersTableTitleText + " paragraph "}>Order Date</p>
                                  <img
                                    src={
                                      sort.field === "createdAt"
                                        ? sort.defaultSortUpImg
                                        : imageBasePath + "up_arrow.svg"
                                    }
                                    alt=""
                                    className="sortUp"
                                    onClick={() =>
                                      handleSort({
                                        field: "createdAt",
                                        asc: !sort.asc,
                                        caseSensitive: true,
                                      })
                                    }
                                  />
                                  <img
                                    src={
                                      sort.field === "createdAt"
                                        ? sort.defaultSortDownImg
                                        : imageBasePath + "down_arrow.svg"
                                    }
                                    alt=""
                                    className="sortDown"
                                    onClick={() =>
                                      handleSort({
                                        field: "createdAt",
                                        asc: !sort.asc,
                                        caseSensitive: true,
                                      })
                                    }
                                  />
                                </div>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item md={2}>
                            <Grid
                              container
                              direction="row"
                              spacing={2}
                              justifyContent="flex-start"
                              alignItems="center"
                            >
                              <Grid item md={4}>
                                <div className={styles.ordersTableTitleTextWrapper}>
                                  <p className={styles.ordersTableTitleText + " paragraph "}>Units</p>
                                  <img
                                    src={
                                      sort.field === "units"
                                        ? sort.defaultSortUpImg
                                        : imageBasePath + "up_arrow.svg"
                                    }
                                    alt=""
                                    className="sortUp"
                                    onClick={() =>
                                      handleSort({
                                        field: "units",
                                        asc: !sort.asc,
                                        caseSensitive: false,
                                      })
                                    }
                                  />
                                  <img
                                    src={
                                      sort.field === "units"
                                        ? sort.defaultSortDownImg
                                        : imageBasePath + "down_arrow.svg"
                                    }
                                    alt=""
                                    className="sortDown"
                                    onClick={() =>
                                      handleSort({
                                        field: "units",
                                        asc: !sort.asc,
                                        caseSensitive: false,
                                      })
                                    }
                                  />
                                </div>
                              </Grid>
                              <Grid item md={8}>
                                <div className={styles.ordersTableTitleTextWrapper}>
                                  <p className={styles.ordersTableTitleText + " paragraph "}>Subscription</p>
                                  <img
                                    src={
                                      sort.field === "subscriptionPeriod"
                                        ? sort.defaultSortUpImg
                                        : imageBasePath + "up_arrow.svg"
                                    }
                                    alt=""
                                    className="sortUp"
                                    onClick={() =>
                                      handleSort({
                                        field: "subscriptionPeriod",
                                        asc: !sort.asc,
                                        caseSensitive: false,
                                      })
                                    }
                                  />
                                  <img
                                    src={
                                      sort.field === "subscriptionPeriod"
                                        ? sort.defaultSortDownImg
                                        : imageBasePath + "down_arrow.svg"
                                    }
                                    alt=""
                                    className="sortDown"
                                    onClick={() =>
                                      handleSort({
                                        field: "subscriptionPeriod",
                                        asc: !sort.asc,
                                        caseSensitive: false,
                                      })
                                    }
                                  />
                                </div>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item md={6}>
                            <Grid
                              container
                              direction="row"
                              spacing={2}
                              justifyContent="flex-end"
                              alignItems="center"
                            >
                              <Grid item md={4}>
                                <div className={styles.ordersTableTitleTextWrapper}>
                                  <p className={styles.ordersTableTitleText + " paragraph "}>
                                    Payment Status
                                  </p>
                                  <img
                                    src={
                                      sort.field === "paymentStatus"
                                        ? sort.defaultSortUpImg
                                        : imageBasePath + "up_arrow.svg"
                                    }
                                    alt=""
                                    className="sortUp"
                                    onClick={() =>
                                      handleSort({
                                        field: "paymentStatus",
                                        asc: !sort.asc,
                                        caseSensitive: true,
                                      })
                                    }
                                  />
                                  <img
                                    src={
                                      sort.field === "paymentStatus"
                                        ? sort.defaultSortDownImg
                                        : imageBasePath + "down_arrow.svg"
                                    }
                                    alt=""
                                    className="sortDown"
                                    onClick={() =>
                                      handleSort({
                                        field: "paymentStatus",
                                        asc: !sort.asc,
                                        caseSensitive: true,
                                      })
                                    }
                                  />
                                </div>
                              </Grid>
                              <Grid item md={4}>
                                <div className={styles.ordersTableTitleTextWrapper}>
                                  <p className={styles.ordersTableTitleText + " paragraph "}>
                                    Shipment In Progress
                                  </p>
                                  <img
                                    src={
                                      sort.field === "shippingInProgress"
                                        ? sort.defaultSortUpImg
                                        : imageBasePath + "up_arrow.svg"
                                    }
                                    alt=""
                                    className="sortUp"
                                    onClick={() =>
                                      handleSort({
                                        field: "shippingInProgress",
                                        asc: !sort.asc,
                                        caseSensitive: false,
                                      })
                                    }
                                  />
                                  <img
                                    src={
                                      sort.field === "shippingInProgress"
                                        ? sort.defaultSortDownImg
                                        : imageBasePath + "down_arrow.svg"
                                    }
                                    alt=""
                                    className="sortDown"
                                    onClick={() =>
                                      handleSort({
                                        field: "shippingInProgress",
                                        asc: !sort.asc,
                                        caseSensitive: false,
                                      })
                                    }
                                  />
                                </div>
                              </Grid>
                              <Grid item md={4}>
                                <div className={styles.ordersTableTitleTextWrapper}>
                                  <p className={styles.ordersTableTitleText + " paragraph "}>
                                    Shipment Fulfilled
                                  </p>
                                  <img
                                    src={
                                      sort.field === "shippedSuccessfully"
                                        ? sort.defaultSortUpImg
                                        : imageBasePath + "up_arrow.svg"
                                    }
                                    alt=""
                                    className="sortUp"
                                    onClick={() =>
                                      handleSort({
                                        field: "shippedSuccessfully",
                                        asc: !sort.asc,
                                        caseSensitive: false,
                                      })
                                    }
                                  />
                                  <img
                                    src={
                                      sort.field === "shippedSuccessfully"
                                        ? sort.defaultSortDownImg
                                        : imageBasePath + "down_arrow.svg"
                                    }
                                    alt=""
                                    className="sortDown"
                                    onClick={() =>
                                      handleSort({
                                        field: "shippedSuccessfully",
                                        asc: !sort.asc,
                                        caseSensitive: false,
                                      })
                                    }
                                  />
                                </div>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </div>
                    )}
                    <div className={styles.ordersListTableContent}>
                      {loading ? (
                        <Shimmer type="singleOrderList" />
                      ) : selectedTab == 0 ? (
                        search.val ? (
                          <>
                            {search.list.length === 0 ? (
                              <NoResult image="no_order.png" title="No Orders found" />
                            ) : (
                              search.list.map((order) => (
                                <div key={order._id}>
                                  <SingleOrderList order={order} orderDetails={orderDetails} />
                                </div>
                              ))
                            )}
                          </>
                        ) : (
                          ordersList.map((order) => (
                            <div key={order._id}>
                              <SingleOrderList order={order} orderDetails={orderDetails} />
                            </div>
                          ))
                        )
                      ) : ordersDeliveredList.length === 0 ? (
                        <NoResult image="no_order.png" title="No search result found" />
                      ) : search.val ? (
                        search.list.map((order) => (
                          <div key={order._id}>
                            <SingleOrderList order={order} orderDetails={orderDetails} />
                          </div>
                        ))
                      ) : (
                        ordersDeliveredList.map((order) => (
                          <div key={order._id}>
                            <SingleOrderList order={order} orderDetails={orderDetails} />
                          </div>
                        ))
                      )}

                      {orderDetail ? (
                        <OrderListDrawer
                          handleClose={() => setOrderDetail(false)}
                          open={orderDetail}
                          orderDetailList={orderDetailList}
                          emptyOrderDetails={emptyOrderDetails}
                          getAllAdminOrdersList={getAllAdminOrdersList}
                          orderId={orderId}
                        />
                      ) : null}
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
            {/* <div className="customPagination">
          <CustomPagination count={6} />
        </div> */}
          </div>
        ) : (
          <>
            <NoResult
              image="no_order.png"
              title="No Orders found"
              subtext="Looks like you have not yet placed any order. Start requesting quotes to place bulk orders"
              btnText="Request quote"
              link="/request-quote"
            />
          </>
        )}
      </div>
    </>
  );
}
