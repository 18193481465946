import React, {Component} from 'react';
import { Container ,Grid, List, ListItem} from '@material-ui/core';
import {imageBasePath} from '../constants';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';
import moment from 'moment'; 

function Footer(props) {

    const currentYear = moment().year();

    return (
        <footer>
            <Container maxWidth="lg">
                <div className="footerWrapper">
                    <Grid container direction="row" spacing={2}>
                        <Grid item md={6}>
                            <Link to="/">
                                <img src={ imageBasePath+'logo.svg' } alt="Letsving"/>
                            </Link>
                            <p className="FooterText paragraph">Unified Video Conference Platform <br /> For Hybrid Workplace</p>
                            <p className="FooterText paragraph">Any OEM Camera. Any Meeting Platform. Anytime. Anywhere</p>
                        </Grid>
                        <Grid item md={3} container direction="column">
                            <div className="link-items">
                                    <span className=" paragraph" >
                                        <a
                                            href="mailto:hello@LetsVing.com"
                                            target="_blank"
                                            className="link-items"
                                            rel="noopener noreferrer"
                                        >
                                            Contact Us
                                        </a>
                                    </span>
                            </div>
                            <div className="link-items">
                                <Link to="/terms-of-use">
                                    <span className="paragraph">Terms of Use</span>
                                </Link>
                            </div>
                            <div className="link-items">
                                <Link to="/privacy-policy">
                                    <span className="paragraph">Privacy Policy</span>
                                </Link>
                            </div>
                        </Grid>
                        <Grid item md={3}>
                            <p className="footerSocialMediaTitle paragraph">Follow Us</p>
                            <a className="socialMediaLogo" href="https://twitter.com/letsving" rel="noreferrer" target="_blank">
                                <img src={ imageBasePath+'Twitter_white.svg' } alt="Letsving"/>
                            </a>
                            <a className="socialMediaLogo" href="https://www.linkedin.com/company/letsving/" rel="noreferrer" target="_blank">
                                <img src={ imageBasePath+'LinkedIN_white.svg' } alt="Letsving"/>
                            </a>
                        </Grid>
                </Grid>
                </div>
                    <div >
                        <Grid item xs={12}>
                             <p className={"paragraph textCenter copyRight"}>© {currentYear} Ving. All Rights Reserved.</p>
                        </Grid>
                    </div>
            </Container>
    </footer>
    );
}

export default Footer;